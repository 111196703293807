import type { IShipment, IShipmentDataDTO } from '@/modules/pmt/interfaces';
import { mapShipment } from '@/modules/pmt/mappers';
import type { FirestoreDataConverter } from 'firebase/firestore';

export const shipmentConverter: FirestoreDataConverter<IShipment> = {
  fromFirestore(snapshot) {
    const data = snapshot.data();
    // TODO: Remove isDeleting
    return {
      isDeleting: false,
      data: mapShipment(snapshot.id, data as IShipmentDataDTO),
    };
  },
  toFirestore(shipment) {
    return shipment;
  },
};

import { useRouter } from 'next/router';
import { useMemo, useRef } from 'react';
import {
  checkIsLeadsWrapperPages,
  getPathnameFromURL,
  gtmInteractionLeadsForm,
  gtmViewLeadsForm,
  gtmSubmissionLeadsForm,
} from '@/utils';
import { FieldType, type FormOnSubmit, type FormOnFocus, type IFormField } from '@/interfaces';
import { LEADS_PAGE_TYPE, LeadsFormFields } from '@/constants';
import { triggerHook } from '@/fetchers';
import { useTriggerWhenScrollIntoViewOnce, useGlobalState } from '@/hooks';

interface LeadsWrapperProps {
  formFieldList?: IFormField[];
  zapierHookId?: string;
  pageTitle?: string;
}

const modifiedBooleanValue = (formValue: Record<string, any>) => {
  return Object.entries(formValue).reduce<Record<string, any>>((newObj, [key, value]) => {
    if (typeof value === 'boolean') {
      newObj[key] = value ? 'Yes' : 'No';
    } else {
      newObj[key] = value;
    }
    return newObj;
  }, {});
};

const useLeadsForm = ({ formFieldList, zapierHookId, pageTitle }: LeadsWrapperProps) => {
  const { pageUrl, userInfo, utmData } = useGlobalState();
  const { pathname, query, asPath } = useRouter();

  const formValuesMapping: Record<string, any> = {
    firstName: userInfo?.firstName,
    lastName: userInfo?.lastName,
    emailAddress: userInfo?.email,
    companyName: userInfo?.companyName,
  };

  const leadsWrapperRef = useRef<HTMLDivElement>(null);

  useTriggerWhenScrollIntoViewOnce(leadsWrapperRef, () => {
    if (!checkIsLeadsWrapperPages(pageUrl)) return;

    gtmViewLeadsForm({
      landingPage: getPathnameFromURL(pageUrl),
      leadsPageType: LEADS_PAGE_TYPE[pathname],
    });
  });

  const modifiedFormFieldList = useMemo(() => {
    return (formFieldList || []).reduce(
      (acc, formField) => {
        const fieldName = formField.name.replace(/[<>]/g, '');
        const fieldNameCamelCase = LeadsFormFields[fieldName];

        if (fieldNameCamelCase) {
          formField.value = formValuesMapping[fieldNameCamelCase];
        }

        switch (formField.type) {
          case FieldType.Dropdown: {
            const { dropdownOptions } = formField;
            if (!dropdownOptions?.length) return acc;

            const queryKey = Object.keys(query).find((k) => k === fieldName);
            const queryValue = queryKey && query[queryKey];

            if (!queryValue || Array.isArray(queryValue)) return [...acc, formField];

            const dropdownOrder = Number.parseInt(queryValue);
            if (isNaN(dropdownOrder)) return [...acc, formField];

            if (dropdownOrder > 0 && dropdownOrder <= dropdownOptions?.length) {
              formField.value = dropdownOptions?.[dropdownOrder - 1];
            }
            break;
          }

          case FieldType.Checkbox:
            formField.truncate = 2;
            break;

          default:
            break;
        }

        return [...acc, formField];
      },
      <IFormField[]>[],
    );
  }, [formFieldList, query]);

  const handleSubmit: FormOnSubmit = (formValue, onFinish, { reset }) => {
    if (!zapierHookId) {
      return onFinish(false);
    }

    let submitData = {
      ...modifiedBooleanValue(formValue),
      pagePath: asPath.split('?')[0],
      pageTitle,
    };

    if (utmData) {
      submitData = {
        ...submitData,
        ...utmData,
      };
    }

    triggerHook({
      hookId: zapierHookId,
      data: submitData,
    }).then((response) => {
      if (!response?.ok) {
        return onFinish(false);
      }

      reset();
      onFinish(true);
      setTimeout(() => onFinish(false), 2000);

      if (checkIsLeadsWrapperPages(pageUrl)) {
        gtmSubmissionLeadsForm({
          landingPage: getPathnameFromURL(pageUrl),
          leadsPageType: LEADS_PAGE_TYPE[pathname],
        });
      }
    });
  };

  const handleFocus: FormOnFocus = (formField) => {
    const isLeadsWrapperPages = checkIsLeadsWrapperPages(pageUrl);
    isLeadsWrapperPages && formField && formField.name && gtmInteractionLeadsForm(formField.name);
  };

  return {
    modifiedFormFieldList,
    leadsWrapperRef,
    handleSubmit,
    handleFocus,
  };
};

export default useLeadsForm;

import { Typography } from '@/components';
import { styledText } from '@/components/Typography/Typography.styles';
import { TextStyles } from '@/constants';
import NextImage from 'next/image';
import styled from 'styled-components';
import { PaywallBackgroundContainer } from '../../RegistrationWall.styles';

export const MainPaywallContainer = styled(PaywallBackgroundContainer)`
  padding: 0;
  ${({ theme, isInsideRT }) =>
    !isInsideRT &&
    `
    // fake spacing to see content below
    padding-top: ${theme.spacing[20]};
  `}
  display: flex;
  align-items: end;
  align-self: end;
  height: 100%;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    padding: ${({ theme }) => `${theme.spacing[32]} ${theme.spacing[40]}`};
    align-items: center;
    align-self: center;
  }
`;

export const WallContainer = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.darkBlue};
  box-shadow: ${({ theme }) => theme.elevation[3]};
  border-radius: 0 0 4px 4px;
  padding: ${({ theme }) => theme.spacing[20]};
  color: ${({ theme }) => theme.colors.highEmphasisWhite};
  word-break: break-word;

  > div {
    position: relative;
    z-index: 1;
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    border-radius: 8px;
    padding: ${({ theme }) => `${theme.spacing[40]} ${theme.spacing[82]}`};
  }
`;

export const Image = styled(NextImage).attrs({
  fill: true,
  sizes: '100vw',
})({
  objectFit: 'cover',
  objectPosition: 'center',
});

export const HighlightedTitle = styled(Typography).attrs({
  variant: TextStyles['Heading 3'],
})`
  text-align: center;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    ${styledText[TextStyles['Heading 1']]}
  }
`;

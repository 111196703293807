import styled from 'styled-components';
import { TextStyles } from '@/constants';
import { ButtonSize, ButtonVariant, ButtonColorKey } from '@/interfaces';
import { Typography, Button } from '@/components';
import { styledText } from '../../Typography/Typography.styles';

import PMLogo from '../../../public/images/icons/monitor_logo.svg';

const clampText = `
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
`;

export const PanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  overflow: hidden;
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  background: ${({ theme }) => theme.colors.darkBlue};
  padding: ${({ theme }) => `${theme.spacing[20]} ${theme.spacing[20]} ${theme.spacing[40]}`};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    position: relative;
    bottom: unset;
    width: 400px;
    border-radius: 8px;
    flex: none;
    padding: ${({ theme }) => theme.spacing[40]};
  }
`;

export const Logo = styled(PMLogo)`
  display: none;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: block;
    width: 180px;
    margin-bottom: ${({ theme }) => theme.spacing[24]};
  }
`;

// tag h3: h1/h3 (desktop/mobile)
export const PanelHeader = styled(Typography).attrs({
  variant: TextStyles['Heading 3'],
})`
  color: ${({ theme }) => theme.colors.highEmphasisWhite};
  margin-bottom: ${({ theme }) => theme.spacing[16]};
  ${clampText}
  -webkit-line-clamp: 2;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    ${styledText['Heading 1']}
    margin-bottom: 29px;
  }
`;

// relative: to overlay the graphic
export const ValueItem = styled.div`
  position: relative;
  display: flex;
  color: ${({ theme }) => theme.colors.highEmphasisWhite};
  margin-bottom: ${({ theme }) => theme.spacing[16]};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ValueItemList = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing[32]};
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ValueIcon = styled.img`
  ${({ theme }) => `
    margin-right: ${theme.spacing[16]};
  `}

  width: 30px;
  height: 30px;
`;

// tag h5: h4/h5 (desktop/mobile)
export const ValueTitle = styled(Typography).attrs({
  variant: TextStyles['Heading 2'],
})`
  ${clampText}
  -webkit-line-clamp: 1;
`;

export const ValueDescription = styled(Typography).attrs({
  variant: TextStyles['Body Text Small'],
})`
  ${clampText}
  -webkit-line-clamp: 2;
  color: ${({ theme }) => theme.colors.mediumEmphasisWhite}
`;

export const ImageWrapper = styled.div`
  margin-right: ${({ theme }) => theme.spacing[16]};
`;

export const IntroPanelGraphic = styled.img(({ theme }) => ({
  position: 'absolute',
  userSelect: 'none',
  width: '140% !important',
  left: '-98px !important',
  bottom: '-5px !important',

  [`${theme.mediaBreakpointUp.md}`]: {
    left: '-70px !important',
    bottom: '-52px !important',
  },
}));

export const CustomButton = styled(Button).attrs({
  size: ButtonSize.Large,
  variant: ButtonVariant.Outlined,
  color: ButtonColorKey.Transparent,
})`
  position: relative;
  width: 100%;
  background: ${({ theme }) => theme.colors.darkBlue};

  & > span > svg,
  & > svg,
  & > span > svg path,
  & > svg path {
    fill: ${({ theme }) => theme.colors.highEmphasisWhite};
  }

  :not([disabled]):hover {
    background: ${({ theme }) => theme.colors.darkBlue};
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: none;
  }
`;

import styled from 'styled-components';

const Container = styled.div.attrs({ className: 'content-container' })`
  // TODO: this width should be change based on user screen size. Will do it after having the breakpoints from Design team
  width: 100%;
  max-width: 1160px;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin: auto;
    padding: 0 ${({ theme }) => theme.spacing[20]};
  }
`;

export const PMTContainer = styled.div.attrs({ className: 'content-container' })`
  width: 100%;
  max-width: 1160px;

  ${({ theme }) => theme.mediaBreakpointUp.lg} {
    margin: auto;
    padding-left: ${({ theme }) => theme.spacing[20]};
    padding-right: ${({ theme }) => theme.spacing[20]};
  }
`;

export default Container;

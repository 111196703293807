const fontWeight = {
  extraBold: 800,
  bold: 700,
  semiBold: 600,
  medium: 500,
  regular: 400,
};

const fontSizes = {
  10: '0.625rem',
  12: '0.75rem',
  14: '0.875rem',
  16: '1rem',
  18: '1.125rem',
  20: '1.25rem',
  24: '1.5rem',
  28: '1.75rem',
  30: '1.875rem',
  32: '2rem',
};

const lineHeight = {
  16: '16px',
  18: '18px',
  20: '20px',
  22: '22px',
  24: '24px',
  26: '26px',
  30: '30px',
  36: '36px',
  42: '42px',
  48: '48px',
};

const typography = {
  fontWeight,
  fontSizes,
  lineHeight,
};

export default typography;

enum TextStyles {
  'Large Text Display' = 'Large Text Display',
  Subheading = 'Subheading',
  'Heading 1' = 'Heading 1',
  'Heading 2' = 'Heading 2',
  'Heading 3' = 'Heading 3',
  'Heading 4' = 'Heading 4',
  'Heading 5' = 'Heading 5',
  'Heading 6' = 'Heading 6',
  Button = 'Button',
  'Body Text Large' = 'Body Text Large',
  'Body Text Small' = 'Body Text Small',
  Overline = 'Overline',
  'Nav Item Text' = 'Nav Item Text',
  'Category List' = 'Category List',
  'Caption Text' = 'Caption Text',
}

export default TextStyles;

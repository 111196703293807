import type { IRichText, IDataPicker } from '@/interfaces';

export enum WrapperBackgroundColor {
  grey = 'Grey',
  white = 'White',
}

export enum WrapperEntryName {
  Company = 'Company',
  People = 'People',
  ContentItem = 'ContentItem',
  ContentCard = 'ContentCard',
  Market = 'Market',
  ThumbnailSection = 'ThumbnailSection',
}

export interface IWrapper {
  title?: string;
  subText?: string;
  subTextMobile?: string;
  backgroundColor: WrapperBackgroundColor;
  image?: {
    contentType?: string;
    url: string;
    description: string;
  };
  content?: IRichText;
  entryList?: any[];
  dataPicker: IDataPicker;
  data: any[];
}

import Image from 'next/image';
import { theme } from '@/constants';
import { useGlobalState, useHighlightString } from '@/hooks';
import { type IPanel, OnboardingStepName } from '@/interfaces';
import {
  ImageWrapper,
  Logo,
  PanelHeader,
  PanelWrapper,
  ValueDescription,
  ValueItem,
  ValueTitle,
  IntroPanelGraphic,
  ValueItemList,
  CustomButton,
} from './LeftPanel.styles';

const LeftPanel: React.FC<IPanel> = ({ header, valuePropositionList }) => {
  const { currentStep, setCurrentStep } = useGlobalState();
  const hasButton = [OnboardingStepName.intro, OnboardingStepName.joinUs].includes(currentStep);
  const highlightCss = {
    color: theme.colors.darkOrange,
    textDecoration: 'underline',
    textUnderlinePosition: 'under',
    textDecorationThickness: '0.15rem',
  };

  const handleJoinTheCommunityClick = () => {
    setCurrentStep(OnboardingStepName.joinUs);
  };

  return (
    <PanelWrapper>
      <Logo />
      {header && <PanelHeader>{useHighlightString(header, highlightCss, 'span')}</PanelHeader>}

      <IntroPanelGraphic src='/images/intro_panel_graphic.svg' alt='Intro graphic' />
      {valuePropositionList && valuePropositionList.length > 0 && (
        <ValueItemList>
          {valuePropositionList.map((valueItem, index) => (
            <ValueItem key={index}>
              <ImageWrapper>
                <Image
                  src={valueItem?.url || ''}
                  alt={valueItem?.title || 'Proposition Image'}
                  width={30}
                  height={30}
                />
              </ImageWrapper>
              <div>
                <ValueTitle>{valueItem?.title}</ValueTitle>
                <ValueDescription>{valueItem?.description}</ValueDescription>
              </div>
            </ValueItem>
          ))}
        </ValueItemList>
      )}
      {hasButton && <CustomButton onClick={handleJoinTheCommunityClick}>Join the community</CustomButton>}
    </PanelWrapper>
  );
};

export default LeftPanel;

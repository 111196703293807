import { type IEvent, type IRightPanel, RightPanelType, type ThumbnailProps } from '@/interfaces';
import { utils_getHighlightEvent, utils_getThumbnailsByArticleType } from '@/fetchers/utils';
import { ArticleTypeName } from '@/constants';

interface IGetRelatedArticleArgs {
  data: Array<IRightPanel>;
  events: IEvent[] | ThumbnailProps[] | any;
  articles: ThumbnailProps[];
  currentEventId?: string;
  currentArticleId?: string;
}

// C1: 'Ad' = 'Ad',
// C2: 'Tracking Widget' = 'Tracking Widget',
// C3: 'Featured Carrier Section' = 'Featured Carrier Section',
// C4: 'Report Section' = 'Report Section',
// C5: 'Data Insights Section' = 'Data Insights Section',
// C6: 'Lead Gen Banner' = 'Lead Gen Banner',
// C7: 'Event Highlights' = 'Event Highlights',
export const utils_getRightSectionList = (args: IGetRelatedArticleArgs) => {
  const { data, events, articles, currentEventId, currentArticleId } = args;

  const modifiedRightSectionList = data?.map((rightPanel: IRightPanel) => {
    switch (rightPanel.type) {
      case RightPanelType.DataInsightsSection:
        return {
          ...rightPanel,
          list: utils_getThumbnailsByArticleType({
            isFeatured: true,
            articles,
            articleType: ArticleTypeName.insights,
            currentArticleId,
          })?.slice(0, 3),
        };
      case RightPanelType.EventHighlights:
        return {
          ...rightPanel,
          list: utils_getHighlightEvent({
            events,
            currentEventId,
            areUpcomingEvents: false,
          }),
        };
      case RightPanelType.Ad:
      case RightPanelType.TrackingWidget:
      case RightPanelType.FeaturedCarrierSection:
      case RightPanelType.ReportSection:
      case RightPanelType.LeadGenBanner:
      default:
        return rightPanel;
    }
  });

  return modifiedRightSectionList;
};

import type { LanguageCode } from '@/interfaces';
import type { SignUpSource, UserType } from '@/constants';
import type { PmtOnboardingPopupCta } from '@/interfaces/tracking';

export interface IUserInfo {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  profilePictureUrl: string | null;
  userType: UserType;
  companyName: string | null;
  authenticationMethod: LoginMethod;
  optInDate: string | null;
  verificationStatus?: boolean;
  defaultOptIn: boolean;
  country: string;
  emailSyncOptIn: boolean;
  subscribeToEmailSummary: boolean;
  newsletterOptIn: boolean;
}

export enum LoginMethod {
  Google = 'Google',
  LinkedIn = 'LinkedIn',
  Email = 'Email',
}

export enum LoginState {
  Login = 'login', // logged in and verified
  Unverified = 'unverified', // logged in and not verified
  NotLoggedIn = 'not logged in', // not logged in
}

export interface IVerificationParams {
  language: LanguageCode;
  source: SignUpSource;
  cta?: PmtOnboardingPopupCta;
  variant?: string;
  popupVariant?: string;
}

export enum UserSessionType {
  New = 0,
  Return = 1,
}

import styled from 'styled-components';
import { Typography } from '@/components';
import { TextStyles } from '@/constants';
import { LineChartType } from '@/interfaces';

export const LinesWrapper = styled.div`
  position: relative;
`;

export const Line = styled.div<{ width?: string }>`
  height: 12px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.midnight};
  width: ${({ width }) => `${width}%` || '100%'};

  :last-child {
    background: ${({ theme }) => theme.colors.surface05};
  }
`;

export const LineHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing[4]} 0;

  & > :first-child {
    flex: 1;
    margin-right: ${({ theme }) => theme.spacing[12]};
  }
`;

export const Value = styled(Typography).attrs({
  variant: TextStyles['Heading 3'],
  type: 'span',
})`
  color: ${({ theme }) => theme.colors.mediumEmphasisBlack};
  display: inline-block;

  &:not(:first-child) {
    color: ${({ theme }) => theme.colors.disabledBlack};
    margin-left: ${({ theme }) => theme.spacing[4]};
  }
`;

export const LineItemWrapper = styled.div<{ type: LineChartType }>`
  ${({ type }) =>
    type === LineChartType.ONE_LINE &&
    `
      ${LinesWrapper} > :first-child {
        position: absolute;
        top: 0;
        left: 0;
      }
    `}

  ${({ type, theme }) =>
    type === LineChartType.MULTIPLE_LINES &&
    `
   
      ${LinesWrapper} > :first-child {
        margin-bottom: ${theme.spacing[4]}
      }

      ${LinesWrapper} > :last-child {
        background: #D2DCE3;
      }
    `}
`;

import { useEffect, useRef, useState } from 'react';
import ViewMoreButton from '@/components/Button/ViewMoreButton';
import { TextStyles } from '@/constants';
import type { IRichText } from '@/interfaces';
import { RichText } from '@/components';
import { SEOSubTitle, SubTitle } from './Description.styles';

interface DescriptionProps {
  description: string | IRichText;
  lineClamp: {
    desktop: number;
    mobile: number;
  };
  variant?: TextStyles;
}
const Description: React.FC<DescriptionProps> = ({
  description,
  lineClamp,
  variant = TextStyles['Body Text Large'],
}) => {
  const descriptionRef = useRef<HTMLParagraphElement>(null);
  const [hasOverflow, setHasOverflow] = useState(false);
  const [unlimitedLines, setUnlimitedLines] = useState(false);

  const isRichText = typeof description === 'object';
  const isParagraph = typeof description === 'string';

  const configs = {
    ref: descriptionRef,
    $unlimitedLines: unlimitedLines,
    $lineClamp: lineClamp,
    variant: variant,
  };

  const DescriptionViewMoreButton = hasOverflow && (
    <ViewMoreButton collapsed={!unlimitedLines} onClick={() => setUnlimitedLines((prev) => !prev)} />
  );

  useEffect(() => {
    const lineOverflow =
      !!descriptionRef.current && descriptionRef.current.scrollHeight > descriptionRef.current.clientHeight;
    setHasOverflow(lineOverflow);
  }, []);

  if (isRichText) {
    return (
      <>
        <SEOSubTitle {...configs}>
          <RichText richText={description} />
        </SEOSubTitle>

        {DescriptionViewMoreButton}
      </>
    );
  }

  if (isParagraph) {
    return (
      <>
        <SubTitle {...configs}>{description}</SubTitle>

        {DescriptionViewMoreButton}
      </>
    );
  }

  return null;
};

export default Description;

import styled from 'styled-components';
import { styledText } from '@/components/Typography/Typography.styles';

export const CheckboxSquareWrapper = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${({ theme }) => theme.spacing[8]};
`;

export const CheckboxSquare = styled.div`
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 2px ${({ theme }) => theme.colors.darkBlue};
  border-radius: 3px;

  svg path {
    transform: scaleY(1.5) scaleX(1.6) skewX(-5deg);
    transform-origin: center;
  }
`;

export const Label = styled.label<{ truncate?: number }>`
  flex: 1;
  ${styledText['Body Text Small']}
  color: ${({ theme }) => theme.colors.highEmphasisBlack};

  ${({ truncate }) =>
    truncate &&
    `
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${truncate};
  `};
`;

export const CheckboxWrapper = styled.div<{
  checked?: boolean;
  disabled?: boolean;
  hasError?: boolean;
}>`
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;

  ${({ checked, theme }) =>
    checked &&
    `
    ${CheckboxSquare} {
      background: ${theme.colors.darkBlue};

      svg path {
        fill: ${theme.colors.highEmphasisWhite};
      }
    }
  `}

  ${({ disabled }) =>
    disabled &&
    `
    opacity: .5;
    cursor: not-allowed;
  `}

  ${({ checked, hasError, theme }) =>
    hasError &&
    !checked &&
    `
    ${CheckboxSquare} {
      border-color: ${theme.colors.error};
    }

    ${Label} {
      color: ${theme.colors.error};
    }
  `}
`;

export const FakeCheckbox = styled.input.attrs({
  role: 'presentation',
  type: 'checkbox',
  tabIndex: -1,
})`
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
`;

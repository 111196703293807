export enum IPmAnalyticsQueryOutputWidth {
  '100%' = '100%',
  '80px' = '80px',
}

export interface IPmAnalyticsQueryInput {
  name: string;
  operator?: keyof typeof PmAnalyticsOperator;
  value: string;
}

export interface IPmAnalyticsQueryOutput {
  name: string;
  label?: string;
  tooltip?: string;
  width: IPmAnalyticsQueryOutputWidth;
  locked?: boolean;
}

export interface IPmAnalyticsQuery {
  input: IPmAnalyticsQueryInput[];
  output: IPmAnalyticsQueryOutput[];
  table: PmAnalyticsQueryTable;
}

export enum PmAnalyticsQueryTable {
  Carrier = 'carrier',
  MarketCarrier = 'marketCarrier',
  MarketCountry = 'marketCountry',
  MarketRegion = 'marketRegion',
  MarketRevenue = 'marketRevenue',
  TradelaneTradelane = 'tradelaneTradelane',
  TradelaneCarrier = 'tradelaneCarrier',
  RetailerOrderVolume = 'retailerOrderVolume',
  RetailerTradelane = 'retailerTradelane',
  RetailerCarrier = 'retailerCarrier',
  RetailerCompetitor = 'retailerCompetitor',
}

export enum PmAnalyticsOperator {
  equals = 'e',
  'not equals to' = 'ne',
  'greater than' = 'gt',
  'less than' = 'lt',
  'greater than or equal to' = 'gte',
  'less than or equal to' = 'lte',
}

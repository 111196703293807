import { CustomEventName, ReportOrigin, RichTextEntries } from '@/constants';
import { ButtonFunctionKey, type IRichText, type RTButton } from '@/interfaces';

/**
 * Remove all elements that appears after the 1st entry block with parameter contentType
 * @param richText rich text object - IRichText
 * @param contentType content type allowed in rich text - RichTextEntries
 * @returns {IRichText} New rich text with deleted elements
 */
export const removeRichtextNextSiblings = (richText: IRichText, contentType: RichTextEntries) => {
  if (!richText) return richText;

  const blockEntries = richText?.links?.entries?.block;
  let entryFound = false;
  const initialRichText = {
    data: richText,
    hasEntry: entryFound,
  };

  if (!blockEntries) return initialRichText;
  const containsContentType: any = blockEntries.findIndex((entry) => entry?.__typename === contentType);
  if (containsContentType === -1) return initialRichText;

  const entryId: any = blockEntries[containsContentType].sys.id;

  const filteredRichText = richText.json.content.filter((item) => {
    if (item.nodeType === 'embedded-entry-block' && item.data.target.sys.id === entryId) {
      entryFound = true;
      return item;
    }
    if (!entryFound) {
      return item;
    }
  });

  return {
    data: {
      ...richText,
      json: {
        ...richText.json,
        content: filteredRichText,
      },
    },
    hasEntry: entryFound,
  };
};

export const articleRichTextGTMButtonModify = (content: IRichText, isLoggedIn?: boolean): IRichText => {
  return {
    ...content,
    links: {
      ...content?.links,
      assets: {
        ...content?.links?.assets,
        block: content?.links?.assets?.block || [],
      },
      entries: {
        ...content?.links.entries,
        block: content?.links?.entries?.block
          ? content.links.entries.block.map((blockItem) => {
              const isDownloadButton =
                blockItem?.__typename === RichTextEntries.Button &&
                (blockItem as RTButton).function === ButtonFunctionKey.Download;
              const isPopUpButton =
                blockItem?.__typename === RichTextEntries.Button &&
                (blockItem as RTButton).function === ButtonFunctionKey.PopUp;
              if (isDownloadButton && isLoggedIn) {
                return {
                  ...blockItem,
                  eventTrigger: CustomEventName.ClickReportDownloads,
                  eventLabel: ReportOrigin.article,
                };
              } else if ((isPopUpButton || isDownloadButton) && !isLoggedIn) {
                return {
                  ...blockItem,
                  eventTrigger: CustomEventName.ClickReportsSignups,
                  eventLabel: ReportOrigin.article,
                };
              }

              return blockItem;
            })
          : [],
      },
    },
  };
};

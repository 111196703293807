import styled from 'styled-components';
import { TextStyles } from '@/constants';
import { Button, Typography } from '@/components';
import { Circle, ValuesInnerContainer } from '../../RegistrationWall.styles';

export const SectionHeader = styled(Typography).attrs({
  variant: TextStyles.Overline,
})`
  margin-bottom: ${({ theme }) => theme.spacing[8]};
  text-transform: uppercase;
  text-align: center;
`;

export const FullPaywallValuesContainer = styled.div`
  > div {
    margin-bottom: ${({ theme }) => theme.spacing[16]};
  }

  > div:last-child {
    margin-bottom: 0;
  }

  margin-top: ${({ theme }) => theme.spacing[16]};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin-top: ${({ theme }) => theme.spacing[32]};
  }
`;

export const FullPaywallValuesInnerContainer = styled(ValuesInnerContainer)`
  align-items: flex-start;

  ${Circle} {
    border: 1px solid ${({ theme }) => theme.colors.darkOrange};

    > svg path {
      fill: ${({ theme }) => theme.colors.darkOrange};
    }
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    align-items: center;

    ${Circle} {
      margin-right: ${({ theme }) => theme.spacing[24]};
    }
  }
`;

export const CircleContainer = styled.div`
  padding: ${({ theme }) => theme.spacing[4]} 0;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    padding: 0;
  }
`;

export const CustomButton = styled(Button)`
  margin: ${({ theme }) => theme.spacing[16]} auto 0 auto;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin-top: ${({ theme }) => theme.spacing[32]};
  }
`;

export const SubTitle = styled(Typography).attrs({
  variant: TextStyles['Body Text Large'],
})`
  text-align: center;
  margin-top: ${({ theme }) => theme.spacing[8]};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin-top: ${({ theme }) => theme.spacing[16]};
  }
`;

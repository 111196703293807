import { convertTextToSlug } from '@/utils';

// TODO-Post-21: get prefix should be used in "get somethings from API". => should use construct, convert prefix or other words
/**
 * Returns a slugified version of the market name
 * @param marketName string
 * @param hasCountryCode boolean
 * @returns Slug version of the market name
 * @example
 * getMarketSlugFromMarketName('United States, US', true) // united-states
 * getMarketSlugFromMarketName('Palestine, State of, PS', true) // palestine-state-of
 * getMarketSlugFromMarketName('Palestine, State of, PS', false) // palestine-state-of-ps
 */
export const getMarketSlugFromMarketName = (marketName: string, hasCountryCode = false): string => {
  if (!hasCountryCode) {
    return convertTextToSlug(marketName);
  }

  const name = marketName.split(',').slice(0, -1).join(',');
  return convertTextToSlug(name);
};

/**
 * Returns the name from the headquarters string
 * @param headquarters string
 * @returns Name from the headquarters string
 * @example
 * getNameFromHeadquarters('United States, US,   US...9  9us') // United States, US
 * getNameFromHeadquarters('United States , US') // United States
 */
export const getNameFromHeadquarters = (headquarters: string): string => {
  return headquarters.split(', ').slice(0, -1).join(', ').trim();
};

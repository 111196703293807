export enum SectionWrapperVariant {
  RowDisplay = 'RowDisplay',
  ColumnDisplay = 'ColumnDisplay',
  StickyDisplay = 'StickyDisplay',
  BlockDisplay = 'BlockDisplay',
  HideDisplay = 'HideDisplay',
}

export interface ISectionWrapper {
  variant?: SectionWrapperVariant;
}

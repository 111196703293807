import { theme } from '@/constants';
import { ButtonSize, ButtonFunctionKey, type RegistrationWallProps } from '@/interfaces';
import { gtmClickLeadsElements } from '@/utils';
import { useGlobalState, useHighlightString } from '@/hooks';
import {
  CustomButton,
  InnerContainer,
  LockOutlineIcon,
  HighlightedTitle,
  ButtonPaywallContainer,
} from './ButtonPaywall.styles';

const LOCK_SIZE = 28;

const ButtonPaywall: React.FC<RegistrationWallProps> = (props) => {
  const { title, button, className, hasParentHeight } = props;
  const { setIsFromJoinUsBtn } = useGlobalState();
  const hasButton = button && button.primaryButtonText;
  const isPopUpButton = button.function === ButtonFunctionKey.PopUp;

  const highlightCss = {
    // TODO: move to constants
    color: theme.colors.darkOrange,
    textDecoration: 'underline',
    textUnderlinePosition: 'under',
    textDecorationThickness: '3px',
  };
  const highlightedTitle = useHighlightString(title, highlightCss);

  const handleLeadButtonClick = () => {
    isPopUpButton && setIsFromJoinUsBtn(true);
    button.buttonEventLabel && gtmClickLeadsElements(button.buttonEventLabel);
  };

  return (
    <ButtonPaywallContainer className={className || ''} hasParentHeight={hasParentHeight}>
      <InnerContainer>
        <LockOutlineIcon width={LOCK_SIZE} height={LOCK_SIZE} />

        {highlightedTitle && <HighlightedTitle>{highlightedTitle}</HighlightedTitle>}

        {hasButton && (
          <CustomButton
            size={ButtonSize.Large}
            buttonLink={button.buttonLink}
            function={button.function}
            mobileSize={ButtonSize.Medium}
            openInNewTab={button.openInNewTab}
            primaryButtonText={button.primaryButtonText}
            onClick={handleLeadButtonClick}
          />
        )}
      </InnerContainer>
    </ButtonPaywallContainer>
  );
};

export default ButtonPaywall;

import type { Asset } from '@/interfaces';
import { type CSSProperties, useEffect, useRef } from 'react';
import { VideoContentType } from '../interfaces';
import { isVideo } from '../utils';
import { VideoContainer } from './Video.styles';

const Video: React.FC<{
  asset?: Asset;
  height?: {
    mobile?: number | string;
    desktop?: number | string;
  };
  style?: CSSProperties;
}> = ({ style, asset, height }) => {
  const { url, contentType } = asset || {};

  const videoContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (videoContainerRef.current?.childNodes.length || !url) return;

    const video = document.createElement('video');
    video.src = url;
    video.controls = true;
    video.autoplay = true;
    video.loop = true;
    video.muted = true; // fixes autoplay in chrome
    video.setAttribute('playsinline', 'true'); // fixes autoplay in webkit (ie. mobile safari)

    if (contentType && contentType !== VideoContentType.QUICKTIME) {
      const source = document.createElement('source');
      source.src = url;
      source.type = contentType;
      video.appendChild(source);
    }

    videoContainerRef.current?.appendChild(video);
  }, []);

  if (!asset || !isVideo(asset)) return null;

  return <VideoContainer ref={videoContainerRef} style={style} customHeight={height} />;
};

export default Video;

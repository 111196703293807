/* eslint-disable @typescript-eslint/no-unused-vars */
import type { ButtonProps } from '@/interfaces';
import { checkIsActualDownloadButton } from './button.utils';

type RemoveOneButtonSensitiveData = (button: ButtonProps) => ButtonProps;

export const removeOneButtonSensitiveData: RemoveOneButtonSensitiveData = (button) => {
  const isActualDownloadButton = checkIsActualDownloadButton(button);

  if (!isActualDownloadButton) return button;

  const { buttonLink, ...rest } = button;

  return rest;
};

type RemoveManyButtonSensitiveData = (buttons: ButtonProps[]) => ButtonProps[];

export const removeManyButtonSensitiveData: RemoveManyButtonSensitiveData = (buttons) =>
  buttons.map(removeOneButtonSensitiveData);

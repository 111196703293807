import { CustomEventName, DelayTime, type PopupType } from '@/constants';
import type { LoginMethod } from '@/interfaces';
import { pushEvent, resetGTMDataLayer, resetDataLayerAndPushEvent } from '..';

export const gtmOnboardingPopUpDelay = (delayTime: DelayTime): void => {
  resetGTMDataLayer();
  pushEvent(CustomEventName.PopUpViews, { delayTime: DelayTime[delayTime] });
};

export const gtmLinkedInFollowSuccess = (authenticationMethod?: LoginMethod): void => {
  resetDataLayerAndPushEvent(CustomEventName.LinkedInFollowSuccess, {
    authenticationMethod,
  });
};

export const gtmBasicDetailsSuccess = (): void => {
  resetDataLayerAndPushEvent(CustomEventName.BasicDetailsSuccess);
};

export const gtmClickSkipLinkedIn = (authenticationMethod?: LoginMethod): void => {
  resetDataLayerAndPushEvent(CustomEventName.ClickSkipLinkedIn, {
    authenticationMethod,
  });
};

export const gtmViewLinkedIn = (): void => {
  resetDataLayerAndPushEvent(CustomEventName.ViewLinkedIn);
};

export const gtmViewBasicDetails = (): void => {
  resetDataLayerAndPushEvent(CustomEventName.ViewBasicDetails);
};

type GtmViewJoinUsPmc = (arg: {
  timing?: number;
  variant?: string;
}) => void;

export const gtmViewJoinUsPmc: GtmViewJoinUsPmc = ({ timing, variant }) => {
  resetDataLayerAndPushEvent(CustomEventName.ViewJoinUsPmc, {
    timing,
    variant,
  });
};

type GtmSignUpSuccessPmc = (arg: {
  popupType: PopupType | undefined;
  authenticationMethod: LoginMethod | undefined;
}) => void;

export const gtmSignUpSuccessPmc: GtmSignUpSuccessPmc = ({ authenticationMethod, popupType }) => {
  resetDataLayerAndPushEvent(CustomEventName.SignUpSuccessPmc, {
    authenticationMethod,
    popupType,
  });
};

type GtmClickJoinUsPmc = (arg: {
  timing?: number;
  popupType: PopupType | undefined;
  authenticationMethod: LoginMethod | undefined;
  variant?: string;
}) => void;

export const gtmClickJoinUsPmc: GtmClickJoinUsPmc = ({ timing, authenticationMethod, popupType, variant }) => {
  resetDataLayerAndPushEvent(CustomEventName.ClickJoinUsPmc, {
    timing,
    authenticationMethod,
    popupType,
    variant,
  });
};

import type { Asset } from '@/interfaces';
import { ImageContentType, VideoContentType } from './interfaces';

export const isVideo = (asset: Asset): boolean => {
  const { url, contentType } = asset;
  if (!url || !contentType) return false;

  return Object.values(VideoContentType).includes(contentType as VideoContentType);
};

export const isImage = (asset: Asset): boolean => {
  const { url, contentType } = asset;
  if (!url || !contentType) return false;

  return Object.values(ImageContentType).includes(contentType as ImageContentType);
};

import { ButtonSize } from '@/interfaces';
import {
  MainText,
  PMCButton,
  PMCImage,
  SectionContainer,
  SectionInnerContainer,
  SectionWrapper,
  SubText,
} from './PMCSection.styles';

interface IPMCSection {
  mainText?: string;
  subText?: string;
  buttonText?: string;
}

const PMCSection: React.FC<IPMCSection> = ({ mainText, subText, buttonText }) => {
  const DEFAULT_BUTTON_TEXT = 'EXPLORE THE COMMUNITY';
  return (
    <SectionWrapper>
      <SectionContainer>
        <SectionInnerContainer>
          <PMCImage />
          <MainText>{mainText}</MainText>
          <SubText>{subText?.trim()}</SubText>
          <PMCButton
            href='/'
            target='_blank'
            mobileSize={ButtonSize.Medium}
            primaryButtonText={buttonText || DEFAULT_BUTTON_TEXT}
            isPMTButton
            truncate
          />
        </SectionInnerContainer>
      </SectionContainer>
    </SectionWrapper>
  );
};

export default PMCSection;

import { useMemo, useState } from 'react';
import { PaginationArrowBox, PaginationContainer, PaginationNumberBox } from './Pagination.styles';
import AngleDown from '../../../../public/images/icons/arrow_down.svg';

interface PaginationProps {
  goToPage: (page: number) => void;
  pageCount: number;
  pageIndex: number;
  totalRows: number;
  maxPerNav?: number;
  pageSize: number;
  onArrowClicked: (index: number) => void;
}

const DEFAULT_MAX_PER_NAV = 5;

const Pagination: React.FC<PaginationProps> = ({
  goToPage,
  pageCount,
  pageIndex,
  totalRows,
  maxPerNav = DEFAULT_MAX_PER_NAV,
  pageSize,
  onArrowClicked,
}) => {
  if (totalRows <= 0) return null;

  const maxBoxes = Math.min(maxPerNav, pageCount);
  const [curIndexOfBoxes, setCurIndexOfBoxes] = useState(0);
  const hasReachFinalIndexOfBoxes = useMemo(() => {
    return Math.floor(totalRows / pageSize / maxPerNav) === curIndexOfBoxes;
  }, [totalRows, pageSize, maxBoxes, curIndexOfBoxes]);

  const handleArrowClick = (isLeft: boolean) => {
    if (isLeft && curIndexOfBoxes > 0) {
      setCurIndexOfBoxes(curIndexOfBoxes - 1);
      onArrowClicked(curIndexOfBoxes - 1);
    }

    if (!isLeft && curIndexOfBoxes < pageCount - maxBoxes) {
      setCurIndexOfBoxes(curIndexOfBoxes + 1);
      onArrowClicked(curIndexOfBoxes + 1);
    }
  };

  return (
    <PaginationContainer>
      <PaginationArrowBox
        onClick={() => handleArrowClick(true)}
        disabled={curIndexOfBoxes === 0}
        aria-label='Previous page'
        leftArrow
      >
        <AngleDown />
      </PaginationArrowBox>
      {Array.from({ length: maxBoxes }).map((_, idx) => {
        const pageNumber = idx + maxBoxes * curIndexOfBoxes;
        const isCurrentPage = pageNumber === pageIndex;
        if (pageNumber >= pageCount) return null;
        return (
          <PaginationNumberBox key={idx} active={isCurrentPage} onClick={() => goToPage && goToPage(pageNumber)}>
            {pageNumber + 1}
          </PaginationNumberBox>
        );
      })}
      <PaginationArrowBox
        onClick={() => handleArrowClick(false)}
        disabled={hasReachFinalIndexOfBoxes}
        aria-label='Next page'
      >
        <AngleDown />
      </PaginationArrowBox>
    </PaginationContainer>
  );
};

export default Pagination;

import styled from 'styled-components';

export const DropdownNavigationWrapper = styled.div`
   flex-wrap: nowrap;
   height: 100%;
   margin-right: auto;
   margin-left: ${({ theme }) => theme.spacing['32']};
   display: none;

   a {
    color: inherit;
   }

   ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: flex;
   }
`;

import { theme } from '@/constants';
import { type IShipmentData, type IProgressStage, ShipmentStage, TrackingMilestone } from '@/modules/pmt/interfaces';

export const PENDING_STAGE: IProgressStage = {
  name: ShipmentStage.Pending,
  milestones: new Set([TrackingMilestone.Pending]),
  iconSrc: '/images/icons/hourglass_bottom.svg',
  colorScheme: theme.colors.pastelBlue,
};

const ORDERED_STAGE: IProgressStage = {
  name: ShipmentStage.Ordered,
  milestones: new Set([TrackingMilestone.Ordered]),
  iconSrc: '/images/icons/shopping_bag.svg',
  // TODO: make this a theme variable
  colorScheme: '#92B2E7',
};

const IN_TRANSIT_STAGE: IProgressStage = {
  name: ShipmentStage.InTransit,
  milestones: new Set([TrackingMilestone.InTransit]),
  iconSrc: '/images/icons/truck.svg',
  colorScheme: theme.colors.silverBlue,
};

const TO_COLLECT_STAGE: IProgressStage = {
  name: ShipmentStage.ToCollect,
  milestones: new Set([TrackingMilestone.OutForDelivery, TrackingMilestone.ToCollect]),
  iconSrc: '/images/icons/delivery_hand.svg',
  colorScheme: theme.colors.cyanBlue,
};

const COLLECTED_STAGE: IProgressStage = {
  name: ShipmentStage.ToCollect,
  milestones: new Set([TrackingMilestone.Delivered, TrackingMilestone.Collected, TrackingMilestone.Returned]),
  iconSrc: '/images/icons/check_circle.svg',
  colorScheme: theme.colors.indigoBlue,
};

const CANCELLED_STAGE: IProgressStage = {
  name: ShipmentStage.Cancelled,
  milestones: new Set([TrackingMilestone.Cancelled]),
  iconSrc: '/images/icons/error.svg',
  colorScheme: theme.colors.darkError,
};

// Issue is not a Stage!
export const ISSUE_CONFIG = {
  name: ShipmentStage.Error,
  iconSrc: '/images/icons/error.svg',
  colorScheme: theme.colors.darkError,
};

const matcher = (stageCondition: Set<TrackingMilestone>) => {
  return (shipment: IShipmentData) => {
    return stageCondition.has(shipment.milestone);
  };
};

type StageMatcher = {
  stage: IProgressStage;
  matcher: (shipment: IShipmentData) => boolean;
};

// Order is important here, check from top->bottom. Please be mindful before adding a stage matcher
export const STAGE_MATCHERS: StageMatcher[] = [
  { stage: CANCELLED_STAGE, matcher: matcher(CANCELLED_STAGE.milestones) },
  { stage: PENDING_STAGE, matcher: matcher(PENDING_STAGE.milestones) },
  { stage: ORDERED_STAGE, matcher: matcher(ORDERED_STAGE.milestones) },
  { stage: IN_TRANSIT_STAGE, matcher: matcher(IN_TRANSIT_STAGE.milestones) },
  { stage: TO_COLLECT_STAGE, matcher: matcher(TO_COLLECT_STAGE.milestones) },
  { stage: COLLECTED_STAGE, matcher: matcher(COLLECTED_STAGE.milestones) },
];

export const TRACKING_STAGE_LIST: IProgressStage[] = [
  PENDING_STAGE,
  IN_TRANSIT_STAGE,
  TO_COLLECT_STAGE,
  COLLECTED_STAGE,
];

export const ORDERED_TRACKING_STAGE_LIST: IProgressStage[] = [
  ORDERED_STAGE,
  IN_TRANSIT_STAGE,
  TO_COLLECT_STAGE,
  COLLECTED_STAGE,
];

export const CANCELLED_TRACKING_STAGE_LIST: IProgressStage[] = [
  ORDERED_STAGE,
  IN_TRANSIT_STAGE,
  TO_COLLECT_STAGE,
  CANCELLED_STAGE,
];

import { Breadcrumbs, Container, PageOverviewBanner, RichText } from '@/components';
import type { IBreadcrumbs } from '@/components/Breadcrumbs/Breadcrumbs';
import { ContentTypeName } from '@/constants';
import { type IRichText, LeadGenBannerVariant, type SectionThemeKey } from '@/interfaces';
import { capitalize } from '@/utils';
import SectionListRenderer from '../SectionListRenderer';
import { RichTextContainer } from '../SectionListRenderer.styles';
import { BreadcrumbsContainer } from './LandingPageRenderer.styles';

interface ILandingPageRendererProps {
  sectionList?: any[];
  content?: IRichText;
  breadcrumbsTheme?: SectionThemeKey;
  title?: string;
  onlyLogo?: boolean;
}

const LandingPageRenderer: React.FC<ILandingPageRendererProps> = ({
  sectionList = [],
  content,
  breadcrumbsTheme,
  title,
  onlyLogo,
}) => {
  const foundPageOverviewBanner = sectionList.find(
    (section: any) => capitalize(section.__typename) === ContentTypeName.PageOverviewBanner,
  );

  const filteredSectionList = sectionList.reduce((filtered, section: any) => {
    if ([foundPageOverviewBanner].includes(section)) {
      return filtered;
    }
    if (capitalize(section.__typename) === ContentTypeName.LeadGenBanner) {
      section.variant = LeadGenBannerVariant.FULL_WIDTH;
    }
    return [...filtered, section];
  }, []);

  const breadcrumbs: IBreadcrumbs | undefined = !onlyLogo
    ? {
        title,
        breadcrumbsTheme,
      }
    : undefined;

  return (
    <>
      {foundPageOverviewBanner && <PageOverviewBanner breadcrumbs={breadcrumbs} {...foundPageOverviewBanner} />}
      {!foundPageOverviewBanner && !!breadcrumbs && (
        <BreadcrumbsContainer>
          <Breadcrumbs {...breadcrumbs} />
        </BreadcrumbsContainer>
      )}
      {content && !filteredSectionList?.length && (
        <Container>
          <RichTextContainer>
            <RichText richText={content} />
          </RichTextContainer>
        </Container>
      )}
      <SectionListRenderer sectionList={filteredSectionList} pageTitle={title} />
    </>
  );
};

export default LandingPageRenderer;

import {
  CarrierListTemplate,
  ChartType,
  DataSectionChildren,
  PeopleVariant,
  RightPanelType,
  WordCloudLayout,
} from '@/interfaces';
import dynamic from 'next/dynamic';

export const PeopleComponentMapping: {
  [key in PeopleVariant]?: React.ComponentType<any>;
} = {
  [PeopleVariant.Default]: dynamic(async () => import('../components/People/templates/Default')),
  [PeopleVariant.WithQuotes]: dynamic(async () => import('../components/People/templates/WithQuotes')),
  [PeopleVariant.Border]: dynamic(async () => import('../components/People/templates/Border')),
};

export const DataSectionMapping: {
  [key in DataSectionChildren]?: React.ComponentType<any>;
} = {
  [DataSectionChildren.retailerList]: dynamic(async () => import('../components/DataSection/components/RetailerList')),
  [DataSectionChildren.carrierList]: dynamic(async () => import('../components/DataSection/components/CarrierList')),
  [DataSectionChildren.peopleList]: dynamic(async () => import('../components/DataSection/components/PeopleList')),
  [DataSectionChildren.chartList]: dynamic(async () => import('../components/DataSection/components/ChartList')),
};

export const CarrierListMapping: {
  [key in CarrierListTemplate]: React.ComponentType<any>;
} = {
  [CarrierListTemplate.ItemList]: dynamic(
    async () => import('../components/DataSection/components/CarrierList/ItemList'),
  ),
  [CarrierListTemplate.CardGrid]: dynamic(
    async () => import('../components/DataSection/components/CarrierList/CardGrid/templates/DefaultCardGrid'),
  ),
  [CarrierListTemplate.CenteredCardGrid]: dynamic(
    async () => import('../components/DataSection/components/CarrierList/CardGrid/templates/CenteredCardGrid'),
  ),
};

export const ChartComponentMapping: {
  [key in ChartType]?: React.ComponentType<any>;
} = {
  [ChartType.LineMetrics]: dynamic(async () => import('../components/Chart/LineMetrics')),
  [ChartType.TableMetrics]: dynamic(async () => import('../components/Chart/TableMetrics')),
  [ChartType.BarMetrics]: dynamic(async () => import('../components/Chart/BarMetrics')),
  [ChartType.TrendMetrics]: dynamic(async () => import('../components/Chart/TrendMetrics')),
};

export const RightSectionListComponentMapping: {
  [key in RightPanelType]?: React.ComponentType<any>;
} = {
  [RightPanelType.TrackingWidget]: dynamic(async () => import('../components/TrackingWidget')),
  [RightPanelType.EventHighlights]: dynamic(async () => import('../components/RightSection/EventHighlights')),
  [RightPanelType.LeadGenBanner]: dynamic(async () => import('../components/LeadGenBanner')),
  [RightPanelType.FeaturedCarrierSection]: dynamic(
    async () => import('../components/RightSection/FeaturedCarriersSection'),
  ),
  [RightPanelType.DataInsightsSection]: dynamic(async () => import('../components/RightSection/DataInsightsSection')),
  [RightPanelType.ReportSection]: dynamic(async () => import('../components/RightSection/ReportSection')),
};

export const WordCloudMapping: {
  [key in WordCloudLayout]: React.ComponentType<any>;
} = {
  [WordCloudLayout.Slider]: dynamic(async () => import('../components/WordCloud/layout/Slider')),
  [WordCloudLayout.Row]: dynamic(async () => import('../components/WordCloud/layout/Row')),
};

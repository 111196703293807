import { StatusCodes } from '@/constants';
import { emailSyncFetcher } from './base.fetchers';

type EnableRealTimeSync = (args: { userId?: string }) => Promise<StatusCodes | undefined>;

export const disableRealTimeSync: EnableRealTimeSync = async ({ userId }) => {
  if (!userId) return;
  const endpoint = `email-account/${userId}/disable`;
  try {
    const response = await emailSyncFetcher.patch(endpoint);
    if (response.status === 200) return StatusCodes.SUCCESS_200;
  } catch (error) {
    console.error(endpoint, error);
  }
};

import styled from 'styled-components';
import { TextStyles } from '@/constants';
import Typography from '@/components/Typography';
import ArrowRight from '../../../../public/images/icons/arrow_forward.svg';

export const ArrowIcon = styled(ArrowRight)`
  width: 24px;
  height: 24px;
  margin: 0 ${({ theme }) => theme.spacing[16]};
`;

export const TradeLaneHeader = styled.div`
  display: flex;
  align-items: center;
`;

export const TradeLaneDescription = styled(Typography).attrs({
  variant: TextStyles['Body Text Small'],
})`
  color: ${({ theme }) => theme.colors.highEmphasisBlack};
  margin-top: ${({ theme }) => theme.spacing[8]};
`;

export const TradeLaneWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing[4]};
`;

import styled from 'styled-components';
import { ThumbnailDescription, ThumbnailTitle, ThumbnailEventImage } from '../Thumbnail/Thumbnail.styles';

export const LeftColumn = styled.div`
  ${({ theme }) => theme.mediaBreakpointDown.md}  {
    ${ThumbnailEventImage} {
      width: 100%;
      height: 169px;
    }
  }
`;

export const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  
  & > * {
    margin-top: ${({ theme }) => theme.spacing[16]};
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    max-width: 338px;

    & > *:first-child {
      margin-top: 0;
    }
  }
`;

export const EventListItem = styled.a``;

export const EventListWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > div > div {
    cursor: pointer;
  }

  ${ThumbnailTitle} {
    -webkit-line-clamp: 2;
  }

  ${ThumbnailDescription} {
    -webkit-line-clamp: 2;
  }
  
  ${({ theme }) => theme.mediaBreakpointUp.md} {
    justify-content: space-between;
    flex-direction: row;

    & > * {
      flex-basic: 50%;
      width: 50%;
    }

    ${ThumbnailDescription} {
      -webkit-line-clamp: 5;
    }
  }
`;

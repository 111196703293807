import type { FC, ReactElement, ReactNode } from 'react';
import { LineChartType } from '@/interfaces';
import { Line, LineHeader, LineItemWrapper, LinesWrapper, Value } from './LineItem.styles';

interface LineItemProps {
  type: LineChartType;
  title?: ReactNode | ReactElement | JSX.Element;
  data: (string | number)[][];
  maxValue?: number;
}

const LineItem: FC<LineItemProps> = ({ title, type, data, maxValue }) => {
  const _maxValue = data.reduce(
    (max: (string | number)[], entry: (string | number)[]) => (entry[1] >= max[1] ? entry : max),
    [0, Number.NEGATIVE_INFINITY],
  );

  const getWidth = (value: number) => {
    if (data?.length < 2 && maxValue && !isNaN(maxValue)) return (value / maxValue) * 100;
    if (value < 0) return 0;
    return (value / (_maxValue[1] as number)) * 100;
  };

  const renderLineValue = (i: number, value: number) => {
    if (!value) return null;
    if (data?.length > 1 && i !== 0 && value) return <Value key={i}>{`/ ${value}`}</Value>;
    return <Value key={i}>{value}</Value>;
  };

  return (
    <LineItemWrapper type={type}>
      <LineHeader>
        {title}
        <div>{data?.map((entry, i) => renderLineValue(i, entry[1] as number))}</div>
      </LineHeader>
      <LinesWrapper>
        {data.map((entry, index) => (
          <Line key={index} width={getWidth(entry[1] as number).toString()} />
        ))}
        {type === LineChartType.ONE_LINE && <Line />}
      </LinesWrapper>
    </LineItemWrapper>
  );
};

export default LineItem;

import { type QueryCompositeFilterConstraint, type QueryFieldFilterConstraint, orderBy } from 'firebase/firestore';
import { MasterTranslation } from '@/constants';
import {
  CANCELLED_SHIPMENTS_QUERY,
  DELIVERED_SHIPMENTS_QUERY,
  EXCLUDE_DELETED_SHIPMENTS_QUERY,
  IN_TRANSIT_SHIPMENTS_QUERY,
  ORDERED_SHIPMENTS_QUERY,
  OUT_FOR_DELIVERY_SHIPMENTS_QUERY,
  PENDING_SHIPMENTS_QUERY,
  RETURNED_SHIPMENTS_QUERY,
  TO_COLLECT_SHIPMENTS_QUERY,
} from './firestoreQueries.constants';

export enum SORT_DIRECTIONS {
  ASC = 'asc',
  DESC = 'desc',
}

export const DEFAULT_SORT_DIRECTION = SORT_DIRECTIONS.DESC;

// Order is important here, please check before adding new options
export enum FILTER_OPTIONS {
  ALL = 'All',
  PENDING = 'Pending',
  ORDERED = 'Ordered',
  IN_TRANSIT = 'InTransit',
  OUT_FOR_DELIVERY = 'OutForDelivery',
  TO_COLLECT = 'ToCollect',
  DELIVERED = 'Delivered',
  RETURNED = 'Returned',
  CANCELLED = 'Cancelled',
  // ARCHIVED = 'Archived',
}
export const DEFAULT_FILTER_OPTION = FILTER_OPTIONS.ALL;

type FilterOptionToWhereClause = Record<FILTER_OPTIONS, QueryFieldFilterConstraint | QueryCompositeFilterConstraint>;

export const FILTER_OPTION_TO_WHERE_CLAUSE: FilterOptionToWhereClause = {
  [FILTER_OPTIONS.ALL]: EXCLUDE_DELETED_SHIPMENTS_QUERY,
  [FILTER_OPTIONS.PENDING]: PENDING_SHIPMENTS_QUERY,
  [FILTER_OPTIONS.ORDERED]: ORDERED_SHIPMENTS_QUERY,
  [FILTER_OPTIONS.IN_TRANSIT]: IN_TRANSIT_SHIPMENTS_QUERY,
  [FILTER_OPTIONS.OUT_FOR_DELIVERY]: OUT_FOR_DELIVERY_SHIPMENTS_QUERY,
  [FILTER_OPTIONS.TO_COLLECT]: TO_COLLECT_SHIPMENTS_QUERY,
  [FILTER_OPTIONS.DELIVERED]: DELIVERED_SHIPMENTS_QUERY,
  [FILTER_OPTIONS.RETURNED]: RETURNED_SHIPMENTS_QUERY,
  [FILTER_OPTIONS.CANCELLED]: CANCELLED_SHIPMENTS_QUERY,
  // [FILTER_OPTIONS.ARCHIVED]: where('is_archived', '==', true),
};

export enum SORT_OPTIONS {
  LATEST_ADDED = 'LatestAdded',
  LATEST_UPDATED = 'LatestUpdated',
  DELIVERY_DATE = 'DeliveryDate',
}

export const DEFAULT_SORT_OPTION = SORT_OPTIONS.LATEST_ADDED;

export const SORT_OPTION_TO_ORDER_BY_CLAUSE = (direction: 'desc' | 'asc') => ({
  [SORT_OPTIONS.LATEST_ADDED]: orderBy('last_searched', direction),
  [SORT_OPTIONS.LATEST_UPDATED]: orderBy('updated_at', direction),
  [SORT_OPTIONS.DELIVERY_DATE]: orderBy('estimated_delivery_date', direction),
});

export const ALL_SORT_OPTIONS = Object.values(SORT_OPTIONS);

export const ALLOWED_FILTER_TO_SORT: Record<FILTER_OPTIONS, SORT_OPTIONS[]> = {
  [FILTER_OPTIONS.ALL]: ALL_SORT_OPTIONS,
  [FILTER_OPTIONS.PENDING]: [SORT_OPTIONS.LATEST_ADDED, SORT_OPTIONS.LATEST_UPDATED],
  [FILTER_OPTIONS.ORDERED]: ALL_SORT_OPTIONS,
  [FILTER_OPTIONS.IN_TRANSIT]: ALL_SORT_OPTIONS,
  [FILTER_OPTIONS.OUT_FOR_DELIVERY]: ALL_SORT_OPTIONS,
  [FILTER_OPTIONS.TO_COLLECT]: ALL_SORT_OPTIONS,
  [FILTER_OPTIONS.DELIVERED]: [SORT_OPTIONS.LATEST_ADDED, SORT_OPTIONS.LATEST_UPDATED],
  [FILTER_OPTIONS.RETURNED]: [SORT_OPTIONS.LATEST_ADDED, SORT_OPTIONS.LATEST_UPDATED],
  [FILTER_OPTIONS.CANCELLED]: [SORT_OPTIONS.LATEST_ADDED, SORT_OPTIONS.LATEST_UPDATED],
  // [FILTER_OPTIONS.ARCHIVED]: [SORT_OPTIONS.LATEST_ADDED, SORT_OPTIONS.LATEST_UPDATED],
};

export const DEFAULT_QUERY_LIMIT = 10;

export const MAPPED_TRANSLATION_FILTER_OPTIONS: Record<FILTER_OPTIONS, MasterTranslation> = {
  [FILTER_OPTIONS.ALL]: MasterTranslation.AllFilter,
  [FILTER_OPTIONS.PENDING]: MasterTranslation.PendingFilter,
  [FILTER_OPTIONS.ORDERED]: MasterTranslation.OrderedFilter,
  [FILTER_OPTIONS.IN_TRANSIT]: MasterTranslation.InTransitFilter,
  [FILTER_OPTIONS.OUT_FOR_DELIVERY]: MasterTranslation.OutForDeliveryFilter,
  [FILTER_OPTIONS.TO_COLLECT]: MasterTranslation.ToCollectFilter,
  [FILTER_OPTIONS.DELIVERED]: MasterTranslation.DeliveredFilter,
  [FILTER_OPTIONS.RETURNED]: MasterTranslation.ReturnedFilter,
  [FILTER_OPTIONS.CANCELLED]: MasterTranslation.CancelledFilter,
  // [FILTER_OPTIONS.ARCHIVED]: MasterTranslation.ArchiveFilter,
};

export const MAPPED_TRANSLATION_SORT_OPTIONS: Record<SORT_OPTIONS, MasterTranslation> = {
  [SORT_OPTIONS.LATEST_ADDED]: MasterTranslation.LatestAddedSort,
  [SORT_OPTIONS.LATEST_UPDATED]: MasterTranslation.LatestUpdateSort,
  [SORT_OPTIONS.DELIVERY_DATE]: MasterTranslation.DeliveryDateSort,
};

export const ALLOWED_EDD_FILTERS = Object.entries(ALLOWED_FILTER_TO_SORT)
  .filter(([_, sortOptions]) => sortOptions.includes(SORT_OPTIONS.DELIVERY_DATE))
  .map(([filterOption]) => filterOption);

import Image from 'next/image';
import { theme } from '@/constants';

type LogoImageProps = {
  src: string;
  alt: string;
  [key: string]: any;
};

/**
 * Logo image component, default width and height is 48px
 * @param src logo image source
 * @param alt alt text
 * @param rest props passed to the img tag, and can override the default width and height
 * @returns img tag with the logo image
 * @example
 * <LogoImage src='https://google.com' alt='Google' width={100} height={100} style={{ borderRadius: 10 }} />
 */
const LogoImage = ({ src, alt, ...rest }: LogoImageProps) => {
  return (
    <Image
      src={src}
      alt={alt}
      width={48}
      height={48}
      unoptimized
      style={{
        borderRadius: 2,
        marginTop: theme.spacing[8],
        marginBottom: theme.spacing[8],
      }}
      {...rest}
    />
  );
};

export default LogoImage;

import styled from 'styled-components';
import { Button } from '@/components';
import { ButtonSize, ButtonVariant } from '@/interfaces';

const visibleStyle = `
  opacity: 1;
  visibility: visible;
  pointer-events: all;
`;

const hiddenStyle = `
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
`;

export const PopOverText = styled(Button).attrs({
  variant: ButtonVariant.Link,
  size: ButtonSize.Small,
})`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  text-transform: uppercase;
  transition: 0.3s;
`;

export const PlaceholderText = styled.div`
  color: transparent;
  text-shadow: 0 0 8px ${({ theme }) => theme.colors.highEmphasisBlack};
  transition: 0.3s;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-break: break-word;
  -webkit-line-clamp: 1;
`;

export const BlurredTextContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  ${PlaceholderText} {
    ${hiddenStyle}
  }

  ${PopOverText} {
    ${visibleStyle}
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    ${PlaceholderText} {
      ${visibleStyle}
    }
  
    ${PopOverText} {
      ${hiddenStyle}
    }

    &:hover {
      ${PlaceholderText} {
        ${hiddenStyle}
      }
      ${PopOverText} {
        ${visibleStyle}
      }
    }
  }
`;

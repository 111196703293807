import { TextStyles } from '@/constants';
import { ButtonSize, ButtonFunctionKey, type RegistrationWallProps } from '@/interfaces';
import { gtmClickLeadsElements } from '@/utils';
import { useGlobalState } from '@/hooks';
import { Typography } from '@/components';

import MainPaywall from '../MainPaywallLayout';
import { Circle } from '../../RegistrationWall.styles';
import { Flex } from '@/components/Common';
import {
  SubTitle,
  CustomButton,
  SectionHeader,
  CircleContainer,
  FullPaywallValuesContainer,
  FullPaywallValuesInnerContainer,
} from './FullPaywall.styles';
import Check from '../../../../public/images/icons/check.svg';

const FullPaywall: React.FC<RegistrationWallProps> = (props) => {
  const { button, subText, subTitle, sectionHeader } = props;
  const { setIsFromJoinUsBtn } = useGlobalState();
  const hasButton = button && !!button.primaryButtonText;
  const isPopUpButton = button.function === ButtonFunctionKey.PopUp;

  const handleLeadButtonClick = () => {
    isPopUpButton && setIsFromJoinUsBtn(true);
    button.buttonEventLabel && gtmClickLeadsElements(button.buttonEventLabel);
  };

  const sectionHeaderComponent = <SectionHeader>{sectionHeader}</SectionHeader>;

  const buttonComponent = (
    <Flex>
      <CustomButton
        size={ButtonSize.Large}
        buttonLink={button.buttonLink}
        function={button.function}
        openInNewTab={button.openInNewTab}
        primaryButtonText={button.primaryButtonText}
        onClick={handleLeadButtonClick}
      />
    </Flex>
  );

  const subTextComponent = (
    <FullPaywallValuesContainer>
      {subText?.map((text: string, index: number) => (
        <FullPaywallValuesInnerContainer key={index}>
          <CircleContainer>
            <Circle mobileOuterWidth={20} mobileInnerWidth={16} desktopOuterWidth={32} desktopInnerWidth={20}>
              <Check />
            </Circle>
          </CircleContainer>
          <Typography variant={TextStyles['Body Text Large']}>{text}</Typography>
        </FullPaywallValuesInnerContainer>
      ))}
    </FullPaywallValuesContainer>
  );

  const subTitleComponent = <SubTitle>{subTitle}</SubTitle>;

  return (
    <MainPaywall
      {...props}
      buttonComponent={hasButton ? buttonComponent : undefined}
      subTextComponent={subText?.length ? subTextComponent : undefined}
      subTitleComponent={subTitleComponent}
      sectionHeaderComponent={sectionHeaderComponent}
    />
  );
};

export default FullPaywall;

import { SpinnerSize } from '@/constants';
import { SpinnerText, SpinnerImage, SpinnerWrapper, SpinnerLargeImage } from './Spinner.styles';

const Spinner: React.FC<{ text?: string; size?: SpinnerSize }> = ({ text, size = SpinnerSize.Small }) => (
  <SpinnerWrapper>
    {size === SpinnerSize.Small ? <SpinnerImage /> : <SpinnerLargeImage />}
    {text && <SpinnerText>{text}</SpinnerText>}
  </SpinnerWrapper>
);

export default Spinner;

import { Typography } from '@/components';
import { styledHyperlink } from '@/components/Button/VariantStyles';
import { styledText } from '@/components/Typography/Typography.styles';
import { TextStyles } from '@/constants';
import Image from 'next/image';
import styled from 'styled-components';

export const ItemWrapper = styled.div`
  color: ${({ theme }) => theme.colors.highEmphasisBlack};

  a {
    ${styledHyperlink}
  }
`;

export const Title = styled(Typography).attrs({
  variant: TextStyles['Heading 3'],
})`
  margin-bottom: ${({ theme }) => theme.spacing[20]};

  a {
    ${styledText[TextStyles['Heading 3']]}
  }
`;

export const Description = styled(Typography).attrs({
  variant: TextStyles['Body Text Large'],
})`
  a {
    ${styledText[TextStyles['Body Text Large']]}
  }
`;

export const Icon = styled(Image)`
  display: block;
  margin-bottom: ${({ theme }) => theme.spacing[4]};
`;

import styled from 'styled-components';
import { Typography } from '@/components';
import ArrowDown from '../../../../../public/images/icons/arrow_down.svg';

export const MenuTitle = styled(Typography)`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing[16]} 0;
`;

export const MainMenuTitle = styled(MenuTitle)`
  color: ${({ theme }) => theme.colors.darkBlue};
  padding-bottom: 7px;
`;

export const PanelTitle = styled.div`
  &:not(:last-child) {
    border-bottom: solid 1px ${({ theme }) => theme.colors.focusedBlack};
  }
`;

export const PanelTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: ${({ theme }) => theme.spacing[16]};
  padding-bottom: 7px;

  ${MainMenuTitle} {
    padding: 0 !important;
  }
`;

export const PanelContentWrapper = styled.div`
  max-height: 0;
  overflow: hidden;
  transition: .4s ease-in-out;
  padding-top: 5px;

  ${MenuTitle} {
    padding-top: 0;
    padding-bottom: ${({ theme }) => theme.spacing[12]};
  }

  & > a:last-child ${MenuTitle}, 
  & > ${MenuTitle}:last-child {
    padding-bottom: ${({ theme }) => theme.spacing[16]};
  }
`;

export const Arrow = styled(ArrowDown)`
  transition: .4s;
`;

export const PanelWrapper = styled.div<{ isExpand: boolean }>`
  &:not(:last-child) {
    border-bottom: solid 1px ${({ theme }) => theme.colors.focusedBlack};
  }

  ${({ isExpand }) =>
    isExpand &&
    `
    ${PanelContentWrapper} {
      display: block;
      max-height: 100vh;
      transition: .8s ease-in-out;
    }

    ${Arrow} {
      transform: rotate(180deg);
    }
  `}
`;

export const AccordionContainer = styled.div`
  flex: 1;
  height: max-content;
  width: auto;
  margin: -${({ theme }) => theme.spacing[24]};
  padding: ${({ theme }) => theme.spacing[24]};
  color: ${({ theme }) => theme.colors.mediumEmphasisBlack};

  ${Arrow} path {
    fill:  ${({ theme }) => theme.colors.highEmphasisBlack};
  }

  a {
    color: inherit;
  }

  ${PanelWrapper}:first-child > ${PanelTitleWrapper} > ${MenuTitle},
  ${PanelTitle}:first-child ${MenuTitle} {
    padding-top: ${({ theme }) => theme.spacing[8]};
  }

  ${PanelWrapper}:last-child > ${PanelTitleWrapper} > ${MenuTitle},
  ${PanelTitle}:last-child ${MenuTitle} {
    padding-bottom: ${({ theme }) => theme.spacing[8]};
  }

  ${PanelWrapper}:last-child ${PanelContentWrapper} > ${MenuTitle}:first-child,
  ${PanelWrapper}:last-child ${PanelContentWrapper} > a:first-child ${MenuTitle} {
    padding-top: ${({ theme }) => theme.spacing[8]};
  }

  ${PanelWrapper}:last-child ${PanelContentWrapper} > ${MenuTitle}:last-child,
  ${PanelWrapper}:last-child ${PanelContentWrapper} > a:last-child ${MenuTitle} {
    padding-bottom: 0;
  }
`;

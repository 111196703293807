import Cookies from 'js-cookie';
import { useMemo, useState } from 'react';

import { authenticate, updateTrackedShipments } from '@/fetchers';

import { CookieKey, ROUTES, RouteName, type SignUpSource } from '@/constants';

import { userInfoModify } from '@/utils';

import { useGeo, useGlobalState } from '@/hooks';
import { type IUserInfo, LoginMethod } from '@/interfaces';
import { PmtOnboardingPopupCta } from '@/interfaces/tracking/trackingOnboardingPopup.interfaces';
import { getGaClientId, getSessionId } from '@/modules/pmt/utils/ga4.utils';

type HandleSuccess = (code: string) => void;

type UseLinkedInState = (args: {
  shouldReloadAfterSuccess?: boolean;
  customHandleSuccess?: (userInfo: IUserInfo) => void;
  origin: string;
  signUpSource: SignUpSource;
  newsletterOptIn: boolean;
}) => {
  isLoading: boolean;
  handleSuccess: HandleSuccess;
  redirectUri: string;
};

const useLinkedInState: UseLinkedInState = ({
  shouldReloadAfterSuccess = true,
  customHandleSuccess,
  origin,
  signUpSource,
  newsletterOptIn,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { geoInfo } = useGeo();
  const {
    setIsLoggedIn,
    setUserInfo,
    pmtCurrentLanguageCode,
    setFirestoreUuid,
    isMyParcelsPage,
    isFromGetNotifiedBtn,
    isFromEmailSyncBtn,
    isFromDelayedShipmentBtn,
  } = useGlobalState();

  const redirectUri = `${origin}${ROUTES[RouteName.SignInWithLinkedIn].path}`;

  const cta = useMemo(() => {
    switch (true) {
      case isFromGetNotifiedBtn:
        return PmtOnboardingPopupCta.Noti;
      case isFromEmailSyncBtn:
        return PmtOnboardingPopupCta.Login;
      case isFromDelayedShipmentBtn:
        return PmtOnboardingPopupCta.DelayedShipment;
      default:
    }
  }, [isFromGetNotifiedBtn, isFromEmailSyncBtn, isFromDelayedShipmentBtn]);

  const handleSuccess: HandleSuccess = async (code) => {
    setIsLoading(true);

    try {
      const response = await authenticate({
        mediumOrigin: 'linkedin',
        code,
        redirectUri,
        country: geoInfo.country,
        signUpSource,
        newsletterOptIn,
        gaClientId: getGaClientId(),
        gaSessionId: getSessionId(isMyParcelsPage),
        eventTimestamp: Date.now.toString(),
        cta,
        authenMethod: LoginMethod.LinkedIn,
      });

      const hasError = 'error' in response;

      if (hasError) {
        throw response;
      }

      Cookies.set(CookieKey.UmsAccessToken, response.access_token, {
        expires: 365,
      });
      setIsLoggedIn(true);

      const modifiedUserInfo = userInfoModify(response.user_info);
      setUserInfo(modifiedUserInfo);

      if (response.user_info) {
        Cookies.set(CookieKey.UserId, response.user_info.id, { expires: 365 });
        setFirestoreUuid(response.user_info.id);

        updateTrackedShipments({
          language: pmtCurrentLanguageCode,
          accessToken: response.access_token,
        });
      }

      if (shouldReloadAfterSuccess) {
        location.reload();
      }

      customHandleSuccess?.(modifiedUserInfo); // Change to optional chain: biome rule
    } catch (error: any) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    handleSuccess,
    redirectUri,
  };
};

export default useLinkedInState;

import type { ISEOFields } from './head.interfaces';
import type { IRightPanel } from './rightPanel.interfaces';
import type { SectionThemeKey } from './sectionThemeKey';

export enum CompanyType {
  Carrier = 'carrier',
  Retailer = 'retailer',
  Other = 'other',
}

export interface IContentfulRetailer {
  sys: {
    id: string;
    publishedAt?: string;
  };
  breadcrumbsTheme?: SectionThemeKey;
  sectionList?: any[];
  seo: ISEOFields;
  rightSectionList: Array<IRightPanel>;
}

export interface IPmInsightsRetailer {
  id: string;
  name: string;
  type: CompanyType;
  slug?: string;
  logo?: string;
  description?: string;
  headquarters?: string;
  industry?: string;
  productCategories?: string; // original is array, but is converted to string at server-side
  foundedYear?: string;
  companySize?: string;
  website?: string;
}

export enum BlackListedRetailerKeys {
  id = 0,
  name = 1,
  type = 2,
  slug = 3,
  logo = 4,
  description = 5,
}

export enum BoardDetailsPositioning {
  headquarters = 0,
  industry = 1,
  product_categories = 2,
  founded_year = 3,
  company_size = 4,
  website = 5,
}

export interface IRetailer extends IContentfulRetailer, IPmInsightsRetailer {}

import {
  gtmOnboardingPopUpDelay,
  gtmLinkedInFollowSuccess,
  gtmBasicDetailsSuccess,
  gtmClickSkipLinkedIn,
  gtmViewBasicDetails,
  gtmViewLinkedIn,
  gtmViewJoinUsPmc,
  gtmClickJoinUsPmc,
  gtmSignUpSuccessPmc,
} from './gtmOnboardingPopUp';
import { gtmTrackingWidget } from './gtmTrackingWidget';
import { gtmFormSubmission, gtmContactFormSubmission } from './gtmFormSubmission';
import { gtmSubmissionLeadsForm } from './gtmLeadsForm';
import { gtmViewLeadsForm } from './gtmLeadsForm';
import {
  gtmVerificationPopupViews,
  gtmBrowserVerificationClicks,
  gtmViewBrowserVerification,
  gtmTrackingClickBrowserVerification,
} from './gtmVerify';
import {
  gtmClickCourierSite,
  gtmClickPmc,
  gtmParcelSearches,
  gtmInvalidParcelSearches,
  gtmImmediateFound,
  gtmImmediateNotFound,
  gtmClickParcel,
  gtmUserSuggest,
  gtmUserSuggestFound,
  gtmClickRemoveParcel,
  gtmRemoveParcelConfirm,
  gtmClickFilter,
  gtmClickSort,
  gtmClickPlus,
  gtmViewParcel,
  gtmSignUpSuccess,
  gtmViewJoinUs,
  gtmClickJoinUs,
  gtmClickUnsubAllConfirm,
  gtmViewGrantAccessPopup,
} from './gtmTracking';
import {
  gtmViewNotified,
  gtmClickNotified,
  gtmViewUnsubAll,
  gtmClickUnsubAll,
  gtmClickUnsubEmail,
  gtmClickEmailCta,
  gtmUnsubSummaryEmail,
  gtmResubSummaryToggle,
} from './gtmNotification';
import { gtmInteractionLeadsForm } from './gtmLeadsForm';
import { gtmClickNavigationOpen, gtmClickNavigationItem } from './gtmNavigation';
import {
  gtmClickRegistrationWall,
  gtmClickRegistrationBanner,
  gtmClickReportsSignups,
  gtmClickReportDownloads,
  gtmClickEventVideoSignups,
  gtmClickHeaderSignups,
  gtmClickLeadsElements,
  gtmClickOverviewBannerSignups,
} from './gtmButton';
import { gtmSessions } from './gtmSessions';
import {
  gtmViewLoginCta,
  gtmClickLoginCta,
  gtmViewEsyncAccounts,
  gtmClickEsyncDisconnect,
  gtmEsyncDisconnectConfirm,
  gtmConnectEmailSuccess,
  gtmClickEsyncConnect,
  gtmViewEsyncCta,
  gtmClickEsyncCta,
  gtmViewEsyncAlerts,
} from './gtmEmailSync';
import { gtmViewJoinUsReports } from './gtmReportsPopup';

export {
  gtmFormSubmission,
  gtmContactFormSubmission,
  gtmOnboardingPopUpDelay,
  gtmLinkedInFollowSuccess,
  gtmBasicDetailsSuccess,
  gtmClickSkipLinkedIn,
  gtmViewBasicDetails,
  gtmViewLinkedIn,
  gtmViewJoinUsPmc,
  gtmSignUpSuccessPmc,
  gtmClickJoinUsPmc,
  gtmTrackingWidget,
  gtmVerificationPopupViews,
  gtmBrowserVerificationClicks,
  gtmClickCourierSite,
  gtmImmediateFound,
  gtmImmediateNotFound,
  gtmParcelSearches,
  gtmInvalidParcelSearches,
  gtmClickParcel,
  gtmUserSuggest,
  gtmUserSuggestFound,
  gtmClickPmc,
  gtmClickRemoveParcel,
  gtmRemoveParcelConfirm,
  gtmClickFilter,
  gtmClickSort,
  gtmClickPlus,
  gtmViewParcel,
  gtmViewLeadsForm,
  gtmClickNavigationOpen,
  gtmClickNavigationItem,
  gtmClickRegistrationWall,
  gtmClickRegistrationBanner,
  gtmClickReportsSignups,
  gtmClickReportDownloads,
  gtmClickEventVideoSignups,
  gtmClickHeaderSignups,
  gtmClickLeadsElements,
  gtmViewJoinUsReports,
  gtmClickOverviewBannerSignups,
  // Leads Form
  gtmInteractionLeadsForm,
  // PMT
  gtmSignUpSuccess,
  gtmViewJoinUs,
  gtmClickJoinUs,
  gtmViewNotified,
  gtmClickNotified,
  gtmViewUnsubAll,
  gtmClickUnsubAll,
  gtmClickUnsubAllConfirm,
  gtmClickEmailCta,
  gtmClickUnsubEmail,
  gtmViewBrowserVerification,
  gtmTrackingClickBrowserVerification,
  gtmSubmissionLeadsForm,
  gtmSessions,
  gtmUnsubSummaryEmail,
  gtmResubSummaryToggle,
  // Email Sync
  gtmViewLoginCta,
  gtmClickLoginCta,
  gtmViewEsyncAccounts,
  gtmClickEsyncDisconnect,
  gtmEsyncDisconnectConfirm,
  gtmConnectEmailSuccess,
  gtmClickEsyncConnect,
  gtmViewEsyncCta,
  gtmClickEsyncCta,
  gtmViewEsyncAlerts,
  gtmViewGrantAccessPopup,
};

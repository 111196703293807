import { type RefObject, useEffect, useRef } from 'react';

const useTriggerWhenScrollIntoViewOnce = (ref: RefObject<HTMLElement>, action: () => void) => {
  const hasBeenViewed = useRef(false);

  useEffect(() => {
    const handleScroll = () => {
      if (ref.current && !hasBeenViewed.current) {
        const rect = ref.current.getBoundingClientRect();
        const isInViewport = rect.top >= 0 && rect.top <= window.innerHeight;

        if (isInViewport) {
          hasBeenViewed.current = true;
          action();
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
};

export default useTriggerWhenScrollIntoViewOnce;

import { PaywallBackgroundContainer } from './../../RegistrationWall/RegistrationWall.styles';
import styled from 'styled-components';
import Typography from '../../Typography';
import { TextStyles } from '@/constants';

export const Timeframe = styled(Typography).attrs({
  variant: TextStyles['Body Text Small'],
})`
  color: ${({ theme }) => theme.colors.disabledBlack};
  margin-top: ${({ theme }) => theme.spacing[24]};
`;

export const LineItemWrapper = styled.div(({ theme }) => ({
  marginTop: theme.spacing[24],
}));

export const LineChildOuterContainer = styled.div<{ hasData: boolean }>(({ theme, hasData }) => ({
  position: 'relative',
  width: '100%',

  ...(!hasData && {
    [`${theme.mediaBreakpointDown.sm}`]: {
      minHeight: 444,
    },
  }),
}));

export const ChildContainer = styled.div(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: `-${theme.spacing[16]}`,
  display: 'flex',
  width: `calc(100% + ${theme.spacing[32]})`,
  background: theme.colors.backgroundOpacity02,
  opacity: 0,
  pointerEvents: 'none',
  '> * > *': {
    borderRadius: 8,
  },

  [`${theme.mediaBreakpointDown.sm}`]: {
    [`${PaywallBackgroundContainer}`]: {
      padding: 0,
      alignSelf: 'center',
      '> div': {
        maxWidth: 'unset',
      },
    },
  },
}));

export const YearText = styled(Typography).attrs({
  variant: TextStyles['Heading 3'],
})(({ theme }) => ({
  color: theme.colors.highEmphasisBlack,
}));

import { USER_RETAILERS_LENGTH } from '@/constants';
import { DataPickerFieldType, type IDataPicker, type IWrapper } from '@/interfaces';
import { mapDataPickerFromRetailers } from '@/mappers';
import { fetchAllEntries } from '@/utils';
import { fetchRetailers } from '../pmInsightsAPI/retailer.fetchers';

type DataPickerReturnType = {
  data: IWrapper['data'];
  pagination: IDataPicker['pagination'] | null;
};

type DataPickerEntryHandler = (args: {
  dataPicker: IDataPicker;
  slug: string; // for logging purposes
}) => Promise<DataPickerReturnType>;

export const dataPickerEntryHandler: DataPickerEntryHandler = async ({ dataPicker, slug }) => {
  const allEntries = await fetchAllEntries({
    ...dataPicker,
    slug,
  });

  return {
    data: allEntries,
    pagination: null,
  };
};

type DataPickerRetailerHandler = (args: {
  fields: IDataPicker['fields'];
  accessToken?: string;
}) => Promise<DataPickerReturnType>;

export const dataPickerRetailerHandler: DataPickerRetailerHandler = async ({ fields, accessToken }) => {
  const EMPTY_DATA = {
    data: [],
    pagination: null,
  };
  if (!accessToken) return EMPTY_DATA;

  const hasCustomOrBackendField = fields?.some(
    ({ type }) => type === DataPickerFieldType.Custom || type === DataPickerFieldType.Backend,
  );

  if (!hasCustomOrBackendField) return EMPTY_DATA;

  const response = await getRetailers({
    page: 1,
    accessToken,
    pageSize: USER_RETAILERS_LENGTH,
  });

  return response;
};

type GetRetailers = (args: {
  page?: number;
  pageSize?: number;
  accessToken: string;
}) => Promise<DataPickerReturnType>;

export const getRetailers: GetRetailers = async ({ page = 1, pageSize = 15, accessToken }) => {
  const response = await fetchRetailers({
    page,
    pageSize,
    accessToken,
  });
  if (!response) return { data: [], pagination: null };

  return mapDataPickerFromRetailers(response);
};

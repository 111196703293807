import type { IArticleProps } from '@/interfaces';
import { SectionTitle } from '@/components';
import { MostReadContainer, MostReadWrapper } from './MostRead.styles';

// TODO: Remove the comment tag when needed
const MostReadSection: React.FC<{ data?: IArticleProps[] }> = () => {
  // const getNumberListing = (num: number) => {
  //   if (num < 10) return '0' + num
  //   return num
  // }

  return (
    <MostReadWrapper>
      <MostReadContainer>
        <SectionTitle>Most Read</SectionTitle>
        {/*
        {
          data?.map((thumbnailData: any, index: number) => (
            <ThumbnailsWrapper key={index}>
              <NumberListing>{getNumberListing(index+1)}</NumberListing>
              <Thumbnail
                {...thumbnailData}
                description={thumbnailData?.seo?.description}
                variant={ThumbnailVariant.SMALL_DEFAULT} 
              />
            </ThumbnailsWrapper>
          ))
        } */}
      </MostReadContainer>
    </MostReadWrapper>
  );
};

export default MostReadSection;

import styled from 'styled-components';
import { TextStyles } from '@/constants';
import { Typography } from '@/components';
import { styledText } from '@/components/Typography/Typography.styles';

export const DetailsContainer = styled.div<{ length: number }>`
  word-break: break-word;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${({ theme }) => `${theme.spacing[16]} ${theme.spacing[8]}`};
  
  ${({ theme }) => theme.mediaBreakpointUp.md} {
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: column;
    grid-template-rows: ${({ length }) => `repeat(${length}, auto)`};
  }
`;

export const DetailsItem = styled.div`
  height: fit-content;
`;

export const DetailsHeader = styled(Typography).attrs({
  variant: TextStyles.Overline,
})`
  margin-bottom: ${({ theme }) => theme.spacing[8]};
  text-transform: uppercase;
`;

export const DetailsValue = styled(Typography).attrs({
  variant: TextStyles['Body Text Small'],
})`
  color: ${({ theme }) => theme.colors.mediumEmphasisBlack};
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const DetailsLinkValue = styled.a`
  ${styledText['Body Text Small']}
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.hyperlink};

  :hover {
    color: ${({ theme }) => theme.colors.blue};
  }
`;

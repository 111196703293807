import { type DependencyList, type RefObject, useEffect } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useClickOutsideMultiElement = (refs: RefObject<HTMLDivElement>[], callback: any, deps?: DependencyList) => {
  useEffect(() => {
    function handleClickOutside(e: MouseEvent): void {
      const isOutside = refs.every((ref) => ref.current && !ref.current.contains(e.target as HTMLElement));
      if (isOutside) callback();
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, deps);
};

export default useClickOutsideMultiElement;

import { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { useClickOutsideMultiElement } from '@/hooks';
import { useMergeRefs } from '@floating-ui/react';

import ErrorMessage from '../../ErrorMessage';
import Label from '../../Label';

import {
  SelectedOptionWrapper,
  SelectorWrapper,
  SelectorInput,
  SelectedOption,
  SelectorOptions,
  FakeInput,
  ArrowIcon,
  Option,
} from './Selector.styles';

interface ISelectorProps {
  name?: string;
  label?: string;
  value?: string;
  placeholder?: string;
  isMandatory?: boolean;
  errorMessage?: string;
  dropdownOptions?: string[];
  onSelect?: (selected: string) => void;
  hideErrorMessage?: boolean;
}

const Selector: React.ForwardRefRenderFunction<HTMLInputElement, ISelectorProps & Record<string, any>> = (
  props,
  ref,
) => {
  const {
    name,
    label,
    onSelect,
    isMandatory,
    errorMessage,
    dropdownOptions,
    value,
    hideErrorMessage = false,
    ...restProps
  } = props;

  const [isOpen, setOpen] = useState<boolean>(false);

  const selectorWrapper = useRef<HTMLDivElement>(null);
  const selectorOptions = useRef<HTMLDivElement>(null);
  const fakeInput = useRef<HTMLInputElement>(null);

  const fakeInputMerged = useMergeRefs([ref, fakeInput]);

  // Close dropdown when clicking outside input and list
  useClickOutsideMultiElement([selectorWrapper, selectorOptions], () => setOpen(false), []);

  const handleSelect = (option: string) => () => {
    onSelect && onSelect(option);
    setOpen(false);
  };

  useEffect(() => {
    if (!fakeInput?.current) return;

    if (isOpen) {
      fakeInput.current.focus();
    } else {
      fakeInput.current.blur();
    }
  }, [isOpen]);

  const modifiedDropdownOptions: string[] = useMemo(() => {
    return dropdownOptions ? Array.from(new Set(dropdownOptions.map((option) => option.trim()))) : [];
  }, [dropdownOptions]);

  return (
    <SelectorWrapper>
      <SelectorInput
        hasValue={!!value}
        isInvalid={!!errorMessage}
        ref={selectorWrapper}
        onClick={() => setOpen((prevOpen) => !prevOpen)}
        isOpen={isOpen}
      >
        <FakeInput ref={fakeInputMerged} name={name} value={value} {...restProps} />
        {label && (
          <Label htmlFor={name} isMandatory={isMandatory}>
            {label}
          </Label>
        )}
        <SelectedOptionWrapper>
          <SelectedOption>{value}</SelectedOption>
        </SelectedOptionWrapper>
        <ArrowIcon show={isOpen} />
      </SelectorInput>
      {isOpen && (
        <SelectorOptions ref={selectorOptions}>
          {modifiedDropdownOptions.map((option, index) => (
            <Option key={index} isSelected={value === option} onClick={handleSelect(option)}>
              {option}
            </Option>
          ))}
        </SelectorOptions>
      )}
      {!hideErrorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </SelectorWrapper>
  );
};

export default forwardRef(Selector);

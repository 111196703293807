import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { type IChart, ResponsiveCollection } from '@/interfaces';
import { useResponsive } from '@/hooks';

import LineMetrics from '../LineMetrics';
import ChartJsMetrics from '../ChartContainer/components/ChartJsMetrics';

ChartJS.register(CategoryScale, LinearScale, PointElement, BarElement, Title, Tooltip, Legend, ChartDataLabels, Filler);

const BarMetrics: React.FC<IChart> = (props) => {
  const { isMobile } = useResponsive([ResponsiveCollection.Mobile]);

  if (isMobile) {
    return <LineMetrics {...props} />;
  }

  return <ChartJsMetrics ChartJsComponent={Bar} {...props} />;
};

export default BarMetrics;

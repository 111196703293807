import { ErrorPage } from '@/components';
import { ErrorPageCodes } from '@/constants';
import { sendSentryError } from '@/utils';
import React from 'react';

type ClientErrorBoundaryProps = Record<string, unknown>;

class ClientErrorBoundary extends React.Component<ClientErrorBoundaryProps, { hasError: boolean }> {
  constructor(props: ClientErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    sendSentryError(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage code={ErrorPageCodes.ERROR_500} />;
    }
    return this.props.children;
  }
}

export default ClientErrorBoundary;

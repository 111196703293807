import { CustomEventName, type ReportOrigin } from '@/constants';
import { resetDataLayerAndPushEvent } from '..';

export const gtmClickRegistrationWall = () => {
  resetDataLayerAndPushEvent(CustomEventName.ClickRegistrationWall);
};

export const gtmClickRegistrationBanner = () => {
  resetDataLayerAndPushEvent(CustomEventName.ClickRegistrationBanner);
};

export const gtmClickReportsSignups = (reportOrigin: ReportOrigin) => {
  resetDataLayerAndPushEvent(CustomEventName.ClickReportsSignups, {
    reportOrigin,
  });
};

export const gtmClickReportDownloads = (reportOrigin: ReportOrigin) => {
  resetDataLayerAndPushEvent(CustomEventName.ClickReportDownloads, {
    reportOrigin,
  });
};

export const gtmClickHeaderSignups = () => {
  resetDataLayerAndPushEvent(CustomEventName.ClickHeaderSignups);
};

export const gtmClickEventVideoSignups = () => {
  resetDataLayerAndPushEvent(CustomEventName.ClickEventVideoSignups);
};

export const gtmClickLeadsElements = (buttonEventLabel: string) => {
  resetDataLayerAndPushEvent(CustomEventName.ClickLeadsElements, {
    eventLabel: buttonEventLabel,
  });
};

export const gtmClickOverviewBannerSignups = () => {
  resetDataLayerAndPushEvent(CustomEventName.ClickOverviewBannerSignups);
};

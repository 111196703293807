import styled from 'styled-components';
import ArrowForward from 'public/images/icons/arrow_forward.svg';
import { DetailsLinkValue, DetailsValue } from '../../OverviewSection/OverviewSection.styles';

export const StyledDetailsLinkValue = styled(DetailsLinkValue)`
  display: inline-flex;
  width: 100%;

  svg {
    flex: none;
    margin-top: ${({ theme }) => theme.spacing[2]};
  }
`;

export const StyledDetailsValue = styled(DetailsValue)`
  display: inline-flex;
  width: 100%;

  svg {
    flex: none;
    margin-top: ${({ theme }) => theme.spacing[2]};
  }
`;

export const ArrowIcon = styled(ArrowForward)`
  width: 16px;
  height: 16px;
  position: relative;

  path {
    fill: ${({ theme }) => theme.colors.mediumEmphasisBlack};
  }

  margin-right: ${({ theme }) => theme.spacing[8]};
`;

import styled from 'styled-components';

import Typography from '../Typography';
import { Button as CommonButton } from '@/components';

import { TextStyles } from '@/constants';

import { ButtonVariant } from '@/interfaces';

export const SocialSharingWrapper = styled.div`
  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: flex;
    align-items: center;
  }
`;

export const Title = styled(Typography).attrs({ variant: TextStyles['Heading 5'] })`
  color: ${({ theme }) => theme.colors.mediumEmphasisBlack};
  margin-bottom: ${({ theme }) => theme.spacing[16]};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin-right: ${({ theme }) => theme.spacing[16]};
    margin-bottom: 0;
    margin-top: 0;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
`;

export const Button = styled(CommonButton).attrs({
  variant: ButtonVariant.Secondary,
  openInNewTab: true,
})`
  > span {
    display: flex;
    align-items: center;
    min-width: unset;
  }

  :not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing[8]};
  }
`;

export const ButtonIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;

  path {
    fill: ${({ theme }) => theme.colors.blue};
  }
`;

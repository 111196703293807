import { BoardDetailType, type IBoardDetail } from '@/interfaces';
import { LinkBoard, TextBoard } from '../BoardDetail';
import { Wrapper } from './BoardDetailList.styles';

type BoardDetailListProps = {
  boardDetails?: IBoardDetail[];
};

const BoardDetailList: React.FC<BoardDetailListProps> = ({ boardDetails }) => {
  if (!boardDetails?.length) return null;

  const convertText = (displayText: string | string[]) => {
    if (!Array.isArray(displayText)) return displayText;
    return displayText.join(', ');
  };

  return (
    <Wrapper>
      {boardDetails.map((boardDetail, index) => {
        if (boardDetail.type === BoardDetailType.Link) {
          const displayText = convertText(boardDetail.displayText || 'Website');

          return (
            <LinkBoard
              // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
              key={index}
              name={boardDetail.name}
              href={boardDetail.value}
              displayText={displayText}
              openInNewTab={boardDetail.openInNewTab}
            />
          );
        }

        const displayText = convertText(boardDetail.value);

        // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
        return <TextBoard key={index} name={boardDetail.name} displayText={displayText} />;
      })}
    </Wrapper>
  );
};

export default BoardDetailList;

import { ButtonColorKey } from '@/interfaces';
import styled from 'styled-components';
import { FilledButtonAsLink } from '../Button.styles';

export const ButtonLink = styled(FilledButtonAsLink)<{ isPMTButton?: boolean }>`
  border: none;
  background: ${({ theme, color }) => (color === ButtonColorKey.Yellow ? theme.colors.darkOrange : theme.colors.blue)};
  color: ${({ theme }) => theme.colors.highEmphasisWhite};

  & > span > svg,
  & > svg,
  & > span > svg path,
  & > svg path {
    fill: ${({ theme }) => theme.colors.highEmphasisWhite};
  }

  ${({ theme, isPMTButton }) => theme.mediaBreakpointUp[isPMTButton ? 'lg' : 'md']} {
    :not([disabled]):hover {
      background: ${({ theme, color }) =>
        color === ButtonColorKey.Yellow ? theme.colors.darkOrangeHover : theme.colors.blueHover};
  
      & > span > svg,
      & > svg,
      & > span > svg path,
      & > svg path {
        fill: ${({ theme }) => theme.colors.highEmphasisWhite};
      }
    }
  }
`;

export const Button = styled(ButtonLink).attrs({
  as: 'button',
})``;

export default { Button, ButtonLink };

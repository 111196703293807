import getConfig from 'next/config';
import { ArticleTypeName, CompanyType, ContentTypeIds, RouteName, countries } from '@/constants';
import type { IUrlItem, ISlugItem } from '@/interfaces';
import { replacePath } from '@/utils';

const { publicRuntimeConfig } = getConfig();

/**
 * Check if a slug item is valid or not, this is usually used for Preview mode since in Preview mode ths slug (required field) can be empty
 * @param item ISlugItem - { slug: '', type: '' }
 * @param type ContentTypeIds
 * @returns boolean
 * @example
 * checkInvalidISlugItem({ slug: 'news' }, 'tag') => true
 * checkInvalidISlugItem({ slug: '' }, 'article') => true
 * checkInvalidISlugItem({ slug: '', articleType: 'news' }, 'article') => true
 * checkInvalidISlugItem({ slug: 'article-1', articleType: 'news' }, 'article') => false
 */
const checkInvalidISlugItem = (item: ISlugItem, type: ContentTypeIds) => {
  switch (type) {
    case ContentTypeIds.tag:
      return !item.type;
    case ContentTypeIds.article:
      return !item.articleType || !item.slug;
    case ContentTypeIds.event:
    case ContentTypeIds.landingPage:
    case ContentTypeIds.carrier:
    case ContentTypeIds.companyDirectory:
    case ContentTypeIds.market:
      return !item.slug;
    default:
      return false;
  }
};

/**
 * Generate full slug from a slug item
 * @param item ISlugItem - { slug: '', type: '' }
 * @param type ContentTypeIds
 * @returns string
 * @example
 * generateFullSlugFromItem({ slug: '', type: 'news' }, 'tag') => '/news'
 * generateFullSlugFromItem({ slug: 'article-1', articleType: 'news' }, 'article') => '/news/article-1'
 */
const generateFullSlugFromItem = (item: ISlugItem, type: ContentTypeIds) => {
  switch (type) {
    case ContentTypeIds.tag: {
      if (!item.slug) {
        return replacePath(RouteName.ArticleSummary, [item.type]);
      }

      return replacePath(RouteName.Article, [item.type, item.slug]);
    }
    case ContentTypeIds.article:
      return replacePath(RouteName.Article, [item.articleType, item.slug]);
    case ContentTypeIds.event:
      return replacePath(RouteName.Article, [ArticleTypeName.events, item.slug]);
    case ContentTypeIds.landingPage:
      return replacePath(RouteName.ArticleSummary, [item.slug]);
    case ContentTypeIds.companyDirectory:
      return replacePath(RouteName.Carriers, [item.slug]);
    case ContentTypeIds.carrier:
      return replacePath(RouteName.IndividualCarrier, [CompanyType.carriers, item.slug]);
    case ContentTypeIds.market:
      return replacePath(RouteName.Market, [item.slug]);
    default:
      return '';
  }
};

export const convertToSitemapItems = (items: ISlugItem[] | null, type: ContentTypeIds, baseUrl: string) => {
  if (!items) return [];

  return items.reduce<IUrlItem[]>((previousItems, currentItem) => {
    if (checkInvalidISlugItem(currentItem, type)) {
      return previousItems;
    }

    const href = generateFullSlugFromItem(currentItem, type);

    return [
      ...previousItems,
      {
        location: baseUrl + href,
        priority: '0.9',
      },
    ];
  }, []);
};

/**
 * Generate all tradelane sitemap items
 * @param baseUrl the base url of the website, default is publicRuntimeConfig.origin
 * @param limit the maximum number of items to return, default is 100
 * @param skip the number of items to skip, default is 0
 * @returns IUrlItem[]
 * @example
 * generateAllTradelaneSitemapItems('https://www.parcelmonitor.com', 1, 1) => [
 *  {
 *   location: 'https://www.parcelmonitor.com/markets/afghanistan-to-aland-islands',
 *  }
 * ]
 */
export const generateAllTradelaneSitemapItems = (baseUrl = publicRuntimeConfig.origin, limit = 100, skip = 0) => {
  const allTradelaneSitemapItems: IUrlItem[] = [];

  const countryList = Object.keys(countries);
  const countryListLength = countryList.length;
  const startingOriginIndex = Math.floor(skip / countryListLength);
  const startingDestinationIndex = skip - countryListLength * startingOriginIndex;

  for (let i = startingOriginIndex; i < countryListLength; i++) {
    const currentDestinationIndex = i === startingOriginIndex ? startingDestinationIndex : 0;
    for (let j = currentDestinationIndex; j < countryListLength; j++) {
      const slug = `${countryList[i]}-to-${countryList[j]}`;
      const location = replacePath(RouteName.Market, [slug]);

      allTradelaneSitemapItems.push({
        location: baseUrl + location,
        priority: '0.9',
      });

      if (allTradelaneSitemapItems.length >= limit) {
        return allTradelaneSitemapItems;
      }
    }
  }

  return allTradelaneSitemapItems;
};

/**
 * Get trade route sitemap identifier from slug
 * @param slug string
 * @returns number
 * @example
 * getTradeRouteSitemapId('/markets/trade-route-sitemap-12.xml?test=test') => 12
 * getTradeRouteSitemapId('/markets/trade-route-sitemap-12') => 12
 * getTradeRouteSitemapId('/markets/trade-route-sitemap-12-xml') => 0
 */
export const getTradeRouteSitemapId = (slug: string) => {
  if (!slug) return 0;

  const regexMatch = slug.match(/trade-route-sitemap-(\d+)/);
  return regexMatch ? Number.parseInt(regexMatch[1]) : 0;
};

import {
  Container,
  EventMainSection,
  SectionWrapper,
  RichText,
  SocialSharing,
  SectionTitle,
  Carousel,
  RightSection,
  Breadcrumbs,
  PMCAdsense,
} from '@/components';
import { SubSectionWrapper } from '../Article/Article.styles';
import { EventWrapper, Content, LeftSection } from './Event.styles';

import { PMCAdType, type IEvent, type IRightPanel, ResponsiveCollection, type ThumbnailProps } from '@/interfaces';

import { useGlobalState, useResponsive } from '@/hooks';
import EventDetails from '../EventDetails';

interface IEventProps {
  eventContent: IEvent;
  relatedEvents: ThumbnailProps[] | null;
  relatedCampaignEvents: ThumbnailProps[] | null;
  campaignTitle: string | null;
  rightSectionList: Array<IRightPanel>;
}

const Event: React.FC<IEventProps> = ({
  eventContent,
  relatedEvents,
  relatedCampaignEvents,
  campaignTitle,
  rightSectionList,
}) => {
  const { content, title, breadcrumbsTheme } = eventContent;
  const { isMobile } = useResponsive([ResponsiveCollection.Mobile]);

  const { pageUrl } = useGlobalState();

  return (
    <EventWrapper>
      <Container>
        <Breadcrumbs title={title} breadcrumbsTheme={breadcrumbsTheme} />
        <SectionWrapper hasBorderTop={false}>
          <EventMainSection {...eventContent} />
        </SectionWrapper>
        <Content className='custom__richtext'>
          <LeftSection>
            <SectionWrapper>
              {content && <RichText richText={content} />}
              <SocialSharing url={pageUrl} />
            </SectionWrapper>
            {isMobile && eventContent && <EventDetails {...eventContent} />}
            <PMCAdsense variant={PMCAdType.LEFT_SECTION} className='ads-left-section' />
            {!!relatedCampaignEvents?.length && (
              <SubSectionWrapper>
                <SectionTitle>{campaignTitle}</SectionTitle>
                <Carousel carouselData={relatedCampaignEvents} slidesToShow={isMobile ? 2 : 3} />
              </SubSectionWrapper>
            )}
            <SubSectionWrapper>
              <SectionTitle>Other events you may like</SectionTitle>
              <Carousel carouselData={relatedEvents} slidesToShow={isMobile ? 2 : 3} />
            </SubSectionWrapper>
          </LeftSection>
          <RightSection eventDetails={eventContent} rightSectionList={rightSectionList} />
        </Content>
      </Container>
    </EventWrapper>
  );
};

export default Event;

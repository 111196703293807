import type { CodeResponse } from '@react-oauth/google';
import { SignUpSource } from '@/constants';
import { umsFetcher } from '@/fetchers/umsAPI/base.fetchers';
import type { TEmailAccountDTO, TListEmailSyncAccountsDTO } from '@/modules/pmt/interfaces';

type TListEmailSyncAccounts = (args: {
  userUuid: string;
}) => Promise<TListEmailSyncAccountsDTO | undefined>;

export const listEmailSyncAccounts: TListEmailSyncAccounts = async ({ userUuid }) => {
  try {
    const response = await umsFetcher.get('email-accounts', { accessToken: userUuid });
    if (response.ok) {
      return await response.json();
    }
    return Promise.reject(response);
  } catch (err) {
    console.error('Network error');
    throw err;
  }
};

type TToggleEmailSyncAccount = (args: {
  userUuid: string;
  emailAccountId: string;
  realtimeSyncActivated: boolean;
}) => Promise<TEmailAccountDTO | undefined>;

export const toggleEmailSyncAccount: TToggleEmailSyncAccount = async ({
  userUuid,
  emailAccountId,
  realtimeSyncActivated,
}) => {
  try {
    const response = await umsFetcher.patch(
      `email-accounts/${emailAccountId}`,
      { realtime_sync_activated: realtimeSyncActivated },
      { accessToken: userUuid },
    );

    if (response.ok) {
      return await response.json();
    }

    const errorText = await response.text();
    throw JSON.parse(errorText);
  } catch (err) {
    console.error('Network error');
    throw err;
  }
};

type CountryConfig = (args: { countryCode?: string }) => Promise<boolean | undefined>;

export const getCountryConfig: CountryConfig = async ({ countryCode }) => {
  try {
    const res = await umsFetcher.get(`emailsync/country-config/${countryCode}`);
    const data = await res.json();
    return data?.is_allowed;
  } catch (err: any) {
    console.error('get country config ', err);
  }
};

export type AddEmailPayload = {
  code: CodeResponse['code'];
  signUpSource: SignUpSource;
  newsletterOptIn: boolean;
  scope: string;
  country?: string;
  fromPpEmail?: string;
  userId?: string;
};

export const addEmailAccount = async ({
  code,
  signUpSource = SignUpSource.PMT,
  newsletterOptIn = false,
  country,
  fromPpEmail,
  userId,
}: AddEmailPayload) => {
  try {
    const response = await umsFetcher.post(
      'email-accounts/add',
      {
        code,
        sign_up_source: signUpSource,
        newsletter_opt_in: newsletterOptIn,
        country: country,
        from_pp_email: fromPpEmail,
      },
      { accessToken: userId },
    );

    if (response.ok) {
      return await response.json();
    }

    const errorText = await response.text();
    throw JSON.parse(errorText);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

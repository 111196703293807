import Image from 'next/image';
import { useEffect, useMemo } from 'react';
import type { NextPage } from 'next';
import { FieldType, type IForm, type IFormField } from '@/interfaces';
import { FormField, ReCaptchaScript, Spinner } from '@/components';
import { FormFieldContainer, FormSubmitButton, FormWrapper, PromptBox, PromptText } from './Form.styles';
import useBaseForm from './useBaseForm.hook';
import { useGlobalState } from '@/hooks';
import { AboutYouForm } from '@/interfaces';
import { SignUpSource } from '@/constants';
import { renderHTML } from '@/utils';

const Form: NextPage<IForm> = ({
  buttonText,
  submittingButtonText = 'SUBMITTING...',
  promptBoxContent = '{google_linkedin} account already exists. Please sign in with Google/LinkedIn to continue.',
  formFieldList,
  onSuccess,
  isEmailForm,
  truncate,
  isPMTButton,
  signUpSource = SignUpSource.PMC,
  cta,
}) => {
  const {
    register,
    handleSubmit,
    formState,
    formValue,
    setValue,
    watch,
    isSubmitting,
    isSubmitted,
    AuthMethod,
    isOtherAuthMethodExist,
    setIsOtherAuthMethodExist,
  } = useBaseForm({
    cta,
    isEmailForm,
    signUpSource,
    emailField: isEmailForm ? formFieldList[0] : undefined,
  });

  const { userInfo } = useGlobalState();

  const UserInfoAboutYouForm: any = {
    firstName: userInfo?.firstName,
    lastName: userInfo?.lastName,
    companyName: userInfo?.companyName,
  };

  const EmailPromptBox = () => (
    <PromptBox>
      <Image
        src='/images/icons/info.svg'
        alt='Info'
        width={16}
        height={16}
        style={{
          maxWidth: '100%',
          height: 'auto',
          objectFit: 'contain',
          objectPosition: 'top',
        }}
      />
      <PromptText>
        {renderHTML(`${promptBoxContent.replace('{google_linkedin}', `<b>${AuthMethod}</b>`)}`, 'span')}
      </PromptText>
    </PromptBox>
  );

  useEffect(() => {
    if (onSuccess && isSubmitted) isEmailForm ? onSuccess() : onSuccess(formValue);
  }, [isSubmitted]);

  const isDisplayCheckbox = useMemo(() => {
    // PMT: Always show checkbox
    // PMC: Only show checkbox if user inputs on email field
    if (signUpSource === SignUpSource.PMT) return true;

    return !!formState.dirtyFields.email && !isOtherAuthMethodExist;
  }, [formState.dirtyFields.email, isOtherAuthMethodExist]);

  const commonFieldProps = {
    watch,
    setValue,
    register,
    formState,
    isEmailForm,
    setIsOtherAuthMethodExist,
  };

  return (
    <FormWrapper autoComplete='off' onSubmit={handleSubmit}>
      <ReCaptchaScript />
      <FormFieldContainer>
        {formFieldList?.map((field: IFormField, index: number) => {
          if (isEmailForm && field.type === FieldType.Checkbox) {
            return (
              <FormField
                key={index}
                index={index}
                {...commonFieldProps}
                {...field}
                appear={field.appear && isDisplayCheckbox}
                disabled={isSubmitting}
              />
            );
          }

          return (
            <FormField
              key={index}
              index={index}
              {...commonFieldProps}
              value={
                AboutYouForm[field.name] && UserInfoAboutYouForm[AboutYouForm[field.name]]
                  ? UserInfoAboutYouForm[AboutYouForm[field.name]]
                  : ''
              }
              {...field}
            />
          );
        })}
      </FormFieldContainer>
      {isEmailForm && isOtherAuthMethodExist && <EmailPromptBox />}
      <FormSubmitButton
        type='submit'
        truncate={truncate}
        isPMTButton={isPMTButton}
        isSubmitting={isSubmitting}
        disabled={isSubmitting || !formState.isValid || isOtherAuthMethodExist}
      >
        {isSubmitting ? <Spinner text={submittingButtonText} /> : buttonText}
      </FormSubmitButton>
    </FormWrapper>
  );
};

export default Form;

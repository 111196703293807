import type { IPmInsightsRetailer } from '@/interfaces';
import { pmInsights } from './base.fetchers';

type FetchRetailerResponse = {
  links: string;
  page_size: number;
  current_page: number;
  total_page: number;
  count: number;
  results: IPmInsightsRetailer[];
};

type FetchRetailers = (args: {
  page?: number;
  pageSize?: number;
  mapStatus?: 'mapped' | 'unmapped' | 'all';
  accessToken: string;
}) => Promise<FetchRetailerResponse | undefined>;

/**
 * Fetch retailer list with length based on page and pageSize
 * @param {FetchRetailers} args The required arguments to fetch retailer list
 * @param {number} [args.page=1] The current page number
 * @param {number} [args.pageSize=15] The number of results to return per page - maximum is 100, value is -1 for all results
 * @param {RetailerMapStatus} [args.mapStatus=Mapped] The status of the retailer mapping
 * @param {string} [args.accessToken] The bearer token from pp-authenticator
 * @returns retailer list
 */
export const fetchRetailers: FetchRetailers = async ({ page, pageSize, mapStatus = 'mapped', accessToken }) => {
  if (!accessToken) return;

  const ENDPOINT = 'company';
  const parameters = {
    type: 'retailer',
    map_status: mapStatus,
    ...(page && { page: page.toString() }),
    ...(pageSize && { page_size: pageSize.toString() }),
  };

  const queryParams = new URLSearchParams(parameters);

  try {
    const response = await pmInsights.get(ENDPOINT, { accessToken }, queryParams);
    if (!response.ok) throw new Error(response.statusText);

    const data: FetchRetailerResponse = await response.json();

    return data;
  } catch (error) {
    const slug = `${ENDPOINT}?${queryParams}`;
    console.error(slug, error);
  }
};

type FetchRetailerDetails = (args: {
  slug: string;
  accessToken: string;
}) => Promise<IPmInsightsRetailer | undefined>;

export const fetchRetailerDetails: FetchRetailerDetails = async ({ slug, accessToken }) => {
  const endpoint = `company/${slug}`;

  try {
    const response = await pmInsights.get(endpoint, { accessToken });
    if (!response.ok) throw new Error(response.statusText);

    const data = await response.json();
    return data;
  } catch (error) {
    console.error(endpoint, error);
  }
};

type FetchCompetitors = (args: {
  slug: string;
  accessToken: string;
}) => Promise<IPmInsightsRetailer[] | undefined>;

export const fetchCompetitors: FetchCompetitors = async ({ slug, accessToken }) => {
  const endpoint = `company/${slug}/competitors`;

  try {
    const response = await pmInsights.get(endpoint, { accessToken });
    if (!response.ok) throw new Error(response.statusText);

    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error(endpoint, error);
  }
};

import type { Column } from 'react-table';
import type { AcceptedDataTypes, DataPickerContentType } from '@/constants';
import type { IRegistrationWall, SortDirection } from '@/interfaces';

// Used for theme.colors[TableIconColor]
export enum TableIconColor {
  DarkOrange = 'darkOrange',
  Blue = 'blue',
}

export enum UniqueTableChartFieldName {
  logoLink = 'logoLink',
  carrierLogo = 'carrierLogo',
  pParcelFromCountryIso = 'pParcelFromCountryIso', //NOTE: no use case for countryOrigin/countryDestination yet
  pParcelToCountryIso = 'pParcelToCountryIso',
}

export enum BlurredTextDataType {
  shortText = 'short text',
  longText = 'long text',
  number = 'number',
  text = 'text',
}

export enum UniqueTableSectionField {
  order = 'order',
}

export enum CustomTableField {
  LogoCarrierNameLink = 'Logo + Carrier name + Link',
  FlagCountryNameLink = 'Flag + Country name + Link',
  LogoRetailerNameLink = 'Logo + Retailer name + Link',
}

export interface ITableTheme {
  colors: {
    headerCellBackground: string;
    headerCellText: string;
    rowHoverBackground?: string;
  };
}

export interface ITableData {
  [key: string]: React.FC | string | number | boolean | JSX.Element;
}

export enum TableVariant {
  Metrics = 0,
  Section = 1,
}

export interface ITable {
  title?: string;
  footer?: string;
  columns: Column<ITableData>[];
  data: ITableData[];
  child?: Record<string, any>;

  // For Wall rendering
  minimumRecord?: number | null;
  hasData?: boolean;

  // For Table render
  Component?: React.FC<any>;
  variant?: TableVariant;
  tableTheme?: ITableTheme;
  pageSize?: number;
  pseudoPageCount?: number;

  // For Pagination render
  pagination?: IDataPicker['pagination'];
}

export enum DataPickerFieldType {
  Default = 'default',
  Custom = 'custom',
  Premium = 'premium',
  GroupBy = 'groupBy',
  Backend = 'backend',
}

export enum DataPickerUiTemplate {
  Table = 'table',
  Columns = 'columns',
}

export interface IDataPickerField {
  id: string;
  label: string;
  type: DataPickerFieldType;
  dataType?: AcceptedDataTypes;
  show: boolean;
  sortDirection?: SortDirection;
}

export interface IDataPicker {
  contentType: DataPickerContentType;
  fields?: IDataPickerField[];
  uiTemplate: DataPickerUiTemplate;
  groupBy?: string;
  sortDirection?: SortDirection;
  registrationWall?: IRegistrationWall;

  // For API pagination call
  pagination: {
    links: {
      next: string;
      previous: string;
    };
    pageSize: number;
    currentPage: number;
    totalPage: number;
    count: number;
    fetchData?: (pageNumber: number) => void;
  } | null;
}

export interface IDataPickerSection {
  dataPicker: IDataPicker;
  data?: any[];
}

export interface ITableSectionCustomMapper {
  minimumRecord: number;
  pseudoPageCount: number;
}

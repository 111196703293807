import { createContext, useContext, useState, type FC, useMemo, useCallback, useRef } from 'react';
import {
  DEFAULT_FILTER_OPTION,
  DEFAULT_SORT_OPTION,
  ALLOWED_FILTER_TO_SORT,
  SORT_OPTIONS,
  type FILTER_OPTIONS,
  SORT_DIRECTIONS,
  DEFAULT_QUERY_LIMIT,
  DEFAULT_SORT_DIRECTION,
} from '@/modules/pmt/constants';
import { queryBuilder } from '@/modules/pmt/services';
import { useMyParcelsContext } from '@/modules/pmt/context';

interface IShipmentFilterConfig {
  currentFilter: FILTER_OPTIONS;
  currentSort: SORT_OPTIONS;
  currentLimit: number;
  currentSortDirection: SORT_DIRECTIONS;
}

interface IShipmentFiltersContext {
  filterConfig: {
    currentFilter: FILTER_OPTIONS;
    currentSort: SORT_OPTIONS;
    currentLimit: number;
    currentSortDirection: SORT_DIRECTIONS;
  };
  updateCurrentFilter: (filter: FILTER_OPTIONS) => void;
  updateCurrentSort: (sort: SORT_OPTIONS) => void;
  resetFilterAndSort: () => void;
  updateCurrentLimit: (limit: number) => void;
  filterHasChanged: React.MutableRefObject<boolean>;
}

const DEFAULT_FILTER_CONFIG = {
  currentFilter: DEFAULT_FILTER_OPTION,
  currentSort: DEFAULT_SORT_OPTION,
  currentLimit: DEFAULT_QUERY_LIMIT,
  currentSortDirection: DEFAULT_SORT_DIRECTION,
};

const ShipmentFiltersContext = createContext<IShipmentFiltersContext>({
  filterConfig: DEFAULT_FILTER_CONFIG,
  updateCurrentFilter: () => null,
  updateCurrentSort: () => null,
  resetFilterAndSort: () => null,
  updateCurrentLimit: () => null,
  filterHasChanged: { current: false },
});

export const ShipmentFiltersProvider: FC = ({ children }) => {
  const [filterConfig, setFilterConfig] = useState<IShipmentFilterConfig>(DEFAULT_FILTER_CONFIG);
  const filterHasChanged = useRef(false);
  const { focusFirstShipmentRef } = useMyParcelsContext();

  const currentFilter = filterConfig.currentFilter;
  const currentSort = filterConfig.currentSort;

  const updateCurrentFilter = useCallback(
    (filter: FILTER_OPTIONS) => {
      if (filter === currentFilter) return;

      const sortedByEdd = currentSort === SORT_OPTIONS.DELIVERY_DATE;
      const allowedSortOptions = ALLOWED_FILTER_TO_SORT[filter];
      const currentSortAllowed = allowedSortOptions.find((option) => option === currentSort);

      let sortDirection = SORT_DIRECTIONS.DESC;

      // If current sort is EDD, and next sort is not EDD, sort direction is DESC
      // If current sort is EDD, and next sort is EDD, sort direction is ASC
      if (sortedByEdd) {
        sortDirection = currentSortAllowed ? SORT_DIRECTIONS.ASC : SORT_DIRECTIONS.DESC;
      }

      queryBuilder
        .updateFilterBy(filter)
        .updateLimit(DEFAULT_QUERY_LIMIT)
        .updateSortBy(currentSortAllowed ? currentSort : allowedSortOptions[0])
        .updateSortDirection(sortDirection);

      setFilterConfig((prev) => ({
        ...prev,
        currentFilter: filter,
        currentSort: currentSortAllowed ? currentSort : allowedSortOptions[0],
        currentLimit: DEFAULT_QUERY_LIMIT,
      }));

      filterHasChanged.current = true;
      focusFirstShipmentRef.current = true;
    },
    [currentFilter, currentSort, focusFirstShipmentRef],
  );

  const updateCurrentSort = useCallback(
    (sort: SORT_OPTIONS) => {
      if (sort === currentSort) return;
      const sortedByEdd = sort === SORT_OPTIONS.DELIVERY_DATE;
      // We want to get the shipments with EDD that is closest to today, and move onwards
      // asc means from today and onwards, desc means furthest from today and backwards
      const sortDirection = sortedByEdd ? SORT_DIRECTIONS.ASC : SORT_DIRECTIONS.DESC;

      queryBuilder.updateSortBy(sort).updateSortDirection(sortDirection).updateLimit(DEFAULT_QUERY_LIMIT);

      setFilterConfig((prev) => ({
        ...prev,
        currentSort: sort,
        currentSortDirection: sortDirection,
        currentLimit: DEFAULT_QUERY_LIMIT,
      }));

      focusFirstShipmentRef.current = true;
      filterHasChanged.current = true;
    },
    [currentSort, focusFirstShipmentRef],
  );

  const resetFilterAndSort = useCallback(() => {
    if (currentFilter !== DEFAULT_FILTER_OPTION || currentSort !== DEFAULT_SORT_OPTION) {
      queryBuilder
        .updateFilterBy(DEFAULT_FILTER_OPTION)
        .updateSortBy(DEFAULT_SORT_OPTION)
        .updateSortDirection(DEFAULT_SORT_DIRECTION)
        .updateLimit(DEFAULT_QUERY_LIMIT);

      setFilterConfig((prev) => ({
        ...prev,
        currentFilter: DEFAULT_FILTER_OPTION,
        currentSort: DEFAULT_SORT_OPTION,
        currentLimit: DEFAULT_QUERY_LIMIT,
      }));
      focusFirstShipmentRef.current = true;
      filterHasChanged.current = true;
    }
  }, [currentFilter, currentSort, focusFirstShipmentRef]);

  const updateCurrentLimit = useCallback(
    (limit: number) => {
      queryBuilder.updateLimit(limit);
      setFilterConfig((prev) => ({ ...prev, currentLimit: limit }));
      focusFirstShipmentRef.current = false;
      filterHasChanged.current = true;
    },
    [focusFirstShipmentRef],
  );

  const ctxValue = useMemo(
    () => ({
      filterConfig,
      updateCurrentSort,
      updateCurrentFilter,
      updateCurrentLimit,
      resetFilterAndSort,
      filterHasChanged,
    }),
    [filterConfig, updateCurrentSort, updateCurrentFilter, updateCurrentLimit, resetFilterAndSort],
  );

  return <ShipmentFiltersContext.Provider value={ctxValue}>{children}</ShipmentFiltersContext.Provider>;
};

export const useShipmentFiltersContext = () => useContext(ShipmentFiltersContext);

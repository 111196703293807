import { CookieKey, SignUpSource } from '@/constants';
import { updateTrackedShipments } from '@/fetchers';
import { umsFetcher } from '@/fetchers/umsAPI/base.fetchers';
import { useGlobalState } from '@/hooks';
import type { LoginMethod } from '@/interfaces';
import { userInfoModify } from '@/utils';
import type { OriginalUserInfo } from '@/utils/umsDataModify.utils';
import type { CodeResponse } from '@react-oauth/google';
import Cookies from 'js-cookie';

export type AuthPayload = {
  code: CodeResponse['code'];
  signUpSource: SignUpSource;
  newsletterOptIn: boolean;
  scope: string;
  country?: string;
  fromPpEmail?: string;
  userId?: string;
  redirectUri?: string;
  gaClientId?: string;
  gaSessionId?: string;
  eventTimestamp?: string;
  cta?: string;
  authenMethod?: LoginMethod;
};

export const useAuthentication = () => {
  const { setIsLoggedIn, setUserInfo, setFirestoreUuid, pmtCurrentLanguageCode } = useGlobalState();

  /**
   *
   * @param {string} accessToken access token
   * @param {OriginalUserInfo} userInfo user info
   * @returns void
   */
  const PMLogin = (accessToken: string, userInfo: OriginalUserInfo) => {
    Cookies.set(CookieKey.UmsAccessToken, accessToken, {
      expires: 365,
    });
    setIsLoggedIn(true);
    const modifiedUserInfo = userInfoModify(userInfo);
    setUserInfo(modifiedUserInfo);

    if (userInfo) {
      Cookies.set(CookieKey.UserId, userInfo.id, { expires: 365 });
      setFirestoreUuid(userInfo.id);

      updateTrackedShipments({
        language: pmtCurrentLanguageCode,
        accessToken,
      });
    }
  };

  type TAuthDTO = {
    access_token: string;
    medium_origin: string;
    token_type: string;
    user_info?: OriginalUserInfo;
  };

  /**
   *
   * @param {string} code auth code
   * @param {SignUpSource} signUpSource signup source PMT / PMC
   * @param {boolean} newsletterOptIn opt in to newsletter
   * @param {string} country country code
   * @returns {Promise<any>} data
   */
  const auth = async ({
    code,
    signUpSource = SignUpSource.PMT,
    newsletterOptIn = false,
    country,
    fromPpEmail,
    userId,
    redirectUri,
    gaClientId,
    gaSessionId,
    eventTimestamp,
    cta,
    authenMethod,
  }: AuthPayload): Promise<TAuthDTO> => {
    try {
      const response = await umsFetcher.post('emailsync/auth', {
        code,
        sign_up_source: signUpSource,
        newsletter_opt_in: newsletterOptIn,
        country,
        from_pp_email: fromPpEmail,
        user_id: userId,
        redirect_uri: redirectUri,
        ga_event: {
          client_id: gaClientId,
          session_id: gaSessionId,
          event_timestamp: eventTimestamp,
          cta,
          authentication_method: authenMethod,
        },
      });

      if (response.ok) {
        return await response.json();
      }

      const errorText = await response.text();
      throw JSON.parse(errorText);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  return { auth, PMLogin };
};

import { Badge, CenteredSectionTitle, Icons, SectionTitle } from '@/components';
import type React from 'react';
import { Container, ViewMoreButton } from './Header.styles';

interface DataSectionHeaderProps {
  title?: string;
  sectionLabel?: string;
  href?: string;
  centered: boolean;
}

const Header: React.FC<DataSectionHeaderProps> = ({ title, sectionLabel, href, centered }) => {
  const SectionTitleComponent = centered ? CenteredSectionTitle : SectionTitle;

  return (
    <>
      {sectionLabel && (
        <Container>
          <Badge leftIcon={Icons.StarBoldIcon} label={sectionLabel} />
          {title && <ViewMoreButton href={href}>VIEW MORE</ViewMoreButton>}
        </Container>
      )}

      <SectionTitleComponent>{title}</SectionTitleComponent>
    </>
  );
};

export default Header;

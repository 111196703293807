export const wordBubblesModified = <T>(original: T[], rows: number): T[][] => {
  let count = 0;
  const temp: T[][] = [];

  for (let i = 0; i < rows; i++) {
    temp[i] = [];

    for (let j = 0; j < original.length; j++) {
      if (i === j - rows * count) {
        temp[i] = [...temp[i], original[j]];
        count++;
      }
    }

    count = 0;
  }

  return temp;
};

/** Reference: https://github.com/nvh95/react-linkedin-login-oauth2 */
import { useCallback, useEffect, useRef } from 'react';

import { ErrorMessages, MessageField } from '@/constants';

type UseLinkedIn = (args: {
  redirectUri: string;
  clientId: string;
  onSuccess: (code: string) => void;
  onError?: ({
    error,
    errorMessage,
  }: {
    error: string;
    errorMessage: string;
  }) => void;
  state?: string;
  scope?: string;
  closePopupMessage?: string;
}) => {
  linkedInLogin: () => void;
};

const getPopupPositionProperties = ({ width = 600, height = 600 }) => {
  const left = screen.width / 2 - width / 2;
  const top = screen.height / 2 - height / 2;
  return `left=${left},top=${top},width=${width},height=${height}`;
};

const useLinkedIn: UseLinkedIn = ({
  redirectUri,
  clientId,
  onSuccess,
  onError,
  scope = 'r_liteprofile+r_emailaddress',
  closePopupMessage = ErrorMessages[MessageField.CLOSE_POPUP_MESSAGE].error,
}) => {
  const popupRef = useRef<Window | null>();
  const popUpIntervalRef = useRef<number | null>(null);
  const STATE_STRING = '3ddd00484d235450c1fe4c83b9d01387bd71f744';

  const receiveMessage = useCallback(
    (event: MessageEvent) => {
      if (event.origin === window.location.origin) {
        if (event.data.errorMessage && event.data.from === 'LinkedIn') {
          // Prevent CSRF attack by testing state
          if (event.data.state !== STATE_STRING) {
            return;
          }
          onError && onError(event.data);
        } else if (event.data.code && event.data.from === 'LinkedIn') {
          // Prevent CSRF attack by testing state
          if (event.data.state !== STATE_STRING) {
            console.error(ErrorMessages[MessageField.STATE_DOES_MATCH].error);
            return;
          }
          onSuccess && onSuccess(event.data.code);
        }
      }
    },
    [onError, onSuccess],
  );

  useEffect(() => {
    return () => {
      window.removeEventListener('message', receiveMessage, false);

      if (popupRef.current) {
        popupRef.current = null;
      }
      if (popUpIntervalRef.current) {
        window.clearInterval(popUpIntervalRef.current);
        popUpIntervalRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);
    return () => {
      window.removeEventListener('message', receiveMessage, false);
    };
  }, [receiveMessage]);

  const getUrl = () => {
    const scopeParam = `&scope=${encodeURI(scope)}`;
    const linkedInAuthLink = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}${scopeParam}&state=${STATE_STRING}`;
    return linkedInAuthLink;
  };

  const linkedInLogin = () => {
    popupRef.current?.close();
    popupRef.current = window.open(getUrl(), '_blank', getPopupPositionProperties({ width: 600, height: 600 }));

    if (popUpIntervalRef.current) {
      window.clearInterval(popUpIntervalRef.current);
      popUpIntervalRef.current = null;
    }
    popUpIntervalRef.current = window.setInterval(() => {
      try {
        if (popupRef.current && popupRef.current.closed) {
          popUpIntervalRef.current && window.clearInterval(popUpIntervalRef.current);
          popUpIntervalRef.current = null;
          if (onError) {
            onError({
              error: 'user_closed_popup',
              errorMessage: closePopupMessage,
            });
          }
        }
      } catch (error) {
        console.error(error);
        popUpIntervalRef.current && window.clearInterval(popUpIntervalRef.current);
        popUpIntervalRef.current = null;
      }
    }, 1000);
  };

  return {
    linkedInLogin,
  };
};

export default useLinkedIn;

import styled from 'styled-components';
import { WidthType } from '@/interfaces';
import { filledInputStyle } from './components/FormFieldType/Input/Input.styles';
import Label from './components/Label';

export const FormFieldWrapper = styled.div.withConfig({
  shouldForwardProp: (propName, defaultValidatorFn) => propName.toString() !== 'width' && defaultValidatorFn(propName),
})<{ width: WidthType; hasValue?: boolean }>`
  flex: 0 0 100%;
  max-width: 100%;
  flex-direction: column;

  ${({ theme, width }) => {
    const flexBasis = width === WidthType.Full ? '100%' : 'calc(50% - 5px)';

    return `
      ${theme.mediaBreakpointUp.md} {
        flex-basis: ${flexBasis};
        max-width: ${flexBasis};
        width: 100%;
        display: ${width === WidthType.Half ? 'inline-flex' : 'flex'}
      }
    `;
  }}


  ${({ hasValue }) =>
    hasValue &&
    `
    & input ~ ${Label} {
      ${filledInputStyle}
    }
  `}
`;

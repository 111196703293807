import { usePrevious } from '@/hooks';
import { useShipmentsOverviewContext } from '@/modules/pmt/context';
import { ShipmentSearchStatus } from '@/modules/pmt/interfaces';
import { getShipmentSearchStatus } from '@/modules/pmt/utils';
import { useEffect, useMemo, useRef } from 'react';

export const useTriggerAfterFirstParcelLoads = (action: () => void) => {
  const hasBeenTriggered = useRef(false);

  const { shipments } = useShipmentsOverviewContext();

  /**
   * Pending shipments' array has 3 update phases:
   *   1. Empty (when "shipments" are being added).
   *   2. All of "shipments" searched through TW by user.
   *   3. Has been cut down further than 2nd phase because the "shipments" have been searched.
   */
  const currentPendingShipments = useMemo(
    () => shipments.filter((shipment) => getShipmentSearchStatus(shipment.data) === ShipmentSearchStatus.Pending),
    [shipments],
  );

  const previousPendingShipments = usePrevious(currentPendingShipments);

  useEffect(() => {
    if (hasBeenTriggered.current || !previousPendingShipments) return;

    // Check if the 3rd phase occurs
    const isAfterFirstParcelLoads = currentPendingShipments.length < previousPendingShipments.length;

    if (isAfterFirstParcelLoads) {
      hasBeenTriggered.current = true;
      action();
    }
  }, [action, previousPendingShipments, currentPendingShipments.length]);
};

import styled from 'styled-components';

export const BottomLeftGraphic = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 204px;
  height: 94px;
  background: url('/images/mobile-bottom-graphic.png');
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;

  ${({ theme }) => theme.mediaBreakpointUp.lg} {
    width: 786px;
    height: 316px;
    background: url('/images/bottom-graphic.png');
    background-size: contain;
  }
`;

export const TopRightGraphic = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 204px;
  height: 94px;
  background: url('/images/mobile-top-graphic.png');
  background-position: top right;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: -1;

  ${({ theme }) => theme.mediaBreakpointUp.lg} {
    width: 786px;
    height: 316px;
    background: url('/images/top-graphic.png');
  }
`;

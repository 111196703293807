import { type RegistrationWallProps, RegistrationWallTypes } from '@/interfaces';
import FullPaywall from './templates/FullPaywall';
import ButtonPaywall from './templates/ButtonPaywall';
import RegisWall from './templates/RegisWall';
import MiniPaywall from './templates/MiniPaywall';

const RegistrationWall: React.FC<RegistrationWallProps> = (props) => {
  switch (props.type) {
    case RegistrationWallTypes.FullPaywall:
      return <FullPaywall {...props} />;
    case RegistrationWallTypes.ButtonPaywall:
      return <ButtonPaywall {...props} />;
    case RegistrationWallTypes.MiniPaywall:
      return <MiniPaywall {...props} />;
    default:
      return <RegisWall {...props} />;
  }
};

export default RegistrationWall;

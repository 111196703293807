import { TextStyles } from '@/constants';
import { checkIsNumber } from '@/utils';

export const getTextLevel = (variant: string) => {
  switch (variant) {
    case TextStyles['Heading 1']:
      return 'h1';
    case TextStyles['Heading 2']:
      return 'h2';
    case TextStyles['Heading 3']:
      return 'h3';
    case TextStyles['Heading 4']:
      return 'h4';
    case TextStyles['Heading 5']:
      return 'h5';
    case TextStyles['Heading 6']:
      return 'h6';
    default:
      return 'p';
  }
};

export const handleTextWithoutAccents = (text: string) => {
  return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export const truncateTextWithEllipsis = (text: string, maxChar: number): string => {
  if (text.length > maxChar) {
    return text.substring(0, maxChar) + '...';
  }
  return text;
};

export const truncateListString = (arr: string[] | undefined, maxChar: number): string[] | undefined => {
  let stackChar = maxChar;
  arr?.forEach((item: string, index: number) => {
    if (item.length > stackChar) {
      arr[index] = truncateTextWithEllipsis(arr[index], stackChar);
    } else {
      stackChar -= item.length;
    }
  });
  return arr;
};

export const truncateTextInObject = <T extends { [key: string]: any }>(
  array: T[],
  textField: string,
  maxChar: number,
): T[] => {
  let stackChar = maxChar;
  let hasTruncated = false;

  return array?.reduce((acc: T[], item: T) => {
    const fieldText = item[textField];

    if (!fieldText) return acc;

    if (fieldText.length <= stackChar) {
      stackChar -= fieldText.length;
      return [...acc, item];
    }

    if (fieldText.length > stackChar && hasTruncated) {
      return acc;
    }

    if (fieldText.length > stackChar && !hasTruncated) {
      hasTruncated = true;
      return [
        ...acc,
        {
          ...item,
          [textField]: truncateTextWithEllipsis(fieldText, stackChar),
        },
      ];
    }

    return acc;
  }, []);
};

export const styleToString = (style: Record<string, unknown>) => {
  return Object.keys(style).reduce(
    (acc, key) =>
      acc +
      key
        .split(/(?=[A-Z])/)
        .join('-')
        .toLowerCase() +
      ':' +
      style[key] +
      ';',
    '',
  );
};

/**
 * Capitalize the first letter of the string
 * @param text string
 */
export const capitalize = (text: string) => (text && text[0].toUpperCase() + text.slice(1)) || '';

/**
 * Format floating number to a fixed number of decimal places
 * @param number string | number
 * @param decimalPlaces number (default 2)
 * @returns string
 * @example
 * formatFloatingNumber('abc') // abc
 * formatFloatingNumber('1.001') // 1.00
 * formatFloatingNumber('1.001', 3) // 1.001
 * formatFloatingNumber(100.4) // 100.40
 * formatFloatingNumber('1,000.0121') // 1,000.01
 */
export const formatFloatingNumber = (number: string | number, decimalPlaces = 2) => {
  if (isNaN(Number.parseFloat(number as string)) || !checkIsNumber(number.toString())) {
    return number;
  }

  // if number is in scientific notation, return the number (in integer)
  if (number.toString().toLowerCase().includes('e')) {
    return Number(number);
  }

  const [integerPart, decimalPart] = number.toString().split('.');
  const croppedDecimalPart = decimalPart?.substring(0, decimalPlaces) || '';

  if (!croppedDecimalPart.replace(/0+$/, '') && !decimalPlaces) {
    return integerPart;
  }

  // if decimal part is equal to decimalPlaces, return the number
  const existingDecimalPart = decimalPart?.length ? decimalPart : [];
  if (existingDecimalPart.length === decimalPlaces) {
    return number;
  }

  // if decimal part is longer than decimalPlaces, crop the number
  if (existingDecimalPart.length > decimalPlaces) {
    return `${integerPart}.${croppedDecimalPart}`;
  }

  if (!decimalPart) {
    return `${integerPart}.${'0'.repeat(decimalPlaces)}`;
  }

  // if decimal part is shorter than decimalPlaces, add 0s to the end of the number
  const missingZeroCount = decimalPlaces - existingDecimalPart.length;
  const missingZeros = '0'.repeat(missingZeroCount);
  return `${number}${missingZeros}`;
};

/**
 * Extract text before first colon, and after first colon
 * @param text string
 * @returns string
 * @example
 * extractByColon('123:Carrier Name : Name') = ['123', 'Carrier Name : Name']
 */
export const extractByColon = (text: string) => {
  const index = text.indexOf(':');
  return [text.slice(0, index), text.slice(index + 1)];
};

type ExtractTextInsideAndOutsideDoubleCurlyBraces = (text: string) => {
  inside: string[];
  outside: string;
};

/**
 * Extract text inside double curly braces
 * @param text string
 * @returns { inside: string[], outside: string }
 * @example
 * extractTextInsideAndOutsideDoubleCurlyBraces('Text {{New}}') = {
 *  inside: ['New'],
 *  outside: 'Text'
 * }
 * extractTextInsideAndOutsideDoubleCurlyBraces('Text {{New}} Text2 {{New2}}') = {
 *  inside: ['New', 'New2'],
 *  outside: 'Text Text2'
 * }
 * extractTextInsideAndOutsideDoubleCurlyBraces('Text Text2') = {
 *  inside: [],
 *  outside: 'Text Text2'
 * }
 */
export const extractTextInsideAndOutsideDoubleCurlyBraces: ExtractTextInsideAndOutsideDoubleCurlyBraces = (text) => {
  if (!text)
    return {
      inside: [],
      outside: '',
    };

  const regex = /\{\{(.*?)\}\}/g;
  const inside = regex.exec(text) || [];
  const outside = inside.length ? text.replace(/(\{\{.*?\}\})/g, '') : text;
  return {
    inside,
    outside,
  };
};

/**
 * Check if the value is a boolean or a number. If not, wrap it in double quotes.
 * @param value string
 * @returns The original value if it is a boolean or a number, otherwise the value wrapped in double quotes
 * @example
 * checkBooleanAndNumber('true') // true
 * checkBooleanAndNumber('false') // false
 * checkBooleanAndNumber('123') // 123
 * checkBooleanAndNumber('abc') // "abc"
 * checkBooleanAndNumber('360 Lion Express') // "360 Lion Express"
 */
export const checkBooleanAndNumber = (value: string) => {
  const isBoolean = ['true', 'false'].includes(value.toLowerCase());
  if (isBoolean) return value.toLowerCase();

  const isNumber = checkIsNumber(value);
  return isNumber ? value : `"${value}"`;
};

/**
 * Converts text to slug format
 * @param text string
 * @param separator The separator to be used in place of spaces. Default is '-'.
 * @returns The slug format of the text
 * @example
 * convertTextToSlug('United States') // united-states
 * convertTextToSlug('United States', '_') // united_states
 * convertTextToSlug('Côte dIvoire ') // cote-divoire
 * convertTextToSlug('Venezuela (Bolivarian Republic of)') // venezuela-bolivarian-republic-of
 */
export const convertTextToSlug = (text: string, separator = '-') => {
  const textWithoutAccents = text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  const newReg = new RegExp(`[^a-zA-Z${separator}]`, 'g');

  return textWithoutAccents
    .trim()
    .toLowerCase()
    .replace(/\s+/g, separator)
    .replace(newReg, '')
    .replace(new RegExp(`${separator}$`), '');
};

export const replaceSpecialCharacters = (str?: string) => {
  return (str || '').replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
};

/**
 * Add space before capital letters, and trim spaces at start and at end
 * @param str input
 * @returns String with spaces in between the words
 * @example
 * replaceSnakeCaseWithSpace('carrier_name') // "carrier name"
 * replaceSnakeCaseWithSpace('product_categories_') // "product categories"
 */
export const replaceSnakeCaseWithSpace = (str: string) => {
  return str.replace(/_/g, ' ').trim();
};

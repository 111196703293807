export enum CookieKey {
  UmsAccessToken = 'UMS_ACCESS_TOKEN',
  IsReturner = 'IS_RETURNER',
  IsPmtReturner = 'IS_PMT_RETURNER',
  OnPageTimer = 'ON_PAGE_TIMER',
  OnPmtPageTimer = 'ON_PMT_PAGE_TIMER',
  IsFollowing = 'IS_FOLLOWING',
  IsLoggedIn = 'IS_LOGGED_IN',
  BrowserUuid = 'BROWSER_UUID',
  GuestTrackingUuid = 'GUEST_TRACKING_UUID',
  NewBrowserUuid = 'NEW_BROWSER_UUID',
  UserEmail = 'USER_EMAIL',
  FirebaseMessageToken = 'FIREBASE_MESSAGE_TOKEN',
  VerificationStatus = 'VERIFICATION_STATUS',
  PmtVerificationStatus = 'PMT_VERIFICATION_STATUS',
  IsValidVerification = 'IS_VALID_VERIFICATION',
  UserId = 'USER_ID',
  DownloadFileNames = 'DOWNLOAD_FILE_NAMES',
  SyncingESBannerExpiredTime = 'SYNCING_ES_BANNER_EXPIRED_TIME',
  HasGrantedAllAccess = 'HAS_GRANTED_ALL_ACCESS',
  LastValidIpInfoToken = 'LAST_VALID_IP_INFO_TOKEN',
  GA = '_ga',
}

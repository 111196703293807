import { LOCALE_GROUPS } from '@/constants';
import { type Locale, LanguageCode } from '@/interfaces';

type ILanguageFieldsMapping = (languageCode: LanguageCode) => {
  locale: Locale;
  getField: (fieldName: string) => string;
};

type ILanguageCodeObj = {
  [key in LanguageCode]: {
    locale: Locale;
    fieldIndex: number;
  };
};

export const languageFieldsMapping: ILanguageFieldsMapping = (languageCode) => {
  const languageCodeObj = <ILanguageCodeObj>{};

  Object.keys(LOCALE_GROUPS).forEach((locale) => {
    LOCALE_GROUPS[locale as keyof typeof LOCALE_GROUPS].forEach((code, index) => {
      languageCodeObj[code] = {
        locale: locale as Locale,
        fieldIndex: index + 1,
      };
    });
  });

  const { locale, fieldIndex } = languageCodeObj[languageCode];

  return {
    locale,
    getField: (fieldName) => `${fieldName}: ${fieldName}${fieldIndex}`,
  };
};

export const getMasterTranslationLanguageCode = (languageCode: LanguageCode): LanguageCode => {
  switch (languageCode) {
    case LanguageCode.SimplifiedChinese:
      return 'zh' as LanguageCode;
    case LanguageCode.TraditionalChinese:
      return 'tw' as LanguageCode;
    default:
      return languageCode;
  }
};

export const revertMasterTranslationLanguageCode = (languageCode: string | LanguageCode): LanguageCode => {
  switch (languageCode) {
    case 'zh':
      return LanguageCode.SimplifiedChinese;
    case 'tw':
      return LanguageCode.TraditionalChinese;
    default:
      return languageCode as LanguageCode;
  }
};

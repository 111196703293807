import styled from 'styled-components';
import { Typography } from '@/components';
import { TextStyles } from '@/constants';

export const SingleMenuItemTitle = styled(Typography).attrs({
  variant: TextStyles['Nav Item Text'],
  type: 'span',
})<{ isActive: boolean }>`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing['12']};
  position: relative;
  color: ${({ theme }) => theme.colors.mediumEmphasisWhite};
  transition: .3s;

  :hover {
    color: ${({ theme }) => theme.colors.highEmphasisWhite};
  }
`;

import type { BreakpointNames, Breakpoints } from '@/interfaces';

const breakpoints: Breakpoints = {
  xs: '0',
  sm: '375px',
  md: '600px',
  // lg: '905px',
  lg: '800px',
  xl: '1160px',
  xxl: '1440px',
};

const downBreakpoints: Breakpoints = {
  xs: '374.98px',
  sm: '599.98px',
  // md: '904.98px',
  md: '799.98px',
  lg: '1239.98px',
  xl: '1439.98px',
};

const breakpointKeys = {
  xs: 'xs' as BreakpointNames,
  sm: 'sm' as BreakpointNames,
  md: 'md' as BreakpointNames,
  lg: 'lg' as BreakpointNames,
  xl: 'xl' as BreakpointNames,
  xxl: 'xxl' as BreakpointNames,
};

const mediaBreakpointUp = Object.keys(breakpoints).reduce<Breakpoints>((accumulator, currentBreakpointName) => {
  const key = currentBreakpointName as BreakpointNames;

  accumulator[key] = `@media (min-width: ${breakpoints[key]})`;

  return accumulator;
}, {});

const mediaBreakpointDown = Object.keys(downBreakpoints).reduce<Breakpoints>((accumulator, currentBreakpointName) => {
  const key = currentBreakpointName as BreakpointNames;

  accumulator[key] = `@media (max-width: ${downBreakpoints[key]})`;

  return accumulator;
}, {});

const breakpoint = { breakpointKeys, mediaBreakpointUp, mediaBreakpointDown };
export default breakpoint;

import { ButtonSize, ButtonFunctionKey, type RegistrationWallProps } from '@/interfaces';
import { gtmClickLeadsElements } from '@/utils';
import { useGlobalState } from '@/hooks';
import { Flex } from '@/components/Common';

import MainPaywall from '../MainPaywallLayout';
import { CustomButton, SubTitle, WallContainer } from './MiniPaywall.styles';

const FullPaywall: React.FC<RegistrationWallProps> = (props) => {
  const { button, subTitle } = props;
  const { setIsFromJoinUsBtn } = useGlobalState();
  const hasButton = button && !!button.primaryButtonText;
  const isPopUpButton = button.function === ButtonFunctionKey.PopUp;

  const handleLeadButtonClick = () => {
    isPopUpButton && setIsFromJoinUsBtn(true);
    button.buttonEventLabel && gtmClickLeadsElements(button.buttonEventLabel);
  };

  const buttonComponent = (
    <Flex>
      <CustomButton
        size={ButtonSize.Large}
        buttonLink={button.buttonLink}
        function={button.function}
        openInNewTab={button.openInNewTab}
        primaryButtonText={button.primaryButtonText}
        onClick={handleLeadButtonClick}
      />
    </Flex>
  );

  const subTitleComponent = <SubTitle>{subTitle}</SubTitle>;

  return (
    <MainPaywall
      {...props}
      hasNoOuterContainer
      subTitleComponent={subTitleComponent}
      wallContainerComponent={WallContainer}
      buttonComponent={hasButton ? buttonComponent : undefined}
    />
  );
};

export default FullPaywall;

import { ThumbnailTag } from '../Thumbnail/Thumbnail.styles';
import {
  CustomMembershipLabel,
  EventMainSectionWrapper,
  HeroImageAndOverlayWrapper,
  LeftSection,
  Overlay,
  RecommendedLabel,
  RightSection,
  CustomThumbnailTagWrapper,
  Divider,
  DetailItemList,
  DetailItem,
  DetailItemText,
  RegisterButton,
  AdditionalComment,
  Title,
  LeftSectionContent,
  HeroVideo,
  PlayIcon,
  LockIcon,
  BookmarkIcon,
  OverlayContent,
  DetailItemImage,
} from './EventMainSection.styles';

import { TextStyles } from '@/constants';

import type { IEvent } from '@/interfaces';

import { useGlobalState } from '@/hooks';

import { gtmClickEventVideoSignups } from '@/utils';

import useEventMainSection from './useEventMainSection.hooks';

const EventMainSection: React.FC<IEvent> = (props) => {
  const { recommended, title, image, video, tags, address, buttonText, buttonLink = '' } = props;

  const { setIsFromJoinUsBtn, getProperImageUrl } = useGlobalState();
  const {
    isVideoPlaying,
    isLoggedIn,
    isInternalEvent,
    isCompletedEvent,
    hasVideo,
    displayDate,
    displayTime,
    formattedAdditionalComment,
    handleVideoPlay,
    handleVideoTimeUpdate,
  } = useEventMainSection(props);

  const shouldShowPopup = !isLoggedIn && isInternalEvent && isCompletedEvent;
  const shouldShowVideo = isLoggedIn && hasVideo && isInternalEvent && isCompletedEvent;
  const shouldShowVideoOnDesktop = shouldShowVideo && isVideoPlaying;

  const handleRegisterButtonClick = () => {
    setIsFromJoinUsBtn(true);
    gtmClickEventVideoSignups();
  };

  const renderHeroImageAndOverlayWrapper = () => (
    <HeroImageAndOverlayWrapper>
      <Overlay onClick={handleVideoPlay} backgroundUrl={getProperImageUrl(image)}>
        {isInternalEvent && isCompletedEvent && (
          <OverlayContent>
            <CustomMembershipLabel />
            {isLoggedIn ? <PlayIcon /> : <LockIcon />}
          </OverlayContent>
        )}
      </Overlay>
    </HeroImageAndOverlayWrapper>
  );

  const renderRegisterButton = () => (
    <RegisterButton
      href={!isCompletedEvent ? buttonLink : undefined}
      disabled={isCompletedEvent && (isLoggedIn || !isInternalEvent)}
      openInNewTab
      onClick={shouldShowPopup ? () => handleRegisterButtonClick() : undefined}
    >
      {isCompletedEvent ? (!isLoggedIn && isInternalEvent ? 'JOIN THE COMMUNITY' : 'COMPLETED EVENT') : buttonText}
    </RegisterButton>
  );

  const renderVideo = () => {
    if (shouldShowPopup) {
      return <div onClick={handleRegisterButtonClick}>{renderHeroImageAndOverlayWrapper()}</div>;
    }

    if (shouldShowVideoOnDesktop) {
      return (
        <HeroVideo autoPlay controls onTimeUpdate={handleVideoTimeUpdate}>
          <source src={video?.url} type='video/mp4' />
        </HeroVideo>
      );
    }

    if (buttonLink && !shouldShowVideo) {
      return (
        <a target='_blank' rel='noreferrer' href={buttonLink}>
          {renderHeroImageAndOverlayWrapper()}
        </a>
      );
    }

    return renderHeroImageAndOverlayWrapper();
  };

  return (
    <EventMainSectionWrapper>
      <LeftSection>
        <LeftSectionContent>{renderVideo()}</LeftSectionContent>
      </LeftSection>
      <RightSection>
        {recommended && !isCompletedEvent && (
          <RecommendedLabel>
            <BookmarkIcon />
            <span>RECOMMENDED</span>
          </RecommendedLabel>
        )}
        <CustomThumbnailTagWrapper>
          {isCompletedEvent ? (
            <ThumbnailTag noTruncate>COMPLETED EVENT</ThumbnailTag>
          ) : (
            tags?.slice(0, 2)?.map((tag, index) => {
              return (
                <ThumbnailTag noTruncate key={index}>
                  {tag?.title || tag?.marketName}
                </ThumbnailTag>
              );
            })
          )}
        </CustomThumbnailTagWrapper>
        <Title variant={TextStyles['Heading 1']}>{title}</Title>
        <Divider />
        <DetailItemList>
          <DetailItem>
            <DetailItemImage src='/images/icons/date.svg' alt='date' />
            <DetailItemText>{displayDate}</DetailItemText>
          </DetailItem>
          <DetailItem>
            <DetailItemImage src='/images/icons/time.svg' alt='time' />
            <DetailItemText>{displayTime}</DetailItemText>
          </DetailItem>
          <DetailItem>
            <DetailItemImage src='/images/icons/location.svg' alt='location' />
            <DetailItemText>{address}</DetailItemText>
          </DetailItem>
        </DetailItemList>
        {renderRegisterButton()}
        <AdditionalComment>{formattedAdditionalComment}</AdditionalComment>
      </RightSection>
    </EventMainSectionWrapper>
  );
};

export default EventMainSection;

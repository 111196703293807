export const ACCEPTED_TAG_CATEGORY_TYPES: Record<string, string> = {
  ArticleTopic: 'Article Topic',
  EventType: 'Event Type',
  EventTopic: 'Event Topic',
  EventCampaign: 'Event Campaign',
  EventFormat: 'Event Format',
  Market: 'Market',
  Country: 'Country',
  RecommendedEvent: 'Recommended Event',
};

export const ACCEPTED_EVENT_TYPE_TAG_SLUGS: Record<string, string> = {
  Physical: 'physical',
  Hybrid: 'hybrid',
  Virtual: 'virtual',
  InHouse: 'in-house',
};

export const RECORDINGS_EVENT_SLUG = 'recordings';
export const UNPACKING_SERIES_EVENT_SLUG = 'unpacking-series';

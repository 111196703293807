import { Breadcrumbs, Container, Description } from '@/components';
import { TextStyles } from '@/constants';
import { useResponsive } from '@/hooks';
import {
  type IBannerWithInfoBoard,
  type IPageOverviewBanner,
  ResponsiveCollection,
  SectionThemeKey,
} from '@/interfaces';
import { useEffect, useRef } from 'react';
import GraphicImg from '../../GraphicImg';
import {
  BannerButton,
  BannerWrapper,
  BoardWrapper,
  DescriptionWrapper,
  HeadingWrapper,
  Title,
  TitleWrapper,
} from './BannerWithInfoBoard.styles';
import BoardDetailList from './BoardDetailList';
import BoardLogo from './BoardLogo';

const BannerWithInfoBoard: React.FC<IBannerWithInfoBoard> = ({
  breadcrumbs,
  title,
  logo,
  description,
  boardDetails,
  buttons,
  priority,
  image,
  imageMobile,
  theme,
}) => {
  const { isDesktop } = useResponsive([ResponsiveCollection.Desktop]);
  const bannerRef = useRef<HTMLDivElement>(null);
  const boardRef = useRef<HTMLDivElement>(null);
  const hasNotEnoughData = !boardDetails?.length || !description;

  const renderButton = (buttons: IPageOverviewBanner['buttons']) => {
    if (!buttons?.length) return null;
    const button = buttons[0];

    return <BannerButton {...button}>{button.primaryButtonText}</BannerButton>;
  };

  useEffect(() => {
    if (!bannerRef.current || !boardRef.current) return;

    const calculateMarginBottom = () => {
      if (!bannerRef.current || !boardRef.current) return;
      const TOP_BORDER_SIZE = 1;
      const bannerHeight = bannerRef.current.offsetHeight;
      const bannerPaddingTop = Number.parseInt(getComputedStyle(bannerRef.current).paddingTop);
      const boardHeight = boardRef.current.offsetHeight;

      const additionalScreenBasedSpacing = isDesktop ? 60 : 40;
      const marginBottom =
        boardHeight - TOP_BORDER_SIZE - (bannerHeight - bannerPaddingTop) + additionalScreenBasedSpacing;
      bannerRef.current.style.marginBottom = `${marginBottom}px`;
    };

    const resizeObserver = new ResizeObserver(calculateMarginBottom);

    resizeObserver.observe(boardRef.current);

    return () => resizeObserver.disconnect(); // clean up
  }, [isDesktop]);

  if (!title) return null;

  const Header: React.FC = () => (
    <HeadingWrapper>
      <TitleWrapper>
        <BoardLogo src={logo} alt={title || 'logo'} placeholderText={title} />
        <Title>{title}</Title>
      </TitleWrapper>
      {renderButton(buttons)}
    </HeadingWrapper>
  );

  return (
    <BannerWrapper ref={bannerRef} hasNotEnoughData={hasNotEnoughData} sectionTheme={theme || SectionThemeKey.Light}>
      <GraphicImg image={image} imageMobile={imageMobile} priority={priority} />
      <Container style={{ position: 'relative' }}>
        {!!breadcrumbs && <Breadcrumbs {...breadcrumbs} />}
        <BoardWrapper ref={boardRef}>
          <Header />
          {description && (
            <DescriptionWrapper>
              <Description
                description={description}
                lineClamp={{ desktop: 4, mobile: 4 }}
                variant={TextStyles['Body Text Small']}
              />
            </DescriptionWrapper>
          )}
          {boardDetails && <BoardDetailList boardDetails={boardDetails} />}
        </BoardWrapper>
      </Container>
    </BannerWrapper>
  );
};

export default BannerWithInfoBoard;

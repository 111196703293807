import type { IEvent, ITag } from '@/interfaces';
import { SectionWrapper } from '@/components';
import { EventDetailsTitle } from './EventDetails.styles';
import EventDetailsContent from './EventDetailsContent';
import LocationIcon from '../../public/images/icons/location.svg';
import FolderIcon from '../../public/images/icons/folder.svg';
import HomeIcon from '../../public/images/icons/home.svg';
import EmailIcon from '../../public/images/icons/email.svg';

const EventDetails: React.FC<IEvent> = ({ contactEmail, tags, organizer }) => {
  const market = tags?.find((tag: ITag) => tag.category === 'Market');
  const eventTopics = tags?.find((tag: ITag) => tag.category === 'Event Topic');

  return (
    <SectionWrapper>
      <EventDetailsTitle>Event Details</EventDetailsTitle>
      <EventDetailsContent icon={LocationIcon} header='Location' description={market?.title} />
      <EventDetailsContent icon={FolderIcon} header='Category' description={eventTopics?.title} />
      {organizer && (
        <EventDetailsContent
          icon={HomeIcon}
          header='Organizer'
          description={organizer.companyName}
          url={organizer.companyLink}
          urlParsed={organizer.companyLink}
        />
      )}
      <EventDetailsContent
        icon={EmailIcon}
        header='Contact Email'
        url={contactEmail}
        urlParsed={`mailto:?to=${contactEmail}`}
      />
    </SectionWrapper>
  );
};

export default EventDetails;

import type { FC } from 'react';
import { ArrowIcon, TradeLaneDescription, TradeLaneHeader, TradeLaneWrapper } from './TradeLane.styles';
import { getCountryName } from '@/utils';
import { CountryOption } from '@/components';

interface ITradeLane {
  originCountryCode?: string;
  destinationCountryCode?: string;
  showDescription?: boolean;
}

const TradeLane: FC<ITradeLane> = ({ originCountryCode, destinationCountryCode, showDescription }) => {
  if (!originCountryCode || !destinationCountryCode) return null;

  const originCountryName = getCountryName(originCountryCode);
  const destinationCountryName = getCountryName(destinationCountryCode);

  return (
    <TradeLaneWrapper>
      <TradeLaneHeader>
        <CountryOption code={originCountryCode} />
        <ArrowIcon />
        <CountryOption code={destinationCountryCode} />
      </TradeLaneHeader>
      {showDescription && (
        <TradeLaneDescription>
          {`All shipments from ${originCountryName} to ${destinationCountryName}`}
        </TradeLaneDescription>
      )}
    </TradeLaneWrapper>
  );
};

export default TradeLane;

import { type FC, useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { LANGUAGES } from '@/constants';
import { type ILanguage, LanguageCode, ResponsiveCollection } from '@/interfaces';
import { useGlobalState, useResponsive } from '@/hooks';

import {
  ArrowIcon,
  CurrentLanguageItem,
  CurrentLanguageWrapper,
  LanguageIcon,
  LanguageItem,
  LanguageList,
  LanguageWrapper,
} from './Languages.styles';

const getPmtLangHref = (languageCode: string, asPath: string) => {
  const splitPath = asPath.split('/');
  const code = splitPath[1];

  const hasLanguageCodeOnUrl = Object.values<string>(LanguageCode).includes(code);
  const isEnglish = languageCode === LanguageCode.English;

  if (hasLanguageCodeOnUrl && isEnglish) return asPath.replace(`/${code}/`, '/');
  if (hasLanguageCodeOnUrl && !isEnglish) return asPath.replace(`/${code}/`, `/${languageCode}/`);
  if (!hasLanguageCodeOnUrl && !isEnglish) return '/' + languageCode + asPath;
  return asPath;
};

const Languages: FC = () => {
  const { asPath } = useRouter();
  const { isDesktopPMT } = useResponsive([ResponsiveCollection.DesktopPMT]);
  const [show, setShow] = useState<boolean>(false);
  const { pmtCurrentLanguageCode } = useGlobalState();

  const currentLanguage = LANGUAGES.find(({ code }) => code === pmtCurrentLanguageCode) || LANGUAGES[0];

  return (
    <LanguageWrapper>
      <CurrentLanguageWrapper onClick={() => !isDesktopPMT && setShow(!show)}>
        <LanguageIcon />
        <CurrentLanguageItem>{currentLanguage?.name}</CurrentLanguageItem>
        <ArrowIcon show={show} />
      </CurrentLanguageWrapper>
      <LanguageList show={show}>
        {LANGUAGES?.map((language: ILanguage) => (
          <Link key={language.code} href={getPmtLangHref(language.code, asPath)}>
            <LanguageItem>{language.name}</LanguageItem>
          </Link>
        ))}
      </LanguageList>
    </LanguageWrapper>
  );
};

export default Languages;

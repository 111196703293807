import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { truncateTextWithEllipsis } from './text.utils';
import { RichTextEntries, ArticleTypeName } from '@/constants';

import type {
  IArticleProps,
  ISimplifiedArticleProps,
  IArticleWithoutSensitiveDataProps,
  IRichText,
  RssItem,
  RTButton,
  RTLeadGenBanner,
  RssChannel,
} from '@/interfaces';

import { removeManyButtonSensitiveData, removeOneButtonSensitiveData } from './buttonDataModify.utils';
import { removeRichtextNextSiblings } from './richtext.utils';
import { convertISOtoUTC } from './date.utils';

type RemoveOneArticleSensitiveData = (article: IArticleProps) => IArticleWithoutSensitiveDataProps;

export const removeOneArticleSensitiveData: RemoveOneArticleSensitiveData = (article) => {
  const hasLinkedEntry = article.content?.links.entries.block.length;

  if (!hasLinkedEntry) {
    return {
      ...article,
      hasWall: false,
    };
  }

  const { data: modifiedContent, hasEntry } = removeRichtextNextSiblings(
    article.content as IRichText,
    RichTextEntries.RegistrationWall,
  );

  const modifiedLinkedEntriesBlock = modifiedContent.links.entries.block.map((item) => {
    const itemTypename = item?.__typename;

    switch (itemTypename) {
      case RichTextEntries.Button: {
        const button = item as RTButton;

        return removeOneButtonSensitiveData(button);
      }

      case RichTextEntries.LeadGenBanner: {
        const leadGenBanner = item as RTLeadGenBanner;
        const hasButtons = !!leadGenBanner.buttons?.length;

        if (!hasButtons) return leadGenBanner;

        const modifiedButtons = removeManyButtonSensitiveData(leadGenBanner.buttons || []);

        return {
          ...leadGenBanner,
          buttons: modifiedButtons,
        };
      }

      default: {
        return item;
      }
    }
  });

  return {
    ...article,
    content: {
      ...modifiedContent,
      links: {
        ...modifiedContent.links,
        entries: {
          block: modifiedLinkedEntriesBlock,
        },
      },
    },
    hasWall: hasEntry,
  } as IArticleWithoutSensitiveDataProps;
};

type ConvertToRssData = (args: {
  articles: ISimplifiedArticleProps[];
  baseUrl: string;
  channel: RssChannel;
}) => RssItem[];

export const convertToRssData: ConvertToRssData = ({ articles, baseUrl, channel }) => {
  return articles?.map((article) => {
    const richText = article.content ? documentToHtmlString(article?.content.json) : '';

    return {
      title: article.title,
      link: baseUrl + `/${ArticleTypeName.insights}/` + article.slug,
      description: article.seo?.description,
      imageUrl: article.image?.url,
      guid: article.sys.id,
      pubDate: article.date && convertISOtoUTC(article.date),
      content: richText,
      source: {
        url: channel.url,
        title: channel.title,
      },
    };
  });
};

export const articlesRssXml = (articles: RssItem[] | null) => {
  let rssItemsXml = '';
  articles?.forEach((item) => {
    rssItemsXml += `
      <item>
        <title><![CDATA[${item.title}]]></title>
        <link><![CDATA[${item.link}]]></link>
        <pubDate>${item.pubDate}</pubDate>
        <guid isPermaLink="false">${item.guid}</guid>
        <description><![CDATA[${item.description}]]></description>
        <content:encoded>
          <![CDATA[<img src="${item.imageUrl}" />${item.content}]]>
        </content:encoded>
    </item>`;
  });

  return { rssItemsXml };
};

// https://developers.google.com/search/docs/data-types/article
export const getArticleStructuredData = (article: IArticleProps) => {
  const paywalledContent = {
    isAccessibleForFree: 'False',
    hasPart: {
      '@type': 'WebPageElement',
      isAccessibleForFree: 'False',
      cssSelector: '.paywall',
    },
  };

  const articleStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'NewsArticle',
    author: [
      {
        '@type': 'Organization',
        name: 'Parcel Monitor',
        url: 'https://www.parcelmonitor.com',
      },
    ],
    dateModified: article.sys.publishedAt,
    datePublished: article.date,
    headline: article.title && truncateTextWithEllipsis(article.title, 110),
    image: article?.image?.url,
  };

  return (article as IArticleWithoutSensitiveDataProps).hasWall
    ? { ...articleStructuredData, ...paywalledContent }
    : articleStructuredData;
};

import styled from 'styled-components';

export const Container = styled.div`
  display: inline-flex;
  align-items: center;

  height: 28px;
  width: fit-content;

  border-radius: 100px;
  gap: 0 ${({ theme }) => theme.spacing[4]};
  padding: ${({ theme }) => `${theme.spacing[4]} ${theme.spacing[8]}`};

  background-color: ${({ theme }) => theme.colors.darkOrange};
  color: ${({ theme }) => theme.colors.highEmphasisWhite};
`;

export const IconContainer = styled.div`
  display: inline-flex;

  &,
  svg {
    width: 16px;
    height: 16px;
  }

  path {
    fill: ${({ theme }) => theme.colors.highEmphasisWhite};
  }
`;

import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { MasterTranslation } from '@/constants';
import { useGlobalState } from '@/hooks';
import { renderHTML, getCookieBannerClosed, setCookieBannerClosed } from '@/utils';
import {
  CloseButton,
  CloseButtonContainer,
  CookieBannerContainer,
  CookieBannerInnerContainer,
} from './CookieBanner.styles';

const CookieBanner: React.FC = () => {
  const [isClosed, setCloseState] = useState(false);
  const { t } = useGlobalState();

  const closeCookieBanner = () => {
    setCookieBannerClosed();
    if (getCookieBannerClosed()) setCloseState(true);
  };

  const cookieBannerText = () => {
    let cookieBanner = t(MasterTranslation.CookieBanner);

    cookieBanner = cookieBanner
      .replace(/\r?\n/g, '<br />')
      .replace(/{{(.*?)}}/g, '<a target="_blank" href="/privacy" rel="noreferrer">$1</a>');
    return renderHTML(cookieBanner);
  };

  useEffect(() => {
    if ((window as any).clarity && !Cookies.get('_clck')) {
      (window as any).clarity('consent');
    }
  }, []);

  if (isClosed) return null;

  return (
    <CookieBannerContainer>
      <CookieBannerInnerContainer>
        <div>{cookieBannerText()}</div>
        <CloseButtonContainer onClick={closeCookieBanner}>
          <CloseButton />
        </CloseButtonContainer>
      </CookieBannerInnerContainer>
    </CookieBannerContainer>
  );
};

export default CookieBanner;

import { PageType } from '@/constants';
import { utils_getLandingPageThumbnailSectionData } from '@/fetchers/utils';
import type { IEventList, IHighlightSection, IThumbnailSection, ThumbnailProps } from '@/interfaces';

type GetThumbnailSectionData = (args: {
  thumbnailSection: IThumbnailSection;
  highlightSection: IHighlightSection;
  allArticles: ThumbnailProps[];
  allEvents: ThumbnailProps[];
  pageType: PageType;
}) => IThumbnailSection;

const THUMBNAIL_ITEMS_PER_PAGE = 10;

export const getThumbnailSectionData: GetThumbnailSectionData = ({
  thumbnailSection,
  highlightSection,
  allArticles,
  allEvents,
  pageType = PageType.LandingPage,
}) => {
  const foundThumbnailSection = { ...thumbnailSection };
  const foundHighlightSection = highlightSection ? { ...highlightSection } : null;
  foundThumbnailSection.latestData = utils_getLandingPageThumbnailSectionData({
    foundThumbnailSection,
    foundHighlightSection,
    allThumbnailList: allArticles,
    allEventList: allEvents,
  });

  const totalThumbnails = foundThumbnailSection.latestData.length;
  const totalPages = Math.ceil(totalThumbnails / THUMBNAIL_ITEMS_PER_PAGE);

  foundThumbnailSection.pageInfo = {
    itemsPerPage: THUMBNAIL_ITEMS_PER_PAGE,
    totalPages,
  };

  foundThumbnailSection.latestData = foundThumbnailSection.latestData.slice(0, THUMBNAIL_ITEMS_PER_PAGE);
  foundThumbnailSection.foundHighlightSection = foundHighlightSection || null;
  foundThumbnailSection.pageType = pageType;

  return foundThumbnailSection;
};

type FilterOutThumbnailSectionData = (args: {
  thumbnailSection: IThumbnailSection;
  foundEventListSection: IEventList;
}) => IThumbnailSection;

export const filterOutThumbnailSectionData: FilterOutThumbnailSectionData = ({
  thumbnailSection,
  foundEventListSection,
}) => {
  const foundThumbnailSection = { ...thumbnailSection };
  foundThumbnailSection.latestData = foundThumbnailSection.latestData?.filter(
    (thumbnailItem) =>
      !foundEventListSection?.eventList.find((featuredEventItem) => featuredEventItem.sys.id === thumbnailItem.sys.id),
  );

  foundThumbnailSection.foundEventListSection = foundEventListSection;

  return foundThumbnailSection;
};

export const thumbnailSectionDataModify = {
  getThumbnailSectionData,
  filterOutThumbnailSectionData,
};

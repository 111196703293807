export const UNKNOWN_CARRIER_ID = 'unknwn';
export const UNDEFINED_CARRIER_ID = 'undefined_carrier_id';
export const EXCLUDED_CARRIER_IDS = ['katodo'];

// Styling constants
export const HEADER_HEIGHT = 106; // not in MyParcels, but used for styling
export const SHIPMENT_FILTER_HEIGHT = 66;
export const SHIPMENT_FILTER_PADDING = 20;
export const SHIPMENT_CARD_MARGIN_BOTTOM = 8;
export const SHIPMENT_FILTER_FULL_HEIGHT = SHIPMENT_FILTER_HEIGHT + SHIPMENT_FILTER_PADDING * 2;

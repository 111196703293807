import { type IPanel, LoginMethod, OnboardingStepName } from '@/interfaces';
import { Form } from '@/components';
import { useEffect } from 'react';
import { useGlobalState } from '@/hooks';
import { gtmBasicDetailsSuccess, userInfoModify } from '@/utils';

const AboutYou: React.FC<IPanel> = ({ onStepSuccess, form }) => {
  const { userInfo, currentStep, setCurrentStep, setUserInfo, setIsNextButtonClicked, isLoggedIn } = useGlobalState();
  const isBasicDetails = !!userInfo?.firstName && !!userInfo?.lastName && !!userInfo?.companyName;

  const onAboutYouFormSuccess = (formValue: any) => {
    setUserInfo({
      ...userInfo, //in case userInfo has other coded data like firstLetter
      ...userInfoModify(formValue),
    });

    if (!onStepSuccess) {
      return;
    }

    (userInfo?.authenticationMethod !== LoginMethod.Email || isLoggedIn) && onStepSuccess();
    setIsNextButtonClicked(true);
    gtmBasicDetailsSuccess();
  };

  useEffect(() => {
    if (currentStep === OnboardingStepName.aboutYou && isBasicDetails) {
      setCurrentStep(OnboardingStepName.thankYou);
    }
  }, []);

  if (!form || !form.formFieldList?.length) return null;

  return <Form {...form} onSuccess={onAboutYouFormSuccess} />;
};

export default AboutYou;

import getConfig from 'next/config';
import { PmAnalyticsTableMapping } from '@/constants';
import {
  capitalize,
  sendSentryError,
  checkBooleanAndNumber,
  customChartDataMapper,
  getPreviousTimeframe,
} from '@/utils';
import {
  TimeKey,
  CustomQueryValues,
  type IPmAnalyticsQuery,
  type IPmAnalyticsQueryInput,
  type IPmAnalyticsQueryOutput,
  KeysCanBeCompared,
  PmAnalyticsOperator,
  PmAnalyticsQueryTable,
} from '@/interfaces';
import { baseFetcher } from '../base.fetchers';

const { serverRuntimeConfig } = getConfig();
export const BASE_URL = serverRuntimeConfig.pmAnalyticsUrl + '/api';
const pmAnalyticsFetcher = baseFetcher(BASE_URL);

const DEFAULT_ORDER_BY = 'cCarrierId';

const joinCompareColumns = (a: string, b: string) => {
  return [a, b].filter(Boolean).join('__AND__');
};

const inputMapping = (queryInputs: IPmAnalyticsQueryInput[]): string[] => {
  let isDescending = true;

  const removedDuplicateItems = queryInputs.reduce(
    (result, queryInput) => {
      if (!queryInput.value) return result;

      const converted: IPmAnalyticsQueryInput = { ...queryInput };

      switch (queryInput.name) {
        case 'limit':
          converted.name = 'first';
          break;

        case 'sortDirection':
          isDescending = queryInput.value === 'DESC';

          converted.name = 'orderBy';
          converted.value = isDescending ? `-${DEFAULT_ORDER_BY}` : DEFAULT_ORDER_BY;
          break;

        case 'orderBy':
          converted.value = isDescending ? `-${queryInput.value}` : queryInput.value;
          break;
        case KeysCanBeCompared.quarter:
          if (queryInput.value === CustomQueryValues.previousQuarter) {
            const { text } = getPreviousTimeframe({ pivot: TimeKey.quarter });
            converted.value = text;
          }
          break;
        default:
          break;
      }

      const { name, operator, value } = converted;

      if (operator && Object.values<string>(KeysCanBeCompared).includes(value)) {
        const prevCompareColumns = result.compareColumns;
        const currCompareColumns = `${name}__${PmAnalyticsOperator[operator]}__${value}`;

        const compareColumns = joinCompareColumns(prevCompareColumns, currCompareColumns);

        return { ...result, compareColumns };
      }

      if (!operator || operator === 'equals') {
        return { ...result, [name]: value };
      }

      return {
        ...result,
        [name + `_${capitalize(PmAnalyticsOperator[operator])}`]: value,
      };
    },
    <Record<string, string>>{},
  );

  const result = Object.entries(removedDuplicateItems).map(([name, value]) => {
    return `${name}:${checkBooleanAndNumber(value)}`;
  });

  return result;
};

const outputMapping = (queryOutputs: IPmAnalyticsQueryOutput[]): string[] => {
  return queryOutputs.map(({ name }) => name);
};

const tableMapping = (queryTable = PmAnalyticsQueryTable.Carrier) => {
  return PmAnalyticsTableMapping[queryTable];
};

type CallPmAnalytics = (arg: {
  query: IPmAnalyticsQuery;
  orderBy?: string;
  accessToken?: string;
}) => Promise<Record<string, any>[] | null>;

type PmAnalyticsResponse = {
  data: {
    [collection: string]: {
      edges: {
        node: Record<string, any>;
      }[];
    };
  };
};

const callPmAnalytics: CallPmAnalytics = async ({ query, accessToken }) => {
  if (!accessToken) return null;

  try {
    const { table, input, output } = query;
    const { time, endpoint, collection } = tableMapping(table);
    // Do not send "Locked output" to pm-analytics because it is redundant.
    // "Locked output" is used for the frontend to display blur effect on the locked columns.
    const { lockedOutput, unlockedOutput } = _separateLockedAndUnlockedOutput(output);
    const mappedInput = inputMapping(input);
    const mappedOutput = outputMapping(unlockedOutput);
    const queryString = `{${collection}(${mappedInput}){edges{node{${mappedOutput},${time}}}}}`;

    const res = await pmAnalyticsFetcher.post(endpoint, { query: queryString }, { accessToken });

    if (!res.ok) return null;

    const { data }: PmAnalyticsResponse = await res.json();

    if (!data || !data[collection]) return null;

    const dummyNodes = _constructDummyColumns(lockedOutput);
    const dataNodes = data[collection].edges
      .map(({ node }) => {
        const customMappedNode = customChartDataMapper(node);
        return Object.assign({}, customMappedNode, dummyNodes);
      })
      .filter(Boolean);

    return dataNodes;
  } catch (err) {
    sendSentryError(err, { query });
    return null;
  }
};

const _constructDummyColumns = (outputs: IPmAnalyticsQueryOutput[]) => {
  const dummyNodes: Record<string, any> = {};

  outputs.forEach((output) => {
    dummyNodes[output.name] = '';
  });

  return dummyNodes;
};

const _separateLockedAndUnlockedOutput = (outputs: IPmAnalyticsQueryOutput[]) => {
  const lockedOutput: IPmAnalyticsQueryOutput[] = [];
  const unlockedOutput: IPmAnalyticsQueryOutput[] = [];

  outputs.forEach((output) => {
    if (output.locked) {
      lockedOutput.push(output);
    } else {
      unlockedOutput.push(output);
    }
  });

  return {
    lockedOutput,
    unlockedOutput,
  };
};

export default callPmAnalytics;

import { Paragraph } from '@/components/RichText/RichText.styles';
import styled from 'styled-components';
import { BreadcrumbsSectionContainer } from '../Breadcrumbs/Breadcrumbs.styles';

export const EventWrapper = styled.div`
    ${BreadcrumbsSectionContainer} {
    margin: ${({ theme }) => `${theme.spacing[20]} ${theme.spacing[20]} 0 ${theme.spacing[20]}`};
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    padding-top: ${({ theme }) => theme.spacing[40]};
    padding-bottom: ${({ theme }) => theme.spacing[40]};

    ${BreadcrumbsSectionContainer} {
      margin: ${({ theme }) => `0 0 ${theme.spacing[16]} 0`};
    }
  }
`;

export const Content = styled.div`
  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: flex;
    justify-content: space-between;
  }

  &.custom__richtext ${Paragraph} {
    a {
      display: inline;
    }
  }

  &.custom__richtext h1 a,
  &.custom__richtext h2 a,
  &.custom__richtext h3 a,
  &.custom__richtext h4 a,
  &.custom__richtext h5 a,
  &.custom__richtext h6 a {
    font-weight: inherit;
    font-size: inherit;
  }
`;

export const LeftSection = styled.div`
  ${({ theme }) => theme.mediaBreakpointUp.md} {
    flex: 0 0 736px;
    max-width: 736px;
  }
`;

import { ACCEPTED_EVENT_TYPE_TAG_SLUGS, ACCEPTED_TAG_CATEGORY_TYPES } from '@/constants';
import {
  type IHighlightSection,
  type ITag,
  type IThumbnailCarousel,
  type IThumbnailSection,
  type IWrapper,
  SectionItemType,
  type ThumbnailProps,
} from '@/interfaces';
import { modifyUrlQuery, sortDatesByClosest } from '@/utils';

export const modifyRelatedCampaignEvents = (allEvents: ThumbnailProps[], currentEventSysId: string) => {
  const curDate = new Date();

  const withoutCurrentEvent = allEvents?.filter((event: ThumbnailProps) => event.sys.id !== currentEventSysId) || [];

  const upComingEvents = withoutCurrentEvent.filter((item) => item.startDate && new Date(item.startDate) > curDate);
  const onGoingEvents = withoutCurrentEvent.filter(
    (item) => item.startDate && item.endDate && new Date(item.endDate) > curDate && new Date(item.startDate) <= curDate,
  );
  const completedEvents = withoutCurrentEvent.filter((item) => item.endDate && new Date(item.endDate) <= curDate);

  const relatedCampaignEvents = [
    ...sortDatesByClosest(upComingEvents),
    ...sortDatesByClosest(onGoingEvents),
    ...sortDatesByClosest(completedEvents),
  ];

  return relatedCampaignEvents;
};

export const findInternalEvent = (tagList: ITag[]) =>
  tagList.find(
    (tag: ITag) =>
      tag.category === ACCEPTED_TAG_CATEGORY_TYPES.EventType && tag.slug === ACCEPTED_EVENT_TYPE_TAG_SLUGS.InHouse,
  );

export const findEventCampaign = (tagList: ITag[]) =>
  tagList.find((tag: ITag) => tag.category === ACCEPTED_TAG_CATEGORY_TYPES.EventCampaign);

export const sortEventsByClosestAndUpcoming = (events: ThumbnailProps[]) => {
  const curDate = new Date();
  const upComingEvents = events.filter((item) => item.startDate && new Date(item.startDate) > curDate);
  const onGoingEvents = events.filter(
    (item) => item.endDate && item.startDate && new Date(item.endDate) > curDate && new Date(item.startDate) <= curDate,
  );
  const completedEvents = events.filter((item) => item.endDate && new Date(item.endDate) <= curDate);

  return [
    ...sortDatesByClosest(upComingEvents),
    ...sortDatesByClosest(onGoingEvents),
    ...sortDatesByClosest(completedEvents),
  ];
};

type EventThumbnailsWithTags = {
  withCorrectTags: ThumbnailProps[];
  withoutCorrectTags: ThumbnailProps[];
};

export const modifyRelatedEvents = (allEvents: ThumbnailProps[], tagList: Array<string>) => {
  // Logic 1: Get all events match by first 2 tags
  const { withCorrectTags, withoutCorrectTags } = allEvents.reduce<EventThumbnailsWithTags>(
    (resultEvents, currentEvents) => {
      const hasCorrectTags = currentEvents?.tags?.slice(0, 2).every((tag, index) => tag?.slug === tagList[index]);
      if (hasCorrectTags)
        return {
          ...resultEvents,
          withCorrectTags: [...resultEvents.withCorrectTags, currentEvents],
        };
      return {
        ...resultEvents,
        withoutCorrectTags: [...resultEvents.withoutCorrectTags, currentEvents],
      };
    },
    { withCorrectTags: [], withoutCorrectTags: [] },
  );

  // Logic 2a: All the correct events are sorted from not-completed to completed
  // Logic 2b: The not-completed and completed events must be closest to Current Date accordingly
  const sortedWithCorrectTags = sortEventsByClosestAndUpcoming(withCorrectTags);

  // Logic 3a: All the other events are sorted from not-completed to completed
  // Logic 3b: The not-completed and completed events must be closest to Current Date accordingly
  const sortedWithoutCorrectTags = sortEventsByClosestAndUpcoming(withoutCorrectTags);

  // Logic 4: Merge two list of correct and incorrect
  const allEventList = sortedWithCorrectTags.concat(sortedWithoutCorrectTags);
  return allEventList;
};

type NeedEventOrArticleDataArg = (args: {
  foundHighlightSection?: IHighlightSection;
  foundThumbnailSection?: IThumbnailSection;
  foundThumbnailCarousel?: IThumbnailCarousel[];
  foundWrapperUsingThumbnailSection?: IWrapper[];
}) => {
  hasEvents: boolean;
  hasArticles: boolean;
};

export const needEventOrArticleData: NeedEventOrArticleDataArg = ({
  foundHighlightSection,
  foundThumbnailSection,
  foundThumbnailCarousel,
  foundWrapperUsingThumbnailSection,
}) => {
  let hasEvents = false;
  let hasArticles = !!foundHighlightSection;

  if (foundThumbnailCarousel) {
    foundThumbnailCarousel.forEach((item: IThumbnailCarousel) => {
      item.itemType === SectionItemType.Event ? (hasEvents ||= true) : (hasArticles ||= true);
    });
  }
  if (foundThumbnailSection) {
    const isEventType = foundThumbnailSection.itemType === SectionItemType.Event;
    isEventType ? (hasEvents ||= true) : (hasArticles ||= true);
  }
  if (foundWrapperUsingThumbnailSection) {
    foundWrapperUsingThumbnailSection.forEach((item: any) => {
      const isEventType = item.entryList?.items?.[0]?.itemType === SectionItemType.Event;
      isEventType ? (hasEvents ||= true) : (hasArticles ||= true);
    });
  }

  return { hasEvents, hasArticles };
};

export type GetProperImageUrl = (image?: {
  contentType?: string | undefined;
  url?: string | undefined;
  [key: string]: any;
}) => string;

type CreateGetProperImageUrl = (isWebpSupported: boolean) => GetProperImageUrl;

const WEBP_CONTENT_TYPE = 'image/webp';
const SVG_CONTENT_TYPE = 'image/svg+xml';

export const createGetProperImageUrl: CreateGetProperImageUrl = (isWebpSupported) => (image) => {
  if (!image?.url) return '';

  const { contentType, url } = image;

  if (contentType === SVG_CONTENT_TYPE) {
    const hasQuery = url.includes('?');

    return hasQuery ? (url.split('?').shift() as string) : url;
  }

  if (isWebpSupported) {
    if (contentType === WEBP_CONTENT_TYPE) {
      return url;
    }

    return modifyUrlQuery(url, (prevQuery) => {
      return {
        ...(prevQuery as Record<string, any>),
        fm: 'webp',
      };
    });
  }

  if (!contentType || contentType === WEBP_CONTENT_TYPE) {
    return modifyUrlQuery(url, (prevQuery) => {
      return {
        ...(prevQuery as Record<string, any>),
        fm: 'png',
      };
    });
  }

  return url;
};

import { DEFAULT_LANGUAGE } from '@/constants';
import { type IHeader, LanguageCode } from '@/interfaces';
import { languageFieldsMapping, translationDataModify, headerDataModify } from '@/utils';
import callContentful from './base.fetchers';

type GetHeader = (args: {
  isPreviewMode: boolean;
  languageCode: LanguageCode;
  slug: string;
}) => Promise<IHeader>;

const getHeader: GetHeader = async ({ isPreviewMode, languageCode = LanguageCode.English, slug }) => {
  const mappedLanguageFields = languageFieldsMapping(languageCode);

  const query = `
    {
      defaultHeaderCollection: headerCollection(
        preview: ${isPreviewMode}
        locale: "${DEFAULT_LANGUAGE}"
        limit: 1
      ) {
        items {
          itemList
          mainText: mainText1 
          subText: subText1
          button: button1
          reportsPopup
        }
      }
      headerCollection(
        preview: ${isPreviewMode},
        locale: "${mappedLanguageFields.locale}"
        limit: 1,
      ) {
        items {
          itemList
          ${mappedLanguageFields.getField('mainText')}
          ${mappedLanguageFields.getField('subText')}
          ${mappedLanguageFields.getField('button')}
          reportsPopup
        }
      }
    }
    `;

  const response = await callContentful(query, isPreviewMode, slug, 'header.fetchers.getHeader');
  const defaultHeaderData = response?.data?.defaultHeaderCollection?.items?.filter(Boolean)[0] || null;
  const headerData = response?.data?.headerCollection?.items?.filter(Boolean)[0] || null;

  const data = translationDataModify(defaultHeaderData, headerData);
  return headerDataModify(data);
};

export { getHeader };

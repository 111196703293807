import ReactCountryFlag from 'react-country-flag';
import styled from 'styled-components';
import { TextStyles } from '@/constants';
import { Typography } from '@/components';
import { Flex } from '@/components/Common';

export const CountryFlag = styled(ReactCountryFlag)`
  line-height: 21px !important;
  margin-right: ${({ theme }) => theme.spacing[8]};
`;

export const CountryCode = styled(Typography).attrs({
  variant: TextStyles['Heading 3'],
})``;

export const CountryCodeWrapper = styled(Flex)`
  a {
    overflow: hidden;
  }

  a,
  a:active,
  a:focus,
  a:visited {
    color: ${({ theme }) => theme.colors.highEmphasisBlack};
  }

  a:hover {
    color: ${({ theme }) => theme.colors.blueHover};
    cursor: pointer;
    text-decoration: underline;
  }
`;

import styled from 'styled-components';
import { Typography } from '@/components';

export const SubTitle = styled(Typography)<{
  $unlimitedLines: boolean;
  $lineClamp: { desktop: number; mobile: number };
}>`
  color: ${({ theme }) => theme.colors.mediumEmphasisBlack};
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  word-break: break-word;
  -webkit-line-clamp: ${({ $unlimitedLines, $lineClamp }) => !$unlimitedLines && $lineClamp.mobile};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    -webkit-line-clamp: ${({ $unlimitedLines, $lineClamp }) => !$unlimitedLines && $lineClamp.desktop};
  }
`;

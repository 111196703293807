import { type EffectCallback, type DependencyList, useRef, useEffect } from 'react';

type UseSecondEffect = (effect: EffectCallback, deps?: DependencyList) => void;

const useSecondEffect: UseSecondEffect = (effect, deps) => {
  const isFirstRun = useRef(true);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;

      return;
    }

    effect();
  }, deps);
};

export default useSecondEffect;

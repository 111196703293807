import { useRecaptcha } from '@/modules/pmt/hooks';
import { createContext, useContext, useMemo } from 'react';

interface IRecaptchaContext {
  executeRecaptchaV3: (action?: string) => Promise<string>;
  verifyRecaptchaV3: (token: string) => Promise<number | undefined>;
  isV3Loaded: boolean;
  checkWithReCaptchaV3: ({ action, callback }: { action: string; callback: () => void }) => Promise<void>;
}

const RecaptchaContext = createContext<IRecaptchaContext>({
  executeRecaptchaV3: async () => new Promise(() => ''),
  verifyRecaptchaV3: async () => new Promise(() => 0),
  isV3Loaded: false,
  checkWithReCaptchaV3: async () => {},
});

export const RecaptchaProvider: React.FC = ({ children }) => {
  const { executeRecaptchaV3, verifyRecaptchaV3, isV3Loaded, checkWithReCaptchaV3 } = useRecaptcha();

  const value = useMemo(
    () => ({
      executeRecaptchaV3,
      verifyRecaptchaV3,
      isV3Loaded,
      checkWithReCaptchaV3,
    }),
    [executeRecaptchaV3, verifyRecaptchaV3, isV3Loaded, checkWithReCaptchaV3],
  );

  return <RecaptchaContext.Provider value={value}>{children}</RecaptchaContext.Provider>;
};

export const useRecaptchaContext = () => useContext(RecaptchaContext);

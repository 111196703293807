import type React from 'react';
import type { IMenuItem } from '@/interfaces';
import {
  Arrow,
  DropdownContainer,
  DropdownTitleWrapper,
  DropdownMenuItemTitle,
  DropdownMenuItemWrapper,
} from './DropdownMenuItem.styles';
import { TextStyles } from '@/constants';
import { AnnouncementLabel } from '../../Header.styles';
import SecondaryMenuList from './SecondaryMenuList';
import Description from './Description';

const DropdownMenuItem: React.FC<{ data: IMenuItem }> = ({ data }) => {
  const hasDropdown = (data?.innerData && data?.innerData?.length > 0) || data.description;

  return (
    <DropdownMenuItemWrapper>
      <DropdownTitleWrapper>
        <DropdownMenuItemTitle variant={TextStyles['Nav Item Text']}>{data?.title}</DropdownMenuItemTitle>
        {data?.announcementLabel && <AnnouncementLabel>{data.announcementLabel}</AnnouncementLabel>}
        <Arrow />
      </DropdownTitleWrapper>
      {hasDropdown && (
        <DropdownContainer>
          {data?.innerData && <SecondaryMenuList data={data?.innerData} />}
          {data?.description && <Description data={data?.description} />}
        </DropdownContainer>
      )}
    </DropdownMenuItemWrapper>
  );
};

export default DropdownMenuItem;

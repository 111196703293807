import type { DelayTime } from '@/constants';
import type { Asset } from '@/interfaces';

export enum PmtOnboardingPopupTrigger {
  AfterFirstParcelLoads = 'After first parcel loads',
  UserScrollsToTheBottomOfThePage = 'User scrolls to the bottom of the page',
}

export interface ITrackingLeftPanel {
  joinUsHeader?: string;
  joinUsValuePropositionList?: Array<Asset>;
}
export interface ITrackingRightPanel {
  joinUsDesktopMainText?: string;
  joinUsSubtext?: string;
  desktopGoogleCta?: string;
  subPmcNewsletterText?: string;
  newsletterSubText?: string;
  heroImage?: Asset;
  sys?: { id: string };
  zapierHookId?: string;
  newUserTriggerTimes?: Array<number> | null;
  returnUserTriggerTimes?: Array<number> | null;
  trigger?: PmtOnboardingPopupTrigger;
  identifier?: string;
  onNext?: () => void;
  onClose?: () => void;

  // For GTM
  delayTime?: DelayTime;
}

export type IPmtOnboardingPopup = ITrackingLeftPanel & ITrackingRightPanel;

export enum PmtSignUpStep {
  SignUp = 'Sign Up',
  VerifyEmail = 'Verify Email',
  Success = 'Success',
}

export enum PmtOnboardingPopupCta {
  Noti = 'noti',
  Login = 'login',
  DelayedShipment = 'delayed shipment',
  ShipmentSummary = 'shipment summary',
}

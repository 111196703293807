import styled from 'styled-components';
import { FilledButtonAsLink } from '../Button.styles';

// Variant link still has height
export const ButtonLink = styled(FilledButtonAsLink)`
  border: none;
  padding: 0;
  border-radius: unset;
  color: ${({ theme }) => theme.colors.blue};

  & > span {
    justify-content: flex-start;
    width: fit-content;
  }

  & > span > svg,
  & > svg,
  & > span > svg path,
  & > svg path {
    fill: ${({ theme }) => theme.colors.blue};
  }

  //TODO: 14/7
  ${({ theme }) => theme.mediaBreakpointUp.md} {
    :not([disabled]):hover {
      color: ${({ theme }) => theme.colors.blueHover};
      text-decoration: ${({ icon }) => (icon ? 'none' : 'underline')};
  
      & > span > svg,
      & > svg,
      & > span > svg path,
      & > svg path {
        fill: ${({ theme }) => theme.colors.blueHover};
      }
    }
  }
`;

export const Button = styled(ButtonLink).attrs({
  as: 'button',
})``;

export default { Button, ButtonLink };

import { Typography } from '@/components';
import { DefaultText } from '@/components/Tooltip/components/TooltipWrapper/TooltipWrapper.styles';
import { styledTextAsObj } from '@/components/Typography/Typography.styles';
import { TextStyles } from '@/constants';
import Link from 'next/link';
import styled from 'styled-components';

export const Name = styled(Typography).attrs({
  variant: TextStyles.Overline,
})(({ theme }) => ({
  color: theme.colors.highEmphasisBlack,
}));

export const TextContent = styled(DefaultText)(({ theme }) => ({
  marginTop: theme.spacing[8],
  color: theme.colors.mediumEmphasisBlack,
}));

export const LinkContent = styled(Link)(({ theme }) => ({
  wordBreak: 'break-word',
  display: '-webkit-box',
  overflow: 'hidden',
  WebkitBoxOrient: 'vertical',
  marginTop: theme.spacing[8],
  textDecoration: 'underline',
  color: theme.colors.hyperlink,
  ...styledTextAsObj[TextStyles['Body Text Small']],

  ':hover': {
    color: theme.colors.blue,
  },
}));

import styled from 'styled-components';
import { theme, TooltipVariant } from '@/constants';

export const IconWrapper = styled.div`
  display: flex;
  margin: auto 0;
`;

export const InfoIconContainer = styled.a<{ iconHeight?: number }>`
  display: flex;
  height: ${({ iconHeight }) => iconHeight || 22}px; // 22px is default height based on original Tooltip in Orion-13
`;

export const FlexContainer = styled.div<{ variant: TooltipVariant }>`
  display: flex;
  align-items: flex-start;
  word-break: break-word;

  ${({ theme, variant }) =>
    variant === TooltipVariant.Icon &&
    `
    width: fit-content;
    > div:first-child {
      margin-right: ${theme.spacing[4]};
    }
  `}
`;

export const TooltipInnerContent = styled.div<{ variant: TooltipVariant }>`
  border-radius: 4px;
  background-color: ${theme.colors.surface01};
  color: ${theme.colors.highEmphasisBlack};
  border: 1px solid ${theme.colors.surface04};
  padding: ${`${theme.spacing[12]} ${theme.spacing[16]}`};
  box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16);
`;

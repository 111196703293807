import styled, { css } from 'styled-components';
import Button from './Button';
import { ButtonColorKey, type ButtonProps, ButtonVariant } from '@/interfaces';
import type React from 'react';
import type { AnchorHTMLAttributes, ButtonHTMLAttributes } from 'react';
import ArrowDown from 'public/images/icons/arrow_down.svg';

const Arrow = styled(ArrowDown)``;

const StyledButton = styled(Button)<{ $collapsed: boolean }>`
  padding: ${({ theme }) => theme.spacing[8]} 0;

  :not([disabled]):hover {
    text-decoration: initial;
  }

  ${({ $collapsed }) =>
    !$collapsed &&
    css`
      ${Arrow} {
        transform: rotate(180deg);
      }
    `}
`;

interface ViewMoreButtonProps {
  collapsed: boolean;
}
const ViewMoreButton: React.FC<
  ButtonProps & ViewMoreButtonProps & (AnchorHTMLAttributes<'a'> | ButtonHTMLAttributes<any>)
> = ({ collapsed, ...props }) => {
  return (
    <StyledButton
      {...props}
      variant={ButtonVariant.Link}
      color={ButtonColorKey.Blue}
      icon={Arrow}
      $collapsed={collapsed}
    >
      {collapsed ? 'View More' : 'View Less'}
    </StyledButton>
  );
};

export default ViewMoreButton;

import styled from 'styled-components';
import { LayoutHierarchy, theme } from '@/constants';
import { styledText } from '@/components/Typography/Typography.styles';
import ArrowDown from '../../../../../public/images/icons/arrow_down.svg';
import Label from '../../Label';

const zIndex = {
  SelectedOption: 1,
  FakeInput: -1,
};

export const filledInputStyle = `
  top: -11px;
  transform: translateY(0);
  transform-origin: top left;
  left: ${theme.spacing[8]};
  background: ${theme.colors.highEmphasisWhite};

  ${styledText['Body Text Small']}
`;

export const SelectedOption = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  ${styledText['Body Text Large']}
  position: relative;
  z-index: ${zIndex.SelectedOption};
  color: ${theme.colors.highEmphasisBlack};
`;

export const ArrowIcon = styled(ArrowDown).withConfig({
  shouldForwardProp: (props) => props !== 'show',
})`
  transition: 0.1s;

  ${({ show }) =>
    show &&
    `
    transform: rotate(180deg);
  `}

  path {
    fill: ${theme.colors.darkCharcoal};
  }
`;

export const SelectorWrapper = styled.div`
  position: relative;
  margin-top: ${({ theme }) => theme.spacing[10]};
`;

export const SelectedOptionWrapper = styled.div`
  display: grid;
  flex: 1 1 0%;
`;

export const SelectorInput = styled.div<{
  isInvalid: boolean;
  isOpen?: boolean;
  hasValue?: boolean;
}>`
  cursor: pointer;
  border: 1px solid ${theme.colors.inactiveBlack};
  height: 45px;
  padding: 9px ${theme.spacing[14]};
  border-radius: 4px;
  overflow-x: hidden;
  overflow-x: visible;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${Label} {
    cursor: pointer;
    padding-right: ${theme.spacing[32]};
  }

  ${({ hasValue }) =>
    hasValue &&
    `
    ${Label} {
      ${filledInputStyle}
      padding-right: unset;
      height: 22px;
    }
  `}

  ${({ isOpen }) =>
    isOpen &&
    `
    border: solid 2px ${theme.colors.blue};

    ${ArrowIcon} {
      margin-right: -1px;
    }

    ${Label} {
      ${filledInputStyle}
      color: ${theme.colors.blue};
      padding-right: unset;
    }
  `}

  ${({ isInvalid }) =>
    isInvalid &&
    `
    border: solid 1px ${theme.colors.error};

    ${ArrowIcon} {
      margin-right: unset;
    }

    ${Label} {
      color: ${theme.colors.error};
    }
  `}
`;

export const FakeInput = styled.input.attrs({
  role: 'presentation',
  readOnly: true,
  type: 'text',
  tabIndex: -1,
})`
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  opacity: 0;
  z-index: ${zIndex.FakeInput};
`;

export const Option = styled.div<{ isSelected: boolean }>`
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: ${theme.spacing[12]} ${theme.spacing[16]} ${theme.spacing[10]};
  color: ${theme.colors.highEmphasisBlack};
  ${styledText['Body Text Small']}

  :hover {
    background-color: ${theme.colors.surface02};
  }

  ${({ isSelected }) =>
    isSelected &&
    `
    ${styledText['Heading 4']}
  `}
`;

export const SelectorOptions = styled.div`
  position: absolute;
  top: 45px;
  left: 0;
  background: ${theme.colors.highEmphasisWhite};
  border-radius: 4px;
  overflow: hidden;
  width: 100%;
  margin-top: ${theme.spacing[8]};
  z-index: ${LayoutHierarchy.Fifth};
  box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04),
    0px 4px 8px rgba(96, 97, 112, 0.16);

  > ${Option}:not(:last-child) {
    border-bottom: 1px solid ${theme.colors.pressedBlack};
  }

  > ${Option}:not(:first-child) {
    margin-top: 1px;
  }
`;

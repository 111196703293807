import type { ICarrier } from '@/modules/pmt/interfaces';
import { CARRIER_URL_PLACEHOLDERS } from '@/modules/pmt/constants';

export const getCarrierUrl = ({ carrier, parcelId }: { carrier: ICarrier; parcelId: string }) => {
  const { ParcelId, ParcelToPostCodeCustomer } = CARRIER_URL_PLACEHOLDERS;
  const { carrierUrlTracking, carrierUrl } = carrier;

  if (!carrierUrlTracking) return carrierUrl;

  if (!carrierUrlTracking.includes(ParcelId) && !carrierUrlTracking.includes(ParcelToPostCodeCustomer)) {
    return carrierUrlTracking;
  }

  if (carrierUrlTracking.includes(ParcelToPostCodeCustomer)) {
    return carrierUrl;
  }

  return carrierUrlTracking.includes(ParcelId) ? carrierUrlTracking.replace(ParcelId, parcelId) : carrierUrl;
};

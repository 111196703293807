import type { IIndividualCarrier } from './individualCarrier.interfaces';
import type { IMarket } from './market.interfaces';
import type { ITag } from './tag.interfaces';

export enum WordCloudLayout {
  Slider = 'Slider',
  Row = 'Row',
}

export type IWordBubble = ITag & IMarket & IIndividualCarrier;

export interface IWordCloud {
  alignment?: 'Left' | 'Center';
  title: string;
  subText?: string;
  layout?: WordCloudLayout;
  buttonLink?: string;
  wordBubbles: IWordBubble[];
  tradelanePool?: string[];
}

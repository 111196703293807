export const checkJson = (json: string): boolean => {
  try {
    JSON.parse(json);
  } catch (err) {
    return false;
  }
  return true;
};

export const objToBase64 = <T>(object: T): string => {
  const json = JSON.stringify(object);
  return Buffer.from(json).toString('base64');
};

export const base64ToObj = <T>(base64: string): T | null => {
  const json = Buffer.from(base64, 'base64').toString('utf-8');
  if (!checkJson(json)) return null;
  return JSON.parse(json);
};

/**
 * Convert string from base64 to utf8
 * @param base64 string
 * @returns string
 *
 *
 * @example
 * base64ToUtf8('SGVsbG8gV29ybGQh') = 'Hello World!'
 * base64ToUtf8('SGVsbG8gV29ybGQh', 'Hello World!') = 'Hello World!'
 * base64ToUtf8('SGVsbG8gV29ybGQh', 'Hello World!', 'Hello World!') = 'Hello World!'
 * base64ToUtf8('SGVsbG8gV29ybGQh', 'Hello World!', 'Hello World!', 'Hello World!') = 'Hello World!'
 * base64ToUtf8('SGVsbG8gV29ybGQh', 'Hello World!', 'Hello World!', 'Hello World!', 'Hello World!') = 'Hello World!'
 */

export const base64ToUtf8 = (base64: string) => {
  return Buffer.from(base64, 'base64').toString('utf8');
};

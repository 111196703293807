import { EventPhaseKey, ParcelEventAnalyticsType, ShipmentSearchStatus } from '@/modules/pmt/interfaces';

export const analyticsWithIssue = [ParcelEventAnalyticsType.Delay, ParcelEventAnalyticsType.Exception];

export const pendingStatuses = [
  ShipmentSearchStatus.Pending,
  ShipmentSearchStatus.Timeout,
  ShipmentSearchStatus.ScrapingWithCarrier,
];

export const orderedLastEventKeys = [EventPhaseKey.PreAlert, EventPhaseKey.OrderEvents, EventPhaseKey.FulfilmentEvents];

import type { Bar, Line } from 'react-chartjs-2';

import type { IChart } from '@/interfaces';
import { getRenderConditionsForChart } from '@/utils';
import { RegistrationWall } from '@/components';
import { transformTrendAndBarData } from '../../utils/chart.utils';

import ChartContainer from '../..';
import { CanvasContainer, OuterContainer, ChildContainer, ChildOuterContainer } from '../../ChartContainer.styles';

interface IChartJsMetrics extends IChart {
  ChartJsComponent: typeof Bar | typeof Line;
}

const ChartJsMetrics: React.FC<IChartJsMetrics> = (props) => {
  const Component = RegistrationWall;
  const { type, title, query, minimumRecord, ChartJsComponent, registrationWall: child } = props;
  const { chartLabels, chartData, data, options, hasData, heights } = transformTrendAndBarData(props);
  const { hasActualData, shouldRenderInnerComponent: _shouldRenderInnerComponent } = getRenderConditionsForChart({
    data,
    child,
    hasData,
    minimumRecord,
    Component,
  });
  const shouldRenderInnerComponent = _shouldRenderInnerComponent || !!query.output.find((item) => item.locked);

  if (!data?.length || (!hasActualData && !shouldRenderInnerComponent)) {
    return null;
  }

  const renderPaywall = () => {
    if (!shouldRenderInnerComponent) return null;

    return (
      <ChildOuterContainer>
        <ChildContainer wallDesktopHeight={heights.wallDesktopHeight}>
          <Component {...child} />
        </ChildContainer>
      </ChildOuterContainer>
    );
  };

  return (
    <ChartContainer chartLabels={chartLabels} title={title} type={type}>
      <OuterContainer>
        <CanvasContainer hasInnerChild={shouldRenderInnerComponent} chartDesktopHeight={heights.chartDesktopHeight}>
          <ChartJsComponent options={options} data={chartData} />
        </CanvasContainer>
        {renderPaywall()}
      </OuterContainer>
    </ChartContainer>
  );
};

export default ChartJsMetrics;

import { type IPeople, PeopleVariant } from '@/interfaces';
import { PeopleComponentMapping } from '@/constants';

const People: React.FC<IPeople> = (props) => {
  const PeopleComponent = PeopleComponentMapping[props.variant || PeopleVariant.Default];

  if (!PeopleComponent) {
    return null;
  }

  return <PeopleComponent {...props} />;
};

export default People;

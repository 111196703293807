import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { MasterTranslation } from '@/constants';
import type { ITrackingEventDateOptions } from '@/modules/pmt/interfaces';
// https://day.js.org/docs/en/plugin/is-between
import isBetween from 'dayjs/plugin/isBetween';
import isoWeek from 'dayjs/plugin/isoWeek';

dayjs.extend(isoWeek);
dayjs.extend(isBetween);
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

const checkIsTodayOrYesterday = (initialDate: string): MasterTranslation.Today | MasterTranslation.Yesterday | null => {
  switch (true) {
    case dayjs(initialDate).isToday():
      return MasterTranslation.Today;
    case dayjs(initialDate).isYesterday():
      return MasterTranslation.Yesterday;
    default:
      return null;
  }
};

const defaultOptions: ITrackingEventDateOptions = {
  showDate: true,
  showTime: true,
  fullDay: false,
  showDay: true,
  isFormatTimeUnderOneDay: false,
};

export const trackingEventDateModify = (
  initialDate: string | null,
  options = defaultOptions,
  t: (key: string) => string,
): string | null => {
  if (!initialDate) {
    return null;
  }

  const date = dayjs(initialDate);
  const isTodayOrYesterday = checkIsTodayOrYesterday(initialDate);

  const day = !options.showDay ? '' : options.fullDay ? `, ${date.format('dddd')}` : ` (${date.format('ddd')})`;

  const modifiedDate = options.showDate
    ? (isTodayOrYesterday && t(isTodayOrYesterday)) || date.format('DD MMM YY') + day
    : '';

  const modifiedTime = options.showDate && options.isFormatTimeUnderOneDay && date.isToday() && `(${date.fromNow()})`;

  const time = modifiedTime || (options.showTime ? date.format('LT') : '');

  return [modifiedDate, time].filter(Boolean).join(modifiedTime ? ' ' : ', ');
};

// For EDD section
export const isToday = (date?: string) => date && dayjs(date).isToday();

export const isBeforeToday = (date?: string) => date && dayjs(date).isBefore(dayjs(), 'day');

export const isTomorrow = (date?: string) => date && dayjs(date).isTomorrow();

export const isWithinThisWeek = (date?: string) => {
  if (!date) return false;

  const weekStart = dayjs().startOf('isoWeek');
  const weekEnd = dayjs().endOf('isoWeek');
  const isWithinThisWeek = dayjs(date).isBetween(weekStart, weekEnd, null, '[]'); // '[]' includes both start and end dates
  return isWithinThisWeek;
};

export const isAfterThisWeek = (date?: string) => {
  if (!date) return false;

  const weekEnd = dayjs().endOf('isoWeek');
  const isAfterThisWeek = dayjs(date).isAfter(weekEnd);
  return isAfterThisWeek;
};

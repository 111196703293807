import {
  ContentDescription,
  ContentHeader,
  ContentWrapper,
  DetailWrapper,
  DetailLinkItem,
} from './EventDetailsContent.styles';

interface DetailsContent {
  icon: any;
  header: string;
  description?: string;
  url?: string;
  urlParsed?: string;
}

const EventDetailsContent: React.FC<DetailsContent> = ({ icon: Icon, header, description, url, urlParsed }) => {
  if (!description && !url) return null;

  return (
    <DetailWrapper>
      <Icon width='24' height='24' />
      <ContentWrapper>
        <ContentHeader>{header}</ContentHeader>
        <ContentDescription>{description}</ContentDescription>
        {urlParsed && url && <DetailLinkItem href={urlParsed}>{url}</DetailLinkItem>}
      </ContentWrapper>
    </DetailWrapper>
  );
};

export default EventDetailsContent;

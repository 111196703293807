import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import type { ICarrier } from '@/modules/pmt/interfaces';
import { getCarriers } from '@/modules/pmt/fetchers';
import { EXCLUDED_CARRIER_IDS } from '@/modules/pmt/constants';

interface ICarriersContext {
  carriersData: ICarrier[];
}

const CarriersContext = createContext<ICarriersContext>({
  carriersData: [],
});

export const CarriersProvider: React.FC = ({ children }) => {
  const [carriersData, setCarriersData] = useState<ICarrier[]>([]);

  const getCarriersFromDataIo = async () => {
    try {
      const carriersList = await getCarriers();
      if (Array.isArray(carriersList) && carriersList.length) {
        const filteredData = carriersList.filter((carrier) => !EXCLUDED_CARRIER_IDS.includes(carrier.id));
        setCarriersData(filteredData);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (!carriersData?.length) {
      getCarriersFromDataIo();
    }
  }, []);

  const value = useMemo(
    () => ({
      carriersData,
    }),
    [carriersData],
  );

  return <CarriersContext.Provider value={value}>{children}</CarriersContext.Provider>;
};

export const useCarriersContext = () => useContext(CarriersContext);

import styled, { keyframes } from 'styled-components';
import SpinnerIcon from '../../public/images/icons/spinner.svg';
import SpinnerLargeIcon from '../../public/images/icons/spinner_24.svg';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
    tranform-origin: center center;
  }

  to {
    transform: rotate(360deg);
    tranform-origin: center center;
  }
`;

export const SpinnerWrapper = styled.div`
  position: relative;
  display: flex;

  span {
    margin-left: ${({ theme }) => theme.spacing[8]};
    display: flex;
    align-items: center;
  }
`;

const commonSpinnerIconStyle = `
  animation-duration: 700ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  &, path {
    fill: transparent !important;
    stroke: currentColor;
  }
`;

export const SpinnerImage = styled(SpinnerIcon)`
  ${commonSpinnerIconStyle}
  animation-name: ${rotate};
`;

export const SpinnerLargeImage = styled(SpinnerLargeIcon)`
  ${commonSpinnerIconStyle}
  animation-name: ${rotate};
`;

export const SpinnerText = styled.span`
  text-transform: uppercase;
`;

import { Typography } from '@/components';
import { LayoutHierarchy, TextStyles } from '@/constants';
import styled from 'styled-components';
import { Flex } from '../Common';
import { styledTextAsObj } from '../Typography/Typography.styles';

const MOBILE_IMAGE_HEIGHT = 160;
const DESKTOP_IMAGE_HEIGHT = 180;
const DESKTOP_IMAGE_WIDTH = 160;
const JOIN_US_BUTTON_LIST_HEIGHT = 104;
const ellipsisStyles = {
  display: '-webkit-box',
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
};

export const PopupContainer = styled(Flex)<{ isExpanded?: boolean }>(({ theme, isExpanded }) => ({
  position: 'fixed',
  zIndex: LayoutHierarchy.Sixth,
  bottom: 0,
  left: 0,
  flexDirection: 'column',
  width: '100%',
  alignItems: 'center',
  padding: theme.spacing[20],
  backgroundColor: theme.colors.darkBlue,
  transition: 'height 0.2s linear',

  [`${theme.mediaBreakpointUp.md}`]: {
    alignItems: 'flex-start',
    bottom: theme.spacing[16],
    left: theme.spacing[16],
    flexDirection: 'row',
    width: isExpanded !== false ? 564 : 348,
    transition: 'width 0.2s linear',
    borderRadius: 8,
    boxShadow: theme.elevation[5],
  },
}));

export const ImageContainer = styled.div<{
  isMobile: boolean;
  isExpanded?: boolean;
}>(({ theme, isMobile, isExpanded }) => ({
  display: isMobile ? 'block' : 'none',
  position: 'relative',
  overflow: 'hidden',
  borderRadius: 4,
  boxShadow: theme.elevation[2],
  marginBottom: isExpanded !== false ? theme.spacing[16] : 0,
  flex: 'none',
  marginTop: 'auto',
  height: isExpanded !== false ? MOBILE_IMAGE_HEIGHT : 0,
  width: '100%',
  opacity: isExpanded !== false ? 1 : 0,
  transition: 'opacity 0.1s linear, height 0.2s linear, margin-bottom 0.2s linear',

  [`${theme.mediaBreakpointUp.md}`]: {
    display: isMobile ? 'none' : 'block',
    marginBottom: 0,
    height: isExpanded !== false ? DESKTOP_IMAGE_HEIGHT : 0,
    width: isExpanded !== false ? DESKTOP_IMAGE_WIDTH : 0,
    marginRight: isExpanded !== false ? theme.spacing[16] : 0,
  },
}));

export const CommonText = styled(Typography).attrs({
  variant: TextStyles['Body Text Large'],
})<{ isMobile?: boolean }>(({ theme, isMobile }) => ({
  width: '100%',
  wordBreak: 'break-word',
  color: theme.colors.highEmphasisWhite,
  display: isMobile !== false ? 'block' : 'none',

  [`${theme.mediaBreakpointUp.md}`]: {
    display: isMobile === false ? 'none' : 'block',
  },
}));

export const Title = styled(CommonText)({
  ...styledTextAsObj['Heading 3'],
  ['> *']: {
    ...ellipsisStyles,
    '-webkit-line-clamp': '2',
  },
});

export const SubText = styled(CommonText)(({ theme }) => ({
  ...ellipsisStyles,
  '-webkit-line-clamp': '2',
  display: 'none',

  [`${theme.mediaBreakpointUp.md}`]: {
    marginTop: theme.spacing[8],
    display: '-webkit-box',
  },
}));

export const ContentContainer = styled(Flex)({
  position: 'static',
  width: '100%',
  overflow: 'hidden',
});

export const InnerContentContainer = styled(Flex)(({ theme }) => ({
  flexDirection: 'column',
  width: '100%',
  textAlign: 'center',

  [`${theme.mediaBreakpointUp.md}`]: {
    textAlign: 'left',
  },
}));

export const ButtonListWrapper = styled(Flex)<{ isExpanded?: boolean }>(({ theme, isExpanded }) => ({
  width: '100%',
  marginTop: isExpanded !== false ? theme.spacing[16] : 0,
  flexDirection: 'column',
  justifyContent: 'center',
  overflow: 'hidden',
  height: isExpanded === undefined ? 'auto' : isExpanded ? JOIN_US_BUTTON_LIST_HEIGHT : 0,
  opacity: isExpanded !== false ? 1 : 0,
  transition: 'opacity 0.1s linear, height 0.2s linear, margin-top 0.2s linear',

  ['> :first-child']: {
    marginBottom: theme.spacing[8],
  },

  ['> :only-child']: {
    marginBottom: 0,
  },

  ['> *']: {
    width: '100% !important',
  },

  [`${theme.mediaBreakpointUp.md}`]: {
    justifyContent: 'flex-start',
    marginTop: isExpanded !== false ? theme.spacing[16] : 0,
  },
}));

export const TextContentContainer = styled(Flex)({
  flexDirection: 'column',
  width: '100%',
});

export const FunctionalityButton = styled.button.attrs({ type: 'button' })<{
  isMobile?: boolean;
  isExpanded?: boolean;
}>(({ theme, isMobile, isExpanded }) => ({
  // Reset styles
  border: 'none',
  background: 'none',
  cursor: 'pointer',
  outline: 'none',

  // Position styles
  position: 'absolute',
  top: `-${theme.spacing[24]}`,
  right: theme.spacing[8],

  borderRadius: '4px 4px 0 0',
  backgroundColor: theme.colors.darkBlue,
  display: isMobile !== false ? 'block' : 'none',
  padding: `${theme.spacing[4]} ${theme.spacing[4]} 0 ${theme.spacing[4]}`,

  [`${theme.mediaBreakpointUp.md}`]: {
    position: 'relative',
    borderRadius: 0,
    padding: 0,
    top: 'unset',
    right: 'unset',
    marginLeft: theme.spacing[8],
    display: isMobile === false ? 'none' : 'block',
  },

  ['> svg']: {
    ...(isExpanded !== false ? {} : { transform: 'rotate(180deg)' }),
    transition: 'all 0.2s linear',
  },
}));

import type { ICarrier } from '../interfaces';
import { generateSquareLogo, isAbsoluteUrl } from '@/utils';

const generateCarrierUrl = (carrierUrl: string) => {
  if (!carrierUrl) return;

  return isAbsoluteUrl(carrierUrl) ? carrierUrl : 'https://' + carrierUrl;
};

export const mapCarrier = (carrier: any): ICarrier => {
  if (!carrier) return {} as ICarrier;

  return {
    id: carrier.id,
    carrierId: carrier.carrier_id,
    name: carrier.name,
    logo: generateSquareLogo(carrier.carrier_logo),
    csPhone: carrier.carrier_cs_phone,
    url: generateCarrierUrl(carrier.carrier_url),
    carrierUrl: generateCarrierUrl(carrier.carrier_url),
    carrierUrlTracking: generateCarrierUrl(carrier.carrier_url_tracking),
  };
};

import type { Dispatch, SetStateAction } from 'react';
import type { FieldValues, UseFormRegister, UseFormReturn, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import type { SignUpSource } from '@/constants';
import type { ButtonProps, IButton } from '@/interfaces';
import type { PmtOnboardingPopupCta } from './tracking';

export enum WidthType {
  Full = 'Full-width',
  Half = 'Half-width',
}

export enum FieldType {
  Input = 'Input',
  Email = 'Email',
  Checkbox = 'Checkbox',
  Dropdown = 'Dropdown',
  TextArea = 'TextArea',
}

export const AboutYouForm: any = {
  first_name: 'firstName',
  last_name: 'lastName',
  company_name: 'companyName',
};

export interface IFormField {
  sys?: { id: string };
  name: string;
  label: string;
  value?: string;
  type: FieldType;
  width?: WidthType;
  truncate?: number;
  placeholder?: string;
  isMandatory: boolean;
  dropdownOptions?: string[];
  mandatoryCheckErrorMessage?: string;
  customErrorMessage?: string;
  disabled?: boolean;
  appear?: boolean;
}

export type FormOnSubmit = (
  formValue: Record<string, any>,
  onSuccess: (isFinish?: boolean) => void,
  useFormReturn: UseFormReturn,
) => void;

export type FormOnFocus = (formField: Partial<IFormFieldProps<Record<string, string | boolean>>> | null) => void;

export interface IForm {
  sys?: { id: string };
  title?: string;
  subText?: string;
  buttonText?: string;
  button?: IButton;
  buttonProps?: ButtonProps;
  formFieldList: IFormField[];
  onSubmit?: FormOnSubmit;
  onSuccess?: (formValue?: any) => void;
  onFocus?: FormOnFocus;
  isEmailForm?: boolean;
  truncate?: boolean;
  isPMTButton?: boolean;
  signUpSource?: SignUpSource;
  submittingButtonText?: string;
  promptBoxContent?: string;
  isLoggedIn?: boolean;

  // Email sync
  cta?: PmtOnboardingPopupCta;
}

export interface IFormHookProps<T extends FieldValues> {
  formState: any;
  watch: UseFormWatch<T>;
  register: UseFormRegister<T>;
  setValue: UseFormSetValue<T>;
}

export type IFormFieldProps<T extends FieldValues> = IFormField &
  IFormHookProps<T> & {
    index: number;
    value?: string;
    isEmailForm?: boolean;
    setIsOtherAuthMethodExist?: Dispatch<SetStateAction<boolean>>;
    onClick?: (value?: any) => void;
    onFocus?: FormOnFocus;
  };

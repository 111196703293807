import Link from 'next/link';

import { Typography } from '@/components';
import { ColumnsWrapper, Column, ColumnTitle, ColumnSubItemsWrapper, ColumnSubItem } from './Columns.styles';

import { TextStyles } from '@/constants';
import type { IMenuItem } from '@/interfaces';

interface IColumnsProps {
  itemList: IMenuItem[];
  onLinkClick: (href?: string) => void;
  isOpenInNewTab: (href?: string) => '_blank' | undefined;
}

const Columns: React.FC<IColumnsProps> = ({ itemList, onLinkClick, isOpenInNewTab }) => {
  const AMP_PAGE_REGEX = new RegExp(/^track-[\w-]+$/);

  const renderColumnSubItem = (subItem: IMenuItem, subItemIndex: number) => {
    if (subItem.url?.substring(1)?.match(AMP_PAGE_REGEX)) {
      return (
        <ColumnSubItem
          key={subItemIndex}
          href={subItem.url || '#'}
          target={isOpenInNewTab(subItem.url)}
          onClick={() => onLinkClick(subItem.url)}
        >
          <Typography variant={TextStyles['Body Text Small']}>{subItem.title}</Typography>
        </ColumnSubItem>
      );
    }

    return (
      <Link key={subItemIndex} href={subItem.url || '#'} passHref legacyBehavior>
        <ColumnSubItem target={isOpenInNewTab(subItem.url)} onClick={() => onLinkClick(subItem.url)}>
          <Typography variant={TextStyles['Body Text Small']}>{subItem.title}</Typography>
        </ColumnSubItem>
      </Link>
    );
  };

  return (
    <ColumnsWrapper>
      {itemList.map((item, index) => {
        const hasInnerData = item.innerData && item.innerData.length > 0;

        return (
          <Column key={index}>
            <Link href={item.url || '#'} passHref legacyBehavior>
              <ColumnTitle target={isOpenInNewTab(item.url)} onClick={() => onLinkClick(item.url)}>
                <Typography variant={TextStyles['Category List']}>{item.title}</Typography>
              </ColumnTitle>
            </Link>
            {hasInnerData && (
              <ColumnSubItemsWrapper>
                {item.innerData?.map((subItem, subItemIndex) => renderColumnSubItem(subItem, subItemIndex))}
              </ColumnSubItemsWrapper>
            )}
          </Column>
        );
      })}
    </ColumnsWrapper>
  );
};

export default Columns;

export enum ContentItemUITemplateKeys {
  BulletPoints = 'Bullet Points',
  Metrics = 'Metrics',
  Services = 'Services',
}

export type ContentItemInterface = {
  sys?: { id: string };
  uiTemplate?: ContentItemUITemplateKeys;
  title: string;
  subTitle: string;
  longSubTitle?: string;
  buttonText?: string;
  buttonLink?: string;
  openInNewTab?: boolean;
  image?: {
    contentType?: string;
    url: string;
    description: string;
  };
  wrapperImage?: {
    contentType?: string;
    url: string;
    description: string;
  };
};

import type { IThumbnailSection } from '@/interfaces';
import { LoadMoreSection, ViewAllSection } from './templates';

const ThumbnailSection: React.FC<IThumbnailSection> = (props) => {
  const { containsMarketTag, latestData, featuredArticles } = props;

  if (!latestData?.length && !featuredArticles?.length) return null;

  if (featuredArticles?.length) {
    return <ViewAllSection {...props} thumbnails={featuredArticles} />;
  }

  if (!containsMarketTag) {
    return <LoadMoreSection {...props} />;
  }

  return <ViewAllSection {...props} thumbnails={latestData} />;
};

export default ThumbnailSection;

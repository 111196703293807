import { CustomEventName } from '@/constants';
import { resetGTMDataLayer, pushEvent } from '..';

export const gtmFormSubmission = (formLabel: string | undefined): void => {
  resetGTMDataLayer();
  const details = { formLabel };
  pushEvent(CustomEventName.CustomFormSubmission, details);
};

export const gtmContactFormSubmission = (data: any): void => {
  resetGTMDataLayer();
  pushEvent(CustomEventName.ContactFormSubmission, data);
};

import { FieldType, type IFormField } from '@/interfaces';

export const DEFAULT_REQUIRED_MESSAGE = "This field can't be left blank";
export const EMAIL_REGEX = /^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/g;

export const EmailField: IFormField = {
  name: 'email',
  label: 'Email Address',
  type: FieldType.Email,
  isMandatory: true,
  customErrorMessage: 'Please enter your email address in format: email@example.com',
};

export const CheckboxField: IFormField = {
  name: 'newsletterOptIn',
  label: 'Subscribe to receive our newsletters',
  type: FieldType.Checkbox,
  isMandatory: false,
};

export const LeadsFormFields: Record<string, string> = {
  first_name: 'firstName',
  last_name: 'lastName',
  company_name: 'companyName',
  email_address: 'emailAddress',
};

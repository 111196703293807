import { type ConnectEmailMethod, CustomEventName, type EsyncAlerts } from '@/constants';
import { resetDataLayerAndPushEvent } from '..';

export const gtmViewLoginCta = (variant?: string) => {
  resetDataLayerAndPushEvent(CustomEventName.ViewLoginCta, { variant });
};

export const gtmClickLoginCta = (variant?: string) => {
  resetDataLayerAndPushEvent(CustomEventName.ClickLoginCta, { variant });
};

export const gtmClickEsyncDisconnect = () => {
  resetDataLayerAndPushEvent(CustomEventName.ClickEsyncDisconnect);
};

export const gtmViewEsyncAccounts = () => {
  resetDataLayerAndPushEvent(CustomEventName.ViewEsyncAccounts);
};

export const gtmEsyncDisconnectConfirm = () => {
  resetDataLayerAndPushEvent(CustomEventName.EsyncDisconnectConfirm);
};

export const gtmConnectEmailSuccess = (method: ConnectEmailMethod, variant?: string) => {
  resetDataLayerAndPushEvent(CustomEventName.ConnectEmailSuccess, { method, variant });
};

export const gtmClickEsyncConnect = ({
  method,
}: {
  method: Omit<ConnectEmailMethod, 'login_cta' | 'esync_cta'>;
}) => {
  resetDataLayerAndPushEvent(CustomEventName.ClickEsyncConnect, { method });
};

type GtmEsyncCtaArgs = (args: { variant?: string }) => void;

export const gtmViewEsyncCta: GtmEsyncCtaArgs = ({ variant }) => {
  resetDataLayerAndPushEvent(CustomEventName.ViewEsyncCta, { variant });
};

export const gtmClickEsyncCta: GtmEsyncCtaArgs = ({ variant }) => {
  resetDataLayerAndPushEvent(CustomEventName.ClickEsyncCta, { variant });
};

type GtmEsyncAlertsArgs = (args: { alert: EsyncAlerts }) => void;

export const gtmViewEsyncAlerts: GtmEsyncAlertsArgs = ({ alert }) => {
  resetDataLayerAndPushEvent(CustomEventName.ViewEsyncAlerts, { alert });
};

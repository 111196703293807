import { forwardRef, type MouseEventHandler } from 'react';
import { BsCheck } from 'react-icons/bs';
import { CheckboxWrapper, CheckboxSquare, Label, CheckboxSquareWrapper, FakeCheckbox } from './Checkbox.styles';

interface ICheckboxProps {
  name?: string;
  checked?: boolean;
  label?: string;
  onClick?: MouseEventHandler;
  truncate?: number;
  errorMessage?: string;
}

const Checkbox: React.ForwardRefRenderFunction<HTMLInputElement, ICheckboxProps & Record<string, any>> = (
  props,
  ref,
) => {
  const { name, checked, label, disabled, onClick, truncate, errorMessage, ...restProps } = props;

  return (
    <CheckboxWrapper
      checked={checked}
      disabled={disabled}
      hasError={!!errorMessage}
      onClick={!disabled ? onClick : undefined}
    >
      <FakeCheckbox ref={ref} name={name} checked={checked} disabled={disabled} {...restProps} />
      <CheckboxSquareWrapper>
        <CheckboxSquare>{checked && <BsCheck />}</CheckboxSquare>
      </CheckboxSquareWrapper>
      {label && <Label truncate={truncate}>{label}</Label>}
    </CheckboxWrapper>
  );
};

export default forwardRef(Checkbox);

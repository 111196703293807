type TriggerHook = <T>(args: {
  hookId?: string | null;
  data: T;
}) => Promise<Response | undefined>;

export const triggerHook: TriggerHook = async ({ hookId, data }) => {
  if (!hookId) {
    return;
  }

  const fetchUrl = '/api/send-zapier';
  const fetchOptions: RequestInit = {
    method: 'POST',
    body: JSON.stringify({ data, hookId }),
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetch(fetchUrl, fetchOptions);
};

import styled from 'styled-components';

import { Typography } from '@/components';
import { TextStyles } from '@/constants';

import { PageLinksWrapper } from './PageLinks/PageLinks.styles';

export const FooterWrapper = styled.div`
  position: relative;
`;

export const FooterInner = styled.div`
  position: relative;

  ${({ theme }) => `
    padding-top: 40px;
    padding-bottom: ${theme.spacing[20]};
    background: ${theme.colors.darkBlue};
  `}
`;

export const ImageContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
`;

export const ColumnsInnerContent = styled.div<{ onlyLogo?: boolean }>`
  ${({ theme }) => `
    padding-left: ${theme.spacing[20]};
    padding-right: ${theme.spacing[20]};
  `}

  display: flex;
  flex-wrap: wrap;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    padding-left: 0;
    padding-right: 0;
  }

  ${({ onlyLogo }) => onlyLogo && 'justify-content: space-between;'}
`;

export const LeftSection = styled.div`
  width: 100%;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    width: 730px;
    margin-bottom: 0;
    margin-right: ${({ theme }) => theme.spacing[32]};
  }

  ${({ theme }) => theme.mediaBreakpointUp.lg} {
    width: 352px;
  }
`;

export const Logo = styled.a<{ onlyLogo?: boolean }>(({ theme, onlyLogo }) => ({
  display: 'block',
  position: 'relative',
  marginBottom: theme.spacing[24],

  ...(onlyLogo && {
    marginBottom: theme.spacing[16],

    [`${theme.mediaBreakpointUp.md}`]: {
      marginBottom: theme.spacing[20],
    },
  }),

  img: {
    width: 240,
  },
}));

export const SubText = styled(Typography).attrs({
  variant: TextStyles['Body Text Small'],
})`
  color: ${({ theme }) => theme.colors.highEmphasisWhite};
`;

export const PPText = styled(Typography).attrs({
  type: 'span',
})`
  color: ${({ theme }) => theme.colors.highEmphasisWhite};
  margin-right: ${({ theme }) => theme.spacing[6]};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: 14px;
  font-size: 12.5px;
`;

export const PoweredByPP = styled.a`
  margin-top: ${({ theme }) => theme.spacing[8]};
  position: relative;
  display: flex;
  align-items: center;

  img {
    object-fit: contain;
    object-position: center center;
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin-top: ${({ theme }) => theme.spacing[16]};
  }
`;

export const BottomSection = styled.div<{ onlyLogo?: boolean }>(({ theme, onlyLogo }) => ({
  ...(!onlyLogo && {
    width: '100%',

    [`${theme.mediaBreakpointUp.md}`]: {
      marginTop: theme.spacing[48],
    },
  }),

  marginTop: theme.spacing[24],

  [`${theme.mediaBreakpointUp.md}`]: {
    marginTop: 'unset',
  },
}));

export const CopyrightAndPageLinks = styled.div<{ onlyLogo?: boolean }>(({ theme, onlyLogo }) => ({
  display: 'flex',
  flexDirection: 'column',

  [`${theme.mediaBreakpointUp.md}`]: {
    flexDirection: 'column-reverse',
    alignItems: 'flex-end',

    [`${PageLinksWrapper}`]: {
      marginBottom: theme.spacing[20],
    },
  },

  ...(!onlyLogo && {
    justifyContent: 'space-between',
    flexDirection: 'row',
    flexWrap: 'wrap',

    [`${theme.mediaBreakpointUp.md}`]: {
      flexDirection: 'row',
      alignItems: 'unset',
      flexWrap: 'unset',

      [`${PageLinksWrapper}`]: {
        marginBottom: 'unset',
      },
    },
  }),
}));

export const Copyright = styled(Typography).attrs({
  variant: TextStyles['Body Text Small'],
})`
  ${({ theme }) => `
    color: ${theme.colors.gray300};
    margin-bottom: ${theme.spacing[16]};
  `}

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin-bottom: 0;
  }
`;

import type { IIndividualCarrier } from '@/interfaces';
import { LoadMoreButton as Button } from './LoadMoreButton.styles';

const MAX_CARRIER_LIST_COUNTER = 5;

interface ILoadMoreButtonProps {
  allCarrierList: Partial<IIndividualCarrier>[];
  handleLoadMore: () => void;
  truncatedCarrierListLength: number;
  isDesktop?: boolean;
}

const LoadMoreButton: React.FC<ILoadMoreButtonProps> = ({
  handleLoadMore,
  allCarrierList,
  truncatedCarrierListLength,
  isDesktop = false,
}) => {
  if (allCarrierList.length <= MAX_CARRIER_LIST_COUNTER || truncatedCarrierListLength >= allCarrierList.length)
    return null;

  return (
    <Button onClick={handleLoadMore} isDesktop={isDesktop}>
      Load more
    </Button>
  );
};

export default LoadMoreButton;

import styled from 'styled-components';
import { IconAlignment, type TableIconColor } from '@/interfaces';

export const HeaderWithIcon = styled.div<{ alignment: IconAlignment; color: TableIconColor }>`
  display: flex;
  align-items: center;

  ${({ alignment }) =>
    alignment === IconAlignment.Right &&
    `
    flex-direction: row-reverse;
  `}

  > svg {
    flex: none;

    path {
      fill: ${({ theme, color }) => theme.colors[color]};
    }

    ${({ theme, alignment }) =>
      alignment === IconAlignment.Right
        ? `
      margin-left: ${theme.spacing[8]};
    `
        : `
      margin-right: ${theme.spacing[8]};
    `}
  }
`;

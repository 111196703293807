import type React from 'react';
import Link from 'next/link';
import { type IEvent, type IEventList, ThumbnailVariant } from '@/interfaces';
import { SectionTitle, Thumbnail, SectionWrapper } from '@/components';
import { EventListItem, EventListWrapper, LeftColumn, RightColumn } from './EventList.styles';
import { replacePath } from '@/utils';
import { ACCEPTED_ARTICLE_TYPES, RouteName } from '@/constants';

const EventListSection: React.FC<IEventList & { featuredEventList?: IEvent[] | null }> = ({
  title,
  featured,
  eventList,
  featuredEventList = [],
}) => {
  const _renderedEventList = featured ? featuredEventList : eventList;
  const firstEvent = _renderedEventList?.[0] || null;
  if (!_renderedEventList || _renderedEventList?.length === 0) return null;

  const getHref = (slug: string) => {
    return replacePath(RouteName.Article, [ACCEPTED_ARTICLE_TYPES['events'], slug]);
  };

  return (
    <SectionWrapper>
      {title && <SectionTitle>{title}</SectionTitle>}
      <EventListWrapper>
        <LeftColumn>
          {firstEvent && (
            <Link key={firstEvent?.sys?.id} href={getHref(firstEvent.slug || '')} passHref legacyBehavior>
              <EventListItem>
                <Thumbnail {...firstEvent} priority variant={ThumbnailVariant.LARGE_DEFAULT} />
              </EventListItem>
            </Link>
          )}
        </LeftColumn>
        <RightColumn>
          {_renderedEventList?.map((event: IEvent, index: number) => {
            if (index === 0) return null;

            return (
              <Link key={event.sys.id} href={getHref(event.slug || '')} passHref legacyBehavior>
                <EventListItem>
                  <Thumbnail {...event} variant={ThumbnailVariant.BLUE_BORDER_LEFT} />
                </EventListItem>
              </Link>
            );
          })}
        </RightColumn>
      </EventListWrapper>
    </SectionWrapper>
  );
};

export default EventListSection;

import type React from 'react';
import type { IHeader, IMenuItem } from '@/interfaces';
import { DropdownNavigationWrapper } from './DropdownNavigation.styles';
import DropdownMenuItem from './DropdownMenuItem';
import SingleMenuItem from './SingleMenuItem';

const DropdownNavigation: React.FC<IHeader> = ({ itemList }) => {
  if (!itemList?.length) return null;

  return (
    <DropdownNavigationWrapper>
      {itemList.map((navItem: IMenuItem, index: number) => {
        if (!navItem?.innerData?.length) {
          return <SingleMenuItem key={index} data={navItem} />;
        }

        return <DropdownMenuItem key={index} data={navItem} />;
      })}
    </DropdownNavigationWrapper>
  );
};

export default DropdownNavigation;

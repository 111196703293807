import styled from 'styled-components';
import { LayoutHierarchy, theme } from '@/constants';
import { styledText } from '@/components/Typography/Typography.styles';

const LinkStyles = `
  cursor: pointer;
  color: ${theme.colors.hyperlink};

  :hover {
    text-decoration: underline;
    opacity: 0.7;
  }

  :visited {
    color: ${theme.colors.hyperlinkVisited};
  }
`;

export const CCPAInnerContainer = `
  position: fixed;
  z-index: ${LayoutHierarchy.Fourth};
  width: 100%;
  bottom: 0;
  padding: ${theme.spacing[20]};
  background-color: ${theme.colors.surface04};
  color: ${theme.colors.darkGray};
  ${styledText['Body Text Small']}

  & a {
    ${LinkStyles}
  }

  ${theme.mediaBreakpointUp.md} {
    padding: ${theme.spacing[20]} 0;
  }
`;

export const CCPAWrapper = `
  width: 100%;
  max-width: 1160px;

  ${theme.mediaBreakpointUp.md} {
    margin: auto;
    padding: 0 ${theme.spacing[20]};
  }
`;

export const CCPAContainer = styled.div`
  background-color: ${theme.colors.highEmphasisWhite};

  #pm_ccpa_inner_container {
    ${CCPAInnerContainer}
  }

  #pm_ccpa_wrapper {
    ${CCPAWrapper}
  }
`;

import callContentful from './base.fetchers';
import type { IEvent, ThumbnailProps, ITag } from '@/interfaces';
import {
  allThumbnailDataModify,
  sortDatesByClosest,
  sortFromCurrentDate,
  eventDataModify,
  removeDuplicateCTFObjects,
} from '@/utils';
import {
  findEventCampaign,
  findInternalEvent,
  modifyRelatedEvents,
  modifyRelatedCampaignEvents,
  sortEventsByClosestAndUpcoming,
} from '../utils';
import { RECORDINGS_EVENT_SLUG } from '@/constants';
import { rightSectionList, tagsAndMarketsQuery } from '@/fetchers';

const EVENT_START_DATE = 'startDate';

type BaseThumbnailQuery = (args: {
  isPreviewMode?: boolean;
  limit?: number;
  where?: string;
  order?: string;
  tagLimit?: number;
}) => string;

// max is: 1099, use 1090
const baseEventThumbnailQuery: BaseThumbnailQuery = ({ isPreviewMode, limit = 1, where, order, tagLimit = 2 }) => {
  return `
    events: eventCollection(
      preview: ${isPreviewMode}
      limit: ${limit}
      ${where ? `where: ${where}` : ''}
      ${order ? `order: ${order}` : ''}
    ) {
      items {
        sys {
          id
        }
        title
        featured
        recommended
        slug
        image {
          contentType
          url(transform: {
            width: 400,
            resizeStrategy: FIT,
          })
          title
          description
        }
        tags: tagsCollection(
          preview: ${isPreviewMode}
          limit: ${tagLimit}
        ) {
          items {
            ${tagsAndMarketsQuery()}
          }
        }
        startDate
        endDate
        address
        seo {
          title
          description
          ogType
          ogUrl
          ogImage {
            contentType
            url
          }
          ogTitle
          ogDescription
          robots
        }
      }
    }
  `;
};

type GetEvent = (args: {
  isPreviewMode: boolean;
  slug: string;
}) => Promise<IEvent | null>;

export const getEvent: GetEvent = async ({ isPreviewMode, slug }) => {
  const query = `
    {
      events: eventCollection(
        preview: ${isPreviewMode}
        where: {slug: "${slug}"}
        limit: 1
      ) {
        items {
          sys {
            id
          }
          recommended
          showCampaign
          title
          breadcrumbsTheme
          image {
            contentType
            url(transform: {
              height: 363,
              resizeStrategy: FIT,
            })
            description
          }
          video {
            url
          }
          tags: tagsCollection(
            preview: ${isPreviewMode}
            limit: 50
          ) {
            items {
              ${tagsAndMarketsQuery()}
            }
          }
          startDate
          endDate
          address
          additionalComment
          buttonText
          buttonLink
          content {
            json
            links {
              assets {
                block {
                  sys {
                    id
                  }
                  __typename
                  contentType
                  url
                  title
                  description
                  height
                  width
                }
              }
              entries {
                block {
                  sys {
                    id
                  }
                  __typename
                  ... on Button {
                    primaryButtonText
                    secondaryButtonText
                    function
                    color
                    email
                    buttonLink
                    openInNewTab
                    triggerPopUp
                  }
                  ... on LeadGenBanner {
                    title
                    titleMobile
                    image {
                      contentType
                      url(transform: {
                        width: 696,
                        resizeStrategy: FIT,
                      })
                      description
                    }
                    imageMobile {
                      contentType
                      url(transform: {
                        width: 696,
                        resizeStrategy: FIT,
                      })
                      description
                    }
                    subCopy
                    subCopyMobile
                    buttons: buttonsCollection(limit: 3) {
                      items {
                        sys {
                          id
                        }
                        primaryButtonText
                        secondaryButtonText
                        function
                        color
                        email
                        buttonLink
                        buttonEventLabel
                        openInNewTab
                      }
                    }
                    sectionTheme: theme
                    isLinkedInBanner
                  }
                  ... on RegistrationWall {
                    type
                    sectionHeader
                    image {
                      contentType
                      url(transform: {
                        width: 520,
                        resizeStrategy: FIT,
                      })
                      description
                    }
                    mobileImage {
                      contentType
                      url(transform: {
                        width: 520,
                        resizeStrategy: FIT,
                      })
                      description
                    }
                    title
                    subTitle
                    button {
                      primaryButtonText
                      function
                      color
                      email
                      buttonLink
                      buttonEventLabel
                      openInNewTab
                    }
                    subText
                  }
                  ... on People {
                    image {
                      contentType
                      url(transform: {
                        width: 130,
                        resizeStrategy: FIT,
                      })
                      description
                    }
                    fullName
                    company {
                      companyName
                      industry
                      companyType
                      companyLink
                    }
                    jobPosition
                    jobSeniority
                    subHeader
                    personDescription
                    profileLink
                  }
                }
              }
            }
          }
          organizer {
            companyName
            companyLink
          }
          contactEmail
          location
          seo {
            title
            description
            ogType
            ogUrl
            ogImage {
              contentType
              url
            }
            ogTitle
            ogDescription
            robots
          }
          ${rightSectionList(isPreviewMode)}
        }
      }
    }
  `;

  const response = await callContentful(query, isPreviewMode, `/events/${slug}`, 'event.fetchers.getEvent');
  const eventData = response?.data?.events?.items?.filter(Boolean)?.[0];

  return eventData ? eventDataModify(eventData) : null;
};

export const getEventsById = async (args: {
  isPreviewMode: boolean;
  id: string;
}): Promise<ThumbnailProps[] | null> => {
  const { isPreviewMode, id } = args;
  const query = `{${baseEventThumbnailQuery({
    isPreviewMode,
    limit: 1800,
    where: `{
        sys: {
          id_not_in: "${id}"
        }
      }`,
    order: '[startDate_DESC]',
  })}}`;

  const response = await callContentful(query, isPreviewMode); // PARENT FUNC IS DEPRECATED
  const data = allThumbnailDataModify(response.data?.events?.items, false, EVENT_START_DATE) || [];
  return data;
};

export const getAllEventThumbnails = async (args: {
  isPreviewMode: boolean;
  fetcher?: (query: string, isPreviewMode: boolean) => Promise<any>;
  slug: string;
}): Promise<ThumbnailProps[]> => {
  const { isPreviewMode, fetcher = callContentful, slug } = args;
  const query = `{${baseEventThumbnailQuery({
    isPreviewMode,
    limit: 400,
    order: '[startDate_DESC]',
    tagLimit: 6,
  })}}`;

  const response = await fetcher(query, isPreviewMode, slug, 'event.fetchers.getAllEventThumbnails');
  const data = allThumbnailDataModify(response.data?.events?.items, false, EVENT_START_DATE) || [];
  return data;
};

export const getEventThumbnailsByDate = async (args: {
  isPreviewMode: boolean;
  slug?: string;
  type?: string;
}): Promise<ThumbnailProps[]> => {
  const { isPreviewMode, slug, type } = args;
  const events =
    (await getEventsByTag({
      isPreviewMode,
      slug,
      type,
    })) || [];
  return sortEventsByClosestAndUpcoming(events);
};

export const getEventsByTag = async (args: {
  isPreviewMode: boolean;
  slug?: string;
  type?: string;
}): Promise<ThumbnailProps[]> => {
  const { isPreviewMode, slug, type } = args;
  const typeQuery = type ? `type: "${type}"` : '';
  const slugQuery = slug ? `slug: "${slug}"` : 'slug_exists: false';
  const query = `
  {
    tagCollection(
      preview: ${isPreviewMode},
      limit: 1, 
      where: {
        AND: [
          { ${slugQuery} }
          { ${typeQuery} }
        ]
      }
    ) {
      items {
        title
        linkedFrom {
          ${baseEventThumbnailQuery({ isPreviewMode, limit: 1800 })}
        }
      }
    }
  }
  `;

  const response = await callContentful(query, isPreviewMode); // PARENT FUNC IS DEPRECATED
  const data = response?.data?.tagCollection?.items?.[0];
  const events =
    allThumbnailDataModify(data?.linkedFrom?.events?.items?.filter(Boolean), false, EVENT_START_DATE) || [];
  return events;
};

export const getEventsByTagList = async (args: {
  isPreviewMode: boolean;
  tags: ITag[]; // TODO: func deprecated => no need update with IMarket, but delete func
}): Promise<ThumbnailProps[]> => {
  const { isPreviewMode, tags } = args;
  const allEventsByTag = await Promise.all(
    tags?.map(
      async (tag) =>
        await getLatestEventThumbnails({
          isPreviewMode,
          slug: tag.slug,
          type: tag.type,
        }),
    ),
  );

  const data = allEventsByTag.flat(1);
  const sortedData = sortDatesByClosest(data);
  return removeDuplicateCTFObjects(sortedData);
};

export const getLatestEventThumbnails = async (args: {
  isPreviewMode: boolean;
  slug?: string;
  type?: string;
}): Promise<ThumbnailProps[]> => {
  const { isPreviewMode, slug, type } = args;
  const events = await getEventsByTag({
    isPreviewMode,
    slug,
    type,
  });

  const upcomingEvents = events.filter((item) => item.startDate && new Date(item.startDate) > new Date());
  return sortDatesByClosest(upcomingEvents);
};

export const getRelatedEvents = async (args: {
  isPreviewMode: boolean;
  tagList: Array<string>;
  currentEventSysId: string;
}): Promise<ThumbnailProps[] | null> => {
  const { isPreviewMode, tagList, currentEventSysId } = args;
  const allEvents = (await getEventsById({ isPreviewMode, id: currentEventSysId })) || [];
  const filteredAllEvents: ThumbnailProps[] = allEvents.map((event: ThumbnailProps) => ({
    ...event,
    tags: event.tags?.filter(Boolean) || [],
  }));
  return modifyRelatedEvents(filteredAllEvents, tagList);
};

export const getRelatedCampaignEvents = async (args: {
  isPreviewMode: boolean;
  tagList: ITag[]; // TODO: func deprecated => no need update with IMarket, but delete func
  currentEventSysId: string;
  showCampaign?: boolean;
}): Promise<{
  campaignTitle: string;
  relatedCampaignEvents: ThumbnailProps[];
} | null> => {
  const { isPreviewMode, tagList, currentEventSysId, showCampaign } = args;
  const isInternalEvent = findInternalEvent(tagList);
  const eventCampaign = findEventCampaign(tagList);

  if (!isInternalEvent || !eventCampaign || !showCampaign) return null;

  const allEvents = await getEventsByTag({ isPreviewMode, slug: eventCampaign.slug });
  const filteredAllEvents = allEvents?.map((event: any) => ({ ...event, tags: event.tags?.filter(Boolean) || [] }));

  const relatedCampaignEvents = modifyRelatedCampaignEvents(filteredAllEvents, currentEventSysId);

  return {
    campaignTitle: eventCampaign.title,
    relatedCampaignEvents: relatedCampaignEvents,
  };
};

type GetFeaturedEventList = (args: {
  isPreviewMode: boolean;
  slug?: string;
}) => Promise<IEvent[] | null>;

export const getFeaturedEventList: GetFeaturedEventList = async ({ isPreviewMode, slug }) => {
  // Get all events which fall under the respective Full Page and Tag
  const eventsWithCurrentTag = await getEventsByTag({ isPreviewMode, slug });

  // Sort by closest to current date
  const sortedEventsByClosestDate =
    eventsWithCurrentTag &&
    (slug === RECORDINGS_EVENT_SLUG
      ? sortDatesByClosest(eventsWithCurrentTag)
      : sortFromCurrentDate(eventsWithCurrentTag));

  // Filter featured events
  const featuredEvents =
    (sortedEventsByClosestDate?.filter((event) => !!event.featured).slice(0, 4) as IEvent[]) || null;

  return featuredEvents;
};

type GetHighlightEvents = (args: {
  isPreviewMode: boolean;
  currentEventId?: string;
}) => Promise<IEvent[]>;

export const getHighlightEvents: GetHighlightEvents = async ({ isPreviewMode, currentEventId }) => {
  const currentDateISOString = new Date().toISOString();

  const query = `{
    events: eventCollection(
      preview: ${isPreviewMode}
      limit: 5
      where: {
        featured: true
        startDate_gt: "${currentDateISOString}"
        ${
          currentEventId
            ? `
          sys: {
            id_not: "${currentEventId}"
          }
        `
            : ''
        }
      }
      order: startDate_ASC
    ) {
      items {
        tags: tagsCollection(preview: ${isPreviewMode}, limit: 50) {
          items {
            __typename
            ... on Tag {
              title
            }
            ... on Market {
              marketName
              region
            }
          }
        }
        title
        slug
        startDate
        endDate
        address
      }
    }
  }`;

  const response = await callContentful(query, isPreviewMode); // PARENT FUNC IS DEPRECATED
  const highlightEvents = response?.data?.events?.items?.filter(Boolean);

  return highlightEvents ? highlightEvents.map(eventDataModify) : [];
};

import { SectionTitle, SectionWrapper } from '@/components';
import type { ISingleItem } from '@/interfaces';
import type React from 'react';
import { DetailsHeader } from '../OverviewSection/OverviewSection.styles';
import ServiceOfferingValue from './ServiceOfferingValue';
import { StyledDetailsContainer, StyledDetailsItem } from './ServiceOfferingsSection.styles';

interface ServiceOfferingsSectionProps {
  northAmerica?: ISingleItem[];
  europe?: ISingleItem[];
  oceania?: ISingleItem[];
}

const ServiceOfferingsSection: React.FC<ServiceOfferingsSectionProps> = ({ northAmerica, europe, oceania }) => {
  const shouldRenderServiceOfferingsSection =
    (northAmerica && northAmerica?.length > 0) || (europe && europe?.length > 0) || (oceania && oceania?.length > 0);

  if (!shouldRenderServiceOfferingsSection) {
    return null;
  }

  const renderDetailsItem = (items: ISingleItem[]) => {
    return items
      .filter((item) => item.title)
      .map((item, index) => <ServiceOfferingValue key={index} title={item.title} description={item.description} />);
  };

  return (
    <SectionWrapper>
      <SectionTitle>Service Offerings</SectionTitle>
      <StyledDetailsContainer length={2}>
        {northAmerica && northAmerica?.length > 0 && (
          <StyledDetailsItem>
            <DetailsHeader>North America</DetailsHeader>
            {renderDetailsItem(northAmerica)}
          </StyledDetailsItem>
        )}

        {europe && europe.length > 0 && (
          <StyledDetailsItem>
            <DetailsHeader>Europe</DetailsHeader>
            {renderDetailsItem(europe)}
          </StyledDetailsItem>
        )}

        {oceania && oceania.length > 0 && (
          <StyledDetailsItem>
            <DetailsHeader>Oceania</DetailsHeader>
            {renderDetailsItem(oceania)}
          </StyledDetailsItem>
        )}
      </StyledDetailsContainer>
    </SectionWrapper>
  );
};

export default ServiceOfferingsSection;

import { useEffect } from 'react';
import Image from 'next/image';
import { theme } from '@/constants';
import { type IReportsPopup, type RenderImageProps, type RenderTitleProps, ResponsiveCollection } from '@/interfaces';
import { gtmViewJoinUsReports } from '@/utils';
import { useGlobalState, useHighlightString, useResponsive } from '@/hooks';
import { JoinUsPopupContent, DownloadPopupContent } from './templates';
import { ImageContainer, Title } from './ReportsPopup.styles';

const ReportsPopup: React.FC<IReportsPopup> = (props) => {
  const { isLoggedIn, isHomepage, getProperImageUrl } = useGlobalState();
  const { isDesktop } = useResponsive([ResponsiveCollection.Desktop]);
  const { imageDesktop, imageMobile, joinUs, collapsedJoinUs, download } = props;
  const hasNoContent = !joinUs || !collapsedJoinUs || !download;
  const shouldHidePopup = !isDesktop;

  useEffect(() => {
    if (isLoggedIn || hasNoContent || shouldHidePopup) return;
    gtmViewJoinUsReports();
  }, [isLoggedIn, isDesktop, isHomepage]);

  if (hasNoContent || shouldHidePopup) {
    return null;
  }

  const highlightCss = {
    color: theme.colors.darkOrange,
    textDecoration: 'underline',
    textUnderlinePosition: 'under',
    textDecorationThickness: '0.15rem',
  };
  const image = isDesktop ? imageDesktop : imageMobile;

  const renderImage = ({ isMobile, isExpanded }: RenderImageProps) => {
    if (!image || !image.url) return null;
    return (
      <ImageContainer isMobile={isMobile} isExpanded={isExpanded}>
        <Image
          src={getProperImageUrl(image)}
          alt={image.description || 'popup image'}
          fill
          sizes='100vw'
          style={{
            objectFit: 'cover',
          }}
        />
      </ImageContainer>
    );
  };

  const renderTitle = ({ title, isMobile }: RenderTitleProps) => {
    if (!title) return null;

    return <Title isMobile={isMobile}>{useHighlightString(title, highlightCss, 'span')}</Title>;
  };

  const renderPopupContent = () => {
    if (!isLoggedIn) {
      return (
        <JoinUsPopupContent
          joinUs={joinUs}
          collapsedJoinUs={collapsedJoinUs}
          renderImage={renderImage}
          renderTitle={renderTitle}
        />
      );
    }
    return <DownloadPopupContent download={download} renderImage={renderImage} renderTitle={renderTitle} />;
  };

  return <>{renderPopupContent()}</>;
};

export default ReportsPopup;

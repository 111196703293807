import { useMyParcelsContext } from '@/modules/pmt/context';
import { verifyReCaptcha } from '@/modules/pmt/fetchers';
import getConfig from 'next/config';
import { useCallback, useEffect, useState } from 'react';

const { publicRuntimeConfig } = getConfig();

const RECAPTCHA_V3_SITE_KEY = publicRuntimeConfig.pmRecaptchaV3SiteKey;

export const useRecaptcha = () => {
  const { pmtPageData } = useMyParcelsContext();
  const [isV3Loaded, setIsV3Loaded] = useState(false);

  useEffect(() => {
    const loadRecaptchaV3 = () => {
      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_V3_SITE_KEY}`;
      script.async = true;
      script.defer = true;
      script.onload = () => setIsV3Loaded(true);
      document.body.appendChild(script);
    };

    loadRecaptchaV3();

    return () => {
      // Clean up the script when the component unmounts
      const script = document.querySelector(`script[src^="https://www.google.com/recaptcha/api.js?render="]`);
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, []);

  const executeRecaptchaV3 = useCallback(async (action?: string) => {
    if (typeof window === 'undefined' || !(window as any)?.grecaptcha?.execute) {
      console.error('reCAPTCHA v3 is not loaded yet');
    }

    try {
      // Wait for reCAPTCHA to be ready
      await new Promise<void>((resolve) => (window as any).grecaptcha.ready(resolve));

      // Execute reCAPTCHA
      const token = await (window as any).grecaptcha.execute(RECAPTCHA_V3_SITE_KEY, { action: action ?? 'submit' });

      return token;
    } catch (error) {
      console.error('Error executing reCAPTCHA v3:', error);
    }
  }, []);

  const verifyRecaptchaV3 = async (token: string): Promise<number | undefined> => {
    try {
      const response = await verifyReCaptcha({ token, version: 'v3' });
      return response.score;
    } catch (error) {
      console.error('Error verifying reCAPTCHA v3:', error);
    }
  };

  const checkWithReCaptchaV3 = async ({ action, callback }: { action: string; callback: () => void }) => {
    const v3Token = await executeRecaptchaV3(action);
    const score = await verifyRecaptchaV3(v3Token);

    // ReCaptcha v3 passed
    if (!pmtPageData?.reCaptchaThreshold || !score || score >= pmtPageData.reCaptchaThreshold) {
      return callback();
    }
    // ReCaptcha v3 failed
    if (typeof window !== 'undefined') {
      // Note: Cannot use router.push() here since cannot get server props when running getInitialProps inside _app
      window.location.href = '/404';
    }
  };

  return {
    executeRecaptchaV3,
    verifyRecaptchaV3,
    isV3Loaded,
    checkWithReCaptchaV3,
  };
};

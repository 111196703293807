export enum WebhookAction {
  create = 'create',
  save = 'save',
  auto_save = 'auto_save',
  archive = 'archive',
  unarchive = 'unarchive',
  publish = 'publish',
  unpublish = 'unpublish',
  delete = 'delete',
}

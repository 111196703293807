import styled from 'styled-components';
import { ButtonVariant, ButtonSize } from '@/interfaces';
import { Button, Thumbnail } from '@/components';
import { ThumbnailImage } from '@/components/Thumbnail/Thumbnail.styles';

export const ViewAllSectionContainer = styled.div(({ theme }) => ({
  marginTop: `-${theme.spacing[8]}`,
}));

export const ThumbnailSectionContainer = styled.div(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: 32,

  [`${theme.mediaBreakpointUp.lg}`]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: 24,

    '> *:nth-child(odd)': {
      marginLeft: 'auto',
    },

    '> *:nth-child(even)': {
      marginRight: 'auto',
    },
  },

  [`${theme.mediaBreakpointUp.xl}`]: {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
}));

export const CustomThumbnail = styled(Thumbnail)(({ theme }) => ({
  width: '100%',

  [`${ThumbnailImage}`]: {
    width: '100%',
  },

  [`${theme.mediaBreakpointUp.lg}`]: {
    width: 357,

    [`${ThumbnailImage}`]: {
      width: 357,
    },
  },
}));

export const ViewAllButton = styled(Button).attrs({
  variant: ButtonVariant.Secondary,
  size: ButtonSize.Large,
})(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing[32],

  [`${theme.mediaBreakpointUp.md}`]: {
    marginTop: theme.spacing[24],
  },
}));

import Image from 'next/image';
import styled from 'styled-components';

import MembershipLabel from '../MembershipLabel';
import Typography from '../Typography';
import Button from '../Button';
import { ThumbnailTag, ThumbnailTagWrapper } from '../Thumbnail/Thumbnail.styles';

import { TextStyles } from '@/constants';
import { ButtonFunctionKey, ButtonSize, ButtonVariant } from '@/interfaces';
import Bookmark from '../../public/images/icons/bookmark.svg';

export const EventMainSectionWrapper = styled.div`
  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: flex;
    justify-content: space-between;
  }
`;

export const LeftSection = styled.div`
  ${({ theme }) => `
    margin-bottom: ${theme.spacing[32]};
  `}

  position: relative;
  width: 100%;
  padding-top: 50.8403361%;
  border-radius: 4px;
  overflow: hidden;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin-bottom: 0;
    width: 714px;
    padding-top: 0;
    height: 363px;
  }
`;

export const LeftSectionContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const HeroImageAndOverlayWrapper = styled.div`
  height: 100%;
`;

export const Overlay = styled.div<{
  on?: string;
  backgroundUrl?: string;
}>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  ${({ backgroundUrl }) =>
    backgroundUrl &&
    `
    background: url(${backgroundUrl}) no-repeat;
    background-size: cover;
    background-position: center center;
  `}
`;

export const OverlayContent = styled.div<{ on?: string }>`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;

  ::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${({ theme }) => theme.colors.inactiveBlack};
    z-index: 2;
  }
`;

export const HeroVideo = styled.video`
  height: 100%;
  width: 100%;
`;

export const CustomMembershipLabel = styled(MembershipLabel)`
  ${({ theme }) => `
    top: ${theme.spacing[8]};
    left: ${theme.spacing[8]};
  `}

  position: absolute;
  z-index: 3;
`;

const baseIconCss = `
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  cursor: pointer;
`;

export const PlayIcon = styled(Image).attrs({
  src: '/images/icons/play.svg',
  alt: 'play',
  height: 47,
  width: 47,
})`
  ${baseIconCss}

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    height: 100px;
    width: 100px;
  }
`;

export const LockIcon = styled(Image).attrs({
  src: '/images/icons/lock.svg',
  alt: 'lock',
  height: 31,
  width: 24,
})`
  ${baseIconCss}

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    height: 67px;
    width: 51px;
  }
`;

export const RightSection = styled.div`
  ${({ theme }) => theme.mediaBreakpointUp.md} {
    flex: 0 0 334px;
    max-width: 334px;
  }
`;

export const CustomThumbnailTagWrapper = styled(ThumbnailTagWrapper)`
  flex-wrap: wrap;
  ${({ theme }) => `
    padding-bottom: ${theme.spacing[8]};
    margin: -${theme.spacing[8]} -${theme.spacing[4]} 0;
  `}

  ${ThumbnailTag},
  ${ThumbnailTag}:first-child {
    max-width: unset;
    width: fit-content;
    ${({ theme }) => `margin: ${theme.spacing[8]} ${theme.spacing[4]} 0;`}
  }
`;

export const RecommendedLabel = styled.div`
  ${({ theme }) => `
    background: ${theme.colors.darkOrange};
    padding: ${theme.spacing[4]} ${theme.spacing[8]};
    font-size: ${theme.fontSizes[12]};
    font-weight: ${theme.fontWeight.semiBold};
    color: ${theme.colors.highEmphasisWhite};
    margin-bottom: ${theme.spacing[16]};
  `}

  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  border-radius: 4px;

  svg {
    margin-right: 7px;
  }
`;

export const BookmarkIcon = styled(Bookmark)`
  path {
    fill: ${({ theme }) => theme.colors.highEmphasisWhite};
  }
`;

export const Title = styled(Typography).attrs({ variant: TextStyles['Heading 1'] })`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  white-space: normal;
  color: ${({ theme }) => theme.colors.highEmphasisBlack};
`;

export const Divider = styled.div`
  ${({ theme }) => `
    background: ${theme.colors.pressedBlack};
    margin: ${theme.spacing[16]} 0;
  `}

  height: 1px;
`;

export const DetailItemList = styled.div`
  ${({ theme }) => `
    margin-bottom: ${theme.spacing[16]};
  `}
`;

export const DetailItem = styled.div`
  ${({ theme }) => `
    :not(:last-child) {
      margin-bottom: ${theme.spacing[16]};
    }
  `}

  display: flex;
  align-items: center;
`;

export const DetailItemImage = styled(Image).attrs({
  width: 24,
  height: 24,
})(({ theme }) => ({
  marginRight: theme.spacing[16],
}));

export const DetailItemText = styled(Typography).attrs({
  as: 'span',
  variant: TextStyles['Body Text Large'],
})`
  ${({ theme }) => `
    color: ${theme.colors.highEmphasisBlack};
  `}
`;

export const RegisterButton = styled(Button).attrs({
  size: ButtonSize.Large,
  variant: ButtonVariant.Primary,
  function: ButtonFunctionKey.Link,
})`
  ${({ theme }) => `
    margin-bottom: ${theme.spacing[8]};
  `}

  width: 100%;
`;

export const AdditionalComment = styled(Typography).attrs({
  variant: TextStyles['Body Text Large'],
})`
  font-style: italic;
`;

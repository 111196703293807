import styled from 'styled-components';

export const TrackingPageWrapper = styled.div<{ remainElementsHeight?: number }>`
  background-color: #fff;
  position: relative;
  min-height: calc(100vh - ${({ remainElementsHeight }) => remainElementsHeight}px);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
  padding: 50px 30px;
`;

import { useEffect, useState } from 'react';

// TODO: hook for all positions: 'topOfView' | 'bottomOfView' | 'leftOfView' | 'rightOfView' | 'outOfView' | 'inView'
const useElementTopOfView = (id: string) => {
  const [isTopOfView, setIsTopOfView] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = () => {
      const element = document.getElementById(id);
      if (!element) return;
      const { top } = element.getBoundingClientRect();
      setIsTopOfView(top <= 0);
    };

    handleScroll();

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  return isTopOfView;
};

export default useElementTopOfView;

import styled from 'styled-components';
import { theme } from '@/constants';

const defaultPadding = theme.spacing[20];

export const MostReadWrapper = styled.div`
  margin: 0 -${defaultPadding};
`;

export const MostReadContainer = styled.div`
  padding: ${defaultPadding};
  background: ${({ theme }) => theme.colors.surface02};
`;

// export const ThumbnailsWrapper = styled.div`
//   display: grid;
//   gap: ${({ theme })=> theme.spacing[24]};

//   padding: ${({ theme })=> theme.spacing[16]} 0;
//   border-bottom: 1px solid ${({ theme }) => theme.colors.hoverBlack};

//   &:nth-child(2) {
//     padding-top: 0;
//   }

//   &:last-child {
//     padding-bottom: 0;
//     border-bottom: none;
//   }
// `

// export const NumberListing = styled(Typography).attrs({
//   type: 'p',
//   variant: TextStyles['Heading 2'],
// })`
//   width: 40px;
//   color: ${({ theme }) => theme.colors.disabledBlack};
// `

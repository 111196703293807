import { CustomEventName } from '@/constants';
import { resetDataLayerAndPushEvent } from '..';

export const gtmVerificationPopupViews = () => {
  resetDataLayerAndPushEvent(CustomEventName.VerificationPopupViews);
};

export const gtmBrowserVerificationClicks = () => {
  resetDataLayerAndPushEvent(CustomEventName.BrowserVerificationClicks);
};

// PMT
export const gtmViewBrowserVerification = () => {
  resetDataLayerAndPushEvent(CustomEventName.ViewBrowserVerification);
};

export const gtmTrackingClickBrowserVerification = () => {
  resetDataLayerAndPushEvent(CustomEventName.ClickBrowserVerification);
};

import { type ISEOFields, LanguageCode } from '@/interfaces';
import { modifyUrlQuery } from '@/utils';
import { NextSeo } from 'next-seo';
import getConfig from 'next/config';
import { useRouter } from 'next/router';

const { publicRuntimeConfig } = getConfig();

const DocumentHead: React.FC<ISEOFields> = (data) => {
  const { origin } = publicRuntimeConfig;
  const { asPath } = useRouter();

  const shouldConvertOgImageToNonWebp =
    data.ogImage?.url && (data.ogImage.contentType === 'image/webp' || !data.ogImage.contentType);

  const ogImageUrl =
    data.ogImage?.url && shouldConvertOgImageToNonWebp
      ? modifyUrlQuery(data.ogImage.url, (prevQuery) => {
          return {
            ...(prevQuery as Record<string, any>),
            fm: 'png',
          };
        })
      : data.ogImage?.url;

  const languageAlternates = [
    {
      hrefLang: LanguageCode.English,
      href: `${origin}${asPath.split('?')[0]}`,
    },
  ];

  return (
    <NextSeo
      title={data.title}
      defaultTitle='Parcel Monitor'
      description={data.description}
      noindex={data.robots?.includes('noindex')}
      nofollow={data.robots?.includes('nofollow')}
      canonical={data.ogUrl}
      openGraph={{
        type: data.ogType,
        url: data.ogUrl,
        title: data.ogTitle,
        description: data.ogDescription,
        images: [
          {
            url: ogImageUrl || '',
            alt: data.ogImage?.description,
          },
        ],
      }}
      twitter={{
        handle: '@ParcelPerform',
        site: '@ParcelPerform',
        cardType: 'summary_large_image',
      }}
      additionalLinkTags={[
        {
          rel: 'icon',
          href: '/favicons/favicon-32x32.png',
          type: 'image/png',
          sizes: '32x32',
        },
        {
          rel: 'icon',
          href: '/favicons/favicon-16x16.png',
          type: 'image/png',
          sizes: '16x16',
        },
      ]}
      additionalMetaTags={[
        {
          name: 'msapplication-TileColor',
          content: '#ffc03f',
        },
        {
          property: 'theme-color',
          content: '#ffffff',
        },
      ]}
      languageAlternates={languageAlternates}
    />
  );
};

export default DocumentHead;

import { Typography } from '@/components';
import { styledText } from '@/components/Typography/Typography.styles';
import { TextStyles } from '@/constants';
import styled from 'styled-components';

export const clampText = `
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-break: break-word;
`;

export const MobileTitle = styled(Typography).attrs({
  variant: TextStyles['Heading 1'],
})`
  margin-bottom: ${({ theme }) => theme.spacing[8]};
  -webkit-line-clamp: 2;
  ${clampText}

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: none;
  }
`;

export const DesktopTitle = styled(MobileTitle)`
  display: none;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    ${styledText['Large Text Display']}
    margin-bottom: ${({ theme }) => theme.spacing[16]};
    display: -webkit-box;
  }
`;

export const MobileSubtext = styled(Typography).attrs({
  variant: TextStyles['Body Text Small'],
})`
  -webkit-line-clamp: 3;
  ${clampText}

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: none;
  }
`;

export const DesktopSubtext = styled(MobileSubtext)`
  display: none;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    ${styledText['Body Text Large']}
    display: -webkit-box;
  }
`;

export const TextContainer = styled.div`
  max-width: 540px;
`;

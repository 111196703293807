import styled from 'styled-components';
import ArrowForward from 'public/images/icons/arrow_forward.svg';
import { ButtonSize, SectionThemeKey } from '@/interfaces';
import { SelectWrapper } from '@/components/Select/Select.styles';
import { Button } from '@/components';

export const Container = styled.div<{
  sectionTheme: SectionThemeKey;
}>`
  margin-top: ${({ theme }) => theme.spacing[24]};
  padding: ${({ theme }) => theme.spacing[16]};

  border-radius: 4px;
  background-color: ${({ sectionTheme, theme }) => {
    return sectionTheme !== SectionThemeKey.Light ? theme.colors.highEmphasisWhite : theme.colors.darkBlue;
  }};

  ${SelectWrapper}:first-of-type > div:first-child {
    border-radius: 4px 0 0 4px;
  }

  ${SelectWrapper}:last-of-type > div:first-child {
    border-left: none;
    border-radius: 0 4px 4px 0;
  }

  ${({ theme }) => theme.mediaBreakpointDown.sm} {
    ${SelectWrapper}:first-of-type > div:first-child {
      border-radius: 4px 4px 0 0;
    }

    ${SelectWrapper}:last-of-type > div:first-child {
      border-top: none;
      border-left: 1px solid ${({ theme }) => theme.colors.pressedBlack};
      border-radius: 0 0 4px 4px;
    }
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    padding: ${({ theme }) => theme.spacing[20]};
    margin-top: ${({ theme }) => theme.spacing[32]};
  }
`;
export const SelectGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    flex-direction: row;
  }
`;

// TODO: Check if can add width, height to svg, then remove the internal styling at all places
export const DividerIcon = styled(ArrowForward)``;

export const DividerIconWrapper = styled.div(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: theme.spacing[16],
  transform: 'translateY(-50%) rotate(90deg)',
  backgroundColor: theme.colors.surface03,
  padding: theme.spacing[4],
  borderRadius: '50%',
  zIndex: 1,
  width: 21,
  height: 21,
  display: 'flex',
  alignItems: 'center',

  [`${theme.mediaBreakpointUp.md}`]: {
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

export const DividerWrapper = styled.div({
  position: 'relative',
});

export const SearchIcon = styled.img.attrs({
  src: '/images/icons/search.svg',
})`
  width: 20px;
  height: 20px;
`;

export const SearchButton = styled(Button).attrs({
  size: ButtonSize.Large,
})`
  width: 100%;
  height: 50px;

  margin-top: ${({ theme }) => theme.spacing[12]};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    min-width: fit-content;
    max-width: fit-content;
    height: 56px;

    margin-top: initial;
    margin-left: ${({ theme }) => theme.spacing[8]};
  }
`;

import { createContext, useContext, useMemo, useRef } from 'react';
import { AdSetups } from '../constants';

interface IAdsContext {
  currentAdSetup: AdSetups | null;
}

const AdsContext = createContext<IAdsContext>({
  currentAdSetup: null,
});

export const AdsProvider: React.FC = ({ children }) => {
  const currentAdSetup = useRef<AdSetups>(Math.random() < 0.8 ? AdSetups.SETUP_A : AdSetups.SETUP_B).current;

  const value = useMemo(
    () => ({
      currentAdSetup,
    }),
    [currentAdSetup],
  );

  return <AdsContext.Provider value={value}>{children}</AdsContext.Provider>;
};

export const useAdsContext = () => useContext(AdsContext);

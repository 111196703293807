import type { IEmailAccount, IEmailSyncAccount, TEmailAccountDTO, TListEmailSyncAccountsDTO } from '../interfaces';

const mapEmailAccount = (emailAccount: TEmailAccountDTO): IEmailAccount => {
  return {
    id: emailAccount.id,
    realtimeSyncActivated: emailAccount.realtime_sync_activated,
    userId: emailAccount.user_id,
    createdDate: emailAccount.created_date,
    lastUpdated: emailAccount.last_updated,
    emailAddress: emailAccount.email_address,
  };
};

export const mapEmailSyncAccount = (
  emailSyncData?: TListEmailSyncAccountsDTO['data'],
): IEmailSyncAccount | undefined => {
  if (!emailSyncData) return undefined;

  return {
    total: emailSyncData.total,
    emails: emailSyncData.emails?.map(mapEmailAccount),
    maximumAccountAllowed: emailSyncData.maximum_account_allowed,
  };
};

import { TextStyles } from '@/constants';
import { Typography } from '@/components';
import { CountryFlag } from '@/components/Chart/Chart.styles';
import { FlagTextWrapper } from './FlagText.styles';

type FlagTextProps = {
  code: string;
  name: string;
};

/**
 * Flag text component, display the country flag and the country code
 * @param code country code
 * @param name country name
 * @returns flag and country code
 * @example
 * <FlagText code='US' name='United States' />
 */
const FlagText = ({ code, name }: FlagTextProps) => {
  return (
    <FlagTextWrapper>
      <CountryFlag countryCode={code} aria-label={name} alt={name} />
      <Typography variant={TextStyles['Body Text Small']}>{code}</Typography>
    </FlagTextWrapper>
  );
};

export default FlagText;

import type { ThumbnailProps, ITag, IArticleProps, ISimplifiedArticleProps } from '@/interfaces';

import { getThumbnailsByTag, rightSectionList, tagsAndMarketsQuery } from '@/fetchers';
import callContentful from './base.fetchers';

import { articleDataModify, sortByDate } from '@/utils';
import { ArticleTypeName } from '@/constants';

interface IGetArticleArgs {
  isPreviewMode: boolean;
  articleTypeName: string;
  slug: string;
}

export const getArticle = async (args: IGetArticleArgs): Promise<IArticleProps | null> => {
  const { isPreviewMode, articleTypeName, slug } = args;

  const query = `
    {
      articleCollection(
        preview: ${isPreviewMode}
        where: {
          articleType: "${articleTypeName}"
          slug: "${slug}"
        }
        limit: 1
      ) {
        items {
          sys {
            id
            publishedAt
          }
          title
          breadcrumbsTheme
          articleType
          slug
          date
          image {
            contentType
            url(transform: {
              width: 696,
              resizeStrategy: FIT,
            })
          }
          tags: tagsCollection(preview: ${isPreviewMode}) {
            items {
              ${tagsAndMarketsQuery()}
            }
          }
          content {
            json
            links {
              assets {
                block {
                  sys {
                    id
                  }
                  __typename
                  contentType
                  url
                  title
                  description
                  height
                  width
                }
              }
              entries {
                block {
                  sys {
                    id
                  }
                  __typename
                  ... on Button {
                    primaryButtonText
                    secondaryButtonText
                    function
                    color
                    email
                    buttonLink
                    openInNewTab
                    triggerPopUp
                  }
                  ... on LeadGenBanner {
                    title
                    titleMobile
                    image {
                      contentType
                      url(transform: {
                        width: 696,
                        resizeStrategy: FIT,
                      })
                      description
                    }
                    imageMobile {
                      contentType
                      url(transform: {
                        width: 696,
                        resizeStrategy: FIT,
                      })
                      description
                    }
                    subCopy
                    subCopyMobile
                    buttons: buttonsCollection(limit: 3) {
                      items {
                        sys {
                          id
                        }
                        primaryButtonText
                        secondaryButtonText
                        function
                        color
                        email
                        buttonLink
                        buttonEventLabel
                        openInNewTab
                      }
                    }
                    sectionTheme: theme
                    isLinkedInBanner
                  }
                  ... on People {
                    image {
                      contentType
                      url(transform: {
                        width: 130,
                        resizeStrategy: FIT,
                      })
                      description
                    }
                    fullName
                    subHeader
                    personDescription
                    profileLink
                  }
                  ... on RegistrationWall {
                    type
                    sectionHeader
                    image {
                      contentType
                      url(transform: {
                        width: 520,
                        resizeStrategy: FIT,
                      })
                      description
                    }
                    mobileImage {
                      contentType
                      url(transform: {
                        width: 520,
                        resizeStrategy: FIT,
                      })
                      description
                    }
                    title
                    subTitle
                    button {
                      primaryButtonText
                      function
                      color
                      email
                      buttonLink
                      buttonEventLabel
                      openInNewTab
                    }
                    subText
                  }
                }
              }
            }
          }
          seo {
            title
            description
            ogType
            ogUrl
            ogImage {
              contentType
              url
            }
            ogTitle
            ogDescription
            robots
          }
          ${rightSectionList(isPreviewMode)}
        }
      }
    }
  `;

  const response = await callContentful(
    query,
    isPreviewMode,
    `/${articleTypeName}/${slug}`,
    'article.fetchers.getArticle',
  );

  return response.data?.articleCollection?.items?.[0]
    ? articleDataModify(response.data?.articleCollection?.items?.[0])
    : null;
};

interface IGetRelatedArticleArgs {
  isPreviewMode: boolean;
  currentArticleTags: ITag[] | undefined | null;
  currentArticleSysId: string;
}

// TODO: func deprecated => no need update with IMarket, but delete func
export const getRelatedArticles = async (args: IGetRelatedArticleArgs) => {
  const { isPreviewMode, currentArticleTags, currentArticleSysId } = args;

  const firstTag = currentArticleTags?.[0];

  if (!firstTag) return null;

  const thumbnailsWithFirstTag: ThumbnailProps[] =
    (await getThumbnailsByTag({
      isPreviewMode,
      slug: firstTag.slug,
      type: firstTag.type,
    })) || [];

  const allMatchedTagThumbnails: ThumbnailProps[] = thumbnailsWithFirstTag?.filter((article: ThumbnailProps) =>
    currentArticleTags
      ?.slice(1)
      ?.every((currentTag: ITag) => article?.tags?.some((tag: ITag) => tag?.sys?.id === currentTag?.sys?.id)),
  );

  const sortedMatchedThumbnails = sortByDate(allMatchedTagThumbnails) || [];

  const relatedThumbnails: ThumbnailProps[] = [...sortedMatchedThumbnails, ...thumbnailsWithFirstTag];

  const uniqueRelatedThumbnailsBySysId = relatedThumbnails?.reduce(
    (result: ThumbnailProps[], current: ThumbnailProps) => {
      const isDuplicate = result?.some((article: ThumbnailProps) => article.sys.id === current.sys.id);
      const isCurrentArticle = current.sys.id === currentArticleSysId; // Except current post

      return isDuplicate || isCurrentArticle ? result : [...result, current];
    },
    [],
  );

  return uniqueRelatedThumbnailsBySysId;
};

export const getAllArticlesRssInfo = async (): Promise<ISimplifiedArticleProps[] | null> => {
  const ARTICLES_RSS_LIMIT = 1000;
  const query = `
    {
      articleCollection(
        preview: false
        where: {
          articleType: "${ArticleTypeName.insights}"
        }
        limit: ${ARTICLES_RSS_LIMIT}
      ) {
        items {
          sys {
            id
          }
          title
          slug
          date
          image {
            size
            contentType
            url(transform: {
              width: 696,
              resizeStrategy: FIT,
            })
          }
          content {
            json
          }
          seo {
            description
          }
        }
      }
    }
  `;

  const response = await callContentful(query, false, '/rss', 'article.fetchers.getAllArticlesRssInfo');

  return response.data?.articleCollection?.items?.filter(Boolean) || null;
};

import styled from 'styled-components';
import { LayoutHierarchy } from '@/constants';

export const CollapseNavigationWrapper = styled.div<{
  isMyParcelsPage: boolean;
}>`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${LayoutHierarchy.Third};

  ${({ theme, isMyParcelsPage }) => theme.mediaBreakpointUp[isMyParcelsPage ? 'lg' : 'xl']} {
    display: none;
  }
`;

export const HamburgerButtonLine = styled.div`
  width: 18px;
  height: 2px;
  margin: 1px auto;
  background: ${({ theme }) => theme.colors.highEmphasisWhite};
  transition: 0.4s;

  :first-child {
    transform-origin: 0% 0%;
  }

  :last-child {
    transform-origin: 0% 100%;
  }
`;

export const HamburgerButton = styled.div<{ isExpanded?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-left: 15px;
  width: 18px;

  ${(props) =>
    props.isExpanded &&
    `
    ${HamburgerButtonLine} {
      :first-child {
        transform: rotate(43deg) translate(2px,-3px);
        width: 15px;
      }
      
      :last-child {
        transform: rotate(-43deg) translate(2px,3px);
        width: 15px;
      }

      :nth-child(2) {
        opacity: 0;
        width: 15px;
      }
    }
  `}
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: ${({ theme }) => theme.colors.disabledBlack};
  margin: ${({ theme }) => theme.spacing['12']} 0;
`;

export const CollapseContainer = styled.div<{ topEleHeights: number }>`
  display: flex;
  width: 100%;
  height: calc(100vh - ${({ topEleHeights }) => topEleHeights}px);
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  left: 0;
  background: ${({ theme }) => theme.colors.pageBackground};
  padding: ${({ theme }) => theme.spacing[24]};
  padding-bottom: 64px; // for ios url bar
  flex-direction: column;
`;

import Head from 'next/head';
import type { NextPage } from 'next';
import type { IArticleStructuredData, IEventStructuredData } from '@/interfaces';

// https://nextjs.org/docs/messages/no-script-component-in-head
const StructuredData: NextPage<{ data: IArticleStructuredData | IEventStructuredData }> = ({ data }) => {
  return (
    <Head>
      <script
        key='structured-data'
        type='application/ld+json'
        dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
      />
    </Head>
  );
};

export default StructuredData;

import { type IPageOverviewBanner, Widget } from '@/interfaces';
import BannerWithTrackingWidget from './Widget/BannerWithTrackingWidget';
import DefaultBanner from './DefaultBanner';
import BannerWithTradeRouteWidget from './Widget/BannerWithTradeRouteWidget';
import BannerWithInfoBoard from './Widget/BannerWithInfoBoard';

const PageOverviewBanner: React.FC<IPageOverviewBanner> = ({ widget, ...props }) => {
  switch (widget) {
    case Widget.Tracking:
      return <BannerWithTrackingWidget {...props} />;
    case Widget.TradeRoute:
      return <BannerWithTradeRouteWidget {...props} />;
    case Widget.InfoBoard:
      return <BannerWithInfoBoard {...props} />;
    default:
      return <DefaultBanner {...props} />;
  }
};

export default PageOverviewBanner;

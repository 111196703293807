import styled from 'styled-components';
import { TextStyles } from '@/constants';
import Typography from '@/components/Typography';

export const AccountVerificationWrapper = styled(Typography).attrs({
  variant: TextStyles['Heading 2'],
})`
  width: 100%;
  text-align: center;
  margin-top: ${({ theme }) => theme.spacing[16]};
  color: ${({ theme }) => theme.colors.highEmphasisBlack};
  word-break: break-word;
`;

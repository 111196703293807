import { BreadcrumbsSectionContainer } from '@/components/Breadcrumbs/Breadcrumbs.styles';
import styled from 'styled-components';

export const BreadcrumbsContainer = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.spacing[20]};
  margin: 0;
  width: 100%;

  ${BreadcrumbsSectionContainer} {
    margin: auto;
    max-width: 1160px;
    padding: 0 ${({ theme }) => theme.spacing[20]};
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    top: ${({ theme }) => theme.spacing[40]};
  }
`;

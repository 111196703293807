import { CustomTableField, TableVariant, UniqueTableChartFieldName, UniqueTableSectionField } from '@/interfaces';

type ICheckForUniqueCell = (args: {
  variant: TableVariant;
  columnId: string;
}) => {
  isUniqueField: boolean;
  containsLogo: boolean;
};

export const checkForUniqueCell: ICheckForUniqueCell = ({ variant, columnId }) => {
  if (variant === TableVariant.Section) {
    const uniqueFields = [...Object.values(CustomTableField), ...Object.values(UniqueTableSectionField)];
    const isUniqueField = uniqueFields.includes(columnId as CustomTableField);
    const containsLogo = [CustomTableField.LogoRetailerNameLink].includes(columnId as CustomTableField);

    return {
      isUniqueField,
      containsLogo,
    };
  }

  const uniqueFields = Object.values(UniqueTableChartFieldName);
  const isUniqueField = uniqueFields.includes(columnId as UniqueTableChartFieldName);
  const containsLogo = [UniqueTableChartFieldName.logoLink, UniqueTableChartFieldName.carrierLogo].includes(
    columnId as UniqueTableChartFieldName,
  );

  return {
    isUniqueField,
    containsLogo,
  };
};

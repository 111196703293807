import type { ISEOFields } from '@/interfaces';

export const VERIFY_PAGE_HEAD: ISEOFields = {
  title: 'Parcel Monitor | Verify your email',
  description: 'Verify Parcel Monitor account through verification link',
  ogType: 'website',
  ogTitle: 'Parcel Monitor | Verify your email',
  ogDescription: 'Verify Parcel Monitor account through verification link',
  robots: ['noindex', 'nofollow'],
};

import pino from 'pino';
import getConfig from 'next/config';

const { serverRuntimeConfig } = getConfig();

let logger: any = {
  error: (...args: any[]) => args,
  info: (...args: any[]) => args,
};

const isEnable = serverRuntimeConfig.useLoki === 'enable';

if (isEnable) {
  logger = pino({
    timestamp: () => `,"time":"${new Date(Date.now()).toISOString()}"`,
  });
}

export { logger };

import { forwardRef, useEffect } from 'react';
import type { WidthType } from '@/interfaces';
import ErrorMessage from '../../ErrorMessage';
import Label from '../../Label';

import { TextInput, TextInputWrapper } from './Input.styles';

interface IInputProps {
  value?: string;
  name?: string;
  label?: string;
  width?: WidthType;
  placeholder?: string;
  isMandatory?: boolean;
  errorMessage?: string;
  isEmailForm?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

let timer: NodeJS.Timeout;

const Input: React.ForwardRefRenderFunction<HTMLInputElement, IInputProps & Record<string, any>> = (props, ref) => {
  const { name, label, width, isMandatory, errorMessage, value, isEmailForm, onChange, ...restProps } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      if (!onChange) return;
      onChange(event);
    }, 500);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <>
      <TextInputWrapper isInvalid={!!errorMessage} width={width}>
        <TextInput
          ref={ref}
          name={name}
          role='presentation'
          defaultValue={value}
          {...restProps}
          onChange={handleChange}
          placeholder=''
        />
        {label && (
          <Label htmlFor={name} isMandatory={isMandatory}>
            {label}
          </Label>
        )}
      </TextInputWrapper>
      {isEmailForm ? (
        errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>
      ) : (
        <ErrorMessage>{errorMessage}</ErrorMessage>
      )}
    </>
  );
};

export default forwardRef(Input);

import type { IMerchantInfo } from '@/modules/pmt/interfaces';
import { mapMerchantInfo } from '../mappers';

import { firestoreDB } from '@/lib';
import { doc, getDoc } from 'firebase/firestore';

export interface IGetMerchantFailureResponse {
  error: string;
}

type GetMerchant = (merchantRef: string) => Promise<IMerchantInfo | undefined>;

export const getMerchant: GetMerchant = async (merchantRef: string) => {
  try {
    const docRef = doc(firestoreDB, merchantRef);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return mapMerchantInfo(docSnap.data());
    }
  } catch (error: any) {
    console.error('Error! Can not get Merchant info!', error);
  }
};

import type { GoogleAdsenseAttribute } from '@/interfaces';

export enum PMTAdType {
  PMT_HEADER = 'PMT_HEADER',
  PMT_SQUARE_AD = 'PMT_SQUARE_AD',
  PMT_SQUARE_AD_2 = 'PMT_SQUARE_AD_2',
  PMT_SQUARE_AD_PARCEL_LIST = 'PMT_SQUARE_AD_PARCEL_LIST',
  PMT_HORIZONTAL_AD_1A = 'PMT_HORIZONTAL_AD_1A',
  PMT_HORIZONTAL_AD_2 = 'PMT_HORIZONTAL_AD_2',
  PMT_INDIVIDUAL_PARCEL_VIEW_1 = 'PMT_INDIVIDUAL_PARCEL_VIEW_1',
  PMT_INDIVIDUAL_PARCEL_VIEW_1_SME = 'PMT_INDIVIDUAL_PARCEL_VIEW_SME',
}

// Ref: https://www.pivotaltracker.com/story/show/187122613
export enum AdSetups {
  SETUP_A = 'SETUP_A', // has probability of 80%
  SETUP_B = 'SETUP_B', // has probability of 20%
}

// Ref: https://parcelperform.atlassian.net/wiki/display/PM/viewpage.action?pageId=3105619980
const SETUP_A_AD_SLOTS = {
  [PMTAdType.PMT_HEADER]: { dataAdSlot: '3109373289' },
  [PMTAdType.PMT_SQUARE_AD]: { dataAdSlot: '8569175374' },
  [PMTAdType.PMT_SQUARE_AD_2]: { dataAdSlot: '5876748874' },
  [PMTAdType.PMT_SQUARE_AD_PARCEL_LIST]: { dataAdSlot: '1942524360' },
  [PMTAdType.PMT_HORIZONTAL_AD_1A]: { dataAdSlot: '7799799434' },
  [PMTAdType.PMT_HORIZONTAL_AD_2]: { dataAdSlot: '5310201611' },
  [PMTAdType.PMT_INDIVIDUAL_PARCEL_VIEW_1]: { dataAdSlot: '5993592232' },
  [PMTAdType.PMT_INDIVIDUAL_PARCEL_VIEW_1_SME]: { dataAdSlot: '8190374144' },
};

const SETUP_B_AD_SLOTS = {
  [PMTAdType.PMT_HEADER]: { dataAdSlot: '8330499666' },
  [PMTAdType.PMT_SQUARE_AD]: { dataAdSlot: '8981236355' },
  [PMTAdType.PMT_SQUARE_AD_2]: { dataAdSlot: '8992844649' },
  [PMTAdType.PMT_SQUARE_AD_PARCEL_LIST]: { dataAdSlot: '8936186085' },
  [PMTAdType.PMT_HORIZONTAL_AD_1A]: { dataAdSlot: '2427436299' },
  [PMTAdType.PMT_HORIZONTAL_AD_2]: { dataAdSlot: '9998804559' },
  [PMTAdType.PMT_INDIVIDUAL_PARCEL_VIEW_1]: { dataAdSlot: '2805892009' },
  [PMTAdType.PMT_INDIVIDUAL_PARCEL_VIEW_1_SME]: { dataAdSlot: '9026897379' },
};

export const pmtAdUnits: Record<AdSetups, Partial<Record<PMTAdType, GoogleAdsenseAttribute>>> = {
  [AdSetups.SETUP_A]: SETUP_A_AD_SLOTS,
  [AdSetups.SETUP_B]: SETUP_B_AD_SLOTS,
};

export const adUnitsInsideParcelList: { [index: number]: PMTAdType } = {
  0: PMTAdType.PMT_HORIZONTAL_AD_1A, // Desktop: height 250px - Mobile: hidden
  1: PMTAdType.PMT_HORIZONTAL_AD_2, // Desktop: height 120px - Mobile: 100px
};

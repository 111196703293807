import styled from 'styled-components';

export const PageLinksWrapper = styled.div`
  color: ${({ theme }) => theme.colors.highEmphasisWhite};
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const PageLink = styled.a`
  color: ${({ theme }) => theme.colors.highEmphasisWhite};
  display: inline-block;

  :hover {
    color: ${({ theme }) => theme.colors.mediumEmphasisWhite};
  }
`;

export const PageLinkSeparator = styled.span`
  display: inline-block;
  height: 22px;
  width: 1px;
  background: ${({ theme }) => theme.colors.mediumEmphasisWhite};
  margin-left: ${({ theme }) => theme.spacing[12]};
  margin-right: ${({ theme }) => theme.spacing[12]};
`;

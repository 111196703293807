import { useEffect, useState } from 'react';
import { gtmClickJoinUsPmc, gtmSignUpSuccessPmc } from '@/utils';
import { type IReportsJoinUsPopup, LoginMethod, ResponsiveCollection } from '@/interfaces';
import { GoogleLogin, LinkedInLogin } from '@/components';
import { PopupType } from '@/constants';
import { useGlobalState, useResponsive } from '@/hooks';
import {
  PopupContainer,
  ContentContainer,
  ButtonListWrapper,
  FunctionalityButton,
  InnerContentContainer,
} from '../../ReportsPopup.styles';
import ExpandIcon from '../../../../public/images/icons/expand_more.svg';

const JoinUsPopupContent: React.FC<IReportsJoinUsPopup> = ({ joinUs, collapsedJoinUs, renderImage, renderTitle }) => {
  const { isDesktop } = useResponsive([ResponsiveCollection.Desktop]);
  const { setIsFromReportsPopup } = useGlobalState();
  const [isExpanded, setIsExpanded] = useState(isDesktop);
  const { titleDesktop, titleMobile } = joinUs;
  const { titleDesktop: collapsedTitleDesktop, titleMobile: collapsedTitleMobile } = collapsedJoinUs;
  const title = isDesktop
    ? isExpanded
      ? titleDesktop
      : collapsedTitleDesktop
    : isExpanded
      ? titleMobile
      : collapsedTitleMobile;

  useEffect(() => {
    setIsExpanded(isDesktop);
  }, [isDesktop]);

  const getLoginButtonProps = (authenticationMethod: LoginMethod) => ({
    shouldReloadAfterSuccess: false,
    customHandleClick: () => {
      gtmClickJoinUsPmc({
        authenticationMethod,
        popupType: PopupType.reportsPopUp,
      });
    },
    customHandleSuccess: () => {
      setIsFromReportsPopup(true);
      gtmSignUpSuccessPmc({
        authenticationMethod,
        popupType: PopupType.reportsPopUp,
      });
    },
  });

  const customFunctionalityButtonClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <PopupContainer isExpanded={isExpanded}>
      {/* Mobile content */}
      {renderImage({ isMobile: true, isExpanded })}
      {/* Desktop content */}
      {renderImage({ isMobile: false, isExpanded })}

      <ContentContainer>
        <InnerContentContainer>
          {/* Mobile content */}
          {renderTitle({ title, isMobile: true })}
          {/* Desktop content */}
          {renderTitle({ title, isMobile: false })}

          <ButtonListWrapper isExpanded={isExpanded}>
            <LinkedInLogin {...getLoginButtonProps(LoginMethod.LinkedIn)} />
            <GoogleLogin {...getLoginButtonProps(LoginMethod.Google)} />
          </ButtonListWrapper>
        </InnerContentContainer>

        {/* Functionality Component */}
        <FunctionalityButton onClick={customFunctionalityButtonClick} isExpanded={isExpanded}>
          <ExpandIcon />
        </FunctionalityButton>
      </ContentContainer>
    </PopupContainer>
  );
};

export default JoinUsPopupContent;

import { TimeKey, PmAnalyticsQueryTable } from '@/interfaces';

export const PmAnalyticsTableMapping = {
  [PmAnalyticsQueryTable.Carrier]: {
    collection: 'allCarrierCommunityInsights',
    endpoint: 'community-carrier/graphql',
    time: TimeKey.timeframe,
  },
  [PmAnalyticsQueryTable.MarketCarrier]: {
    collection: 'allCarrierMarketPage',
    endpoint: 'carrier-market-page/graphql',
    time: TimeKey.quarter,
  },
  [PmAnalyticsQueryTable.MarketCountry]: {
    collection: 'allCountryMarketPage',
    endpoint: 'country-market-page/graphql',
    time: TimeKey.quarter,
  },
  [PmAnalyticsQueryTable.MarketRegion]: {
    collection: 'allRegionMarketPage',
    endpoint: 'region-market-page/graphql',
    time: TimeKey.quarter,
  },
  [PmAnalyticsQueryTable.MarketRevenue]: {
    collection: 'allRevenueMarketPage',
    endpoint: 'revenue-market-page/graphql',
    time: TimeKey.year,
  },
  [PmAnalyticsQueryTable.TradelaneTradelane]: {
    collection: 'allTradelaneTradelanePage',
    endpoint: 'tradelane-tradelane-page/graphql',
    time: TimeKey.quarter,
  },
  [PmAnalyticsQueryTable.TradelaneCarrier]: {
    collection: 'allCarrierTradelanePage',
    endpoint: 'carrier-tradelane-page/graphql',
    time: TimeKey.quarter,
  },
  [PmAnalyticsQueryTable.RetailerOrderVolume]: {
    collection: 'allOrderVolumeRetailerPage',
    endpoint: 'order-volume-retailer-page/graphql',
    time: TimeKey.month,
  },
  [PmAnalyticsQueryTable.RetailerTradelane]: {
    collection: 'allTradelaneDeliveryByRetailerRetailerPage',
    endpoint: 'tradelane-delivery-by-retailer-retailer-page/graphql',
    time: TimeKey.quarter,
  },
  [PmAnalyticsQueryTable.RetailerCarrier]: {
    collection: 'allCarrierPartnerRetailerPage',
    endpoint: 'carrier-partner-retailer-page/graphql',
    time: TimeKey.quarter,
  },
  [PmAnalyticsQueryTable.RetailerCompetitor]: {
    collection: 'allCompetitorRetailerPage',
    endpoint: 'competitor-retailer-page/graphql',
    time: TimeKey.quarter,
  },
};

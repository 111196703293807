import { CustomEventName } from '@/constants';
import { resetDataLayerAndPushEvent } from '..';

type GtmLeadsForm = (arg: {
  landingPage: string;
  leadsPageType: string;
}) => void;

export const gtmViewLeadsForm: GtmLeadsForm = ({ landingPage, leadsPageType }) => {
  resetDataLayerAndPushEvent(CustomEventName.ViewLeadsForm, {
    landingPage,
    leadsPageType,
  });
};

export const gtmSubmissionLeadsForm: GtmLeadsForm = ({ landingPage, leadsPageType }) => {
  resetDataLayerAndPushEvent(CustomEventName.SubmissionLeadsForm, {
    landingPage,
    leadsPageType,
  });
};

export const gtmInteractionLeadsForm = (leadsFieldName: string) => {
  resetDataLayerAndPushEvent(CustomEventName.InteractionLeadsForm, { leadsFieldName });
};

import Image from 'next/image';

import { ButtonSize, type RegistrationWallProps } from '@/interfaces';
import { TextStyles, theme } from '@/constants';
import { useGlobalState, useHighlightString } from '@/hooks';
import { gtmClickLeadsElements, gtmClickRegistrationWall } from '@/utils';
import { Button, Typography } from '@/components';
import { ValuesInnerContainer, Circle } from '../../RegistrationWall.styles';
import {
  Line,
  CTAContainer,
  WallContainer,
  HeaderContainer,
  ValuesContainer,
  OuterWallContainer,
  HeaderInnerContainer,
  ImageWrapper,
} from './RegisWall.styles';
import Lock from '../../../../public/images/icons/lock_outline.svg';
import Check from '../../../../public/images/icons/check.svg';

const RegisWall: React.FC<RegistrationWallProps> = (props) => {
  const { sectionHeader, image, title, button, subText, className } = props;
  const hasButton = button && button.primaryButtonText;

  const highlightCss = {
    color: theme.colors.blue,
    textDecoration: 'underline',
    textUnderlinePosition: 'under',
    textDecorationThickness: theme.spacing['4'],
  };

  const highlightedTitle = useHighlightString(title, highlightCss);
  const { setIsFromJoinUsBtn, getProperImageUrl } = useGlobalState();

  const handleJoinUsButtonClick = (buttonEventLabel?: string) => {
    setIsFromJoinUsBtn(true);
    gtmClickRegistrationWall();
    buttonEventLabel && gtmClickLeadsElements(buttonEventLabel);
  };

  return (
    <OuterWallContainer className={className || ''}>
      <WallContainer>
        {/* HEADER */}
        {sectionHeader && (
          <HeaderContainer>
            <Lock width='32' height='32' />
            <HeaderInnerContainer>
              <Line />
              <Typography variant={TextStyles.Overline} style={{ color: theme.colors.mediumEmphasisBlack }}>
                {sectionHeader}
              </Typography>
              <Line />
            </HeaderInnerContainer>
          </HeaderContainer>
        )}
        {/* GRAPHICS */}
        {image && (
          <ImageWrapper>
            <Image
              src={getProperImageUrl(image)}
              alt={image.description || 'Registration Wall Graphic'}
              width={0}
              height={0}
              style={{
                width: '100%',
                height: 'auto',
                display: 'block',
              }}
              unoptimized
            />
          </ImageWrapper>
        )}
        {/* CTA */}
        <CTAContainer>
          <Typography variant={TextStyles['Heading 1']}>{highlightedTitle}</Typography>
          {hasButton && (
            <Button
              {...button}
              size={ButtonSize.Large}
              mobileSize={ButtonSize.Medium}
              onClick={() => handleJoinUsButtonClick(button.buttonEventLabel)}
            />
          )}
        </CTAContainer>
        {/* VALUES */}
        <ValuesContainer>
          {subText?.map((text: string, index: number) => (
            <ValuesInnerContainer key={index}>
              <Circle>
                <Check />
              </Circle>
              <Typography variant={TextStyles['Body Text Large']}>{text}</Typography>
            </ValuesInnerContainer>
          ))}
        </ValuesContainer>
      </WallContainer>
    </OuterWallContainer>
  );
};

export default RegisWall;

import { type Dispatch, type SetStateAction, useEffect, useState } from 'react';
import { onSnapshot, query } from 'firebase/firestore';
import type { IShipment } from '@/modules/pmt/interfaces';
import { queryBuilder } from '@/modules/pmt/services';
import { shipmentConverter } from '@/modules/pmt/mappers';
import { useShipmentFiltersContext } from '@/modules/pmt/context';
import { FILTER_OPTIONS, SORT_OPTIONS } from '@/modules/pmt/constants';

const DEFAULT_DELIVERED_SHIPMENTS_QUERY_LIMIT = 3;

const useDeliveredShipments = (
  deletingShipmentsRef: React.MutableRefObject<Set<string>>,
  shipments: {
    overview: IShipment[];
    delivered: IShipment[];
  },
  setShipments: Dispatch<
    SetStateAction<{
      overview: IShipment[];
      delivered: IShipment[];
    }>
  >,
) => {
  const [queryLimit, setQueryLimit] = useState(DEFAULT_DELIVERED_SHIPMENTS_QUERY_LIMIT);
  const { filterConfig } = useShipmentFiltersContext();
  const showDeliveredShipments =
    filterConfig.currentFilter === FILTER_OPTIONS.ALL && filterConfig.currentSort === SORT_OPTIONS.DELIVERY_DATE;

  const fetchMore = () => {
    setQueryLimit((prev) => prev + DEFAULT_DELIVERED_SHIPMENTS_QUERY_LIMIT);
  };

  useEffect(() => {
    if (!showDeliveredShipments) return;
    const deliveredShipmentsQuery = queryBuilder.buildDeliveredShipmentsQuery({
      queryLimit: queryLimit + 1,
    });

    if (!deliveredShipmentsQuery) return;

    const unsub = onSnapshot(
      query(deliveredShipmentsQuery.withConverter(shipmentConverter)),
      (snapshot) => {
        const docChanges = snapshot.docChanges();

        docChanges.forEach((change) => {
          const { newIndex, oldIndex, type, doc } = change;
          if (type === 'removed') {
            setShipments((prev) => ({
              ...prev,
              delivered: prev.delivered.slice(0, oldIndex).concat(prev.delivered.slice(oldIndex + 1)),
            }));
            deletingShipmentsRef.current.delete(doc.id);
          }

          const data = {
            ...doc.data(),
            isDeleting: deletingShipmentsRef.current.has(doc.id),
          };

          if (type === 'added') {
            setShipments((prev) => {
              const existed = prev.delivered.some((shipment) => shipment.data.firestoreDocId === doc.id);
              if (existed) return prev;

              return {
                ...prev,
                delivered: [...prev.delivered.slice(0, newIndex), data, ...prev.delivered.slice(newIndex)],
              };
            });
          }
          if (type === 'modified') {
            setShipments((prev) => {
              if (oldIndex !== newIndex) {
                const temp = prev.delivered[oldIndex];
                prev.delivered[oldIndex] = prev.delivered[newIndex];
                prev.delivered[newIndex] = temp;
              } else {
                prev.delivered[oldIndex] = data;
              }

              return { ...prev };
            });
          }
        });
      },
      (error) => {
        console.error('Could not fetch delivered shipments', error);
      },
    );

    return () => unsub();
  }, [deletingShipmentsRef, queryLimit, setShipments, showDeliveredShipments]);

  return {
    queryLimit,
    fetchMore,
    hasMore: shipments.delivered.length > queryLimit,
    noDeliveredShipmentsFound: !shipments.delivered.length,
  };
};

export { useDeliveredShipments };

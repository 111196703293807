import { Breadcrumbs, Container, PageOverviewBanner, RightSection } from '@/components';
import { ContentTypeName } from '@/constants';
import type { IPageOverviewBanner, ITradelane } from '@/interfaces';
import { capitalize } from '@/utils';
import SectionListRenderer from '../SectionListRenderer';
import { LeftSection, SectionListWrapper } from '../SectionListRenderer.styles';
import { PageTitle } from './TradelanePage.styles';

const FULL_WIDTH_SECTION_LIST = [ContentTypeName.LeadsWrapper];

interface ITradelanePageRendererProps {
  pageTitle?: ITradelane['pageTitle'];
  sectionList?: ITradelane['sectionList'];
  rightSectionList: ITradelane['rightSectionList'];
  breadcrumbsTheme?: ITradelane['breadcrumbsTheme'];
  showPageTitle?: boolean;
}

const TradelanePageRenderer: React.FC<ITradelanePageRendererProps> = ({
  pageTitle = '',
  sectionList = [],
  rightSectionList,
  breadcrumbsTheme,
  showPageTitle = true,
}) => {
  const foundPageOverviewBanner: IPageOverviewBanner = sectionList.find(
    (section: any) => capitalize(section.__typename) === ContentTypeName.PageOverviewBanner,
  );

  const sections = sectionList.reduce<{
    fullWidthSectionList: any[];
    leftSectionList: any[];
  }>(
    (result, section) => {
      const contentType = capitalize(section.__typename) as ContentTypeName;
      if (section === foundPageOverviewBanner) return result;

      const isFullWidthSection = FULL_WIDTH_SECTION_LIST.includes(contentType);

      if (isFullWidthSection) {
        result.fullWidthSectionList.push(section);
      } else {
        result.leftSectionList.push(section);
      }

      return result;
    },
    {
      fullWidthSectionList: [],
      leftSectionList: [],
    },
  );

  return (
    <>
      {foundPageOverviewBanner && (
        <PageOverviewBanner
          priority
          breadcrumbs={{
            title: pageTitle,
            breadcrumbsTheme,
          }}
          {...foundPageOverviewBanner}
        />
      )}
      <Container>
        <SectionListWrapper>
          <LeftSection>
            {!foundPageOverviewBanner && <Breadcrumbs title={pageTitle} breadcrumbsTheme={breadcrumbsTheme} />}
            {showPageTitle && pageTitle && (
              <PageTitle $hasPageOverviewBanner={!!foundPageOverviewBanner}>{pageTitle}</PageTitle>
            )}
            <SectionListRenderer sectionList={sections.leftSectionList} />
          </LeftSection>
          {rightSectionList && <RightSection rightSectionList={rightSectionList} />}
        </SectionListWrapper>
      </Container>

      <SectionListRenderer sectionList={sections.fullWidthSectionList} />
    </>
  );
};

export default TradelanePageRenderer;

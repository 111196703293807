import useGlobalState from '@/hooks/useGlobalState.hooks';
import Image from 'next/image';
import { ButtonSize, ButtonVariant } from '../../interfaces';
import { getStylingOfVariant } from '../../utils';
import { LoginButton, Logo, Text } from './VariantStyles/GoogleBranded.styles';

type TGoogleBrandedButtonProps = {
  primaryButtonText?: string;
  secondaryButtonText?: string;
  buttonLink?: string;
};

const getPathNameAndUrl = (url?: string) => {
  if (!url) return '';
  try {
    const parsedURL = new URL(url);
    return `${parsedURL.pathname}${parsedURL.search}`;
  } catch {
    return '';
  }
};

const GoogleBrandedButton = ({ primaryButtonText, secondaryButtonText, buttonLink }: TGoogleBrandedButtonProps) => {
  const { userInfo } = useGlobalState();

  if (!userInfo || !userInfo.emailSyncOptIn) {
    return (
      <LoginButton href={getPathNameAndUrl(buttonLink)}>
        <Logo>
          <Image src='/images/google-logo.svg' alt='google-logo' width={24} height={24} />
        </Logo>
        <Text>{!userInfo ? primaryButtonText : secondaryButtonText}</Text>
      </LoginButton>
    );
  }

  const { ButtonLink } = getStylingOfVariant(ButtonVariant.Primary);
  return (
    <ButtonLink size={ButtonSize.Large} href='/track-my-parcel'>
      View Parcels
    </ButtonLink>
  );
};

export { GoogleBrandedButton };

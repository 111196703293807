import type { IMenuItem } from '@/interfaces';
import { DescriptionContainer, DescriptionText } from './Description.styles';

const Description: React.FC<{ data: IMenuItem['description'] }> = ({ data }) => {
  return (
    <DescriptionContainer>
      <DescriptionText>{data}</DescriptionText>
    </DescriptionContainer>
  );
};

export default Description;

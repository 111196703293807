import styled from 'styled-components';

export const LoginButton = styled.a(({ theme }) => ({
  height: 48,
  padding: `${theme.spacing[2]} 0 ${theme.spacing[2]} ${theme.spacing[2]}`,
  color: 'white',
  textAlign: 'center',
  backgroundColor: '#5383EC',
  border: 'none',
  boxShadow: '0px 2px 4px 0px rgba(0,0,0,0.25)',
  borderRadius: 4,
  cursor: 'pointer',
  alignItems: 'center',
  display: 'inline-flex',
  textTransform: 'uppercase',
}));

export const Logo = styled.span(({ theme }) => ({
  width: 44,
  height: 44,
  borderRadius: 2,
  background: theme.colors.highEmphasisWhite,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const Text = styled.span(({ theme }) => ({
  display: 'block',
  fontWeight: 600,
  lineHeight: '100%',
  padding: `0 ${theme.spacing[14]}`,
  flex: 1,
}));

import styled from 'styled-components';

export const GraphicImgContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  overflow: hidden;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    width: fit-content;
  }
`;

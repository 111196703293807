import styled from 'styled-components';

export const LogoWrapper = styled.div(({ theme }) => ({
  flex: 'none',
  borderRadius: 6,
  width: 80,
  height: 80,
  overflow: 'hidden',
  position: 'relative',
  border: `1px solid ${theme.colors.surface05}`,
  marginRight: theme.spacing[20],
  boxShadow: '0px 1.5px 2.5px 0px rgba(96, 97, 112, 0.16), 0px 0px 0.5px 0px rgba(40, 41, 61, 0.04)',

  // For no logo
  color: theme.colors.pressedBlack,
  backgroundColor: theme.colors.surface04,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

import type { ConnectFailedReason } from '@/modules/pmt/components/ESConnectFailedDialog';
import { type Dispatch, type SetStateAction, createContext, useContext, useMemo } from 'react';
import { useEmailSyncAccounts, useIsEmailSyncAllowed } from '../hooks';
import type { TEmailSyncData } from '../hooks/useEmailSyncAccounts';

interface IEmailSyncContext {
  emailSyncData?: TEmailSyncData;
  isEmailSyncAllowed: boolean;
  isESAllowed: 'unitialized' | 'allowed' | 'not-allowed';
  hasEmailAccounts: boolean;
  hasEmailConnected: boolean;
  refetchEmailSyncAccounts: () => Promise<void>;
  connectEsFailedReason: ConnectFailedReason | null;
  setConnectEsFailedReason: Dispatch<SetStateAction<IEmailSyncContext['connectEsFailedReason']>>;
  syncingAccount: string | null;
  setSyncingAccount: Dispatch<SetStateAction<IEmailSyncContext['syncingAccount']>>;
}

const EmailSyncContext = createContext<IEmailSyncContext>({
  emailSyncData: undefined,
  isEmailSyncAllowed: false,
  isESAllowed: 'unitialized',
  hasEmailAccounts: false,
  hasEmailConnected: false,
  refetchEmailSyncAccounts: async () => Promise.resolve(),
  connectEsFailedReason: null,
  setConnectEsFailedReason: () => null,
  syncingAccount: null,
  setSyncingAccount: () => null,
});

export const EmailSyncProvider: React.FC = ({ children }) => {
  const {
    emailSyncData,
    refetchEmailSyncAccounts,
    connectEsFailedReason,
    setConnectEsFailedReason,
    syncingAccount,
    setSyncingAccount,
  } = useEmailSyncAccounts();

  const hasEmailAccounts = !!emailSyncData?.emails?.length;
  const hasEmailConnected = !!emailSyncData?.hasEmailConnected;
  const { isEmailSyncAllowed, isESAllowed } = useIsEmailSyncAllowed({ emailSyncData, hasEmailAccounts });

  const value = useMemo(
    () => ({
      emailSyncData,
      isEmailSyncAllowed,
      isESAllowed,
      hasEmailAccounts,
      hasEmailConnected,
      refetchEmailSyncAccounts,
      connectEsFailedReason,
      setConnectEsFailedReason,
      syncingAccount,
      setSyncingAccount,
    }),
    [
      connectEsFailedReason,
      emailSyncData,
      hasEmailAccounts,
      hasEmailConnected,
      isEmailSyncAllowed,
      isESAllowed,
      refetchEmailSyncAccounts,
      setConnectEsFailedReason,
      syncingAccount,
      setSyncingAccount,
    ],
  );

  return <EmailSyncContext.Provider value={value}>{children}</EmailSyncContext.Provider>;
};

export const useEmailSyncContext = () => useContext(EmailSyncContext);

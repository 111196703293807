import { useEffect, useState } from 'react';
import { useResponsive } from '@/hooks';
import { ResponsiveCollection } from '@/interfaces';

const useOverflowing = ({
  ref,
  limit = 1,
  lineHeight = 22, // Body Text Small
}: {
  ref: React.RefObject<HTMLDivElement>;
  limit?: number;
  lineHeight?: number;
}) => {
  const [overflowing, setOverflowing] = useState(false);
  const { isDesktop } = useResponsive([ResponsiveCollection.Desktop]);

  useEffect(() => {
    if (ref.current) {
      const isOverflowing = ref.current.scrollHeight > limit * lineHeight;
      setOverflowing(isOverflowing);
    }
  }, [ref.current, limit, lineHeight, isDesktop]);

  return overflowing;
};

export default useOverflowing;

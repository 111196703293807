import { useEffect, useRef } from 'react';
import { ResponsiveCollection } from '@/interfaces';
import { useOverflowing, useResponsive } from '@/hooks';
import { Tooltip } from '@/components';
import { TextContainer } from './TooltipWrapper.styles';

type TooltipWrapperProps = {
  value: string;
  limit?: number;
  lineHeight?: number;
  TextComponent?: React.FC;
};

// TODO: Replace at other places + Edit this common component to match all use cases of other places
// TODO: Merge with Tooltip.tsx
// Places:
// - components/Chart/LineMetrics/Carrier/Carrier.tsx
// - components/Chart/Table/CustomTableComponents/TableText/TableText.tsx

/**
 * A common tooltip component with ref to check if text is overflowing. If overflow, show tooltip
 * @param value text to display inside the tooltip box
 * @param limit number of lines to limit the text to. If text is more than the limit, show tooltip box
 * @param lineHeight line-height of text
 * @param children text or component to display
 * @returns text with tooltip
 * @example
 * <TooltipWrapper value='Textttttttttt'>Textttttttttt</TooltipWrapper>
 * <TooltipWrapper value='Textttttttttt'>
 *   <Value>Textttttttttt</Value>
 * </TooltipWrapper>
 */
const TooltipWrapper: React.FC<TooltipWrapperProps> = ({
  value,
  limit = 1,
  lineHeight = 22, // Body Text Small
  children,
}) => {
  const tooltipTextRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);

  const isOverflowing = useOverflowing({
    ref: tooltipTextRef,
    limit,
    lineHeight,
  });
  const { isDesktop } = useResponsive([ResponsiveCollection.Desktop]);

  useEffect(() => {
    if (!isDesktop) return;

    if (textRef.current) {
      if (isOverflowing) {
        textRef.current.classList.add('has-tooltip');
      } else {
        textRef.current.classList.remove('has-tooltip');
      }
    }
  }, [isOverflowing, isDesktop]);

  const renderInnerText = () => {
    return <div ref={textRef}>{children}</div>;
  };

  const renderTooltipWithText = () => {
    const hasTooltipCondition = isOverflowing && isDesktop;
    if (hasTooltipCondition) {
      return <Tooltip content={value}>{renderInnerText()}</Tooltip>;
    }

    return renderInnerText();
  };

  return (
    <TextContainer ref={tooltipTextRef} limit={limit}>
      {renderTooltipWithText()}
    </TextContainer>
  );
};

export default TooltipWrapper;

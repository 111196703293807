import Cookies from 'js-cookie';
import { useMemo } from 'react';
import { CookieKey } from '@/constants';
import { gtmSessions } from '@/utils';
import { type ISessionData, LoginState } from '@/interfaces';
import { setAndCheckNewSession, getSessionData } from '@/utils/cookies.utils';

export const useTrackSessions = (isLoggedIn: boolean) => {
  const loginState = useMemo(() => {
    const browserUuid = Cookies.get(CookieKey.BrowserUuid);
    if (!isLoggedIn && !!browserUuid) return LoginState.Unverified;
    if (!isLoggedIn) return LoginState.NotLoggedIn;
    return LoginState.Login;
  }, [isLoggedIn]);

  const triggerGtmSessions = (
    isMyParcelsPage: boolean,
    pmtSessionData?: {
      emailSyncOptIn?: 'Yes' | 'No';
      numberOfEmailSyncAccounts?: number;
    },
  ) => {
    const handleSendSessionToGTM = (sessionsData: Array<ISessionData>) => {
      const isNewSession = setAndCheckNewSession(sessionsData);
      if (!isNewSession) return;
      const data = pmtSessionData ? { loginState, ...pmtSessionData } : { loginState };
      gtmSessions(data, isMyParcelsPage);
    };
    getSessionData(isMyParcelsPage, handleSendSessionToGTM);
  };

  return { triggerGtmSessions };
};

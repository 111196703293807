import type React from 'react';
import { Fragment } from 'react';
import Link from 'next/link';
import type { IMenuItem } from '@/interfaces';
import { TextStyles } from '@/constants';
import { Typography } from '@/components';
import { PageLinksWrapper, PageLink, PageLinkSeparator } from './PageLinks.styles';

interface IPageLinksProps {
  pageLinks: IMenuItem[];
  onLinkClick: (href?: string) => void;
  isOpenInNewTab: (href?: string) => '_blank' | undefined;
}

const PageLinks: React.FC<IPageLinksProps> = ({ pageLinks, onLinkClick, isOpenInNewTab }) => {
  return (
    <PageLinksWrapper>
      {pageLinks.map(({ title, url }, index) => {
        const isNotLastItem = index !== pageLinks?.length - 1;

        return (
          <Fragment key={index}>
            <Link href={url || '#'} passHref legacyBehavior>
              <PageLink target={isOpenInNewTab(url)} onClick={() => onLinkClick(url)}>
                <Typography type='span' variant={TextStyles['Body Text Small']}>
                  {title}
                </Typography>
              </PageLink>
            </Link>
            {isNotLastItem && <PageLinkSeparator />}
          </Fragment>
        );
      })}
    </PageLinksWrapper>
  );
};

export default PageLinks;

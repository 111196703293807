import styled from 'styled-components';
import { Button } from '@/components';

export const ThankYouWrapper = styled.div<{ hasImage: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  justify-content: ${({ hasImage }) => (hasImage ? 'space-between' : 'end')};
`;

export const Image = styled.img<{ isFullScreen?: boolean }>`
  display: block;
  height: ${({ isFullScreen }) => (isFullScreen ? '260' : '241')}px;
  max-width: 100%;
  object-fit: contain;
  margin: auto;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    max-height: 238px;
    height: auto;
    width: auto;
  }
`;

export const ImageWrapper = styled.div<{ isFullScreen?: boolean }>`
  margin: 0 auto;
  display: flex;
  margin-top: ${({ theme }) => theme.spacing[24]};
  margin-bottom: ${({ theme }) => theme.spacing[24]};
  height: ${({ isFullScreen }) => (isFullScreen ? '326' : '250')}px;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    flex: 1;
    height: 295px;
    margin-top: ${({ theme }) => theme.spacing[16]};
  }
`;

export const CustomButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing[16]};
  width: 100%;
`;

import { EUCountryCodes, ErrorMessages, MessageField } from '@/constants';
import type { IGeo } from '@/interfaces';

// No country = show QC
const isEU = (geoInfo: IGeo) => {
  if (geoInfo.country && !EUCountryCodes.includes(geoInfo.country)) return false;
  return true;
};

const fetchGeoLocationFromIpInfo = async (token: string): Promise<IGeo> => {
  const response = await fetch(`https://ipinfo.io?token=${token}`);

  if (response.ok) {
    const ipInfoObject = await response.json();
    return { ...ipInfoObject, fetched: true };
  }

  throw new Error(ErrorMessages[MessageField.GEO_INFO_ERROR].error);
};

const fetchGeoLocationFromIp2c = async (): Promise<IGeo> => {
  const response = await fetch('https://ip2c.org/s', {
    signal: AbortSignal.timeout(2000),
  });

  if (response.ok) {
    const result = await response.text();
    if (typeof result !== 'string') {
      throw new Error(ErrorMessages[MessageField.GEO_INFO_INVALID].error);
    }

    const [responseStatus, countryCode] = result.split(';');
    if (responseStatus === '1') {
      return { country: countryCode, fetched: true };
    }
  }

  throw new Error(ErrorMessages[MessageField.GEO_INFO_INVALID].error);
};

export { isEU, fetchGeoLocationFromIpInfo, fetchGeoLocationFromIp2c };

import dynamic from 'next/dynamic';

export enum RichTextEntries {
  Button = 'Button',
  LeadGenBanner = 'LeadGenBanner',
  RegistrationWall = 'RegistrationWall',
  People = 'People',
}

export enum ContentTypeName {
  HighlightSection = 'HighlightSection',
  WordCloud = 'WordCloud',
  Ads = 'Ads',
  ThumbnailSection = 'ThumbnailSection',
  ThumbnailCarousel = 'ThumbnailCarousel',
  LeadGenBanner = 'LeadGenBanner',
  SeoSection = 'SeoSection',
  EventList = 'EventList',
  Wrapper = 'Wrapper',
  PageOverviewBanner = 'PageOverviewBanner',
  CarrierSection = 'CarrierSection',
  LeadsWrapper = 'LeadsWrapper',
  Carrier = 'Carrier',
  Article = 'Article',
  Tag = 'Tag',
  Market = 'Market',
  Tradelane = 'Tradelane',
}

export enum ContentTypeIds {
  Asset = 'Asset',
  ads = 'ads',
  article = 'article',
  button = 'button',
  carrier = 'carrier',
  chart = 'chart',
  company = 'company',
  companyDirectory = 'companyDirectory',
  contentItem = 'contentItem',
  event = 'event',
  eventList = 'eventList',
  footer = 'footer',
  header = 'header',
  highlightSection = 'highlightSection',
  leadGenBanner = 'leadGenBanner',
  leadsWrapper = 'leadsWrapper',
  market = 'market',
  notificationTranslation = 'notificationTranslation',
  onboardingPopup = 'onboardingPopup',
  people = 'people',
  pmtPage = 'pmtPage',
  registrationWall = 'registrationWall',
  retailer = 'retailer',
  seo = 'seo',
  seoSection = 'seoSection',
  tag = 'tag',
  thumbnailCarousel = 'thumbnailCarousel',
  thumbnailSection = 'thumbnailSection',
  tradelane = 'tradelane',
  mainTranslation = 'mainTranslation',
  trackingTranslation = 'trackingTranslation',
  wordCloud = 'wordCloud',
  formField = 'formField',
  form = 'form',
  landingPage = 'landingPage',
  wrapper = 'wrapper',
  pageOverviewBanner = 'pageOverviewBanner',
  pmtOnboardingPopup = 'pmtOnboardingPopup',
  contentCard = 'contentCard',
}

export const entryPages: ContentTypeIds[] = [
  ContentTypeIds.article,
  ContentTypeIds.carrier,
  ContentTypeIds.companyDirectory,
  ContentTypeIds.event,
  ContentTypeIds.landingPage,
  ContentTypeIds.pmtPage,
  ContentTypeIds.tag,
  ContentTypeIds.market,
];

export const entryMaster: ContentTypeIds[] = [ContentTypeIds.retailer, ContentTypeIds.tradelane];

export const ContentTypeRichText: {
  [key: string]: string;
} = {
  [ContentTypeIds.article]: 'content',
  [ContentTypeIds.event]: 'content',
  [ContentTypeIds.landingPage]: 'content',
  [ContentTypeIds.wrapper]: 'content',
};

export enum DataPickerContentType {
  carrier = 'carrier',
  article = 'article',
  event = 'event',
  market = 'market',
  retailer = 'retailer',
}

export const ContentTypeMapping: {
  [key in ContentTypeName]?: React.ComponentType<any>;
} = {
  [ContentTypeName.HighlightSection]: dynamic(async () => import('../components/HighlightSection')),
  [ContentTypeName.WordCloud]: dynamic(async () => import('../components/WordCloud')),
  [ContentTypeName.Ads]: dynamic(async () => import('../components/PMCAdsense')),
  [ContentTypeName.ThumbnailSection]: dynamic(async () => import('../components/ThumbnailSection')),
  [ContentTypeName.ThumbnailCarousel]: dynamic(async () => import('../components/ThumbnailCarousel')),
  [ContentTypeName.LeadGenBanner]: dynamic(async () => import('../components/LeadGenBanner')),
  [ContentTypeName.SeoSection]: dynamic(async () => import('../components/SEOSection')),
  [ContentTypeName.EventList]: dynamic(async () => import('../components/EventList')),
  [ContentTypeName.Wrapper]: dynamic(async () => import('../components/Wrapper')),
  [ContentTypeName.PageOverviewBanner]: dynamic(async () => import('../components/PageOverviewBanner')),
  [ContentTypeName.CarrierSection]: dynamic(async () => import('../components/DataSection')),
  [ContentTypeName.LeadsWrapper]: dynamic(async () => import('../components/LeadsWrapper')),
};

export enum CategoryContentTypeIds {
  carrier = 'carrier',
}

export const CategoryTypes: {
  [key in CategoryContentTypeIds]: {
    plural: string;
  };
} = {
  [CategoryContentTypeIds.carrier]: {
    plural: 'carriers',
  },
};

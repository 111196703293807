import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useBlockLayout, usePagination, useTable } from 'react-table';
import { lightTableTheme, LANDING_PAGE_PAGE_SIZE } from '@/constants';
import { type ITable, TableVariant } from '@/interfaces';
import { checkForUniqueCell } from '@/utils';
import TableHeaderText from './CustomTableComponents/TableHeaderText';
import Pagination from './Pagination';
import {
  TH,
  TR,
  TD,
  Table,
  TableChild,
  CellSkeleton,
  TableAndChildWrapper,
  CellSkeletonContainer,
  TablePaginationWrapper,
  TableClonedRowsContainer,
} from './Table.styles';
import { getRenderConditionsForChart } from '@/utils';

// TODO: Refractor Registration Wall

/** A common table component
 * @param data data to be displayed in the table
 * @param child data of paywall or other component
 * @param columns header of the table
 * @param Component component to be displayed below the table's body, e.g Paywall, Registration Wall
 * @param variant type of table (Metrics, Section)
 * @param pageSize number of rows per page
 * @param pseudoPageCount number of fake pages to be displayed in the pagination, maximum is 5
 * @param tableTheme theme of some table elements (header's bg, cell's padding, etc.)
 * @param minimumRecord minimum number of records to be displayed, if below, show paywall or other component
 * @param hasData if the table has actual data
 * @param pagination pagination props
 * @returns Table component
 * @example
 * <Table
 *  columns={[
 *    {
 *      Header: <CustomHeader></CustomHeader>,
 *      accessor: 'logoLink',
 *      tooltip: '',
 *      columnWidth: '80px',
 *    },
 *    {
 *      Header: <CustomHeader>carrierName</CustomHeader>,
 *      accessor: 'carrierName',
 *      tooltip: '',
 *      columnWidth: '100%',
 *    },
 *    {
 *      Header: <CustomHeader>Tradelane</CustomHeader>,
 *      tooltip: 'Dis is the meaning of life',
 *      accessor: 'tradelane',
 *      columnWidth: '100%',
 *    },
 *  ]}
 *  data={[
 *   {
 *     logoLink: <LogoImage src='https://assets.parcelperform.com/pp-web-app-assets/logo-carriers/square-logo-carries/dhlexp.png' alt='DHL Express' />,
 *     carrierName: ( // has tooltip if text too long, and has URL if text short
 *       <TableText url='https://www.dhl.de/en/privatkunden/paket.html'>
 *         DHL Paket
 *       </TableText>
 *     ),
 *   },
 *   {
 *     logoLink: <LogoImage src='https://assets.parcelperform.com/pp-web-app-assets/logo-carriers/square-logo-carries/dhlexp.png' alt='DHL Express' />,
 *     carrierName: ( // has tooltip if text too long, but has NO URL if text short
 *       <TableText>
 *         DHL Paket
 *       </TableText>
 *     ),
 *   },
 *  ]}
 *  Component={RegistrationWall}
 *  tableTheme={darkTableTheme}
 * />
 */
const CommonTable: React.FC<ITable> = ({
  data,
  child,
  columns,
  Component,
  variant = TableVariant.Metrics,
  pageSize = LANDING_PAGE_PAGE_SIZE,
  pseudoPageCount,
  tableTheme = lightTableTheme,
  // For inner component rendering
  minimumRecord,
  hasData = true,
  // For pagination pages rendering
  pagination,
}) => {
  //#region STATE
  const [hideFirstRowRef, setHideFirstRowRef] = useState(false);
  const [arrowIndex, setArrowIndex] = useState(0);
  const memoizedColumns = useMemo(() => columns, [columns]);
  const memoizedData = useMemo(() => data, [data]);
  const tableRef = useRef<HTMLDivElement>(null);
  const tableChildRef = useRef<HTMLDivElement>(null);
  const firstRowRef = useRef<HTMLDivElement>(null);
  const tableClonedRowsRef = useRef<HTMLDivElement | null>(null);
  const [clonedRowArray, setClonedRowArray] = useState<Array<JSX.Element | null>>([]);
  const tableProps = useMemo(() => {
    const defaultProps = {
      columns: memoizedColumns,
      data: memoizedData,
      initialState: { pageIndex: 0, pageSize },
    };

    if (!pagination) return defaultProps;

    return {
      ...defaultProps,
      manualPagination: true,
      pageCount: pagination?.totalPage,
    };
  }, [data]);

  const {
    page,
    rows,
    gotoPage: goToPage,
    pageCount,
    prepareRow,
    headerGroups,
    getTableProps,
    getTableBodyProps,
    state: { pageIndex },
  } = useTable(tableProps, useBlockLayout, usePagination);
  //#endregion

  //#region VARIABLES & MEMO
  const hasPagination = variant === TableVariant.Section;
  const tableRows = hasPagination ? page : rows;
  const { hasActualData, shouldRenderInnerComponent } = getRenderConditionsForChart({
    data,
    child,
    Component,
    minimumRecord,
    hasData,
  });

  const { _pageCount, pageCountDiff } = useMemo(() => {
    const hasPseudoPageCount = !!(shouldRenderInnerComponent && pseudoPageCount && pseudoPageCount > pageCount);
    const _pageCount = hasPseudoPageCount ? pseudoPageCount : pageCount;
    const pageCountDiff = Math.abs(_pageCount - pageCount);
    return {
      _pageCount,
      pageCountDiff,
    };
  }, []);

  const _shouldRenderInnerComponent = useMemo(() => {
    if (!hasPagination) return shouldRenderInnerComponent;
    // Should only render paywall at the last page with data
    return shouldRenderInnerComponent && pageIndex === pageCount - 1;
  }, [pageIndex]);
  //#endregion

  //#region HOOKS & FUNCTIONS
  const getPaginationProps = () => {
    const dataLength = pagination?.count ?? data.length;

    return {
      pageSize,
      goToPage: async (pageIndex: number) => {
        const realPageIndex = pageCount - 1;
        const isPseudoPage = pageIndex > realPageIndex;
        if (isPseudoPage) {
          return;
        }

        if (pagination?.fetchData) {
          await pagination.fetchData(pageIndex);
        }

        goToPage(pageIndex);
      },
      pageCount: _pageCount,
      pageIndex,
      totalRows: dataLength,
      onArrowClicked: handleArrowClicked,
    };
  };

  const renderSkeletonRows = useCallback((height: number, numberOfRows = 1) => {
    if (!firstRowRef.current?.innerHTML) return;
    const smallestHeightRow = firstRowRef.current;

    if (smallestHeightRow) {
      const row = tableRows[0];
      prepareRow(row);
      const clonedRow = (
        <TR {...row.getRowProps()}>
          {row.cells.map((cell, cellIdx) => {
            const columnId = cell.column.id;
            const { isUniqueField, containsLogo } = checkForUniqueCell({
              variant,
              columnId,
            });

            return (
              <TD
                {...cell.getCellProps({
                  columnWidth: (cell.column as any).columnWidth,
                } as any)}
                key={cellIdx}
              >
                {isUniqueField ? (
                  <CellSkeletonContainer columnId={columnId}>
                    {cell.render('Cell')}
                    <CellSkeleton containsLogo={containsLogo} />
                  </CellSkeletonContainer>
                ) : (
                  cell.render('Cell')
                )}
              </TD>
            );
          })}
        </TR>
      );
      const clonedRows = new Array(numberOfRows).fill(clonedRow);
      setClonedRowArray(clonedRows);
      if (tableChildRef.current) {
        tableChildRef.current.style.height = `${height * numberOfRows}px`;
        tableChildRef.current.style.opacity = '1';
        tableChildRef.current.style.pointerEvents = 'auto';
      }
    }
  }, []);

  const handleArrowClicked = useCallback((index: number) => {
    setArrowIndex(index);
  }, []);

  useEffect(() => {
    if (!firstRowRef.current || !tableChildRef.current || !_shouldRenderInnerComponent) return;

    const resizeObserver = new ResizeObserver(() => {
      const height = firstRowRef.current ? firstRowRef.current.clientHeight : 80;
      // calculate the height of the tableChildRef, e.g. calculate height of Paywall
      const tableChildRefHeight = tableChildRef.current?.clientHeight || 0;
      // calculate how many rows are needed to fill the tableChildRef
      const realDataLength = hasData ? data?.length : 0;

      let baseBlurredRows = 0;

      if (hasPagination) {
        // If minimumRecord is 100, but the remaining data of last page is 3 items, then the blurred rows should be 15 - 3 = 12 rows. We don't care about minimumRecord for displaying the blurred rows
        const baseBlurredRowsOfLastPagination = pageSize - (realDataLength % pageSize);
        baseBlurredRows = baseBlurredRowsOfLastPagination;
      } else {
        baseBlurredRows = minimumRecord ? minimumRecord - realDataLength : 0;
      }

      const minimumBlurredRows = Math.ceil(tableChildRefHeight / height);
      const numberOfRows = Math.max(baseBlurredRows, minimumBlurredRows);
      // render the needed skeleton rows, based on the number of rows needed

      renderSkeletonRows(height, numberOfRows);
      // remove 1st row if has no actual data
      if (!hasActualData) {
        setHideFirstRowRef(true);
        if (firstRowRef.current?.innerHTML) firstRowRef.current.innerHTML = '';
      }
    });
    resizeObserver.observe(firstRowRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, [pageIndex, arrowIndex]);

  // detect when scrollbar horizontally appears in Table component
  useEffect(() => {
    if (!tableRef.current) return;
    const table = tableRef.current;

    if (table.scrollWidth > table.clientWidth) {
      table.classList.add('scrollable');
    }
  }, []);
  //#endregion

  //#region RENDER
  // condition = !data?.length: for common Table
  // condition = !hasActualData && !shouldRenderInnerComponent: for Table Metrics
  if (!data?.length || (!hasActualData && !shouldRenderInnerComponent)) {
    return null;
  }

  return (
    <div>
      <TableAndChildWrapper>
        <Table ref={tableRef} {...getTableProps()} hasPagination={hasPagination}>
          <div>
            {/* REAL DATA HEAD */}
            {headerGroups.map((headerGroup, headerGroupIdx) => (
              <TR {...headerGroup.getHeaderGroupProps()} key={headerGroupIdx}>
                {headerGroup.headers.map((column, columnIdx) => (
                  <TH
                    {...column.getHeaderProps({
                      columnWidth: (column as any).columnWidth,
                    } as any)}
                    accessor={column.id}
                    tableTheme={tableTheme}
                    key={`${headerGroupIdx}-${columnIdx}`}
                  >
                    <TableHeaderText content={(column as any).tooltip}>{column.render('Header')}</TableHeaderText>
                  </TH>
                ))}
              </TR>
            ))}
          </div>
          <div {...getTableBodyProps()}>
            {/* REAL DATA ROWS */}
            {tableRows.map((row, rowIdx) => {
              prepareRow(row);
              return (
                <TR
                  {...row.getRowProps()}
                  tableTheme={tableTheme}
                  key={rowIdx + '-' + pageIndex}
                  ref={rowIdx === 0 ? firstRowRef : null}
                  hasNoActualData={hideFirstRowRef}
                >
                  {row.cells.map((cell, cellIdx) => {
                    return (
                      <TD
                        {...cell.getCellProps({
                          columnWidth: (cell.column as any).columnWidth,
                        } as any)}
                        accessor={cell.column.id}
                        key={cellIdx}
                      >
                        {cell.render('Cell')}
                      </TD>
                    );
                  })}
                </TR>
              );
            })}
            {/* CLONED ROWS: for paywall or other component */}
            {_shouldRenderInnerComponent && (
              <TableClonedRowsContainer ref={tableClonedRowsRef}>
                {clonedRowArray.map((row, idx) => (
                  <Fragment key={idx}>{row}</Fragment>
                ))}
              </TableClonedRowsContainer>
            )}
          </div>
        </Table>
        {/* POSITIONED COMPONENT: for paywall or other component */}
        {Component && _shouldRenderInnerComponent && (
          <TableChild ref={tableChildRef} hasPagination={hasPagination}>
            <Component {...child} />
          </TableChild>
        )}
      </TableAndChildWrapper>
      {/* PAGINATION */}
      {hasPagination && (
        <TablePaginationWrapper pageCount={_pageCount} arrowIndex={arrowIndex} pageCountDiff={pageCountDiff}>
          <Pagination {...getPaginationProps()} />
        </TablePaginationWrapper>
      )}
    </div>
  );
  //#endregion
};

export default CommonTable;

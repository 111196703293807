import styled from 'styled-components';
import { theme } from '@/constants';
import { styledText } from '@/components/Typography/Typography.styles';
import Label from '../../Label';

export const filledInputStyle = `
  top: -11px;
  transform: translateY(0);
  transform-origin: top left;
  left: ${theme.spacing[8]};
  background: ${theme.colors.highEmphasisWhite};

  ${styledText['Body Text Small']}
`;

export const TextInput = styled.textarea<{ hasValue: boolean }>`
  resize: none;
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  border: none;
  background: none;
  z-index: 1;
  position: relative;
  color: ${theme.colors.highEmphasisBlack};
  ${styledText['Body Text Large']}
  padding-right: ${theme.spacing[10]};
  overflow-y: scroll;
  overflow-y: overlay;

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${theme.colors.scrollBarThumb};
    border-radius: 8px;
  }

  :focus {
    outline: none;
  }

  ${({ hasValue }) =>
    hasValue &&
    `
    ~ ${Label} {
      ${filledInputStyle}
    }
  `}

  :focus ~ ${Label} {
    ${filledInputStyle}
    color: ${theme.colors.blue};
  }
`;

export const TextAreaWrapper = styled.div<{
  isInvalid: boolean;
}>`
  margin-top: ${({ theme }) => theme.spacing[10]};
  border: 1px solid ${theme.colors.inactiveBlack};
  padding: 9px ${theme.spacing[14]};
  padding-right: ${theme.spacing[4]};
  border-radius: 4px;
  overflow-x: hidden;
  overflow-x: visible;
  position: relative;

  &:focus-within {
    border: solid 2px ${theme.colors.blue};
  }

  ${({ isInvalid, theme }) =>
    isInvalid &&
    `
    border: solid 1px ${theme.colors.error} !important;

    &:focus-within ${Label}, ${Label} {
      color: ${theme.colors.error};
    }
  `}
`;

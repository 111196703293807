import styled from 'styled-components';

export const ToggleSpan = styled.span(({ theme }) => ({
  position: 'absolute',
  cursor: 'pointer',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: theme.colors.mediumEmphasisBlack,
  transition: '0.3s',
  borderRadius: 30,

  ':before': {
    content: '""',
    position: 'absolute',
    height: 8,
    width: 8,
    left: 2.7,
    top: '50%',
    transform: 'translateY(-50%)',
    backgroundColor: '#EFEFEF',
    borderRadius: '50%',
    transition: '0.3s',
  },
}));

export const Input = styled.input({
  width: 0,
  height: 0,
  opacity: 0,
  transition: '0.3s',
});

export const InputContainer = styled.label(({ theme }) => ({
  position: 'relative',
  display: 'block',
  width: 26.67,
  height: 13.33,
  minWidth: 26.67,

  [`${Input}:checked + ${ToggleSpan}`]: {
    backgroundColor: theme.colors.blue,
  },

  [`${Input}:checked + ${ToggleSpan}:before`]: {
    transform: 'translate(13.3px, -50%)',
  },
}));

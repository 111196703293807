import NextImage from 'next/image';
import Button from '@/components/Button';
import LinkedInFollowBtn from '@/components/LeadGenBanner/LinkedInFollowBtn';
import { ButtonSize, ButtonVariant } from '@/interfaces';
import styled from 'styled-components';

export const FollowUsWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: ${({ theme }) => theme.spacing[24]};
  flex-direction: column;
  align-items: center;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    justify-content: space-between;
  }
`;

export const Image = styled(NextImage).attrs({
  height: 178,
  width: 281,
})(({ theme }) => ({
  display: 'block',

  [`${theme.mediaBreakpointUp.md}`]: {
    height: 221,
    width: 351,
  },
}));

export const LinkedInWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.spacing[24]} 0;
  margin-bottom: ${({ theme }) => theme.spacing[24]};

  > div {
    position: absolute;
    top: 15%;
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin-bottom: 0;
    padding: 11px 0;

    > div {
      top: 12%;
    }
  }
`;

export const Iframe = styled(LinkedInFollowBtn)`
  width: 82px;
  height: 22px;
`;

export const SkipButton = styled(Button).attrs({
  size: ButtonSize.Large,
  variant: ButtonVariant.Link,
})`
  width: 100%;
  color: ${({ theme }) => theme.colors.pressedBlack};
  margin-top: auto;

  :hover {
    text-decoration: none !important;
    color: ${({ theme }) => theme.colors.inactiveBlack} !important;
  }
`;

export const arrayChunks = <T>(array: T[], chunkSize: number): T[][] => {
  return Array.from({ length: Math.ceil(array.length / chunkSize) }, (_, index) => {
    return array.slice(index * chunkSize, index * chunkSize + chunkSize);
  });
};

// Not dynamic yet
/**
 * Remove duplicate items from an array of Contentful entries/assets
 * @param array List of entries/assets
 * @returns Filtered array
 */
export const removeDuplicateCTFObjects = <T extends Record<string, any>>(array: T[]): T[] => {
  const matchedList = array.map((obj) => obj?.sys?.id);
  const filteredList = array.filter((obj, index) => !matchedList.includes(obj?.sys?.id, index + 1));
  return filteredList;
};

export const excludeUndefinedKey = (obj: Record<string, unknown>): Record<string, unknown> | null => {
  Object.keys(obj).forEach((key) => obj[key] === undefined && delete obj[key]);
  if (Object.keys(obj).length === 0) return null;
  return obj;
};

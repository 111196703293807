const spacing = {
  2: '0.125rem',
  4: '0.25rem',
  6: '0.375rem',
  8: '0.5rem',
  10: '0.625rem',
  12: '0.75rem',
  14: '0.875rem',
  16: '1rem',
  18: '1.125rem',
  20: '1.25rem',
  22: '1.375rem',
  24: '1.5rem',
  26: '1.625rem',
  28: '1.75rem',
  30: '1.875rem',
  32: '2rem',
  34: '2.125rem',
  36: '2.25rem',
  38: '2.375rem',
  40: '2.5rem',
  44: '2.75rem',
  46: '2.875rem',
  48: '3rem',
  56: '3.5rem',
  60: '3.75rem',
  64: '4rem',
  76: '4.75rem',
  80: '5rem',
  82: '5.125rem',
  90: '5.625rem',
};

const letterSpacing = {
  1: '0.01em',
  2: '0.02em',
  5: '0.05em',
  75: '0.0075em',
};

export { spacing, letterSpacing };

import styled from 'styled-components';
import { TextStyles } from '@/constants';
import { Typography } from '@/components';
import { styledText } from '@/components/Typography/Typography.styles';

const BANNER_IMAGE_HEIGHT = 160;
const SECTION_HORIZONTAL_PADDING = 16;
export const Container = styled.div`
  position: relative;
  background: ${({ theme }) => theme.colors.highEmphasisWhite};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    border-radius: 8px;
    box-shadow: ${({ theme }) => theme.colors.boxShadow};

    :not(:last-child) {
      margin-bottom: ${({ theme }) => theme.spacing[24]};
    }
  }
`;

export const CarrierInfoContainer = styled.div<{ hasDesktopBanner: boolean }>`
  word-break: break-word;
  padding: ${({ theme }) => `${theme.spacing[12]} ${theme.spacing[SECTION_HORIZONTAL_PADDING]}`};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    ${({ theme, hasDesktopBanner }) => !hasDesktopBanner && `margin-top: ${theme.spacing[76]};`}
  }
`;

export const CarrierBannerImageContainer = styled.div<{ isDesktop: boolean }>`
  display: ${({ isDesktop }) => (isDesktop ? 'none' : 'block')};
  position: relative;
  width: 100%;
  height: ${BANNER_IMAGE_HEIGHT}px;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: ${({ isDesktop }) => (isDesktop ? 'block' : 'none')};
    overflow: hidden;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
`;

export const CarrierLogoContainer = styled.div<{ hasDesktopBanner?: boolean }>`
  display: flex;
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.colors.boxShadow};
  overflow: hidden;
  margin-bottom: ${({ theme }) => theme.spacing[8]};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    width: 120px;
    height: 120px;
    position: absolute;
    top: ${({ hasDesktopBanner }) => (hasDesktopBanner ? BANNER_IMAGE_HEIGHT : 0)}px;
    left: ${SECTION_HORIZONTAL_PADDING}px;
    transform: translateY(-50%);
    margin-bottom: 0;
  }
`;

export const CarrierNameContainer = styled.div<{ hasCarrierLogo: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    ${({ hasCarrierLogo }) => hasCarrierLogo && 'margin-left: 136px;'}
  }
`;

export const CarrierName = styled(Typography).attrs({
  variant: TextStyles['Heading 1'],
})`
  color: ${({ theme }) => theme.colors.darkBlue};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CarrierButton = styled.a<{ isDesktop?: boolean }>`
  ${styledText['Body Text Small']}
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.hyperlink};
  display: ${({ isDesktop }) => (isDesktop ? 'none' : 'block')};
  margin-top: ${({ theme }) => theme.spacing[8]};

  :hover {
    color: ${({ theme }) => theme.colors.blue};
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: ${({ isDesktop }) => (isDesktop ? 'block' : 'none')};
    margin-top: 0;
    flex: none;
    margin-left: ${({ theme }) => theme.spacing[16]}; // not in design
  }
`;

export const DescriptionContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing[8]};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin-top: ${({ theme }) => theme.spacing[32]};
  }
`;

import getConfig from 'next/config';
import type { IIndividualCarrier, IMinifiedCarrier, IMinifiedCarrierByName } from '@/interfaces';

type CarrierList = Partial<IIndividualCarrier>[];

type ModifiedData = {
  featured: CarrierList;
  remaining: CarrierList;
};

export const sortCarrierList = ({
  manuallyAddedCarriers,
  allCarriers,
}: {
  manuallyAddedCarriers?: CarrierList;
  allCarriers?: CarrierList; // data get from Contentful should be sorted by latest publish date
}): CarrierList | null | undefined => {
  if (!manuallyAddedCarriers?.length && !allCarriers?.length) return null;
  if (!manuallyAddedCarriers?.length) return manuallyAddedCarriers;
  if (!allCarriers) return manuallyAddedCarriers;

  const clonedManuallyAddedCarriers = [...manuallyAddedCarriers];

  const modifiedData = allCarriers?.reduce(
    (result: ModifiedData, currentCarrier: Partial<IIndividualCarrier>) => {
      const _index = clonedManuallyAddedCarriers.findIndex(
        (carrier: Partial<IIndividualCarrier>) => carrier?.sys?.id === currentCarrier?.sys?.id,
      );

      if (_index > -1) {
        clonedManuallyAddedCarriers.splice(_index, 1);
        return result;
      }

      if (currentCarrier.featured) {
        result.featured = [...result.featured, currentCarrier];
      } else {
        result.remaining = [...result.remaining, currentCarrier];
      }

      return result;
    },
    {
      featured: [],
      remaining: [],
    },
  );

  return [...manuallyAddedCarriers, ...modifiedData.featured, ...modifiedData.remaining];
};

export const convertMinifiedCarriersDataFromRedis = (data?: IMinifiedCarrier[]): IMinifiedCarrierByName => {
  if (!data) return {};

  return data.reduce(
    (result, carrier: IMinifiedCarrier) => {
      if (!carrier) return result;
      result[carrier.carrierName] = carrier;
      return result;
    },
    <IMinifiedCarrierByName>{},
  );
};

export const getCarrierInfoByName = (
  name: string,
  carriers?: IMinifiedCarrierByName | null,
): IMinifiedCarrier | null => {
  if (!carriers) return null;
  return carriers[name];
};

const { publicRuntimeConfig } = getConfig();

export const getCarrierCollectionKey = () => {
  const env = publicRuntimeConfig.contentfulEnvironment;
  return `${env}_carriers`;
};

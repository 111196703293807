import type { NextRouter } from 'next/router';
import { ArticleTypeName, SlugRoutes } from '@/constants';
import { type IReportsPopup, ReportsPopupType } from '@/interfaces';

export const groupReportsPopupsByType = (data: any) => {
  if (!data?.length) return null;

  return data.reduce(
    (acc: any, item: any) => {
      const key = item.pageType || ReportsPopupType.homePage;
      return { ...acc, [key]: [...(acc[key] || []), item] };
    },
    <{ [pageType in ReportsPopupType]: IReportsPopup[] }>{},
  );
};

export const getReportPopupTypeByRouter = ({ asPath, query }: NextRouter): ReportsPopupType | undefined => {
  const pathNoParams = asPath.split('?')[0];

  if (pathNoParams === '/') return ReportsPopupType.homePage;

  if (pathNoParams.includes(SlugRoutes.companies)) return ReportsPopupType.carrierPages;

  if (query?.articleTypeSlug === ArticleTypeName.insights) return ReportsPopupType.insights;

  if (query?.articleTypeSlug === ArticleTypeName.reports) return ReportsPopupType.reports;

  if (query?.articleTypeSlug === ArticleTypeName.news) return ReportsPopupType.news;

  if (pathNoParams.includes(SlugRoutes.markets)) return ReportsPopupType.markets;
};

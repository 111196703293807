import React, { type HTMLAttributes } from 'react';
import { Container, IconContainer } from './Badge.styles';
import Typography from '../Typography';
import { TextStyles } from '@/constants';

interface BadgeProps {
  label: string;
  leftIcon?: any;
}

const Badge: React.FC<BadgeProps & HTMLAttributes<HTMLDivElement>> = ({ label, leftIcon, ...rest }) => {
  return (
    <Container {...rest}>
      {leftIcon && <IconContainer>{React.createElement(leftIcon)}</IconContainer>}
      <Typography variant={TextStyles.Overline}>{label}</Typography>
    </Container>
  );
};

export default Badge;

import styled from 'styled-components';
import { DetailsContainer, DetailsHeader, DetailsItem } from '../OverviewSection/OverviewSection.styles';

export const StyledDetailsItem = styled(DetailsItem)`
  ${DetailsHeader} {
    margin-bottom: 0;
  }

  > * + * {
    margin-top: ${({ theme }) => theme.spacing[8]};
  }
`;

export const StyledDetailsContainer = styled(DetailsContainer)`
  grid-auto-flow: initial;
`;

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Line } from 'react-chartjs-2';

import type { IChart } from '@/interfaces';
import ChartJsMetrics from '../ChartContainer/components/ChartJsMetrics';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
  Filler,
);

const TrendMetrics: React.FC<IChart> = (props) => {
  return <ChartJsMetrics ChartJsComponent={Line} {...props} />;
};

export default TrendMetrics;

import type { ArticleTypeName, PageType } from '@/constants';
import type { IEvent } from '@/interfaces';

import type { ThumbnailProps, IHighlightSection, IEventList } from '@/interfaces';

export enum SectionItemType {
  Event = 'Event',
  Article = 'Article',
}

export enum SectionArticleType {
  notSelected = 'Not selected',
  news = 'news',
  insights = 'insights',
  reports = 'reports',
}

export interface IThumbnailSection {
  mostRead: boolean;
  itemType: SectionItemType;
  tags?: any;
  articleType?: SectionArticleType;
  containsMarketTag?: boolean;
  buttonLink?: string;
  latestData: ThumbnailProps[];
  // This is the type of page which use Thumbnail section, e.g. LandingPage, TagPage.
  pageType: PageType;
  // Pagination for latestData
  pageInfo: {
    itemsPerPage: number;
    totalPages: number;
  };
  // tagDataType and tagDataSlug are used by Tag page only
  tagDataType?: ArticleTypeName;
  tagDataSlug?: string;
  foundHighlightSection: IHighlightSection | undefined | null;
  foundEventListSection?: IEventList;
  featuredEventList?: IEvent[];
  mostReadData?: ThumbnailProps[];
  featuredArticles?: ThumbnailProps[];
}

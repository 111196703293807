import styled from 'styled-components';

export const AdsenseSectionWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => `${theme.spacing[8]} ${theme.spacing[20]}`};
  background: ${({ theme }) => theme.colors.adBackground};
  overflow: hidden;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    border-top: none;
    box-shadow: none;
    border-radius: 0;
    padding: 0;
  }
`;

import { ButtonsWrapper, SocialSharingWrapper, Title, Button, ButtonIcon } from './SocialSharing.styles';
import LinkedInIcon from '../../public/images/icons/social-sharing/linkedin.svg';
import FacebookIcon from '../../public/images/icons/social-sharing/facebook.svg';
import EmailIcon from '../../public/images/icons/social-sharing/email.svg';
import { IconAlignment } from '@/interfaces';

interface ISocialSharing {
  url: string;
}

const SocialSharing: React.FC<ISocialSharing> = ({ url }) => (
  <SocialSharingWrapper>
    <Title>Share this with your network</Title>
    <ButtonsWrapper>
      <Button
        icon={() => (
          <ButtonIcon>
            <LinkedInIcon />
          </ButtonIcon>
        )}
        href={`https://www.linkedin.com/shareArticle?mini=true&url=${url}`}
        alignment={IconAlignment.Left}
      >
        POST
      </Button>
      <Button
        icon={() => (
          <ButtonIcon>
            <FacebookIcon />
          </ButtonIcon>
        )}
        href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
        alignment={IconAlignment.Left}
      >
        SHARE
      </Button>
      <Button
        icon={() => (
          <ButtonIcon>
            <EmailIcon />
          </ButtonIcon>
        )}
        href={`mailto:?body=${url}`}
        alignment={IconAlignment.Left}
      >
        EMAIL
      </Button>
    </ButtonsWrapper>
  </SocialSharingWrapper>
);

export default SocialSharing;

import { LanguageCode } from '@/interfaces';

export enum PageType {
  LandingPage = 'LandingPage',
  TagPage = 'TagPage',
  MarketPage = 'MarketPage',
}

export enum RouteName {
  Home = 'Home',
  ArticleSummary = 'ArticleSummary',
  Article = 'Article',
  SignInWithLinkedIn = 'SignInWithLinkedIn',
  Verify = 'Verify',
  TrackMyParcelEn = 'TrackMyParcelEn',
  TrackMyParcelMultiLanguages = 'TrackMyParcelMultiLanguages',
  UnsubscribeAllEn = 'UnsubscribeAllEn',
  UnsubscribeAllMultiLanguages = 'UnsubscribeAllMultiLanguages',
  UnsubscribeSummaryNotificationEn = 'UnsubscribeSummaryNotificationEn',
  UnsubscribeSummaryNotificationMultiLanguages = 'UnsubscribeSummaryNotificationMultiLanguages',
  Carriers = 'Carriers',
  IndividualCarrier = 'IndividualCarrier',
  Market = 'Market',
}

interface IRoute {
  path: string;
  component: any;
}

const ROUTES: Record<string, IRoute> = {
  [RouteName.Home]: {
    path: '/',
    component: () => import('pages'),
  },
  [RouteName.ArticleSummary]: {
    path: '/[articleTypeSlug]',
    component: () => import('pages/[articleTypeSlug]'),
  },
  [RouteName.Article]: {
    path: '/[articleTypeSlug]/[slug]',
    component: () => import('pages/[articleTypeSlug]/[slug]'),
  },
  [RouteName.SignInWithLinkedIn]: {
    path: '/sign-in-with-linkedin',
    component: () => import('pages/sign-in-with-linkedin'),
  },
  [RouteName.Verify]: {
    path: '/verify',
    component: () => import('pages/verify'),
  },
  [RouteName.TrackMyParcelEn]: {
    path: '/track-my-parcel',
    component: () => import('pages/track-my-parcel'),
  },
  [RouteName.TrackMyParcelMultiLanguages]: {
    path: '/[articleTypeSlug]/track-my-parcel',
    component: () => import('pages/[articleTypeSlug]/track-my-parcel'),
  },
  [RouteName.UnsubscribeAllEn]: {
    path: '/unsubscribe-all',
    component: () => import('pages/unsubscribe-all'),
  },
  [RouteName.UnsubscribeAllMultiLanguages]: {
    path: '/[articleTypeSlug]/unsubscribe-all',
    component: () => import('pages/[articleTypeSlug]/unsubscribe-all'),
  },
  [RouteName.UnsubscribeSummaryNotificationEn]: {
    path: '/unsubscribe-summary-email',
    component: () => import('pages/unsubscribe-summary-email'),
  },
  [RouteName.UnsubscribeSummaryNotificationMultiLanguages]: {
    path: '/[articleTypeSlug]/unsubscribe-summary-email',
    component: () => import('pages/[articleTypeSlug]/unsubscribe-summary-email'),
  },
  [RouteName.Carriers]: {
    path: '/companies/[companyType]',
    component: () => import('pages/companies/[companyType]'),
  },
  [RouteName.IndividualCarrier]: {
    path: '/companies/[companyType]/[slug]',
    component: () => import('pages/companies/[companyType]/[slug]'),
  },
  [RouteName.Market]: {
    path: '/markets/[slug]',
    component: () => import('pages/markets/[slug]'),
  },
};

export enum ArticleTypeName {
  news = 'news',
  insights = 'insights',
  events = 'events',
  exclusives = 'exclusives',
  people = 'people',
  markets = 'markets',
  topic = 'topic',
  hub = 'hub',
  reports = 'reports',
}

export enum CompanyType {
  carriers = 'carriers',
  merchants = 'merchants',
  retailers = 'retailers',
}

export const ACCEPTED_ARTICLE_TYPES: Record<string, string> = {
  [ArticleTypeName.news]: 'news',
  [ArticleTypeName.insights]: 'insights',
  [ArticleTypeName.events]: 'events',
  [ArticleTypeName.exclusives]: 'exclusives',
  [ArticleTypeName.people]: 'people',
  [ArticleTypeName.markets]: 'markets',
  [ArticleTypeName.topic]: 'topic',
  [ArticleTypeName.hub]: 'hub',
  [ArticleTypeName.reports]: 'reports',
};

const LANGUAGE_CODES_GROUP = Object.values(LanguageCode);

const CONFIRMATION_PAGES = ['unsub-shipment', 'unsubscribe-all', 'unsubscribe-summary-email'];

export const TRACK_MY_PARCEL_REGEX = new RegExp(
  `^/((${LANGUAGE_CODES_GROUP.join('|')})/)?(track-my-parcel|${CONFIRMATION_PAGES.join('|')})$`,
);

export enum SlugRoutes {
  carriers = '/companies/carriers',
  companies = '/companies',
  markets = '/markets',
}

export enum PpSlugRoutes {
  insights = '/insights',
  resources = '/resources',
  reports = '/reports',
}

export default ROUTES;

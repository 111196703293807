import { TableIconColor, IconAlignment } from '@/interfaces';
import { HeaderWithIcon } from './CustomHeader.styles';

type CustomHeaderProps = {
  icon?: React.FC<any>;
  alignment?: IconAlignment;
  children: React.FC | string | number | boolean | JSX.Element;
  color?: TableIconColor;
};

/**
 * Header with icon component
 * @param icon icon to display (optional)
 * @param alignment IconAlignment.Left | IconAlignment.Right
 * @param children text to display
 * @example
 * <HeaderWithIcon Icon={StarIcon} alignment={IconAlignment.Left}>
 *  Delivery Performance
 * </HeaderWithIcon>
 */
const CustomHeader: React.FC<CustomHeaderProps> = ({
  icon: Icon,
  children,
  alignment = IconAlignment.Left,
  color = TableIconColor.DarkOrange,
}) => {
  if (!Icon) return <>{children}</>;

  return (
    <HeaderWithIcon alignment={alignment} color={color}>
      <Icon width={16} height={16} />
      {children}
    </HeaderWithIcon>
  );
};

export default CustomHeader;

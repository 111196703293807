import { ContentTypeIds } from '@/constants';
import type { ISlugItem } from '@/interfaces';
import { minifyQuery } from '@/utils';
import callContentful from './base.fetchers';

const CARRIER_LIMIT = 1000;
const CARRIER_MAX_SKIP = 2;

type GetAllPagesSlug = (args: { isPreviewMode: boolean }) => Promise<{
  [key in ContentTypeIds]?: ISlugItem[] | null;
}>;

export const getAllPagesSlug: GetAllPagesSlug = async ({ isPreviewMode }) => {
  const pageQuery = `
    tagCollection(
      preview: ${isPreviewMode}
      where: {
        hide_not: true
      }
    ) {
      items {
        type
        slug
      }
    }

    landingPageCollection(
      preview: ${isPreviewMode}
      where: { slug_exists: true }
    ) {
      items {
        slug
      }
    }

    articleCollection(
      preview: ${isPreviewMode}
    ) {
      items {
        articleType
        slug
      }
    }

    eventCollection(
      preview: ${isPreviewMode}
    ) {
      items {
        slug
      }
    }

    companyDirectoryCollection(
      preview: ${isPreviewMode}
    ) {
      items {
        slug
      }
    }

    marketCollection(
      preview: ${isPreviewMode}
    ) {
      items {
        slug
      }
    }
  `;

  // Custom query to get all carriers
  const carrierQuery = Array.from(
    { length: CARRIER_MAX_SKIP },
    (_, i) => `
    carrierCollection${i}: carrierCollection(
      preview: ${isPreviewMode}
      limit: ${CARRIER_LIMIT}
      skip: ${i * CARRIER_LIMIT}
    ) {
      items {
        slug
      }
    }
  `,
  ).join('\n');

  const query = `{ ${pageQuery + carrierQuery} }`;

  const response = await callContentful(
    minifyQuery(query),
    isPreviewMode,
    '/main-sitemap.xml',
    'sitemap.fetchers.getAllPagesSlug',
  );
  if (!response?.data) return {};

  const { data } = response;

  const tagCollection = data.tagCollection?.items?.filter(Boolean) || null;
  const landingPageCollection = data.landingPageCollection?.items?.filter(Boolean) || null;
  const articleCollection = data.articleCollection?.items?.filter(Boolean) || null;
  const eventCollection = data.eventCollection?.items?.filter(Boolean) || null;
  const companyDirectoryCollection = data.companyDirectoryCollection?.items?.filter(Boolean) || null;
  const carrierCollection = Array.from(
    { length: CARRIER_MAX_SKIP },
    (_, i) => data[`carrierCollection${i}`]?.items?.filter(Boolean) || null,
  ).flat();
  const marketCollection = data.marketCollection?.items?.filter(Boolean) || null;

  return {
    [ContentTypeIds.tag]: tagCollection,
    [ContentTypeIds.landingPage]: landingPageCollection,
    [ContentTypeIds.article]: articleCollection,
    [ContentTypeIds.event]: eventCollection,
    [ContentTypeIds.companyDirectory]: companyDirectoryCollection,
    [ContentTypeIds.carrier]: carrierCollection,
    [ContentTypeIds.market]: marketCollection,
  };
};

import styled from 'styled-components';
import { TextStyles } from '@/constants';
import { Typography } from '@/components';

export const DescriptionContainer = styled.div`
  min-width: 280px;
  border-left: 1px solid ${({ theme }) => theme.colors.pressedBlack};
  padding: ${({ theme }) => theme.spacing[24]};
  color: ${({ theme }) => theme.colors.highEmphasisBlack};
`;

export const DescriptionText = styled(Typography).attrs({
  variant: TextStyles['Body Text Small'],
})`
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

import Link from 'next/link';
import type { IPanel } from '@/interfaces';
import { RouteName, ROUTES } from '@/constants';
import ProgressTracker from './ProgressTracker';
import {
  RightPanelWrapper,
  PanelSubtext,
  PanelTitle,
  PanelWrapper,
  ComponentWrapper,
  StepWrapper,
  PanelHeader,
  Logo,
  PanelHeaderLink,
  CloseButton,
  PanelHeaderImage,
} from './RightPanel.styles';
import Image from 'next/image';

const RightPanel: React.FC<IPanel> = (props) => {
  const {
    title,
    subtext,
    Component,
    closePopUp, //have to pass from outside
    isFullScreen,
    progressIndicator,
  } = props;

  const renderTitle = () => {
    return title && <PanelTitle>{title}</PanelTitle>;
  };

  return (
    <RightPanelWrapper isFullScreen={isFullScreen}>
      <PanelHeader>
        {isFullScreen ? (
          <>
            <Link href={ROUTES[RouteName.Home].path} passHref legacyBehavior>
              <PanelHeaderLink onClick={closePopUp}>
                <Logo />
              </PanelHeaderLink>
            </Link>
            <CloseButton onClick={closePopUp} />
          </>
        ) : (
          <PanelHeaderImage>
            <Image
              src='/images/panel.png'
              alt='Panel Header Graphic'
              fill
              sizes='100vw'
              style={{
                objectFit: 'cover',
              }}
            />
          </PanelHeaderImage>
        )}
      </PanelHeader>
      <PanelWrapper>
        {progressIndicator !== false && <ProgressTracker isFullScreen={isFullScreen} />}
        <StepWrapper>
          {renderTitle()}
          {subtext && <PanelSubtext>{subtext}</PanelSubtext>}
          {Component && (
            <ComponentWrapper>
              <Component {...props} />
            </ComponentWrapper>
          )}
        </StepWrapper>
      </PanelWrapper>
    </RightPanelWrapper>
  );
};

export default RightPanel;

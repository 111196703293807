import styled from 'styled-components';
import Typography from '@/components/Typography';
import { theme, TextStyles, TrackerState } from '@/constants';
import { styledText } from '@/components/Typography/Typography.styles';

const PROGRESS_ITEM_SIZE = 24;
const PROGRESS_ITEM_SIZE_MD = 32;
const FULL_SCREEN_PROGRESS_LINE_GAP = 2;
const NON_FULL_SCREEN_PROGRESS_LINE_GAP = 4;

const fullScreenProgressNumberMetrics = `
  width: ${PROGRESS_ITEM_SIZE_MD}px;
  height: ${PROGRESS_ITEM_SIZE_MD}px;
  line-height: ${PROGRESS_ITEM_SIZE_MD - 2}px;
`;

const defaultProgressNumberMetrics = `
  width: ${PROGRESS_ITEM_SIZE}px;
  height: ${PROGRESS_ITEM_SIZE}px;
  line-height: ${PROGRESS_ITEM_SIZE - 3}px;
`;

const fullScreenProgressLineMetrics = `
  left: calc(50% + ${PROGRESS_ITEM_SIZE_MD / 2}px + ${FULL_SCREEN_PROGRESS_LINE_GAP}px);
  top: ${PROGRESS_ITEM_SIZE_MD / 2}px;
  width: calc(100% - ${PROGRESS_ITEM_SIZE_MD}px - ${FULL_SCREEN_PROGRESS_LINE_GAP * 2}px);
`;

const mobileNonFullScreenProgressLineMetrics = `
  left: calc(50% + ${PROGRESS_ITEM_SIZE / 2}px + ${NON_FULL_SCREEN_PROGRESS_LINE_GAP}px);
  top: ${PROGRESS_ITEM_SIZE / 2}px;
  width: calc(100% - ${PROGRESS_ITEM_SIZE}px - ${NON_FULL_SCREEN_PROGRESS_LINE_GAP * 2}px);
`;

export const ProgressText = styled.div`
  width: 100%;
  text-align: center;
`;

export const ProgressNumber = styled(Typography).attrs({
  variant: TextStyles['Heading 5'],
})`
  position: relative;
  margin: 0 auto;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${({ theme }) => theme.colors.pressedBlack};
  color: ${({ theme }) => theme.colors.pressedBlack};
  transition: .3s;

  ${defaultProgressNumberMetrics}

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    ${fullScreenProgressNumberMetrics}
  }
`;

export const ProgressTitle = styled(Typography).attrs({
  variant: TextStyles['Heading 5'],
})`
  margin-top: ${({ theme }) => theme.spacing[8]};
  text-transform: uppercase;

  color: ${({ theme }) => theme.colors.pressedBlack};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    ${styledText[TextStyles['Heading 5']]}
  }
`;

export const ProgressLine = styled.div<{ isFullScreen?: boolean }>`
  position: absolute;
  height: 2px;
  background: ${({ theme }) => theme.colors.pressedBlack};

  ${({ isFullScreen }) => (isFullScreen ? fullScreenProgressLineMetrics : mobileNonFullScreenProgressLineMetrics)}

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    ${fullScreenProgressLineMetrics}
  }
`;

export const CheckIcon = styled.div(({ theme }) => ({
  width: 14,
  height: 14,
  position: 'relative',

  [`${theme.mediaBreakpointUp.md}`]: {
    width: 20,
    height: 20,
  },
}));

export const TrackerWrapper = styled.div<{ isFullScreen?: boolean }>`
  margin-bottom: ${({ theme, isFullScreen }) => (isFullScreen ? theme.spacing[32] : theme.spacing[28])};
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: ${({ isFullScreen }) => (isFullScreen ? 'unset' : '302px')};
  margin-left: auto;
  margin-right: auto;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    max-width: unset;
    margin-bottom: ${({ theme }) => theme.spacing[40]};
  }
`;

export const ProgressItem = styled.div<{
  length: number;
  state: TrackerState;
  isFullScreen?: boolean;
}>`
  ${({ state, isFullScreen }) => (isFullScreen ? fullScreenProgressStyleMapping[state] : progressStyleMapping[state])}
  display: flex;
  width: calc(100% / ${({ length }) => length});
  max-width: 140px;
  position: relative;

  ${({ isFullScreen }) =>
    isFullScreen &&
    `
    ${CheckIcon} {
      width: 20px;
      height: 20px;
    }

    ${ProgressTitle} {
      ${styledText[TextStyles['Heading 5']]}
    }

    ${ProgressNumber} {
      ${styledText[TextStyles['Heading 5']]}
      ${fullScreenProgressNumberMetrics}
    }
  `}
`;

const progressStyleMapping = {
  [TrackerState.active]: `
    ${ProgressTitle} {
      color: ${theme.colors.mediumEmphasisBlack};
    }

    ${ProgressNumber} {
      border-color: ${theme.colors.darkBlue};
      color: ${theme.colors.darkBlue};
    }
  `,
  [TrackerState.checked]: `
    ${ProgressTitle} {
      color: ${theme.colors.disabledBlack};
    }

    ${ProgressLine} {
      background: ${theme.colors.darkBlue};
    }

    ${ProgressNumber} {
      border-color: ${theme.colors.darkBlue};
      color: ${theme.colors.darkBlue};
      background: ${theme.colors.darkBlue};
      line-height: ${theme.lineHeight[24]};

      ${theme.mediaBreakpointUp.md} {
        line-height: ${theme.lineHeight[36]};
      }
    }
  `,
  [TrackerState.disabled]: `
    ${ProgressTitle} {
      color: ${theme.colors.pressedBlack};
    }
  `,
};

const fullScreenProgressStyleMapping = {
  ...progressStyleMapping,
  [TrackerState.checked]: `
    ${ProgressTitle} {
      color: ${theme.colors.disabledBlack};
    }

    ${ProgressLine} {
      background: ${theme.colors.darkBlue};
    }

    ${ProgressNumber} {
      border-color: ${theme.colors.darkBlue};
      color: ${theme.colors.darkBlue};
      background: ${theme.colors.darkBlue};
      line-height: ${theme.lineHeight[36]}; // non fullscreen and fullscreen different at this line
    }
  `,
};

import { UITemplatesContentItem } from '@/constants';
import { type ContentItemInterface, ContentItemUITemplateKeys } from '@/interfaces/';

const ContentItem: React.FC<ContentItemInterface> = (props) => {
  const { uiTemplate } = props;
  const defaultKey = ContentItemUITemplateKeys.BulletPoints;
  const defaultContentItem = UITemplatesContentItem[defaultKey];
  const UITemplateContentItem = uiTemplate
    ? UITemplatesContentItem[uiTemplate] || defaultContentItem
    : defaultContentItem;

  return <UITemplateContentItem {...props} />;
};

export default ContentItem;

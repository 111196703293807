import { useGlobalState } from '@/hooks';
import type { ISingleItem } from '@/interfaces';
import { renderHTML } from '@/utils';
import { Description, Icon, ItemWrapper, Title } from './SEOItem.styles';

const ICON_SIZE = 24;
const ICON_SIZE_TRANSFORMED = ICON_SIZE * 2;

const SEOItem: React.FC<ISingleItem> = ({ icon, title, description }) => {
  const { getProperImageUrl } = useGlobalState();

  const addTargetBlankToAnchor = (str: string) => {
    return str.replace('href', 'target="_blank" href');
  };

  const iconUrl = icon?.url && `https:${icon.url}?w=${ICON_SIZE_TRANSFORMED}&h=${ICON_SIZE_TRANSFORMED}`;

  return (
    <ItemWrapper>
      {iconUrl && (
        <Icon
          src={getProperImageUrl({ ...icon, url: iconUrl })}
          alt={icon.description || 'SEO Icon'}
          height={ICON_SIZE}
          width={ICON_SIZE}
        />
      )}
      <Title>{renderHTML(addTargetBlankToAnchor(title || ''), 'span')}</Title>
      <Description>{renderHTML(addTargetBlankToAnchor(description || ''), 'span')}</Description>
    </ItemWrapper>
  );
};

export default SEOItem;

import styled from 'styled-components';
import { TextStyles } from '@/constants';
import { Button, Typography } from '@/components';
import { PaywallBackgroundContainer } from '../../RegistrationWall.styles';
import LockOutline from 'public/images/icons/lock_outline.svg';

export const ButtonPaywallContainer = styled(PaywallBackgroundContainer)(({ theme }) => ({
  maxWidth: 194,
  margin: 'auto',
  padding: theme.spacing[16], // isInsideRT has same padding
}));

export const HighlightedTitle = styled(Typography).attrs({
  variant: TextStyles['Heading 3'],
})(({ theme }) => ({
  textAlign: 'center',
  marginTop: theme.spacing[8],
}));

export const CustomButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing[16],
}));

export const LockOutlineIcon = styled(LockOutline)(({ theme }) => ({
  path: {
    fill: theme.colors.blue,
  },
}));

// InnerContainer is added to match the other paywalls structure
export const InnerContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

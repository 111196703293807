export enum CountryNames {
  AD = 'Andorra',
  AE = 'United Arab Emirates',
  AF = 'Afghanistan',
  AG = 'Antigua and Barbuda',
  AI = 'Anguilla',
  AL = 'Albania',
  AM = 'Armenia',
  AO = 'Angola',
  AQ = 'Antarctica', // ❌ not in countries constant
  AR = 'Argentina',
  AS = 'American Samoa',
  AT = 'Austria',
  AU = 'Australia',
  AW = 'Aruba',
  AX = 'Åland Islands',
  AZ = 'Azerbaijan',
  BA = 'Bosnia and Herzegovina',
  BB = 'Barbados',
  BD = 'Bangladesh',
  BE = 'Belgium',
  BF = 'Burkina Faso',
  BG = 'Bulgaria',
  BH = 'Bahrain',
  BI = 'Burundi',
  BJ = 'Benin',
  BL = 'Saint Barthélemy',
  BM = 'Bermuda',
  BN = 'Brunei Darussalam',
  BO = 'Bolivia',
  BQ = 'Bonaire Sint Eustatius Saba',
  BR = 'Brazil',
  BS = 'Bahamas',
  BT = 'Bhutan',
  BV = 'Bouvet Island', // ❌ not in countries constant
  BW = 'Botswana',
  BY = 'Belarus',
  BZ = 'Belize',
  CA = 'Canada',
  CC = 'Cocos Islands',
  CD = 'Democratic Republic of the Congo',
  CF = 'Central African Republic',
  CG = 'Republic of the Congo',
  CH = 'Switzerland',
  CI = 'Côte divoire',
  CK = 'Cook Islands',
  CL = 'Chile',
  CM = 'Cameroon',
  CN = 'China',
  CO = 'Colombia',
  CR = 'Costa Rica',
  CU = 'Cuba',
  CV = 'Cabo Verde',
  CW = 'Curaçao',
  CX = 'Christmas Island',
  CY = 'Cyprus',
  CZ = 'Czechia',
  DE = 'Germany',
  DJ = 'Djibouti',
  DK = 'Denmark',
  DM = 'Dominica',
  DO = 'Dominican Republic',
  DZ = 'Algeria',
  EC = 'Ecuador',
  EE = 'Estonia',
  EG = 'Egypt',
  EH = 'Western Sahara',
  ER = 'Eritrea',
  ES = 'Spain',
  ET = 'Ethiopia',
  FI = 'Finland',
  FJ = 'Fiji',
  FK = 'Falkland Islands Malvinas',
  FM = 'Micronesia',
  FO = 'Faroe Islands',
  FR = 'France',
  GA = 'Gabon',
  GB = 'United Kingdom',
  GD = 'Grenada',
  GE = 'Georgia',
  GF = 'French Guiana',
  GG = 'Guernsey',
  GH = 'Ghana',
  GI = 'Gibraltar',
  GL = 'Greenland',
  GM = 'Gambia',
  GN = 'Guinea',
  GP = 'Guadeloupe',
  GQ = 'Equatorial Guinea',
  GR = 'Greece',
  GS = 'South Georgia and the South Sandwich Islands', // ❌ not in countries constant
  GT = 'Guatemala',
  GU = 'Guam',
  GW = 'Guinea-Bissau',
  GY = 'Guyana',
  HK = 'Hong Kong',
  HM = 'Heard Island and McDonald Islands', // ❌ not in countries constant
  HN = 'Honduras',
  HR = 'Croatia',
  HT = 'Haiti',
  HU = 'Hungary',
  ID = 'Indonesia',
  IE = 'Ireland',
  IL = 'Israel',
  IM = 'Isle of Man',
  IN = 'India',
  IO = 'British Indian Ocean Territory',
  IQ = 'Iraq',
  IR = 'Iran',
  IS = 'Iceland',
  IT = 'Italy',
  JE = 'Jersey',
  JM = 'Jamaica',
  JO = 'Jordan',
  JP = 'Japan',
  KE = 'Kenya',
  KG = 'Kyrgyzstan',
  KH = 'Cambodia',
  KI = 'Kiribati',
  KM = 'Comoros',
  KN = 'Saint Kitts and Nevis',
  KP = 'North Korea',
  KR = 'Korea',
  KW = 'Kuwait',
  KY = 'Cayman Islands',
  KZ = 'Kazakhstan',
  LA = 'Lao Peoples Democratic Republic',
  LB = 'Lebanon',
  LC = 'Saint Lucia',
  LI = 'Liechtenstein',
  LK = 'Sri Lanka',
  LR = 'Liberia',
  LS = 'Lesotho',
  LT = 'Lithuania',
  LU = 'Luxembourg',
  LV = 'Latvia',
  LY = 'Libya',
  MA = 'Morocco',
  MC = 'Monaco',
  MD = 'Moldova',
  ME = 'Montenegro',
  MF = 'Saint Martin',
  MG = 'Madagascar',
  MH = 'Marshall Islands',
  MK = 'North Macedonia',
  ML = 'Mali',
  MM = 'Myanmar',
  MN = 'Mongolia',
  MO = 'Macao',
  MP = 'Northern Mariana Islands',
  MQ = 'Martinique',
  MR = 'Mauritania',
  MS = 'Montserrat',
  MT = 'Malta',
  MU = 'Mauritius',
  MV = 'Maldives',
  MW = 'Malawi',
  MX = 'Mexico',
  MY = 'Malaysia',
  MZ = 'Mozambique',
  NA = 'Namibia',
  NC = 'New Caledonia',
  NE = 'Niger',
  NF = 'Norfolk Island',
  NG = 'Nigeria',
  NI = 'Nicaragua',
  NL = 'Netherlands',
  NO = 'Norway',
  NP = 'Nepal',
  NR = 'Nauru',
  NU = 'Niue',
  NZ = 'New Zealand',
  OM = 'Oman',
  PA = 'Panama',
  PE = 'Peru',
  PF = 'French Polynesia',
  PG = 'Papua New Guinea',
  PH = 'Philippines',
  PK = 'Pakistan',
  PL = 'Poland',
  PM = 'Saint Pierre and Miquelon',
  PN = 'Pitcairn',
  PR = 'Puerto Rico',
  PS = 'Palestine, State of',
  PT = 'Portugal',
  PW = 'Palau',
  PY = 'Paraguay',
  QA = 'Qatar',
  RE = 'Réunion',
  RO = 'Romania',
  RS = 'Serbia',
  RU = 'Russian Federation',
  RW = 'Rwanda',
  SA = 'Saudi Arabia',
  SB = 'Solomon Islands',
  SC = 'Seychelles',
  SD = 'Sudan',
  SE = 'Sweden',
  SG = 'Singapore',
  SH = 'Saint Helena Ascension Island Tristan da Cunha',
  SI = 'Slovenia',
  SJ = 'Svalbard Jan Mayen',
  SK = 'Slovakia',
  SL = 'Sierra Leone',
  SM = 'San Marino',
  SN = 'Senegal',
  SO = 'Somalia',
  SR = 'Suriname',
  SS = 'South Sudan',
  ST = 'Sao Tome and Principe',
  SV = 'El Salvador',
  SX = 'Sint Maarten',
  SY = 'Syrian Arab Republic',
  SZ = 'Eswatini',
  TC = 'Turks and Caicos Islands',
  TD = 'Chad',
  TF = 'French Southern Territories', // ❌ not in countries constant
  TG = 'Togo',
  TH = 'Thailand',
  TJ = 'Tajikistan',
  TK = 'Tokelau',
  TL = 'Timor-Leste',
  TM = 'Turkmenistan',
  TN = 'Tunisia',
  TO = 'Tonga',
  TR = 'Turkey',
  TT = 'Trinidad and Tobago',
  TV = 'Tuvalu',
  TW = 'Taiwan',
  TZ = 'Tanzania, the United Republic of',
  UA = 'Ukraine',
  UG = 'Uganda',
  UM = 'United States Minor Outlying Islands',
  US = 'United States of America',
  UY = 'Uruguay',
  UZ = 'Uzbekistan',
  VA = 'Holy See',
  VC = 'Saint Vincent and the Grenadines',
  VE = 'Venezuela (Bolivarian Republic of)',
  VG = 'Virgin Islands (British)',
  VI = 'Virgin Islands (U.S.)',
  VN = 'Vietnam',
  VU = 'Vanuatu',
  WF = 'Wallis and Futuna',
  WS = 'Samoa',
  YE = 'Yemen',
  YT = 'Mayotte',
  ZA = 'South Africa',
  ZM = 'Zambia',
  ZW = 'Zimbabwe',
}

export const countries = {
  afghanistan: { marketName: CountryNames.AF, countryCode: 'AF' },
  'aland-islands': { marketName: CountryNames.AX, countryCode: 'AX' },
  albania: { marketName: CountryNames.AL, countryCode: 'AL' },
  algeria: { marketName: CountryNames.DZ, countryCode: 'DZ' },
  'american-samoa': { marketName: CountryNames.AS, countryCode: 'AS' },
  andorra: { marketName: CountryNames.AD, countryCode: 'AD' },
  angola: { marketName: CountryNames.AO, countryCode: 'AO' },
  anguilla: { marketName: CountryNames.AI, countryCode: 'AI' },
  'antigua-and-barbuda': { marketName: CountryNames.AG, countryCode: 'AG' },
  argentina: { marketName: CountryNames.AR, countryCode: 'AR' },
  armenia: { marketName: CountryNames.AM, countryCode: 'AM' },
  aruba: { marketName: CountryNames.AW, countryCode: 'AW' },
  australia: { marketName: CountryNames.AU, countryCode: 'AU' },
  austria: { marketName: CountryNames.AT, countryCode: 'AT' },
  azerbaijan: { marketName: CountryNames.AZ, countryCode: 'AZ' },
  bahamas: { marketName: CountryNames.BS, countryCode: 'BS' },
  bahrain: { marketName: CountryNames.BH, countryCode: 'BH' },
  bangladesh: { marketName: CountryNames.BD, countryCode: 'BD' },
  barbados: { marketName: CountryNames.BB, countryCode: 'BB' },
  belarus: { marketName: CountryNames.BY, countryCode: 'BY' },
  belgium: { marketName: CountryNames.BE, countryCode: 'BE' },
  belize: { marketName: CountryNames.BZ, countryCode: 'BZ' },
  benin: { marketName: CountryNames.BJ, countryCode: 'BJ' },
  bermuda: { marketName: CountryNames.BM, countryCode: 'BM' },
  bhutan: { marketName: CountryNames.BT, countryCode: 'BT' },
  bolivia: { marketName: CountryNames.BO, countryCode: 'BO' },
  'bonaire-sint-eustatius-saba': { marketName: CountryNames.BQ, countryCode: 'BQ' },
  'bosnia-and-herzegovina': { marketName: CountryNames.BA, countryCode: 'BA' },
  botswana: { marketName: CountryNames.BW, countryCode: 'BW' },
  brazil: { marketName: CountryNames.BR, countryCode: 'BR' },
  'british-indian-ocean-territory': { marketName: CountryNames.IO, countryCode: 'IO' },
  'brunei-darussalam': { marketName: CountryNames.BN, countryCode: 'BN' },
  bulgaria: { marketName: CountryNames.BG, countryCode: 'BG' },
  'burkina-faso': { marketName: CountryNames.BF, countryCode: 'BF' },
  burundi: { marketName: CountryNames.BI, countryCode: 'BI' },
  'cabo-verde': { marketName: CountryNames.CV, countryCode: 'CV' },
  cambodia: { marketName: CountryNames.KH, countryCode: 'KH' },
  cameroon: { marketName: CountryNames.CM, countryCode: 'CM' },
  canada: { marketName: CountryNames.CA, countryCode: 'CA' },
  'cayman-islands': { marketName: CountryNames.KY, countryCode: 'KY' },
  'central-african-republic': { marketName: CountryNames.CF, countryCode: 'CF' },
  chad: { marketName: CountryNames.TD, countryCode: 'TD' },
  chile: { marketName: CountryNames.CL, countryCode: 'CL' },
  china: { marketName: CountryNames.CN, countryCode: 'CN' },
  'christmas-island': { marketName: CountryNames.CX, countryCode: 'CX' },
  'cocos-islands': { marketName: CountryNames.CC, countryCode: 'CC' },
  colombia: { marketName: CountryNames.CO, countryCode: 'CO' },
  comoros: { marketName: CountryNames.KM, countryCode: 'KM' },
  'cook-islands': { marketName: CountryNames.CK, countryCode: 'CK' },
  'costa-rica': { marketName: CountryNames.CR, countryCode: 'CR' },
  'cote-divoire': { marketName: CountryNames.CI, countryCode: 'CI' },
  croatia: { marketName: CountryNames.HR, countryCode: 'HR' },
  cuba: { marketName: CountryNames.CU, countryCode: 'CU' },
  curacao: { marketName: CountryNames.CW, countryCode: 'CW' },
  cyprus: { marketName: CountryNames.CY, countryCode: 'CY' },
  czechia: { marketName: CountryNames.CZ, countryCode: 'CZ' },
  'democratic-republic-of-the-congo': { marketName: CountryNames.CD, countryCode: 'CD' },
  denmark: { marketName: CountryNames.DK, countryCode: 'DK' },
  djibouti: { marketName: CountryNames.DJ, countryCode: 'DJ' },
  dominica: { marketName: CountryNames.DM, countryCode: 'DM' },
  'dominican-republic': { marketName: CountryNames.DO, countryCode: 'DO' },
  ecuador: { marketName: CountryNames.EC, countryCode: 'EC' },
  egypt: { marketName: CountryNames.EG, countryCode: 'EG' },
  'el-salvador': { marketName: CountryNames.SV, countryCode: 'SV' },
  'equatorial-guinea': { marketName: CountryNames.GQ, countryCode: 'GQ' },
  eritrea: { marketName: CountryNames.ER, countryCode: 'ER' },
  estonia: { marketName: CountryNames.EE, countryCode: 'EE' },
  eswatini: { marketName: CountryNames.SZ, countryCode: 'SZ' },
  ethiopia: { marketName: CountryNames.ET, countryCode: 'ET' },
  'falkland-islands-malvinas': { marketName: CountryNames.FK, countryCode: 'FK' },
  'faroe-islands': { marketName: CountryNames.FO, countryCode: 'FO' },
  fiji: { marketName: CountryNames.FJ, countryCode: 'FJ' },
  finland: { marketName: CountryNames.FI, countryCode: 'FI' },
  france: { marketName: CountryNames.FR, countryCode: 'FR' },
  'french-guiana': { marketName: CountryNames.GF, countryCode: 'GF' },
  'french-polynesia': { marketName: CountryNames.PF, countryCode: 'PF' },
  gabon: { marketName: CountryNames.GA, countryCode: 'GA' },
  gambia: { marketName: CountryNames.GM, countryCode: 'GM' },
  georgia: { marketName: CountryNames.GE, countryCode: 'GE' },
  germany: { marketName: CountryNames.DE, countryCode: 'DE' },
  ghana: { marketName: CountryNames.GH, countryCode: 'GH' },
  gibraltar: { marketName: CountryNames.GI, countryCode: 'GI' },
  greece: { marketName: CountryNames.GR, countryCode: 'GR' },
  greenland: { marketName: CountryNames.GL, countryCode: 'GL' },
  grenada: { marketName: CountryNames.GD, countryCode: 'GD' },
  guadeloupe: { marketName: CountryNames.GP, countryCode: 'GP' },
  guam: { marketName: CountryNames.GU, countryCode: 'GU' },
  guatemala: { marketName: CountryNames.GT, countryCode: 'GT' },
  guernsey: { marketName: CountryNames.GG, countryCode: 'GG' },
  guinea: { marketName: CountryNames.GN, countryCode: 'GN' },
  'guinea-bissau': { marketName: CountryNames.GW, countryCode: 'GW' },
  guyana: { marketName: CountryNames.GY, countryCode: 'GY' },
  haiti: { marketName: CountryNames.HT, countryCode: 'HT' },
  'holy-see': { marketName: CountryNames.VA, countryCode: 'VA' },
  honduras: { marketName: CountryNames.HN, countryCode: 'HN' },
  'hong-kong': { marketName: CountryNames.HK, countryCode: 'HK' },
  hungary: { marketName: CountryNames.HU, countryCode: 'HU' },
  iceland: { marketName: CountryNames.IS, countryCode: 'IS' },
  india: { marketName: CountryNames.IN, countryCode: 'IN' },
  indonesia: { marketName: CountryNames.ID, countryCode: 'ID' },
  iran: { marketName: CountryNames.IR, countryCode: 'IR' },
  iraq: { marketName: CountryNames.IQ, countryCode: 'IQ' },
  ireland: { marketName: CountryNames.IE, countryCode: 'IE' },
  'isle-of-man': { marketName: CountryNames.IM, countryCode: 'IM' },
  israel: { marketName: CountryNames.IL, countryCode: 'IL' },
  italy: { marketName: CountryNames.IT, countryCode: 'IT' },
  jamaica: { marketName: CountryNames.JM, countryCode: 'JM' },
  japan: { marketName: CountryNames.JP, countryCode: 'JP' },
  jersey: { marketName: CountryNames.JE, countryCode: 'JE' },
  jordan: { marketName: CountryNames.JO, countryCode: 'JO' },
  kazakhstan: { marketName: CountryNames.KZ, countryCode: 'KZ' },
  kenya: { marketName: CountryNames.KE, countryCode: 'KE' },
  kiribati: { marketName: CountryNames.KI, countryCode: 'KI' },
  korea: { marketName: CountryNames.KR, countryCode: 'KR' },
  kuwait: { marketName: CountryNames.KW, countryCode: 'KW' },
  kyrgyzstan: { marketName: CountryNames.KG, countryCode: 'KG' },
  'lao-peoples-democratic-republic': { marketName: CountryNames.LA, countryCode: 'LA' },
  latvia: { marketName: CountryNames.LV, countryCode: 'LV' },
  lebanon: { marketName: CountryNames.LB, countryCode: 'LB' },
  lesotho: { marketName: CountryNames.LS, countryCode: 'LS' },
  liberia: { marketName: CountryNames.LR, countryCode: 'LR' },
  libya: { marketName: CountryNames.LY, countryCode: 'LY' },
  liechtenstein: { marketName: CountryNames.LI, countryCode: 'LI' },
  lithuania: { marketName: CountryNames.LT, countryCode: 'LT' },
  luxembourg: { marketName: CountryNames.LU, countryCode: 'LU' },
  macao: { marketName: CountryNames.MO, countryCode: 'MO' },
  madagascar: { marketName: CountryNames.MG, countryCode: 'MG' },
  malawi: { marketName: CountryNames.MW, countryCode: 'MW' },
  malaysia: { marketName: CountryNames.MY, countryCode: 'MY' },
  maldives: { marketName: CountryNames.MV, countryCode: 'MV' },
  mali: { marketName: CountryNames.ML, countryCode: 'ML' },
  malta: { marketName: CountryNames.MT, countryCode: 'MT' },
  'marshall-islands': { marketName: CountryNames.MH, countryCode: 'MH' },
  martinique: { marketName: CountryNames.MQ, countryCode: 'MQ' },
  mauritania: { marketName: CountryNames.MR, countryCode: 'MR' },
  mauritius: { marketName: CountryNames.MU, countryCode: 'MU' },
  mayotte: { marketName: CountryNames.YT, countryCode: 'YT' },
  mexico: { marketName: CountryNames.MX, countryCode: 'MX' },
  micronesia: { marketName: CountryNames.FM, countryCode: 'FM' },
  moldova: { marketName: CountryNames.MD, countryCode: 'MD' },
  monaco: { marketName: CountryNames.MC, countryCode: 'MC' },
  mongolia: { marketName: CountryNames.MN, countryCode: 'MN' },
  montenegro: { marketName: CountryNames.ME, countryCode: 'ME' },
  montserrat: { marketName: CountryNames.MS, countryCode: 'MS' },
  morocco: { marketName: CountryNames.MA, countryCode: 'MA' },
  mozambique: { marketName: CountryNames.MZ, countryCode: 'MZ' },
  myanmar: { marketName: CountryNames.MM, countryCode: 'MM' },
  namibia: { marketName: CountryNames.NA, countryCode: 'NA' },
  nauru: { marketName: CountryNames.NR, countryCode: 'NR' },
  nepal: { marketName: CountryNames.NP, countryCode: 'NP' },
  netherlands: { marketName: CountryNames.NL, countryCode: 'NL' },
  'new-caledonia': { marketName: CountryNames.NC, countryCode: 'NC' },
  'new-zealand': { marketName: CountryNames.NZ, countryCode: 'NZ' },
  nicaragua: { marketName: CountryNames.NI, countryCode: 'NI' },
  niger: { marketName: CountryNames.NE, countryCode: 'NE' },
  nigeria: { marketName: CountryNames.NG, countryCode: 'NG' },
  niue: { marketName: CountryNames.NU, countryCode: 'NU' },
  'norfolk-island': { marketName: CountryNames.NF, countryCode: 'NF' },
  'north-korea': { marketName: CountryNames.KP, countryCode: 'KP' },
  'north-macedonia': { marketName: CountryNames.MK, countryCode: 'MK' },
  'northern-mariana-islands': { marketName: CountryNames.MP, countryCode: 'MP' },
  norway: { marketName: CountryNames.NO, countryCode: 'NO' },
  oman: { marketName: CountryNames.OM, countryCode: 'OM' },
  pakistan: { marketName: CountryNames.PK, countryCode: 'PK' },
  palau: { marketName: CountryNames.PW, countryCode: 'PW' },
  'palestine-state-of': { marketName: CountryNames.PS, countryCode: 'PS' },
  panama: { marketName: CountryNames.PA, countryCode: 'PA' },
  'papua-new-guinea': { marketName: CountryNames.PG, countryCode: 'PG' },
  paraguay: { marketName: CountryNames.PY, countryCode: 'PY' },
  peru: { marketName: CountryNames.PE, countryCode: 'PE' },
  philippines: { marketName: CountryNames.PH, countryCode: 'PH' },
  pitcairn: { marketName: CountryNames.PN, countryCode: 'PN' },
  poland: { marketName: CountryNames.PL, countryCode: 'PL' },
  portugal: { marketName: CountryNames.PT, countryCode: 'PT' },
  'puerto-rico': { marketName: CountryNames.PR, countryCode: 'PR' },
  qatar: { marketName: CountryNames.QA, countryCode: 'QA' },
  'republic-of-the-congo': { marketName: CountryNames.CG, countryCode: 'CG' },
  reunion: { marketName: CountryNames.RE, countryCode: 'RE' },
  romania: { marketName: CountryNames.RO, countryCode: 'RO' },
  'russian-federation': { marketName: CountryNames.RU, countryCode: 'RU' },
  rwanda: { marketName: CountryNames.RW, countryCode: 'RW' },
  'saint-barthelemy': { marketName: CountryNames.BL, countryCode: 'BL' },
  'saint-helena-ascension-island-tristan-da-cunha': { marketName: CountryNames.SH, countryCode: 'SH' },
  'saint-kitts-and-nevis': { marketName: CountryNames.KN, countryCode: 'KN' },
  'saint-lucia': { marketName: CountryNames.LC, countryCode: 'LC' },
  'saint-martin': { marketName: CountryNames.MF, countryCode: 'MF' },
  'saint-pierre-and-miquelon': { marketName: CountryNames.PM, countryCode: 'PM' },
  'saint-vincent-and-the-grenadines': { marketName: CountryNames.VC, countryCode: 'VC' },
  samoa: { marketName: CountryNames.WS, countryCode: 'WS' },
  'san-marino': { marketName: CountryNames.SM, countryCode: 'SM' },
  'sao-tome-and-principe': { marketName: CountryNames.ST, countryCode: 'ST' },
  'saudi-arabia': { marketName: CountryNames.SA, countryCode: 'SA' },
  senegal: { marketName: CountryNames.SN, countryCode: 'SN' },
  serbia: { marketName: CountryNames.RS, countryCode: 'RS' },
  seychelles: { marketName: CountryNames.SC, countryCode: 'SC' },
  'sierra-leone': { marketName: CountryNames.SL, countryCode: 'SL' },
  singapore: { marketName: CountryNames.SG, countryCode: 'SG' },
  'sint-maarten': { marketName: CountryNames.SX, countryCode: 'SX' },
  slovakia: { marketName: CountryNames.SK, countryCode: 'SK' },
  slovenia: { marketName: CountryNames.SI, countryCode: 'SI' },
  'solomon-islands': { marketName: CountryNames.SB, countryCode: 'SB' },
  somalia: { marketName: CountryNames.SO, countryCode: 'SO' },
  'south-africa': { marketName: CountryNames.ZA, countryCode: 'ZA' },
  'south-sudan': { marketName: CountryNames.SS, countryCode: 'SS' },
  spain: { marketName: CountryNames.ES, countryCode: 'ES' },
  'sri-lanka': { marketName: CountryNames.LK, countryCode: 'LK' },
  sudan: { marketName: CountryNames.SD, countryCode: 'SD' },
  suriname: { marketName: CountryNames.SR, countryCode: 'SR' },
  'svalbard-jan-mayen': { marketName: CountryNames.SJ, countryCode: 'SJ' },
  sweden: { marketName: CountryNames.SE, countryCode: 'SE' },
  switzerland: { marketName: CountryNames.CH, countryCode: 'CH' },
  'syrian-arab-republic': { marketName: CountryNames.SY, countryCode: 'SY' },
  taiwan: { marketName: CountryNames.TW, countryCode: 'TW' },
  tajikistan: { marketName: CountryNames.TJ, countryCode: 'TJ' },
  'tanzania-the-united-republic-of': { marketName: CountryNames.TZ, countryCode: 'TZ' },
  thailand: { marketName: CountryNames.TH, countryCode: 'TH' },
  'timor-leste': { marketName: CountryNames.TL, countryCode: 'TL' },
  togo: { marketName: CountryNames.TG, countryCode: 'TG' },
  tokelau: { marketName: CountryNames.TK, countryCode: 'TK' },
  tonga: { marketName: CountryNames.TO, countryCode: 'TO' },
  'trinidad-and-tobago': { marketName: CountryNames.TT, countryCode: 'TT' },
  tunisia: { marketName: CountryNames.TN, countryCode: 'TN' },
  turkey: { marketName: CountryNames.TR, countryCode: 'TR' },
  turkmenistan: { marketName: CountryNames.TM, countryCode: 'TM' },
  'turks-and-caicos-islands': { marketName: CountryNames.TC, countryCode: 'TC' },
  tuvalu: { marketName: CountryNames.TV, countryCode: 'TV' },
  uganda: { marketName: CountryNames.UG, countryCode: 'UG' },
  ukraine: { marketName: CountryNames.UA, countryCode: 'UA' },
  'united-arab-emirates': { marketName: CountryNames.AE, countryCode: 'AE' },
  'united-kingdom': { marketName: CountryNames.GB, countryCode: 'GB' },
  'united-states-minor-outlying-islands': { marketName: CountryNames.UM, countryCode: 'UM' },
  'united-states-of-america': { marketName: CountryNames.US, countryCode: 'US' },
  uruguay: { marketName: CountryNames.UY, countryCode: 'UY' },
  uzbekistan: { marketName: CountryNames.UZ, countryCode: 'UZ' },
  vanuatu: { marketName: CountryNames.VU, countryCode: 'VU' },
  'venezuela-bolivarian-republic-of': { marketName: CountryNames.VE, countryCode: 'VE' },
  vietnam: { marketName: CountryNames.VN, countryCode: 'VN' },
  'virgin-islands-british': { marketName: CountryNames.VG, countryCode: 'VG' },
  'virgin-islands-us': { marketName: CountryNames.VI, countryCode: 'VI' },
  'wallis-and-futuna': { marketName: CountryNames.WF, countryCode: 'WF' },
  'western-sahara': { marketName: CountryNames.EH, countryCode: 'EH' },
  yemen: { marketName: CountryNames.YE, countryCode: 'YE' },
  zambia: { marketName: CountryNames.ZM, countryCode: 'ZM' },
  zimbabwe: { marketName: CountryNames.ZW, countryCode: 'ZW' },
};

export const UNKNOWN_COUNTRY = 'Unknown';

import type { ChartData } from 'chart.js';
import { barChartStyles, lineChartStyles, trendAndBarHeight, trendAndBarMockData } from '@/constants';
import { type IChart, type TimeKey, ChartType, ChartPercentageKeys, type IPmAnalyticsQueryOutput } from '@/interfaces';
import {
  findXAndYKeys,
  getChartLabels,
  getBarMetricsConfig,
  getTrendMetricsConfig,
  formatFloatingNumber,
  getPreviousTimeframes,
} from '@/utils';

type TransformTrendAndBarData = (originalData: IChart) => {
  data: IChart['data'];
  chartLabels: IPmAnalyticsQueryOutput[];
  chartData: any;
  options: Record<string, any>;
  hasData: boolean;
  heights: {
    chartDesktopHeight: number;
    wallDesktopHeight: number;
  };
};

export const transformTrendAndBarData: TransformTrendAndBarData = (originalData) => {
  const isBarMetrics = originalData.type === ChartType.BarMetrics;
  const chartStyles = isBarMetrics ? barChartStyles : lineChartStyles;
  const defaultChartData: ChartData<'line'> | ChartData<'bar'> = {
    labels: [],
    datasets: [{ data: [], ...chartStyles }],
  };

  const { query, type } = originalData;
  let data = originalData.data;
  const chartLabels = getChartLabels(query.output);

  // S1: Find X and Y keys
  const { numericalKey, stringKey } = findXAndYKeys(query);

  if (!numericalKey)
    return {
      data: [],
      chartLabels,
      chartData: defaultChartData,
    };

  // S2: Check if is percentage number to show correct UI
  const isPercentageNumber = Object.values(ChartPercentageKeys).includes(numericalKey as ChartPercentageKeys);

  // S3a: If there is real data, use it
  // S3b: If there is no real data, create mock data
  const filteredData = data?.filter((item) => !!item[numericalKey]);
  const hasData = !!filteredData?.length;
  const mockValuesData = trendAndBarMockData[type];

  if (!hasData && mockValuesData) {
    const mockValuesArray = isPercentageNumber ? mockValuesData.percentageValues : mockValuesData.numberValues;
    const previousTimeframes = getPreviousTimeframes({
      key: stringKey as TimeKey,
      limit: mockValuesArray.length,
    });
    data = mockValuesArray.map((value, index) => ({
      [numericalKey]: value,
      [stringKey]: previousTimeframes[index],
    }));
  }

  // S4a: Find max number
  // S4b: Create list of X and Y data
  // S4c: Create originalData
  let highestNumber = data?.length ? (data[0] as any)[numericalKey] * 1 : 0;

  const mappedObj = (data as any[]).reduce(
    (acc, item) => {
      const number = item[numericalKey] * 1;
      if (!number) return acc;
      highestNumber = Math.max(highestNumber, number);

      const numberValue = formatFloatingNumber(number, 1) || 0;
      const stringValue = item[stringKey] || '';

      acc.chartData.datasets[0].data.push(numberValue);
      acc.chartData.labels?.push(stringValue);
      acc.data.push(item);

      return acc;
    },
    {
      data: [] as any[],
      chartData: defaultChartData,
    },
  );

  // S5: Generate chart styling options
  const options = isBarMetrics
    ? getBarMetricsConfig({
        hasActualData: hasData,
        highestNumber,
        isPercentageNumber,
      })
    : getTrendMetricsConfig({
        hasActualData: hasData,
        highestNumber,
        isPercentageNumber,
        hasNegativeData: filteredData?.some((item) => item[numericalKey] < 0),
      });

  // S6: Get heights for chart and wall
  const heights = trendAndBarHeight[type];

  return {
    options,
    chartLabels,
    hasData,
    heights,
    ...mappedObj,
  };
};

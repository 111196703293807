import { ButtonFunctionKey, type ButtonProps, ButtonSize, type IPageOverviewBanner } from '@/interfaces';
import { useGlobalState } from '@/hooks';
import { Button } from '@/components';
import { ButtonWrapper } from './ButtonGroup.styles';

interface IButtonGroupProps {
  buttons: IPageOverviewBanner['buttons'];
  buttonClick?: (button: ButtonProps) => void;
}

const ButtonGroup: React.FC<IButtonGroupProps> = ({ buttons, buttonClick }) => {
  const isEmptyBtnOnLoggedIn = !buttons?.filter((button) => !button.hideOnLoggedIn).length;

  const { setIsFromJoinUsBtn, isLoggedIn } = useGlobalState();

  if (!buttons?.length || (isEmptyBtnOnLoggedIn && isLoggedIn)) return null;

  const handleClickButton = (button: ButtonProps) => {
    if (button.function === ButtonFunctionKey.PopUp) {
      setIsFromJoinUsBtn(true);
      buttonClick && buttonClick(button);
    }
  };

  return (
    <ButtonWrapper>
      {buttons.map((button, index) => {
        if (!button || (button.hideOnLoggedIn && isLoggedIn)) {
          return null;
        }

        return (
          <Button
            {...button}
            key={index}
            size={ButtonSize.Large}
            mobileSize={ButtonSize.Large}
            onClick={() => handleClickButton(button)}
          />
        );
      })}
    </ButtonWrapper>
  );
};

export default ButtonGroup;

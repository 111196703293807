import styled from 'styled-components';

export const Flex = styled.div<{
  direction?: 'row' | 'column';
  justify?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around';
  align?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around';
  wrap?: 'wrap' | 'nowrap';
}>(({ direction = 'row', justify = 'flex-start', align = 'flex-start', wrap = 'nowrap' }) => ({
  display: 'flex',
  flexDirection: direction,
  justifyContent: justify,
  alignItems: align,
  flexWrap: wrap,
}));

import styled from 'styled-components';

import { theme } from '@/constants';

export const LoginButtonWrapper = styled.div<{
  disabled: boolean;
  backgroundColor?: string;
}>`
  height: 48px;
  width: 368px;
  padding: 2px 0 2px 2px;
  color: white;
  text-align: center;
  background-color: ${({ backgroundColor }) => backgroundColor || '#0077B5'};
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 25%);
  border-radius: 4px;
  cursor: pointer;
  align-items: center;
  display: inline-flex;
  text-transform: uppercase;

  ${(props) =>
    props.disabled &&
    `
    opacity: 0.6;
  `}
`;

export const Logo = styled.span`
  width: 44px;
  height: 44px;
  border-radius: 2px;
  background: ${theme.colors.highEmphasisWhite};
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 24px;
    height: 24px;
  }
`;

export const Text = styled.span`
  display: block;
  font-weight: 600;
  line-height: 100%;
  flex: 1;
`;

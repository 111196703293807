import { SectionTitle, SectionWrapper } from '@/components';
import type { IWordCloud } from '@/interfaces';
import styled from 'styled-components';
import { SectionSubTitle } from '../DataSection/DataSection.styles';

export const WordCloudBackgroundContainer = styled(SectionWrapper)(({ theme }) => ({
  position: 'relative',
  background: theme.colors.highEmphasisWhite,
  padding: `${theme.spacing[20]} 0`,
}));

export const WordCloudSectionWrapper = styled.div({
  maxWidth: 1140,
  width: '100%',
  margin: '0 auto',
});

export const WordCloudSectionHeader = styled.div(({ theme }) => ({
  margin: `0 ${theme.spacing[20]}`,
}));

export const WordCloudSectionTitle = styled(SectionTitle)<{ alignment: IWordCloud['alignment'] }>(({ alignment }) => ({
  ...(alignment === 'Center' && {
    textAlign: 'center',

    '&::after': {
      left: '50%',
      transform: 'translateX(-50%)',
    },
  }),
}));

export const WordCloudSectionSubText = styled(SectionSubTitle)<{ alignment: IWordCloud['alignment'] }>(
  ({ theme, alignment }) => ({
    marginBottom: theme.spacing[16],
    textAlign: alignment === 'Center' ? 'center' : 'left',

    [`${theme.mediaBreakpointUp.md}`]: {
      marginBottom: theme.spacing[24],
    },
  }),
);

import styled from 'styled-components';
import { LayoutHierarchy } from '@/constants';

export const PopupContainer = styled.div`
  position: fixed;
  z-index: ${LayoutHierarchy.Second};
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PopupOverlay = styled.div`
  background: ${({ theme }) => theme.colors.overlay};
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
`;

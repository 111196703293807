import { useEffect } from 'react';
import { type IPanel, OnboardingStepName } from '@/interfaces';
import { useGlobalState } from '@/hooks';
import { FollowUsWrapper, Iframe, LinkedInWrapper, SkipButton, Image } from './FollowUs.styles';
import { CookieKey } from '@/constants';
import Cookies from 'js-cookie';
import { gtmClickSkipLinkedIn, gtmLinkedInFollowSuccess } from '@/utils';

const FollowUs: React.FC<IPanel> = ({ image }) => {
  const { isFollowingLinkedIn, currentStep, setCurrentStep, userInfo, getProperImageUrl } = useGlobalState();

  const initFollow = Cookies.get(CookieKey.IsFollowing);
  const isBasicDetails = !!userInfo?.firstName && !!userInfo?.lastName && !!userInfo?.companyName;

  useEffect(() => {
    const isFollowing = Cookies.get(CookieKey.IsFollowing);
    const isTriggerGTMFollowUsStep = initFollow === 'false' && isFollowing === 'true';
    if (isTriggerGTMFollowUsStep) {
      gtmLinkedInFollowSuccess(userInfo?.authenticationMethod);
    }
    if (currentStep === OnboardingStepName.followUs && isFollowing === 'true') {
      setCurrentStep(OnboardingStepName.aboutYou);
    }
  }, [isFollowingLinkedIn]);

  const onSkip = () => {
    gtmClickSkipLinkedIn(userInfo?.authenticationMethod);
    setCurrentStep(OnboardingStepName[isBasicDetails ? 'thankYou' : 'aboutYou']);
  };

  return (
    <FollowUsWrapper>
      <LinkedInWrapper>
        <Image
          src={getProperImageUrl(image)}
          alt='Follow Us'
          style={{
            objectFit: 'cover',
          }}
        />
        <Iframe />
      </LinkedInWrapper>
      <SkipButton onClick={onSkip}>SKIP FOR NOW</SkipButton>
    </FollowUsWrapper>
  );
};

export default FollowUs;

import Image from 'next/image';
import { TrackerState } from '@/constants';
import { OnboardingStepName } from '@/interfaces';
import { useGlobalState } from '@/hooks';
import {
  ProgressLine,
  ProgressItem,
  ProgressNumber,
  ProgressText,
  ProgressTitle,
  TrackerWrapper,
  CheckIcon,
} from './ProgressTracker.styles';

type ProgressType = {
  name: string;
  state: TrackerState;
};

const ProgressTracker: React.FC<{
  currentStep?: OnboardingStepName;
  isFullScreen?: boolean;
}> = ({ currentStep: _currentStep, isFullScreen }) => {
  const { currentStep } = useGlobalState();

  const getCurrentState = (step: OnboardingStepName): TrackerState => {
    switch (step) {
      case OnboardingStepName.joinUs:
        if (currentStep === OnboardingStepName.joinUs) return TrackerState.active;
        return TrackerState.checked;
      case OnboardingStepName.followUs:
        if (currentStep === OnboardingStepName.joinUs) return TrackerState.disabled;
        if (currentStep === OnboardingStepName.followUs) return TrackerState.active;
        return TrackerState.checked;
      case OnboardingStepName.aboutYou:
        if (currentStep === OnboardingStepName.aboutYou) return TrackerState.active;
        if ([OnboardingStepName.thankYou, OnboardingStepName.accountVerification].includes(currentStep)) {
          return TrackerState.checked;
        }
        return TrackerState.disabled;
    }
    return TrackerState.disabled;
  };

  const progressValue: ProgressType[] = [
    {
      name: 'Join Us',
      state: getCurrentState(OnboardingStepName.joinUs),
    },
    {
      name: 'Follow Us',
      state: getCurrentState(OnboardingStepName.followUs),
    },
    {
      name: 'About You',
      state: getCurrentState(OnboardingStepName.aboutYou),
    },
  ];

  return (
    <TrackerWrapper isFullScreen={isFullScreen}>
      {progressValue.map((item, index) => {
        return (
          <ProgressItem key={index} state={item.state} isFullScreen={isFullScreen} length={progressValue.length}>
            <ProgressText>
              <ProgressNumber>
                {item.state !== TrackerState.checked ? (
                  index + 1
                ) : (
                  <CheckIcon>
                    <Image src='/images/icons/icon_check.svg' alt='check icon' fill sizes='100vw' />
                  </CheckIcon>
                )}
              </ProgressNumber>
              <ProgressTitle>{item.name}</ProgressTitle>
            </ProgressText>
            {index !== progressValue.length - 1 && <ProgressLine isFullScreen={isFullScreen} />}
          </ProgressItem>
        );
      })}
    </TrackerWrapper>
  );
};

export default ProgressTracker;

import Link from 'next/link';
import { useEffect, useRef } from 'react';
import { useOverflowing, useResponsive } from '@/hooks';
import { ResponsiveCollection } from '@/interfaces';
import { Tooltip } from '@/components';
import { Anchor, TableTextContainer, Text } from './TableText.styles';

type TableTextProps = {
  children: string;
  url?: string;
  limit?: number;
  styles?: React.CSSProperties;
  enableTooltip?: boolean;
};

/** A common table text component: text with tooltip, text with no tooltip, or text with link
 * @param children text to display in the table cell
 * @param url link to navigate to when clicking the text
 * @param limit number of lines to limit the text to - for tooltip
 * @param styles custom styles to apply to the text
 * @param enableTooltip whether to show tooltip if text is overflowing
 * @returns table cell text with tooltip and link
 * @example
 * // Desktop: allow hover to show tooltip
 * // Mobile: only show tooltip if has no url
 * <TableText>Text</TableText>
 * <TableText url='https://google.com'>Text</TableText>
 * <TableText limit={1}>Text</TableText>
 * <TableText url='https://google.com' limit={1} styles={{ color: 'red' }}>Text</TableText>
 */
const TableText = ({ children, url, limit = 2, styles, enableTooltip = true }: TableTextProps) => {
  if (!children) return null;

  const tableTooltipTextRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);
  const { isDesktop } = useResponsive([ResponsiveCollection.Desktop]);
  const isOverflowing = useOverflowing({
    ref: tableTooltipTextRef,
    limit,
  });

  useEffect(() => {
    if (!enableTooltip) return;

    if (textRef.current) {
      if (isOverflowing) {
        textRef.current.classList.add('has-tooltip');
      } else {
        textRef.current.classList.remove('has-tooltip');
      }
    }
  }, [isOverflowing, tableTooltipTextRef.current, textRef.current]);

  const TextWrapperComponent = () => {
    if (url) {
      return (
        <Link href={url} passHref legacyBehavior>
          <Anchor>
            <Text {...styles}>{children}</Text>
          </Anchor>
        </Link>
      );
    }
    return <Text {...styles}>{children}</Text>;
  };

  const renderInnerTextComponent = () => {
    const hasTooltipCondition = enableTooltip && isOverflowing && (!url || isDesktop);
    if (hasTooltipCondition) {
      return (
        children && (
          <Tooltip content={children}>
            <div ref={textRef}>
              <TextWrapperComponent />
            </div>
          </Tooltip>
        )
      );
    }

    return (
      <div ref={textRef}>
        <TextWrapperComponent />
      </div>
    );
  };

  return (
    <TableTextContainer limit={limit} style={{ width: '100%' }} ref={tableTooltipTextRef} enableTooltip={enableTooltip}>
      {renderInnerTextComponent()}
    </TableTextContainer>
  );
};

export default TableText;

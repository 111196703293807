import { useEffect, useState } from 'react';

const useElementInView = (id: string) => {
  const [isInView, setIsInView] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = () => {
      const element = document.getElementById(id);
      if (!element) return;
      const { top } = element.getBoundingClientRect();
      const isInViewport = top >= 0 && top <= window.innerHeight;
      setIsInView(isInViewport);
    };

    handleScroll();

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  return isInView;
};

export default useElementInView;

import { DesktopSubtext, DesktopTitle, MobileSubtext, TextContainer, MobileTitle } from './WidgetTextSection.styles';

interface WidgetTextSectionProps {
  desktopTitle: string;
  mobileTitle: string;
  mobileSubText: string;
  desktopSubText: string;
}

const WidgetTextSection: React.FC<WidgetTextSectionProps> = ({
  mobileTitle,
  desktopTitle,
  mobileSubText,
  desktopSubText,
}) => {
  return (
    <TextContainer>
      <MobileTitle>{mobileTitle}</MobileTitle>
      <DesktopTitle>{desktopTitle}</DesktopTitle>
      <MobileSubtext>{mobileSubText}</MobileSubtext>
      <DesktopSubtext>{desktopSubText}</DesktopSubtext>
    </TextContainer>
  );
};

export default WidgetTextSection;

import { type SyntheticEvent, useEffect, useState } from 'react';
import Image, { type ImageProps } from 'next/image';

type ImageWithFallbackProps = ImageProps & {
  fallbackSrc?: string;
  useNextImage?: boolean;
};

const ImageWithFallback = (props: ImageWithFallbackProps) => {
  const { src, fallbackSrc, useNextImage, ...rest } = props;
  const [imgSrc, setImgSrc] = useState(src);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setHasError(false);
    setImgSrc(src);
  }, [src]);

  const handleErrorImage = (e?: SyntheticEvent<HTMLImageElement, Event>) => {
    if (e && !fallbackSrc) {
      setHasError(true);
    }
    fallbackSrc && setImgSrc(fallbackSrc);
  };

  if (hasError) {
    return null;
  }

  if (useNextImage) {
    return (
      <Image
        {...rest}
        src={imgSrc}
        loader={({ src, width }) => {
          if (width === 0) {
            // Broken image
            handleErrorImage();
          }

          return src
        }}
        onError={handleErrorImage}
        style={{
          maxWidth: '100%',
          height: 'auto',
        }}
      />
    );
  }

  return <img {...rest} src={imgSrc as string} onError={handleErrorImage} />;
};

export default ImageWithFallback;

import { DividerIcon, DividerIconWrapper, DividerWrapper } from './TradeRouteSelector.styles';

const TradeRouteDivider: React.FC = () => {
  return (
    <DividerWrapper>
      <DividerIconWrapper>
        <DividerIcon width={24} height={24} />
      </DividerIconWrapper>
    </DividerWrapper>
  );
};

export default TradeRouteDivider;

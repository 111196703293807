import { Locale, LanguageCode, type ILanguage } from '@/interfaces';

export const DEFAULT_LANGUAGE = Locale.EnglishUnitedStates;

export const FALLBACK_PMT_LANGUAGE_CODE = LanguageCode.English;

export const LOCALE_GROUPS: {
  [key in Locale]: LanguageCode[];
} = {
  [Locale.EnglishUnitedStates]: [
    LanguageCode.English,
    LanguageCode.Portuguese,
    LanguageCode.Thai,
    LanguageCode.Indonesian,
    LanguageCode.Serbian,
  ],
  [Locale.Chinese]: [
    LanguageCode.SimplifiedChinese,
    LanguageCode.TraditionalChinese,
    LanguageCode.Danish,
    LanguageCode.Korean,
    LanguageCode.Hindi,
  ],
  [Locale.French]: [
    LanguageCode.French,
    LanguageCode.Polish,
    LanguageCode.Turkish,
    LanguageCode.Malay,
    LanguageCode.Tamil,
  ],
  [Locale.Germany]: [
    LanguageCode.German,
    LanguageCode.Russian,
    LanguageCode.Hungarian,
    LanguageCode.Vietnamese,
    LanguageCode.Slovenian,
  ],
  [Locale.Italian]: [LanguageCode.Italian, LanguageCode.Romania, LanguageCode.Norwegian, LanguageCode.Slovak],
  [Locale.Japanese]: [LanguageCode.Japanese, LanguageCode.Greek, LanguageCode.Finland, LanguageCode.Czech],
  [Locale.Spanish]: [LanguageCode.Spanish, LanguageCode.Dutch, LanguageCode.Swedish, LanguageCode.Croatia],
};

export const LANGUAGES: ILanguage[] = [
  {
    code: LanguageCode.English,
    name: 'English',
    englishName: 'English',
  },
  {
    code: LanguageCode.SimplifiedChinese,
    name: '简体中文',
    englishName: 'Simplified Chinese',
  },
  {
    code: LanguageCode.TraditionalChinese,
    name: '繁體中文',
    englishName: 'Traditional Chinese',
  },
  {
    code: LanguageCode.Russian,
    name: 'Русский',
    englishName: 'Russian',
  },
  {
    code: LanguageCode.Hindi,
    name: 'हिन्दी',
    englishName: 'Hindi',
  },
  {
    code: LanguageCode.Japanese,
    name: '日本語',
    englishName: 'Japanese',
  },
  {
    code: LanguageCode.Korean,
    name: '한국어',
    englishName: 'Korean',
  },
  {
    code: LanguageCode.Thai,
    name: 'ภาษาไทย',
    englishName: 'Thai language',
  },
  {
    code: LanguageCode.Indonesian,
    name: 'Bahasa Indonesia',
    englishName: 'Indonesian',
  },
  {
    code: LanguageCode.Malay,
    name: 'Bahasa Melayu',
    englishName: 'Malay',
  },
  {
    code: LanguageCode.Tamil,
    name: 'தமிழ்',
    englishName: 'Tamil',
  },
  {
    code: LanguageCode.Vietnamese,
    name: 'Tiếng Việt',
    englishName: 'Vietnamese',
  },
  {
    code: LanguageCode.German,
    name: 'Deutsch',
    englishName: 'German',
  },
  {
    code: LanguageCode.French,
    name: 'Français',
    englishName: 'French',
  },
  {
    code: LanguageCode.Spanish,
    name: 'Español',
    englishName: 'Spanish',
  },
  {
    code: LanguageCode.Italian,
    name: 'Italiano',
    englishName: 'Italian',
  },
  {
    code: LanguageCode.Portuguese,
    name: 'Português',
    englishName: 'Portuguese',
  },
  {
    code: LanguageCode.Croatia,
    name: 'Hrvatska',
    englishName: 'Croatia',
  },
  {
    code: LanguageCode.Czech,
    name: 'Čeština',
    englishName: 'Czech',
  },
  {
    code: LanguageCode.Danish,
    name: 'Dansk',
    englishName: 'Danish',
  },
  {
    code: LanguageCode.Dutch,
    name: 'Nederlands',
    englishName: 'Dutch',
  },
  {
    code: LanguageCode.Finland,
    name: 'Suomi',
    englishName: 'Finland',
  },
  {
    code: LanguageCode.Greek,
    name: 'Ελληνικά',
    englishName: 'Greek',
  },
  {
    code: LanguageCode.Hungarian,
    name: 'Magyar',
    englishName: 'Hungarian',
  },
  {
    code: LanguageCode.Norwegian,
    name: 'Norsk',
    englishName: 'Norwegian bokmål',
  },
  {
    code: LanguageCode.Polish,
    name: 'Polski',
    englishName: 'Polish',
  },
  {
    code: LanguageCode.Romania,
    name: 'Română',
    englishName: 'Romania',
  },
  {
    code: LanguageCode.Serbian,
    name: 'Srpski',
    englishName: 'Serbian',
  },
  {
    code: LanguageCode.Swedish,
    name: 'Svenska',
    englishName: 'Swedish',
  },
  {
    code: LanguageCode.Turkish,
    name: 'Türkçe',
    englishName: 'Turkish',
  },
  {
    code: LanguageCode.Slovenian,
    name: 'Slovenščina',
    englishName: 'Slovenian',
  },
  {
    code: LanguageCode.Slovak,
    name: 'Slovenský',
    englishName: 'Slovak',
  },
];

import styled from 'styled-components';

export const LoginButton = styled.button<{
  disabled?: boolean;
  backgroundColor?: string;
}>(({ backgroundColor, disabled = false }) => ({
  height: 48,
  width: '100%',
  padding: '2px 0 2px 2px',
  color: 'white',
  textAlign: 'center',
  backgroundColor: backgroundColor ?? '#0077B5',
  border: 'none',
  boxShadow: '0px 2px 4px 0px rgba(0,0,0,0.25)',
  borderRadius: 4,
  cursor: 'pointer',
  alignItems: 'center',
  display: 'inline-flex',
  textTransform: 'uppercase',
  opacity: disabled ? 0.6 : 1,
}));

export const Logo = styled.span(({ theme }) => ({
  width: 44,
  height: 44,
  borderRadius: 2,
  background: theme.colors.highEmphasisWhite,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const Text = styled.span({
  display: 'block',
  fontWeight: 600,
  lineHeight: '100%',
  flex: 1,
});

import { ContentTypeName, WordCloudMapping } from '@/constants';
import { type IWordCloud, WordCloudLayout } from '@/interfaces';
import {
  WordCloudBackgroundContainer,
  WordCloudSectionHeader,
  WordCloudSectionSubText,
  WordCloudSectionTitle,
  WordCloudSectionWrapper,
} from './WordCloud.styles';

const WordCloud: React.FC<IWordCloud> = ({
  alignment,
  title,
  layout,
  subText,
  buttonLink,
  wordBubbles,
  tradelanePool,
}) => {
  const tradelaneBubbles =
    tradelanePool?.map((tradelaneSlug: string) => ({
      __typename: ContentTypeName.Tradelane,
      slug: tradelaneSlug,
    })) || [];

  const props = { wordBubbles: [...wordBubbles, ...tradelaneBubbles], buttonLink };

  const renderLayout = () => {
    const WordCloudLayoutComponent = WordCloudMapping[layout || WordCloudLayout.Slider];
    return <WordCloudLayoutComponent {...props} alignment={alignment} />;
  };

  const renderSectionSubText = (subText?: IWordCloud['subText'], isDesktop = false) => {
    if (!subText) return null;

    return (
      <WordCloudSectionSubText alignment={alignment} isDesktop={isDesktop}>
        {subText}
      </WordCloudSectionSubText>
    );
  };

  return (
    <WordCloudBackgroundContainer>
      <WordCloudSectionWrapper>
        <WordCloudSectionHeader>
          <WordCloudSectionTitle alignment={alignment}>{title}</WordCloudSectionTitle>
          {renderSectionSubText(subText)}
          {renderSectionSubText(subText, true)}
        </WordCloudSectionHeader>
        {renderLayout()}
      </WordCloudSectionWrapper>
    </WordCloudBackgroundContainer>
  );
};

export default WordCloud;

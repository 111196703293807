import type { ISingleItem } from '@/interfaces';
import type React from 'react';
import type { HTMLAttributes } from 'react';
import Link from 'next/link';
import { ArrowIcon, StyledDetailsLinkValue, StyledDetailsValue } from './ServiceOfferingValue.styles';

const ServiceOfferingValue: React.FC<ISingleItem & HTMLAttributes<HTMLParagraphElement>> = ({ title, description }) => {
  if (description) {
    return (
      <Link passHref href={description} legacyBehavior>
        <StyledDetailsLinkValue target='_blank'>
          <ArrowIcon />
          {title}
        </StyledDetailsLinkValue>
      </Link>
    );
  }

  return (
    <StyledDetailsValue>
      <ArrowIcon />
      {title}
    </StyledDetailsValue>
  );
};

export default ServiceOfferingValue;

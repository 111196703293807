import type { Asset } from './asset.interfaces';

export interface RenderImageProps {
  isMobile: boolean;
  isExpanded?: boolean;
}

export interface RenderTitleProps {
  title?: string;
  isMobile?: boolean;
}

export interface IReportsJoinUsPopup {
  joinUs: {
    titleDesktop?: string;
    titleMobile?: string;
  };
  collapsedJoinUs: {
    titleDesktop?: string;
    titleMobile?: string;
  };
  renderImage: (props: RenderImageProps) => JSX.Element | null;
  renderTitle: (props: RenderTitleProps) => JSX.Element | null;
}

export interface IReportsDownloadPopup {
  download: {
    title?: string;
    subText?: string;
    buttonText?: string;
    file?: Asset;
  };
  renderImage: (props: RenderImageProps) => JSX.Element | null;
  renderTitle: (props: RenderTitleProps) => JSX.Element | null;
}

export interface IReportsPopup extends IReportsJoinUsPopup, IReportsDownloadPopup {
  imageDesktop?: Asset;
  imageMobile?: Asset;
  pageType?: ReportsPopupType;
}

export interface IDownloadButtonProps {
  file?: IReportsDownloadPopup['download']['file'];
  closePopup: () => void;
}

export enum ReportsPopupType {
  homePage = 'Home Page',
  carrierPages = 'Carrier Pages',
  insights = 'Insights',
  reports = 'Reports',
  news = 'News',
  markets = 'Markets',
}

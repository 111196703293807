import { chartJsStyles } from '@/constants';

type getTrendMetricsConfig = (args: {
  tickCount?: number;
  highestNumber: number;
  isPercentageNumber: boolean;
  hasActualData?: boolean;
  hasNegativeData?: boolean;
}) => Record<string, any>;

export const getTrendMetricsConfig: getTrendMetricsConfig = ({
  tickCount = 6,
  highestNumber,
  isPercentageNumber,
  hasActualData,
  hasNegativeData,
}) => {
  const valuePostfix = isPercentageNumber ? '%' : '';
  const stepSize = Math.ceil(Math.ceil(highestNumber) / (tickCount - 1));
  const additionalConfig = {
    ...chartJsStyles,
    layout: {
      ...chartJsStyles.layout,
      padding: {
        ...chartJsStyles.layout.padding,
        right: 36,
      },
    },
    plugins: {
      ...chartJsStyles.plugins,
      datalabels: {
        ...chartJsStyles.plugins.datalabels,
        formatter: (value: string) => {
          return hasActualData ? value + valuePostfix : '---';
        },
      },
    },
    scales: {
      ...chartJsStyles.scales,
      y: {
        ...chartJsStyles.scales.y,
        ticks: {
          ...chartJsStyles.scales.y.ticks,
          count: tickCount,
          stepSize: isPercentageNumber ? 20 : stepSize,
          callback: (value: string, index: number) => {
            if (index === 0) return '';
            return hasActualData ? value + valuePostfix : '--';
          },
        },
        suggestedMax: isPercentageNumber ? 100 : stepSize * (tickCount - 1),
      },
    },
  };

  if (hasNegativeData && isPercentageNumber) {
    return {
      ...additionalConfig,
      scales: {
        ...additionalConfig.scales,
        y: {
          ...additionalConfig.scales.y,
          ticks: {
            ...additionalConfig.scales.y.ticks,
            count: 6,
            stepSize: 50,
            callback: (value: string) => {
              return hasActualData ? value + valuePostfix : '--';
            },
          },
          min: -100,
          max: 100,
        },
      },
    };
  }

  return additionalConfig;
};

type GetBarMetricsConfig = (args: {
  highestNumber: number;
  tickCount?: number;
  multiple?: number;
  isPercentageNumber?: boolean;
  hasActualData?: boolean;
}) => Record<string, any>;

export const getBarMetricsConfig: GetBarMetricsConfig = ({
  highestNumber,
  tickCount = 7,
  multiple = 25,
  isPercentageNumber,
  hasActualData,
}) => {
  const valuePostfix = isPercentageNumber ? '%' : '';
  const baseValue = highestNumber / (tickCount - 1);
  const stepSize = Math.ceil(baseValue / multiple) * multiple;
  const additionalConfig = {
    ...chartJsStyles,
    barThickness: 48,
    layout: {
      ...chartJsStyles.layout,
      padding: {
        ...chartJsStyles.layout.padding,
        right: 20,
      },
    },
    plugins: {
      ...chartJsStyles.plugins,
      datalabels: {
        ...chartJsStyles.plugins.datalabels,
        anchor: 'end',
        align: 'end',
        formatter: (value: string) => {
          return hasActualData ? value + valuePostfix : '---';
        },
      },
    },
    scales: {
      ...chartJsStyles.scales,
      y: {
        ...chartJsStyles.scales.y,
        ticks: {
          ...chartJsStyles.scales.y.ticks,
          count: tickCount,
          stepSize,
          callback: (value: string, index: number) => {
            if (index === 0) return '';
            return hasActualData ? value + valuePostfix : '--';
          },
        },
        suggestedMax: stepSize * (tickCount - 1),
      },
    },
  };
  return additionalConfig;
};

import styled from 'styled-components';
import { styledText } from '@/components/Typography/Typography.styles';

const Label = styled.label<{ isMandatory?: boolean }>`
  color: ${({ theme }) => theme.colors.inactiveBlack};
  display: block;
  position: absolute;
  top: 0;
  transform: translateY(9px);
  padding: 0 ${({ theme }) => theme.spacing[4]};
  transition: transform 0.1s cubic-bezier(0.25, 0.46, 0.43, 1.16);
  max-width: calc(100% - 22px);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  ${styledText['Body Text Large']}

  ${({ isMandatory }) =>
    isMandatory &&
    `
    &::after {
      content: '*';
    }
  `}

  > *:first-child {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export default Label;

import { ACCEPTED_ARTICLE_TYPES, RouteName } from '@/constants';
import type { IThumbnailSection, ThumbnailProps } from '@/interfaces';
import { replacePath } from '@/utils';
import Link from 'next/link';
import {
  CustomThumbnail,
  ThumbnailSectionContainer,
  ViewAllButton,
  ViewAllSectionContainer,
} from './ViewAllSection.styles';

const MAX_DISPLAYED_THUMBNAILS = 6;

type ViewAllSectionProps = IThumbnailSection & { thumbnails: ThumbnailProps[] };

const ViewAllSection: React.FC<ViewAllSectionProps> = ({ thumbnails, buttonLink }) => {
  return (
    <ViewAllSectionContainer>
      <ThumbnailSectionContainer>
        {thumbnails?.slice(0, MAX_DISPLAYED_THUMBNAILS).map((thumbnailData, index) => {
          const { articleType, slug } = thumbnailData;
          if (!articleType || !slug) return null;
          const href = replacePath(RouteName.Article, [ACCEPTED_ARTICLE_TYPES[articleType], slug]);
          return (
            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
            <Link key={index} href={href} passHref>
              <CustomThumbnail {...thumbnailData} href={href} />
            </Link>
          );
        })}
      </ThumbnailSectionContainer>
      {buttonLink && <ViewAllButton href={buttonLink}>VIEW ALL</ViewAllButton>}
    </ViewAllSectionContainer>
  );
};

export default ViewAllSection;

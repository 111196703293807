import styled from 'styled-components';

export const SocialLinkItem = styled.a.attrs({ target: '_blank' })`
  display: inline-flex;
  width: 32px;
  height: 32px;
  align-items: center;

  :not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing[16]};
  }
`;

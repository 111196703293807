import styled from 'styled-components';

const FlexStyles = `
  display: flex;
  align-items: center;
`;

export const ValuesInnerContainer = styled.div`
  ${FlexStyles}
`;

export const Circle = styled.div<{
  mobileOuterWidth?: number;
  mobileInnerWidth?: number;
  desktopOuterWidth?: number;
  desktopInnerWidth?: number;
}>`
  ${FlexStyles}
  justify-content: center;
  border-radius: 50%;
  min-width: ${({ mobileOuterWidth }) => mobileOuterWidth || 32}px;
  height: ${({ mobileOuterWidth }) => mobileOuterWidth || 32}px;
  border: 1px solid ${({ theme }) => theme.colors.blue};
  margin-right: ${({ theme }) => theme.spacing[16]};

  > svg {
    width: ${({ mobileInnerWidth }) => mobileInnerWidth || 20}px;
    height: ${({ mobileInnerWidth }) => mobileInnerWidth || 20}px;
  }

  > svg path {
    fill: ${({ theme }) => theme.colors.blue};
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    min-width: ${({ desktopOuterWidth, mobileOuterWidth }) => desktopOuterWidth || mobileOuterWidth || 32}px;
    height: ${({ desktopOuterWidth, mobileOuterWidth }) => desktopOuterWidth || mobileOuterWidth || 32}px;

    > svg {
      width: ${({ desktopInnerWidth, mobileInnerWidth }) => desktopInnerWidth || mobileInnerWidth || 20}px;
      height: ${({ desktopInnerWidth, mobileInnerWidth }) => desktopInnerWidth || mobileInnerWidth || 20}px;
    }
  }
`;

// For Paywall
export const PaywallBackgroundContainer = styled.div<{
  isInsideRT?: boolean;
  hasParentHeight?: boolean;
}>`
  padding: ${({ theme }) => theme.spacing[8]} ${({ theme }) => theme.spacing[16]};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;

  // for non-RT
  pointer-events: none;

  > * {
    pointer-events: auto;
  }

  // for RT
  ${({ theme, isInsideRT }) =>
    isInsideRT &&
    `
    padding: ${theme.spacing[16]};
    background: unset;
    margin-bottom: ${theme.spacing[24]};
    min-height: 80px;
  `}

  ${({ hasParentHeight }) =>
    hasParentHeight &&
    `
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  `};
`;

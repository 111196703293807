import type { IDataPicker } from '@/interfaces';

export const mapDataPickerFromRetailers = (
  retailers: any,
): {
  data: any[];
  pagination: IDataPicker['pagination'];
} => {
  const { results, links, page_size, current_page, total_page, count } = retailers;

  return {
    data: results,
    pagination: {
      links,
      pageSize: page_size,
      currentPage: current_page,
      totalPage: total_page,
      count,
    },
  };
};

import { shipmentSummaryRequiredGtmParams, utmQueryParams } from '@/modules/pmt/constants';
import { useMyParcelsContext, useShipmentsOverviewContext } from '@/modules/pmt/context';
import type { IShipment } from '@/modules/pmt/interfaces';
import { useRouter } from 'next/router';
import {
  type Dispatch,
  type ReactNode,
  type SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { getMerchant } from '../fetchers';

interface IShipmentDetailsContext {
  showDeleteShipment: boolean;
  setShowDeleteShipment: Dispatch<SetStateAction<boolean>>;
  showDetailsView: boolean;
  setShowDetailsView: Dispatch<SetStateAction<boolean>>;
  currentShipment: IShipment | undefined;
  currentShipmentDocId: string | undefined;
  setQueryParamsToRemove: Dispatch<SetStateAction<string[]>>;
  setCurrentShipmentDocId: Dispatch<SetStateAction<string | undefined>>;
}

const defaultState = {
  showDeleteShipment: false,
  setShowDeleteShipment: () => null,
  showDetailsView: false,
  setShowDetailsView: () => null,
  currentShipment: undefined,
  currentShipmentDocId: undefined,
  setCurrentShipmentDocId: () => null,
  setQueryParamsToRemove: () => null,
};

const ShipmentDetailsContext = createContext<IShipmentDetailsContext>(defaultState);

export const ShipmentDetailsProvider = ({ children }: { children: ReactNode }) => {
  const router = useRouter();
  const { query, pathname } = router;
  const [showDetailsView, setShowDetailsView] = useState(false);
  const [showDeleteShipment, setShowDeleteShipment] = useState(false);
  const [currentShipmentDocId, setCurrentShipmentDocId] = useState<string>();

  const [queryParamsToRemove, setQueryParamsToRemove] = useState<string[]>([]);

  const { focusFirstShipmentRef } = useMyParcelsContext();
  const { allShipments, shipments, shipmentsLoaded, setShipmentsLoaded } = useShipmentsOverviewContext();

  const currentShipment = allShipments.find((s) => s.data.firestoreDocId === currentShipmentDocId);

  useEffect(() => {
    if (!shipments.length || currentShipmentDocId) return;
    setCurrentShipmentDocId(shipments[0].data.firestoreDocId);
  }, [shipments, currentShipmentDocId]);

  useEffect(() => {
    if (currentShipment?.data.merchantRef) {
      (async () => {
        try {
          const merchant = await getMerchant(currentShipment.data.merchantRef as string);
          if (merchant) {
            currentShipment.data = { ...currentShipment.data, merchantInfo: merchant };
            setShipmentsLoaded(true);
          }
        } catch (err) {
          console.error('Error can not get Merchant info', err);
        }
      })();
    }
  }, [currentShipment]);

  useEffect(() => {
    // reactive state must be checked separately from ref, since ref cannot trigger deps array change
    if (!shipmentsLoaded || !allShipments.length) return;

    // focus first shipment when shipments are loaded and filter has changed
    if (focusFirstShipmentRef.current) {
      focusFirstShipmentRef.current = false;
      setCurrentShipmentDocId(shipments[0]?.data.firestoreDocId);
    }
    setShipmentsLoaded(false);
  }, [allShipments, focusFirstShipmentRef, setShipmentsLoaded, shipments, shipmentsLoaded]);

  useEffect(() => {
    const hasGtmParams = shipmentSummaryRequiredGtmParams.every((val) => !!query[val]);
    if (shipmentsLoaded && hasGtmParams) {
      // shipment in url is bumped in server, so should be on top of shipments list in Firestore
      const emailShipment = shipments[0];
      if (!emailShipment) {
        console.error("Error! Shipment from email can't be found");
        return;
      }
      // set shipment from email as current shipment
      setCurrentShipmentDocId(emailShipment.data.firestoreDocId);
      setShowDetailsView(true);
      // clear params
      shipmentSummaryRequiredGtmParams.forEach((param) => delete query[`${param}`]);
      void router.push({ query, pathname }, undefined, { shallow: true });
    }
  }, [router.query, router.pathname, shipments, shipmentsLoaded]);

  useEffect(() => {
    if (query['isSME']) {
      if (shipmentsLoaded && shipments.length > 0 && query['isSME'] === 'true') {
        setCurrentShipmentDocId(shipments[0].data.firestoreDocId);
        setShowDetailsView(true);
      }

      if (shipmentsLoaded) {
        // clear params
        const queryKeys = Object.keys(query);
        queryKeys.forEach((key) => !utmQueryParams.includes(key) && delete query[`${key}`]);

        void router.replace(
          {
            pathname,
            query,
          },
          undefined,
          { shallow: false },
        );
      }
    }
  }, [router.query, router.pathname, shipments, shipmentsLoaded]);

  useEffect(() => {
    if (query['rating'] && query['shipmentId']) {
      if (shipmentsLoaded && shipments.length > 0 && Number.parseInt(query['rating'] as string) > 0) {
        setShowDetailsView(true);
      }
    }
  }, [router.query, shipments, shipmentsLoaded]);

  useEffect(() => {
    if (query['rating'] && !!queryParamsToRemove.length) {
      queryParamsToRemove.forEach((param) => delete query[param]);
      void router.push({ pathname, query }, undefined, { shallow: true });
    }
  }, [queryParamsToRemove]);

  const ctxValue = useMemo(
    () => ({
      showDeleteShipment,
      setShowDeleteShipment,
      showDetailsView,
      setShowDetailsView,
      currentShipment,
      currentShipmentDocId,
      setCurrentShipmentDocId,
      setQueryParamsToRemove,
    }),
    [currentShipment, currentShipmentDocId, showDeleteShipment, showDetailsView],
  );

  return <ShipmentDetailsContext.Provider value={ctxValue}>{children}</ShipmentDetailsContext.Provider>;
};

export const useShipmentDetailsContext = () => useContext(ShipmentDetailsContext);

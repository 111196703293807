import styled from 'styled-components';
import { theme } from '@/constants';
import { FilledButtonAsLink } from '../Button.styles';

export const styledHyperlink = `
  padding: 0;
  height: unset;
  border-radius: unset;
  word-break: break-word;
  text-transform: unset;
  color: ${theme.colors.hyperlink};
  font-weight: ${theme.fontWeight.regular};
  font-size: ${theme.fontSizes[16]};
  line-height: ${theme.fontSizes[24]};
  letter-spacing: normal;
  
  & > span {
    justify-content: flex-start;
    width: fit-content;
    min-width: auto;
  }

  & > span > svg,
  & > svg,
  & > span > svg path,
  & > svg path {
    fill: ${theme.colors.hyperlink};
  }

  :not([disabled]):visited {
    color: ${theme.colors.hyperlinkVisited};
    & > span > svg,
    & > svg,
    & > span > svg path,
    & > svg path {
      fill: ${theme.colors.hyperlinkVisited};
    }
  }

  :not([disabled]):hover {
    opacity: 0.7;
    text-decoration: underline;
  }
`;

export const ButtonLink = styled(FilledButtonAsLink)`
  ${styledHyperlink}
`;

export const Button = styled(ButtonLink).attrs({
  as: 'button',
})``;

export default { Button, ButtonLink };

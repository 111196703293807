import styled from 'styled-components';
import { TextStyles } from '@/constants';
import { ButtonVariant } from '@/interfaces';
import { Typography, Button } from '@/components';

export const DetailWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.hoverBlack};
  padding: ${({ theme }) => theme.spacing[16]} 11.5px;
  display: flex;

  &:last-child {
    padding-bottom: 0;
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    padding: ${({ theme }) => theme.spacing[16]} 0;
  }
`;

export const ContentWrapper = styled.div`
  padding-left: ${({ theme }) => theme.spacing[16]};
  max-width: calc(100% - 24px);
`;

export const ContentHeader = styled(Typography).attrs({
  variant: TextStyles['Heading 3'],
})`
  margin-bottom: ${({ theme }) => theme.spacing[4]};
`;

export const ContentDescription = styled(Typography).attrs({
  variant: TextStyles['Body Text Small'],
})`
  margin-bottom: ${({ theme }) => theme.spacing[4]};
  color: ${({ theme }) => theme.colors.mediumEmphasisBlack};
`;

export const DetailLinkItem = styled(Button).attrs({
  variant: ButtonVariant.Hyperlink,
})`
  font-size: ${({ theme }) => theme.fontSizes[14]} !important;
  line-height: 21px;
  text-decoration: underline;
`;

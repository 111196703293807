import { CookieKey, DEFAULT_POPUP_TIME_TRIGGERS, ON_PAGE_DURATION_BY_SECOND } from '@/constants';
import { useGlobalState } from '@/hooks';
import { type IOnboardingPopup, OnboardingStepName, UserSessionType } from '@/interfaces';
import { gtmOnboardingPopUpDelay } from '@/utils';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';

// TODO: Separate into multiple hooks/files
const useOnboardingPopUp = ({ popUp }: { popUp?: IOnboardingPopup }) => {
  // STATES
  const {
    isLoggedIn,
    currentStep,
    setCurrentStep,
    isFromJoinUsBtn,
    setIsFromJoinUsBtn,
    isFromBasicDetailsBtn,
    setIsFromBasicDetailsBtn,
    setShowPopUp,
    showPopUp,
    isFromReportsPopup,
    setIsFromReportsPopup,
  } = useGlobalState();
  const { asPath } = useRouter();

  const [isFullScreen, setIsFullScreen] = useState(false);
  const isIntroScreen = [OnboardingStepName.intro, OnboardingStepName.joinUs].includes(currentStep);

  const [timers, setTimers] = useState(() => {
    if (!isIntroScreen) return [];

    const isReturner = Cookies.get(CookieKey.IsReturner);
    const userType = isReturner ? UserSessionType.Return : UserSessionType.New;
    const timer = isReturner ? popUp?.returnUserTriggerTimes : popUp?.newUserTriggerTimes;
    return timer?.length ? timer : DEFAULT_POPUP_TIME_TRIGGERS[userType];
  });
  const delayTime = timers[0];

  const willShowOnClickPopUp = !!(popUp && currentStep !== OnboardingStepName.finish);

  const isMatchedExcludedSlugs = useMemo(
    () => !!popUp?.excludedSlugs?.some((slug) => slug.url === asPath.split('?')[0] || slug.url === asPath),
    [asPath, popUp?.excludedSlugs],
  );

  const willShowTimeBasedPopUp = !!(
    !showPopUp &&
    !isLoggedIn &&
    timers.length &&
    willShowOnClickPopUp &&
    !isMatchedExcludedSlugs
  );
  // UTIL FUNCTIONS
  const closePopUp = () => {
    setShowPopUp(false);
    setIsFromJoinUsBtn(false);
    if (!isIntroScreen) {
      return;
    }

    setTimers((prev) => prev.slice(1));
  };

  const removePopUpTimer = () => {
    setTimers([]);
  };

  useEffect(() => {
    if (Cookies.get(CookieKey.IsReturner)) return;

    const pageTimerValue = Cookies.get(CookieKey.OnPageTimer);
    let onPageTimer = Number.isNaN(+pageTimerValue) ? 0 : +pageTimerValue;

    const interval = setInterval(() => {
      Cookies.set(CookieKey.OnPageTimer, ++onPageTimer);
      if (onPageTimer >= ON_PAGE_DURATION_BY_SECOND) {
        Cookies.set(CookieKey.IsReturner, true);
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (isLoggedIn) setTimers([]);
  }, [isLoggedIn]);

  useEffect(() => {
    document.documentElement.style.overflow = showPopUp ? 'hidden' : 'scroll';
  }, [showPopUp]);

  useEffect(() => {
    if (!isFromReportsPopup) return;
    closePopUp();
    setCurrentStep(OnboardingStepName.followUs);
    removePopUpTimer();
    setIsFromReportsPopup(false);
  }, [isFromReportsPopup]);

  useEffect(() => {
    if (!willShowTimeBasedPopUp) return;

    const timer = setTimeout(() => {
      setCurrentStep(OnboardingStepName.joinUs);
      gtmOnboardingPopUpDelay(delayTime);
      setIsFullScreen(false);
      setShowPopUp(true);
    }, delayTime * 1000);

    if (!isIntroScreen) {
      clearTimeout(timer);
    }
    return () => clearTimeout(timer);
  }, [timers.length, isIntroScreen, currentStep, isFromBasicDetailsBtn]);

  useEffect(() => {
    if (willShowOnClickPopUp && isFromJoinUsBtn) {
      setCurrentStep(OnboardingStepName.joinUs);
      setIsFullScreen(true);
      setShowPopUp(true);
    }
  }, [isFromJoinUsBtn]);

  useEffect(() => {
    if (willShowOnClickPopUp && isFromBasicDetailsBtn) {
      setCurrentStep(OnboardingStepName.aboutYou);
      setIsFullScreen(true);
      setShowPopUp(true);
      setIsFromBasicDetailsBtn(false);
    }
  }, [isFromBasicDetailsBtn]);

  return {
    showPopUp,
    popUp,
    closePopUp,
    removePopUpTimer,
    isFullScreen,
    delayTime,
  };
};

export default useOnboardingPopUp;

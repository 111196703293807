import dynamic from 'next/dynamic';
import { theme } from '@/constants';
import { type ITableTheme, CustomTableField, DataPickerFieldType } from '@/interfaces';

export enum TableFieldName {
  Header = 'Header',
  logoLink = 'logoLink',
  name = 'name',
  carrierName = 'carrierName',
  carrierLogo = 'carrierLogo',
  pParcelToCountryIso = 'pParcelToCountryIso',
  pParcelFromCountryIso = 'pParcelFromCountryIso',
  carrierHeadquarter = 'carrierHeadquarter',
}

export const TableComponentsMapping: {
  [key in TableFieldName]: React.ComponentType<any>;
} = {
  [TableFieldName.Header]: dynamic(() => import('../components/Chart/Table/CustomTableComponents/TableHeaderText')),
  [TableFieldName.logoLink]: dynamic(() => import('../components/Chart/Table/CustomTableComponents/LogoImage')),
  [TableFieldName.carrierLogo]: dynamic(() => import('../components/Chart/Table/CustomTableComponents/LogoImage')),
  [TableFieldName.name]: dynamic(() => import('../components/Chart/Table/CustomTableComponents/TableText')),
  [TableFieldName.carrierName]: dynamic(() => import('../components/Chart/Table/CustomTableComponents/TableText')),
  [TableFieldName.pParcelToCountryIso]: dynamic(
    () => import('../components/Chart/Table/CustomTableComponents/FlagText'),
  ),
  [TableFieldName.pParcelFromCountryIso]: dynamic(
    () => import('../components/Chart/Table/CustomTableComponents/FlagText'),
  ),
  [TableFieldName.carrierHeadquarter]: dynamic(
    () => import('../components/Chart/Table/CustomTableComponents/TableText'),
  ),
};

export const lightTableThemeWithHover: ITableTheme = {
  colors: {
    headerCellBackground: theme.colors.surface05,
    headerCellText: theme.colors.highEmphasisBlack,
    rowHoverBackground: theme.colors.surface02,
  },
};

export const lightTableTheme: ITableTheme = {
  colors: {
    headerCellBackground: theme.colors.surface05,
    headerCellText: theme.colors.highEmphasisBlack,
    rowHoverBackground: theme.colors.highEmphasisWhite,
  },
};

export const darkTableTheme: ITableTheme = {
  colors: {
    headerCellBackground: theme.colors.darkBlue,
    headerCellText: theme.colors.highEmphasisWhite,
    rowHoverBackground: theme.colors.surface02,
  },
};

export const LANDING_PAGE_PAGE_SIZE = 15;
export const ORDER_KEY = 'order';

export const TableColumnWidth: {
  [key: string]: string;
} = {
  [ORDER_KEY]: '67px',
  [DataPickerFieldType.Premium]: '232px',
  [CustomTableField.LogoCarrierNameLink]: '240px',
  [CustomTableField.LogoRetailerNameLink]: '240px',
};

export enum AcceptedDataTypes {
  Symbol = 'Symbol',
  Text = 'Text',
  Boolean = 'Boolean',
  Date = 'Date',
  Link = 'Link', // only 'image'
}

export const ACCEPTED_IMAGE_TYPES = [
  'image/png',
  'image/jpg',
  'image/jpeg',
  'image/svg+xml',
  'image/webp',
  'image/gif',
];

import { RouteName, ROUTES } from '@/constants';

type ReplacePath = (routeName: RouteName | string, params: Array<string | number | undefined | null>) => string;

const replacePath: ReplacePath = (routeName, params) => {
  const isDefinedRouteName = Object.keys(RouteName).includes(routeName);
  const pathToBeReplaced = isDefinedRouteName ? ROUTES[routeName].path : routeName;
  let currentIndex = 0;

  return pathToBeReplaced.replace(/\[([a-zA-Z]+)\]/g, () => `${params[currentIndex++]}`);
};

export default replacePath;

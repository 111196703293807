import type { AnchorHTMLAttributes, ComponentType } from 'react';
import getConfig from 'next/config';

import SsoLoginButton from '../SsoLoginButton';

import { useGlobalState, useLinkedIn } from '@/hooks';
import useLinkedInState from './useLinkedInState.hook';

import { SignUpSource } from '@/constants';
import type { IUserInfo } from '@/interfaces';

interface ILinkedInLoginProps extends AnchorHTMLAttributes<'a'> {
  buttonText?: string;
  shouldReloadAfterSuccess?: boolean;
  signUpSource?: SignUpSource;
  newsletterOptIn?: boolean;
  customHandleSuccess?: (userInfo: IUserInfo) => void;
  customHandleClick?: () => void;
  as?: string | ComponentType<any>;
}

const { publicRuntimeConfig } = getConfig();

const LinkedInLogin: React.FC<ILinkedInLoginProps> = ({
  buttonText = 'CONTINUE WITH LINKEDIN',
  shouldReloadAfterSuccess,
  customHandleSuccess,
  customHandleClick,
  signUpSource = SignUpSource.PMC,
  newsletterOptIn = true,
  ...restProps
}) => {
  const { origin } = useGlobalState();

  const { isLoading, handleSuccess, redirectUri } = useLinkedInState({
    shouldReloadAfterSuccess,
    customHandleSuccess,
    origin,
    signUpSource,
    newsletterOptIn,
  });

  const { linkedInLogin } = useLinkedIn({
    clientId: publicRuntimeConfig.linkedInClientId,
    redirectUri,
    onSuccess: handleSuccess,
  });

  return (
    <SsoLoginButton
      disabled={isLoading}
      buttonText={buttonText}
      logoUrl='/images/linkedin-logo.svg'
      backgroundColor='#0077B5'
      onButtonClick={() => {
        linkedInLogin();
        if (customHandleClick) {
          customHandleClick();
        }
      }}
      {...restProps}
    />
  );
};

export default LinkedInLogin;

import { TooltipVariant } from '@/constants';
import { ChartType, type IPmAnalyticsQueryOutput } from '@/interfaces';
import Tooltip from '@/components/Tooltip';
import {
  Title,
  LabelText,
  LabelColor,
  ChartHeader,
  ChartWrapper,
  LabelWrapper,
  TitleWrapper,
} from './ChartContainer.styles';

interface ChartContainerProps {
  title?: JSX.Element | string;
  footer?: JSX.Element;
  type?: ChartType;
  children?: JSX.Element;
  chartLabels?: IPmAnalyticsQueryOutput[];
}

const ChartContainer: React.FC<ChartContainerProps> = ({
  title,
  footer,
  children,
  chartLabels,
  type = ChartType.TableMetrics,
}) => {
  if (!children) return null;

  const renderTitle = () => {
    if (!title) return null;

    if (typeof title === 'string') {
      return <Title>{title}</Title>;
    }

    return title;
  };

  const renderChildAndFooter = () => {
    return (
      <>
        {children}
        {footer}
      </>
    );
  };

  return (
    <div>
      <TitleWrapper>{renderTitle()}</TitleWrapper>
      {type === ChartType.TableMetrics ? (
        renderChildAndFooter()
      ) : (
        <ChartWrapper>
          {!!chartLabels?.length && (
            <ChartHeader>
              {chartLabels.map((label, i) => (
                <LabelWrapper key={i}>
                  <LabelColor chartType={type} />
                  <Tooltip variant={TooltipVariant.Icon} content={label.tooltip}>
                    <LabelText>{label.label}</LabelText>
                  </Tooltip>
                </LabelWrapper>
              ))}
            </ChartHeader>
          )}
          {renderChildAndFooter()}
        </ChartWrapper>
      )}
    </div>
  );
};

export default ChartContainer;

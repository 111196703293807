import LinkedLogo from '../public/images/icons/linkedin.svg';
import YoutubeLogo from '../public/images/icons/youtube.svg';
import TwitterLogo from '../public/images/icons/twitter.svg';

interface ISocialLink {
  href: string;
  icon?: any;
}

const SOCIAL_LINKS: ISocialLink[] = [
  {
    href: 'https://www.linkedin.com/company/parcel-monitor/?originalSubdomain=sg',
    icon: LinkedLogo,
  },
  {
    href: 'https://www.youtube.com/channel/UCpNd8TRHhOkrRUPzW-bJMtA',
    icon: YoutubeLogo,
  },
  {
    href: 'https://twitter.com/ParcelMonitor',
    icon: TwitterLogo,
  },
];

export default SOCIAL_LINKS;

import { useState, useEffect, useCallback } from 'react';
import { useGlobalState } from '@/hooks';
import { mapEmailSyncAccount } from '@/modules/pmt/mappers';
import { listEmailSyncAccounts } from '@/modules/pmt/fetchers';
import type { IEmailAccount, IEmailSyncAccount } from '@/modules/pmt/interfaces';
import type { ConnectFailedReason } from '@/modules/pmt/components/ESConnectFailedDialog';
import { useTrackSessions } from '@/hooks';

const MAX_DISCONNECTED_EMAILS_SHOWN = 3;

export type TEmailSyncData = IEmailSyncAccount & {
  hasEmailConnected: boolean;
  connectedEmails: IEmailAccount[];
  disconnectedEmails: IEmailAccount[];
};

export function useEmailSyncAccounts() {
  const { userInfo, isLoggedIn } = useGlobalState();
  const [emailSyncData, setEmailSyncData] = useState<TEmailSyncData | undefined>();
  const [syncingAccount, setSyncingAccount] = useState<string | null>(null);
  const [connectEsFailedReason, setConnectEsFailedReason] = useState<ConnectFailedReason | null>(null);
  const { triggerGtmSessions } = useTrackSessions(isLoggedIn);

  const fetchEmailSyncAccounts = useCallback(async () => {
    const userUuid = userInfo?.id;
    if (!userUuid) return;
    try {
      const response = await listEmailSyncAccounts({ userUuid });
      const mappedResponse = mapEmailSyncAccount(response?.data);
      const emails = mappedResponse?.emails;
      if (!emails) return;

      const { connectedEmails, disconnectedEmails } = emails.reduce<{
        connectedEmails: IEmailAccount[];
        disconnectedEmails: IEmailAccount[];
      }>(
        (acc, curr) => {
          if (curr.realtimeSyncActivated) acc.connectedEmails.push(curr);
          else if (acc.disconnectedEmails.length < MAX_DISCONNECTED_EMAILS_SHOWN) {
            acc.disconnectedEmails.push(curr);
          }

          return acc;
        },
        { connectedEmails: [], disconnectedEmails: [] },
      );

      setEmailSyncData({
        ...mappedResponse,
        emails: [...connectedEmails, ...disconnectedEmails],
        connectedEmails,
        disconnectedEmails,
        hasEmailConnected: !!connectedEmails.length,
      });
    } catch (e) {
      console.error('List email sync accounts failed', e);
    }
  }, [userInfo?.id]);

  useEffect(() => {
    fetchEmailSyncAccounts();
  }, [fetchEmailSyncAccounts]);

  useEffect(() => {
    triggerGtmSessions(true, {
      emailSyncOptIn: emailSyncData?.hasEmailConnected ? 'Yes' : 'No',
      numberOfEmailSyncAccounts: emailSyncData?.connectedEmails?.length ?? 0,
    });
  }, [emailSyncData]);

  return {
    connectEsFailedReason,
    setConnectEsFailedReason,
    emailSyncData,
    refetchEmailSyncAccounts: fetchEmailSyncAccounts,
    syncingAccount,
    setSyncingAccount,
  };
}

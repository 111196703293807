import styled from 'styled-components';
import { Button } from '@/components';
import { ButtonSize, ButtonVariant } from '@/interfaces';
import { styledText } from '@/components/Typography/Typography.styles';

export const FormSubmitButton = styled(Button).attrs({
  size: ButtonSize.Large,
  variant: ButtonVariant.Secondary,
})<{ isSubmitting?: boolean }>`
  width: 100%;
  transition: 0.5s;
  margin-top: ${({ theme }) => theme.spacing[16]};

  ${({ isSubmitting, theme }) =>
    isSubmitting &&
    `
    background: ${theme.colors.surface02} !important;
    opacity: 0.8 !important;
  `}
`;

export const FormFieldContainer = styled.div`
  margin-top: 22px;
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-content: flex-start;
  flex-wrap: wrap;
  width: calc(100% + ${({ theme }) => theme.spacing[14]});
  padding-right: ${({ theme }) => theme.spacing[14]};
`;

export const FormWrapper = styled.form`
  position: relative;
  background: ${({ theme }) => theme.colors.highEmphasisWhite};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const PromptBox = styled.div`
  background-color: #edf7fb;
  border-radius: 4px;
  display: flex;

  ${({ theme }) => `
    margin-top: ${theme.spacing[8]};
    padding: ${theme.spacing[16]};
  `}
`;

export const PromptText = styled.p`
  width: calc(100% - 28px);

  ${({ theme }) => `
    margin: 0 0 0 ${theme.spacing[12]};
    font-weight: ${theme.fontWeight.regular};
    ${styledText['Body Text Small']}
  `}
`;

type Config = { accessToken?: string } & RequestInit;
type Body = Record<string, any>;
type CallService = (url: string, config: Config, body?: Body) => Promise<Response>;

const callService: CallService = async (url, { accessToken, ...customConfig }, body) => {
  const headers: HeadersInit = {
    'Content-Type': 'application/json',
  };

  if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`;
  }

  const config: RequestInit = {
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };

  if (body) {
    config.body = JSON.stringify(body);
  }

  return fetch(url, config);
};

type BaseFetcher = (baseUrl: string) => {
  get: (endpoint: string, config?: Config, parameters?: URLSearchParams) => Promise<Response>;
  post: (endpoint: string, body: Body, config?: Config, parameters?: URLSearchParams) => Promise<Response>;
  put: (endpoint: string, body: Body, config?: Config, parameters?: URLSearchParams) => Promise<Response>;
  delete: (endpoint: string, body: Body, config?: Config, parameters?: URLSearchParams) => Promise<Response>;
  patch: (endpoint: string, body?: Body, config?: Config, parameters?: URLSearchParams) => Promise<Response>;
};

export const baseFetcher: BaseFetcher = (baseUrl) => {
  const url = (endpoint: string, parameters?: URLSearchParams) => {
    const queryParams = parameters ? `?${parameters}` : '';
    return `${baseUrl}/${endpoint}/${queryParams}`;
  };

  return {
    get: (endpoint, config, parameters) => callService(url(endpoint, parameters), { method: 'GET', ...config }),
    post: (endpoint, body, config, parameters) =>
      callService(url(endpoint, parameters), { method: 'POST', ...config }, body),
    put: (endpoint, body, config, parameters) =>
      callService(url(endpoint, parameters), { method: 'PUT', ...config }, body),
    delete: (endpoint, body, config, parameters) =>
      callService(url(endpoint, parameters), { method: 'DELETE', ...config }, body),
    patch: (endpoint, body, config, parameters) =>
      callService(url(endpoint, parameters), { method: 'PATCH', ...config }, body),
  };
};

import type React from 'react';
import { useEffect, useState } from 'react';
import Script from 'next/script';
import { TrackingPageWrapper } from './TrackingPage.styles';
import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';

const TrackingPage: React.FC = () => {
  const [remainElementsHeight, setRemainElementsHeight] = useState<number>(0);
  const router = useRouter();

  useEffect(() => {
    const elements = document.querySelectorAll('#layout > *:not(#main)');

    let totalHeight = 0;
    if (elements?.length) {
      elements.forEach((el) => {
        const { height: elHeight } = el.getBoundingClientRect();

        totalHeight += elHeight;
      });
    }
    setRemainElementsHeight(totalHeight);
  }, []);

  useEffect(() => {
    router.beforePopState(({ as }) => {
      window.location.href = as;
      return false;
    });
  }, []);

  return (
    <TrackingPageWrapper remainElementsHeight={remainElementsHeight}>
      <NextSeo
        title='Parcel Monitor'
        defaultTitle='Parcel Monitor'
        additionalLinkTags={[
          {
            rel: 'icon',
            href: '/favicons/favicon-32x32.png',
            type: 'image/png',
            sizes: '32x32',
          },
          {
            rel: 'icon',
            href: '/favicons/favicon-16x16.png',
            type: 'image/png',
            sizes: '16x16',
          },
        ]}
        additionalMetaTags={[
          {
            name: 'msapplication-TileColor',
            content: '#ffc03f',
          },
          {
            property: 'theme-color',
            content: '#ffffff',
          },
        ]}
      />
      <Script src='https://tracking.parcelperform.com/pp-widget.js' strategy='afterInteractive' />
      <div id='pp-widget-root' style={{ height: '600px' }} data-cms-slug='2962c309e7ff'></div>
    </TrackingPageWrapper>
  );
};

export default TrackingPage;

import styled from 'styled-components';
import { LayoutHierarchy } from '@/constants';
import { Typography } from '@/components';
import ArrowDown from '../../../../../public/images/icons/arrow_down.svg';

export const Arrow = styled(ArrowDown)`
  margin-left: 2px;

  path {
    fill: ${({ theme }) => theme.colors.highEmphasisWhite};
  }
`;

export const DropdownContainer = styled.div`
  display: none;
  min-width: 224px; // 2nd NAV
  max-width: 504px; // 2nd NAV + Desc
  position: absolute;
  top: 100%;
  left: 0;
  background-color: ${({ theme }) => theme.colors.highEmphasisWhite};
  border-radius: 0px 0px 4px 4px;
  box-shadow: 0px 4px 8px 0px rgba(96, 97, 112, 0.16), 0px 0px 2px 0px rgba(40, 41, 61, 0.04);
  z-index: ${LayoutHierarchy.Third};
  word-break: break-word;
`;

export const DropdownMenuItemTitle = styled(Typography)`
  transition: .3s;

  :hover {
    color: ${({ theme }) => theme.colors.highEmphasisWhite};
  }
`;

export const DropdownTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: default;
  padding: ${({ theme }) => theme.spacing['12']};
`;

export const DropdownMenuItemWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  color: ${({ theme }) => theme.colors.mediumEmphasisWhite};

  ${Arrow} {
    path {
      fill: ${({ theme }) => theme.colors.mediumEmphasisWhite};
    }
  }

  &:hover {
    ${DropdownContainer} {
      display: flex;
    }

    ${DropdownTitleWrapper} {
      color: ${({ theme }) => theme.colors.highEmphasisWhite};
    }

    ${Arrow} {
      transform: rotate(180deg);
      transition: .3s;
      path {
        fill: ${({ theme }) => theme.colors.highEmphasisWhite};
      }
    }
  }
`;

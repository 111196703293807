import { BlurredTextDataType } from '@/interfaces';
import { BlurredTextContainer, PlaceholderText, PopOverText } from './BlurredText.styles';

type BlurredTextProps = {
  dataType?: BlurredTextDataType;
  popOverText?: string;
  href?: string;
};

/**
 * Blurred text component that displays a popOverText on hover
 * @param dataType BlurredTextDataType - type of UI for blurred text, default is 'text'
 * @param popOverText text to display on hover
 * @example
 * <BlurredText />
 */
const BlurredText: React.FC<BlurredTextProps> = ({
  dataType = BlurredTextDataType.text,
  popOverText = 'FIND OUT',
  href,
}) => {
  const renderPlaceholderText = () => {
    switch (dataType) {
      case BlurredTextDataType.shortText:
        return 'TEXT';
      case BlurredTextDataType.text:
        return 'HIDDENTEXT';
      case BlurredTextDataType.longText:
        return 'HIDDENLONGLONGLONGLONGLONGLONGTEXT';
      case BlurredTextDataType.number:
        return '20.2606';
      default:
        return 'HIDDENTEXT';
    }
  };

  if (!href) {
    return <PlaceholderText>{renderPlaceholderText()}</PlaceholderText>;
  }

  return (
    <BlurredTextContainer>
      <PlaceholderText>{renderPlaceholderText()}</PlaceholderText>
      <PopOverText href={href}>{popOverText}</PopOverText>
    </BlurredTextContainer>
  );
};

export default BlurredText;

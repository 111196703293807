import { Container } from '@/components';
import { WidthType, WrapperBackgroundColor } from '@/interfaces';
import styled from 'styled-components';

export const SEOSectionContainer = styled.div<{ bgColor: WrapperBackgroundColor }>`
  /* background: ${({ theme }) => theme.colors.surface02}; */
  background: ${({ theme, bgColor }) =>
    bgColor === WrapperBackgroundColor.white ? theme.colors.highEmphasisWhite : theme.colors.pageBackground};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    :not(:last-child) {
      margin-bottom: ${({ theme }) => theme.spacing[24]};
    }
  }
`;

export const SectionContainer = styled(Container)`
  padding: ${({ theme }) => theme.spacing[20]};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    padding-top: ${({ theme }) => theme.spacing[40]};
    padding-bottom: ${({ theme }) => theme.spacing[40]};
  }
`;

export const ListContainer = styled.div<{ width?: WidthType }>`
  margin-top: ${({ theme }) => theme.spacing[28]};
  display: grid;
  grid-template-columns: 1fr;
  gap: ${({ theme }) => `${theme.spacing[24]} ${theme.spacing[32]}`};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    ${({ width }) =>
      width !== WidthType.Full &&
      `
      grid-template-columns: repeat(2, 1fr);
    `}
  }
`;

export const LongFormSectionWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing[28]};
`;

import styled from 'styled-components';
import { Button, Typography } from '@/components';
import { TextStyles } from '@/constants';
import { ButtonVariant } from '@/interfaces';

export const UnsubscribeSingleWrapper = styled.div`
  padding: 0 31px;
  position: relative;
  min-height: 587px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
  background: ${({ theme }) => theme.colors.pageBackground};

  ${({ theme }) => theme.mediaBreakpointUp.lg} {
    min-height: 640px;
  }
`;

export const PageTitle = styled(Typography).attrs({
  variant: TextStyles['Heading 1'],
})`
  color: ${({ theme }) => theme.colors.highEmphasisBlack};
  word-break: break-word;
  white-space: pre-line;
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing[20]};

  ${({ theme }) => theme.mediaBreakpointUp.lg} {
    margin-bottom: ${({ theme }) => theme.spacing[24]};
    width: 720px;
  }

  span {
    color: ${({ theme }) => theme.colors.darkOrange};
  }
`;

export const ViewMyParcelsButton = styled(Button).attrs({
  variant: ButtonVariant.Link,
})`
  word-break: break-word;
  text-align: center;
`;

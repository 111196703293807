import { useGlobalState } from '@/hooks';
import { shipmentSummaryGtmParams } from '@/modules/pmt/constants';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

export const useRequestLoginDialog = () => {
  const router = useRouter();
  const { userInfo } = useGlobalState();
  const [showRequestLoginDialog, setShowRequestLoginDialog] = useState<boolean>(false);
  const [isFromRequestLoginDialog, setIsFromRequestLoginDialog] = useState<boolean>(false);

  useEffect(() => {
    const { query, pathname } = router;
    if (query['utm_source'] === 'shipment-summary' && !userInfo) {
      setShowRequestLoginDialog(true);
      // clear params
      shipmentSummaryGtmParams.forEach((param) => delete query[`${param}`]);
      void router.push({ query, pathname }, undefined, { shallow: true });
    }
  }, [router, router.push, userInfo]);

  return { showRequestLoginDialog, setShowRequestLoginDialog, isFromRequestLoginDialog, setIsFromRequestLoginDialog };
};

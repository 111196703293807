/**
 * Check if the value is a number
 * @param value string
 * @returns boolean
 * @example
 * checkIsNumber('123') // true
 * checkIsNumber('abc') // false
 * checkIsNumber('360 Lion Express') // false
 * checkIsNumber('360.9999') // true
 * checkIsNumber('1.001') // true
 * checkIsNumber('1,000') // true
 * checkIsNumber('1,000.0121') // true
 */
export const checkIsNumber = (value: string | null) => {
  if (!value) return false;
  const regex = /^-?\d+\.?\d*$/;
  return regex.test(value.replace(/,/g, '')) && !isNaN(Number.parseFloat(value));
};

const colors = {
  gray01: '#DFE3E8',
  gray300: '#D9DBE1',
  blue: '#275CDE',
  blueHover: '#1D4CBE',
  yellow: '#FFC03F',
  yellowHover: '#ECAA02',
  orange: '#FFA300',
  darkOrange: '#EA580C',
  darkOrangeHover: '#CA4A07',
  darkBlue: '#001B3A',
  regal: '#01456C',
  midnight: '#0E2F56',
  hyperlink: '#1B70EF',
  hyperlinkVisited: '#6507BA', //no name yet
  surface01: '#F7F9FF',
  surface02: '#F2F4F8',
  surface03: '#EBEFF2',
  surface04: '#DCE3E9',
  surface05: '#E5E6E8',
  pastelBlue: '#ADBBD4',
  silverBlue: '#5C83C4',
  cyanBlue: '#2C599D',
  indigoBlue: '#1A3B70',
  lightSilver: '#D6D6D6',
  highEmphasisBlack: 'rgba(0, 0, 0, 0.87)',
  mediumEmphasisBlack: 'rgba(0, 0, 0, 0.6)',
  disabledBlack: 'rgba(0, 0, 0, 0.38)',
  focusedBlack: 'rgba(0, 0, 0, 0.12)',
  inactiveBlack: 'rgba(0, 0, 0, 0.54)',
  pressedBlack: 'rgba(0, 0, 0, 0.16)',
  hoverBlack: 'rgba(0, 0, 0, 0.08)',
  overlay: 'rgba(0, 0, 0, 0.25)',
  darkGray: '#333333',
  darkCharcoal: '#323232',
  highEmphasisWhite: '#FFFFFF',
  mediumEmphasisWhite: 'rgba(255, 255, 255, 0.7)',
  disabledWhite: 'rgba(255, 255, 255, 0.5)',
  focusedWhite: 'rgba(255, 255, 255, 0.12)',
  inactiveWhite: 'rgba(255, 255, 255, 0.54)',
  pressedWhite: 'rgba(255, 255, 255, 0.32)',
  hoverWhite: 'rgba(255, 255, 255, 0.08)',
  pageBackground: '#F9F9F9',
  adBackground: '#F2F2F2',
  error: '#FB2424',
  darkError: '#E20A0A',
  scrollBarThumb: '#C4C4C4',
  backgroundOpacity: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 21.35%)',
  backgroundOpacity02: 'linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.9) 21.35%)',
  backgroundOpacity03: 'linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.90) 96.05%)',
  boxShadow: '0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16)',
};

export default colors;

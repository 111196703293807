import type { ThumbnailProps } from '@/interfaces';

export enum HighlightDisplayLogic {
  Featured = 'Featured',
  Latest = 'Latest',
}

export interface IHighlightSection {
  title: string; //{ [LanguageLocale]: string }
  displayingLogic: HighlightDisplayLogic;
  tags?: any; // TODO: update this interface since error if use 'ITag[] | { items: ITag[] }'
  thumbnails?: ThumbnailProps[];
}

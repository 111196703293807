import { useRef, useState, type ChangeEvent, type ReactElement } from 'react';
import { useClickOutsideMultiElement } from '@/hooks';
import type { IOption } from '@/interfaces';
import { Input, Dropdown, InputWrapper, SelectWrapper, NoResultsFound, ArrowIcon, Option } from './Select.styles';

const NO_RESULTS_FOUND = 'No Results Found';

interface ISelectProps {
  options: IOption[];
  value: string;
  onValueChanged?: (value: string) => void;
  onSelect: (arg: IOption) => void;
  inputPrepend?: ReactElement;
  onMenuOpened?: () => void;
  onMenuClosed?: () => void;
}

const Select: React.FC<ISelectProps> = ({
  options,
  value,
  onValueChanged,
  onSelect,
  inputPrepend,
  onMenuOpened,
  onMenuClosed,
}) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState('');

  const inputWrapperRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useClickOutsideMultiElement(
    [inputWrapperRef, dropdownRef],
    () => {
      setOpen(false);
      setInputValue('');

      if (onValueChanged) {
        onValueChanged('');
      }

      if (onMenuClosed) {
        onMenuClosed();
      }
    },
    [onValueChanged, onMenuClosed],
  );

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);

    if (onValueChanged) {
      onValueChanged(event.target.value);
    }
  };

  const handleSelect = (option: IOption) => () => {
    onSelect && onSelect(option);
    setInputValue('');
    setOpen(false);

    if (onValueChanged) {
      onValueChanged('');
    }

    if (onMenuClosed) {
      onMenuClosed();
    }
  };

  const hanndleInputWrapperClicked = () => {
    if (!inputRef?.current) return;
    inputRef.current.focus();
  };

  const handleInputFocused = () => {
    setOpen(true);

    if (onMenuOpened) {
      onMenuOpened();
    }
  };

  const renderOptions = () => {
    if (options.length === 0) {
      return <NoResultsFound>{NO_RESULTS_FOUND}</NoResultsFound>;
    }

    return options.map((option, index) => (
      <Option key={index} onClick={handleSelect(option)} $selected={option.key === value}>
        {option.displayValue || option.key}
      </Option>
    ));
  };

  return (
    <SelectWrapper>
      <InputWrapper ref={inputWrapperRef} onClick={hanndleInputWrapperClicked}>
        {inputPrepend}
        <Input
          value={inputValue}
          ref={inputRef}
          onChange={handleInputChange}
          onFocus={handleInputFocused}
          placeholder={inputValue.length === 0 ? value : ''}
        />
        <ArrowIcon show={isOpen} />
      </InputWrapper>
      {isOpen && <Dropdown ref={dropdownRef}>{renderOptions()}</Dropdown>}
    </SelectWrapper>
  );
};

export default Select;

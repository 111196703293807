import styled from 'styled-components';

const getHeight = (height: string | number) => {
  if (typeof height === 'number') {
    return `${height}px`;
  }

  return height;
};

export const VideoContainer = styled.div<{
  customHeight?: Record<string, string | number>;
}>`
  position: relative;
  display: flex;
  justify-content: center;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  flex: 1;
  margin: 0 !important;

  video {
    width: 100%;
    height: ${({ customHeight }) => getHeight(customHeight?.mobile || '100%')};
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    video {
      height: ${({ customHeight }) => getHeight(customHeight?.desktop || '100%')};
    }
  }
`;

import dynamic from 'next/dynamic';
import { Fragment } from 'react';
import { AdsWrapper, RightSectionWrapper } from './RightSection.styles';
import { RightSectionListComponentMapping } from '@/constants';
import {
  type IEvent,
  PMCAdType,
  type IRightPanel,
  RightPanelType,
  LeadGenBannerVariant,
  ResponsiveCollection,
} from '@/interfaces';

import { useResponsive } from '@/hooks';

const EventDetails = dynamic(() => import('@/components/EventDetails'));

interface IRightSectionProps {
  eventDetails?: IEvent;
  haveTrackingBanner?: boolean;
  rightSectionList: Array<IRightPanel>;
}

const RightSection: React.FC<IRightSectionProps> = ({ eventDetails, haveTrackingBanner = false, rightSectionList }) => {
  const { isDesktop } = useResponsive([ResponsiveCollection.Desktop]);

  if (!isDesktop) return null;

  const renderRightPanel = (rightPanel: IRightPanel) => {
    const { title, button, list, type } = rightPanel;

    if (!type) return null;

    if (type === RightPanelType.Ad) {
      return <AdsWrapper variant={PMCAdType.RIGHT_SECTION} className='ads-right-section' />;
    }

    const Component = RightSectionListComponentMapping[type];
    if (!Component) return null;

    switch (type) {
      case RightPanelType.TrackingWidget:
        return !haveTrackingBanner && <Component />;
      case RightPanelType.EventHighlights:
        return <Component eventList={list} title={title} button={button} />;
      case RightPanelType.LeadGenBanner:
        return list && list.length && <Component {...list[0]} variant={LeadGenBannerVariant.SMALL} />;
      case RightPanelType.FeaturedCarrierSection:
        return <Component carrierList={list} title={title} button={button} />;
      case RightPanelType.ReportSection:
        return list && list.length && <Component data={list[0]} title={title} button={button} />;
      case RightPanelType.DataInsightsSection:
        return <Component {...rightPanel} />;
      default:
        return null;
    }
  };

  return (
    <RightSectionWrapper>
      {eventDetails && <EventDetails {...eventDetails} />}
      {rightSectionList?.map((rightPanel: IRightPanel, index) => (
        <Fragment key={index}>{renderRightPanel(rightPanel)}</Fragment>
      ))}
    </RightSectionWrapper>
  );
};

export default RightSection;

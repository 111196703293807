import { useForm, type SubmitHandler } from 'react-hook-form';
import { useState } from 'react';
import type { FormOnSubmit } from '@/interfaces';
import { postSubmitForm } from '@/fetchers';
import { useReCaptcha } from '@/hooks';

interface BaseFormProps {
  onSubmit?: FormOnSubmit;
  onSuccess?: () => void;
}

const useBaseForm = ({ onSubmit, onSuccess }: BaseFormProps) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const useFormReturn = useForm({ mode: 'all' });

  const { handleReCaptchaVerify } = useReCaptcha();

  const handlePostFormSubmit = async () => {
    try {
      const captchaCode = await handleReCaptchaVerify();

      if (!captchaCode) {
        throw 'reCAPTCHA verify failed';
      }

      const captchaValidation = await postSubmitForm(captchaCode);

      if (captchaValidation?.status === 200) {
        return true;
      } else {
        throw 'Unprocessable request: Invalid captcha code';
      }
    } catch (error: any) {
      console.error(error);
      return false;
    }
  };

  const handleFormSubmit: SubmitHandler<any> = async (formValue, e) => {
    e?.preventDefault();

    try {
      setIsSubmitting(true);
      const isUserSubmitFormValid = await handlePostFormSubmit();

      if (!isUserSubmitFormValid) {
        setIsSubmitting(false);
        return;
      }

      onSubmit &&
        onSubmit(
          formValue,
          (isFinish = true) => {
            if (onSuccess) {
              onSuccess();
            }

            setIsSubmitted(isFinish);
            setIsSubmitting(false);
          },
          useFormReturn,
        );
    } catch {
      setIsSubmitting(false);
    }
  };

  return {
    handleFormSubmit: useFormReturn.handleSubmit(handleFormSubmit),
    isSubmitting,
    isSubmitted,
    ...useFormReturn,
  };
};

export default useBaseForm;

import { Typography } from '@/components';
import { BreadcrumbsSectionContainer } from '@/components/Breadcrumbs/Breadcrumbs.styles';
import { TextStyles } from '@/constants';
import { SectionThemeKey } from '@/interfaces';
import styled from 'styled-components';
import { MobileSubtext, MobileTitle, clampText } from '../WidgetTextSection/WidgetTextSection.styles';

export const TradeRouteWidgetWrapper = styled.div`
  position: relative;
  margin: auto;
  width: 100%;
  max-width: 1160px;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    padding-left: ${({ theme }) => theme.spacing[20]};
    padding-right: ${({ theme }) => theme.spacing[20]};
  }
`;

export const TradeRouteTitle = styled(Typography).attrs({
  variant: TextStyles['Heading 2'],
})`
  margin-bottom: ${({ theme }) => theme.spacing[12]};
  -webkit-line-clamp: 2;
  ${clampText}

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: none;
  }
`;

export const TradeRouteDesktopTitle = styled(TradeRouteTitle)`
  display: none;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: -webkit-box;
  }
`;

export const TradeRouteWidgetContainer = styled.div<{
  sectionTheme: SectionThemeKey;
}>`
  position: relative;
  padding: ${({ theme }) => `${theme.spacing[20]} ${theme.spacing[20]} ${theme.spacing[40]} ${theme.spacing[20]}`};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    padding: ${({ theme }) => `${theme.spacing[40]} ${theme.spacing[20]} ${theme.spacing[90]} ${theme.spacing[20]} `};

    ${BreadcrumbsSectionContainer} {
      margin-bottom: ${({ theme }) => theme.spacing[30]};
    }
  }

  background-color: ${({ sectionTheme, theme }) => {
    return sectionTheme === SectionThemeKey.Light ? theme.colors.surface02 : theme.colors.darkBlue;
  }};

  ${MobileTitle} {
    color: ${({ sectionTheme, theme }) => {
      return sectionTheme === SectionThemeKey.Light ? theme.colors.midnight : theme.colors.highEmphasisWhite;
    }};
  }

  ${MobileSubtext} {
    color: ${({ sectionTheme, theme }) => {
      return sectionTheme === SectionThemeKey.Light ? theme.colors.mediumEmphasisBlack : theme.colors.highEmphasisWhite;
    }};
  }
`;

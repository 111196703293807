import Image from 'next/image';
import dynamic from 'next/dynamic';

import { Button, Container } from '..';
import {
  DesktopLeadGenSubCopy,
  DesktopLeadGenTitle,
  LeadGenBannerWrapper,
  LeadGenButtonWrapper,
  LeadGenContent,
  LeadGenSubCopy,
  LeadGenTitle,
} from './LeadGenBanner.styles';
import {
  ButtonFunctionKey,
  ButtonSize,
  type ILeadGenBanner,
  LeadGenBannerVariant,
  SectionThemeKey,
  ResponsiveCollection,
} from '@/interfaces';
import { useGlobalState, useHighlightString, useResponsive } from '@/hooks';
import { theme } from '@/constants';
import { gtmClickLeadsElements, gtmClickRegistrationBanner } from '@/utils';

const LinkedInFollowBtn = dynamic(() => import('./LinkedInFollowBtn'), {
  ssr: false,
});

const LeadGenBanner: React.FC<ILeadGenBanner & { className?: string; variant?: LeadGenBannerVariant }> = ({
  title: titleDesktop,
  titleMobile,
  image: imageDesktop,
  imageMobile,
  subCopy: subCopyDesktop,
  subCopyMobile,
  buttons,
  sectionTheme,
  className,
  isLinkedInBanner = false,
  variant = LeadGenBannerVariant.DEFAULT,
}) => {
  const highlightCss = {
    color: theme.colors.darkOrange,
  };

  const { setIsFromJoinUsBtn, isLoggedIn, getProperImageUrl } = useGlobalState();
  const isPopUpButton = buttons?.some((button) => button.function === ButtonFunctionKey.PopUp);
  const isHideLoggedInUser = isPopUpButton && isLoggedIn;
  const { isMobile } = useResponsive([ResponsiveCollection.Mobile]);

  if (isHideLoggedInUser) return null;

  const handleLeadButtonClick = (isPopupButton: boolean, buttonEventLabel?: string) => {
    if (isPopupButton) {
      setIsFromJoinUsBtn(true);
      gtmClickRegistrationBanner();
    }

    buttonEventLabel && gtmClickLeadsElements(buttonEventLabel);
  };

  let image;

  if (isMobile) {
    image = imageMobile;
  } else {
    image = imageDesktop;
  }

  const buttonSize = {
    [LeadGenBannerVariant.DEFAULT]: {
      desktop: ButtonSize.Large,
      mobile: ButtonSize.Medium,
    },
    [LeadGenBannerVariant.SMALL]: {
      desktop: ButtonSize.Medium,
      mobile: ButtonSize.Medium,
    },
    [LeadGenBannerVariant.FULL_WIDTH]: {
      desktop: ButtonSize.Large,
      mobile: ButtonSize.Large,
    },
  };

  const renderTextSection = () => {
    if (isMobile) {
      return (
        <>
          <LeadGenTitle sectionTheme={sectionTheme || SectionThemeKey.Light}>
            {useHighlightString(titleMobile, highlightCss)}
          </LeadGenTitle>
          <LeadGenSubCopy hasButtons={!!buttons?.length} sectionTheme={sectionTheme || SectionThemeKey.Light}>
            {useHighlightString(subCopyMobile, highlightCss)}
          </LeadGenSubCopy>
        </>
      );
    }

    return (
      <>
        <DesktopLeadGenTitle variant={variant} sectionTheme={sectionTheme || SectionThemeKey.Light}>
          {useHighlightString(titleDesktop, highlightCss)}
        </DesktopLeadGenTitle>
        <DesktopLeadGenSubCopy
          variant={variant}
          hasButtons={!!buttons?.length}
          sectionTheme={sectionTheme || SectionThemeKey.Light}
        >
          {useHighlightString(subCopyDesktop, highlightCss)}
        </DesktopLeadGenSubCopy>
      </>
    );
  };

  const renderButtons = () => {
    if (isLinkedInBanner) return <LinkedInFollowBtn />;
    if (!buttons?.length) return;
    return (
      <LeadGenButtonWrapper>
        {buttons.map((button, index) => {
          if (!button) {
            return null;
          }

          const isPopupButton = button.function === ButtonFunctionKey.PopUp;

          return (
            <Button
              {...button}
              key={index}
              size={buttonSize[variant].desktop}
              mobileSize={buttonSize[variant].mobile}
              onClick={() => handleLeadButtonClick(isPopupButton, button.buttonEventLabel)}
            />
          );
        })}
      </LeadGenButtonWrapper>
    );
  };

  const renderContent = () => {
    const content = (
      <LeadGenContent>
        {renderTextSection()}
        {renderButtons()}
      </LeadGenContent>
    );
    if (variant === LeadGenBannerVariant.FULL_WIDTH) {
      return <Container>{content}</Container>;
    }
    return content;
  };

  return (
    <LeadGenBannerWrapper isLinkedIn={isLinkedInBanner} className={className} variant={variant}>
      {image && (
        <Image
          src={getProperImageUrl(image)}
          alt={image.description || 'Lead Gen Banner Image'}
          fill
          sizes='100vw'
          style={{
            objectPosition: !isLinkedInBanner ? 'top' : 'right',
            objectFit: !isLinkedInBanner ? 'cover' : 'contain',
          }}
        />
      )}
      {renderContent()}
    </LeadGenBannerWrapper>
  );
};

export default LeadGenBanner;

import { ButtonFunctionKey } from '@/interfaces';
import type { ReactElement } from 'react';
import ArrowForward from '../public/images/icons/arrow_forward.svg';
import Download from '../public/images/icons/download.svg';

export const ButtonIconByFunction: Record<ButtonFunctionKey, ReactElement | null> = {
  [ButtonFunctionKey.Download]: Download as ReactElement,
  [ButtonFunctionKey.PopUp]: ArrowForward as ReactElement,
  [ButtonFunctionKey.Link]: null,
  [ButtonFunctionKey.Email]: null,
  // Defined in GoogleBrandedButton
  [ButtonFunctionKey.GoogleBranded]: null,
};

import { Typography } from '@/components';
import { TextStyles } from '@/constants';
import styled from 'styled-components';

const ErrorMessage = styled(Typography).attrs({
  variant: TextStyles['Caption Text'],
})`
  margin-bottom: 2px;
  margin-top: 3px;
  padding-left: 14px;
  min-height: 20px;
  color: ${({ theme }) => theme.colors.error};
  transition: 0.5s;
`;

export default ErrorMessage;

import type { IShipmentLineItem } from '../interfaces';

interface IRawLineItem {
  currency_code?: string;
  product_name?: string;
  product_url?: string;
  product_image_url?: string;
  quantity?: string;
  product_cost?: string;
  total_price?: string;
  product_description?: string;
}

export const mapLineItem = (lineItem?: IRawLineItem): IShipmentLineItem | undefined => {
  if (!lineItem) return undefined;
  return {
    currencyCode: lineItem.currency_code,
    productName: lineItem.product_name,
    productUrl: lineItem.product_url,
    productImageUrl: lineItem.product_image_url,
    quantity: lineItem.quantity,
    productCost: lineItem.product_cost,
    totalPrice: lineItem.total_price,
    productDescription: lineItem.product_description,
  };
};

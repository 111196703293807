import { Typography, Button } from '@/components';
import { ButtonVariant } from '@/interfaces';

/**
 * @param variant constants/TextStyles
 * @param anchor_attrs anchor tag's attributes, e.g. target, href
 * @returns {React.FC} Returns correspending stylings and anchor tag with inner text-tag type
 */
const TypographyLink: React.FC<any> = (props) => {
  const anchorProps = { ...props };
  delete anchorProps.variant;

  return (
    <Button {...anchorProps} variant={ButtonVariant.Hyperlink} openInNewTab={anchorProps.target}>
      <Typography variant={props.variant}>{props.children}</Typography>
    </Button>
  );
};

export default TypographyLink;

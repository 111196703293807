import { UserSessionType } from '@/interfaces';
import ROUTES, { RouteName } from './routes.constants';

export enum TrackerState {
  disabled = 'disabled',
  active = 'active',
  checked = 'checked',
}

export enum DelayTime {
  '10s' = 10,
  '20s' = 20,
  '30s' = 30,
  '2mins' = 120,
}

export const DEFAULT_POPUP_TIME_TRIGGERS = {
  [UserSessionType.New]: [DelayTime['10s'], DelayTime['30s'], DelayTime['2mins']],
  [UserSessionType.Return]: [DelayTime['20s'], DelayTime['2mins']],
};

export enum PopupType {
  actionBased = 'Action Based',
  timeBased = 'Time Based',
  reportsPopUp = 'Reports pop up',
}

export const NON_POPUP_PATHS: string[] = [ROUTES[RouteName.SignInWithLinkedIn].path, ROUTES[RouteName.Verify].path];

export const ON_PAGE_DURATION_BY_SECOND = 90 * 60; // 1min = 60s

export enum SignUpSource {
  PMC = 'PMC',
  PMT = 'PMT',
}

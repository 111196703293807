export const pmtQueryParams = {
  // https://www.pivotaltracker.com/n/projects/2649247/stories/187873450
  IsFromEmsLandingPage: 'isFromEMSLandingPage',
  EmsLandingPageIdentifier: 'emsLandingPageIdentifier',
  // TODO use these
  // shipment-summary
  ShipmentFsId: 'shipmentFsId',
  Section: 'section',
  Milestone: 'milestone',
  ShowEdd: 'showEdd',
  // Google Oauth
  Code: 'code',
  Scope: 'scope',
  AuthUser: 'authuser',
  Prompt: 'prompt',
  // UTM
  UtmSource: 'utm_source',
  UtmCampaign: 'utm_campaign',
  UtmMedium: 'utm_medium',
};

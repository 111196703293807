import { useState, useEffect } from 'react';
import { ResponsiveCollection } from '@/interfaces';

type WindowSize = {
  width: number;
  height: number;
};

type Output = {
  [key in ResponsiveCollection]: boolean;
};

const getOutput = ({ width }: WindowSize): Output => ({
  [ResponsiveCollection.CarouselMaxSize]: width > 460,
  [ResponsiveCollection.DesktopPMT]: width > 799,
  [ResponsiveCollection.Tablet]: width < 1160,
  [ResponsiveCollection.Mobile]: width < 600,
  [ResponsiveCollection.Desktop]: width > 599,
});

const DEFAULT_COLLECTION = Object.values(ResponsiveCollection);

/**
 * Responsive hook
 * @param dependencies - array of ResponsiveCollection - defaults to all - this function will be triggered by the items placed here, determining whether or not to update - if you don't pass anything, it will update on all changes belonging to ResponsiveCollection
 */
const useResponsive = (dependencies: ResponsiveCollection[] = DEFAULT_COLLECTION) => {
  const [output, setOutput] = useState<Output>(
    getOutput({ width: 598, height: 1280 }), // keep this in sync with useWindowSize.hook.ts
  );

  useEffect(() => {
    let prevOutput = <Output>{};

    const handler = () => {
      const windowSize = {
        width: window.innerWidth,
        height: window.innerHeight,
      };

      const result = dependencies.reduce(
        (acc, key) => {
          return { ...acc, [key]: getOutput(windowSize)[key] };
        },
        <Output>{},
      );

      const shouldUpdate = Object.entries(result).some(([k, v]) => {
        const key = k as keyof Output;
        return prevOutput && v !== prevOutput[key];
      });

      if (!shouldUpdate) return;

      prevOutput = result;
      setOutput(result);
    };

    // // Have not resized yet
    handler();

    window.addEventListener('resize', handler);
    return () => {
      window.removeEventListener('resize', handler);
    };
  }, []);

  return output;
};

export default useResponsive;

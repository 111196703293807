import { PageOverviewBanner } from '@/components';

import { ContentTypeName } from '@/constants';
import { capitalize } from '@/utils';

import { LeadGenBannerVariant } from '@/interfaces';
import SectionListRenderer from '../SectionListRenderer';

interface IHomepageRendererProps {
  sectionList?: any[];
}

const HomepageRenderer: React.FC<IHomepageRendererProps> = ({ sectionList = [] }) => {
  const foundPageOverviewBanner = sectionList.find(
    (section: any) => capitalize(section.__typename) === ContentTypeName.PageOverviewBanner,
  );

  const filteredSectionList = sectionList.reduce((filtered, section: any) => {
    if ([foundPageOverviewBanner].includes(section)) {
      return filtered;
    }
    if (capitalize(section.__typename) === ContentTypeName.LeadGenBanner) {
      section.variant = LeadGenBannerVariant.FULL_WIDTH;
    }
    return [...filtered, section];
  }, []);

  return (
    <>
      {foundPageOverviewBanner && <PageOverviewBanner priority {...foundPageOverviewBanner} />}
      <SectionListRenderer sectionList={filteredSectionList} hideSpacingBetweenSections />
    </>
  );
};

export default HomepageRenderer;

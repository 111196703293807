import styled from 'styled-components';
import { TextStyles, theme } from '@/constants';
import { SectionThemeKey } from '@/interfaces';
import Typography from '@/components/Typography';

export const Title = styled(Typography).attrs({
  variant: TextStyles['Overline'],
})(({ theme }) => ({
  marginBottom: theme.spacing[8],
  textAlign: 'left',

  [`${theme.mediaBreakpointUp.md}`]: {
    maxWidth: 300,
    textAlign: 'center',
    marginBottom: theme.spacing[16],
  },
}));

export const LogoWrapper = styled.div(({ theme }) => ({
  display: 'block',
  position: 'relative',
  width: 70,
  height: 35,
  filter: 'grayscale(1)',

  [`${theme.mediaBreakpointUp.md}`]: {
    width: 84,
    height: 42,
  },
}));

export const List = styled.div(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  gap: `${theme.spacing[8]} ${theme.spacing[16]}`,

  [`${theme.mediaBreakpointUp.md}`]: {
    gap: theme.spacing[24],
    justifyContent: 'center',
  },
}));

// `theme` is from @/constants, not from ThemeProvider
const sectionThemeToStyleMapperObj = {
  [SectionThemeKey.Dark]: {
    [`${Title}`]: {
      color: theme.colors.mediumEmphasisWhite,
    },
  },
  [SectionThemeKey.Light]: {
    [`${Title}`]: {
      color: theme.colors.disabledBlack,
    },
  },
};

export const Container = styled.div<{
  sectionTheme?: SectionThemeKey;
}>(({ theme, sectionTheme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',

  ...sectionThemeToStyleMapperObj[sectionTheme || SectionThemeKey.Light],

  [`${theme.mediaBreakpointUp.md}`]: {
    alignItems: 'center',
  },
}));

import { useEffect, useState } from 'react';
import { GoogleLogin, LinkedInLogin, Form } from '@/components';
import { ButtonListWrapper, DividedLine, DividedText, JoinUsWrapper } from './JoinUs.styles';

import { type IPanel, LoginMethod } from '@/interfaces';

import { EmailField, CheckboxField, SignUpSource } from '@/constants';

const JoinUs: React.FC<IPanel> = ({ onStepSuccess, onStepClick, isFullScreen }) => {
  const [currentLoginMethod, setCurrentLoginMethod] = useState<LoginMethod | null>(null);

  const linkedInLoginButtonProps = {
    shouldReloadAfterSuccess: false,
    customHandleSuccess: () => {
      onStepSuccess && onStepSuccess({ authenticationMethod: LoginMethod.LinkedIn });
    },
    customHandleClick: () => {
      onStepClick && onStepClick({ authenticationMethod: LoginMethod.LinkedIn });
      setCurrentLoginMethod(LoginMethod.LinkedIn);
    },
  };

  const googleLoginButtonProps = {
    shouldReloadAfterSuccess: false,
    customHandleSuccess: () => {
      onStepSuccess && onStepSuccess({ authenticationMethod: LoginMethod.Google });
    },
    customHandleClick: () => {
      onStepClick && onStepClick({ authenticationMethod: LoginMethod.Google });
      setCurrentLoginMethod(LoginMethod.Google);
    },
  };

  const onEmailButtonClicksSuccess = () => {
    if (!onStepClick || !onStepSuccess) {
      return;
    }

    onStepClick({ authenticationMethod: LoginMethod.Email });
    onStepSuccess();
  };

  useEffect(() => {
    return () => setCurrentLoginMethod(null);
  }, []);

  const DividedBar = () => (
    <DividedLine>
      <DividedText>OR</DividedText>
    </DividedLine>
  );

  const CustomEmailField = {
    ...EmailField,
    onClick: () => setCurrentLoginMethod(LoginMethod.Email),
  };

  const CustomCheckboxField = {
    ...CheckboxField,
    appear: currentLoginMethod === LoginMethod.Email,
  };

  return (
    <JoinUsWrapper isFullScreen={isFullScreen}>
      <ButtonListWrapper>
        <LinkedInLogin {...linkedInLoginButtonProps} />
        <br />
        <GoogleLogin {...googleLoginButtonProps} />
      </ButtonListWrapper>
      <DividedBar />
      <Form
        formFieldList={[CustomEmailField, CustomCheckboxField]}
        buttonText='Continue'
        isEmailForm={true}
        onSuccess={onEmailButtonClicksSuccess}
        signUpSource={SignUpSource.PMC}
      />
    </JoinUsWrapper>
  );
};

export default JoinUs;

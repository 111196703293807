import { RichText, SectionTitle } from '@/components';
import type { ISEOSection, ISingleItem } from '@/interfaces';
import SEOItem from './SEOItem';
import { ListContainer, LongFormSectionWrapper, SEOSectionContainer, SectionContainer } from './SEOSection.styles';

const SEOSection: React.FC<ISEOSection> = ({ title, seoList, width, longForm, backgroundColor }) => {
  return (
    <SEOSectionContainer bgColor={backgroundColor}>
      <SectionContainer>
        <SectionTitle>{title}</SectionTitle>

        {seoList && seoList.length > 0 && (
          <ListContainer width={width}>
            {seoList.map((seoItem: ISingleItem, index: number) => (
              // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
              <SEOItem key={index} {...seoItem} />
            ))}
          </ListContainer>
        )}

        {longForm && (
          <LongFormSectionWrapper>
            <RichText richText={longForm} />
          </LongFormSectionWrapper>
        )}
      </SectionContainer>
    </SEOSectionContainer>
  );
};

export default SEOSection;

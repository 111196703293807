import { DEFAULT_LANGUAGE } from '@/constants';
import { type IFooter, LanguageCode } from '@/interfaces';
import { languageFieldsMapping, translationDataModify } from '@/utils';
import callContentful from './base.fetchers';

type GetFooter = (args: {
  isPreviewMode: boolean;
  languageCode?: LanguageCode;
  isMyParcelsPage: boolean;
  slug: string;
}) => Promise<IFooter>;

export const getFooter: GetFooter = async ({
  isPreviewMode,
  languageCode = LanguageCode.English,
  isMyParcelsPage = false,
  slug,
}) => {
  const mappedLanguageFields = languageFieldsMapping(languageCode);
  const query = `
    {
      defaultFooterCollection: footerCollection(
        preview: ${isPreviewMode}
        locale: "${DEFAULT_LANGUAGE}"
        where: { forPmtPage: ${isMyParcelsPage} }
        limit: 1
      ) {
        items {
          sys {
            id
          }
          subText: subText1 
          itemList: itemList1
          pageLinks: pageLinks1
          copyright: copyright1
          ppText: ppText1
        }
      }
      footerCollection(
        preview: ${isPreviewMode}
        locale: "${mappedLanguageFields.locale}"
        where: { forPmtPage: ${isMyParcelsPage} }
        limit: 1
      ) {
        items {
          ${['subText', 'itemList', 'pageLinks', 'copyright', 'ppText'].map((field) =>
            mappedLanguageFields.getField(field),
          )}
        }
      }
    }
  `;

  const response = await callContentful(query, isPreviewMode, slug, 'footer.fetchers.getFooter');

  const defaultFooterData = response?.data?.defaultFooterCollection?.items?.filter(Boolean)[0] || null;
  const footerData = response?.data?.footerCollection?.items?.filter(Boolean)[0] || null;
  return translationDataModify(defaultFooterData, footerData);
};

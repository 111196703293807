import dynamic from 'next/dynamic';
import styled from 'styled-components';

const DynamicAdsense = dynamic(() => import('../PMCAdsense'));

export const RightSectionWrapper = styled.div`
  display: none;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: block;
    flex: 0 0 352px;
    max-width: 352px;

    > div:not(:last-child) {
      margin-bottom: ${({ theme }) => theme.spacing[24]};
    }
  }
`;

export const AdsWrapper = styled(DynamicAdsense)`
  padding: ${({ theme }) => theme.spacing[16]} 37px;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    padding: ${({ theme }) => theme.spacing[16]} 26px;
  }
`;

import type React from 'react';
import { useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { theme } from '@/constants';
import Slider from 'react-slick';
import { Thumbnail, Button } from '@/components';
import { type ThumbnailProps, ThumbnailVariant, ButtonSize, ButtonVariant, ResponsiveCollection } from '@/interfaces';
import { replacePath } from '@/utils';
import { useResponsive } from '@/hooks';
import { ACCEPTED_ARTICLE_TYPES, RouteName } from '@/constants';
import { CarouselWrapper, ButtonContainer, PrevArrow, NextArrow, FakeThumbnail } from './Carousel.styles';

interface IRenderSlidesArg {
  navs: JSX.Element;
  sliderProps: any;
}

const Carousel: React.FC<{
  renderSlides?: (arg: IRenderSlidesArg) => JSX.Element;
  carouselData?: ThumbnailProps[] | null;
  expandLink?: string;
  infinite?: boolean;
  hideViewAll?: boolean;
  slidesToShow?: number;
}> = ({ renderSlides, carouselData, expandLink, infinite = false, hideViewAll = false, slidesToShow }) => {
  const { asPath } = useRouter();
  const sliderRef = useRef<any>(null);
  const [state, setState] = useState({
    clientXonMouseDown: null,
    clientYonMouseDown: null,
  });
  const [slidePos, setSlidePos] = useState('start');
  const { isDesktop } = useResponsive([ResponsiveCollection.Desktop, ResponsiveCollection.CarouselMaxSize]);

  const isReachedFirstSlide = useRef<boolean>(false);
  const isReachedLastSlide = useRef<boolean>(false);

  if (!carouselData?.length && !renderSlides) return null;

  // Fixed right now, will use this as a property later if needed.
  const settings = {
    dots: false,
    arrows: false,
    infinite,
    slidesToShow,
    variableWidth: true,
    centerMode: false,
    accessibility: false,
    touchThreshold: 100,
    swipeToSlide: true,
    swipe: true,
    lazyLoad: 'ondemand',
    afterChange: () => {
      if (
        sliderRef.current.innerSlider.state.currentSlide ===
        sliderRef.current.innerSlider.state.slideCount - sliderRef.current.props.slidesToShow
      ) {
        setSlidePos('end');
        isReachedLastSlide.current = true;
        isReachedFirstSlide.current = false;
      } else if (sliderRef.current.innerSlider.state.currentSlide === 0) {
        setSlidePos('start');
        isReachedFirstSlide.current = true;
        isReachedLastSlide.current = false;
      } else {
        setSlidePos('middle');
      }
    },
  };

  useEffect(() => {
    if (sliderRef?.current) {
      sliderRef.current?.slickGoTo(0);
    }
  }, [asPath]);

  const handleOnMouseDown = (e: any) => {
    setState({
      clientXonMouseDown: e.clientX,
      clientYonMouseDown: e.clientY,
    });
    e.preventDefault(); // stops weird link dragging effect
  };

  const handleOnClick = (e: any) => {
    e.stopPropagation();
    if (state.clientXonMouseDown !== e.clientX || state.clientYonMouseDown !== e.clientY) {
      // prevent link click if the element was dragged
      e.preventDefault();
    }
  };

  const renderNavs = (
    <>
      <Button
        onClick={() => sliderRef?.current?.slickPrev()}
        variant={ButtonVariant.Link}
        disabled={!infinite && slidePos === 'start'}
      >
        <PrevArrow />
      </Button>
      <Button
        onClick={() => {
          sliderRef?.current?.slickNext();
        }}
        variant={ButtonVariant.Link}
        disabled={!infinite && (slidePos === 'end' || (!!carouselData && carouselData.length < 3))}
        style={{ marginLeft: theme.spacing['16'] }}
      >
        <NextArrow />
      </Button>
    </>
  );

  if (renderSlides) {
    return renderSlides({
      sliderProps: { ...settings, ref: sliderRef },
      navs: renderNavs,
    });
  }

  return (
    <CarouselWrapper isReachedFirstSlide={isReachedFirstSlide.current} isReachedLastSlide={isReachedLastSlide.current}>
      <Slider ref={sliderRef} {...settings}>
        {carouselData?.map((thumbnail) => {
          const { articleType, slug } = thumbnail;
          const href = replacePath(RouteName.Article, [ACCEPTED_ARTICLE_TYPES[articleType ?? 'events'], slug]);

          return (
            <Link
              key={thumbnail.sys.id}
              href={href}
              onMouseDown={(e) => handleOnMouseDown(e)}
              onClick={(e) => handleOnClick(e)}
            >
              <Thumbnail {...thumbnail} variant={ThumbnailVariant.LARGE_HIDE_DES} />
            </Link>
          );
        })}
        {!infinite && <FakeThumbnail />}
      </Slider>
      <ButtonContainer>
        {isDesktop && renderNavs}
        {expandLink && !hideViewAll && (
          <Button
            size={ButtonSize.Small}
            mobileSize={ButtonSize.Small}
            variant={ButtonVariant.Link}
            href={expandLink}
            style={{ marginLeft: theme.spacing['32'] }}
          >
            View All
          </Button>
        )}
      </ButtonContainer>
    </CarouselWrapper>
  );
};

export default Carousel;

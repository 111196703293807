import type { NextPage } from 'next';
import type { RegisterOptions } from 'react-hook-form';
import { DEFAULT_REQUIRED_MESSAGE, EMAIL_REGEX, EmailField } from '@/constants';
import { FieldType, type IFormFieldProps, WidthType } from '@/interfaces';
import { Input, Checkbox, Selector, TextArea } from './components/FormFieldType';
import { FormFieldWrapper } from './FormField.styles';

const FormField: NextPage<IFormFieldProps<Record<string, string | boolean>> | null> = ({
  index,
  type,
  formState,
  isEmailForm,
  appear = true,
  width = WidthType.Full,
  mandatoryCheckErrorMessage,
  setIsOtherAuthMethodExist,
  register,
  setValue,
  watch,
  onFocus,
  ...inputProps
}) => {
  const errorMessage = inputProps?.name && formState?.errors[inputProps.name]?.message;

  const registerOptions: RegisterOptions<any> = isEmailForm
    ? {
        required: inputProps?.isMandatory ?? true,
        onChange: () => setIsOtherAuthMethodExist && setIsOtherAuthMethodExist(false),
        pattern: undefined,
      }
    : {
        required: inputProps?.isMandatory && (mandatoryCheckErrorMessage || DEFAULT_REQUIRED_MESSAGE),
      };

  const registerName = inputProps?.name || `field=${index}`;
  const registerField = register(registerName, registerOptions);

  const inputBaseProps = {
    ...inputProps,
    ...registerField,
    errorMessage,
    isEmailForm,
    onFocus: () => onFocus && onFocus(inputProps),
  };

  const renderFormField = () => {
    switch (type) {
      case FieldType.Input:
        return <Input {...inputBaseProps} />;

      case FieldType.Email:
        return (
          <Input
            {...inputBaseProps}
            {...register(registerName, {
              pattern: {
                value: EMAIL_REGEX,
                message: EmailField.customErrorMessage as string,
              } as any,
              ...registerOptions,
            })}
          />
        );

      case FieldType.TextArea:
        return <TextArea {...inputBaseProps} value={watch(registerName) as string} />;

      case FieldType.Checkbox:
        return (
          <Checkbox
            checked={!!watch(registerName)}
            {...inputBaseProps}
            onClick={() => {
              inputBaseProps.onClick && inputBaseProps.onClick(!watch(registerName));
              setValue(registerName, !watch(registerName));
            }}
          />
        );

      case FieldType.Dropdown:
        return (
          <Selector
            {...inputBaseProps}
            value={(watch(registerName) as string) || inputBaseProps.value}
            onSelect={(selected: string) => setValue(registerName, selected, { shouldValidate: true })}
          />
        );

      default:
        return null;
    }
  };

  return appear ? (
    <FormFieldWrapper hasValue={!!watch(registerName)} width={width || WidthType.Full}>
      {renderFormField()}
    </FormFieldWrapper>
  ) : null;
};

export default FormField;

import { DEFAULT_LANGUAGE } from '@/constants';
import { LanguageCode } from '@/interfaces';
import { languageFieldsMapping, translationDataModify } from '@/utils';
import callContentful from './base.fetchers';
import type { IMainTranslation, INotificationTranslation, ITrackingTranslation } from '@/modules/pmt/interfaces';

type GetTranslationDataArgs = (args: {
  isPreviewMode: boolean;
  languageCode: LanguageCode;
}) => Promise<{
  mainTranslation: IMainTranslation;
  trackingTranslations: ITrackingTranslation[];
  notificationTranslations: INotificationTranslation[];
}>;

export const getTranslationData: GetTranslationDataArgs = async ({
  isPreviewMode,
  languageCode = LanguageCode.English,
}) => {
  const mappedLanguageFields = languageFieldsMapping(languageCode);

  const query = `
    {
      defaultMainTranslationCollection: mainTranslationCollection(
        preview: ${isPreviewMode}
        locale: "${DEFAULT_LANGUAGE}"
        limit: 1
        order: sys_firstPublishedAt_DESC
      ) {
        items {
          sys {
            id
          }
          parcelListSectionTitleMobile: parcelListSectionTitleMobile1
          parcelListSectionTitleDesktop: parcelListSectionTitleDesktop1
        }
      }
      mainTranslationCollection(
        preview: ${isPreviewMode}
        locale: "${mappedLanguageFields.locale}"
        limit: 1
        order: sys_firstPublishedAt_DESC
      ) {
        items {
          sys {
            id
          }
          ${mappedLanguageFields.getField('parcelListSectionTitleMobile')}
          ${mappedLanguageFields.getField('parcelListSectionTitleDesktop')}
        }
      }
      defaultTrackingTranslationCollection: trackingTranslationCollection(
        preview: ${isPreviewMode}
        locale: "${DEFAULT_LANGUAGE}"
        limit: 1
        order: sys_firstPublishedAt_DESC
      ) {
        items {
          sys {
            id
          }
          identifier
          mainTextMobile: mainTextMobile1
          subTextMobile: subTextMobile1
          sectionTitleDesktop: sectionTitleDesktop1
          mainTextDesktop: mainTextDesktop1
          subTextDesktop: subTextDesktop1
          mainTextGrantAccessPopup: mainTextGrantAccessPopup1
          subTextGrantAccessPopup: subTextGrantAccessPopup1
          mainButtonGrantAccessPopup: mainButtonGrantAccessPopup1
        }
      }
      trackingTranslationCollection(
        preview: ${isPreviewMode}
        locale: "${mappedLanguageFields.locale}"
        limit: 10
        order: sys_firstPublishedAt_DESC
      ) {
        items {
          sys {
            id
          }
          identifier
          ${mappedLanguageFields.getField('mainTextMobile')}
          ${mappedLanguageFields.getField('subTextMobile')}
          ${mappedLanguageFields.getField('sectionTitleDesktop')}
          ${mappedLanguageFields.getField('mainTextDesktop')}
          ${mappedLanguageFields.getField('subTextDesktop')}
          ${mappedLanguageFields.getField('mainTextGrantAccessPopup')}
          ${mappedLanguageFields.getField('subTextGrantAccessPopup')}
          ${mappedLanguageFields.getField('mainButtonGrantAccessPopup')}
        }
      }
      defaultNotificationTranslationCollection: notificationTranslationCollection(
        preview: ${isPreviewMode}
        locale: "${DEFAULT_LANGUAGE}"
        limit: 1
        order: sys_firstPublishedAt_DESC
      ) {
        items {
          sys {
            id
          }
          identifier
          mobileText: mobileText1
          desktopText: desktopText1
          buttonText: buttonText1
          emailSyncDesktopMainText: emailSyncDesktopMainText1
          emailSyncMobileMainText: emailSyncMobileMainText1
          emailSyncButtonText: emailSyncButtonText1
          loggedInEsMainText: loggedInEsMainText1
          loggedInEsHasParcelsButtonText: loggedInEsHasParcelsButtonText1
          loggedInEsNoParcelsButtonText: loggedInEsNoParcelsButtonText1
        }
      }
      notificationTranslationCollection(
        preview: ${isPreviewMode}
        locale: "${mappedLanguageFields.locale}"
        limit: 10
        order: sys_firstPublishedAt_DESC
      ) {
        items {
          sys {
            id
          }
          identifier
          ${mappedLanguageFields.getField('mobileText')}
          ${mappedLanguageFields.getField('desktopText')}
          ${mappedLanguageFields.getField('buttonText')}
          ${mappedLanguageFields.getField('emailSyncDesktopMainText')}
          ${mappedLanguageFields.getField('emailSyncMobileMainText')}
          ${mappedLanguageFields.getField('emailSyncButtonText')}
          ${mappedLanguageFields.getField('loggedInEsMainText')}
          ${mappedLanguageFields.getField('loggedInEsHasParcelsButtonText')}
          ${mappedLanguageFields.getField('loggedInEsNoParcelsButtonText')}
        }
      }
    }
  `;

  const slug = '/' + `${languageCode === LanguageCode.English ? '' : '/'}` + 'track-my-parcel';
  const response = await callContentful(query, isPreviewMode, slug, 'translation.fetchers.getTranslationData');
  const defaultMainData: IMainTranslation = response.data?.defaultMainTranslationCollection?.items?.[0];
  const defaultTrackingData: ITrackingTranslation = response.data?.defaultTrackingTranslationCollection?.items?.[0];
  const defaultNotificationData: INotificationTranslation =
    response.data?.defaultNotificationTranslationCollection?.items?.[0];

  const mainData: IMainTranslation = response.data?.mainTranslationCollection?.items?.[0];
  const trackingData: ITrackingTranslation[] = response.data?.trackingTranslationCollection?.items;
  const notificationData: INotificationTranslation[] = response.data?.notificationTranslationCollection?.items;

  return {
    mainTranslation: translationDataModify(defaultMainData, mainData),
    trackingTranslations: trackingData?.map((trackingTranslation) =>
      translationDataModify(defaultTrackingData, trackingTranslation),
    ),
    notificationTranslations: notificationData?.map((notificationTranslation) =>
      translationDataModify(defaultNotificationData, notificationTranslation),
    ),
  };
};

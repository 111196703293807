import getConfig from 'next/config';

import { ErrorMessages, MessageField } from '@/constants';

const { serverRuntimeConfig } = getConfig();

const BASE_URL = serverRuntimeConfig.neoCmsUrl;

interface IGetTrackingSitemapFailureResponse {
  error: string;
}

type GetTrackingSitemap = () => Promise<string | IGetTrackingSitemapFailureResponse>;

export const getTrackingSitemap: GetTrackingSitemap = async () => {
  const fetchUrl = `${BASE_URL}/sitemap.xml`;
  const fetchOptions: RequestInit = { method: 'GET' };

  try {
    const res = await fetch(fetchUrl, fetchOptions);
    const data = await res.text();
    return data;
  } catch {
    throw ErrorMessages[MessageField.GET_TRACKING_SITEMAP_ERROR];
  }
};

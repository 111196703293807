/**
 * Replace a new value into a part of the original string (with {{ }} marker)
 * @param originalString string with {{ }} marker
 * @param key text inside {{ }} marker
 * @param newValue text to be replace into `{{key}}`
 * @returns string
 */
const replaceHighlightString = (originalString: string | undefined, key: string, newValue: string): string => {
  if (!originalString) return '';

  const indicatorRegex = new RegExp(`{{${key}}}`, 'g');
  return originalString.replace(indicatorRegex, newValue);
};

export default replaceHighlightString;

import { ErrorPageCodes } from '@/constants';
import type { NextApiResponse } from 'next';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

export const apiErrorPageReturn = async (res: NextApiResponse, errorCode = ErrorPageCodes.ERROR_404) => {
  const { origin } = publicRuntimeConfig;
  const fetchRes = await fetch(`${origin}/${errorCode}`);
  const notFoundPage = await fetchRes.text();
  const notFoundPageWithBodyTag = notFoundPage.replace(/<body(.*?)>/g, '<body$1 style="margin: 0">');

  res.setHeader('Content-Type', 'text/html');
  return res.status(404).send(notFoundPageWithBodyTag);
};

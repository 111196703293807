import styled, { css } from 'styled-components';
import { TextStyles, theme } from '@/constants';
import { ThumbnailVariant } from '@/interfaces';
import { Typography } from '@/components';
import Recommended from '../../public/images/icons/recommended-tag.svg';

export const ThumbnailImage = styled.div`
  width: 338px;
  position:relative;
  border-radius: 4px;

  * {
    border-radius: 4px;
  }

  img {
    object-fit: cover;
    object-position: center center;
  }
`;

export const ThumbnailArticleImage = styled(ThumbnailImage)`
  height: 194px;
`;

export const ThumbnailEventImage = styled(ThumbnailImage)`
  height: 169px;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
  border: 1px solid ${({ theme }) => theme.colors.surface05};
`;

export const ThumbnailRecommended = styled(Recommended)`
  position: absolute;
  top: -4px;
  right: 8px;
`;

export const ThumbnailTag = styled(Typography).attrs({
  variant: TextStyles['Overline'],
})<{ noTruncate?: boolean }>`
  border: 1px solid ${({ theme }) => theme.colors.disabledBlack};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.inactiveBlack};
  padding: ${({ theme }) => `3px ${theme.spacing[8]}`};
  display: block;
  white-space: nowrap;
  max-width: 70%;

  ${({ noTruncate }) =>
    !noTruncate &&
    `
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

export const ThumbnailTagWrapper = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: ${({ theme }) => theme.spacing[4]};
  
  ${ThumbnailTag} {
    margin-left: ${({ theme }) => theme.spacing[8]};
  }

  ${ThumbnailTag}:first-child {
    margin-left: 0;
    flex-shrink: 0;
  }
`;

export const ThumbnailTitle = styled(Typography).attrs({ variant: TextStyles['Heading 3'] })`
  color: ${({ theme }) => theme.colors.highEmphasisBlack};
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  white-space: normal;
`;

export const ThumbnailEventTitle = styled(ThumbnailTitle)`
  -webkit-line-clamp: 2;
`;

export const ThumbnailDescription = styled(Typography).attrs({ variant: TextStyles['Body Text Small'] })`
  color: ${({ theme }) => theme.colors.highEmphasisBlack};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
`;

export const ThumbnailDate = styled(Typography).attrs({ variant: TextStyles['Body Text Small'] })`
  color: ${({ theme }) => theme.colors.inactiveBlack};
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const EventThumbnailDate = styled(ThumbnailDate)`
  color: ${({ theme }) => theme.colors.mediumEmphasisBlack};
`;

export const ThumbnailMainContent = styled.div`
  display: grid;
  grid-auto-rows: max-content;
  grid-auto-columns: 100%;
  gap: ${({ theme }) => theme.spacing['8']};
  overflow: hidden;
`;

// Note: Event does not have this styling
// Styles apply for Default small card
const SmallDefaultCardStyles = css`
  flex-direction: column;
  width: 200px;
  
  ${ThumbnailImage} {
    min-width: 200px;
    width: 200px;
    height: 112.5px;
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    flex-direction: row;
    width: 338px;

    ${ThumbnailImage} {
      width: 160px;
      min-width: 160px;
      height: 90px;
    }
  }
`;

// Note: Event does not have this styling
// Styles apply for Default small card with small image
// Remove border-radius of img to have correct border-solid (only for this variant, since afraid break other variants)
const SmallImgCardStyles = css`
  flex-direction: row;
  width: 100%;

  ${ThumbnailImage} {
    width: 120px;
    min-width: 120px;
    height: 67.5px;
    border: 1px solid ${theme.colors.surface05};
    box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
    overflow: hidden;

    * {
      border-radius: 0;
    }
  }
`;

// Styles apply for Hidden image small card
const HiddenImgSmallCardStyles = css`
  flex-direction: column;

  ${ThumbnailImage} {
   display: none;
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    max-width: 312px;
  }
`;

// Styles apply for Default large card
const LargeDefaultCardStyles = css`
  ${({ theme }) => theme.mediaBreakpointUp.md} {
    flex-direction: column;
    width: 100%;

    ${ThumbnailImage} {
      width: 100%;
      height: 194px;
    }

    ${ThumbnailEventImage} {
      height: 169px;
    }
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    width: 338px;

    ${ThumbnailImage} {
      width: 338px;
    }
  }
`;

// Styles apply for Hidden Description large card
const HiddenDesLargeCardStyles = css`
  flex-direction: column;
  width: 200px;

  ${ThumbnailImage} {
    width: 200px;
    height: 112.5px;
  }

  ${ThumbnailEventImage} {
    height: 100px;
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    width: 280px;

    ${ThumbnailImage} {
      width: 280px;
      height: 157.5px;
    }

    ${ThumbnailEventImage} {
      height: 140px;
    }
  }
`;

// Styles apply for Hidden Description large card
const HiddenDateLargeCardStyles = css`
  flex-direction: column;
  width: 100%;

  ${ThumbnailTitle} {
    -webkit-line-clamp: 2;
  }

  ${ThumbnailMainContent} {
    gap: 0;
  }

  ${ThumbnailImage},
  ${ThumbnailEventImage} {
    width: 100%;
    filter: drop-shadow(0px 0px 1px rgba(40, 41, 61, 0.04)) drop-shadow(0px 2px 4px rgba(96, 97, 112, 0.16));
  }
`;

// Styles apply for Blue Border Left card
const BlueBorderLeftCardStyles = css`
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.surface03};
  padding: ${({ theme }) => `
  ${theme.spacing[16]} 
  ${theme.spacing[16]} 
  ${theme.spacing[16]} 
  ${theme.spacing[20]}
  `};
  overflow: hidden;
  position: relative;
  border-radius: 0px 4px 4px 0px;
  ${ThumbnailMainContent} {
    gap: ${({ theme }) => theme.spacing[4]};
  }
  ${ThumbnailTagWrapper} {
    padding-bottom: ${({ theme }) => theme.spacing[8]};
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 4px;
    background-color: ${({ theme }) => theme.colors.blue};
  }

  ${ThumbnailTitle} {
    max-width: 280px;
  }

  ${ThumbnailImage} {
    display: none;
  }
`;

export const ThumbnailWrapper = styled.div<{ variant: ThumbnailVariant }>`
  // DEFAULT SMALL CARD 
  ${({ variant }) => variant === ThumbnailVariant.SMALL_DEFAULT && SmallDefaultCardStyles}

  // HIDDEN IMAGE SMALL CARD 
  ${({ variant }) => variant === ThumbnailVariant.SMALL_HIDE_IMG && HiddenImgSmallCardStyles}

  // DEFAULT SMALL IMAGE CARD 
  ${({ variant }) => variant === ThumbnailVariant.SMALL_IMG && SmallImgCardStyles}

  // DEFAULT LARGE CARD 
  ${({ variant }) => variant === ThumbnailVariant.LARGE_DEFAULT && LargeDefaultCardStyles}

  // HIDDEN DESCRIPTION LARGE CARD 
  ${({ variant }) => variant === ThumbnailVariant.LARGE_HIDE_DES && HiddenDesLargeCardStyles}

  // HIDDEN DATE LARGE CARD 
  ${({ variant }) => variant === ThumbnailVariant.LARGE_HIDE_DATE && HiddenDateLargeCardStyles}

  // BLUE BORDER LEFT CARD 
  ${({ variant }) => variant === ThumbnailVariant.BLUE_BORDER_LEFT && BlueBorderLeftCardStyles}

  position: relative;

  &:hover {
    opacity: .8;
  }

  display: grid;
  gap: ${({ theme }) => theme.spacing[16]};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    ${({ variant }) =>
      variant === ThumbnailVariant.SMALL_DEFAULT &&
      `
      grid-template-columns: 1fr 1fr;
    `}
    ${({ variant }) =>
      variant === ThumbnailVariant.SMALL_IMG &&
      `
      grid-template-columns: 120px 1fr;
    `}
  }
`;

import Image from 'next/image';
import styled from 'styled-components';
import Typography from '@/components/Typography';
import { TextStyles } from '@/constants';
import { FlexContainer } from '@/components/Tooltip/Tooltip.styles';

export const CarrierLogo = styled(Image).attrs({
  width: 40,
  height: 40,
})(({ theme }) => ({
  borderRadius: 2,
  marginRight: theme.spacing[12],
}));

export const CarrierName = styled(Typography).attrs({
  variant: TextStyles['Heading 3'],
})<{ isOverflowing?: boolean }>`
  flex: 1;
  white-space: wrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const CarrierNameWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  overflow: hidden;

  ${FlexContainer}, ${FlexContainer} > div {
    display: flex;
    flex: 1;
    overflow: hidden;
  }

  a {
    overflow: hidden;
  }

  a,
  a:active,
  a:focus,
  a:visited {
    color: ${({ theme }) => theme.colors.highEmphasisBlack};
  }

  a:hover {
    color: ${({ theme }) => theme.colors.blueHover};
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const CarrierDisplayNameWrapper = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;

  .has-tooltip {
    ${CarrierName} {
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;

export enum EmailStatusCode {
  Verified = 200,
  Unverified = 201,
  ExistOtherIssues = 400,
}

export enum VerifyStatusCode {
  Valid = 200,
  ValidNewBrowser = 201,
  Invalid = 400,
}

export enum SendMailStatusCode {
  Valid = 200,
  ExistOtherMethod = 400,
  EmailNotFoundUser = 404,
}

export enum UserType {
  Professional = 'Professional',
  Tracking = 'Tracking',
  TrackingAndProfessional = 'Tracking + Professional',
}

export enum NewsletterOptInSource {
  PMT_WEBSITE = 'PMT website',
  PMC_WEBSITE = 'PMC website',
  PMT_EMAIL_NOTIFICATION = 'PMT email notification',
}

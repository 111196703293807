import styled from 'styled-components';

export const PaginationContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  > *:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing[8]};
  }
`;

const PaginationBox = styled.div`
  cursor: pointer;
  width: 32px;
  height: 32px;
  border: 1px solid transparent;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${({ theme }) => theme.spacing[4]} 0;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    &:hover {
      background: ${({ theme }) => theme.colors.surface03};
    }
  }
`;

export const PaginationArrowBox = styled(PaginationBox)<{
  disabled?: boolean;
  leftArrow?: boolean;
}>`
  background: ${({ theme }) => theme.colors.highEmphasisWhite};
  border-color: ${({ theme }) => theme.colors.mediumEmphasisBlack};

  svg {
    ${({ leftArrow }) =>
      leftArrow
        ? `
      transform: rotate(90deg);
    `
        : `
      transform: rotate(-90deg);
    `}
  }

  svg path {
    fill: ${({ theme }) => theme.colors.mediumEmphasisBlack};
  }

  ${({ theme, disabled }) =>
    disabled &&
    `
    pointer-events: none;
    opacity: 0.5;
    background: ${theme.colors.disabledBlack};
    border-color: transparent;

    svg path {
      fill: ${theme.colors.highEmphasisWhite};
    }
  `}
`;

export const PaginationNumberBox = styled(PaginationBox)<{
  active?: boolean;
}>`
  padding: ${({ theme }) => theme.spacing[4]};
  background: ${({ theme }) => theme.colors.highEmphasisWhite};
  color: ${({ theme }) => theme.colors.blue};
  border-color: ${({ theme }) => theme.colors.blue};

  ${({ theme, active }) =>
    !active &&
    `
    border-color: ${theme.colors.gray01};
    color: ${theme.colors.mediumEmphasisBlack};
  `}
`;

import { RouteName } from '@/constants';
import {
  BlackListedRetailerKeys,
  BoardDetailType,
  BoardDetailsPositioning,
  type IBannerWithInfoBoard,
  type IBoardDetail,
  type IPmInsightsRetailer,
} from '@/interfaces';
import { getMarketSlugFromMarketName, replacePath } from '@/utils';
import { replaceSnakeCaseWithSpace } from '@/utils/text.utils';

export const mapRetailer = (retailer: any): IPmInsightsRetailer => {
  const clonedRetailer = { ...retailer };
  const productCategories = clonedRetailer.product_categories;
  const isArray = Array.isArray(productCategories);

  clonedRetailer.product_categories = isArray ? productCategories.join(', ') : productCategories;

  return clonedRetailer;
};

type MapRetailerPageOverviewBanner = (args: {
  retailer: IPmInsightsRetailer;
  pageOverviewBanner: IBannerWithInfoBoard;
}) => IBannerWithInfoBoard;

export const mapRetailerPageOverviewBanner: MapRetailerPageOverviewBanner = ({ retailer, pageOverviewBanner }) => {
  pageOverviewBanner.title = retailer.name;
  pageOverviewBanner.description = retailer.description;
  pageOverviewBanner.logo = retailer.logo;

  const boardDetails: IBoardDetail[] = [];

  Object.keys(retailer).forEach((key) => {
    if (Object.values(BlackListedRetailerKeys).includes(key)) {
      return;
    }

    const value = retailer[key as keyof IPmInsightsRetailer];
    if (!value) return;

    const position = BoardDetailsPositioning[key as keyof typeof BoardDetailsPositioning];
    boardDetails[position] = mapBoardDetail({ key, value, retailer });
  });

  pageOverviewBanner.boardDetails = boardDetails.filter(Boolean);

  return pageOverviewBanner;
};

type MapBoardDetail = (args: {
  key: string;
  value: string;
  retailer: IPmInsightsRetailer;
}) => IBoardDetail;

const mapBoardDetail: MapBoardDetail = ({ key, value, retailer }) => {
  const name = replaceSnakeCaseWithSpace(key);

  switch (key) {
    case 'headquarters': {
      const marketSlug = value && getMarketSlugFromMarketName(value, true);
      const marketHref = marketSlug && replacePath(RouteName.Market, [marketSlug]);

      return {
        name,
        value: marketHref,
        displayText: value,
        type: BoardDetailType.Link,
      };
    }
    case 'company_size':
      return {
        name,
        value: value.split(' ')[0],
        type: BoardDetailType.Text,
      };
    case 'website':
      return {
        name,
        value,
        displayText: `${retailer.name} Website`,
        type: BoardDetailType.Link,
        openInNewTab: true,
      };
    default:
      return {
        name,
        value,
        type: BoardDetailType.Text,
      };
  }
};

import { forwardRef, type ChangeEvent, type ChangeEventHandler } from 'react';
import ErrorMessage from '../../ErrorMessage';
import Label from '../../Label';

import { TextInput, TextAreaWrapper } from './TextArea.styles';

interface IInputProps {
  name?: string;
  label?: string;
  isMandatory?: boolean;
  errorMessage?: string;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  value?: string;
}

const MIN_ROWS = 5;
const MAX_ROWS = 10;
const TEXT_LINE_HEIGHT = 24;

const TextArea: React.ForwardRefRenderFunction<HTMLTextAreaElement, IInputProps & Record<string, any>> = (
  props,
  ref,
) => {
  const { name, label, isMandatory, errorMessage, onChange, value, ...restProps } = props;

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onChange && onChange(e);

    // Handle auto height
    e.target.style.height = 'inherit';
    e.target.style.height = `${e.target.scrollHeight}px`;
    e.target.style.height = `${Math.min(e.target.scrollHeight, MAX_ROWS * TEXT_LINE_HEIGHT)}px`;
  };

  return (
    <>
      <TextAreaWrapper isInvalid={!!errorMessage}>
        <TextInput
          ref={ref}
          name={name}
          rows={MIN_ROWS}
          hasValue={!!value}
          role='presentation'
          {...restProps}
          onChange={handleChange}
          placeholder=''
        />
        {label && (
          <Label htmlFor={name} isMandatory={isMandatory}>
            {label}
          </Label>
        )}
      </TextAreaWrapper>
      <ErrorMessage>{errorMessage}</ErrorMessage>
    </>
  );
};

export default forwardRef(TextArea);

import type { EventPhaseKey } from './common.interfaces';

export enum TrackingMilestone {
  Pending = 'Pending',
  Ordered = 'Ordered',
  InTransit = 'InTransit',
  OutForDelivery = 'OutForDelivery',
  ToCollect = 'ToCollect',
  Delivered = 'Delivered',
  Collected = 'Collected',
  Returned = 'Returned',
  Cancelled = 'Cancelled',
}

export interface ITrackingProgressTracker {
  hasIssue: boolean;
  milestone: TrackingMilestone;
}

export interface IMilestoneCondition {
  phaseKey: EventPhaseKey;
  isFinalEvent?: boolean;
  isFixedAddress?: boolean;
}

export enum CarrierDropdownState {
  Selected = 'Selected',
  Scraping = 'Scraping/Scraped',
}

import { CountryNames, countries } from '@/constants';

export const getCountryName = (code: string) => {
  return CountryNames[code as keyof typeof CountryNames] || code;
};

type GetCountryBySlug = (slug: string) => (typeof countries)['vietnam'] | null; // sample an element in object

export const getCountryBySlug: GetCountryBySlug = (slug) => {
  return countries[slug as keyof typeof countries] || null;
};

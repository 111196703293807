export enum ImageContentType {
  PNG = 'image/png',
  JPG = 'image/jpg',
  JPEG = 'image/jpeg',
  SVG = 'image/svg+xml',
  WEBP = 'image/webp',
  GIF = 'image/gif',
}

export enum VideoContentType {
  MP4 = 'video/mp4',
  WEBM = 'video/webm',
  OGG = 'video/ogg',
  QUICKTIME = 'video/quicktime',
}

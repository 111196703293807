import { type MouseEventHandler, type ReactEventHandler, type ReactNode, useMemo, useState, useRef } from 'react';
import dayjs from 'dayjs';

import {
  startAndEndDateFormat,
  resetDataLayerAndPushEvent,
  extractDateFromISOString,
  extractTimezoneFromISOString,
} from '@/utils';

import { useGlobalState, useHighlightString } from '@/hooks';

import type { IEvent } from '@/interfaces';

import { ACCEPTED_EVENT_TYPE_TAG_SLUGS, ACCEPTED_TAG_CATEGORY_TYPES, CustomEventName } from '@/constants';

type UseEventMainSection = (props: IEvent) => {
  isVideoPlaying: boolean;
  isLoggedIn: boolean;
  isInternalEvent: boolean;
  isCompletedEvent: boolean;
  hasVideo: boolean;
  displayDate: string;
  displayTime: string;
  formattedAdditionalComment: ReactNode;
  handleVideoPlay: MouseEventHandler;
  handleVideoTimeUpdate: ReactEventHandler<HTMLVideoElement>;
};

const useEventMainSection: UseEventMainSection = ({
  video,
  startDate: _startDate = '',
  endDate: _endDate = '',
  tags,
  additionalComment,
}) => {
  const startDate = extractDateFromISOString(_startDate);
  const endDate = extractDateFromISOString(_endDate);

  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const isPVVEventPushed = useRef(false); // PVV = PlaybackVideoViews
  const { isLoggedIn } = useGlobalState();
  const isInternalEvent =
    tags?.some(
      (tag) =>
        tag.category === ACCEPTED_TAG_CATEGORY_TYPES.EventType && tag.slug === ACCEPTED_EVENT_TYPE_TAG_SLUGS.InHouse,
    ) || false;
  const isCompletedEvent = Date.now() > Date.parse(endDate);
  const hasVideo = !!video;

  const displayDate = useMemo<string>(() => {
    if (!startDate || !endDate) {
      return '';
    }

    return startAndEndDateFormat(startDate, endDate);
  }, []);

  const displayTime = useMemo<string>(() => {
    const formattedStartTime = dayjs(new Date(startDate)).format('h:mm a');
    const formattedEndTime = dayjs(new Date(endDate)).format('h:mm a');

    const timezone = extractTimezoneFromISOString(_startDate);
    const utcText = timezone && `UTC${timezone}`;

    return `${formattedStartTime} - ${formattedEndTime} ${utcText}`;
  }, []);

  const formattedAdditionalComment = useHighlightString(additionalComment, { fontWeight: 'bold' }, 'span');

  const handleVideoPlay: MouseEventHandler = () => {
    if (isLoggedIn) {
      setIsVideoPlaying(true);
    }
  };

  const handleVideoTimeUpdate: ReactEventHandler<HTMLVideoElement> = (e) => {
    const { currentTime } = e.target as any;

    if (!isPVVEventPushed.current && currentTime >= 3) {
      isPVVEventPushed.current = true;
      resetDataLayerAndPushEvent(CustomEventName.PlaybackVideoButtonViews);
    }
  };

  return {
    isVideoPlaying,
    isLoggedIn,
    isInternalEvent,
    isCompletedEvent,
    hasVideo,
    displayDate,
    displayTime,
    formattedAdditionalComment,
    handleVideoPlay,
    handleVideoTimeUpdate,
  };
};

export default useEventMainSection;

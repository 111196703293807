export enum PMCAdType {
  TOP_OF_PMC_PAGE = 'TOP_OF_PMC_PAGE',
  LEFT_SECTION = 'LEFT_SECTION',
  RIGHT_SECTION = 'RIGHT_SECTION',
}

export interface GoogleAdsenseAttribute {
  dataAdSlot?: string;
  dataAdFormat?: string;
  dataAdLayoutKey?: string;
}

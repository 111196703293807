import styled from 'styled-components';
import { Typography } from '@/components';
import { TextStyles } from '@/constants';

export const Text = styled(Typography).attrs({
  variant: TextStyles['Body Text Small'],
})`
  word-break: break-word;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
`;

export const Anchor = styled.a`
  color: ${({ theme }) => theme.colors.highEmphasisBlack};

  &:hover {
    cursor: pointer;
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.blue};
  }
`;

export const TableTextContainer = styled.div<{
  limit: number;
  enableTooltip: boolean;
  Component?: React.FC;
}>`
  ${({ limit, enableTooltip, Component }) =>
    enableTooltip
      ? `
    .has-tooltip {
      ${Component || Text} {
        -webkit-line-clamp: ${limit};
      }
    }
  `
      : `
    ${Component || Text} {
      -webkit-line-clamp: ${limit};
    }
  `}
`;

import styled from 'styled-components';
import { LayoutHierarchy } from '@/constants';

export const OnboardingPopupContainer = styled.div<{ showPopUp?: boolean }>`
  position: fixed;
  z-index: ${LayoutHierarchy.Second};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
`;

export const OnboardingPopupOverlay = styled.div<{ on?: string }>`
  background: ${({ theme }) => theme.colors.overlay};
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
`;

const OUTER_HORIZONTAL_SPACING = 16;
const OUTER_VERTICAL_SPACING = 40;

export const OnboardingPopupWrapper = styled.div<{
  noTopPosition?: boolean;
  isFullScreen?: boolean;
}>`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  max-width: 948px;

  ${({ theme, noTopPosition, isFullScreen }) =>
    !noTopPosition &&
    (isFullScreen
      ? `
    top: 0;
  `
      : `
    top: ${theme.spacing[OUTER_VERTICAL_SPACING]};
    left: 50%;
    transform: translateX(-50%);
    height: fit-content;
    width: calc(100% - ${theme.spacing[OUTER_HORIZONTAL_SPACING]} * 2);
    max-height: calc(100% - ${theme.spacing[OUTER_VERTICAL_SPACING]} * 2);
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 8px;
    box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.04), 0px 16px 24px rgba(96, 97, 112, 0.16);
  `)}
  
  ${({ theme }) => theme.mediaBreakpointUp.md} {
    top: unset;
    bottom: unset;
    left: unset;
    background: ${({ theme }) => theme.colors.highEmphasisWhite};
    display: flex;
    padding: ${({ theme }) => theme.spacing[10]};
    border-radius: 12px;
    min-height: 580px;

    transform: translateX(-50%);
    max-width: 948px;
    max-height: unset;
    overflow: unset;
    box-shadow: unset;
    transform: unset;
  }
`;

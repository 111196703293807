import { useCallback } from 'react';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

type ReCaptchaVerifyHandler = () => Promise<string> | undefined;

const useReCaptcha = (): {
  handleReCaptchaVerify: ReCaptchaVerifyHandler;
} => {
  const getGrecaptcha = useCallback(() => {
    if (typeof window === 'undefined') {
      return {};
    }

    return (window as any).grecaptcha;
  }, []);

  const handleReCaptchaVerify: ReCaptchaVerifyHandler = useCallback(() => {
    if (!getGrecaptcha()) {
      console.error('reCAPTCHA is not ready yet');
      return undefined;
    }

    return new Promise<string>((resolve) => {
      getGrecaptcha().ready(() => {
        getGrecaptcha()
          .execute(publicRuntimeConfig.recaptchaPublicKey)
          .then((captchaCode: string) => resolve(captchaCode));
      });
    });
  }, [getGrecaptcha()]);

  return {
    handleReCaptchaVerify,
  };
};

export default useReCaptcha;

import getConfig from 'next/config';
import { generateSquareLogo } from '@/utils';
import type { ICarrier } from '@/modules/pmt/interfaces';

const { publicRuntimeConfig } = getConfig();

const BASE_URL = publicRuntimeConfig.dataIoUrl;

export interface IGetCarriersFailureResponse {
  error: string;
}

interface IRawCarrier {
  timezone_awareness: string;
  has_source_site: boolean;
  id: number;
  name: string;
  carrier_id: string;
  carrier_url: string;
  carrier_url_tracking: string;
  carrier_logo: string;
  carrier_cs_phone: string;
  cms_url: string;
  carrier_countries: string[];
}

type GetCarriers = () => Promise<ICarrier[]>;

export const getCarriers: GetCarriers = async () => {
  const fetchUrl = `${BASE_URL}/api/v1/master-data/carrier/public/`;
  const fetchOptions: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const res = await fetch(fetchUrl, fetchOptions);

    const data = await res.json();

    return data.data?.map((item: IRawCarrier) => ({
      id: item.carrier_id,
      logo: generateSquareLogo(item.carrier_logo),
      name: item.name,
    }));
  } catch (error: any) {
    console.error('/api/v1/master-data/carrier/public/', error);
  }
};

import { type FailureResponse, LoginMethod } from '@/interfaces';

export enum StatusCodes {
  SUCCESS_201 = '201',
  SUCCESS_200 = '200',
  ERROR_500 = '500',
  ERROR_504 = '504',
  ERROR_422 = '422',
  ERROR_403 = '403',
  ERROR_404 = '404',
  ERROR_400 = '400',
}

export enum ErrorPageCodes {
  ERROR_404 = '404',
  ERROR_500 = '500',
  ERROR_503 = '503',
}

interface IErrorPageContent {
  errorCode: ErrorPageCodes;
  title: string;
  description?: string;
  buttonText?: string;
}

export const ErrorPageContent: Record<ErrorPageCodes, IErrorPageContent> = {
  [ErrorPageCodes.ERROR_404]: {
    errorCode: ErrorPageCodes.ERROR_404,
    title: "We couldn't find the page you're looking for.",
    buttonText: 'Go back to homepage',
  },
  [ErrorPageCodes.ERROR_500]: {
    errorCode: ErrorPageCodes.ERROR_500,
    title: 'Oops!\nSomething went wrong.',
    description: 'Please try again later.',
    buttonText: 'Go back to homepage',
  },
  [ErrorPageCodes.ERROR_503]: {
    errorCode: ErrorPageCodes.ERROR_503,
    title: 'Upgrading in progress',
    description: "We're performing some upgrades at the moment. Please try again shortly.",
  },
};

export enum MessageField {
  ADSENSE_ERROR = 'AdsenseError',
  SEND_MAIL_ERROR = 'SendMailError',
  UNDEFINED_ERROR = 'UndefinedError',
  STATE_DOES_MATCH = 'StateDoesMatch',
  DELETE_ALL_ERROR = 'DeleteAllError',
  DATA_SENT_SUCCESS = 'DataSentSuccess',
  REQUEST_NOT_FOUND = 'RequestNotFound',
  AUTHENTICATE_ERROR = 'AuthenticateError',
  GET_USER_INFO_ERROR = 'GetUserInfoError',
  ARGUMENT_STR_ERROR = 'ArgumentStrError',
  CLOSE_POPUP_MESSAGE = 'ClosePopupMessage',
  UPDATE_USER_INFO_ERROR = 'UpdateUserInfoError',
  UPDATE_USER_NEWSLETTER_OPT_IN_ERROR = 'UpdateUserNewsletterOptInError',
  REDIS_METHOD_NOT_FOUND = 'RedisMethodNotFound',
  GET_TRACKING_SITEMAP_ERROR = 'GetTrackingSitemapError',
  UNPROCESSABLE_REQUEST_ERROR = 'UnprocessableRequestError',
  GET_SERVER_PROPS_CACHED_ERROR = 'GetServerPropsCachedError',
  UNPROCESSABLE_DATA_FORMAT_ERROR = 'UnprocessableDataFormatError',
  CONNECTION_DATABASE_INTERRUPTED = 'ConnectionDatabaseInterrupted',
  DELETE_SERVER_PROPS_CACHED_ERROR = 'DeleteServerPropsCachedError',
  UNPROCESSABLE_REQUEST_MISSING_ERROR = 'UnprocessableRequestMissingError',
  GET_INITIAL_PROPS_MISSING_DATA_ERROR = 'GetInitialPropsMissingDataError',
  UNPROCESSABLE_REQUEST_ACCESS_DENIED_ERROR = 'UnprocessableRequestAccessDeniedError',
  GET_CARRIER_LIST_ERROR = 'GetCarrierListError',
  WEB_PUSH_NOTIFICATION_IS_NOT_SUPPORTED = 'WebPushNotificationIsNotSupported',
  FIREBASE_FCM_TOKEN_ERROR = 'FirebaseFCMTokenError',
  NOTIFICATION_PERMISSION_NOT_GRANTED = 'Notification permission not granted',
  GET_SHIPMENTS_FAILED = 'GetShipmentsFailed',
  VERIFY_ERROR = 'VerifyError',
  REMOVE_SHIPMENT_FAILED = 'RemoveShipmentFailed',
  TOOLTIP_INCORRECT_STRUCTURE = 'TooltipIncorrectStructure',
  GEO_INFO_ERROR = 'GeoInfoError',
  GEO_INFO_INVALID = 'GeoInfoInvalid',
  EMAIL_SYNC_CLIENT_ENV_NOT_FOUND = 'GoogleEmailSyncClientIdNotFound',
  EMAIL_SYNC_CANNOT_AUTHORIZED = 'GoogleEmailSyncCannotAuthorized',
  NO_DATA_FOUND_FROM_CONTENTFUL = 'NoDataFoundFromContentful',
  MISSING_REQUIRED_PARAMS = 'MissingRequiredParams',
  ASSIGN_CACHE_UNSUCCESSFULLY = 'AssignCacheUnsuccessfully',
  REMOVE_CACHE_UNSUCCESSFULLY = 'RemoveCacheUnsuccessfully',
}

export const ErrorMessages: Record<MessageField, FailureResponse> = {
  [MessageField.ADSENSE_ERROR]: {
    error: 'Adsense error',
  },
  [MessageField.SEND_MAIL_ERROR]: {
    error: 'Send verification link to email failed',
  },
  [MessageField.STATE_DOES_MATCH]: {
    error: 'State does not match',
  },
  [MessageField.DELETE_ALL_ERROR]: {
    error: '/api/cache/deleteServerPropsCached: Not Found',
  },
  [MessageField.UNDEFINED_ERROR]: {
    error: 'UndefinedError',
  },
  [MessageField.REQUEST_NOT_FOUND]: {
    error: 'Request Not Found',
  },
  [MessageField.DATA_SENT_SUCCESS]: {
    error: 'Data sent successfully',
  },
  [MessageField.AUTHENTICATE_ERROR]: {
    error: 'Authentication failed',
  },
  [MessageField.GET_USER_INFO_ERROR]: {
    error: 'Get user info failed',
  },
  [MessageField.ARGUMENT_STR_ERROR]: {
    error: 'argument str must be a string',
  },
  [MessageField.CLOSE_POPUP_MESSAGE]: {
    error: 'User closed the popup',
  },
  [MessageField.UPDATE_USER_INFO_ERROR]: {
    error: 'Update user info failed',
  },
  [MessageField.UPDATE_USER_NEWSLETTER_OPT_IN_ERROR]: {
    error: 'Update user newsletter opt-in failed',
  },
  [MessageField.REDIS_METHOD_NOT_FOUND]: {
    error: '/api/redis/webhook: Not Found',
  },
  [MessageField.GET_TRACKING_SITEMAP_ERROR]: {
    error: 'Get Tracking sitemap failure',
  },
  [MessageField.UNPROCESSABLE_REQUEST_ERROR]: {
    error: 'Unprocessable request: Something went wrong',
  },
  [MessageField.GET_SERVER_PROPS_CACHED_ERROR]: {
    error: '/api/cache/getServerPropsCached: Not Found',
  },
  [MessageField.UNPROCESSABLE_DATA_FORMAT_ERROR]: {
    error: 'Unrecognizable data format',
  },
  [MessageField.CONNECTION_DATABASE_INTERRUPTED]: {
    error: 'Connection to database is interrupted',
  },
  [MessageField.DELETE_SERVER_PROPS_CACHED_ERROR]: {
    error: '/api/cache/deleteServerPropsCached: Not Found',
  },
  [MessageField.UNPROCESSABLE_REQUEST_MISSING_ERROR]: {
    error: 'Unprocessable request: Missing data from request',
  },
  [MessageField.GET_INITIAL_PROPS_MISSING_DATA_ERROR]: {
    error: '_error.js getInitialProps missing data at path:',
  },
  [MessageField.UNPROCESSABLE_REQUEST_ACCESS_DENIED_ERROR]: {
    error: 'Unprocessable request: Access denied',
  },
  [MessageField.GET_CARRIER_LIST_ERROR]: {
    error: 'Get all carriers failure',
  },
  [MessageField.WEB_PUSH_NOTIFICATION_IS_NOT_SUPPORTED]: {
    error: 'Web push notification is not supported',
  },
  [MessageField.FIREBASE_FCM_TOKEN_ERROR]: {
    error: 'No registration token available. Request permission to generate one.',
  },
  [MessageField.NOTIFICATION_PERMISSION_NOT_GRANTED]: {
    error: 'Notification permission not granted.',
  },
  [MessageField.GET_SHIPMENTS_FAILED]: {
    error: 'Get shipments failed',
  },
  [MessageField.VERIFY_ERROR]: {
    error: 'Verify failed',
  },
  [MessageField.REMOVE_SHIPMENT_FAILED]: {
    error: 'Remove shipment failed',
  },
  [MessageField.TOOLTIP_INCORRECT_STRUCTURE]: {
    error: 'Tooltip components must be wrapped in <Tooltip />',
  },
  [MessageField.GEO_INFO_ERROR]: {
    error: 'Fetch IP info failed',
  },
  [MessageField.GEO_INFO_INVALID]: {
    error: 'IP info invalid',
  },
  [MessageField.EMAIL_SYNC_CLIENT_ENV_NOT_FOUND]: {
    error: 'Env value of googleEmailSyncClientId not found',
  },
  [MessageField.EMAIL_SYNC_CANNOT_AUTHORIZED]: {
    error: 'Cannot authorized',
  },
  [MessageField.NO_DATA_FOUND_FROM_CONTENTFUL]: {
    error: 'No data found from the Contentful',
  },
  [MessageField.MISSING_REQUIRED_PARAMS]: {
    error: 'Missing required params',
  },
  [MessageField.ASSIGN_CACHE_UNSUCCESSFULLY]: {
    error: 'Assign cache unsuccessfully',
  },
  [MessageField.REMOVE_CACHE_UNSUCCESSFULLY]: {
    error: 'Remove cache unsuccessfully',
  },
};

export enum UmsMessageField {
  EMAIL_UNVERIFIED = 'EMAIL_UNVERIFIED',
  GOOGLE_VERIFIED_ERROR = 'GOOGLE_VERIFIED_ERROR',
  LINKEDIN_VERIFIED_ERROR = 'LINKEDIN_VERIFIED_ERROR',
  GET_PARCEL_ERROR = 'GET_PARCEL_ERROR',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  VERIFY_ERROR = 'VERIFY_ERROR',
  TRANSLATE_LOCATION_ERROR = 'TRANSLATE_LOCATION_ERROR',
  SUBSCRIBE_SHIPMENT_ERROR = 'SUBSCRIBE_SHIPMENT_ERROR',
  UNSUBSCRIBE_SHIPMENT_ERROR = 'UNSUBSCRIBE_SHIPMENT_ERROR',
  SHIPMENT_NOT_FOUND = 'SHIPMENT_NOT_FOUND',
  UPDATE_SHIPMENT_ERROR = 'UPDATE_SHIPMENT_ERROR',
}

export const UmsErrorMessages: Record<string, string> = {
  [UmsMessageField.EMAIL_UNVERIFIED]: 'Email is not verified',
  [UmsMessageField.GOOGLE_VERIFIED_ERROR]: 'Google is not verified',
  [UmsMessageField.LINKEDIN_VERIFIED_ERROR]: 'LinkedIn is not verified',
  [UmsMessageField.GET_PARCEL_ERROR]: 'Get parcel failed',
  [UmsMessageField.USER_NOT_FOUND]: 'User not found',
  [UmsMessageField.VERIFY_ERROR]: 'Verify failed',
  [UmsMessageField.TRANSLATE_LOCATION_ERROR]: 'Translate location failed',
  [UmsMessageField.SUBSCRIBE_SHIPMENT_ERROR]: 'Subscribe shipment failed',
  [UmsMessageField.UNSUBSCRIBE_SHIPMENT_ERROR]: 'Unsubscribe shipment failed',
  [UmsMessageField.SHIPMENT_NOT_FOUND]: 'Shipment not found',
  [UmsMessageField.UPDATE_SHIPMENT_ERROR]: 'Update shipment failed',
};

export const EmailLoginError: Record<string, LoginMethod> = {
  [UmsMessageField.GOOGLE_VERIFIED_ERROR]: LoginMethod.Google,
  [UmsMessageField.LINKEDIN_VERIFIED_ERROR]: LoginMethod.LinkedIn,
};

import styled from 'styled-components';
import { StyledTypography } from '@/components/Typography/Typography.styles';
import { ValuesInnerContainer } from '../../RegistrationWall.styles';

export const OuterWallContainer = styled.div`
  ${StyledTypography} {
    margin: 0;
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    padding: ${({ theme }) => theme.spacing['20']} 0;
  }
`;

export const WallContainer = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacing['32']};
  max-width: 520px;
  margin: 0 auto;
`;

export const HeaderContainer = styled.div`
  display: grid;
  width: 100%;
  text-align: center;
  gap: ${({ theme }) => theme.spacing['4']};

  & > svg {
    margin: 0 auto;
  }

  & > svg path {
    fill: ${({ theme }) => theme.colors.blue};
  }
`;

export const HeaderInnerContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  gap: ${({ theme }) => theme.spacing['10']};
  width: 100%;

  ${StyledTypography} {
    max-width: 280px;
  }
`;

export const CTAContainer = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacing['16']};

  ${StyledTypography} {
    text-align: center;
  }

  & > a, & > button {
    margin: 0 auto;
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    gap: ${({ theme }) => theme.spacing['24']};
  }
`;

export const ValuesContainer = styled.div`
  ${ValuesInnerContainer} {
    margin-bottom: ${({ theme }) => theme.spacing['16']};
  }

  ${ValuesInnerContainer}:last-child {
    margin-bottom: 0;
  }
`;

export const Line = styled.div`
  flex-grow: 1;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.mediumEmphasisBlack};
`;

export const ImageWrapper = styled.div({
  margin: '0 auto',
  position: 'relative',
});

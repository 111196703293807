import type { LanguageCode } from '@/interfaces';

export const getPmtTranslations = async (languageCode: LanguageCode) => {
  const fetchUrl = `https://assets.parcelperform.com/pp-web-app-assets/pmt-translations/${languageCode}.json`;
  const fetchOptions: RequestInit = { method: 'GET' };

  try {
    const response = await fetch(fetchUrl, fetchOptions);

    if (!response.ok) {
      return Promise.reject(`Response status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (e) {
    console.error(e);
    return new Error('getPmtTranslations failed');
  }
};

import { useState } from 'react';
import type { IOption } from '@/interfaces';
import { handleTextWithoutAccents } from '@/utils';
import { CountryOption, Select } from '@/components';

interface CountrySelectorProps {
  value: IOption;
  options: IOption[];
  onValueChanged: (value: IOption) => void;
  onMenuOpened?: () => void;
  onMenuClosed?: () => void;
}
const CountrySelector: React.FC<CountrySelectorProps> = ({
  value,
  options,
  onValueChanged,
  onMenuOpened,
  onMenuClosed,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState<IOption[]>(options);

  const handleOnValueChanged = (value: string) => {
    const constructedOptions = options.filter((option) =>
      handleTextWithoutAccents(option.key).toLowerCase().includes(handleTextWithoutAccents(value).toLowerCase()),
    );

    setFilteredOptions(constructedOptions);
  };

  const handleOnMenuOpened = () => {
    setOpen(true);

    if (onMenuOpened) {
      onMenuOpened();
    }
  };

  const handleOnMenuClosed = () => {
    setOpen(false);

    if (onMenuClosed) {
      onMenuClosed();
    }
  };

  return (
    <Select
      options={filteredOptions}
      onSelect={onValueChanged}
      onValueChanged={handleOnValueChanged}
      onMenuOpened={handleOnMenuOpened}
      onMenuClosed={handleOnMenuClosed}
      value={value.key}
      inputPrepend={
        isOpen ? undefined : <CountryOption onlyFlag code={value.value.country.countryCode} flagSize={24} />
      }
    />
  );
};

export default CountrySelector;

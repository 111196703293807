import type { ITypography } from '@/interfaces';
import { StyledTypography } from './Typography.styles';
import { forwardRef } from 'react';

/**
 * @param variant constants/TextStyles
 * @returns {React.FC} Returns correspending stylings and tag type
 */
const Typography: React.FC<ITypography> = forwardRef((props, ref) => {
  const { style, variant, className, children, type, ...restProps } = props;

  return (
    <StyledTypography style={style} type={type} variant={variant} className={className} ref={ref} {...restProps}>
      {children}
    </StyledTypography>
  );
});

export default Typography;

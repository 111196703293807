import { and, type QueryFieldFilterConstraint, type QueryCompositeFilterConstraint } from 'firebase/firestore';
import {
  SORT_OPTIONS,
  FILTER_OPTIONS,
  ALLOWED_EDD_FILTERS,
  EDD_DATE_RANGE_QUERY,
  EXCLUDE_DELETED_SHIPMENTS_QUERY,
  EDD_ALL_SHIPMENTS_QUERY,
  FILTER_OPTION_TO_WHERE_CLAUSE,
} from '@/modules/pmt/constants';

export const allowedSortByEDD = (filter: FILTER_OPTIONS, sort: SORT_OPTIONS): boolean => {
  if (sort !== SORT_OPTIONS.DELIVERY_DATE) return false;

  return ALLOWED_EDD_FILTERS.includes(filter);
};

export const excludeDeleted = (query: QueryFieldFilterConstraint | QueryCompositeFilterConstraint) => {
  return and(EXCLUDE_DELETED_SHIPMENTS_QUERY, query);
};

export const getEddShipmentsQuery = (filter: FILTER_OPTIONS) => {
  if (filter === FILTER_OPTIONS.ALL) {
    return and(EDD_ALL_SHIPMENTS_QUERY, EDD_DATE_RANGE_QUERY);
  }

  return and(FILTER_OPTION_TO_WHERE_CLAUSE[filter], EDD_DATE_RANGE_QUERY);
};

import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useRouter } from 'next/router';
import type { IHeader } from '@/interfaces';
import { gtmClickNavigationOpen } from '@/utils';
import {
  useGlobalState,
  useElementInView,
  useCalculateHeight,
  useElementTopOfView,
  usePreventBodyScroll,
} from '@/hooks';
import AccordionMenu from './AccordionMenu';
import {
  Divider,
  HamburgerButton,
  HamburgerButtonLine,
  CollapseNavigationWrapper,
  CollapseContainer,
} from './CollapseNavigation.styles';

import PMCSection from './PMCSection';
import Languages from '../Languages';

const CollapseNavigation: React.FC<IHeader> = (props) => {
  const { itemList, mainText, subText, button } = props;
  const router = useRouter();
  const { isMyParcelsPage } = useGlobalState();
  const [isExpand, setIsExpand] = useState<boolean>(false);
  const collapseWrapperRef = React.useRef<HTMLDivElement>(null);
  const isHeaderTopOfView = useElementTopOfView('header');
  const isAdTopOfView = useElementTopOfView('ads-top-of-page');
  const isAdInView = useElementInView('ads-top-of-page');
  const topEleHeights = useCalculateHeight(isAdInView && isAdTopOfView ? ['header', 'ads-top-of-page'] : ['header'], [
    isAdTopOfView,
    isAdInView,
  ]);
  usePreventBodyScroll(isExpand);

  useEffect(() => {
    !!isExpand && setIsExpand(false);
  }, [router]);

  const CollapseMenuElement = () => {
    let Collapse = <AccordionMenu data={itemList} isCollapseAll={!isExpand} />;

    if (isMyParcelsPage) {
      Collapse = (
        <>
          <Languages />
          <Divider />
          <PMCSection mainText={mainText} subText={subText} buttonText={button} />
        </>
      );
    }

    return <CollapseContainer topEleHeights={topEleHeights}>{Collapse}</CollapseContainer>;
  };

  const createCollapseMenuPortal = () => {
    return createPortal(CollapseMenuElement(), document.getElementById('sticky-collapse-menu') as Element);
  };

  const renderCollapseMenu = () => {
    if (!isHeaderTopOfView) {
      return CollapseMenuElement();
    }
    return createCollapseMenuPortal();
  };

  return (
    <CollapseNavigationWrapper ref={collapseWrapperRef} isMyParcelsPage={isMyParcelsPage}>
      <HamburgerButton
        onClick={() => {
          if (!isExpand && !isMyParcelsPage) {
            gtmClickNavigationOpen();
          }
          setIsExpand(!isExpand);
        }}
        isExpanded={isExpand}
      >
        <HamburgerButtonLine />
        <HamburgerButtonLine />
        <HamburgerButtonLine />
      </HamburgerButton>
      {isExpand && renderCollapseMenu()}
    </CollapseNavigationWrapper>
  );
};

export default CollapseNavigation;

import { getAuth, onAuthStateChanged, signInAnonymously } from 'firebase/auth';
import { useEffect, useState } from 'react';

const useAnonymousLogin = () => {
  // can't use ref here, this is used as reactive dependency for useEffect elsewhere
  const [authenticated, setAuthenticated] = useState(false);

  // we can't use result of signInAnonymously as authenticated state, because it's a one-time invocation
  // onAuthStatechanged watches out for clearing cookies, deleting storage etc <-- chosen approach
  useEffect(() => {
    const auth = getAuth();
    (async () => {
      try {
        await signInAnonymously(auth);
      } catch (err) {
        console.error('Could not authenticate Firebase:\n');
        console.error(err);
      }
    })();

    // the juice
    onAuthStateChanged(auth, (user) => setAuthenticated(!!user));
  }, []);

  return { authenticated };
};

export { useAnonymousLogin };

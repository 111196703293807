import { theme } from '@/constants';
import { type MainPaywallLayoutProps, ResponsiveCollection } from '@/interfaces';
import { useGlobalState, useHighlightString, useResponsive } from '@/hooks';
import { PaywallBackgroundContainer } from '../../RegistrationWall.styles';
import { Image, WallContainer, HighlightedTitle, MainPaywallContainer } from './MainPaywallLayout.styles';

const MainPaywallLayout: React.FC<MainPaywallLayoutProps> = (props) => {
  const {
    title,
    image,
    className,
    isInsideRT,
    mobileImage,
    hasNoOuterContainer = false,

    // components
    sectionHeaderComponent,
    subTitleComponent,
    subTextComponent,
    buttonComponent,
    wallContainerComponent,
  } = props;
  const highlightCss = {
    color: theme.colors.darkOrange,
    textDecoration: 'underline',
    textUnderlinePosition: 'under',
    textDecorationThickness: '3px',
  };

  const highlightedTitle = useHighlightString(title, highlightCss);
  const { getProperImageUrl } = useGlobalState();
  const { isDesktop } = useResponsive([ResponsiveCollection.Desktop]);

  const renderImage = () => {
    const canRenderDesktopImage = !!(isDesktop && image);
    const canRenderMobileImage = !!(!isDesktop && mobileImage);
    if (!canRenderDesktopImage && !canRenderMobileImage) return null;

    if (canRenderDesktopImage) {
      return <Image src={getProperImageUrl(image)} alt={image.description || 'Paywall Graphic'} />;
    }

    if (canRenderMobileImage) {
      return <Image src={getProperImageUrl(mobileImage)} alt={mobileImage.description || 'Paywall Graphic'} />;
    }
  };

  const renderElement = (element?: JSX.Element) => {
    if (!element) return null;
    return element;
  };

  const WallComponent = wallContainerComponent || WallContainer;
  const MainPaywallComponent = hasNoOuterContainer ? PaywallBackgroundContainer : MainPaywallContainer;

  return (
    <MainPaywallComponent className={className || ''} isInsideRT={isInsideRT}>
      <WallComponent>
        <div>
          {renderElement(sectionHeaderComponent)}
          <HighlightedTitle>{highlightedTitle}</HighlightedTitle>
          {renderElement(subTitleComponent)}
          {renderElement(subTextComponent)}
          {renderElement(buttonComponent)}
        </div>
        {renderImage()}
      </WallComponent>
    </MainPaywallComponent>
  );
};

export default MainPaywallLayout;

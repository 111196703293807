import type { ReactNode } from 'react';

import { renderHTML, styleToString } from '@/utils';

// ! For reference
// const highlightCss = {
//   color: '',
//   textDecoration: 'underline',
//   textUnderlinePosition: 'under',
//   textDecorationThickness: '0.15rem',
// }

/**
 * Renders string in Blue
 * @param string string to be highlighted
 * @param style Style Object to add custom styling
 * @returns {ReactNode} HTML of highlighted string
 */
const useHighlightString = (
  string: string | undefined,
  style: Record<string, unknown>,
  element?: string,
): ReactNode => {
  if (!string) {
    return '';
  }

  const newString = string.replace(/{{/g, `<span style='${styleToString(style)}'>`).replace(/}}/g, '</span>');

  return renderHTML(newString || '', element);
};

export default useHighlightString;

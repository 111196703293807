import Image from 'next/image';
import ReactCountryFlag from 'react-country-flag';
import styled from 'styled-components';
import HeaderSection from '../common/HeaderSection';
import { TitleWrapper } from '../SectionListRenderer.styles';

export const CountryFlag = styled(ReactCountryFlag)(({ theme }) => ({
  fontSize: '50px !important',
  lineHeight: '21px !important',
  marginRight: theme.spacing[8],
}));

export const Globe = styled(Image).attrs({
  width: 32,
  height: 32,
})({
  objectFit: 'contain',
});

export const StyledHeaderSection = styled(HeaderSection)`
  ${TitleWrapper} {
    margin-bottom: ${({ theme }) => theme.spacing[8]};
  }
`;

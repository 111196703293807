import NextImage from 'next/image';
import styled from 'styled-components';
import { TextStyles } from '@/constants';
import { Typography } from '@/components';

export const DropdownSecondaryMenuItemTitle = styled(Typography).attrs({
  variant: TextStyles['Overline'],
})`
  transition: .3s;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.highEmphasisBlack};
`;

export const SecondaryMenuContainer = styled.div`
  padding: ${({ theme }) => theme.spacing[12]};
  min-width: 224px;
`;

export const Image = styled(NextImage)({
  transition: '.3s',
  filter: 'brightness(0)',
});

export const DropdownSecondaryMenuItem = styled.div`
  height: 48px;
  padding: ${({ theme }) => theme.spacing[12]};
  display: flex;
  align-items: center;
  transition: .3s;

  :hover {
    ${DropdownSecondaryMenuItemTitle} {
      color: ${({ theme }) => theme.colors.blue};
    }
    ${Image} {
      filter: brightness(1);
    }
  }
`;

export const ImageWrapper = styled.div`
  width: 24px;
  height: 24px;
  padding: ${({ theme }) => theme.spacing[4]};
  margin-right: ${({ theme }) => theme.spacing[8]};
  background-color: ${({ theme }) => theme.colors.surface05};
  border-radius: 4px;
  flex: none;
  position: relative;
`;

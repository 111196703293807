import type { NotiButtonState } from '@/constants';
import type { Dispatch, SetStateAction } from 'react';
import type { CarrierDropdownState } from '../tracking';
import type { ICarrier, IShipmentData } from './shipment.interfaces';

export enum ParcelEventAnalyticsType {
  Regular = 'regular',
  Delay = 'delay',
  Exception = 'exception',
}

// in pp_shared_v2
export enum ParcelStatus {
  NotFound = 'not_found',
  Active = 'active',
  Exception = 'exception',
  Delayed = 'delayed',
  Delivered = 'delivered',
  Pending = 'pending',
}

// in pp-constants
export enum ShipmentStatus {
  Active = 'active',
  Inactive = 'inactive',
  Delivered = 'delivered',
  Pending = 'pending',
  Expired = 'expired',
  Return = 'return',
}

export enum ShipmentStage {
  Ordered = 'Ordered',
  Pending = 'Pending',
  Cancelled = 'Cancelled',
  InTransit = 'InTransit',
  ToCollect = 'ToCollect',
  Collected = 'Collected',
  Error = 'Error',
}

export enum EventPhaseKey {
  OrderEvents = 'A',
  FulfilmentEvents = 'B',
  PreAlert = 'C',
  Pickup = 'D',
  Transit = 'E',
  Customs = 'F',
  OutForDelivery = 'G',
  Delivery = 'H',
  ReturnToSender = 'I',
  Others = 'X',
  Internals = 'Y',
}

export enum ShipmentSearchStatus {
  Timeout = 'timeout',
  Pending = 'pending',
  Fetched = 'fetched',
  ScrapingWithCarrier = 'scraping_with_carrier',
}

export interface IShipment {
  isDeleting?: boolean;
  data: IShipmentData;
}

export interface ITrackingEventDateOptions {
  showDate: boolean;
  showTime: boolean;
  fullDay: boolean;
  showDay?: boolean;
  isFormatTimeUnderOneDay?: boolean;
}

export interface ITrackingInput {
  chips: string[];
  setChips: Dispatch<SetStateAction<string[]>>;
  maxChip?: number;
  clickedSubmitButton?: boolean;
  isDisabled: boolean;
  onChange: Dispatch<SetStateAction<string>>;
}

export interface ICarrierByParcel {
  [parcelId: string]: {
    [key in CarrierDropdownState]: ICarrier;
  };
}

export interface INotiButtonStateByParcel {
  [parcelId: string]: {
    [carrierId: string]: NotiButtonState;
  };
}

export enum SyncParcelsStatus {
  PENDING = 'pending',
  SUCCESS = 'success',
}

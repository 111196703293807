import { CustomEventName, type DelayTime, type ParcelSearchesSource } from '@/constants';
import type { LoginMethod } from '@/interfaces';
import type { GtmClickFilter, GtmClickParcel, PmtOnboardingPopupCta } from '@/interfaces/tracking';
import type { GtmClickSort } from '@/interfaces/tracking/gtm.interfaces';
import { getFullUrlProps, isInternalPmcLink, resetDataLayerAndPushEvent } from '..';

export const gtmClickCourierSite = (linkUrl: string) => {
  resetDataLayerAndPushEvent(CustomEventName.ClickCourierSite, { linkUrl });
};

export const gtmImmediateFound = (isExisting: boolean) => {
  resetDataLayerAndPushEvent(CustomEventName.ImmediateFound, { isExisting });
};

export const gtmImmediateNotFound = (isExisting: boolean) => {
  resetDataLayerAndPushEvent(CustomEventName.ImmediateNotFound, { isExisting });
};

export const gtmClickPmc = (linkUrl: string) => {
  const { url } = getFullUrlProps(linkUrl);
  isInternalPmcLink(linkUrl) && resetDataLayerAndPushEvent(CustomEventName.ClickPmc, { linkUrl: url });
};

export const gtmParcelSearches = (parcelQty: number, source: ParcelSearchesSource) => {
  resetDataLayerAndPushEvent(CustomEventName.ParcelSearches, {
    parcelQty,
    pmtSource: source,
  });
};

export const gtmInvalidParcelSearches = () => {
  resetDataLayerAndPushEvent(CustomEventName.InvalidParcelSearches);
};

export const gtmUserSuggest = (carrier: string) => {
  resetDataLayerAndPushEvent(CustomEventName.UserSuggest, { carrier });
};

export const gtmUserSuggestFound = (carrier: string) => {
  resetDataLayerAndPushEvent(CustomEventName.UserSuggestFound, { carrier });
};

export const gtmClickParcel: GtmClickParcel = ({ stage, hasIssue }) => {
  resetDataLayerAndPushEvent(CustomEventName.ClickParcel, { stage, hasIssue });
};

export const gtmClickRemoveParcel = () => {
  resetDataLayerAndPushEvent(CustomEventName.ClickRemoveParcel);
};

export const gtmRemoveParcelConfirm = () => {
  resetDataLayerAndPushEvent(CustomEventName.RemoveParcelConfirm);
};

export const gtmClickPlus = () => {
  resetDataLayerAndPushEvent(CustomEventName.ClickPlus);
};

export const gtmClickFilter: GtmClickFilter = ({ filter, parcelQty }) => {
  resetDataLayerAndPushEvent(CustomEventName.ClickFilter, {
    filter,
    parcelQty,
  });
};

export const gtmClickSort: GtmClickSort = ({ sort, parcelQty }) => {
  resetDataLayerAndPushEvent(CustomEventName.ClickSort, {
    sort,
    parcelQty,
  });
};

export const gtmViewParcel = () => {
  /** Desktop
  - #1 When Parcel Individual View first auto shows up if have parcels in parcel list
  - #2 When Parcel Individual View’s data is updated when user searches for new parcels
  - #3 When Parcel Individual View’s data is updated when user deletes a parcel in the parcel list,
    and there is at least 1 parcel left in the parcel list after current parcel is deleted
  - From AMP: Desktop #1
   */
  // Mobile #1: When Parcel Individual View shows up after a Parcel card click
  resetDataLayerAndPushEvent(CustomEventName.ViewParcel);
};

type GtmPopupArgs = (args: {
  authenticationMethod?: LoginMethod;
  click?: boolean;
  delayTime?: DelayTime;
  variant?: string;
  popupVariant?: string;
  cta?: PmtOnboardingPopupCta;
}) => void;

export const gtmViewJoinUs: GtmPopupArgs = ({ click, delayTime, variant, popupVariant, cta }) => {
  const obj = {
    click,
    delayTime: click ? undefined : delayTime,
    variant,
    popupVariant,
    cta,
  };
  resetDataLayerAndPushEvent(CustomEventName.ViewJoinUs, obj);
};

export const gtmClickJoinUs: GtmPopupArgs = ({
  authenticationMethod,
  click,
  delayTime,
  cta,
  variant,
  popupVariant,
}) => {
  const obj = {
    authenticationMethod,
    click,
    delayTime: click ? undefined : delayTime,
    cta,
    variant,
    popupVariant,
  };
  resetDataLayerAndPushEvent(CustomEventName.ClickJoinUs, obj);
};

export const gtmSignUpSuccess: GtmPopupArgs = ({
  cta,
  click,
  variant,
  popupVariant,
  delayTime,
  authenticationMethod,
}) => {
  const obj = {
    authenticationMethod,
    click,
    delayTime: click ? undefined : delayTime,
    cta,
    variant,
    popupVariant,
  };
  resetDataLayerAndPushEvent(CustomEventName.SignUpSuccess, obj);
};

export const gtmClickUnsubAllConfirm = () => {
  resetDataLayerAndPushEvent(CustomEventName.ClickUnsubAllConfirm);
};

type GtmViewGrantAccessPopup = (arg: { variant?: string }) => void;
// https://www.pivotaltracker.com/story/show/187112079
export const gtmViewGrantAccessPopup: GtmViewGrantAccessPopup = ({ variant }) => {
  resetDataLayerAndPushEvent(CustomEventName.ViewGrantAccessPopup, {
    variant,
  });
};

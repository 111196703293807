import type { ContentTypeName } from '@/constants';
import type { ISEOFields, IRightPanel, SectionThemeKey, IRichText } from '@/interfaces';

export enum RegionType {
  NotSelected = 'Not selected',
  Americas = 'Americas',
  Asia = 'Asia',
  Europe = 'Europe',
  Oceania = 'Oceania',
  Africa = 'Africa',
}

export enum WorldMapRegionCode {
  NorthAmerica = 'na',
  SouthAmerica = 'sa',
  Europe = 'eu',
  Asia = 'as',
  Oceania = 'oc',
  Africa = 'af',
}

export const REGION_CODE_MAPPING: {
  [key in string]?: WorldMapRegionCode[];
} = {
  [RegionType.Americas]: [WorldMapRegionCode.NorthAmerica, WorldMapRegionCode.SouthAmerica],
  [RegionType.Asia]: [WorldMapRegionCode.Asia],
  [RegionType.Europe]: [WorldMapRegionCode.Europe],
  [RegionType.Oceania]: [WorldMapRegionCode.Oceania],
  [RegionType.Africa]: [WorldMapRegionCode.Africa],
};

export interface IMarket {
  sys: {
    id: string;
    publishedAt?: Date;
  };
  __typename: ContentTypeName;
  slug: string;
  breadcrumbsTheme?: SectionThemeKey;
  marketName: string;
  countryCode?: string;
  subTitle?: string;
  seoDescription?: IRichText;
  region: RegionType;
  sectionList?: any[];
  seo?: ISEOFields;
  rightSectionList: Array<IRightPanel>;
}

export enum IpInfoEnv {
  Prod = 'Prod',
  NonProd = 'NonProd',
}

export const IP_INFO_TOKENS: {
  [key in IpInfoEnv]: string[];
} = {
  [IpInfoEnv.Prod]: [
    '285b6b71d7fe6c',
    'b6432f18fcd560',
    '23722a5721f3ff',
    '51ce7bad780913',
    '288642f6f467f8',
    'ad34dec4db9a77',
    '166fa59d1df931',
    '4a6b46ebea1316',
    '9286f1c84bdd29',
    'f797b107be0a60',
    'f133a15436dc64',
    '628061e3087c9f',
    'bf9e59a3668349',
    'f72f6d8fa3b0ca',
    'a64cb86a90c2c6',
  ],
  [IpInfoEnv.NonProd]: ['4b76c83f9fb8e6', '1ef633787e01f6', '66940a536d3b78'],
};

import getConfig from 'next/config';

const { serverRuntimeConfig } = getConfig();

export const BASE_URL = serverRuntimeConfig.authenticatorUrl;

type GetAuthenticatorAccessTokenResponse = {
  data: {
    access_token: string;
  };
};

type GetAuthenticatorAccessToken = () => Promise<string | undefined>;

export const getAuthenticatorAccessToken: GetAuthenticatorAccessToken = async () => {
  const fetchUrl = `${BASE_URL}/api/authentication/authenticate-service/`;
  const fetchOptions: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      client_id: serverRuntimeConfig.authenticatorClientId,
      client_secret: serverRuntimeConfig.authenticatorClientSecret,
    }),
  };

  try {
    const res = await fetch(fetchUrl, fetchOptions);
    const { data }: GetAuthenticatorAccessTokenResponse = await res.json();

    return data.access_token;
  } catch (error: any) {
    console.error('/api/authentication/authenticate-service/ ', error);
  }
};

import styled from 'styled-components';
import type { ITypography } from '@/interfaces';
import { theme, TextStyles } from '@/constants';
import { getTextLevel } from '@/utils';

export const styledText = {
  [TextStyles['Heading 1']]: `
    font-style: normal;
    font-weight: ${theme.fontWeight.semiBold};
    font-size: ${theme.fontSizes[24]};
    line-height: ${theme.lineHeight[36]};
  `,
  [TextStyles['Heading 2']]: `
    font-style: normal;
    font-weight: ${theme.fontWeight.semiBold};
    font-size: ${theme.fontSizes[18]};
    line-height: ${theme.lineHeight[26]};
  `,
  [TextStyles['Heading 3']]: `
    font-style: normal;
    font-weight: ${theme.fontWeight.semiBold};
    font-size: ${theme.fontSizes[16]};
    line-height: ${theme.lineHeight[24]};
  `,
  [TextStyles['Heading 4']]: `
    font-style: normal;
    font-weight: ${theme.fontWeight.semiBold};
    font-size: ${theme.fontSizes[14]};
    line-height: ${theme.lineHeight[20]};
  `,
  [TextStyles['Heading 5']]: `
    font-style: normal;
    font-weight: ${theme.fontWeight.medium};
    font-size: ${theme.fontSizes[14]};
    line-height: ${theme.lineHeight[20]};
  `,
  [TextStyles['Heading 6']]: `
    font-style: normal;
    font-weight: ${theme.fontWeight.medium};
    font-size: ${theme.fontSizes[12]};
    line-height: ${theme.lineHeight[20]};
  `,
  [TextStyles['Button']]: `
    font-style: normal;
    font-weight: ${theme.fontWeight.semiBold};
    font-size: ${theme.fontSizes[16]};
    line-height: ${theme.lineHeight[16]};
    letter-spacing: ${theme.letterSpacing[75]};
  `,
  [TextStyles['Subheading']]: `
    font-style: normal;
    font-weight: ${theme.fontWeight.regular};
    font-size: ${theme.fontSizes[20]};
    line-height: ${theme.lineHeight[30]};
  `,
  [TextStyles['Large Text Display']]: `
    font-style: normal;
    font-weight: ${theme.fontWeight.semiBold};
    font-size: ${theme.fontSizes[32]};
    line-height: ${theme.lineHeight[48]};
  `,
  [TextStyles['Body Text Large']]: `
    font-style: normal;
    font-weight: ${theme.fontWeight.regular};
    font-size: ${theme.fontSizes[16]};
    line-height: ${theme.lineHeight[24]};
  `,
  [TextStyles['Body Text Small']]: `
    font-style: normal;
    font-weight: ${theme.fontWeight.regular};
    font-size: ${theme.fontSizes[14]};
    line-height: ${theme.lineHeight[22]};
  `,
  [TextStyles['Overline']]: `
    font-style: normal;
    font-weight: ${theme.fontWeight.semiBold};
    font-size: ${theme.fontSizes[12]};
    line-height: ${theme.lineHeight[18]};
    letter-spacing: ${theme.letterSpacing[2]};
    text-transform: uppercase;
  `,
  [TextStyles['Nav Item Text']]: `
    font-style: normal;
    font-weight: ${theme.fontWeight.medium};
    font-size: ${theme.fontSizes[14]};
    line-height: ${theme.lineHeight[20]};
    letter-spacing: ${theme.letterSpacing[5]};
  `,
  [TextStyles['Category List']]: `
    font-style: normal;
    font-weight: ${theme.fontWeight.medium};
    font-size: ${theme.fontSizes[14]};
    line-height: ${theme.lineHeight[20]};
  `,
  [TextStyles['Caption Text']]: `
    font-style: normal;
    font-weight: ${theme.fontWeight.regular};
    font-size: ${theme.fontSizes[12]};
    line-height: ${theme.lineHeight[20]};
  `,
};

export const styledTextAsObj = {
  [TextStyles['Heading 1']]: {
    fontStyle: 'normal',
    fontWeight: theme.fontWeight.semiBold,
    fontSize: theme.fontSizes[24],
    lineHeight: theme.lineHeight[36],
  },
  [TextStyles['Heading 2']]: {
    fontStyle: 'normal',
    fontWeight: theme.fontWeight.semiBold,
    fontSize: theme.fontSizes[18],
    lineHeight: theme.lineHeight[26],
  },
  [TextStyles['Heading 3']]: {
    fontStyle: 'normal',
    fontWeight: theme.fontWeight.semiBold,
    fontSize: theme.fontSizes[16],
    lineHeight: theme.lineHeight[24],
  },
  [TextStyles['Heading 4']]: {
    fontStyle: 'normal',
    fontWeight: theme.fontWeight.semiBold,
    fontSize: theme.fontSizes[14],
    lineHeight: theme.lineHeight[20],
  },
  [TextStyles['Heading 5']]: {
    fontStyle: 'normal',
    fontWeight: theme.fontWeight.medium,
    fontSize: theme.fontSizes[14],
    lineHeight: theme.lineHeight[20],
  },
  [TextStyles['Heading 6']]: {
    fontStyle: 'normal',
    fontWeight: theme.fontWeight.medium,
    fontSize: theme.fontSizes[12],
    lineHeight: theme.lineHeight[20],
  },
  [TextStyles['Button']]: {
    fontStyle: 'normal',
    fontWeight: theme.fontWeight.semiBold,
    fontSize: theme.fontSizes[16],
    lineHeight: theme.lineHeight[16],
    letterSpacing: theme.letterSpacing[75],
  },
  [TextStyles['Subheading']]: {
    fontStyle: 'normal',
    fontWeight: theme.fontWeight.regular,
    fontSize: theme.fontSizes[20],
    lineHeight: theme.lineHeight[30],
  },
  [TextStyles['Large Text Display']]: {
    fontStyle: 'normal',
    fontWeight: theme.fontWeight.semiBold,
    fontSize: theme.fontSizes[32],
    lineHeight: theme.lineHeight[48],
  },
  [TextStyles['Body Text Large']]: {
    fontStyle: 'normal',
    fontWeight: theme.fontWeight.regular,
    fontSize: theme.fontSizes[16],
    lineHeight: theme.lineHeight[24],
  },
  [TextStyles['Body Text Small']]: {
    fontStyle: 'normal',
    fontWeight: theme.fontWeight.regular,
    fontSize: theme.fontSizes[14],
    lineHeight: theme.lineHeight[22],
  },
  [TextStyles['Overline']]: {
    fontStyle: 'normal',
    fontWeight: theme.fontWeight.semiBold,
    fontSize: theme.fontSizes[12],
    lineHeight: theme.lineHeight[18],
    letterSpacing: theme.letterSpacing[2],
    textTransform: 'uppercase',
  },
  [TextStyles['Nav Item Text']]: {
    fontStyle: 'normal',
    fontWeight: theme.fontWeight.medium,
    fontSize: theme.fontSizes[14],
    lineHeight: theme.lineHeight[20],
    letterSpacing: theme.letterSpacing[5],
  },
  [TextStyles['Category List']]: {
    fontStyle: 'normal',
    fontWeight: theme.fontWeight.medium,
    fontSize: theme.fontSizes[14],
    lineHeight: theme.lineHeight[20],
  },
  [TextStyles['Caption Text']]: {
    fontStyle: 'normal',
    fontWeight: theme.fontWeight.regular,
    fontSize: theme.fontSizes[12],
    lineHeight: theme.lineHeight[20],
  },
};

export const StyledTypography = styled.p
  .withConfig({
    shouldForwardProp: (propName, defaultValidatorFn) => propName.toString() !== 'type' && defaultValidatorFn(propName),
  })
  .attrs<ITypography>((props) => ({
    as: props.type || getTextLevel(props.variant),
  }))<ITypography>`
  margin: 0;
  ${(props) => styledText[props.variant]}
`;

import styled from 'styled-components';
import { Button } from '@/components';
import { ButtonSize, ButtonVariant } from '@/interfaces';

export const LoadMoreButton = styled(Button).attrs({
  variant: ButtonVariant.Secondary,
  size: ButtonSize.Large,
})<{ isDesktop: boolean }>`
  width: 100%;
  text-align: center;
  margin-top: ${({ theme }) => theme.spacing[16]};
  display: ${({ isDesktop }) => (isDesktop ? 'none' : 'block')};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: ${({ isDesktop }) => (isDesktop ? 'block' : 'none')};
    margin-top: 0;
    margin-bottom: ${({ theme }) => theme.spacing[24]};
  }
`;

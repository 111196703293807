import { useState } from 'react';
import { Input, InputContainer, ToggleSpan } from './Toggle.styles';

interface IToggle {
  onClick: (isToggled: boolean) => void;
  toggled?: boolean;
  disabled?: boolean;
}

const Toggle = ({ onClick, toggled = false, disabled = false }: IToggle) => {
  const [isToggled, toggle] = useState(toggled);

  const callback = () => {
    !disabled && toggle(!isToggled);
    onClick(!isToggled);
  };

  return (
    <InputContainer>
      <Input type='checkbox' onChange={callback} checked={isToggled} />
      <ToggleSpan />
    </InputContainer>
  );
};

export default Toggle;

import { PmtOnboardingPopupTrigger } from '@/modules/pmt/interfaces/tracking/trackingOnboardingPopup.interfaces';
import { useCallback, useEffect, useState } from 'react';
import { useTriggerAfterFirstParcelLoads } from './useTriggerAfterFirstParcelLoads.hook';
import { useTriggerWhenFooterIsInView } from './useTriggerWhenFooterIsInView.hook';

export const useTriggerPopup = (trigger?: PmtOnboardingPopupTrigger) => {
  const [isTimerTriggered, setIsTimerTriggered] = useState(false);

  useEffect(() => {
    // If "trigger" is empty, "isTimerTriggered" will be true
    setIsTimerTriggered(!trigger);
  }, [trigger]);

  const triggerAfterFirstParcelLoads = useCallback(() => {
    if (trigger === PmtOnboardingPopupTrigger.AfterFirstParcelLoads) {
      // biome-ignore lint/suspicious/noConsoleLog: <explanation>
      console.log('🚀 trigger:', trigger);
      setIsTimerTriggered(true);
    }
  }, [trigger]);

  const triggerWhenFooterIsInView = useCallback(() => {
    if (trigger === PmtOnboardingPopupTrigger.UserScrollsToTheBottomOfThePage) {
      // biome-ignore lint/suspicious/noConsoleLog: <explanation>
      console.log('🚀 trigger:', trigger);
      setIsTimerTriggered(true);
    }
  }, [trigger]);

  useTriggerAfterFirstParcelLoads(triggerAfterFirstParcelLoads);
  useTriggerWhenFooterIsInView(triggerWhenFooterIsInView);

  return isTimerTriggered;
};

import dynamic from 'next/dynamic';
import { DataPickerUiTemplate, WrapperEntryName } from '@/interfaces';

export const WrapperEntryMapping: {
  [key in WrapperEntryName]: React.ComponentType<any>;
} = {
  [WrapperEntryName.Company]: dynamic(() => import('../components/Wrapper/CompanyLogos')),
  [WrapperEntryName.People]: dynamic(() => import('../components/Wrapper/PeopleWithQuotes')),
  [WrapperEntryName.ContentItem]: dynamic(() => import('../components/Wrapper/ContentItems')),
  [WrapperEntryName.ContentCard]: dynamic(() => import('../components/Wrapper/ContentCard')),
  [WrapperEntryName.Market]: dynamic(() => import('../components/Wrapper/RegionWorldMap')),
  [WrapperEntryName.ThumbnailSection]: dynamic(() => import('../components/ThumbnailSection')),
};

export const WrapperDataPickerMapping: {
  [key in DataPickerUiTemplate]: React.ComponentType<any>;
} = {
  [DataPickerUiTemplate.Table]: dynamic(() => import('../components/Wrapper/DataPickerSection/TableSection')),
  [DataPickerUiTemplate.Columns]: dynamic(() => import('../components/Wrapper/DataPickerSection/ColumnsSection')),
};

import { useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { Thumbnail, Button } from '@/components';
import MostRead from './MostRead';
import { ThumbnailSectionContainer, ThumbnailSectionWrapper, ThumbnailGridContainer } from './LoadMoreSection.styles';

import { ButtonSize, ButtonVariant, type IThumbnailSection, SectionItemType, type ThumbnailProps } from '@/interfaces';

import { checkIsPreviewModeRequest, replacePath } from '@/utils';

import { ACCEPTED_ARTICLE_TYPES, ArticleTypeName, PageType, RouteName } from '@/constants';

import { getAllEventThumbnails, getAllThumbnails } from '@/fetchers';
import { callPublicContentful } from '@/fetchers/contentfulAPI/base.fetchers';
import { utils_getLandingPageThumbnailSectionData, utils_getTagPageThumbnailSectionData } from '@/fetchers/utils';

import { useSecondEffect } from '@/hooks';

const dataFilterFunctionMapping: {
  [key in PageType]: (args: any) => ThumbnailProps[];
} = {
  [PageType.LandingPage]: utils_getLandingPageThumbnailSectionData,
  [PageType.TagPage]: utils_getTagPageThumbnailSectionData,
  [PageType.MarketPage]: utils_getTagPageThumbnailSectionData,
};

const FIRST_THUMBNAIL_COUNT = 4;

const LoadMoreSection: React.FC<IThumbnailSection> = (props) => {
  const {
    mostRead,
    latestData,
    foundHighlightSection,
    foundEventListSection,
    pageType,
    pageInfo,
    tagDataType,
    tagDataSlug,
    itemType,
    featuredEventList,
  } = props;
  const { asPath } = useRouter();
  const filteredLatestData = latestData && [...latestData].filter(Boolean);
  const firstThumbnailList = filteredLatestData?.slice(0, FIRST_THUMBNAIL_COUNT);

  const [secondThumbnailList, setSecondThumbnailList] = useState(() => {
    return filteredLatestData?.slice(FIRST_THUMBNAIL_COUNT);
  });

  // allThumbnailList is only available after first click on 'LOAD MORE'
  const [allThumbnailList, setAllThumbnailList] = useState<ThumbnailProps[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isFetching, setIsFetching] = useState(false);
  const { query } = useRouter();

  const fetchAndSortAllThumbnailList = async () => {
    setIsFetching(true);
    const isPreviewMode = checkIsPreviewModeRequest(query);

    const dataFilterFunction = dataFilterFunctionMapping[pageType];

    const allThumbnailList =
      itemType === SectionItemType.Article
        ? await getAllThumbnails({
            isPreviewMode,
            fetcher: callPublicContentful,
            slug: asPath?.split('?')[0],
          })
        : [];

    const allEventList =
      itemType === SectionItemType.Event
        ? await getAllEventThumbnails({
            isPreviewMode,
            fetcher: callPublicContentful,
            slug: asPath?.split('?')[0],
          })
        : [];

    let filteredThumbnailList = dataFilterFunction({
      foundThumbnailSection: props,
      foundHighlightSection,
      allThumbnailList,
      allEventList,
      tagDataType,
      tagDataSlug,
    }).filter(Boolean);

    if (foundEventListSection && !foundEventListSection.featured) {
      filteredThumbnailList = filteredThumbnailList.filter(
        (thumbnailItem) =>
          !foundEventListSection?.eventList.find(
            (featuredEventItem) => featuredEventItem.sys.id === thumbnailItem.sys.id,
          ),
      );
    }

    if (featuredEventList) {
      filteredThumbnailList = filteredThumbnailList.filter(
        (thumbnailItem) =>
          !featuredEventList?.find((featuredEventItem) => featuredEventItem.sys.id === thumbnailItem.sys.id),
      );
    }

    setAllThumbnailList(filteredThumbnailList);
    setIsFetching(false);
  };

  // Fetch allThumbnailList when currentPage === 2
  useSecondEffect(() => {
    if (currentPage !== 2) return;

    fetchAndSortAllThumbnailList();
  }, [currentPage]);

  // Chunk allThumbnailList according to currentPage
  useSecondEffect(() => {
    if (currentPage < 2 || !allThumbnailList) return;

    setSecondThumbnailList((prevSecondThumbnailList) => {
      const startIndex = (currentPage - 1) * pageInfo.itemsPerPage;
      const endIndex = startIndex + pageInfo.itemsPerPage;

      return [...prevSecondThumbnailList, ...allThumbnailList.slice(startIndex, endIndex)];
    });
  }, [currentPage, allThumbnailList]);

  if (!latestData || latestData.length === 0) return null;

  const hasNextPage = currentPage < pageInfo.totalPages;

  const handleLoadMoreButtonClick = async () => {
    if (!hasNextPage) return;

    setCurrentPage((prevCurrentPage) => prevCurrentPage + 1);
  };

  return (
    <ThumbnailSectionContainer>
      <ThumbnailSectionWrapper>
        {/* Start section with 4 articles */}
        <ThumbnailGridContainer>
          {firstThumbnailList?.map((thumbnailData: ThumbnailProps, index: number) => {
            const { articleType, slug } = thumbnailData;
            const href = replacePath(RouteName.Article, [
              ACCEPTED_ARTICLE_TYPES[articleType ?? ArticleTypeName.events],
              slug,
            ]);
            return (
              <Link key={index} href={href} passHref>
                <Thumbnail {...thumbnailData} href={href} />
              </Link>
            );
          })}
        </ThumbnailGridContainer>

        {mostRead && <MostRead />}

        {/* End section */}
        <ThumbnailGridContainer>
          {secondThumbnailList?.map((thumbnailData, index) => {
            const { articleType, slug } = thumbnailData;
            const href = replacePath(RouteName.Article, [
              ACCEPTED_ARTICLE_TYPES[articleType ?? ArticleTypeName.events],
              slug,
            ]);
            return (
              <Link key={index} href={href} passHref>
                <Thumbnail {...thumbnailData} href={href} />
              </Link>
            );
          })}
        </ThumbnailGridContainer>
      </ThumbnailSectionWrapper>
      <Button
        variant={ButtonVariant.Secondary}
        size={ButtonSize.Large}
        disabled={!hasNextPage || isFetching}
        onClick={handleLoadMoreButtonClick}
      >
        LOAD MORE
      </Button>
    </ThumbnailSectionContainer>
  );
};

export default LoadMoreSection;

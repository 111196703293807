// This file is for the use of data mapping of Contentful's raw data
export enum Locale {
  EnglishUnitedStates = 'en-US',
  Spanish = 'es',
  Germany = 'de',
  Japanese = 'ja',
  French = 'fr',
  Chinese = 'zh',
  Italian = 'it',
}

export enum LanguageCode {
  English = 'en',
  SimplifiedChinese = 'zh-cn',
  TraditionalChinese = 'zh-tw',
  Russian = 'ru',
  Hindi = 'hi',
  Japanese = 'ja',
  Korean = 'ko',
  Thai = 'th',
  Indonesian = 'id',
  Malay = 'ms',
  Tamil = 'ta',
  Vietnamese = 'vi',
  German = 'de',
  French = 'fr',
  Spanish = 'es',
  Italian = 'it',
  Portuguese = 'pt',
  Croatia = 'hr',
  Czech = 'cs',
  Danish = 'da',
  Dutch = 'nl',
  Finland = 'fi',
  Greek = 'el',
  Hungarian = 'hu',
  Norwegian = 'nb',
  Polish = 'pl',
  Romania = 'ro',
  Serbian = 'sr',
  Swedish = 'sv',
  Turkish = 'tr',
  Slovenian = 'sl',
  Slovak = 'sk',
}

export interface ILanguage {
  code: LanguageCode;
  name: string;
  englishName: string;
}

import { useState } from 'react';
import Image from 'next/image';
import Typography from '../Typography';
import { TextStyles } from '@/constants';

interface LetterLogoProps {
  text: string;
}

const LetterLogo = ({ text }: LetterLogoProps) => {
  const firstLetter = text.slice(0, 1);
  return <Typography variant={TextStyles['Heading 1']}>{firstLetter}</Typography>;
};

interface PMCLogoProps {
  src?: string | null;
  alt?: string;
  placeholderText?: string;
  [key: string]: any;
}

const PMCLogo = ({ src, alt, placeholderText, ...rest }: PMCLogoProps) => {
  const [isImageBroken, setIsImageBroken] = useState(false);

  if (src && !isImageBroken) {
    return <Image src={src} alt={alt || 'logo'} onError={() => setIsImageBroken(true)} {...rest} />;
  }

  return <LetterLogo text={placeholderText || ''} />;
};

export default PMCLogo;

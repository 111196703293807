import type { ILeadsWrapper } from '@/interfaces';
import { useGlobalState, useHighlightString } from '@/hooks';
import { theme } from '@/constants';
import useLeadsForm from './useLeadsForm';
import Form from './Form';
import {
  FormWrapper,
  MobileTitle,
  DesktopTitle,
  SectionWrapper,
  LeftSectionWrapper,
  RightSectionWrapper,
  FullSectionContainer,
  LeadsWrapperContainer,
} from './LeadsWrapper.styles';
import ValuePropositionList from './ValuePropositionList';
import CustomerLogoSection from './CustomerLogoSection/CustomerLogoSection';

const LeadsWrapper: React.FC<ILeadsWrapper> = ({
  pageTitle,
  title,
  theme: sectionTheme,
  backgroundImage,
  form,
  valuePropositionList,
  customerLogoTitle,
  customerLogoList,
  zapierHookId,
}) => {
  if (!form) return null;

  // TODO: Change other highlightCss to HIGHLIGHT_STYLES
  const HIGHLIGHT_STYLES = {
    color: theme.colors.darkOrange,
  };

  const highlightedTitle = useHighlightString(title, HIGHLIGHT_STYLES);
  const { isLoggedIn } = useGlobalState();
  const { modifiedFormFieldList, handleSubmit, handleFocus, leadsWrapperRef } = useLeadsForm({
    formFieldList: form?.formFieldList,
    zapierHookId,
    pageTitle, // passing specific argument down to a common component might be lead to complex and difficult to maintain scenario when the component is getting bigger
  });

  const LeftSection: React.FC = () => (
    <LeftSectionWrapper>
      <DesktopTitle>{highlightedTitle}</DesktopTitle>
      <ValuePropositionList theme={sectionTheme} items={valuePropositionList} />
    </LeftSectionWrapper>
  );

  const RightSection: React.FC = () => (
    <RightSectionWrapper>
      <MobileTitle>{highlightedTitle}</MobileTitle>
      <FormWrapper>
        <Form
          {...form}
          isLoggedIn={isLoggedIn}
          onFocus={handleFocus}
          onSubmit={handleSubmit}
          formFieldList={modifiedFormFieldList}
        />
      </FormWrapper>
    </RightSectionWrapper>
  );

  return (
    <LeadsWrapperContainer
      id='form'
      ref={leadsWrapperRef}
      backgroundImage={backgroundImage?.url}
      sectionTheme={sectionTheme}
    >
      <FullSectionContainer>
        <SectionWrapper>
          <LeftSection />
          <RightSection />
        </SectionWrapper>
        <CustomerLogoSection title={customerLogoTitle} items={customerLogoList} theme={sectionTheme} />
      </FullSectionContainer>
    </LeadsWrapperContainer>
  );
};

export default LeadsWrapper;

import type React from 'react';
import { useRef } from 'react';
import getConfig from 'next/config';
import Script from 'next/script';

const { publicRuntimeConfig } = getConfig();

const LINKEDIN_FOLLOW_SCRIPT_CLASS = 'linkedin-follow-script';

const LinkedInFollowBtn: React.FC = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  return (
    <div ref={wrapperRef}>
      {/* https://nextjs.org/docs/pages/building-your-application/upgrading/app-router-migration#script-component */}
      <Script
        id='linkedin-follow-script-id'
        async
        className={LINKEDIN_FOLLOW_SCRIPT_CLASS}
        src='https://platform.linkedin.com/in.js'
        type='text/javascript'
        dangerouslySetInnerHTML={{
          __html: `
            lang: en_US
         `,
        }}
        onLoad={() => {
          // The LinkedIn SDK script has loaded, now we can add the follow button script
          const script = document.createElement('script');
          script.type = 'IN/FollowCompany';
          script.setAttribute('data-id', publicRuntimeConfig.linkedInCompanyID);
          wrapperRef.current?.appendChild(script);
        }}
      />
    </div>
  );
};

export default LinkedInFollowBtn;

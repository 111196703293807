import type { Asset, IRichText } from '@/interfaces';
import { Title, PageIcon, PageHeader, IconWrapper, TitleWrapper } from '../../SectionListRenderer.styles';
import Description from '../Description';
import type { HTMLAttributes } from 'react';

interface HeaderSectionProps {
  title?: string;
  icon?: Asset;
  iconComponent?: () => JSX.Element;
  description?: string | IRichText;
}
const HeaderSection: React.FC<HeaderSectionProps & HTMLAttributes<HTMLDivElement>> = ({
  title,
  icon,
  iconComponent,
  description,
  ...rest
}) => {
  const renderIcon = () => {
    if (iconComponent) {
      const Icon = iconComponent;
      return (
        <IconWrapper>
          <Icon />
        </IconWrapper>
      );
    }

    if (icon && icon?.url && icon?.title) {
      return <PageIcon src={icon?.url} alt={icon?.title} />;
    }

    return null;
  };

  return (
    <PageHeader {...rest}>
      {title && (
        <TitleWrapper>
          {renderIcon()}
          <Title>{title}</Title>
        </TitleWrapper>
      )}

      {description && (
        <Description
          description={description}
          lineClamp={{
            desktop: 2,
            mobile: 5,
          }}
        />
      )}
    </PageHeader>
  );
};

export default HeaderSection;

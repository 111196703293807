import { CookieKey, ErrorMessages, MessageField, type SignUpSource } from '@/constants';
import type {
  FailureResponse,
  IAuthenticateSuccessfulResponse,
  ISendMailSuccessfulResponse,
  IVerificationParams,
  LoginMethod,
} from '@/interfaces';
import { getMasterTranslationLanguageCode, objToBase64 } from '@/utils';
import Cookies from 'js-cookie';
import { umsFetcher } from './base.fetchers';

type AuthenticateEmail = (args: {
  email: string;
  browserUuid?: string;
  country?: string;
  signUpSource?: SignUpSource;
  newsletterOptIn: boolean;
  gaClientId?: string;
  gaSessionId?: string;
  eventTimestamp?: string;
  cta?: string;
  authenMethod?: LoginMethod;
}) => Promise<IAuthenticateSuccessfulResponse | FailureResponse>;

export const authenticateEmail: AuthenticateEmail = async ({
  email,
  browserUuid,
  country,
  signUpSource,
  newsletterOptIn,
  gaClientId,
  gaSessionId,
  eventTimestamp,
  cta,
  authenMethod,
}) => {
  const payload = {
    email,
    browser_uuid: browserUuid || null,
    country,
    sign_up_source: signUpSource || null,
    newsletter_opt_in: newsletterOptIn,
    ga_event: {
      client_id: gaClientId,
      session_id: gaSessionId,
      event_timestamp: eventTimestamp,
      cta,
      authentication_method: authenMethod,
    },
  };

  try {
    const res = await umsFetcher.post('email-authenticate', payload);

    const data = await res.json();
    const dataWithStatus = { ...data, status: res.status };

    return dataWithStatus;
  } catch (err: any) {
    console.error('email-authenticate ', err);
    return ErrorMessages[MessageField.AUTHENTICATE_ERROR];
  }
};

type VerifyEmail = (args: {
  token: string;
  browserUuid?: string;
}) => Promise<IAuthenticateSuccessfulResponse | FailureResponse>;

export const verifyEmail: VerifyEmail = async ({ token, browserUuid }) => {
  try {
    const res = await umsFetcher.post('verify-email', {
      token,
      browser_uuid: browserUuid || null,
    });

    const data = await res.json();
    const dataWithStatus = { ...data, status: res.status };

    return dataWithStatus;
  } catch (err: any) {
    console.error('verify-email ', err);
    return ErrorMessages[MessageField.VERIFY_ERROR];
  }
};

type SendVerificationEmail = (
  args: {
    email: string;
    browserUuid?: string;
  } & IVerificationParams,
) => Promise<ISendMailSuccessfulResponse | FailureResponse>;

export const sendVerificationEmail: SendVerificationEmail = async ({
  email,
  browserUuid,
  language: _language,
  source,
  cta,
  variant,
  popupVariant,
}) => {
  const firebaseMessageToken = Cookies.get(CookieKey.FirebaseMessageToken) || null;
  try {
    const language = getMasterTranslationLanguageCode(_language);
    const res = await umsFetcher.post('send-verification-email', {
      email,
      browser_uuid: browserUuid || null,
      firebase_token_device: firebaseMessageToken,
      language,
      source,
      code: objToBase64({
        language,
        source,
        cta,
        variant,
        popupVariant,
      }),
    });

    if (res.status === 500) {
      throw new Error(ErrorMessages[MessageField.SEND_MAIL_ERROR].error);
    }

    const data = await res.json();
    const dataWithStatus = { ...data, status: res.status };

    return dataWithStatus;
  } catch (err: any) {
    console.error(err);
    return ErrorMessages[MessageField.SEND_MAIL_ERROR];
  }
};

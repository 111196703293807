import MembershipLabelWrapper from './MembershipLabel.styles';

import Lock from '../../public/images/icons/lock_outline.svg';

const MembershipLabel: React.FC<{ className?: string }> = ({ className }) => (
  <MembershipLabelWrapper className={className}>
    <Lock width={16} height={16} />
    MEMBERS ONLY
  </MembershipLabelWrapper>
);

export default MembershipLabel;

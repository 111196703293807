import type { DocumentData } from 'firebase/firestore';
import type { IMerchantInfo, IMerchantInfoDTO } from '../interfaces';

export const mapMerchantInfo = (merchantInfo?: IMerchantInfoDTO | DocumentData): IMerchantInfo | undefined => {
  if (!merchantInfo) return;

  return {
    trackingPageReference: merchantInfo.tracking_page_reference,
    merchantName: merchantInfo.merchant_name,
    headerHyperlink: merchantInfo.header_hyperlink,
    headerBanner: merchantInfo.header_banner,
    footerBanner: merchantInfo.footer_banner,
    footerHyperlink: merchantInfo.footer_hyperlink,
    logo: merchantInfo.logo,
    tiktok: merchantInfo.tiktok,
    facebook: merchantInfo.facebook,
    instagram: merchantInfo.instagram,
    youtube: merchantInfo.youtube,
    contact: merchantInfo.contact,
    website: merchantInfo.website,
  };
};

import { ButtonSize, type IPanel, LoginMethod, OnboardingStepName } from '@/interfaces';
import { useGlobalState } from '@/hooks';
import { CustomButton, Image, ImageWrapper, ThankYouWrapper } from './ThankYou.styles';

const ThankYou: React.FC<IPanel> = ({ image, isFullScreen }) => {
  const { userInfo, currentStep, setCurrentStep, getProperImageUrl } = useGlobalState();
  const isBasicDetails = !!userInfo?.firstName && !!userInfo?.lastName && !!userInfo?.companyName;
  const isLoginByEmail = userInfo?.authenticationMethod === LoginMethod.Email;

  if (currentStep === OnboardingStepName.thankYou && isLoginByEmail && isBasicDetails) {
    setCurrentStep(OnboardingStepName.finish);
    return null;
  }

  return (
    <ThankYouWrapper hasImage={!!image}>
      {image && (
        <ImageWrapper isFullScreen={isFullScreen}>
          <Image src={getProperImageUrl(image)} alt={image.description} isFullScreen={isFullScreen} />
        </ImageWrapper>
      )}
      <CustomButton
        className='pop-up-close'
        onClick={() => {
          setCurrentStep(OnboardingStepName.finish);
          document.documentElement.style.overflow = 'scroll';
        }}
        size={ButtonSize.Large}
      >
        Start Browsing
      </CustomButton>
    </ThankYouWrapper>
  );
};

export default ThankYou;

import styled from 'styled-components';
import { BaseDisabledStyles } from './../Button/Button.styles';

export const SelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 5;
`;

export const Dropdown = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

export const SearchIcon = styled.img.attrs({
  src: '/images/icons/search.svg',
})`
  width: 24px;
  height: 24px;
`;

export const SearchIconContainer = styled.div<{ disabled?: boolean }>`
  width: 51px;
  height: 43px;
  background: ${({ theme }) => theme.colors.blue};
  position: absolute;
  right: 1px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ disabled }) => disabled && BaseDisabledStyles}
`;

export const DropdownInputWrapper = styled.div<{ disabled?: boolean }>`
  position: relative;
  width: 100%;

  & > img:first-of-type {
    margin: 0 !important;
    position: absolute !important;
    left: ${({ theme }) => theme.spacing[14]};
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    object-fit: contain;
  }

  ${({ disabled }) =>
    disabled &&
    `
    cursor: not-allowed;

    ${SearchIcon} {
      cursor: not-allowed;
    }
  `}
`;

export const DropdownInput = styled.input`
  width: 100%;
  height: 45px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.inactiveBlack};
  padding: 10.5px ${({ theme }) => theme.spacing[14]};
  padding-right: 42px;
  padding-left: 46px;
  font-size: ${({ theme }) => theme.fontSizes[16]};
  line-height: ${({ theme }) => theme.lineHeight[24]};
  color: ${({ theme }) => theme.colors.highEmphasisBlack};

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
  }

  ::placeholder {
    color: ${({ theme }) => theme.colors.inactiveBlack};
    font-size: ${({ theme }) => theme.fontSizes[14]};
    line-height: ${({ theme }) => theme.lineHeight[22]};
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    padding-left: 50px;
  }
`;

export const DropdownList = styled.div<{ isOpen: boolean | null }>`
  position: absolute;
  left: 0;
  top: calc(100% + ${({ theme }) => theme.spacing[8]});
  z-index: 5;
  width: 100%;
  height: 0;
  max-height: 240px;
  overflow: auto;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.highEmphasisWhite};
  -webkit-box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16);
  box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16));

  ${({ isOpen }) =>
    isOpen &&
    `
    height: auto;
  `}
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.highEmphasisWhite};
  align-items: stretch;
  width: max-content;
  min-width: 100%;
`;

export const DropdownItem = styled.div<{ isActive?: boolean }>`
  min-width: max-content;
  height: 48px;
  display: flex;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing[12]} ${theme.spacing[14]}`};
  background: ${({ theme }) => theme.colors.highEmphasisWhite};
  line-height: ${({ theme }) => theme.lineHeight[24]};
  cursor: pointer;
  transition: background 0.3s ease-in-out;
  white-space: nowrap;

  &:hover {
    background: ${({ theme }) => theme.colors.surface02};
  }

  & > img {
    min-width: 24px !important;
    margin-right: ${({ theme }) => theme.spacing[12]} !important;
    width: 24px;
    height: 24px;
    object-fit: contain;
  }

  ${({ isActive, theme }) =>
    isActive &&
    `
    background: ${theme.colors.surface03};
  `}
`;

export const NotFound = styled.div`
  width: 100%;
  height: 240px;
  background: ${({ theme }) => theme.colors.highEmphasisWhite};
  color: ${({ theme }) => theme.colors.highEmphasisBlack};
  display: flex;
  align-items: center;
  justify-content: center;
`;

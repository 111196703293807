import styled, { keyframes } from 'styled-components';
import { Button } from '@/components';
import { ButtonSize } from '@/interfaces';
import SpinnerIcon from '../../../public/images/icons/spinner.svg';
import CheckIcon from '../../../public/images/icons/check.svg';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
    tranform-origin: center center;
  }

  to {
    transform: rotate(360deg);
    tranform-origin: center center;
  }
`;

export const FormFieldContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-content: flex-start;
  flex-wrap: wrap;
  width: calc(100% + ${({ theme }) => theme.spacing[14]});
  padding-right: ${({ theme }) => theme.spacing[14]};
`;

export const FormWrapper = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const FormSubmitButton = styled(Button).attrs({
  size: ButtonSize.Large,
})`
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing[16]};
`;

export const Spinner = styled(SpinnerIcon)`
  animation-name: ${rotate};
  animation-duration: 700ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  &, path {
    fill: transparent !important;
    stroke: currentColor;
  }
`;

export const Check = styled(CheckIcon)`
  & path {
    fill: currentColor;
  }
`;

import { umsFetcher } from '@/fetchers/umsAPI/base.fetchers';

export interface IVerifyReCaptchaResponse {
  success: boolean;
  score: number;
}

type TVerifyReCaptcha = (args: {
  token: string;
  version: 'v2' | 'v3';
}) => Promise<IVerifyReCaptchaResponse>;

export const verifyReCaptcha: TVerifyReCaptcha = async ({ token, version }) => {
  try {
    const response = await umsFetcher.post('verify-recaptcha', {
      token,
      version,
    });
    if (response.ok) {
      return await response.json();
    }

    return Promise.reject(response);
  } catch (err) {
    console.error('verify-recaptcha', err);
  }
};

import type { UserType } from '@/constants';
import type { IUserInfo, LoginMethod } from '@/interfaces';

export type OriginalUserInfo = {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  profile_picture_url: string | null;
  user_type: UserType;
  company_name: string | null;
  authentication_method: LoginMethod;
  opt_in_date: string | null;
  verification_status?: boolean;
  default_opt_in: boolean;
  country: string;
  email_sync_opt_in: boolean;
  newsletter_opt_in: boolean;
  subscribe_to_email_summary: boolean;
};

export const userInfoModify: (originalUserInfo: OriginalUserInfo) => IUserInfo = ({
  id,
  first_name,
  last_name,
  email,
  profile_picture_url,
  user_type,
  company_name,
  authentication_method,
  opt_in_date,
  verification_status,
  default_opt_in,
  country,
  email_sync_opt_in,
  newsletter_opt_in,
  subscribe_to_email_summary,
}) => {
  return {
    id,
    firstName: first_name,
    lastName: last_name,
    email,
    profilePictureUrl: profile_picture_url,
    userType: user_type,
    companyName: company_name,
    authenticationMethod: authentication_method,
    optInDate: opt_in_date,
    verificationStatus: verification_status || false,
    defaultOptIn: default_opt_in,
    country,
    emailSyncOptIn: email_sync_opt_in,
    newsletterOptIn: newsletter_opt_in,
    subscribeToEmailSummary: subscribe_to_email_summary,
  };
};

import { LogoWrapper } from './BoardLogo.styles';
import PMCLogo from '@/components/PMCLogo';

interface LogoProps {
  src?: string | null;
  alt?: string;
  placeholderText?: string;
}

const BoardLogo = ({ src, alt, placeholderText }: LogoProps) => {
  return (
    <LogoWrapper>
      <PMCLogo
        src={src}
        alt={alt || 'logo'}
        fill
        placeholderText={placeholderText}
        style={{
          objectFit: 'contain',
        }}
      />
    </LogoWrapper>
  );
};

export default BoardLogo;

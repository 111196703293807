import Link from 'next/link';
import type React from 'react';
import { SOCIAL_LINKS } from '@/constants';
import { SocialLinkItem } from './SocialLinks.styles';

const SocialLinks: React.FC = () => {
  return (
    <div>
      {SOCIAL_LINKS.map(({ href, icon }, index) => {
        const SocialLinkIcon = icon;
        return (
          <Link key={index} href={href} passHref legacyBehavior>
            <SocialLinkItem>
              <SocialLinkIcon />
            </SocialLinkItem>
          </Link>
        );
      })}
    </div>
  );
};

export default SocialLinks;

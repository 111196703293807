import styled from 'styled-components';

export const Wrapper = styled.div(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: theme.spacing[16],
  marginTop: theme.spacing[16],

  [`${theme.mediaBreakpointUp.md}`]: {
    gridTemplateColumns: 'repeat(auto-fill, minmax(152px, 1fr))',
    gap: theme.spacing[32],
    marginTop: theme.spacing[24],
  },
}));

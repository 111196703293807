import { LocalStorageKey } from '@/constants';

const { hasVisited, cookieBannerDisplayed } = LocalStorageKey;

export const setCookieBannerClosed = (): void => {
  if (typeof window !== 'undefined' && !localStorage.getItem(cookieBannerDisplayed))
    localStorage.setItem(cookieBannerDisplayed, '1');
};

export const getCookieBannerClosed = (): string | null => {
  if (typeof window !== 'undefined') {
    return localStorage.getItem(cookieBannerDisplayed);
  }
  return null;
};

export const setHasVisited = (): void => {
  if (typeof window !== 'undefined' && !localStorage.getItem(hasVisited)) localStorage.setItem(hasVisited, '1');
};

const getHasVisited = (): string | null => {
  if (typeof window !== 'undefined') {
    return localStorage.getItem(hasVisited);
  }
  return null;
};

const hasQuantcast = () => {
  return document.getElementById('qc-cmp2-container');
};

export const showCookieBannerPopup = (): boolean => {
  const hasVisited = getHasVisited();
  const cookieBannerClosed = getCookieBannerClosed();

  if (hasQuantcast()) return false;
  if (hasVisited === '1' && cookieBannerClosed === '1') return false;
  return true;
};

type GetLocalStorageByKey = (key: string, defaultValue?: string) => string;

export const getLocalStorageByKey: GetLocalStorageByKey = (key, defaultValue = '') => {
  if (typeof window !== 'undefined') {
    return localStorage.getItem(key) || defaultValue;
  }
  return defaultValue;
};

type SetLocalStorageByKey = (key: string, value?: string) => void;

export const setLocalStorageByKey: SetLocalStorageByKey = (key, value = '') => {
  if (typeof window !== 'undefined') {
    return localStorage.setItem(key, value);
  }
};

type DeleteLocalStorageByKey = (key: string) => void;

export const deleteLocalStorageByKey: DeleteLocalStorageByKey = (key) => {
  if (typeof window !== 'undefined') {
    localStorage.removeItem(key);
  }
};

import dynamic from 'next/dynamic';

import { ContentItemUITemplateKeys } from '@/interfaces';

export const UITemplatesContentItem: {
  [key in ContentItemUITemplateKeys]: React.ComponentType<any>;
} = {
  [ContentItemUITemplateKeys.BulletPoints]: dynamic(() => import('../components/ContentItem/Templates/BulletPoints')),
  [ContentItemUITemplateKeys.Metrics]: dynamic(() => import('../components/ContentItem/Templates/Metrics')),
  [ContentItemUITemplateKeys.Services]: dynamic(() => import('../components/ContentItem/Templates/Services')),
};

import styled from 'styled-components';
import { FilledButtonAsLink } from '../Button.styles';

export const ButtonLink = styled(FilledButtonAsLink)<{ isPMTButton?: boolean }>`
  border: none;
  background: ${({ theme }) => theme.colors.surface03};
  color: ${({ theme }) => theme.colors.blue};

  & > span > svg,
  & > svg,
  & > span > svg path,
  & > svg path {
    fill: ${({ theme }) => theme.colors.blue};
  }

  ${({ theme, isPMTButton }) => theme.mediaBreakpointUp[isPMTButton ? 'lg' : 'md']} {
    :not([disabled]):hover {
      background: ${({ theme }) => theme.colors.surface04};
    }
  }
`;

export const Button = styled(ButtonLink).attrs({
  as: 'button',
})``;

export default { Button, ButtonLink };

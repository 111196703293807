import styled from 'styled-components';
import { TextStyles, theme } from '@/constants';
import { SectionThemeKey } from '@/interfaces';
import { Typography } from '@/components';

const clampText = `
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-break: break-word;
`;

export const Item = styled.div(({ theme }) => ({
  color: theme.colors.highEmphasisBlack,
  flex: 1,
  display: 'flex',
  flexDirection: 'row',
}));

export const ContentWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: theme.spacing[8],
}));

export const Title = styled(Typography).attrs({
  variant: TextStyles['Heading 2'],
})`
  ${clampText}
  -webkit-line-clamp: 2;
  margin-bottom: ${({ theme }) => theme.spacing[8]};
`;

export const Description = styled(Typography).attrs({
  variant: TextStyles['Body Text Large'],
})`
  ${clampText}
  -webkit-line-clamp: 3;
`;

// `theme` is from @/constants, not from ThemeProvider
const sectionThemeToStyleMapperObj = {
  [SectionThemeKey.Dark]: {
    [`${Title}`]: {
      color: theme.colors.highEmphasisWhite,
    },
    [`${Description}`]: {
      color: theme.colors.mediumEmphasisWhite,
    },
  },
  [SectionThemeKey.Light]: {
    [`${Title}`]: {
      color: theme.colors.highEmphasisBlack,
    },
    [`${Description}`]: {
      color: theme.colors.mediumEmphasisBlack,
    },
  },
};

export const List = styled.div<{
  sectionTheme?: SectionThemeKey;
}>(({ theme, sectionTheme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing[20],

  [`> ${Item}:not(:last-child)`]: {
    marginBottom: theme.spacing[24],
  },

  [`${theme.mediaBreakpointUp.md}`]: {
    marginTop: theme.spacing[24],
  },

  ...sectionThemeToStyleMapperObj[sectionTheme || SectionThemeKey.Light],
}));

import Image from 'next/image';
import type { ILeadsWrapper } from '@/interfaces';
import { List, Title, Container, LogoWrapper } from './CustomerLogoSection.styles';

interface CustomerLogoSectionProps {
  theme: ILeadsWrapper['theme'];
  title: ILeadsWrapper['customerLogoTitle'];
  items: ILeadsWrapper['customerLogoList'];
}

const CustomerLogoSection: React.FC<CustomerLogoSectionProps> = ({ theme: sectionTheme, title, items }) => {
  // Custom handle for existing entry without customer logos
  if (!items?.length) return null;

  return (
    <Container sectionTheme={sectionTheme}>
      <Title>{title}</Title>
      <List>
        {items.map((logo, index) => (
          <LogoWrapper key={index}>
            <Image
              src={logo.url as string}
              alt={logo.description || 'Customer Logo'}
              fill
              style={{
                objectFit: 'contain',
              }}
            />
          </LogoWrapper>
        ))}
      </List>
    </Container>
  );
};

export default CustomerLogoSection;

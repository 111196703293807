import { DataSectionMapping } from '@/constants';
import {
  CarrierListTemplate,
  DataSectionChildren,
  DataSectionUITemplate,
  type IChart,
  type IDataSection,
} from '@/interfaces';
import {
  DataRendererSection,
  DataSectionWrapper,
  HeadingWrapper,
  SectionButton,
  SectionSubTitle,
} from './DataSection.styles';
import LoadMoreButton from './components/CarrierList/LoadMoreButton';
import useCarrierList from './components/CarrierList/useCarrierList.hooks';
import Header from './components/Header';

type TitleMapping = {
  [key in DataSectionChildren]?: string;
};

/**
 * @note
 * Contentful flow of inputting data is chartList --> peopleList --> carrierList, so the priority of rendering is carrierList, or peopleList, or chartList
 */
const DataSection: React.FC<IDataSection> = (props) => {
  const {
    uiTemplate,
    title,
    sectionLabel,
    retailerCompetitorListTitle,
    retailerList,
    href,
    targetId,
    carrierList,
    carrierListTitle,
    referenceId,
    buttonLabel,
    mobileSubTitle,
    desktopSubTitle,
  } = props;
  // filter out empty data, correct data for carrierList is truncatedCarrierList
  const childrenData = Object.keys(DataSectionChildren).reduce<Record<string, any>>((acc, childKey) => {
    const returnData = (data: any, hasNoData?: boolean) => {
      if (!data?.length || hasNoData) return acc;
      return { ...acc, [childKey]: data };
    };

    switch (childKey) {
      case DataSectionChildren.carrierList: {
        const data = props[DataSectionChildren.carrierList];
        return returnData(data);
      }
      case DataSectionChildren.chartList: {
        const hasNoData = props[DataSectionChildren.chartList]?.every(
          (chart: IChart) => !chart.data?.length && !chart.minimumRecord && !chart.registrationWall,
        );
        const data = props[DataSectionChildren.chartList];
        return returnData(data, hasNoData);
      }
      case DataSectionChildren.retailerList: {
        const hasNoData = !retailerList?.length;
        const data = retailerCompetitorListTitle ? retailerList : null;
        return returnData(data, hasNoData);
      }
      default: {
        const data = props[childKey as keyof IDataSection];
        return returnData(data);
      }
    }
  }, {});

  const childKeys = Object.keys(childrenData);

  const containsCarrierList = childKeys.includes(DataSectionChildren.carrierList);
  const shouldRenderCardGrid = containsCarrierList && childKeys.length > 1;
  const shouldRenderCenteredCarrierCardGrid =
    containsCarrierList && childKeys.length === 1 && uiTemplate === DataSectionUITemplate.CenteredCarrierCardGrid;
  const maxCarriers = shouldRenderCardGrid ? 4 : shouldRenderCenteredCarrierCardGrid ? 6 : 5;
  const { truncatedCarrierList, ...loadMoreProps } = useCarrierList({ carrierList, carrierInterval: maxCarriers });

  if (!childKeys.length) return null;

  // Reassign new carrier list after setting the correct carrierInterval
  childrenData[DataSectionChildren.carrierList] = truncatedCarrierList;

  const renderSectionSubTitle = (subTitle?: IDataSection['mobileSubTitle'], isDesktop = false) => {
    if (!subTitle) return null;

    return (
      <SectionSubTitle isDesktop={isDesktop} isCentered={shouldRenderCenteredCarrierCardGrid}>
        {subTitle}
      </SectionSubTitle>
    );
  };

  const template = shouldRenderCardGrid
    ? CarrierListTemplate.CardGrid
    : shouldRenderCenteredCarrierCardGrid
      ? CarrierListTemplate.CenteredCardGrid
      : CarrierListTemplate.ItemList;

  const titleMapping: TitleMapping = {
    [DataSectionChildren.carrierList]: carrierListTitle,
    [DataSectionChildren.retailerList]: retailerCompetitorListTitle,
  };

  const childrenRenderer = childKeys.map((childKey) => {
    const _childKey = childKey as keyof typeof DataSectionMapping;
    const Component = DataSectionMapping[_childKey];

    if (!Component) return null;
    return (
      <Component key={childKey} template={template} data={childrenData[childKey]} title={titleMapping[_childKey]} />
    );
  });

  return (
    <>
      <DataSectionWrapper id={referenceId} $benchmarked={!!sectionLabel}>
        <HeadingWrapper>
          <Header
            title={title}
            sectionLabel={sectionLabel}
            href={href}
            centered={shouldRenderCenteredCarrierCardGrid}
          />
          {renderSectionSubTitle(mobileSubTitle)}
          {renderSectionSubTitle(desktopSubTitle, true)}
        </HeadingWrapper>
        <DataRendererSection>{childrenRenderer}</DataRendererSection>
        {template === CarrierListTemplate.ItemList && <LoadMoreButton {...loadMoreProps} />}
        {targetId && <SectionButton href={`#${targetId}`}>{buttonLabel || 'view all'}</SectionButton>}
      </DataSectionWrapper>
      {template === CarrierListTemplate.ItemList && <LoadMoreButton {...loadMoreProps} isDesktop />}
    </>
  );
};

export default DataSection;

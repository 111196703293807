import styled from 'styled-components';

const MembershipLabelWrapper = styled.div`
  ${({ theme }) => `
    font-weight: ${theme.fontWeight.semiBold};
    font-size: ${theme.fontSizes[12]};
    background: ${theme.colors.darkBlue};
    padding: ${theme.spacing[4]} 6px;
  `}

  display: inline-flex;
  align-items: center;
  color: white;
  border-radius: 4px;

  svg {
    margin-right: ${({ theme }) => theme.spacing[4]};

    path {
      fill: white;
    }
  }
`;

export default MembershipLabelWrapper;

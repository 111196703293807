import { SlugRoutes, TableFieldName, TableComponentsMapping, lightTableThemeWithHover, RouteName } from '@/constants';
import { type IChart, type ITable, TableIconColor, ChartPercentageKeys } from '@/interfaces';
import {
  getCountryName,
  getCarrierInfoByName,
  formatFloatingNumber,
  getIdenticalTimeframe,
  generateMockMetricsData,
  checkIsNumber,
  getMarketSlugFromMarketName,
  replacePath,
} from '@/utils';
import useGlobalState from './useGlobalState.hooks';
import { BlurredText, CustomHeader } from '@/components/Chart/Table/CustomTableComponents';
import LockIcon from '../public/images/icons/lock_outline.svg';

const CONTACT_US_LINK = '/contact-us'; // TODO: maybe enhancement to the Query app, to make link dynamic

const convertArrayToObject = (array: any[]) => {
  return array.reduce((acc, item) => {
    acc[item.name] = item;
    return acc;
  }, {});
};

// For carrier pages and carrier directory page
const useTableMetrics = (originalData: IChart) => {
  const mappedData: ITable = {
    title: '',
    footer: '',
    child: {},
    columns: [],
    data: [],
    minimumRecord: null,
    hasData: true,
    tableTheme: undefined,
  };

  const { carriers } = useGlobalState();

  // TODO-Post-21: Refractor this func
  const generateTableData = (filteredData: any[]) => {
    const queryOutputAsObject = convertArrayToObject(originalData.query.output);

    return filteredData.map((item: Record<string, any>) => {
      return Object.keys(item).reduce(
        (acc, key) => {
          const Component = TableComponentsMapping[TableFieldName[key as TableFieldName]];
          let isPureString = false;

          switch (key) {
            case TableFieldName.logoLink: // Carrier
            case TableFieldName.carrierLogo: // Market - Carrier
              acc[key] = <Component src={item[key]} alt={item.name || undefined} />;
              break;
            case TableFieldName.name: // Carrier
            case TableFieldName.carrierName: {
              // Market - Carrier
              const carrierInfo = getCarrierInfoByName(item[key], carriers);
              acc[key] = (
                <Component url={carrierInfo?.slug && `${SlugRoutes.carriers}/${carrierInfo?.slug}`}>
                  {carrierInfo?.carrierDisplayName || item[key]}
                </Component>
              );
              break;
            }
            case TableFieldName.pParcelToCountryIso:
            case TableFieldName.pParcelFromCountryIso:
              acc[key] = <Component code={item[key]} name={getCountryName(item[key])} />;
              break;
            case TableFieldName.carrierHeadquarter: {
              const slug = getMarketSlugFromMarketName(item[key], true);
              const href = slug && replacePath(RouteName.Market, [slug]);
              acc[key] = <Component url={href}>{item[key]}</Component>;
              break;
            }
            default: // only pure string, no need for <TableText> wrapper
              isPureString = true;
          }

          if (!isPureString) return acc;

          // Handle for locked keys
          if (queryOutputAsObject[key]?.locked) {
            acc[key] = <BlurredText href={CONTACT_US_LINK} />;
            return acc;
          }

          // Handle for non-number value
          if (!checkIsNumber(item[key])) {
            acc[key] = item[key];
            return acc;
          }

          // Handle for number value
          const isPercentageNumber = Object.values(ChartPercentageKeys).includes(key as ChartPercentageKeys);

          acc[key] = (
            <>
              {formatFloatingNumber(item[key])}
              {isPercentageNumber && '%'}
            </>
          );

          return acc;
        },
        {} as ITable['data'][0],
      );
    });
  };

  mappedData.tableTheme = originalData.query.output.find((item) => item.locked) && lightTableThemeWithHover;
  mappedData.title = originalData.title;
  mappedData.child = originalData.registrationWall;
  mappedData.minimumRecord = originalData.minimumRecord;
  mappedData.columns = originalData.query.output.map((item) => {
    const Icon = item.locked ? LockIcon : null;
    const Header = (
      <CustomHeader icon={Icon} color={TableIconColor.Blue}>
        {item.label || ''}
      </CustomHeader>
    );
    return {
      Header,
      accessor: item.name,
      tooltip: item.tooltip,
      columnWidth: item.width,
      locked: item.locked,
    };
  });

  const filteredData = (originalData.data || []).filter((item: Record<string, any>) => {
    return Object.keys(item).filter((key) => item[key]).length > 0;
  });
  mappedData.hasData = !!filteredData?.length;

  mappedData.data = mappedData.hasData
    ? generateTableData(filteredData)
    : generateTableData(generateMockMetricsData(originalData.query));

  // Find value for timeframe
  const timeData = mappedData.hasData ? originalData.data : mappedData.data;
  const timeframe = getIdenticalTimeframe(timeData);

  mappedData.footer = timeframe && 'Timeframe: ' + timeframe;

  return mappedData;
};

export default useTableMetrics;

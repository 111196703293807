import getConfig from 'next/config';
import { entryPages, SlugRoutes, ContentTypeIds, REDIS_HOMEPAGE_KEY } from '@/constants';
import { getCountryBySlug } from './country.utils';

export const checkIsPreviewModeRequest = (query: NodeJS.Dict<string | string[]>): boolean => {
  const { serverRuntimeConfig } = getConfig();
  const isPreviewTokenCorrect = query?.previewToken === serverRuntimeConfig.previewModeAccessToken;
  const isPreviewMode = query?.preview === 'true' && isPreviewTokenCorrect;

  return isPreviewMode;
};

export const getCachePageSlugFromUrl = (type: ContentTypeIds, pageData: Record<string, any>): string => {
  if (!entryPages.includes(type)) return '';
  const SLASH = '/';

  switch (type) {
    case ContentTypeIds.tag:
      return SLASH + pageData.type + (!pageData.slug ? '' : SLASH + pageData.slug);
    case ContentTypeIds.article:
      return SLASH + pageData.articleType + SLASH + pageData.slug;
    case ContentTypeIds.event:
      return '/events/' + pageData.slug;
    case ContentTypeIds.carrier:
      return SlugRoutes.carriers + SLASH + pageData.slug;
    case ContentTypeIds.companyDirectory:
      return SlugRoutes.companies + (!pageData.slug ? '' : SLASH + pageData.slug);
    case ContentTypeIds.market:
      return SlugRoutes.markets + SLASH + pageData.slug;
    default: //landing page
      return pageData.slug ? SLASH + pageData.slug : REDIS_HOMEPAGE_KEY;
  }
};

export const splitTradelanePageSlug = (slug: string) => {
  const TRADELANE_PAGE_PATTERN = /^([a-z-]+)-to-([a-z-]+)$/;
  const tradelanePageMatch = slug.match(TRADELANE_PAGE_PATTERN);

  if (!tradelanePageMatch) return null;

  const originSlug = tradelanePageMatch[1];
  const destinationSlug = tradelanePageMatch[2];

  const isOriginValid = getCountryBySlug(originSlug);
  const isDestinationValid = getCountryBySlug(destinationSlug);

  if (!isOriginValid || !isDestinationValid) return null;

  return { originSlug, destinationSlug };
};

import styled from 'styled-components';
import { Typography } from '@/components';
import { TextStyles } from '@/constants';
import { styledTextAsObj } from '@/components/Typography/Typography.styles';

export const PageTitle = styled(Typography).attrs({
  variant: TextStyles['Heading 2'],
  className: 'title',
  type: 'h1',
})<{ $hasPageOverviewBanner?: boolean }>(({ theme, $hasPageOverviewBanner }) => ({
  color: theme.colors.highEmphasisBlack,
  padding: theme.spacing[20],

  ...(!$hasPageOverviewBanner && {
    paddingTop: 0,
  }),

  [`${theme.mediaBreakpointUp.md}`]: {
    ...styledTextAsObj[TextStyles['Heading 1']],
    marginBottom: theme.spacing[24],
    padding: 0,
  },
}));

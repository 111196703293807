import { ErrorMessages, MessageField, type StatusCodes } from '@/constants';
import { umsFetcher } from '@/fetchers/umsAPI/base.fetchers';
import type { FailureResponse, LanguageCode } from '@/interfaces';
import { getMasterTranslationLanguageCode } from '@/utils';

type GetShipments = (arg: {
  uuid: string;
  parcelId: string;
  language: LanguageCode;
  isLoggedIn: boolean;
  gaClientId?: string;
  gaSessionId?: string;
  eventTimestamp?: string;
}) => Promise<Response | undefined>;

export const getShipments: GetShipments = async ({
  uuid,
  parcelId,
  language,
  isLoggedIn,
  gaClientId,
  gaSessionId,
  eventTimestamp,
}) => {
  try {
    return await umsFetcher.post('tracking/shipments', {
      uuid,
      parcel_id: parcelId,
      language: getMasterTranslationLanguageCode(language),
      is_logged_in: isLoggedIn,
      ga_event: {
        client_id: gaClientId,
        session_id: gaSessionId,
        event_timestamp: eventTimestamp,
      },
    });
  } catch (err: any) {
    console.error('tracking/shipments', err);
  }
};

type TriggerScrape = (args: {
  uuid: string;
  parcelId: string;
  carrierId?: string;
  shipmentUuid?: string;
  isNew: boolean;
  language: LanguageCode;
  isLoggedIn: boolean;
}) => void;

export const triggerScrape: TriggerScrape = async ({
  uuid,
  parcelId,
  carrierId,
  shipmentUuid,
  isNew,
  language,
  isLoggedIn,
}) => {
  try {
    await umsFetcher.post('tracking/trigger-scrape', {
      uuid,
      parcel_id: parcelId,
      carrier_id: carrierId,
      shipment_uuid: shipmentUuid,
      is_new: isNew,
      language: getMasterTranslationLanguageCode(language),
      is_logged_in: isLoggedIn,
    });
  } catch (err: any) {
    console.error('tracking/trigger-scrape ', err);
  }
};

type UpdateShipmentCarrier = (args: {
  firestoreUuid: string;
  firestoreDocId: string;
  trackingNumber: string;
  carrierId?: string;
}) => void;

export const updateShipmentCarrier: UpdateShipmentCarrier = async ({
  firestoreUuid,
  firestoreDocId,
  trackingNumber,
  carrierId,
}) => {
  try {
    await umsFetcher.put('tracking/shipment/update-shipment-carrier', {
      user_id: firestoreUuid,
      tracking_number: trackingNumber,
      carrier_id: carrierId,
      fb_key: firestoreDocId,
    });
  } catch (err: any) {
    console.error('tracking/shipment/update-shipment-carrier', err);
  }
};

type UpdateShipmentTimeout = (args: {
  firestoreUuid: string;
  firestoreDocId: string;
  trackingNumber: string;
}) => void;

export const updateShipmentTimeout: UpdateShipmentTimeout = async ({
  firestoreUuid,
  firestoreDocId,
  trackingNumber,
}) => {
  try {
    await umsFetcher.put(`tracking/shipment/${trackingNumber}/timeout`, {
      user_id: firestoreUuid,
      fb_key: firestoreDocId,
    });
  } catch (err: any) {
    console.error(`tracking/shipment/${trackingNumber}/timeout`, err);
  }
};

type RemoveShipment = (args: {
  uuid: string;
  shipmentUuid?: string;
  parcelId?: string;
  orderId?: string;
  carrierId?: string;
  fbKey: string;
}) => Promise<StatusCodes.ERROR_500 | FailureResponse | undefined>;

export const removeShipment: RemoveShipment = async ({ uuid, shipmentUuid, parcelId, orderId, carrierId, fbKey }) => {
  try {
    const response = await umsFetcher.delete('tracking/shipment', {
      user_id: uuid,
      shipment_uuid: shipmentUuid,
      parcel_id: parcelId,
      order_id: orderId,
      carrier_id: carrierId,
      fb_key: fbKey,
    });
    if (response.ok) {
      return await response.json();
    }

    return Promise.reject(response);
  } catch (err: any) {
    console.error('tracking/shipment ', err);
    return ErrorMessages[MessageField.REMOVE_SHIPMENT_FAILED];
  }
};

type TranslateEventLocation = (language: LanguageCode, rawShipment: any) => Promise<any>;

export const translateEventLocation: TranslateEventLocation = async (language, parcel) => {
  try {
    const response = await umsFetcher.post('tracking/shipment/translate-location', {
      language,
      parcel,
    });

    if (response.ok) {
      return await response.json();
    }

    return Promise.reject(response);
  } catch (err: any) {
    console.error('tracking/shipment/translate-location', err);
    throw err;
  }
};

type UpdateShipmentLastSearched = (args: { shipmentId: string; userUuid: string }) => Promise<void>;

export const updateShipmentLastSearched: UpdateShipmentLastSearched = async ({ shipmentId, userUuid }) => {
  try {
    const response = await umsFetcher.patch(`tracking/user/${userUuid}/shipments/${shipmentId}`, undefined, {
      accessToken: userUuid,
    });
    if (response.ok) {
      return await response.json();
    }

    return Promise.reject(response);
  } catch (err) {
    console.error(`tracking/user/${userUuid}/shipments/${shipmentId}`, err);
    throw err;
  }
};

type TCreateSMEShipment = (args: {
  userId?: string;
  parcelId?: string;
  orderId?: string;
  trackingPageReference?: string;
  parcelperformId?: string;
  carrierId?: string;
  gaClientId?: string;
  gaSessionId?: string;
  eventTimestamp?: string;
}) => Promise<void>;

export const createSMEShipment: TCreateSMEShipment = async ({
  userId,
  parcelId,
  orderId,
  trackingPageReference,
  parcelperformId,
  carrierId,
  gaClientId,
  gaSessionId,
  eventTimestamp,
}) => {
  const url = 'tracking/sme-shipments';
  try {
    const response = await umsFetcher.post(url, {
      user_id: userId,
      parcel_id: parcelId,
      order_id: orderId,
      tracking_page_reference: trackingPageReference,
      parcelperform_id: parcelperformId,
      carrier_id: carrierId,
      ga_event: {
        client_id: gaClientId,
        session_id: gaSessionId,
        event_timestamp: eventTimestamp,
      },
    });
    if (response.ok) {
      return await response.json();
    }

    return Promise.reject(response);
  } catch (err) {
    console.error(url, err);
  }
};

import { IconAlignment } from './../../interfaces/button.interfaces';
import type { ButtonSize, ButtonProps } from '@/interfaces';
import { getButtonSizeMetrics } from '@/utils';
import styled from 'styled-components';

export const BaseButtonStyles = `
  background: none;
  border: none;
  outline: none;
  text-align: unset;
  padding: 0;
`;

export const BaseDisabledStyles = `
  opacity: 0.5;
  cursor: not-allowed;
`;

const sizeBasedStyles = (size: ButtonSize | undefined, icon?: any) => {
  return `
    padding: ${getButtonSizeMetrics(size, icon).padding};
    ${
      icon &&
      `
      display: grid;
      grid-template-columns: auto auto;
      gap: ${getButtonSizeMetrics(size).icon}px;
    `
    }
  `;
};

export const FilledButtonAsLink = styled.a.withConfig({
  shouldForwardProp: (propName, defaultValidatorFn) => defaultValidatorFn(propName),
})<ButtonProps>`
  ${BaseButtonStyles}
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  border-radius: 4px;
  line-height: 100%;
  text-transform: uppercase;
  -webkit-tap-highlight-color: transparent;
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  font-size: ${({ theme }) => theme.fontSizes[16]};
  letter-spacing: ${({ theme }) => theme.letterSpacing[75]};
  cursor: pointer;

  ${({ size, mobileSize, icon }) => sizeBasedStyles(mobileSize || size, icon)}
  
  & > span {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    word-break: break-word;

    ${(props) =>
      props.icon &&
      `
      justify-content: flex-start;
    `}
  }

  ${(props) =>
    props.alignment === IconAlignment.Left &&
    `
    grid-auto-flow: dense;
    direction: rtl;

    & > span {
      direction: ltr;
    }
  `}

  & > span > svg,
  & > svg {
    width: 20px;
    height: 20px;
  }

  ${(props) =>
    props.disabled &&
    `
    ${BaseDisabledStyles}
  `}

  ${(props) =>
    props.mobileSize &&
    `
    ${props.theme.mediaBreakpointUp.md} {
      ${sizeBasedStyles(props.size, props.icon)}
    }
  `}
`;

export const ButtonText = styled.span<{ truncate?: boolean | number; isPMTButton?: boolean }>`
  word-break: break-word;

  ${({ isPMTButton, theme }) =>
    isPMTButton &&
    `
    margin: -${theme.spacing[4]} 0;
    line-height: ${theme.lineHeight[24]};
  `}

  ${({ truncate }) =>
    truncate &&
    (typeof truncate === 'number'
      ? `
    display: -webkit-box !important;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: ${truncate};
  `
      : `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `)}
`;

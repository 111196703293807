import Image from 'next/image';
import Link from 'next/link';
import type { IIndividualCarrier } from '@/interfaces';
import { gtmClickLeadsElements } from '@/utils';
import { useGlobalState } from '@/hooks';
import {
  Container,
  CarrierName,
  CarrierButton,
  CarrierLogoContainer,
  CarrierNameContainer,
  CarrierInfoContainer,
  CarrierBannerImageContainer,
  DescriptionContainer,
} from './HeaderSection.styles';
import Description from '../../common/Description';
import { TextStyles } from '@/constants';

const HeaderSection: React.FC<Partial<IIndividualCarrier>> = (props) => {
  const { button, carrierLogo, mobileBannerImage, desktopBannerImage, carrierDisplayName, seoDescription } = props;
  const { getProperImageUrl } = useGlobalState();
  const hasDesktopBanner = !!desktopBannerImage?.url;

  const handleLeadButtonClick = (buttonEventLabel?: string) => {
    buttonEventLabel && gtmClickLeadsElements(buttonEventLabel);
  };

  const renderCarrierBannerImage = (bannerImage?: IIndividualCarrier['mobileBannerImage'], isDesktop = false) => {
    if (!bannerImage?.url) return null;

    return (
      <CarrierBannerImageContainer isDesktop={isDesktop}>
        <Image
          src={getProperImageUrl(bannerImage)}
          alt={bannerImage.description || 'Carrier Banner Image'}
          fill
          sizes='100vw'
          style={{
            objectFit: 'cover',
          }}
        />
      </CarrierBannerImageContainer>
    );
  };

  const renderCarrierButton = (button?: IIndividualCarrier['button'], isDesktop = false) => {
    if (!button?.buttonLink || !button?.primaryButtonText) return null;

    return (
      <Link passHref href={button.buttonLink} legacyBehavior>
        <CarrierButton
          target='_self'
          isDesktop={isDesktop}
          onClick={(e) => {
            // Do not use default scroll behavior becasue it is not working properly,
            // workaround is to use window.history.replaceState to change the url.
            // TODO: Figure out why it is not working properly.
            const href = button.buttonLink || '';
            if (href?.startsWith('#')) {
              e.preventDefault();
              window.history.replaceState('', '', href);
              document.querySelector(href)?.scrollIntoView();
            }

            handleLeadButtonClick(button.buttonEventLabel);
          }}
        >
          {button.primaryButtonText}
        </CarrierButton>
      </Link>
    );
  };

  return (
    <Container>
      {renderCarrierBannerImage(mobileBannerImage)}
      {renderCarrierBannerImage(desktopBannerImage, true)}
      <CarrierInfoContainer hasDesktopBanner={hasDesktopBanner}>
        {!!carrierLogo?.url && (
          <CarrierLogoContainer hasDesktopBanner={hasDesktopBanner}>
            <Image
              src={getProperImageUrl(carrierLogo)}
              alt={carrierLogo.description || 'Carrier Logo'}
              fill
              sizes='100vw'
              style={{
                objectFit: 'cover',
              }}
            />
          </CarrierLogoContainer>
        )}
        <CarrierNameContainer hasCarrierLogo={!!carrierLogo?.url}>
          {carrierDisplayName && <CarrierName>{carrierDisplayName}</CarrierName>}
          {renderCarrierButton(button, true)}
        </CarrierNameContainer>
        {renderCarrierButton(button)}
        {seoDescription && (
          <DescriptionContainer>
            <Description
              description={seoDescription}
              lineClamp={{
                desktop: 4,
                mobile: 8,
              }}
              variant={TextStyles['Body Text Small']}
            />
          </DescriptionContainer>
        )}
      </CarrierInfoContainer>
    </Container>
  );
};

export default HeaderSection;

import { ButtonFunctionKey, FieldType, type IButton, type IForm, type IFormField, IconAlignment } from '@/interfaces';
import { FormField, ReCaptchaScript } from '@/components';
import { gtmClickReportDownloads } from '@/utils';
import { ReportOrigin } from '@/constants';
import { Check, Spinner, FormWrapper, FormSubmitButton, FormFieldContainer } from './Form.styles';
import useBaseForm from './useBaseForm.hook';

const SubmittingButton = (
  <FormSubmitButton disabled icon={() => <Spinner />} alignment={IconAlignment.Left}>
    Submitting...
  </FormSubmitButton>
);

const SubmittedButton = (
  <FormSubmitButton disabled icon={() => <Check />} alignment={IconAlignment.Left}>
    Sent
  </FormSubmitButton>
);

const Form: React.FC<IForm> = ({ formFieldList, button, isLoggedIn, onSubmit, onFocus }) => {
  const MOCK_BUTTON: IButton = {
    primaryButtonText: 'CONTACT US',
  };
  const ctaButton = button ?? MOCK_BUTTON;

  const checkShouldSendGA4Event = (button: IButton) => {
    const isDownloadButton = button.function === ButtonFunctionKey.Download && !!button.buttonLink;

    return isDownloadButton && isLoggedIn;
  };

  const sendGA4Event = () => {
    const shouldSendGA4Event = checkShouldSendGA4Event(ctaButton);
    if (shouldSendGA4Event) {
      gtmClickReportDownloads(ReportOrigin.landingPage);
    }
  };

  const { watch, setValue, register, formState, isSubmitted, isSubmitting, handleFormSubmit } = useBaseForm({
    onSubmit,
    onSuccess: sendGA4Event,
  });

  return (
    <FormWrapper autoComplete='off' onSubmit={handleFormSubmit}>
      <ReCaptchaScript />
      <FormFieldContainer>
        {formFieldList?.map((field: IFormField, index: number) => (
          <FormField
            key={index}
            index={index}
            watch={watch}
            setValue={setValue}
            register={register}
            formState={formState}
            onFocus={onFocus}
            {...field}
            disabled={field.disabled || (field.type === FieldType.Checkbox && isSubmitting)}
          />
        ))}
      </FormFieldContainer>

      {isSubmitting && SubmittingButton}
      {isSubmitted && SubmittedButton}
      {!isSubmitting && !isSubmitted && (
        <FormSubmitButton {...ctaButton} onClick={handleFormSubmit} type='submit' disabled={!formState.isValid} />
      )}
    </FormWrapper>
  );
};

export default Form;

import { AssetRenderer, Container } from '@/components';
import { Breadcrumbs } from '@/components';
import { type IPageOverviewBanner, SectionThemeKey } from '@/interfaces';
import ButtonGroup from '../ButtonGroup';
import GraphicImg from '../GraphicImg';
import {
  BannerContainer,
  BannerInnerContainer,
  ButtonGroupWrapper,
  DefaultBannerWrapper,
  HeroAssetImage,
  HeroAssetWrapper,
  MobileSubText,
  MobileTitle,
  SubText,
  SubTitle,
  Title,
} from './DefaultBanner.styles';

const DefaultBanner: React.FC<IPageOverviewBanner> = ({
  breadcrumbs,
  title,
  titleMobile,
  subTitle,
  subText,
  subTextMobile,
  priority,
  buttons,
  theme,
  image,
  imageMobile,
  heroAsset,
}) => {
  return (
    <DefaultBannerWrapper sectionTheme={theme || SectionThemeKey.Light}>
      <GraphicImg image={image} imageMobile={imageMobile} priority={priority} />
      <Container style={{ position: 'relative' }}>
        <BannerContainer fullWidth={!heroAsset}>
          <BannerInnerContainer>
            {!!breadcrumbs && <Breadcrumbs {...breadcrumbs} />}
            {subTitle && <SubTitle>{subTitle}</SubTitle>}
            <Title>{title}</Title>
            <MobileTitle>{titleMobile || title}</MobileTitle>
            <SubText>{subText?.trim()}</SubText>
            <MobileSubText>{subTextMobile?.trim()}</MobileSubText>
            {!!buttons?.length && (
              <ButtonGroupWrapper>
                <ButtonGroup buttons={buttons} />
              </ButtonGroupWrapper>
            )}
          </BannerInnerContainer>

          {heroAsset && (
            <HeroAssetWrapper>
              <AssetRenderer asset={heroAsset} ImageComponent={HeroAssetImage} />
            </HeroAssetWrapper>
          )}
        </BannerContainer>
      </Container>
    </DefaultBannerWrapper>
  );
};

export default DefaultBanner;

import styled, { css } from 'styled-components';
import ArrowRight from '../../public/images/icons/arrow_forward.svg';

export const CarouselWrapper = styled.div<{
  isReachedFirstSlide: boolean;
  isReachedLastSlide: boolean;
}>`
  position: relative;

  .slick-list {
    overflow: hidden;
    transition: padding-left 0.2s ease-in-out;

    ${({ isReachedLastSlide, theme }) =>
      isReachedLastSlide &&
      css`
      padding-left: 136px;

      ${theme.mediaBreakpointUp.md} {
        padding-left: 116px;
      }
    `}

    ${({ isReachedFirstSlide }) =>
      isReachedFirstSlide &&
      css`
      padding-left: unset;
    `}
  }

  .slick-track {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(auto-fill, 200px);
    gap: ${({ theme }) => theme.spacing['24']};
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    .slick-track {
      grid-template-columns: repeat(auto-fill, 280px);
      gap: ${({ theme }) => theme.spacing['20']};
    }
  }
`;

export const NextArrow = styled(ArrowRight)`
  transition: 0.4s;
  width: 24px !important;
  height: 24px !important;

  path {
    fill: ${({ theme }) => theme.colors.blue};
  }
`;

export const PrevArrow = styled(NextArrow)`
  transform: scaleX(-1);
`;

export const ButtonContainer = styled.div`
  background: transparent;
  display: flex !important;
  align-items: center;
  left: unset;
  top: -66px;
  right: 20px;
  bottom: unset;
  transform: translateY(100%);
  transition: 0.4s;
  height: 24px;
  position: absolute;

  &.slick-disabled {
    cursor: default;

    ${PrevArrow} path, ${NextArrow} path {
      fill: ${({ theme }) => theme.colors.disabledBlack} !important;
    }
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    top: -74px;
  }
`;

export const FakeThumbnail = styled.div``;

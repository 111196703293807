import { LIST_FIELD_NOT_RENDER_TO_LINE, PmAnalyticsTableMapping } from '@/constants';
import {
  type IChart,
  type IMarket,
  ChartType,
  type IPmAnalyticsQuery,
  KeysCanBeCompared,
  type IPmAnalyticsQueryInput,
  type IPmAnalyticsQueryOutput,
  PmAnalyticsQueryTable,
  CustomQueryValues,
  TimeKey,
  type IChartData,
} from '@/interfaces';

export const getTradelane = (input?: Record<string, string>) => {
  if (!input) return;

  if (input.tradelane) {
    return {
      originCountryCode: input.tradelane?.slice(0, 2),
      destinationCountryCode: input.tradelane.slice(6),
    };
  }

  if (input.pParcelToCountryIso && input.pParcelFromCountryIso) {
    return {
      originCountryCode: input.pParcelFromCountryIso,
      destinationCountryCode: input.pParcelToCountryIso,
    };
  }

  return null;
};

export const getTradelaneFromInput = (input?: IPmAnalyticsQueryInput[]) => {
  if (!input) return;

  const _input = input.reduce((acc, cur) => {
    return { ...acc, [cur.name]: cur.value };
  }, {} as any);

  return getTradelane(_input);
};

type GetPreviousDateObject = (date?: Date) => {
  month: number;
  year: number;
  quarter: number;
};

/**
 * Get previous month, year, quarter based on the current year
 * @param date The current date (default is new Date())
 * @returns { month, year, quarter }
 * @example
 * getPreviousYear(new Date('2023-10-17')) // { month: 9, year: 2022, quarter: 3 }
 */
const getPreviousYear: GetPreviousDateObject = (date = new Date()) => {
  const currentYear = date.getFullYear();
  const currentMonth = date.getMonth();
  const currentQuarter = Math.floor(currentMonth / 3) + 1;

  const year = currentYear - 1;

  return {
    month: currentMonth,
    year,
    quarter: currentQuarter,
  };
};

/**
 * Get previous month, year, quarter based on the current month
 * @param date The current date (default is new Date())
 * @returns { month, year, quarter }
 * @example
 * getPreviousMonth(new Date('2023-10-17')) // { month: 9, year: 2023, quarter: 3 }
 */
const getPreviousMonth: GetPreviousDateObject = (date = new Date()) => {
  const monthIndex = date.getMonth();
  const year = date.getFullYear();
  const isJanuary = monthIndex === 0;

  const calculatedMonth = isJanuary ? 11 : monthIndex - 1;
  const calculatedYear = isJanuary ? year - 1 : year;
  const calculatedQuarter = Math.floor(calculatedMonth / 3) + 1;

  return {
    month: calculatedMonth,
    year: calculatedYear,
    quarter: calculatedQuarter,
  };
};

/**
 * Get previous month, year, and quarter based on the current quarter
 * @param date The current date (default is new Date())
 * @returns { month, year, quarter }
 * @example
 * getPreviousQuarter
 */
const getPreviousQuarter: GetPreviousDateObject = (date = new Date()) => {
  const QUARTER_FOUR_STARTING_MONTH_INDEX = 9;
  const MONTHS_INDEX_DIFFERENCE = 1; // month index starts from 0-11, normal numbering starts from 1-12
  const QUARTER_ONE_INDEX = 1;
  const QUARTER_FOUR_INDEX = 4;
  const MONTHS_PER_QUARTER = 3;

  const monthIndex = date.getMonth();
  const year = date.getFullYear();
  const quarter = Math.floor(monthIndex / MONTHS_PER_QUARTER) + MONTHS_INDEX_DIFFERENCE;
  const isQuarterOne = quarter === QUARTER_ONE_INDEX;
  const calculatedQuarter = isQuarterOne ? QUARTER_FOUR_INDEX : quarter - 1;
  const calculatedYear = isQuarterOne ? year - 1 : year;

  const getCalculatedMonthIndex = (curQuarter: number) => {
    const previousQuarter = curQuarter - 1;
    const calculatedMonthIndex = previousQuarter * MONTHS_PER_QUARTER - MONTHS_INDEX_DIFFERENCE;

    return Math.abs(calculatedMonthIndex);
  };

  const calculatedMonthByQuarter = isQuarterOne ? QUARTER_FOUR_STARTING_MONTH_INDEX : getCalculatedMonthIndex(quarter);

  return {
    month: calculatedMonthByQuarter,
    year: calculatedYear,
    quarter: calculatedQuarter,
  };
};

type GetPreviousTimeframe = (currentTimeframe: {
  pivot?: TimeKey;
  date?: Date;
}) => {
  text: string;
  date: Date;
};

/**
 * Get previous date object based on the pivot declared
 * @param pivot TimeKey (default is quarter)
 * @param date The current date (default is new Date())
 * @returns { text, date }
 * @example
 * getPreviousTimeframe({ date: new Date('2023-10-17') }) // { text: 'Q3 2023', date: new Date('2023-07-01') }
 * getPreviousTimeframe({ key: 'year', date: new Date('2023-10-17') }) // { text: '2022', date: new Date('2022-01-01') }
 * getPreviousTimeframe({ key: 'month', date: new Date('2023-10-17') }) // { text: 'Oct, 2023', date: new Date('2023-09-01') }
 * getPreviousTimeframe({ key: 'timeframe', date: new Date('2023-10-17') }) // { text: 'Q3 2023', date: new Date('2023-07-01') }
 */
export const getPreviousTimeframe: GetPreviousTimeframe = ({ pivot = TimeKey.quarter, date = new Date() }) => {
  const DEFAULT_DAY = 1;

  switch (pivot) {
    case TimeKey.month: {
      const { month, year } = getPreviousMonth(date);
      const monthAsText = new Date(year, month, 1).toLocaleString('default', {
        month: 'short',
      });
      const text = `${monthAsText}, ${year}`;

      return {
        text,
        date: new Date(year, month, DEFAULT_DAY),
      };
    }
    case TimeKey.quarter:
    case TimeKey.timeframe: {
      const { month, year, quarter } = getPreviousQuarter(date);
      const text = `Q${quarter} ${year}`;

      return {
        text,
        date: new Date(year, month, DEFAULT_DAY),
      };
    }
    case TimeKey.year:
    default: {
      const { month, year } = getPreviousYear(date);

      return {
        text: year.toString(),
        date: new Date(year, month, DEFAULT_DAY),
      };
    }
  }
};

type GetPreviousTimeframes = (args: {
  key?: TimeKey;
  date?: Date;
  limit?: number;
}) => Array<string | number>;

/**
 * Get previous timeframes before the current timeframe
 * @param key TimeKey (default is quarter)
 * @param date Date object to set the current date (default is new Date())
 * @param limit number of timeframes to get (default is 4)
 * @returns array of previous timeframes
 * @example
 * getPreviousTimeframes({ date: new Date('2023-10-17') }) // ['Q4 2022', 'Q1 2022', 'Q2 2022', 'Q3 2022']
 * getPreviousTimeframes({ key: 'year', date: new Date('2023-10-17') }) // [2019, 2020, 2021, 2022]
 */
export const getPreviousTimeframes: GetPreviousTimeframes = ({
  key = TimeKey.quarter,
  date = new Date(),
  limit = 4,
}) => {
  if (!Object.values(TimeKey).includes(key)) return [];

  let currentTimeframe = date;
  const previousTimeframes = [];

  for (let i = 0; i < limit; i++) {
    const curDate = new Date(currentTimeframe);

    const { text, date: calculatedDate } = getPreviousTimeframe({
      pivot: key,
      date: curDate,
    });

    previousTimeframes.unshift(text);

    currentTimeframe = calculatedDate;
  }

  return previousTimeframes;
};

export const generateMockMetricsData = (query: IPmAnalyticsQuery) => {
  const { output } = query;
  if (!output) return [];

  const mockObject: any = {};
  output.forEach((keyData) => {
    const { name } = keyData;
    if (!name) return;
    mockObject[name] = 'name';
  });

  const timeKey = PmAnalyticsTableMapping[query.table].time;
  const { text } = getPreviousTimeframe({ pivot: timeKey });

  return [
    {
      ...mockObject,
      [timeKey]: text,
    },
  ];
};

/**
 * Get chart labels to display on the bordered chart's header, specifically for Line Metrics, Trend Metrics, and Bar Metrics
 * @param output IPmAnalyticsQueryOutput[]
 * @param limit array length (default 2)
 * @returns array of output that does not include LIST_FIELD_NOT_RENDER_TO_LINE
 */
export const getChartLabels = (output: IPmAnalyticsQueryOutput[], limit = 2) => {
  if (!output?.length) return [];

  return (
    output?.filter((outputItem) => !LIST_FIELD_NOT_RENDER_TO_LINE?.includes(outputItem.name))?.slice(0, limit) || []
  );
};

/**
 * If type is not Bar Metrics/Trend Metrics, return the existing query, else filter out non-numerical columns and get only the first numerical column.
 * @param type ChartType, e.g. Table Metrics
 * @param query IPmAnalyticsQuery
 * @returns IPmAnalyticsQuery
 */
export const mapChartQueryOutput = (type = ChartType.TableMetrics, query: IPmAnalyticsQuery) => {
  const table = query.table || PmAnalyticsQueryTable.Carrier;

  if (![ChartType.BarMetrics, ChartType.TrendMetrics].includes(type)) {
    return {
      ...query,
      table,
    };
  }

  const mappedOutput = query.output?.find(({ name }) => !LIST_FIELD_NOT_RENDER_TO_LINE.includes(name));

  return {
    ...query,
    table,
    output: !mappedOutput ? [] : [mappedOutput],
  };
};

type FindIndexesShouldBeModified = (
  queryInput: IPmAnalyticsQueryInput[],
  type: KeysCanBeCompared.country | KeysCanBeCompared.region,
) => number[] | null;

const findIndexesShouldBeModified: FindIndexesShouldBeModified = (queryInput, type) => {
  const typeMapping = {
    [KeysCanBeCompared.country]: {
      fieldName: CustomQueryValues.countryCode,
      origin: KeysCanBeCompared.countryOrigin,
      destination: KeysCanBeCompared.countryDestination,
    },
    [KeysCanBeCompared.region]: {
      fieldName: CustomQueryValues.marketName,
      origin: KeysCanBeCompared.regionOrigin,
      destination: KeysCanBeCompared.regionDestination,
    },
  };

  const foundIdx = queryInput.findIndex(({ name, value }) => value === typeMapping[type].fieldName && name === type);

  // OR comparison
  if (foundIdx !== -1) return [foundIdx];

  const filteredIndexes = queryInput.reduce(
    (acc, { name, value }, idx) => {
      if (
        value === typeMapping[type].fieldName &&
        [typeMapping[type].origin, typeMapping[type].destination].includes(name as KeysCanBeCompared)
      )
        acc.push(idx);
      return acc;
    },
    <number[]>[],
  );

  // AND comparison
  if (filteredIndexes.length === 2) return filteredIndexes;

  return null;
};

type GenerateQueryForMarket = (query: IPmAnalyticsQuery, pageData: IMarket) => IPmAnalyticsQuery;

export const generateQueryForMarket: GenerateQueryForMarket = (query, pageData) => {
  if (!query.table?.includes('market')) return query;

  const { countryCode, marketName } = pageData;

  const modifiedQueryInput = query.input;

  if (countryCode) {
    findIndexesShouldBeModified(modifiedQueryInput, KeysCanBeCompared.country)?.forEach(
      (idx) => (modifiedQueryInput[idx].value = countryCode),
    );
  } else {
    findIndexesShouldBeModified(modifiedQueryInput, KeysCanBeCompared.region)?.forEach(
      (idx) => (modifiedQueryInput[idx].value = marketName || ''),
    );
  }

  return {
    ...query,
    input: modifiedQueryInput,
  };
};

/**
 * Maps the headquarters value to the query input for the TradelaneCarrier table.
 * @param query The PM Analytics query object.
 * @param headquarters The headquarters value to be mapped.
 * @returns The modified PM Analytics query object with the headquarters value mapped.
 */
export const mapHeadquartersToQuery = (query: IPmAnalyticsQuery, headquarters: string) => {
  const TABLES = [PmAnalyticsQueryTable.TradelaneCarrier, PmAnalyticsQueryTable.TradelaneTradelane];

  const { table, input } = query;
  if (!TABLES.includes(table)) {
    return query;
  }

  const modifiedInput: IPmAnalyticsQueryInput[] = input.map((inputObject) => {
    const { name, operator, value } = inputObject;
    const isEqualsOperator = operator === 'equals';
    const isHeadquarters = value === 'headquarters';
    const isCountry = name === KeysCanBeCompared.country;

    if (isCountry && isEqualsOperator && isHeadquarters) {
      const splittedHeadquarters = headquarters.split(', ');
      const countryIsoCode = splittedHeadquarters[splittedHeadquarters.length - 1];
      if (!countryIsoCode) {
        return inputObject;
      }

      return {
        name,
        operator,
        value: countryIsoCode,
      };
    }

    return inputObject;
  });

  return {
    ...query,
    input: modifiedInput,
  };
};

export const findXAndYKeys = (query: IPmAnalyticsQuery) => {
  let numericalKey = '';
  let stringKey = '';

  query.output.every(({ name }) => {
    if (numericalKey && stringKey) return false;
    if (!LIST_FIELD_NOT_RENDER_TO_LINE.includes(name) && !numericalKey) {
      numericalKey = name;
      return true;
    }
    if (!stringKey) stringKey = name;
    return true;
  });

  if (!stringKey) {
    const tableMapping = PmAnalyticsTableMapping[query.table];
    const timeKey = tableMapping?.time;

    stringKey = timeKey || '';
  }

  return { numericalKey, stringKey };
};

type GetRenderConditionsForChart = (args: {
  minimumRecord?: IChart['minimumRecord'];
  data?: IChart['data'];
  child?: Record<string, any>;
  Component?: React.FC<any>;
  hasData?: boolean;
}) => {
  hasActualData: boolean;
  shouldRenderInnerComponent: boolean;
};

/**
 * Check if the chart should render the inner component and if the chart has actual data
 * @param args GetRenderConditionsForChart['args]
 * @returns hasActualData, shouldRenderInnerComponent
 */
export const getRenderConditionsForChart: GetRenderConditionsForChart = (args) => {
  const { data, child, Component, hasData, minimumRecord } = args;
  const hasActualData = hasData !== undefined ? !!(hasData && data?.length) : !!data?.length;
  const hasMinimumRecord =
    typeof minimumRecord === 'number' && !!(!hasActualData ? true : data && data.length < minimumRecord);

  const hasInnerComponent = !!child && !!Component && !!Object.keys(child).length;
  const shouldRenderInnerComponent =
    typeof minimumRecord !== 'number' ? hasInnerComponent : hasInnerComponent && hasMinimumRecord;

  return {
    hasActualData,
    shouldRenderInnerComponent,
  };
};

/**
 * Get identical timeframe from chart data if there is only one timeframe
 * @param data Chart data
 * @returns string of identical timeframe/quarter/year/month
 * @example
 * getIdenticalTimeframe([{ timeframe: 'Q2 2021' }, { timeframe: 'Q1 2021' }]) // 'Q2 2021'
 * getIdenticalTimeframe([{ timeframe: 'Q1 2021' }, { timeframe: 'Q1 2021' }]) // 'Q1 2021'
 * getIdenticalTimeframe([{ quarter: 'Q1 2023' }, { quarter: 'Q3 2023' }]) // null
 * getIdenticalTimeframe([{ quarter: 'Q1 2023' }, { quarter: 'Q1 2023' }]) // 'Q1 2023'
 * getIdenticalTimeframe([{ year: 2023 }, { year: 2023 }]) // 2023
 * getIdenticalTimeframe([{ year: 2023 }, { year: 2022 }]) // null
 * getIdenticalTimeframe([{ month: '2024-01-20 20:00:00' }, { month: '2024-01-20 20:00:00' }]) // '2024-01-20 20:00:00'
 * getIdenticalTimeframe([]) // null
 */
export const getIdenticalTimeframe = (data: IChart['data']) => {
  if (!data?.length) return null;

  const firstAnalyticsData = data[0];
  const timeKey = Object.keys(firstAnalyticsData).find((key) =>
    Object.values(TimeKey).includes(key as TimeKey),
  ) as TimeKey;

  if (!timeKey) return null;

  const firstTimeframeKey = firstAnalyticsData[timeKey];

  switch (timeKey) {
    case TimeKey.quarter: {
      const hasSameQuarter = data.every(({ quarter }) => quarter === firstTimeframeKey);
      const timeframe = hasSameQuarter ? firstTimeframeKey : null;

      return timeframe;
    }
    default:
      return firstTimeframeKey;
  }
};

export const customChartDataMapper = (data: IChartData) => {
  const TIME_KEY = TimeKey.month;
  if (!data[TIME_KEY]) return data;

  const mappedData = { ...data };
  const value = mappedData[TIME_KEY] as string;
  const date = new Date(value);
  const year = date.getFullYear();
  const monthAsText = date.toLocaleString('default', { month: 'short' });
  mappedData[TIME_KEY] = `${monthAsText}, ${year}`;

  return mappedData;
};

import { type TokenResponse, useGoogleLogin } from '@react-oauth/google';

export enum GoogleOAuthFlow {
  AuthCode = 'auth-code',
  Implicit = 'implicit',
}

export interface GoogleOAuthParams {
  onSuccess: (response: TokenResponse) => void;
  onError: (error: Pick<TokenResponse, 'error' | 'error_description' | 'error_uri'>) => void;
  flow?: GoogleOAuthFlow;
  scope?: string;
  redirect_uri?: string;
}

export type GoogleOAuthImplicitParams = Omit<GoogleOAuthParams, 'flow' | 'redirectUri'>;

export interface GoogleOAuthAuthCodeParams extends GoogleOAuthParams {
  flow: GoogleOAuthFlow.AuthCode;
  redirect_uri: string;
  scope: string;
}

export interface UseGoogleOAuthReturn {
  login: () => void;
}

export const useGoogleOAuth = ({ onSuccess, onError, flow, scope }: GoogleOAuthParams): UseGoogleOAuthReturn => {
  /**
   * default google login with input params of the hooks
   */
  const params: GoogleOAuthParams = {
    onSuccess,
    onError,
    scope,
    flow,
  };
  const login = useGoogleLogin(params as any);

  return { login };
};

import styled from 'styled-components';
import { FormFieldContainer } from '@/components/Form/Form.styles';
import ErrorMessage from '@/components/FormField/components/ErrorMessage';
import { LoginButtonWrapper } from '@/components/SsoLoginButton/SsoLoginButton.styles';
import { CheckboxWrapper } from '@/components/FormField/components/FormFieldType/Checkbox/Checkbox.styles';
import { TextStyles } from '@/constants';
import { Typography } from '@/components';

export const ButtonListWrapper = styled.div`
  width: 100%;

  ${({ theme }) => `
    margin-top: ${theme.spacing[16]};

    > :first-child {
      margin-bottom: ${theme.spacing[12]};
    }
  `}

  text-align: center;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin-top: ${({ theme }) => theme.spacing[24]};
  }
`;

export const DividedLine = styled.div`
  position: relative;
  margin: ${({ theme }) => `${theme.spacing[36]} 0 ${theme.spacing[24]} 0`};
  width: 100%;
  height: 1px;
  background-color: #D0D0D0;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin: ${({ theme }) => `${theme.spacing[34]} 0 ${theme.spacing[24]} 0`};
  }
`;

export const DividedText = styled(Typography).attrs({
  variant: TextStyles['Overline'],
})`
  position: absolute;
  margin: 0;
  padding: ${({ theme }) => theme.spacing[10]};
  width: fit-content;
  left: 50%;
  transform: translate(-50%, -50%);

  ${({ theme }) => `
    background-color: ${theme.colors.highEmphasisWhite};
    font-weight: ${theme.fontWeight.semiBold};
  `}
`;

export const JoinUsWrapper = styled.div<{ isFullScreen?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;

  ${LoginButtonWrapper} {
    width: 100%;
  }

  ${FormFieldContainer} {
    margin: 0;

    ${CheckboxWrapper} {
      margin-top: ${({ theme }) => theme.spacing[16]};
    }
  }

  ${ErrorMessage} {
    margin-bottom: 0;
  }

  ${({ theme, isFullScreen }) =>
    isFullScreen &&
    `
    ${ButtonListWrapper} {
      margin-top: ${theme.spacing[24]};
    }

    ${DividedLine} {
      margin: ${theme.spacing[46]} 0 ${theme.spacing[34]} 0;
    }
  `}
`;

import { useState } from 'react';
import type { IReportsDownloadPopup } from '@/interfaces';
import {
  SubText,
  PopupContainer,
  ContentContainer,
  ButtonListWrapper,
  TextContentContainer,
  InnerContentContainer,
  FunctionalityButton,
} from '../../ReportsPopup.styles';
import CloseIcon from '../../../../public/images/icons/close_icon.svg';
import DownloadButton from './DownloadButton';

const DownloadPopupContent: React.FC<IReportsDownloadPopup> = ({ download, renderImage, renderTitle }) => {
  const { title, subText, buttonText, file } = download;
  const [isClosed, setIsClosed] = useState(false);

  const customFunctionalityButtonClick = () => {
    setIsClosed(true);
  };

  if (isClosed) return null;

  return (
    <PopupContainer>
      {/* Mobile content */}
      {renderImage({ isMobile: true })}
      {/* Desktop content */}
      {renderImage({ isMobile: false })}

      <ContentContainer>
        <InnerContentContainer>
          <TextContentContainer>
            {renderTitle({ title })}
            <SubText>{subText}</SubText>
          </TextContentContainer>
          <ButtonListWrapper>
            <DownloadButton file={file} closePopup={customFunctionalityButtonClick}>
              {buttonText}
            </DownloadButton>
          </ButtonListWrapper>
        </InnerContentContainer>

        {/* Functionality Component */}
        <FunctionalityButton onClick={customFunctionalityButtonClick}>
          <CloseIcon />
        </FunctionalityButton>
      </ContentContainer>
    </PopupContainer>
  );
};

export default DownloadPopupContent;

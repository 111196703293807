import styled from 'styled-components';
import { Typography, Button } from '@/components';
import { TextStyles } from '@/constants';
import { ButtonColorKey, ButtonVariant } from '@/interfaces';

export const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

export const ProfileImage = styled.div({
  width: 35,
  height: 35,
  borderRadius: 4,
  position: 'relative',
  overflow: 'hidden',
});

export const ProfileDefaultImage = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.pressedWhite};
  color: ${({ theme }) => theme.colors.highEmphasisWhite};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ProfileName = styled(Typography).attrs({ variant: TextStyles['Body Text Small'] })`
  display: none;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: initial;
    max-width: 105px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: ${({ theme }) => theme.colors.mediumEmphasisWhite};
    margin-left: ${({ theme }) => theme.spacing['8']};
  }
`;

export const LogInButton = styled(Button).attrs({
  variant: ButtonVariant.Outlined,
  color: ButtonColorKey.Transparent,
})`
  margin-left: auto;
`;

export const FirstLetter = styled(Typography).attrs({
  variant: TextStyles['Heading 4'],
})`
  text-transform: uppercase;
`;

import { Button, Container, Typography } from '@/components';
import { BreadcrumbsSectionContainer } from '@/components/Breadcrumbs/Breadcrumbs.styles';
import { Flex } from '@/components/Common';
import { styledTextAsObj } from '@/components/Typography/Typography.styles';
import { TextStyles } from '@/constants';
import { SectionThemeKey } from '@/interfaces';
import styled from 'styled-components';

const TOP_BORDER_SIZE = 1;

export const BoardWrapper = styled(Container)`
  overflow: hidden;
  background: ${({ theme }) => theme.colors.highEmphasisWhite};
  padding: ${({ theme }) => theme.spacing[20]};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.hoverBlack};
  box-shadow: 0px 2px 4px 0px rgba(96, 97, 112, 0.16),
    0px 0px 1px 0px rgba(40, 41, 61, 0.04);

  position: absolute;
  display: block;
  left: 0;
  margin: 0 ${({ theme }) => theme.spacing[20]};
  width: calc(100% - ${({ theme }) => theme.spacing[20]} * 2);

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    top: ${({ theme }) => theme.spacing[60]};
    padding-bottom: ${({ theme }) => theme.spacing[24]};
  }
`;

export const BannerWrapper = styled.div<{
  sectionTheme: SectionThemeKey;
  hasNotEnoughData: boolean;
}>`
  position: relative;
  min-height: ${({ hasNotEnoughData }) => (hasNotEnoughData ? 212 : 336) + TOP_BORDER_SIZE}px;
  margin-bottom: 0;
  padding-top: ${({ theme }) => theme.spacing[20]};

  background-color: ${({ sectionTheme, theme }) => {
    return sectionTheme === SectionThemeKey.Light ? theme.colors.highEmphasisWhite : theme.colors.darkBlue;
  }};

  ${BreadcrumbsSectionContainer} {
    padding: 0 ${({ theme }) => theme.spacing[20]};
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
   
    padding-top: ${({ theme }) => theme.spacing[40]};
    min-height: ${({ hasNotEnoughData }) => (hasNotEnoughData ? 200 : 336) + TOP_BORDER_SIZE}px;

    ${BreadcrumbsSectionContainer} {
      padding: 0;
    }
  }
`;

export const Title = styled(Typography).attrs({
  variant: TextStyles['Heading 1'],
})(({ theme }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',

  [`${theme.mediaBreakpointUp.md}`]: {
    ...styledTextAsObj[TextStyles['Large Text Display']],
  },
}));

export const TitleWrapper = styled(Flex)({
  alignItems: 'center',
  overflow: 'hidden',
  width: '100%',
});

export const HeadingWrapper = styled(Flex)(({ theme }) => ({
  flexDirection: 'column',

  [`${theme.mediaBreakpointUp.md}`]: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

export const BannerButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing[16],
  flex: 'none',

  [`${theme.mediaBreakpointUp.md}`]: {
    marginTop: 0,
    marginLeft: theme.spacing[20],
  },
}));

export const DescriptionWrapper = styled.div(({ theme }) => ({
  marginTop: theme.spacing[16],
}));

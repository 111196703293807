import styled from 'styled-components';
import { LayoutHierarchy } from '@/constants';
import { CCPAWrapper, CCPAInnerContainer } from '../CCPA/CCPA.styles';
import CloseSvg from '../../../public/images/icons/close.svg';

export const CookieBannerContainer = styled.div`
  ${CCPAInnerContainer}
  z-index: ${LayoutHierarchy.First};
`;

export const CookieBannerInnerContainer = styled.div`
  ${CCPAWrapper}
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CloseButtonContainer = styled.div<{ on?: string }>`
  margin-left: ${({ theme }) => theme.spacing[32]};
  cursor: pointer;

  & > svg {
    width: ${({ theme }) => theme.spacing[28]};
    height: ${({ theme }) => theme.spacing[28]};

    > path {
      fill: ${({ theme }) => theme.colors.darkCharcoal};
      fill-opacity: 1;
    }
  }
`;

export const CloseButton = styled(CloseSvg)``;

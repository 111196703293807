import Image from 'next/image';
import type React from 'react';
import { useEffect, useState } from 'react';
import {
  CenterGraphic,
  ErrorPageButton,
  ErrorPageDescription,
  ErrorPageName,
  ErrorPageTitle,
  ErrorPageWrapper,
} from './ErrorPage.styles';

import { ErrorPageCodes, ErrorPageContent } from '@/constants';
import PageWithGraphic from '../PageLayout/PageWithGraphic';

interface ErrorPageProps {
  code: ErrorPageCodes;
}

const ErrorPage: React.FC<ErrorPageProps> = ({ code }) => {
  const [remainElementsHeight, setRemainElementsHeight] = useState<number>(0);

  if (!Object.values(ErrorPageCodes).includes(code)) {
    code = ErrorPageCodes.ERROR_404;
  }

  const errorContent = ErrorPageContent[code];

  useEffect(() => {
    const elements = document.querySelectorAll('#layout > *:not(#main)');

    let totalHeight = 0;
    if (elements.length) {
      elements.forEach((el) => {
        const { height: elHeight } = el.getBoundingClientRect();

        totalHeight += elHeight;
      });
    }
    setRemainElementsHeight(totalHeight);
  }, []);

  const redirectToHomepage = () => {
    window.location.href = '/';
  };

  return (
    <ErrorPageWrapper remainElementsHeight={remainElementsHeight}>
      <PageWithGraphic>
        <CenterGraphic>
          <Image src='/images/center-graphic.png' alt='error' fill sizes='100vw' />
        </CenterGraphic>
        <ErrorPageName>{errorContent.errorCode}</ErrorPageName>
        <ErrorPageTitle>{errorContent.title}</ErrorPageTitle>
        {errorContent.description && <ErrorPageDescription>{errorContent.description}</ErrorPageDescription>}
        {errorContent.buttonText && (
          <ErrorPageButton onClick={redirectToHomepage}>{errorContent.buttonText}</ErrorPageButton>
        )}
      </PageWithGraphic>
    </ErrorPageWrapper>
  );
};

export default ErrorPage;

import styled, { type DefaultTheme } from 'styled-components';
import { FilledButtonAsLink } from '../Button.styles';
import { ButtonColorKey } from '@/interfaces';

const constructButtonColor = (theme: DefaultTheme, color: ButtonColorKey | undefined) => {
  switch (color) {
    case ButtonColorKey.Yellow:
      return theme.colors.yellow;
    case ButtonColorKey.White:
    case ButtonColorKey.Transparent:
      return theme.colors.highEmphasisWhite;
    case ButtonColorKey.Blue:
    default:
      return theme.colors.blue;
  }
};

export const ButtonLink = styled(FilledButtonAsLink)<{ isPMTButton?: boolean }>`
  // TODO: Await fix #185478102 to correct size of button (eg: height from 50px to 48px) when adding border.
  border: 1px solid ${({ theme, color }) => constructButtonColor(theme, color)};
  color: ${({ theme, color }) => constructButtonColor(theme, color)};
  background: ${({ theme, color }) =>
    color === ButtonColorKey.Transparent || color === ButtonColorKey.White
      ? 'transparent'
      : theme.colors.highEmphasisWhite};

  & > span > svg,
  & > svg,
  & > span > svg path,
  & > svg path {
    fill: ${({ theme }) => theme.colors.blue};
  }

  ${({ theme, isPMTButton }) => theme.mediaBreakpointUp[isPMTButton ? 'lg' : 'md']} {
    :not([disabled]):hover {
      background: ${({ theme, color }) => {
        switch (color) {
          case ButtonColorKey.White:
          case ButtonColorKey.Transparent:
            return theme.colors.hoverBlack;
          case ButtonColorKey.Yellow:
            return theme.colors.yellowHover;
          case ButtonColorKey.Blue:
          default:
            return theme.colors.surface04;
        }
      }};
    }
  }
`;

export const Button = styled(ButtonLink).attrs({
  as: 'button',
})``;

export default { Button, ButtonLink };

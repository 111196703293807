export enum UnsubscribeSummaryEmailErrorMessage {
  TOKEN_EXPIRED = 'Token is expired',
  ERROR = 'Error occurred while validating token',
  TOKEN_INVALID = 'Token is invalid',
}

export enum UnsubscribeStatus {
  LOADING = 'Loading',
  EXPIRED = 'Expired',
  FAILED = 'Failed',
  SUCCESS = 'Success',
}

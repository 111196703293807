import styled from 'styled-components';
import { LayoutHierarchy } from '@/constants';
import { Button } from '@/components';
import { ButtonColorKey, ButtonVariant } from '@/interfaces';

export const HeaderWrapper = styled.div`
  width: 100%;
  height: 67px;
  background: ${({ theme }) => theme.colors.darkBlue};
  position: sticky;
  top: 0;
  z-index: ${LayoutHierarchy.Third};

  & > div,
  & > div > div {
    height: 100%;
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    height: 72px;
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  align-items: center;
  justify-content: space-between;
  padding-left: ${({ theme }) => theme.spacing['20']};
  padding-right: ${({ theme }) => theme.spacing['20']};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    padding: 0;
  }
`;

export const Logo = styled.div(({ theme }) => ({
  width: 189,
  height: 34,
  position: 'relative',

  img: {
    display: 'block',
  },

  [`${theme.mediaBreakpointUp.md}`]: {
    width: 207,
    height: 24,
  },
}));

export const ParcelMonitorLogo = styled.img.attrs({
  src: '/images/icons/monitor_logo.svg',
  alt: 'Parcel Monitor Logo',
})`
  width: 188px;
  height: 22px;
  object-fit: contain;
  display: block;

  ${({ theme }) => theme.mediaBreakpointUp.lg} {
    width: 207px;
    height: 24px;
  }
`;

export const ExploreCommunityLink = styled.a`
  margin-left: ${({ theme }) => theme.spacing[16]};
`;

export const ExploreCommunityButton = styled(Button).attrs({
  variant: ButtonVariant.Outlined,
  color: ButtonColorKey.Transparent,
})`
  max-width: 320px;
`;

export const AnnouncementLabel = styled.div`
  line-height: 12px;
  border-radius: 100px;
  text-transform: uppercase;
  padding: 3px ${({ theme }) => theme.spacing[8]};
  margin-left: ${({ theme }) => theme.spacing[8]};
  font-size: ${({ theme }) => theme.fontSizes[10]};
  background-color: ${({ theme }) => theme.colors.blue};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colors.highEmphasisWhite};
  letter-spacing: ${({ theme }) => theme.letterSpacing[2]};
`;

import styled from 'styled-components';
import { Flex } from '@/components/Common';

export const Container = styled.div(({ theme }) => ({
  marginTop: theme.spacing[24],
  '*': {
    color: 'transparent !important',
    textShadow: `0 0 6px ${theme.colors.highEmphasisBlack}`,
  },
  svg: {
    position: 'relative',
    opacity: 0.15,
  },
}));

export const CarrierLogo = styled.div(({ theme }) => ({
  width: 40,
  height: 40,
  background: theme.colors.surface04,
  borderRadius: 2,
  marginRight: theme.spacing[12],
}));

export const CarrierNameWrapper = styled(Flex)(() => ({
  alignItems: 'center',
}));

import { useGlobalState } from '@/hooks';
import type { Asset } from '@/interfaces';
import Image, { type ImageProps } from 'next/image';
import type { CSSProperties } from 'react';
import Video from './Video';
import { isImage, isVideo } from './utils';

type AssetRendererProps = {
  asset?: Asset;
  className?: string;
  ImageComponent?: React.FC;
  VideoComponent?: React.FC;
  videoProps?: {
    height?: {
      mobile?: number | string;
      desktop?: number | string;
    };
    style?: CSSProperties;
  };
  imageProps?: {
    // for next/image
    width?: ImageProps['width'];
    height?: ImageProps['height'];
    quality?: ImageProps['quality'];
    // for Product Mockup
    ref?: React.Ref<HTMLImageElement>;
    onLoad?: () => void;
    // for styled component
    [key: string]: unknown;
  };
};

const AssetRenderer: React.FC<AssetRendererProps> = ({
  asset,
  ImageComponent = Image,
  VideoComponent = Video,
  videoProps,
  imageProps,
}) => {
  const { getProperImageUrl } = useGlobalState();

  if (!asset) return null;

  if (isImage(asset)) {
    return <ImageComponent {...imageProps} src={getProperImageUrl(asset)} alt={asset.description || 'Image'} />;
  }

  if (isVideo(asset)) {
    return <VideoComponent {...videoProps} asset={asset} />;
  }

  return null;
};

export default AssetRenderer;

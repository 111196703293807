import Image from 'next/image';
import dayjs from 'dayjs';

import {
  Container,
  SocialSharing,
  RichText,
  SectionTitle,
  SectionWrapper,
  RightSection,
  Carousel,
  MembershipLabel,
  Breadcrumbs,
} from '@/components';
import {
  ArticleWrapper,
  Content,
  DateTime,
  ImageWrapper,
  InnerContent,
  LeftSection,
  Title,
  SubSectionWrapper,
  CustomBannerAdsense,
} from './Article.styles';

import {
  type ThumbnailProps,
  type IArticleProps,
  type IArticleWithoutSensitiveDataProps,
  type IRightPanel,
  ResponsiveCollection,
  PMCAdType,
} from '@/interfaces';

import { useGlobalState, useResponsive } from '@/hooks';

import { RichTextEntries } from '@/constants';
import { articleRichTextGTMButtonModify, checkIsActualDownloadButton } from '@/utils';

const Article: React.FC<{
  articleContent: IArticleProps;
  relatedArticles: ThumbnailProps[];
  rightSectionList: Array<IRightPanel>;
}> = ({ articleContent, relatedArticles, rightSectionList }) => {
  const { title, date, image, content, breadcrumbsTheme } = articleContent;
  const { pageUrl, isLoggedIn, getProperImageUrl } = useGlobalState();
  const { isMobile } = useResponsive([ResponsiveCollection.Mobile]);

  const formattedDate = date && dayjs(date).format('MMM DD, YYYY');
  const isNotFreeArticle = content?.links?.entries?.block?.some((block) => {
    const isActualDownloadButton = block?.__typename === RichTextEntries.Button && checkIsActualDownloadButton(block);
    const isRegistrationWall = block?.__typename === RichTextEntries.RegistrationWall;

    return isActualDownloadButton || isRegistrationWall;
  });

  const contentModified = content && articleRichTextGTMButtonModify(content, isLoggedIn);

  return (
    <ArticleWrapper>
      <Container>
        <Breadcrumbs title={title} breadcrumbsTheme={breadcrumbsTheme} />
        <InnerContent>
          <LeftSection>
            <SectionWrapper>
              {isNotFreeArticle && <MembershipLabel />}
              <Title>{title}</Title>
              <DateTime>{formattedDate}</DateTime>
              {image && (
                <ImageWrapper>
                  <Image
                    src={getProperImageUrl(image)}
                    alt={title || 'Article Image'}
                    fill
                    sizes='100vw'
                    style={{
                      objectFit: 'cover',
                      objectPosition: 'center',
                    }}
                  />
                </ImageWrapper>
              )}
              {contentModified && (
                <Content className='custom__richtext'>
                  <RichText
                    richText={contentModified}
                    hasWall={(articleContent as IArticleWithoutSensitiveDataProps).hasWall}
                    pageTitle={title}
                  />
                </Content>
              )}
              <SocialSharing url={pageUrl} />
            </SectionWrapper>
            <CustomBannerAdsense variant={PMCAdType.LEFT_SECTION} />
            <SubSectionWrapper>
              <SectionTitle>Also worth your time</SectionTitle>
              <Carousel carouselData={relatedArticles} slidesToShow={isMobile ? 2 : 3} />
            </SubSectionWrapper>
          </LeftSection>
          {rightSectionList && <RightSection rightSectionList={rightSectionList} />}
        </InnerContent>
      </Container>
    </ArticleWrapper>
  );
};

export default Article;

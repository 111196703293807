import { LinkWrapper } from '@/components';
import { useResponsive } from '@/hooks';
import { ResponsiveCollection, SectionThemeKey } from '@/interfaces';
import { getBreadcrumbData, truncateTextInObject } from '@/utils';
import { useRouter } from 'next/router';
import type { FC } from 'react';
import {
  Angle,
  BreadcrumbItem,
  BreadcrumbLabel,
  BreadcrumbsContainer,
  BreadcrumbsSectionContainer,
} from './Breadcrumbs.styles';

export interface IBreadcrumbs {
  breadcrumbsTheme?: SectionThemeKey;
  title?: string;
}

const Breadcrumb: FC<IBreadcrumbs> = ({ breadcrumbsTheme = SectionThemeKey.Dark, title }) => {
  const { asPath } = useRouter();
  const breadcrumbsData = getBreadcrumbData({
    asPath,
    title,
  });
  const { isMobile } = useResponsive([ResponsiveCollection.Mobile]);

  if (!breadcrumbsData?.length) return null;

  const numberOfAngle = breadcrumbsData.length - 1;
  const maxChar = isMobile ? 45 : 96;

  const truncatedBreadcrumbsData = truncateTextInObject(
    breadcrumbsData,
    'label',
    maxChar - numberOfAngle * 4, // 4 is number of characters equal to the width of the angle
  );

  return (
    <BreadcrumbsSectionContainer>
      <BreadcrumbsContainer breadcrumbsTheme={breadcrumbsTheme}>
        {truncatedBreadcrumbsData.map(({ label, slug }, index, arr) => {
          return (
            <BreadcrumbItem key={slug}>
              <LinkWrapper href={slug}>
                <BreadcrumbLabel>{label}</BreadcrumbLabel>
              </LinkWrapper>
              {index < arr.length - 1 && <Angle />}
            </BreadcrumbItem>
          );
        })}
      </BreadcrumbsContainer>
    </BreadcrumbsSectionContainer>
  );
};

export default Breadcrumb;

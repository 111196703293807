import styled from 'styled-components';
import { SectionWrapper } from '@/components';
import { ThumbnailImage, ThumbnailWrapper } from '../../../Thumbnail/Thumbnail.styles';

export const ThumbnailSectionContainer = styled.div`
  padding-bottom: ${({ theme }) => theme.spacing[20]};

  > button {
    width: calc(100% - ${({ theme }) => theme.spacing[40]});
    margin: 0 ${({ theme }) => theme.spacing[20]};
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    > button {
      width: 100%;
      margin: 0;
    }
  }

  ${({ theme }) => theme.mediaBreakpointDown.sm} {
    ${ThumbnailWrapper}:not(:last-child) {
      margin-bottom: ${({ theme }) => theme.spacing[20]}
    }

    ${ThumbnailImage} {
      width: 100%;
    }
  }
`;

export const ThumbnailSectionWrapper = styled(SectionWrapper)`
  display: grid;
  gap: ${({ theme }) => theme.spacing[20]};
`;

export const ThumbnailGridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${({ theme }) => theme.spacing[20]};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

import type { Asset, IForm } from '@/interfaces';

export enum OnboardingStepName {
  intro = 'intro',
  joinUs = 'joinUs',
  followUs = 'followUs',
  accountVerification = 'accountVerification',
  aboutYou = 'aboutYou',
  thankYou = 'thankYou',
  finish = 'finish',
}

export interface ILinkItem {
  title?: string;
  url?: string;
}

export interface IOnboardingPopup {
  sys: { id: string };
  newUserTriggerTimes?: Array<number> | null;
  returnUserTriggerTimes?: Array<number> | null;
  title?: string;
  excludedSlugs?: Array<ILinkItem> | null;
  joinUsHeader?: string;
  joinUsValuePropositionList?: Array<Asset>;
  joinUsTitle?: string;
  joinUsMobileTitle?: string;
  joinUsSubtext?: string;
  joinUsMobileSubtext?: string;
  joinUsImage?: Asset;
  followUsHeader?: string;
  followUsValuePropositionList?: Array<Asset>;
  followUsTitle?: string;
  followUsMobileTitle?: string;
  followUsSubtext?: string;
  followUsMobileSubtext?: string;
  followUsImage?: Asset;
  aboutYouHeader?: string;
  aboutYouValuePropositionList?: Array<Asset>;
  aboutYouTitle?: string;
  aboutYouSubtext?: string;
  aboutYouForm?: IForm;
  thankYouHeader?: string;
  thankYouValuePropositionList?: Array<Asset>;
  thankYouTitle?: string;
  thankYouSubtext?: string;
  thankYouImage?: Asset;
  variant?: string;
  zapierHookId?: string;
  aboutYouClicksLabel?: string;
  progressIndicator?: boolean;

  // for tracker progress
  currentStep?: OnboardingStepName;
}

export interface IPanel {
  header?: string;
  valuePropositionList?: Array<Asset>;
  title?: string;
  subtext?: string;
  image?: Asset;
  form?: IForm;
  Component?: React.FC<any>;
  zapierHookId?: string;
  currentStep?: OnboardingStepName;
  // for RightPanel
  closePopUp?: () => void;
  isFullScreen?: boolean;
  progressIndicator?: boolean;
  // A function to go to next step
  onStepSuccess?: (props?: Record<string, any>) => void;
  // A function for step progress' click
  onStepClick?: (props?: Record<string, any>) => void;
}

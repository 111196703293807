import { TooltipVariant } from '@/constants';
import { Tooltip } from '@/components';

interface TableHeaderTextProps {
  content?: string;
  children: any;
}

/**
 * @param children text to display in the table header (ReactElement)
 * @param content tooltip to display when hovering/clicking the label's icon
 * @returns table header text with tooltip
 * @example
 * <TableHeaderText>Label</TableHeaderText>
 * <TableHeaderText content='tooptip content'>Tooltip trigger</TableHeaderText>
 */
const TableHeaderText: React.FC<TableHeaderTextProps> = (props) => {
  const { content, children } = props;
  if (!content) return children;

  return (
    <Tooltip content={content} variant={TooltipVariant.Icon} iconHeight={24}>
      {children}
    </Tooltip>
  );
};

export default TableHeaderText;

import Adsense from '@/components/Adsense';
import { type PMTAdType, pmtAdUnits } from '@/modules/pmt/constants';
import { useAdsContext } from '@/modules/pmt/context';
import type { FC } from 'react';

type PMTAdsenseProps = { variant: PMTAdType; id?: string; className?: string };

export const PMTAdsense: FC<PMTAdsenseProps> = ({ variant, ...props }) => {
  const { currentAdSetup } = useAdsContext();

  if (!currentAdSetup || !pmtAdUnits[currentAdSetup][variant]?.dataAdSlot) return null;

  return <Adsense {...props} {...pmtAdUnits[currentAdSetup][variant]} />;
};

import type { ICompany } from '@/interfaces';

export enum PeopleVariant {
  Default = 0,
  WithQuotes = 1,
  Border = 2,
}

export interface IPeople {
  sys: { id: string };
  fullName: string;
  company: ICompany;
  jobPosition: string;
  jobSeniority: string;
  subHeader: string;
  personDescription: string;
  profileLink: string;
  image: {
    contentType?: string;
    url: string;
  };
  quotes?: string;
  ctaText?: string;
  article?: {
    articleType?: string;
    slug?: string;
  };
  variant?: PeopleVariant;
}

import { useMemo, useState, type ReactNode, type HTMLProps, forwardRef, useContext, createContext } from 'react';
import {
  size,
  shift,
  offset,
  useRole,
  useHover,
  useClick,
  autoUpdate,
  useDismiss,
  useFloating,
  safePolygon,
  useMergeRefs,
  useInteractions,
  FloatingPortal,
} from '@floating-ui/react';
import type { Placement } from '@floating-ui/react';
import { TextStyles, MessageField, ErrorMessages, TooltipVariant } from '@/constants';
import { useResponsive } from '@/hooks';
import { Typography } from '@/components';
import { ResponsiveCollection } from '@/interfaces';
import { IconWrapper, FlexContainer, InfoIconContainer, TooltipInnerContent } from './Tooltip.styles';
import InfoOutlineIcon from '../../public/images/icons/info-outline.svg';

interface TooltipOptions {
  variant?: TooltipVariant;
  placement?: Placement;
}

const TOOLTIP_GAP = 8;

export function useTooltip({ variant, placement = 'bottom' }: TooltipOptions = {}) {
  const [open, setOpen] = useState(false);
  const { isDesktop } = useResponsive([ResponsiveCollection.Desktop]);

  const data = useFloating({
    placement,
    open, // uncontrolled open state
    onOpenChange: setOpen, // update the open state when the tooltip opens or closes
    whileElementsMounted: autoUpdate, // ensure the tooltip remains anchored to the reference element by updating the position
    middleware: [
      offset(TOOLTIP_GAP), // spacing between the trigger and the tooltip
      shift({
        // shift the tooltip if it's outside the viewport (calculated by lib)
        padding: TOOLTIP_GAP, // 0 by default
      }),
      size({
        padding: TOOLTIP_GAP,
        apply({ availableWidth, elements }) {
          const defaultMaxWidth = variant === TooltipVariant.Icon ? 256 : 500;
          const defaultMaxHeight = variant === TooltipVariant.Icon ? 124 : 44;
          Object.assign(elements.floating.style, {
            maxWidth: `${Math.min(defaultMaxWidth, availableWidth)}px`,
            maxHeight: `${defaultMaxHeight}px`,
          });
        },
      }),
    ],
  });

  const context = data.context;

  // open when hovering the text, close when leaving the text
  const hover = useHover(context, {
    enabled: isDesktop, // disable hover on mobile
    mouseOnly: true, // ignore touch and pen events
    handleClose: safePolygon(), // close when mouse leaves the tooltip in a safe zone (calculated by lib)
  });

  // open when clicking the text
  const click = useClick(context, {
    toggle: false, // disable clicking the text to toggle
    enabled: !isDesktop, // disable click on desktop
  });

  // close when clicking outside the tooltip + text
  const dismiss = useDismiss(context, {
    escapeKey: false, // pressing esc will not close tooltip
  });

  // returns ARIA attribute props
  const role = useRole(context, { role: 'tooltip' });

  const interactions = useInteractions([hover, click, dismiss, role]);

  return useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data,
    }),
    [open, setOpen, interactions, data],
  );
}

type ContextType = ReturnType<typeof useTooltip> | null;

const TooltipContext = createContext<ContextType>(null);

export const useTooltipContext = () => {
  const context = useContext(TooltipContext);

  if (context == null) {
    throw new Error(ErrorMessages[MessageField.TOOLTIP_INCORRECT_STRUCTURE].error);
  }

  return context;
};

export function TooltipWrapper({ children, ...options }: { children: ReactNode } & TooltipOptions) {
  const tooltip = useTooltip(options);
  return <TooltipContext.Provider value={tooltip}>{children}</TooltipContext.Provider>;
}

export const TooltipTrigger = forwardRef<HTMLElement, HTMLProps<HTMLElement>>(function TooltipTrigger(
  { children, ...props },
  propRef,
) {
  const context = useTooltipContext();
  const childrenRef = (children as any).ref;
  const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

  return (
    <div ref={ref} {...context.getReferenceProps(props)}>
      {children}
    </div>
  );
});

export const TooltipContent = forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement>>(
  function TooltipContent(props, propRef) {
    const context = useTooltipContext();
    const ref = useMergeRefs([context.refs.setFloating, propRef]);

    return (
      <FloatingPortal>
        {context.open && (
          <div
            ref={ref}
            style={{
              position: context.strategy,
              top: context.y ?? 0,
              left: context.x ?? 0,
              width: 'max-content',
              visibility: context.x == null ? 'hidden' : 'visible',
              ...props.style,
            }}
            {...context.getFloatingProps(props)}
          />
        )}
      </FloatingPortal>
    );
  },
);

type TooltipProps = {
  content?: string;
  children: ReactNode;
  iconHeight?: number;
  variant?: TooltipVariant;
};

/**
 * Tooltip component
 * @param content - tooltip content
 * @param children - tooltip trigger
 * @param iconHeight - height of the info icon, same as line-height of tooltip trigger
 * @param variant - TooltipVariant
 * @returns
 * @example
 * // default - fullWidth
 * <Tooltip content='Tooltip content'>
 *  <Typography>Hover me</Typography>
 * </Tooltip>
 * @example
 * // has Icon
 * <Tooltip
 *  content='Tooltip content'
 *  variant={TooltipVariant.Icon}
 *  iconHeight={24}
 * >
 *  <Typography>Click me</Typography>
 * </Tooltip>
 */
const Tooltip = ({ content, children, iconHeight, variant = TooltipVariant.FullWidth }: TooltipProps) => {
  const renderTooltipTrigger = () => {
    switch (variant) {
      case TooltipVariant.Icon:
        return (
          <>
            <div>{children}</div>
            <TooltipTrigger style={{ width: 'auto' }}>
              <InfoIconContainer iconHeight={iconHeight}>
                <IconWrapper>
                  <InfoOutlineIcon />
                </IconWrapper>
              </InfoIconContainer>
            </TooltipTrigger>
          </>
        );
      case TooltipVariant.FullWidth:
      default:
        return <TooltipTrigger style={{ width: '100%' }}>{children}</TooltipTrigger>;
    }
  };

  return (
    <TooltipWrapper variant={variant} placement={variant === TooltipVariant.FullWidth ? 'bottom-start' : 'bottom'}>
      <FlexContainer variant={variant}>{renderTooltipTrigger()}</FlexContainer>
      {content && (
        <TooltipContent>
          <TooltipInnerContent variant={variant}>
            <Typography className={`tooltip__content--${variant}`} variant={TextStyles['Caption Text']}>
              {content}
            </Typography>
          </TooltipInnerContent>
        </TooltipContent>
      )}
    </TooltipWrapper>
  );
};

export default Tooltip;

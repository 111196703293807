import type { ISessionData } from '@/interfaces';
import { getSessionData } from '@/utils/cookies.utils';
import getConfig from 'next/config';
import type { NextApiRequestCookies } from 'next/dist/server/api-utils';

const { publicRuntimeConfig } = getConfig();

export const getGaClientId = () => {
  const cookie: any = {};
  document.cookie.split(';').forEach((el) => {
    const splitCookie = el.split('=');
    const key = splitCookie[0]?.trim();
    const value = splitCookie[1];
    cookie[key] = value;
  });
  return cookie['_ga']?.substring(6);
};

export const getSessionId = (isMyParcelsPage: boolean) => {
  let sessionId = '';
  const callbackGetSessionId = (sessionsData: Array<ISessionData>) => {
    sessionId = sessionsData[0]?.sessionId;
  };
  getSessionData(isMyParcelsPage, callbackGetSessionId);

  return sessionId;
};

export const getSessionIdViaCookies = (cookies: NextApiRequestCookies) => {
  if (!cookies) return '';

  const fullId = publicRuntimeConfig.GA4_PM_ID;
  const id = fullId.split('-').pop();
  const cookieKey = Object.keys(cookies).find((cookie) => cookie === `_ga_${id}`) || '';
  if (!cookieKey) return '';

  const pattern = /GS\d\.\d\.(.+?)(?:;|$)/;
  const sessionId = cookies[cookieKey]?.match(pattern)?.[1]?.split('.').shift();

  return sessionId || '';
};

import getConfig from 'next/config';
import Cookies from 'js-cookie';
import { CookieKey, GA4SessionType } from '@/constants';
import type { ISessionData } from '@/interfaces';

const { publicRuntimeConfig } = getConfig();

export const removeCookies = () => {
  Cookies.remove(CookieKey.NewBrowserUuid);
  Cookies.remove(CookieKey.UserEmail);
};

type GetSessionData = (isMyParcelsPage: boolean, callback: (data: any) => void) => void;

export const getSessionData: GetSessionData = (isMyParcelsPage, callback) => {
  const ga4FullIds = isMyParcelsPage
    ? [publicRuntimeConfig.GA4_PM_ID]
    : [publicRuntimeConfig.GA4_PM_ID, publicRuntimeConfig.GA4_PMC_ID];

  const partsArray: Array<ISessionData> = [];

  ga4FullIds.forEach((fullId) => {
    const id = fullId.split('-').pop();
    const pattern = new RegExp(`_ga_${id}=GS\\d\\.\\d\\.(.+?)(?:;|$)`);
    const match = document.cookie.match(pattern);
    const parts = match?.[1]?.split('.');

    if (!parts) return;
    partsArray.push({
      ga4Id: fullId,
      sessionId: parts.shift() || '',
      sessionNumber: parts.shift() || '',
    });
  });

  if (partsArray.length < ga4FullIds.length) {
    // Cookie not yet available; wait a bit and try again.
    window.setTimeout(() => getSessionData(isMyParcelsPage, callback), 200);
    return;
  }
  return callback(partsArray);
};

type SetAndCheckNewSession = (sessionData: Array<ISessionData>) => boolean;

export const setAndCheckNewSession: SetAndCheckNewSession = (sessionsData) => {
  if (!sessionsData.length) return false;

  const sessionTypes: Array<GA4SessionType> = sessionsData.map((sessionData) => {
    const { ga4Id, sessionId, sessionNumber } = sessionData;
    const ga4IdKey = 'pm_ga_' + ga4Id.split('-').pop();
    const ga4IdValue = `GA4.${sessionId}.${sessionNumber}`;

    if (Cookies.get(ga4IdKey) === ga4IdValue) return GA4SessionType.ExistingSession;
    Cookies.set(ga4IdKey, ga4IdValue, { expires: 1 / 48 }); // 30 minutes
    return GA4SessionType.NewSession;
  });

  return sessionTypes.includes(GA4SessionType.NewSession);
};

import Image from 'next/image';
import type { ILeadsWrapper } from '@/interfaces';
import { List, Item, Title, Description, ContentWrapper } from './ValuePropositionList.styles';

interface ValuePropositionListProps {
  theme: ILeadsWrapper['theme'];
  items: ILeadsWrapper['valuePropositionList'];
}

const ValuePropositionList: React.FC<ValuePropositionListProps> = ({ theme: sectionTheme, items }) => {
  if (!items?.length) return null;

  return (
    <List sectionTheme={sectionTheme}>
      {items.map((valueItem, index) => (
        <Item key={index}>
          <Image
            src={valueItem?.url as string}
            alt={valueItem?.description || 'Proposition Icon'}
            height={24}
            width={24}
          />
          <ContentWrapper>
            <Title>{valueItem?.title}</Title>
            <Description>{valueItem?.description}</Description>
          </ContentWrapper>
        </Item>
      ))}
    </List>
  );
};

export default ValuePropositionList;

import { useGeo, useGlobalState } from '@/hooks';
import type { IUserInfo } from '@/interfaces';
import { getCountryConfig } from '@/modules/pmt/fetchers';
import type { IEmailSyncAccount } from '@/modules/pmt/interfaces';
import { useEffect, useRef, useState } from 'react';

const WHITE_LISTED_EMAILS = [
  'bcmjtest@gmail.com',
  'bianca.jimeno@u.nus.edu',
  'cwitte@gmail.com',
  'catta.babay@gmail.com',
  'lamquynhnhu96@gmail.com',
];

const checkIsESAllowed = (userInfo: IUserInfo) => {
  const isPpUser = userInfo.email.includes('@parcelperform.com');
  const isWhitelisted = WHITE_LISTED_EMAILS.includes(userInfo.email);
  return (isPpUser || isWhitelisted) && !userInfo.emailSyncOptIn;
};

export const useIsEmailSyncAllowed = ({
  emailSyncData,
  hasEmailAccounts,
}: {
  emailSyncData?: IEmailSyncAccount;
  hasEmailAccounts?: boolean;
}) => {
  const { geoInfo } = useGeo();
  const { userInfo, isLoggedIn } = useGlobalState();
  // TODO: Migrate isEmailSyncAllowed to isESAllowed
  const [isEmailSyncAllowed, setIsEmailSyncAllowed] = useState(false);
  const [isESAllowed, setIsEsAllowed] = useState<'unitialized' | 'allowed' | 'not-allowed'>('unitialized');
  const isCountryAllowES = useRef<'unitialized' | boolean>('unitialized');

  useEffect(() => {
    // wait for geoInfo to finish
    if (!geoInfo.fetched) return;
    (async () => {
      try {
        if (isCountryAllowES.current === 'unitialized') {
          // if API fails, we treat it as if country does not allow email sync
          isCountryAllowES.current = (await getCountryConfig({ countryCode: geoInfo.country })) ?? false;
        }

        // for non-logged-in users, we only check for country config of user's country
        if (!isLoggedIn || !userInfo) {
          setIsEmailSyncAllowed(!!isCountryAllowES.current);
          setIsEsAllowed(isCountryAllowES.current ? 'allowed' : 'not-allowed');
          return;
        }

        // for logged-in users && country is not allowed, we need to check if they previously used ES,
        // OR they are whitelisted
        if (!isCountryAllowES.current) {
          const isEsyncAllowed = checkIsESAllowed(userInfo);
          setIsEmailSyncAllowed(isEsyncAllowed || !!hasEmailAccounts);
          setIsEsAllowed(isEsyncAllowed || !!hasEmailAccounts ? 'allowed' : 'not-allowed');
          // setIsCountryAllowed(!!hasEmailAccounts);
          return;
        }

        if (!emailSyncData) return;
        // if country is allowed, we check for available spots left for current user
        const hasSpots = emailSyncData.total < emailSyncData.maximumAccountAllowed;
        setIsEsAllowed(hasSpots ? 'allowed' : 'not-allowed');
        setIsEmailSyncAllowed(hasSpots);
      } catch (err) {
        console.error('Could not email sync config', err);
      }
    })();
  }, [emailSyncData, geoInfo.fetched, geoInfo.country, hasEmailAccounts, userInfo, isLoggedIn]);

  return { isEmailSyncAllowed, isESAllowed };
};

import type React from 'react';
import { NextSeo } from 'next-seo';
import { BottomLeftGraphic, TopRightGraphic } from './PageWithGraphic.styles';

const PageWithGraphic: React.FC = ({ children }) => {
  return (
    <>
      <NextSeo
        title='Parcel Monitor'
        defaultTitle='Parcel Monitor'
        additionalLinkTags={[
          {
            rel: 'icon',
            href: '/favicons/favicon-32x32.png',
            type: 'image/png',
            sizes: '32x32',
          },
          {
            rel: 'icon',
            href: '/favicons/favicon-16x16.png',
            type: 'image/png',
            sizes: '16x16',
          },
        ]}
        additionalMetaTags={[
          {
            name: 'msapplication-TileColor',
            content: '#ffc03f',
          },
          {
            property: 'theme-color',
            content: '#ffffff',
          },
        ]}
      />
      <BottomLeftGraphic />
      <TopRightGraphic />
      {children}
    </>
  );
};

export default PageWithGraphic;

import styled from 'styled-components';
import { SectionThemeKey } from '@/interfaces';
import { Container } from '@/components';
import { MobileSubtext, MobileTitle } from '../WidgetTextSection/WidgetTextSection.styles';

export const TrackingWidgetWrapper = styled(Container)`
  display: none;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    position: absolute;
    display: block;
    bottom: 2px;
    left: 50%;
    transform: translate(-50%, 50%);
  }
`;

export const TrackingWidgetMobileWrapper = styled(Container)`
  display: block;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: none;
  }
`;

export const BannerWithTrackingWidgetWrapper = styled.div<{
  sectionTheme: SectionThemeKey;
}>`
  position: relative;
  width: 100%;
  padding: ${({ theme }) => theme.spacing[20]};
  padding-bottom: 117px;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04),
    0px 2px 4px rgba(96, 97, 112, 0.16);

  background-color: ${({ sectionTheme, theme }) => {
    return sectionTheme === SectionThemeKey.Light ? theme.colors.highEmphasisWhite : theme.colors.darkBlue;
  }};

  ${MobileTitle} {
    color: ${({ sectionTheme, theme }) => {
      return sectionTheme === SectionThemeKey.Light ? theme.colors.darkBlue : theme.colors.highEmphasisWhite;
    }};
  }

  ${MobileSubtext} {
    color: ${({ sectionTheme, theme }) => {
      return sectionTheme === SectionThemeKey.Light ? theme.colors.mediumEmphasisBlack : theme.colors.highEmphasisWhite;
    }};
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    min-height: 269px;
    margin-bottom: 38.5px;
    padding: ${({ theme }) => theme.spacing[32]} 0;
    padding-bottom: 80px;
  }
`;

export const ButtonGroupWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing[20]};
`;

import { LineChartType, BlurredTextDataType, LineTitleType } from '@/interfaces';
import { CountryOption } from '@/components';

// Components
import TradeLane from '../TradeLane';
import { BlurredText } from '../../Table/CustomTableComponents';

// Styles
import { YearText } from '../LineMetrics.styles';
import { CarrierName } from '../Carrier/Carrier.styles';
import { CellSkeletonContainer } from '../../Table/Table.styles';
import { CarrierNameWrapper, CarrierLogo, Container } from './Skeleton.styles';
import { Line, Value, LineHeader, LinesWrapper, LineItemWrapper } from '../LineItem/LineItem.styles';

interface SkeletionProps {
  lineType: LineChartType;
  titleType: LineTitleType;
  data?: (string | number)[][];
}

const placeholderText = {
  lineValue: '--',
  dashedLineValue: '/ --',
  originCountryCode: 'US',
  destinationCountryCode: 'DE',
  year: '2023',
};

const Skeleton: React.FC<SkeletionProps> = ({ lineType, titleType, data = [[]] }) => {
  const { lineValue, dashedLineValue, originCountryCode, destinationCountryCode, year } = placeholderText;

  const CarrierComponent = (
    <CellSkeletonContainer>
      <CarrierNameWrapper>
        <CarrierLogo />
        <CarrierName>
          <BlurredText dataType={BlurredTextDataType.shortText} />
        </CarrierName>
      </CarrierNameWrapper>
    </CellSkeletonContainer>
  );

  const CountryComponent = (
    <CellSkeletonContainer>
      <CountryOption code={originCountryCode} />
    </CellSkeletonContainer>
  );

  const YearComponent = (
    <CellSkeletonContainer>
      <YearText>{year}</YearText>
    </CellSkeletonContainer>
  );

  const TradelaneComponent = (
    <CellSkeletonContainer>
      <TradeLane originCountryCode={originCountryCode} destinationCountryCode={destinationCountryCode} />
    </CellSkeletonContainer>
  );

  const titleMapping = {
    [LineTitleType.Carrier]: CarrierComponent,
    [LineTitleType.Country]: CountryComponent,
    [LineTitleType.Year]: YearComponent,
    [LineTitleType.Tradelane]: TradelaneComponent,
  };

  const renderLines = () => (
    <LineItemWrapper type={lineType}>
      <LineHeader>
        {titleMapping[titleType]}
        <div>
          {data?.map((entry, i) => {
            if (entry?.length > 1 && i !== 0) {
              return <Value key={i}>{dashedLineValue}</Value>;
            }
            return <Value key={i}>{lineValue}</Value>;
          })}
        </div>
      </LineHeader>
      <LinesWrapper>
        {data.map((_, index) => {
          const width = lineType === LineChartType.ONE_LINE ? 50 : 100 / (data.length - index);
          return <Line key={index} width={width.toString()} />;
        })}
        {lineType === LineChartType.ONE_LINE && <Line />}
      </LinesWrapper>
    </LineItemWrapper>
  );

  return <Container>{renderLines()}</Container>;
};

export default Skeleton;

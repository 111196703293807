import type { IShipmentAdditionalInfo } from '../interfaces';

const DISCOUNT_REGEX = new RegExp(/(discounts_(.+)_amount)/);

// List of all additional_info.discounts_{n}_amount
const transformDiscounts = (customFields: Record<string, string>) => {
  const filteredKeys: string[] = Object.keys(customFields).filter((keys) => DISCOUNT_REGEX.test(keys));

  const discountList = filteredKeys.reduce<string[]>((acc, key) => {
    const fieldData = customFields[key];
    if (fieldData) {
      acc.push(fieldData);
    }
    return acc;
  }, []);

  return discountList?.length > 0 ? discountList : undefined;
};

const transformUserId = (customFields: Record<string, string>[]) => {
  const userIdRecord = customFields.find((field) => field.key === 'user_id');
  return userIdRecord?.value;
};

export const transformCustomFields = (customFields: any): IShipmentAdditionalInfo | undefined => {
  if (!customFields) return undefined;

  const modifiedCustomFields = customFields.reduce(
    (acc: Record<string, string>, field: { key: string; value: string }) => {
      acc[field.key] = field.value;
      return acc;
    },
    {},
  );

  return {
    emailAccount: modifiedCustomFields.email_account,
    emailSender: modifiedCustomFields.email_sender,
    senderDomain: modifiedCustomFields.sender_domain,
    orderUrl: modifiedCustomFields.order_url,
    orderSourceNameSiteLink: modifiedCustomFields.order_source_name_site_link,
    orderSourceLogo: modifiedCustomFields.order_source_logo,
    orderTotalPrice: modifiedCustomFields.order_total_price,
    orderTotalTax: modifiedCustomFields.order_total_tax,
    discounts: transformDiscounts(modifiedCustomFields),
    userId: transformUserId(customFields),
    label: modifiedCustomFields.label,
  };
};

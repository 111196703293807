import {
  type IPmtOnboardingPopup,
  type ITrackingLeftPanel,
  type ITrackingRightPanel,
  PmtSignUpStep,
  PmtOnboardingPopupCta,
} from './trackingOnboardingPopup.interfaces';
import type { GtmClickFilter, GtmClickParcel } from './gtm.interfaces';
import { ShipmentSubscribeState } from './subscribe.interfaces';
import { EmailConnectedBannerVariant } from './emailSync.interfaces';

export type { GtmClickFilter, GtmClickParcel, IPmtOnboardingPopup, ITrackingLeftPanel, ITrackingRightPanel };

export { ShipmentSubscribeState, PmtSignUpStep, PmtOnboardingPopupCta, EmailConnectedBannerVariant };

import type { Asset, ISEOFields, IRightPanel, ButtonColorKey, SectionThemeKey, ISingleItem, IRichText } from '@/interfaces';
export enum Employees {
  '1-10 Employees' = '1-10 Employees',
  '11-50 Employees' = '11-50 Employees',
  '51-200 Employees' = '51-200 Employees',
  '201-500 Employees' = '201-500 Employees',
  '501-1000 Employees' = '501-1000 Employees',
  '1001-5000 Employees' = '1001-5000 Employees',
  '5001-10,000 Employees' = '5001-10,000 Employees',
  '10,000+ Employees' = '10,000+ Employees',
}

export interface IIndividualCarrier {
  sys: {
    id: string;
    publishedAt?: string;
  };
  slug: string;
  breadcrumbsTheme?: SectionThemeKey;
  carrierName: string;
  carrierDisplayName: string;
  carrierLogo?: Asset;
  desktopBannerImage?: Asset;
  mobileBannerImage?: Asset;
  seoDescription?: IRichText;
  button?: {
    primaryButtonText: string;
    function?: string;
    color?: ButtonColorKey;
    buttonLink?: string;
    buttonEventLabel?: string;
    openInNewTab?: boolean;
  };
  overviewTitle?: string;
  headquarters?: string;
  northAmerica?: ISingleItem[];
  europe?: ISingleItem[];
  oceania?: ISingleItem[];
  foundedDate?: string;
  industry?: string;
  employees?: Employees;
  website?: string;
  linkedin?: string;
  featured?: boolean;
  sectionList?: any[];
  seo: ISEOFields;
  rightSectionList: Array<IRightPanel>;
}

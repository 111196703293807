import type { AnchorHTMLAttributes, ComponentType } from 'react';

import { LoginButtonWrapper, Logo, Text } from './SsoLoginButton.styles';

interface ISsoLoginButtonProps extends AnchorHTMLAttributes<'a'> {
  disabled: boolean;
  buttonText: string;
  logoUrl: string;
  backgroundColor?: string;
  onButtonClick: () => void;
  as?: string | ComponentType<any>;
}

const SsoLoginButton: React.FC<ISsoLoginButtonProps> = ({
  disabled,
  buttonText,
  logoUrl,
  backgroundColor,
  onButtonClick,
  ...restProps
}) => {
  return (
    <LoginButtonWrapper disabled={disabled} onClick={onButtonClick} backgroundColor={backgroundColor} {...restProps}>
      <Logo>
        <img src={logoUrl} />
      </Logo>
      <Text>{buttonText}</Text>
    </LoginButtonWrapper>
  );
};

export default SsoLoginButton;

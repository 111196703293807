import styled from 'styled-components';
import { Typography } from '@/components';
import { TextStyles } from '@/constants';
import { styledText } from '@/components/Typography/Typography.styles';
import PMLogo from '../../../public/images/icons/monitor_logo.svg';
import CloseIcon from '../../../public/images/icons/close.svg';

const FULL_SCREEN_DESKTOP_PANEL_HEIGHT = 72;
const DESKTOP_PANEL_HEIGHT = 40;

const clampText = `
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
`;

export const PanelWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: ${({ theme }) => `${theme.spacing[24]} ${theme.spacing[20]}`};
  background: ${({ theme }) => theme.colors.highEmphasisWhite};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    overflow: auto;
    padding: ${({ theme }) => theme.spacing[40]};
  }
`;

export const PanelTitle = styled(Typography).attrs({
  variant: TextStyles['Heading 2'],
})`
  color: ${({ theme }) => theme.colors.highEmphasisBlack};
  text-align: center;
  ${clampText}
  -webkit-line-clamp: 2;
  word-break: break-word;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    ${styledText[TextStyles['Heading 1']]}
    margin-bottom: ${({ theme }) => theme.spacing[4]};
  }
`;

export const PanelSubtext = styled(Typography).attrs({
  variant: TextStyles['Body Text Small'],
})`
  ${({ theme }) => `
    color: ${theme.colors.mediumEmphasisBlack};
  `};

  ${clampText}
  -webkit-line-clamp: 2;
  text-align: center;
  word-break: break-word;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    ${styledText[TextStyles['Body Text Large']]}
  }
`;

export const ComponentWrapper = styled.div`
  display: flex;
  flex: 1;
`;

export const StepWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    padding: 0 ${({ theme }) => theme.spacing[40]};
  }
`;

export const PanelHeader = styled.div`
  overflow: hidden;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: none;
  }
`;

export const RightPanelWrapper = styled.div<{ isFullScreen?: boolean }>`
  height: ${({ isFullScreen }) => (isFullScreen ? '100%' : 'unset')};

  ${PanelHeader} {
    height: ${DESKTOP_PANEL_HEIGHT}px;
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    height: unset;
    display: flex;
    flex: 1;

    ${PanelWrapper} {
      height: 617px;
    }
  }

  ${({ theme, isFullScreen }) =>
    isFullScreen &&
    `
    ${PanelWrapper} {
      height: calc(100% - ${FULL_SCREEN_DESKTOP_PANEL_HEIGHT}px); // overwrite style
      padding: ${theme.spacing[20]} ${theme.spacing[20]} ${theme.spacing[40]};
    }

    ${PanelTitle} {
      -webkit-line-clamp: 3;
    }

    ${PanelSubtext} {
      -webkit-line-clamp: 3;
      ${styledText[TextStyles['Body Text Large']]};
    }

    ${PanelHeader} {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: ${theme.spacing[20]};
      background: ${theme.colors.darkBlue};
      height: ${FULL_SCREEN_DESKTOP_PANEL_HEIGHT}px; // overwrite style
    }
  `}
`;

export const Logo = styled(PMLogo)`
  display: block;
  width: 175px;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: none;
  }
`;

export const PanelHeaderLink = styled.a<{ on?: string }>``;

export const CloseButton = styled(CloseIcon)<{ on?: string }>`
  cursor: pointer;
  width: 24px;
  height: 24px;
`;

export const PanelHeaderImage = styled.div({
  position: 'relative',
  height: '100%',
});

import { TextStyles } from '@/constants';
import { ButtonVariant } from '@/interfaces';
import styled from 'styled-components';
import Button from '../Button';
import Typography from '../Typography';
import { styledText } from '@/components/Typography/Typography.styles';
import ArrowForward from '../../public/images/icons/arrow_forward.svg';

export const ErrorPageWrapper = styled.div<{ remainElementsHeight?: number }>`
  position: relative;
  min-height: calc(
    100vh - ${({ remainElementsHeight }) => remainElementsHeight}px
  );
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
  padding: 70px 0;
  background: ${({ theme }) => theme.colors.pageBackground};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    padding: 147px 0;
  }
`;

export const ErrorPageName = styled.span`
  width: 315px;
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  font-size: 100px;
  line-height: 100px;
  color: ${({ theme }) => theme.colors.midnight};
  margin-top: ${({ theme }) => theme.spacing[32]};
  text-align: center;
  
  ${({ theme }) => theme.mediaBreakpointUp.md} {
    font-size: 140px;
    line-height: 132px;
  }
`;

export const ErrorPageTitle = styled(Typography).attrs({
  variant: TextStyles['Heading 3'],
})`
  width: 313px;
  color: ${({ theme }) => theme.colors.highEmphasisBlack};
  white-space: pre-line;
  text-align: center;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    width: 366px;
    ${styledText['Heading 1']}
  }
`;

export const ErrorPageDescription = styled(Typography).attrs({
  variant: TextStyles['Body Text Large'],
})`
  width: 313px;
  color: ${({ theme }) => theme.colors.highEmphasisBlack};
  white-space: pre-line;
  margin-top: ${({ theme }) => theme.spacing[8]};
  text-align: center;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    width: 366px;
  }
`;

export const CenterGraphic = styled.div(({ theme }) => ({
  width: 240,
  height: 191,
  position: 'relative',

  [`${theme.mediaBreakpointUp.md}`]: {
    width: 344,
    height: 258,
  },
}));

export const ErrorPageButton = styled(Button).attrs({
  variant: ButtonVariant.Link,
  icon: ArrowForward,
})`
  margin-top: 34px;
`;

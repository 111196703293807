export interface IArticleStructuredData {
  '@context': string;
  '@type': string;
  datePublished?: string;
  dateModified?: string;
  headline?: string;
  image?: string | string[];
  author?: {
    '@type': string;
    name: string;
    url: string;
  }[];
}

export enum EventLocation {
  Place = 'Place',
  VirtualLocation = 'VirtualLocation',
}

export enum EventAttendanceMode {
  Online = 'OnlineEventAttendanceMode',
  Offline = 'OfflineEventAttendanceMode',
}

export interface IEventStructuredData {
  '@context': string;
  '@type': string;
  location: {
    '@type': EventLocation;
    url?: string;
    name?: string;
    address?: {
      '@type': string;
      name?: string;
    };
  };
  eventAttendanceMode: EventAttendanceMode;
  name?: string;
  startDate?: string;
  description?: string;
  endDate?: string;
  image?: string | string[];
  organizer?: {
    '@type'?: string;
    name?: string;
    url?: string;
  };
}

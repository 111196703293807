import styled from 'styled-components';
import { Button } from '@/components';
import { ButtonVariant } from '@/interfaces';
import { PageTitle, UnsubscribeSingleWrapper } from '../UnsubscribeSingle/UnsubscribeSingle.styles';

export const SubscribeNewsletterWrapper = styled(UnsubscribeSingleWrapper)({});

export const SubscribeNewsletterTitle = styled(PageTitle)({});

export const SubscribeNewsletterButton = styled(Button).attrs({
  variant: ButtonVariant.Link,
})({
  wordBreak: 'break-word',
  textAlign: 'center',
});

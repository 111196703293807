import type { IButton, ILeadGenBanner, ThumbnailProps, IMinifiedCarrier } from '@/interfaces';

export enum RightPanelType {
  Ad = 'Ad',
  TrackingWidget = 'Tracking Widget',
  FeaturedCarrierSection = 'Featured Carrier Section',
  ReportSection = 'Report Section',
  DataInsightsSection = 'Data Insights Section',
  LeadGenBanner = 'Lead Gen Banner',
  EventHighlights = 'Event Highlights',
}

export interface IRightPanel {
  sys?: { id: string };
  title?: string;
  type?: RightPanelType;
  button?: IButton;
  list?: Array<ThumbnailProps | ILeadGenBanner | IMinifiedCarrier>;
}

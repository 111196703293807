import { CookieKey, ErrorMessages, MessageField } from '@/constants';
import { type FirebaseApp, getApps, initializeApp } from 'firebase/app';
import {
  getFirestore,
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager,
} from 'firebase/firestore';
import { getMessaging, getToken } from 'firebase/messaging';
import Cookies from 'js-cookie';
/* eslint-disable no-console */
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

const firebaseConfig = {
  apiKey: publicRuntimeConfig.firebaseApiKey,
  authDomain: publicRuntimeConfig.firebaseAuthDomain,
  databaseURL: publicRuntimeConfig.firebaseDatabaseUrl,
  projectId: publicRuntimeConfig.firebaseProjectId,
  storageBucket: publicRuntimeConfig.firebaseStorageBucket,
  messagingSenderId: publicRuntimeConfig.firebaseMessagingSenderId,
  appId: publicRuntimeConfig.firebaseAppId,
};

const firebaseApp = !getApps().length ? initializeApp(firebaseConfig) : undefined;

const getFirebaseLocalCache = () => {
  return initializeFirestore(firebaseApp as FirebaseApp, {
    localCache: persistentLocalCache({
      tabManager: persistentMultipleTabManager(),
    }),
  });
};

export const db = firebaseApp ? getFirebaseLocalCache() : getFirestore(firebaseApp as any);

const handleReceiveMessage = (event: MessageEvent) => {
  const browserUuid = event.data?.data?.browser_uuid;
  const isUmsAccessToken = Cookies.get(CookieKey.UmsAccessToken);
  if (browserUuid && !isUmsAccessToken) {
    Cookies.set(CookieKey.UmsAccessToken, browserUuid, { expires: 365 });
    location.reload();
  }
};

const listenForegroundMessage = () => {
  navigator.serviceWorker.addEventListener('message', handleReceiveMessage);
};

const listenBackgroundMessage = () => {
  const channel = new BroadcastChannel('fcm-background');
  channel.addEventListener('message', handleReceiveMessage);
};

const firebaseMessageInit = () => {
  const isSupported = 'Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window;

  if (!isSupported) {
    console.error(ErrorMessages[MessageField.WEB_PUSH_NOTIFICATION_IS_NOT_SUPPORTED].error);
    return;
  }

  Notification.requestPermission()
    .then((permission) => {
      if (permission === 'granted') {
        const messaging = getMessaging(firebaseApp);
        getToken(messaging, { vapidKey: publicRuntimeConfig.firebaseVapidKey })
          .then((currentToken) => {
            if (currentToken) {
              Cookies.set(CookieKey.FirebaseMessageToken, currentToken, {
                expires: 365,
              });
            } else {
              throw new Error(ErrorMessages[MessageField.FIREBASE_FCM_TOKEN_ERROR].error);
            }
          })
          .catch((err) => {
            console.error(ErrorMessages[MessageField.FIREBASE_FCM_TOKEN_ERROR].error, err);
          });
      }
    })
    .catch((err) => {
      console.error(ErrorMessages[MessageField.NOTIFICATION_PERMISSION_NOT_GRANTED].error, err);
    });

  listenForegroundMessage();
  listenBackgroundMessage();
};

export const firebaseInit = () => {
  if (typeof window === 'undefined') {
    return;
  }

  firebaseMessageInit();
};

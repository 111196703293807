import styled from 'styled-components';
import { Typography, Button } from '@/components';
import { TextStyles } from '@/constants';
import Image from '../../../../../public/images/pmc-section.svg';

export const SectionWrapper = styled.div`
  padding: ${({ theme }) => `${theme.spacing[12]} 0 ${theme.spacing[24]}`};
`;

export const SectionContainer = styled.div`
  display: flex;
`;

export const SectionInnerContainer = styled.div`
  width: 100%;
`;

export const MainText = styled(Typography).attrs({
  variant: TextStyles['Heading 4'],
})`
  margin-top: ${({ theme }) => theme.spacing[24]};
  color: ${({ theme }) => theme.colors.highEmphasisBlack};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const SubText = styled(Typography).attrs({
  variant: TextStyles['Body Text Small'],
})`
  margin-top: ${({ theme }) => theme.spacing[8]};
  color: ${({ theme }) => theme.colors.highEmphasisBlack};
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const PMCButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing[16]};
  padding: ${({ theme }) => theme.spacing[16]};
  max-width: 100%;
`;

export const PMCImage = styled(Image)`
  width: 133.5px;
  height: 80px;
`;

import breakpoint from './breakpoint';
import colors from './colors';
import elevation from './elevation';
import { letterSpacing, spacing } from './spacing';
import typography from './typography';

const theme = {
  colors,
  spacing,
  letterSpacing,
  elevation,
  ...typography,
  ...breakpoint,
};

export default theme;

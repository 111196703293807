import type { IButton } from '@/interfaces';
import type { DefaultTheme, StyledComponent } from 'styled-components';

export enum RegistrationWallTypes {
  RegistrationWall = 'Registration wall',
  ButtonPaywall = 'Button-paywall',
  FullPaywall = 'Full-paywall',
  MiniPaywall = 'Mini-paywall',
}

export interface IRegistrationWall {
  sys?: { id: string };
  type: RegistrationWallTypes;
  sectionHeader: string;
  image?: {
    contentType?: string;
    url: string;
    description?: string;
  };
  mobileImage?: {
    contentType?: string;
    url: string;
    description?: string;
  };
  title: string;
  subTitle: string;
  button: IButton;
  subText?: string[];
}

export interface RegistrationWallProps extends IRegistrationWall {
  className?: string;
  isInsideRT?: boolean;
  hasParentHeight?: boolean;
}

type StyledComponentType = StyledComponent<'div', DefaultTheme, Record<string, unknown>, never>;

// for FullPaywall and MiniPaywall template
export interface MainPaywallLayoutProps extends RegistrationWallProps {
  hasNoOuterContainer?: boolean;
  sectionHeaderComponent?: JSX.Element;
  subTitleComponent?: JSX.Element;
  subTextComponent?: JSX.Element;
  buttonComponent?: JSX.Element;
  wallContainerComponent?: StyledComponentType;
}

import { ContentTypeName, DataPickerContentType, PageType } from '@/constants';
import {
  dataSectionQuery,
  marketMinifiedQuery,
  pageOverviewBannerQuery,
  seoSectionQuery,
  tagsAndMarketsQuery,
  thumbnailSectionQuery,
  wordCloudQuery,
} from '@/fetchers';
import {
  DataPickerFieldType,
  DataPickerUiTemplate,
  HighlightDisplayLogic,
  type IHighlightSection,
  type ILandingPage,
  type IThumbnailCarousel,
  type IThumbnailSection,
  type IWrapper,
  SectionItemType,
  type ThumbnailProps,
} from '@/interfaces';
import { landingPageDataModify, minifyQuery, thumbnailSectionDataModify } from '@/utils';
import {
  dataPickerEntryHandler,
  dataPickerRetailerHandler,
  needEventOrArticleData,
  utils_getEventThumbnailsByDate,
  utils_getThumbnailsByTag,
  utils_getThumbnailsByTagList,
} from '../utils';
import callContentful from './base.fetchers';

const THUMBNAIL_CAROUSEL_MAX_ITEMS = 30;

type GetLandingPage = (args: {
  isPreviewMode: boolean;
  slug?: string;
  allEventsList: ThumbnailProps[];
  allArticlesList: ThumbnailProps[];
  accessToken?: string;
}) => Promise<ILandingPage | null>;

export const getLandingPage: GetLandingPage = async ({
  isPreviewMode,
  slug,
  allEventsList,
  allArticlesList,
  accessToken,
}) => {
  const { getThumbnailSectionData } = thumbnailSectionDataModify;

  const query = /* GraphQL */ `
    {
      landingPageCollection(
        preview: ${isPreviewMode}
        where: {
          ${slug ? `slug: "${slug}"` : 'slug_exists: false'}
        }
        limit: 1
      ) {
        items {
          sys { id }
          title
          breadcrumbsTheme
          onlyLogo
          content {
            json
            links {
              assets {
                block {
                  sys {
                    id
                  }
                  __typename
                  contentType
                  url
                  title
                  description
                  height
                  width
                }
              }
            }
          }
          sectionList: sectionListCollection(
            preview: ${isPreviewMode}
            limit: 15
          ) {
            items {
              __typename
              ${dataSectionQuery(isPreviewMode)}
              ... on LeadsWrapper {
                title
                theme
                backgroundImage {
                  contentType
                  url
                  description
                }
                form {
                  sys {
                    id
                  }
                  title
                  subText
                  button {
                    primaryButtonText
                    secondaryButtonText
                    function
                    color
                    buttonLink
                    openInNewTab
                  }
                  formFieldList: formFieldListCollection(
                    preview: ${isPreviewMode}
                    limit: 10
                  ) {
                    items {
                      ... on FormField {
                        name
                        label
                        placeholder
                        isMandatory
                        type
                        width
                        mandatoryCheckErrorMessage
                        customErrorMessage
                        dropdownOptions
                      }
                    }
                  }
                }
                valuePropositionList: valuePropositionListCollection(
                  preview: ${isPreviewMode}
                  limit: 3
                ) {
                  items {
                    sys {
                      id
                    }
                    title
                    contentType
                    url
                    description
                  }
                }
                customerLogoTitle
                customerLogoList: customerLogoListCollection(
                  preview: ${isPreviewMode}
                  limit: 8
                ) {
                  items {
                    sys {
                      id
                    }
                    contentType
                    url
                    description
                  }
                }
                zapierHookId
              }
              ... on Wrapper {
                title
                subText
                subTextMobile
                backgroundColor
                image {
                  contentType
                  url
                  description
                }
                content {
                  json
                  links {
                    assets {
                      block {
                        sys {
                          id
                        }
                        __typename
                        contentType
                        url
                        title
                        description
                        height
                        width
                      }
                    }
                  }
                }
                entryList: entryListCollection(limit: 20) {
                  items {
                    __typename
                    ... on Company {
                      logo {
                        contentType
                        url(transform: {
                          height: 100,
                          resizeStrategy: FIT,
                        })
                        description
                      }
                      logoLink
                    }
                    ... on People {
                      image {
                        contentType
                        url(transform: {
                          width: 130,
                          resizeStrategy: FIT,
                        })
                        description
                      }
                      fullName
                      subHeader
                      profileLink
                      quotes
                      ctaText
                      article {
                        articleType
                        slug
                      }
                    }
                    ... on ContentItem {
                      uiTemplate
                      title
                      subTitle
                      longSubTitle
                      image {
                        contentType
                        url(transform: {
                          width: 560,
                          resizeStrategy: FIT,
                        })
                        description
                      }
                      buttonText
                      buttonLink
                      openInNewTab
                    }
                    ... on ContentCard {
                      sys { 
                        id 
                      }
                      header
                      title
                      subTitle
                      contentList
                      button {
                        primaryButtonText
                        function
                        color
                        email
                        buttonLink
                        openInNewTab
                      }
                    }
                    ... on Market {
                      __typename
                      ${marketMinifiedQuery()}
                    }
                    ... on ThumbnailSection {
                      ${thumbnailSectionQuery(6, true)}
                    }
                  }
                }
                dataPicker
              }
              ... on HighlightSection {
                title
                displayingLogic
                tags: tagsCollection(limit: 5) {
                  items {
                    sys {
                      id
                    }
                    title
                    type
                    slug
                  }
                }
              }
              ${wordCloudQuery()}
              ${seoSectionQuery()}
              ... on ThumbnailCarousel {
                tag {
                  title
                  type
                  slug
                  hide
                }
                itemType
              }
              ... on ThumbnailSection {
                ${thumbnailSectionQuery()}
              }
              ... on LeadGenBanner {
                title
                titleMobile
                image {
                  contentType
                  url(transform: {
                    width: 1261,
                    resizeStrategy: FIT,
                  })
                  description
                }
                imageMobile {
                  contentType
                  url(transform: {
                    width: 696,
                    resizeStrategy: FIT,
                  })
                  description
                }
                subCopy
                subCopyMobile
                buttons: buttonsCollection(limit: 3) {
                  items {
                    sys {
                      id
                    }
                    primaryButtonText
                    secondaryButtonText
                    function
                    color
                    email
                    buttonLink
                    buttonEventLabel
                    openInNewTab
                  }
                }
                sectionTheme: theme
                isLinkedInBanner
              }
              ${pageOverviewBannerQuery()}
              ... on EventList {
                title
                featured
                eventList: eventListCollection(limit: 4) {
                  items {
                    sys { 
                      id 
                    }
                    title
                    slug
                    image {
                      contentType
                      url(transform: {
                        height: 300,
                        resizeStrategy: FIT,
                      })
                    }
                    startDate
                    endDate
                    address
                    seo {
                      description
                    }
                    tags: tagsCollection(limit: 2) {
                      items {
                        ${tagsAndMarketsQuery()}
                      }
                    }
                  }
                }
              }
            }
          }
          seo {
            title
            description
            ogType
            ogUrl
            ogImage {
              contentType
              url
            }
            ogTitle
            ogDescription
            robots
          }
        }
      }
    }
  `;

  const _slug = `/${slug}` || 'homepage';
  const response = await callContentful(
    minifyQuery(query),
    isPreviewMode,
    _slug,
    'landingPage.fetchers.getLandingPage',
  );
  const landingPageData = response.data?.landingPageCollection.items?.filter(Boolean)?.[0];
  const sectionItems = landingPageData?.sectionList?.items?.filter(Boolean);

  const foundHighlightSection: IHighlightSection = sectionItems?.find(
    (section: any) => section.__typename === ContentTypeName.HighlightSection,
  );

  const foundThumbnailSection: IThumbnailSection = sectionItems?.find(
    (section: any) => section.__typename === ContentTypeName.ThumbnailSection,
  );

  const foundThumbnailCarousel: IThumbnailCarousel[] = sectionItems?.filter(
    (section: any) => section.__typename === ContentTypeName.ThumbnailCarousel,
  );

  const foundWrapperUsingDataPicker: IWrapper[] = sectionItems?.filter(
    (section: any) =>
      section.__typename === ContentTypeName.Wrapper && !section?.entryList?.items?.length && !section?.content,
  );

  const foundWrapperUsingThumbnailSection: IWrapper[] = sectionItems?.filter(
    (section: any) =>
      section.__typename === ContentTypeName.Wrapper &&
      section?.entryList?.items?.length &&
      section?.entryList?.items?.[0]?.__typename === ContentTypeName.ThumbnailSection,
  );

  if (foundWrapperUsingDataPicker.length > 0) {
    await Promise.all(
      foundWrapperUsingDataPicker.map(async (section) => {
        const { dataPicker } = section;

        const contentType = dataPicker.contentType || DataPickerContentType.carrier;
        const uiTemplate = dataPicker.uiTemplate || DataPickerUiTemplate.Table;
        const fields = dataPicker.fields?.filter(({ show }) => show);
        const foundGroupByField = dataPicker.fields?.find(({ type }) => type === DataPickerFieldType.GroupBy);

        section.dataPicker.contentType = contentType;
        section.dataPicker.uiTemplate = uiTemplate;
        section.dataPicker.fields = fields;

        if (foundGroupByField) {
          section.dataPicker.groupBy = foundGroupByField.id;
          section.dataPicker.sortDirection = foundGroupByField.sortDirection; // only support sort for GroupBy field
        }

        const isRetailer = contentType === DataPickerContentType.retailer;

        // NOTE: No case of retailer showing `default` fields yet => Not call API for now
        const mappedData = isRetailer
          ? await dataPickerRetailerHandler({
              fields,
              accessToken,
            })
          : await dataPickerEntryHandler({
              slug: slug || '',
              dataPicker: section.dataPicker,
            });

        section.data = mappedData.data;
        section.dataPicker.pagination = isRetailer ? mappedData.pagination : null;
      }),
    );
  }

  const { hasEvents, hasArticles } = needEventOrArticleData({
    foundHighlightSection,
    foundThumbnailSection,
    foundThumbnailCarousel,
    foundWrapperUsingThumbnailSection,
  });

  const allEvents = hasEvents ? allEventsList || [] : [];
  const allArticles = hasArticles ? allArticlesList || [] : [];

  if (foundHighlightSection) {
    const filteredTags = foundHighlightSection.tags?.items?.filter(Boolean);
    let highlightSectionThumbnails: ThumbnailProps[] = [];

    if (filteredTags?.length > 0) {
      highlightSectionThumbnails = utils_getThumbnailsByTagList({
        isFeatured: foundHighlightSection.displayingLogic === HighlightDisplayLogic.Featured,
        articles: allArticles,
        tags: filteredTags,
      });
    }

    foundHighlightSection.thumbnails = highlightSectionThumbnails.slice(0, 4);
  }

  if (foundThumbnailCarousel.length > 0) {
    foundThumbnailCarousel.map((item) => {
      const el =
        item.itemType === SectionItemType.Event
          ? utils_getEventThumbnailsByDate({
              events: allEvents,
              slug: item.tag.slug,
              type: item.tag.type,
            })
          : utils_getThumbnailsByTag({
              articles: allArticles,
              type: item.tag.type,
              slug: item.tag.slug,
            });
      item.thumbnails = el.slice(0, THUMBNAIL_CAROUSEL_MAX_ITEMS);
    });
  }

  if (foundWrapperUsingThumbnailSection.length) {
    foundWrapperUsingThumbnailSection.forEach(({ entryList }: any) => {
      if (!entryList?.items?.length) return;

      entryList.items = entryList.items.map((data: any, index: number) => {
        if (index !== 0 || !data) return data;

        if (data.featuredArticles?.items?.length) {
          data.featuredArticles = data.featuredArticles?.items.filter(Boolean) || [];
          return data;
        }

        return getThumbnailSectionData({
          thumbnailSection: data,
          highlightSection: foundHighlightSection,
          allArticles,
          allEvents,
          pageType: PageType.LandingPage,
        });
      });
    });
  }

  if (foundThumbnailSection) {
    const newThumbnailSection = getThumbnailSectionData({
      thumbnailSection: foundThumbnailSection,
      highlightSection: foundHighlightSection,
      allArticles,
      allEvents,
      pageType: PageType.LandingPage,
    });

    const foundIndex = sectionItems.findIndex(
      (section: any) => section.__typename === ContentTypeName.ThumbnailSection,
    );

    if (foundIndex > -1) {
      landingPageData.sectionList.items[foundIndex] = newThumbnailSection;
    }
  }

  return landingPageDataModify(landingPageData);
};

// Ref: https://developers.google.com/identity/protocols/oauth2/scopes
export enum GoogleApiScopes {
  ViewEmailMessagesAndSettings = 'https://www.googleapis.com/auth/gmail.readonly',
  SeePersonalInfo = 'https://www.googleapis.com/auth/userinfo.profile',
  SeePrimaryEmailAddress = 'https://www.googleapis.com/auth/userinfo.email',
  AssociateYouWithPersonalInfo = 'openid',
}

export const EmailSyncGoogleApiScopes = [
  GoogleApiScopes.ViewEmailMessagesAndSettings,
  GoogleApiScopes.AssociateYouWithPersonalInfo,
  GoogleApiScopes.SeePersonalInfo,
  GoogleApiScopes.SeePrimaryEmailAddress,
].join(' ');

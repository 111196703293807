import type { ITag, ThumbnailProps } from '@/interfaces';
import { relatedThumbnailDataModify, allThumbnailDataModify, sortByDate, removeDuplicateCTFObjects } from '@/utils';
import callContentful from './base.fetchers';
import { tagsAndMarketsQuery } from '../constant';

type BaseThumbnailQuery = (args: {
  isPreviewMode?: boolean;
  limit?: number;
  where?: string;
  order?: string;
  tagLimit?: number;
  skip?: number;
}) => string;

// max is: 846, use 840
const baseThumbnailQuery: BaseThumbnailQuery = ({
  isPreviewMode,
  limit = 1,
  skip = 0,
  where,
  order,
  tagLimit = 10,
}) => {
  return `
    articles: articleCollection(
      preview: ${isPreviewMode}
      limit: ${limit}
      skip: ${skip}
      ${where ? `where: ${where}` : ''}
      ${order ? `order: ${order}` : ''}
    ) {
      total
      items {
        sys {
          id
        }
        title
        articleType
        slug
        featured
        date
        image {
          contentType
          url(transform: {
            height: 300,
            resizeStrategy: FIT,
          })
          title
          description
        }
        tags: tagsCollection(
          preview: ${isPreviewMode},
          limit: ${tagLimit}
        ) {
          items {
            ${tagsAndMarketsQuery()}
          }
        }
        seo {
          description
        }
      }
    }
  `;
};

// max is: 3666, use 3600
const noTagThumbnailQuery: BaseThumbnailQuery = ({ isPreviewMode, limit = 1, where, order }) => {
  return `
    articles: articleCollection(
      preview: ${isPreviewMode}
      limit: ${limit}
      ${where ? `where: ${where}` : ''}
      ${order ? `order: ${order}` : ''}
    ) {
      items {
        sys {
          id
        }
        title
        articleType
        slug
        featured
        date
        image {
          contentType
          url
          title
          description
        }
        seo {
          description
        }
      }
    }
  `;
};

// Get all thumbnails
const getAllThumbnails = async (args: {
  isPreviewMode: boolean;
  fetcher?: (query: string, isPreviewMode: boolean) => Promise<any>;
  slug: string;
}): Promise<ThumbnailProps[]> => {
  const { isPreviewMode, fetcher = callContentful, slug } = args;
  const limitThumbnail = 390;
  const query = (skip?: number) =>
    `{ ${baseThumbnailQuery({
      isPreviewMode,
      limit: limitThumbnail,
      tagLimit: 25,
      skip: skip,
    })} }`;

  const queryTotal = `
  {
    articles: articleCollection(
      preview: ${isPreviewMode}
    ) { total }
  }
  `;

  // Get total thumbnails
  const responseTotal = await fetcher(queryTotal, isPreviewMode, slug, 'thumbnail.fetchers.getAllThumbnails.1');
  const totalThumbnail = responseTotal?.data?.articles?.total;

  const thumbnails = [];
  const timesToFetch = Math.ceil(totalThumbnail / limitThumbnail);

  for (let i = 0; i < timesToFetch; i++) {
    thumbnails.push(fetcher(query(limitThumbnail * i), isPreviewMode, slug, 'thumbnail.fetchers.getAllThumbnails.2'));
  }

  const response = await Promise.all(thumbnails);
  // Flatten array to get all thumbnails
  const data = [].concat(...response.map((element) => element.data?.articles?.items));

  return allThumbnailDataModify(data) || [];
};

type GetThumbnailsByTag = (args: {
  isPreviewMode: boolean;
  slug?: string;
  type?: string;
}) => Promise<ThumbnailProps[]>;

// Get all thumbnails belong to a tag
const getThumbnailsByTag: GetThumbnailsByTag = async ({ isPreviewMode, type, slug }) => {
  const typeQuery = type ? `type: "${type}"` : '';
  const slugQuery = slug ? `slug: "${slug}"` : 'slug_exists: false';
  const query = `
  {
    tagCollection(
      preview: ${isPreviewMode},
      limit: 1,
      where: {
        AND: [
          { ${slugQuery} }
          { ${typeQuery} }
        ]
      }
    ) {
      items {
        title
        linkedFrom {
          ${baseThumbnailQuery({ isPreviewMode, limit: 50 })}
        }
      }
    }
  }
  `;

  const response = await callContentful(query, isPreviewMode); // PARENT FUNC IS DEPRECATED
  const data = response?.data?.tagCollection?.items?.[0];
  return relatedThumbnailDataModify(data);
};

type GetThumbnailsByTagList = (args: {
  isPreviewMode: boolean;
  tags: ITag[]; // TODO: func deprecated => no need update with IMarket, but delete func
}) => Promise<ThumbnailProps[]>;

const getThumbnailsByTagList: GetThumbnailsByTagList = async ({ isPreviewMode, tags }) => {
  const allThumbnailsByTag = await Promise.all(
    tags?.map(
      async (tag) =>
        await getThumbnailsByTag({
          isPreviewMode,
          slug: tag.slug,
          type: tag.type,
        }),
    ),
  );

  const data = allThumbnailsByTag.flat(1).filter(Boolean);
  const sortedData = sortByDate(data);
  return removeDuplicateCTFObjects(sortedData);
};

type GetLatestThumbnails = (args: {
  isPreviewMode: boolean;
  tagId: string;
  limit?: number;
  needTag?: boolean;
}) => Promise<ThumbnailProps[]>;

const getLatestThumbnails: GetLatestThumbnails = async ({ isPreviewMode, tagId, limit = 4, needTag = true }) => {
  const thumbnailQuery = needTag ? baseThumbnailQuery : noTagThumbnailQuery;

  const query = `{
    tag(preview: ${isPreviewMode}, id: "${tagId}") {
      linkedFrom {
        ${thumbnailQuery({ isPreviewMode, limit })}
      }
    }
  }`;

  const response = await callContentful(query, isPreviewMode); // PARENT FUNC IS DEPRECATED
  const data = response?.data?.tag;
  const modifiedData: ThumbnailProps[] = relatedThumbnailDataModify(data);

  return modifiedData;
};

type GetFeaturedThumbnails = (args: {
  isPreviewMode: boolean;
  tagId: string;
  limit?: number;
}) => Promise<ThumbnailProps[]>;

const getFeaturedThumbnails: GetFeaturedThumbnails = async ({ isPreviewMode, tagId, limit = 4 }) => {
  const articleCollectionQuery = baseThumbnailQuery({
    isPreviewMode,
    limit,
  });
  const query = `{
    tag(preview: ${isPreviewMode}, id: "${tagId}") {
      linkedFrom { ${articleCollectionQuery} }
    }
  }`;

  const response = await callContentful(query, isPreviewMode); // PARENT FUNC IS DEPRECATED
  const data = response?.data?.tag;
  const modifiedData: ThumbnailProps[] = relatedThumbnailDataModify(data);

  return modifiedData?.filter((item) => item?.featured);
};

const getFeaturedThumbnailsByTagList: GetThumbnailsByTagList = async ({ isPreviewMode, tags }) => {
  const allFeaturedThumbnailsByTag = await Promise.all(
    tags?.map(
      async (tag) =>
        await getFeaturedThumbnails({
          isPreviewMode,
          tagId: tag.sys.id,
        }),
    ),
  );

  const data = allFeaturedThumbnailsByTag.flat(1).filter(Boolean);
  const sortedData = sortByDate(data);
  return removeDuplicateCTFObjects(sortedData);
};

export {
  getThumbnailsByTag,
  getThumbnailsByTagList,
  getAllThumbnails,
  getLatestThumbnails,
  getFeaturedThumbnails,
  getFeaturedThumbnailsByTagList,
};

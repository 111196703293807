import { type ChangeEventHandler, type ReactElement, useEffect, useRef, useState } from 'react';
import { ImageWithFallback, Typography } from '@/components';
import { useClickOutsideMultiElement, useGlobalState } from '@/hooks';
import { MasterTranslation, TextStyles } from '@/constants';
import {
  Dropdown,
  DropdownList,
  DropdownInput,
  DropdownItem,
  NotFound,
  SearchIcon,
  ListWrapper,
  SelectorContainer,
  DropdownInputWrapper,
  SearchIconContainer,
} from './Selector.styles';

export interface IItem {
  name: string;
  id: string;
  logo?: string;
}

interface SelectorProps<T> {
  placeholder?: string;
  dropdownList: T[];
  defaultLogo: string;
  disabled?: boolean;
  disabledCustomSearch?: boolean;
  defaultSelectedItem?: T | null;
  onSelect?: (selectedItem: T | null) => void;
  onCustomSearchClicked?: () => void;
}

const Selector = <T extends IItem>({
  placeholder,
  defaultLogo,
  dropdownList,
  disabled,
  onSelect,
  defaultSelectedItem,
  onCustomSearchClicked,
  disabledCustomSearch,
}: SelectorProps<T>): ReactElement => {
  const { t } = useGlobalState();

  const [list, setList] = useState<T[]>(dropdownList || []);
  const [isOpen, setIsOpen] = useState<boolean | null>(null);
  const [currentItem, setCurrentItem] = useState<T | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const inputWrapperRef = useRef<HTMLDivElement>(null);
  const dropdownListRef = useRef<HTMLDivElement>(null);

  const handleCustomSearchFunction = () => {
    !disabledCustomSearch && onCustomSearchClicked && onCustomSearchClicked();
  };

  const setInputValue = (value: string) => {
    if (inputRef?.current) {
      inputRef.current.value = value;
    }
  };

  const handleSearch: ChangeEventHandler<HTMLInputElement> = (e) => {
    const searchValue = e.target.value;
    const searchList = !searchValue
      ? dropdownList
      : dropdownList?.filter((item) => item.name?.toLowerCase()?.includes(searchValue.toLowerCase()));
    setList(searchList);
    setCurrentItem(null);
    onSelect && onSelect(null);
  };

  const handleSelect = (selectedItem: T) => {
    setIsOpen(false);
    dropdownListRef.current?.scroll({ top: 0 });

    if (selectedItem.id === currentItem?.id) return;

    setList(dropdownList);
    setCurrentItem(selectedItem);
    setInputValue(selectedItem.name);
    onSelect && onSelect(selectedItem);
  };

  useEffect(() => {
    if (defaultSelectedItem?.id !== currentItem?.id) {
      setCurrentItem(defaultSelectedItem ?? null);
      setInputValue(defaultSelectedItem?.name ?? '');
    }
  }, [defaultSelectedItem?.id]);

  const notFound = (
    <NotFound>
      <Typography variant={TextStyles['Body Text Large']}>{t(MasterTranslation.NoResultsFound)}</Typography>
    </NotFound>
  );

  const renderDropdownItemChildren = (item: T) => (
    <>
      <ImageWithFallback src={item?.logo || defaultLogo} alt={item?.name || 'Carrier Logo'} fallbackSrc={defaultLogo} />
      {item?.name}
    </>
  );

  const renderedList = (
    <ListWrapper>
      {currentItem && (
        <DropdownItem isActive onClick={() => setIsOpen(false)}>
          {renderDropdownItemChildren(currentItem)}
        </DropdownItem>
      )}
      {list
        ?.filter((item) => item?.id !== currentItem?.id)
        ?.slice(0, currentItem ? 49 : 50)
        ?.map((item: T) => (
          <DropdownItem key={item.id} onClick={() => handleSelect(item)}>
            {renderDropdownItemChildren(item)}
          </DropdownItem>
        ))}
    </ListWrapper>
  );

  // Close dropdown when clicking outside input and list
  useClickOutsideMultiElement([inputWrapperRef, dropdownListRef], () => setIsOpen(false), []);

  return (
    <SelectorContainer>
      <Dropdown>
        <DropdownInputWrapper
          ref={inputWrapperRef}
          onClick={() => (!disabled ? setIsOpen(true) : null)}
          disabled={disabled}
        >
          <ImageWithFallback
            src={currentItem?.logo || defaultLogo}
            alt={currentItem?.name || 'Carrier Logo'}
            fallbackSrc={defaultLogo}
          />
          <DropdownInput ref={inputRef} placeholder={placeholder} onChange={handleSearch} disabled={disabled} />
        </DropdownInputWrapper>
        {onCustomSearchClicked && (
          <SearchIconContainer onClick={handleCustomSearchFunction} disabled={disabledCustomSearch}>
            <SearchIcon />
          </SearchIconContainer>
        )}
        {isOpen !== null && (
          <DropdownList isOpen={isOpen} ref={dropdownListRef}>
            {list?.length ? renderedList : notFound}
          </DropdownList>
        )}
      </Dropdown>
    </SelectorContainer>
  );
};

export default Selector;

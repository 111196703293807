import Image from 'next/image';
import type { ComponentPropsWithoutRef } from 'react';
import { GoogleOAuthFlow, type GoogleOAuthParams, useGoogleOAuth } from './hooks';
import { LoginButton, Logo, Text } from './GoogleLogin.styles';

type GoogleOAuthResponse = Pick<GoogleOAuthParams, 'onSuccess' | 'onError'>;
export interface GoogleLoginButtonProps extends ComponentPropsWithoutRef<'button'> {
  logoSrc?: string;
  backgroundColor?: string;
  onOAuthSuccess: GoogleOAuthResponse['onSuccess'];
  onOAuthError: GoogleOAuthResponse['onError'];
  flow?: GoogleOAuthFlow;
  redirect_uri?: string;
  scope?: string;
  customHandleClick?: () => void;
}

/**
 * `onSuccess` => after login with google successfully
 * `onError` => after fail login with google
 */
export const GoogleLoginButton: React.FC<GoogleLoginButtonProps> = ({
  disabled = false,
  logoSrc = '/images/google-logo.svg',
  backgroundColor = '#5383EC',
  onOAuthSuccess,
  onOAuthError,
  children,
  flow,
  redirect_uri,
  scope,
  customHandleClick,
  ...props
}) => {
  const { login: defaultGoogleOAuth } = useGoogleOAuth({
    onSuccess: onOAuthSuccess,
    onError: onOAuthError,
    flow: flow ?? GoogleOAuthFlow.Implicit,
    redirect_uri,
    scope,
  });

  return (
    <LoginButton
      disabled={disabled}
      backgroundColor={backgroundColor}
      onClick={() => {
        defaultGoogleOAuth();

        if (customHandleClick) {
          customHandleClick();
        }
      }}
      {...props}
    >
      <Logo>
        <Image src={logoSrc} alt='google-logo' width={24} height={24} />
      </Logo>

      <Text>{children}</Text>
    </LoginButton>
  );
};

export default GoogleLoginButton;

import { useEffect } from 'react';

const usePreventBodyScroll = <T>(dependencies: T) => {
  useEffect(() => {
    document.body.style.touchAction = dependencies ? 'none' : 'auto';
    document.documentElement.style.overflow = dependencies ? 'hidden' : 'auto';

    return () => {
      document.documentElement.style.overflow = 'auto';
      document.body.style.touchAction = 'auto';
    };
  }, [dependencies]);
};

export default usePreventBodyScroll;

import Image from 'next/image';
import { useEffect, useState } from 'react';
import Router from 'next/router';
import Cookies from 'js-cookie';
import { gtmBrowserVerificationClicks, gtmTrackingClickBrowserVerification } from '@/utils';
import { removeCookies } from '@/utils/cookies.utils';
import {
  PopupContainer,
  PopupContentWrapper,
  PopupHeader,
  PopupOverlay,
  PopupWrapper,
  Subcopy,
  Title,
  SendLinkButton,
  EmailSent,
} from './VerificationPopup.styles';
import { sendVerificationEmail } from '@/fetchers';
import { CookieKey, SendMailStatusCode, SignUpSource, MasterTranslation } from '@/constants';
import { useGlobalState } from '@/hooks';
import { Spinner } from '@/components';

const VerificationPopup: React.FC = () => {
  const { t, isMyParcelsPage, pmtCurrentLanguageCode } = useGlobalState();

  const [emailSent, setEmailSent] = useState<string>('');
  const [popupOpened, setPopupOpened] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    document.body.setAttribute('style', `overflow: ${popupOpened ? 'hidden' : 'unset'};`);
  }, [popupOpened]);

  if (!popupOpened) return null;

  const sendVerificationLink = async () => {
    isMyParcelsPage ? gtmTrackingClickBrowserVerification() : gtmBrowserVerificationClicks();
    if (isSubmitted || isSubmitting) return;
    setIsSubmitting(true);
    const email = Cookies.get(CookieKey.UserEmail);
    const browserUuid = Cookies.get(CookieKey.NewBrowserUuid);
    Cookies.set(CookieKey.BrowserUuid, browserUuid);
    removeCookies();
    if (email && browserUuid) {
      try {
        const response = await sendVerificationEmail({
          email,
          browserUuid,
          language: pmtCurrentLanguageCode,
          source: isMyParcelsPage ? SignUpSource.PMT : SignUpSource.PMC,
        });

        const hasError = 'error' in response;
        if (hasError) {
          throw response;
        }

        if (response.status === SendMailStatusCode.Valid) {
          setIsSubmitted(true);
          setEmailSent(email);
        }
      } catch (error: any) {
        console.error(error);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const closePopup = () => {
    removeCookies();
    setPopupOpened(false);
    if (isSubmitted) {
      Router.reload();
    }
  };

  return (
    <PopupContainer>
      <PopupOverlay onClick={closePopup} />
      <PopupWrapper>
        <PopupHeader>
          <Image src='/images/verify-graphic.svg' alt='Verify Graphic' fill sizes='100vw' />
        </PopupHeader>
        <PopupContentWrapper>
          {isSubmitted ? (
            <>
              <Title>{t(MasterTranslation.BrowserVerificationPopupSuccessMainText)}</Title>
              <Subcopy>{t(MasterTranslation.BrowserVerificationPopupSuccessSubText)}</Subcopy>
              <EmailSent>{emailSent}</EmailSent>
            </>
          ) : (
            <>
              <Title>{t(MasterTranslation.BrowserVerificationPopupQuestionMainText)}</Title>
              <Subcopy>{t(MasterTranslation.BrowserVerificationPopupQuestionSubText)}</Subcopy>
              <SendLinkButton
                truncate={isMyParcelsPage}
                isPMTButton={isMyParcelsPage}
                onClick={sendVerificationLink}
                isSubmitting={isSubmitting}
                disabled={isSubmitting || isSubmitted}
              >
                {isSubmitting ? (
                  <Spinner text={t(MasterTranslation.SendingLink)} />
                ) : (
                  t(MasterTranslation.SendLinkToEmail)
                )}
              </SendLinkButton>
            </>
          )}
        </PopupContentWrapper>
      </PopupWrapper>
    </PopupContainer>
  );
};

export default VerificationPopup;

import { triggerHook } from '@/fetchers';
import { NewsletterOptInSource } from '@/constants';
import { useGeo, usePrevious, useSecondEffect } from '@/hooks';
import { type IUserInfo, LoginMethod } from '@/interfaces';

type UseZapierTrigger = (args: {
  hookId?: string | null;
  userInfo: IUserInfo | null;
  isFollowingLinkedIn: boolean;
  showPopUp: boolean;
  isNextButtonClicked: boolean;
}) => void;

const useZapierTrigger: UseZapierTrigger = ({
  hookId,
  userInfo,
  isFollowingLinkedIn,
  showPopUp,
  isNextButtonClicked,
}) => {
  const previousIsFollowingLinkedIn = usePrevious(isFollowingLinkedIn);
  const previousUserInfo = usePrevious(userInfo);
  const previousIsNextButtonClicked = usePrevious(isNextButtonClicked);
  const { geoInfo } = useGeo();

  const TRIGGER_CONDITIONS: Record<string, boolean> = {
    AUTHENTICATION_COMPLETE:
      previousUserInfo === null && !!userInfo && userInfo?.authenticationMethod !== LoginMethod.Email,
    LINKEDIN_FOLLOW: !previousIsFollowingLinkedIn && isFollowingLinkedIn,
    ABOUT_YOU_STEP_COMPLETE: !previousIsNextButtonClicked && isNextButtonClicked,
  };

  useSecondEffect(() => {
    // As long as the popup is open, the effect will run
    if (!showPopUp || !hookId) {
      return;
    }

    const isAuthMethodEmail = userInfo?.authenticationMethod === LoginMethod.Email;

    switch (true) {
      case TRIGGER_CONDITIONS.AUTHENTICATION_COMPLETE: {
        triggerHook({
          hookId,
          data: {
            firstName: userInfo?.firstName,
            lastName: userInfo?.lastName,
            email: userInfo?.email,
            authenticationMethod: userInfo?.authenticationMethod,
            profilePictureUrl: userInfo?.profilePictureUrl,
            linkedInFollowStatus: isFollowingLinkedIn,
            verificationStatus: !isAuthMethodEmail,
            newsletterOptIn: 'Yes',
            newsletterOptInSource: NewsletterOptInSource.PMC_WEBSITE,
            userType: userInfo?.userType,
            country: userInfo?.country ?? geoInfo?.country,
          },
        });
        break;
      }

      case TRIGGER_CONDITIONS.LINKEDIN_FOLLOW: {
        triggerHook({
          hookId,
          data: {
            linkedInFollowStatus: isFollowingLinkedIn,
          },
        });
        break;
      }

      case TRIGGER_CONDITIONS.ABOUT_YOU_STEP_COMPLETE: {
        triggerHook({
          hookId,
          data: {
            firstName: userInfo?.firstName,
            lastName: userInfo?.lastName,
            companyName: userInfo?.companyName,
            email: userInfo?.email,
          },
        });
      }
    }
  }, [
    showPopUp,
    previousUserInfo,
    userInfo,
    previousIsFollowingLinkedIn,
    isFollowingLinkedIn,
    previousIsNextButtonClicked,
    isNextButtonClicked,
    geoInfo,
  ]);
};

export default useZapierTrigger;

import { resetDataLayerAndPushEvent } from '..';
import { CustomEventName } from '@/constants';
import type { UnsubSummaryEmailMethod } from '@/constants';
import type { LoginState } from '@/interfaces';

export const gtmViewNotified = (loginState: LoginState) => {
  resetDataLayerAndPushEvent(CustomEventName.ViewNotified, {
    loginState,
  });
};

export const gtmClickNotified = (loginState: LoginState) => {
  resetDataLayerAndPushEvent(CustomEventName.ClickNotified, {
    loginState,
  });
};

export const gtmViewUnsubAll = () => {
  resetDataLayerAndPushEvent(CustomEventName.ViewUnsubAll);
};

export const gtmClickUnsubAll = () => {
  resetDataLayerAndPushEvent(CustomEventName.ClickUnsubAll);
};

export const gtmClickUnsubEmail = () => {
  resetDataLayerAndPushEvent(CustomEventName.ClickUnsubEmail);
};

export const gtmClickEmailCta = () => {
  resetDataLayerAndPushEvent(CustomEventName.ClickEmailCta);
};

export const gtmUnsubSummaryEmail = ({
  variation,
  method,
}: {
  variation: string;
  method: UnsubSummaryEmailMethod;
}) => {
  resetDataLayerAndPushEvent(CustomEventName.UnsubSummaryEmail, {
    variation,
    method,
  });
};

export const gtmResubSummaryToggle = () => {
  resetDataLayerAndPushEvent(CustomEventName.ResubSummaryToggle);
};

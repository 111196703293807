import { ErrorMessages, MessageField, SignUpSource } from '@/constants';
import { useGeo, useGlobalState } from '@/hooks';
import { type IUserInfo, LoginMethod } from '@/interfaces';
import { userInfoModify } from '@/utils';
import { GoogleOAuthProvider } from '@react-oauth/google';
import getConfig from 'next/config';
import { type AnchorHTMLAttributes, type ComponentType, useMemo } from 'react';

import { PmtOnboardingPopupCta } from '@/interfaces/tracking/trackingOnboardingPopup.interfaces';
import { getGaClientId, getSessionId } from '@/modules/pmt/utils/ga4.utils';
import GoogleLoginButton, { type GoogleLoginButtonProps } from './GoogleLoginButton';
import { useAuthentication } from './hooks';
import type { AuthPayload } from './hooks';

interface IGoogleLoginProps extends AnchorHTMLAttributes<'a'> {
  buttonText?: string;
  shouldReloadAfterSuccess?: boolean;
  signUpSource?: SignUpSource;
  newsletterOptIn?: boolean;
  customHandleSuccess?: (userInfo: IUserInfo) => void;
  customHandleClick?: () => void;
  as?: string | ComponentType<any>;
}

const { publicRuntimeConfig } = getConfig();

const GoogleLogin: React.FC<IGoogleLoginProps> = ({
  buttonText = 'CONTINUE WITH GOOGLE',
  shouldReloadAfterSuccess,
  customHandleSuccess,
  customHandleClick,
  signUpSource = SignUpSource.PMC,
  newsletterOptIn = true,
}) => {
  const { authPM, PMLogin } = useAuthentication();
  const { userInfo, isMyParcelsPage, isFromGetNotifiedBtn, isFromEmailSyncBtn, isFromDelayedShipmentBtn } =
    useGlobalState();
  const { geoInfo } = useGeo();
  const { googleSignInClientId } = publicRuntimeConfig;

  const cta = useMemo(() => {
    switch (true) {
      case isFromGetNotifiedBtn:
        return PmtOnboardingPopupCta.Noti;
      case isFromEmailSyncBtn:
        return PmtOnboardingPopupCta.Login;
      case isFromDelayedShipmentBtn:
        return PmtOnboardingPopupCta.DelayedShipment;
      default:
    }
  }, [isFromGetNotifiedBtn, isFromEmailSyncBtn, isFromDelayedShipmentBtn]);

  const loginButtonProps: GoogleLoginButtonProps = {
    onOAuthError: (error) => {
      console.error({ error });
    },
    onOAuthSuccess: async (response) => {
      const { access_token } = response;

      if (!access_token) {
        throw new Error(ErrorMessages[MessageField.AUTHENTICATE_ERROR].error);
      }

      const systemLoginPayload: AuthPayload = {
        code: access_token,
        signUpSource,
        newsletterOptIn,
        country: userInfo?.country ?? geoInfo.country,
        gaClientId: getGaClientId(),
        gaSessionId: getSessionId(isMyParcelsPage),
        eventTimestamp: Date.now().toString(),
        cta,
        authenMethod: LoginMethod.Google,
      };
      try {
        const res = await authPM(systemLoginPayload);
        if (!res) {
          throw new Error(ErrorMessages[MessageField.AUTHENTICATE_ERROR].error);
        }

        const { access_token, user_info } = res;

        // Login to PMC/PMT
        PMLogin(access_token, user_info);

        // trigger zapier and send GTM
        const modifiedUserInfo = userInfoModify(user_info);
        if (shouldReloadAfterSuccess) {
          location.reload();
        }
        customHandleSuccess?.(modifiedUserInfo); // Change to optional chain: biome rule
      } catch (e) {
        console.error(e);
      }
    },
    redirect_uri: 'postmessage',
    customHandleClick: () => customHandleClick?.(), // Change to optional chain: biome rule
  };

  return (
    <>
      <GoogleOAuthProvider clientId={googleSignInClientId}>
        <GoogleLoginButton {...loginButtonProps}>{buttonText}</GoogleLoginButton>
      </GoogleOAuthProvider>
    </>
  );
};

export default GoogleLogin;

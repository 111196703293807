import styled from 'styled-components';

import { Paragraph } from '@/components/RichText/RichText.styles';
import { TextStyles } from '@/constants';
import { BreadcrumbsSectionContainer } from '../Breadcrumbs/Breadcrumbs.styles';
import PMCAdsense from '../PMCAdsense';
import SectionWrapper from '../SectionWrapper';
import { ThumbnailTitle } from '../Thumbnail/Thumbnail.styles';
import Typography from '../Typography';

export const ArticleWrapper = styled.div`
  padding-bottom: ${({ theme }) => theme.spacing[40]};
  ${BreadcrumbsSectionContainer} {
    margin: ${({ theme }) => `${theme.spacing[20]} ${theme.spacing[20]} ${theme.spacing[16]} ${theme.spacing[20]}`};
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    padding-top: ${({ theme }) => theme.spacing[40]};

    ${BreadcrumbsSectionContainer} {
      margin: ${({ theme }) => `0 0 ${theme.spacing[16]} 0`};
    }
  }
`;

export const InnerContent = styled.div`
  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: flex;
    justify-content: space-between;
  }
`;

export const LeftSection = styled.div`
  ${({ theme }) => theme.mediaBreakpointUp.md} {
    flex: 0 0 736px;
    max-width: 736px;
  }
`;

export const Title = styled(Typography).attrs({
  variant: TextStyles['Heading 1'],
})`
  padding: ${({ theme }) => theme.spacing[8]} 0;
  color: ${({ theme }) => theme.colors.highEmphasisBlack};
`;

export const DateTime = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[14]};
  line-height: ${({ theme }) => theme.lineHeight[22]};
  color: ${({ theme }) => theme.colors.inactiveBlack};
  margin-bottom: ${({ theme }) => theme.spacing[24]};
`;

export const ImageWrapper = styled.div(({ theme }) => ({
  width: '100%',
  display: 'block',
  borderRadius: 4,
  overflow: 'hidden',
  position: 'relative',
  marginBottom: theme.spacing[24],
  height: 200,

  [`${theme.mediaBreakpointUp.md}`]: {
    height: 400,
  },
}));

export const Content = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing[24]};

  &.custom__richtext ${Paragraph} {
    a {
      display: inline;
    }
  }

  &.custom__richtext h1 a,
  &.custom__richtext h2 a,
  &.custom__richtext h3 a,
  &.custom__richtext h4 a,
  &.custom__richtext h5 a,
  &.custom__richtext h6 a {
    font-weight: inherit;
    font-size: inherit;
  }
`;

export const CustomBannerAdsense = styled(PMCAdsense)`
  padding: ${({ theme }) => `${theme.spacing[8]} ${theme.spacing[20]}`};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    padding: 0;
    background: unset;
    border-top: none;
  }
`;

export const SubSectionWrapper = styled(SectionWrapper)`
  padding-right: 0;

  ${ThumbnailTitle} {
    -webkit-line-clamp: 2;
  }
`;

import { CustomEventName } from '@/constants';
import type { LoginState } from '@/interfaces';
import { resetDataLayerAndPushEvent } from '..';

export interface SessionData {
  loginState: LoginState;
  emailSyncOptIn?: 'Yes' | 'No';
  numberOfEmailSyncAccounts?: number;
}

export const gtmSessions = (sessionData: SessionData, isMyParcelsPage: boolean) => {
  resetDataLayerAndPushEvent(isMyParcelsPage ? CustomEventName.PMTSessions : CustomEventName.PMCSessions, sessionData);
};

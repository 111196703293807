import { useInView } from 'framer-motion';
import { useEffect } from 'react';

export const useTriggerWhenFooterIsInView = (action: () => void) => {
  const isInView = useInView({ current: document.getElementById('footer') }, { once: true });

  useEffect(() => {
    if (isInView) action();
  }, [action, isInView]);
};

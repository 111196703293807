import { VerifyStatusCode } from '@/constants';
import type { FailureResponse, IAuthenticateSuccessfulResponse } from '@/interfaces';
import { userInfoModify } from '@/utils';

export const handleVerifyData = (response: IAuthenticateSuccessfulResponse | FailureResponse) => {
  const hasError = 'error' in response;
  if (hasError) {
    return;
  }

  switch (response.status) {
    // token A valid, browser_uuid A valid for user A
    // token A valid, browser_uuid B valid for user A
    case VerifyStatusCode.Valid:
    case VerifyStatusCode.ValidNewBrowser: {
      // token A valid, browser_uuid B invalid/empty
      return {
        userInfo: userInfoModify(response.user_info),
        browserUuid: response.browser_uuid,
        differentBrowser: response.status === VerifyStatusCode.ValidNewBrowser,
      };
    }
    default:
      // token invalid/unknown error
      return {
        errorCode: response.error_code,
      };
  }
};

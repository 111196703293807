import dayjs from 'dayjs';
import Image from 'next/image';
import type React from 'react';
import type { ComponentType } from 'react';
import {
  ThumbnailDate,
  ThumbnailArticleImage,
  ThumbnailTitle,
  ThumbnailEventTitle,
  ThumbnailWrapper,
  ThumbnailDescription,
  ThumbnailMainContent,
  ThumbnailTagWrapper,
  ThumbnailTag,
  ThumbnailRecommended,
  ThumbnailEventImage,
  EventThumbnailDate,
} from './Thumbnail.styles';
import { type ThumbnailProps, ThumbnailVariant } from '@/interfaces';
import { extractDateFromISOString, startAndEndDateFormat } from '@/utils';
import { useGlobalState } from '@/hooks';

const Thumbnail: React.FC<
  ThumbnailProps & {
    className?: string;
    as?: string | ComponentType<any>;
    href?: string;
    priority?: boolean;
  }
> = ({
  date,
  title,
  image,
  seo,
  articleType,
  startDate,
  endDate = '',
  recommended,
  tags: _tags,
  address,
  variant = ThumbnailVariant.LARGE_DEFAULT,
  className,
  as,
  href,
  priority,
}) => {
  const { getProperImageUrl } = useGlobalState();
  const tags = _tags?.slice(0, 2);
  const imageUrl = getProperImageUrl(image);

  const defaultLayout = {
    showImage: !!image,
    showDescription: false,
    showRecommended: recommended,
    showDate: true,
  };

  const layout = {
    [ThumbnailVariant.LARGE_DEFAULT]: {
      ...defaultLayout,
      showDescription: !!seo?.description,
    },
    [ThumbnailVariant.LARGE_HIDE_DES]: {
      ...defaultLayout,
      showDescription: false,
    },
    [ThumbnailVariant.LARGE_HIDE_DATE]: {
      ...defaultLayout,
      showDescription: !!seo?.description,
      showDate: false,
    },
    [ThumbnailVariant.SMALL_DEFAULT]: {
      ...defaultLayout,
      showDescription: false,
    },
    [ThumbnailVariant.SMALL_HIDE_IMG]: {
      ...defaultLayout,
      showImage: false,
      showDescription: false,
    },
    [ThumbnailVariant.SMALL_IMG]: {
      ...defaultLayout,
      showDescription: false,
    },
    [ThumbnailVariant.BLUE_BORDER_LEFT]: {
      ...defaultLayout,
      showImage: false,
      showRecommended: false,
    },
  };

  if (articleType) {
    return (
      <ThumbnailWrapper variant={variant} className={className} as={as} href={href}>
        {layout[variant].showImage && (
          <ThumbnailArticleImage>
            <Image
              src={imageUrl}
              alt={image?.description || 'Article Thumbnail Image'}
              priority={priority}
              placeholder={!priority ? 'blur' : 'empty'}
              blurDataURL='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNcVQ8AAdkBKwhkHIIAAAAASUVORK5CYII='
              fill
              sizes='100vw'
              style={{
                objectFit: 'cover',
                objectPosition: 'center',
              }}
            />
          </ThumbnailArticleImage>
        )}
        <ThumbnailMainContent>
          <ThumbnailTitle>{title}</ThumbnailTitle>
          {layout[variant].showDescription && <ThumbnailDescription>{seo?.description}</ThumbnailDescription>}
          {layout[variant].showDate && <ThumbnailDate>{dayjs(date).format('MMM DD, YYYY')}</ThumbnailDate>}
        </ThumbnailMainContent>
      </ThumbnailWrapper>
    );
  }

  const isCompletedEvent = Date.now() > Date.parse(endDate);

  return (
    <ThumbnailWrapper variant={variant} className={className} as={as} href={href}>
      {layout[variant].showImage && (
        <ThumbnailEventImage>
          <Image
            src={imageUrl}
            alt={image?.description || 'Event Thumbnail Image'}
            priority={priority}
            placeholder={!priority ? 'blur' : 'empty'}
            blurDataURL='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNcVQ8AAdkBKwhkHIIAAAAASUVORK5CYII='
            fill
            sizes='100vw'
            style={{
              objectFit: 'cover',
              objectPosition: 'center',
            }}
          />
        </ThumbnailEventImage>
      )}
      <ThumbnailMainContent>
        {tags && tags.length > 0 && (
          <ThumbnailTagWrapper>
            {isCompletedEvent ? (
              <ThumbnailTag noTruncate>COMPLETED EVENT</ThumbnailTag>
            ) : (
              tags.map((tag, index) => {
                return <ThumbnailTag key={index}>{tag?.title || tag?.marketName}</ThumbnailTag>;
              })
            )}
          </ThumbnailTagWrapper>
        )}
        <ThumbnailEventTitle>{title}</ThumbnailEventTitle>
        <EventThumbnailDate>
          {startDate && startAndEndDateFormat(extractDateFromISOString(startDate), extractDateFromISOString(endDate))}
          {address && ' | ' + address}
        </EventThumbnailDate>
        {layout[variant].showDescription && <ThumbnailDescription>{seo?.description}</ThumbnailDescription>}
      </ThumbnailMainContent>
      {layout[variant].showRecommended && <ThumbnailRecommended />}
    </ThumbnailWrapper>
  );
};

export default Thumbnail;

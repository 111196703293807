import { query, limit, collection, type Query, type CollectionReference, getCountFromServer } from 'firebase/firestore';
import { firestoreDB } from '@/lib';
import {
  DEFAULT_QUERY_LIMIT,
  FILTER_OPTIONS,
  DEFAULT_FILTER_OPTION,
  FILTER_OPTION_TO_WHERE_CLAUSE,
  SORT_OPTIONS,
  DEFAULT_SORT_OPTION,
  SORT_OPTION_TO_ORDER_BY_CLAUSE,
  SORT_DIRECTIONS,
  DEFAULT_SORT_DIRECTION,
  EXCLUDE_DELETED_SHIPMENTS_QUERY,
  EDD_DELIVERED_SHIPMENTS_QUERY,
} from '@/modules/pmt/constants';
import { allowedSortByEDD, excludeDeleted, getEddShipmentsQuery } from '@/modules/pmt/utils';

type TGetCustomQueryArgs = {
  filterBy: FILTER_OPTIONS;
  sortBy: SORT_OPTIONS;
  sortDirection: SORT_DIRECTIONS;
  queryLimit: number;
};

class QueryBuilder {
  private _userId: string | null = null;
  private _filterBy: FILTER_OPTIONS = DEFAULT_FILTER_OPTION;
  private _sortBy: SORT_OPTIONS = DEFAULT_SORT_OPTION;
  private _sortDirection: SORT_DIRECTIONS = DEFAULT_SORT_DIRECTION;
  private _limit: number = DEFAULT_QUERY_LIMIT;
  private _collectionReference: CollectionReference | null = null;

  init(userId: string): this {
    this._userId = userId;
    this._collectionReference = collection(firestoreDB, `users/${userId}/shipments`);
    return this;
  }

  updateFilterBy(filterBy: FILTER_OPTIONS): this {
    this._filterBy = filterBy;
    return this;
  }

  updateSortBy(sortBy: SORT_OPTIONS): this {
    this._sortBy = sortBy;
    return this;
  }

  updateSortDirection(sortDirection: SORT_DIRECTIONS): this {
    this._sortDirection = sortDirection;
    return this;
  }

  updateLimit(limit: number): this {
    this._limit = limit;
    return this;
  }

  buildDeliveredShipmentsQuery({ queryLimit }: { queryLimit: number }) {
    if (!this._userId || !this._collectionReference) {
      console.error('User ID/Collection Reference is not set!');
      return null;
    }

    const sortDirection = SORT_DIRECTIONS.DESC;
    const sortBy = SORT_OPTIONS.LATEST_UPDATED;
    const orderByClause = SORT_OPTION_TO_ORDER_BY_CLAUSE(sortDirection)[sortBy];
    const whereClause = EDD_DELIVERED_SHIPMENTS_QUERY;

    return query(this._collectionReference, whereClause, orderByClause, limit(queryLimit));
  }

  buildQuery({ filterBy, sortBy, sortDirection, queryLimit }: TGetCustomQueryArgs): Query | null {
    if (!this._userId || !this._collectionReference) {
      console.error('User ID/Collection Reference is not set!');
      return null;
    }

    const orderByClause = SORT_OPTION_TO_ORDER_BY_CLAUSE(sortDirection)[sortBy];
    let whereClause = allowedSortByEDD(filterBy, sortBy)
      ? getEddShipmentsQuery(filterBy)
      : FILTER_OPTION_TO_WHERE_CLAUSE[filterBy];
    // All shipments query alredady excluded is_deleted=true shipments
    if (filterBy !== FILTER_OPTIONS.ALL) {
      whereClause = excludeDeleted(whereClause);
    }

    return query(this._collectionReference, whereClause as any, orderByClause, limit(queryLimit));
  }

  getCurrentQuery(): Query | null {
    return this.buildQuery({
      filterBy: this._filterBy,
      sortBy: this._sortBy,
      sortDirection: this._sortDirection,
      // get one more to check if there are more shipments to fetch
      queryLimit: this._limit + 1,
    });
  }

  async getTotalShipments(): Promise<number | null> {
    if (!this._userId || !this._collectionReference) {
      console.error('User ID/Collection Reference is not set!');
      return null;
    }

    const countQuery = query(this._collectionReference, EXCLUDE_DELETED_SHIPMENTS_QUERY);
    const snapshot = await getCountFromServer(countQuery);
    return snapshot.data().count;
  }
}

const queryBuilder = new QueryBuilder();

export { queryBuilder };

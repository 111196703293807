import { capitalize } from './text.utils';

interface Breadcrumb {
  label?: string;
  slug?: string;
}

const homeAddress = {
  label: 'Home',
  slug: '/',
};

/**
 * Generate breadcrumbs based on the path directories
 * @param directories - array of preceding path directories, e.g. ['companies', 'carriers'] for '/companies/carriers/dhl-express'
 * @returns array of breadcrumbs including Home, with each breadcrumb containing a slug and the title for that path
 * @example
 * const breadcrumbs = autoGeneratedBreadcrumb(['companies', 'carriers'])
 * // breadcrumbs = [
 * //   { label: 'Home', slug: '/' },
 * //   { label: 'Companies', slug: '/companies' },
 * //   { label: 'Carriers', slug: '/companies/carriers' },
 * // ]
 * const breadcrumbs = autoGeneratedBreadcrumb(['retailers'])
 * // breadcrumbs = [
 * //   { label: 'Home', slug: '/' },
 * //   { label: 'Retailers', slug: '/retailers' },
 * // ]
 */
const autoGeneratedBreadcrumb = (directories: string[]) => {
  return [
    homeAddress,
    ...directories.map((directory: string, index: number) => {
      const slug = '/' + directories.slice(0, index + 1).join('/');
      const label = capitalize(directory);

      return { label, slug };
    }),
  ];
};

type GetPreviousLevels = (asPath: string) => string[];

/**
 * Get path directories based on the current path. Unique case: Individual Retailer page
 * @param asPath - current path, e.g. '/companies/carriers/dhl-express'
 * @returns array of preceding path directories, e.g. ['companies', 'carriers']
 * @example
 * const previousLevels = getPreviousLevels('/companies/carriers/dhl-express')
 * // previousLevels = ['companies', 'carriers']
 * const previousLevels = getPreviousLevels('/companies/retailers/ajio')
 * // previousLevels = ['retailers']
 */
export const getPathDirectories: GetPreviousLevels = (asPath) => {
  const RETAILER_PATH = '/companies/retailers';
  const levels = asPath.split('/').filter(Boolean);
  return asPath.startsWith(RETAILER_PATH) ? levels.slice(1, -1) : levels.slice(0, -1);
};

type GetBreadcrumbsData = (params: {
  asPath: string;
  title?: string;
}) => Breadcrumb[] | undefined | null;

/**
 * Get breadcrumbs array based on the current path and current page title. Unique case: Individual Retailer page
 * @param asPath - current path, e.g. '/companies/carriers/dhl-express'
 * @param title - current page title, e.g. 'DHL Express'
 * @returns array of breadcrumbs, with each breadcrumb containing a slug and the title for that path (except for the current page, which only contains the title)
 * @example
 * const breadcrumbs = getBreadcrumbData({
 *  asPath: '/companies/carriers/dhl-express',
 *  title: 'DHL Express',
 * })
 * // breadcrumbs = [
 * //   { label: 'Home', slug: '/' },
 * //   { label: 'Companies', slug: '/companies' },
 * //   { label: 'Carriers', slug: '/companies/carriers' },
 * //   { label: 'DHL Express' },
 * // ]
 * const breadcrumbs = getBreadcrumbData({
 *  asPath: '/companies/retailers/ajio',
 *  title: 'Ajio',
 * })
 * // breadcrumbs = [
 * //   { label: 'Home', slug: '/' },
 * //   { label: 'Retailers', slug: '/retailers' },
 * //   { label: 'Ajio' },
 * // ]
 */
export const getBreadcrumbData: GetBreadcrumbsData = ({ asPath, title }) => {
  const pathDirectories = getPathDirectories(asPath);

  return [...autoGeneratedBreadcrumb(pathDirectories), { label: title }];
};

import type { ButtonProps, SectionThemeKey } from '@/interfaces';

export enum LeadGenBannerVariant {
  DEFAULT = 'Default',
  SMALL = 'Small',
  FULL_WIDTH = 'Full-width',
}

export interface ILeadGenBanner {
  sys: { id: string };
  title?: string;
  titleMobile?: string;
  image?: {
    contentType?: string;
    url: string;
    description?: string;
  };
  imageMobile?: {
    contentType?: string;
    url: string;
    description?: string;
  };
  subCopy?: string;
  subCopyMobile?: string;
  buttons?: ButtonProps[] | null;
  sectionTheme?: SectionThemeKey;
  isLinkedInBanner?: boolean;
}

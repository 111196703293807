import { theme } from '@/constants';
import type typography from '@/constants/theme/typography';
import { getCountryName } from '@/utils';
import Link from 'next/link';
import type { FC } from 'react';
import { CountryCode, CountryCodeWrapper, CountryFlag } from './CountryOption.styles';

const CountryOption: FC<{
  showName?: boolean;
  onlyFlag?: boolean;
  code: string;
  flagSize?: number;
  href?: string;
}> = ({ showName, onlyFlag, code, flagSize, href }) => {
  const countryName = getCountryName(code);

  const renderText = () => {
    if (onlyFlag) return null;

    if (href) {
      return (
        <Link href={href}>
          <CountryCode>{code}</CountryCode>
        </Link>
      );
    }

    return showName ? countryName : <CountryCode>{code}</CountryCode>;
  };

  return (
    <CountryCodeWrapper align='center'>
      <CountryFlag
        style={{ fontSize: theme.fontSizes[(flagSize as keyof typeof typography.fontSizes) || 30] }}
        countryCode={code}
        alt={countryName}
      />
      {renderText()}
    </CountryCodeWrapper>
  );
};

export default CountryOption;

import Button from '@/components/Button';
import { ButtonSize, ButtonVariant } from '@/interfaces';
import styled from 'styled-components';

export const Container = styled.div`
  display: inline-flex;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing[8]};
  align-items: center;
`;

export const ViewMoreButton = styled(Button).attrs({
  variant: ButtonVariant.Link,
  size: ButtonSize.Large,
})`
  margin-left: auto;
`;

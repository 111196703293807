export type BreakpointNames = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

export type Breakpoints = {
  [key in BreakpointNames]?: string;
};

export enum ResponsiveCollection {
  CarouselMaxSize = 'isCarouselMaxSize',
  DesktopPMT = 'isDesktopPMT',
  Tablet = 'isTablet',
  Mobile = 'isMobile',
  Desktop = 'isDesktop',
}

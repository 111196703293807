import { pmcAdSlots } from '@/constants';
import type { PMCAdType } from '@/interfaces';
import Adsense from '../Adsense';

type PMCAdsenseProps = { variant: PMCAdType; id?: string; className?: string };

const PMCAdsense: React.FC<PMCAdsenseProps> = ({ variant, ...props }) => {
  if (!pmcAdSlots[variant]) return null;
  return <Adsense {...props} dataAdSlot={pmcAdSlots[variant]} />;
};

export default PMCAdsense;

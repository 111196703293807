import { ArticleTypeName, ContentTypeName } from '@/constants';
import { removeDuplicateCTFObjects, sortByDate } from '@/utils';
import { utils_getEventsByTagList, utils_getLatestEventThumbnails } from './event.utils';
import {
  type ITag,
  type IArticleProps,
  type ThumbnailProps,
  type IThumbnailSection,
  type IHighlightSection,
  SectionItemType,
  type IMarketAndTag,
  SectionArticleType,
} from '@/interfaces';

type GetArticle = (args: {
  articles: IArticleProps[];
  slug: string;
  articleTypeName: string;
}) => IArticleProps | null;

export const utils_getArticle: GetArticle = ({ articles, slug, articleTypeName }) => {
  return articles.filter((article) => article.slug === slug && article.articleType === articleTypeName)[0];
};

type GetLandingPageThumbnailSectionData = (args: {
  foundThumbnailSection: IThumbnailSection;
  foundHighlightSection: IHighlightSection | undefined | null;
  allThumbnailList: ThumbnailProps[];
  allEventList: ThumbnailProps[];
}) => ThumbnailProps[];

export const utils_getLandingPageThumbnailSectionData: GetLandingPageThumbnailSectionData = ({
  foundThumbnailSection,
  foundHighlightSection,
  allThumbnailList,
  allEventList,
}) => {
  // Orion-17 scope
  const { tags, itemType } = foundThumbnailSection;

  const filteredOrTags = tags?.items?.filter(Boolean);
  const isEventType = itemType === SectionItemType.Event;
  // Check if the tags have the same Type as ItemType; ItemType has more priority
  const itemTypeBasedTags = isEventType
    ? filteredOrTags?.filter((tag: ITag) => tag.type === ArticleTypeName.events)
    : filteredOrTags?.filter((tag: ITag) => tag.type !== ArticleTypeName.events);
  let thumbnailSectionThumbnails: ThumbnailProps[] = [];

  if (itemTypeBasedTags?.length > 0) {
    thumbnailSectionThumbnails = isEventType
      ? utils_getEventsByTagList({ events: allEventList, tags: itemTypeBasedTags })
      : utils_getThumbnailsByTagList({ articles: allThumbnailList, tags: itemTypeBasedTags });
  } else if (!isEventType) {
    thumbnailSectionThumbnails = allThumbnailList;
  }

  if (!isEventType) {
    thumbnailSectionThumbnails = utils_getThumbnailSectionDataBasedOnAndLogic({
      foundThumbnailSection,
      thumbnailSectionThumbnails,
    });
  }

  let latestData: ThumbnailProps[] = [];

  if (foundHighlightSection) {
    latestData = thumbnailSectionThumbnails.filter(
      (thumbnailItem) =>
        !foundHighlightSection?.thumbnails?.find((highlightItem) => highlightItem.sys.id === thumbnailItem.sys.id),
    );
  } else {
    latestData = thumbnailSectionThumbnails;
  }

  return latestData;
};

type GetTagPageThumbnailSectionData = (args: {
  foundThumbnailSection: IThumbnailSection;
  foundHighlightSection: IHighlightSection | undefined;
  allThumbnailList: ThumbnailProps[];
  allEventList: ThumbnailProps[];
  tagDataType?: ArticleTypeName;
  tagDataSlug: string;
}) => ThumbnailProps[];

export const utils_getTagPageThumbnailSectionData: GetTagPageThumbnailSectionData = ({
  foundThumbnailSection,
  foundHighlightSection,
  allThumbnailList,
  allEventList,
  tagDataType,
  tagDataSlug,
}) => {
  const filteredTags = foundThumbnailSection.tags?.items?.filter(Boolean);
  const isEventType = foundThumbnailSection.itemType === SectionItemType.Event;
  // Check if the tags have the same Type as ItemType; ItemType has more priority
  // TODO-17: Check again if need new logic to show Article or Event in ThumbnailSection ticket
  const itemTypeBasedTags = isEventType
    ? filteredTags?.filter((tag: ITag) => tag.type === ArticleTypeName.events)
    : filteredTags?.filter((tag: ITag) => tag.type !== ArticleTypeName.events);
  let thumbnailSectionThumbnails: ThumbnailProps[] = [];

  if (!itemTypeBasedTags || itemTypeBasedTags.length === 0) {
    thumbnailSectionThumbnails = isEventType
      ? utils_getLatestEventThumbnails({
          events: allEventList,
          slug: tagDataSlug,
          type: tagDataType,
        })
      : utils_getThumbnailsByTag({
          articles: allThumbnailList,
          slug: tagDataSlug,
          type: tagDataType,
        });
  } else {
    thumbnailSectionThumbnails = isEventType
      ? utils_getEventsByTagList({
          events: allEventList,
          tags: itemTypeBasedTags,
        })
      : utils_getThumbnailsByTagList({
          articles: allThumbnailList,
          tags: itemTypeBasedTags,
        });
  }

  if (!isEventType) {
    thumbnailSectionThumbnails = utils_getThumbnailSectionDataBasedOnAndLogic({
      foundThumbnailSection,
      thumbnailSectionThumbnails,
    });
  }

  let latestData: ThumbnailProps[] = [];

  if (foundHighlightSection) {
    latestData = thumbnailSectionThumbnails.filter(
      (thumbnailItem) =>
        !foundHighlightSection?.thumbnails?.find((highlightItem) => highlightItem.sys.id === thumbnailItem.sys.id),
    );
  } else {
    latestData = thumbnailSectionThumbnails;
  }

  return latestData;
};

type FindMarketOrTag = (
  tags?: IMarketAndTag[],
  args?: {
    type: string | undefined | null;
    slug: string | undefined | null;
    __typename?: ContentTypeName;
  },
) => boolean;

const findMarketOrTag: FindMarketOrTag = (tags, args) => {
  const __typename = args?.__typename || ContentTypeName.Tag;
  if (__typename === ContentTypeName.Market) {
    return !!tags?.find((tag) => {
      const isMarketContentType = tag.__typename === ContentTypeName.Market;
      if (!args?.slug) return isMarketContentType;
      const isSlugEquals = (tag.slug || '') === (args?.slug || '');
      return isMarketContentType && isSlugEquals;
    });
  }

  return !!tags?.find((tag) => {
    const isTagContentType = tag.__typename === ContentTypeName.Tag;
    const isTypeEquals = (tag.type || '') === (args?.type || '');
    const isSlugEquals = (tag.slug || '') === (args?.slug || '');
    return isTagContentType && isTypeEquals && isSlugEquals;
  });
};

type GetThumbnailsByTag = (args: {
  isFeatured?: boolean;
  articles?: ThumbnailProps[];
  slug?: string;
  type?: string;
  __typename?: ContentTypeName;
}) => ThumbnailProps[];

export const utils_getThumbnailsByTag: GetThumbnailsByTag = ({
  isFeatured = false,
  articles = [],
  type,
  slug,
  __typename,
}) => {
  return articles?.filter((article) => {
    const hasTag = findMarketOrTag(article.tags, { type, slug, __typename });
    if (isFeatured) {
      return !!article.featured && hasTag;
    }
    return hasTag;
  });
};

type GetThumbnailsByArticleType = (args: {
  isFeatured?: boolean;
  articles?: ThumbnailProps[];
  articleType?: string;
  currentArticleId?: string;
}) => ThumbnailProps[];

export const utils_getThumbnailsByArticleType: GetThumbnailsByArticleType = ({
  isFeatured = false,
  articles = [],
  articleType,
  currentArticleId,
}) => {
  const isNotCurrentArticle = (article: ThumbnailProps) => {
    if (currentArticleId) {
      return article.sys.id !== currentArticleId;
    }
    return true;
  };
  const isArticleTypeMatch = (article: ThumbnailProps) => article.articleType === articleType;
  const isFeaturedArticle = (article: ThumbnailProps) => !!article.featured;

  return articles?.filter((article) => {
    if (isFeatured) {
      return isFeaturedArticle(article) && isArticleTypeMatch(article) && isNotCurrentArticle(article);
    }
    // featured = null or featured = false
    return isArticleTypeMatch(article) && isNotCurrentArticle(article);
  });
};

type GetThumbnailsByTagList = (args: {
  isFeatured?: boolean;
  articles?: ThumbnailProps[];
  tags?: ITag[]; // ThumbnailSection's and HighlightSection's tags
}) => ThumbnailProps[];

export const utils_getThumbnailsByTagList: GetThumbnailsByTagList = ({ isFeatured = false, articles = [], tags }) => {
  const data = articles?.filter((article) => {
    const hasTag = tags?.some((tag) =>
      findMarketOrTag(article.tags, {
        type: tag.type,
        slug: tag.slug,
        __typename: tag.__typename,
      }),
    );
    if (isFeatured) {
      return !!article.featured && hasTag;
    }
    return hasTag;
  });

  const sortedData = sortByDate(data);
  return removeDuplicateCTFObjects(sortedData);
};

interface IGetRelatedArticleArgs {
  articles: ThumbnailProps[];
  currentArticleTags: ITag[] | undefined | null;
  currentArticleSysId: string;
}

export const utils_getRelatedArticles = (args: IGetRelatedArticleArgs) => {
  const { articles, currentArticleTags, currentArticleSysId } = args;

  const firstTag = currentArticleTags?.[0];

  if (!firstTag) return null;

  // TODO: We will see what happened after this and revert if needed
  const thumbnailsWithFirstTag: ThumbnailProps[] =
    utils_getThumbnailsByTag({
      articles,
      slug: firstTag.slug,
      type: firstTag.type,
    }) || [];

  const allMatchedTagThumbnails: ThumbnailProps[] = thumbnailsWithFirstTag?.filter((article: ThumbnailProps) =>
    currentArticleTags
      ?.slice(1)
      ?.every((currentTag: ITag) => article?.tags?.some((tag: ITag) => tag?.sys?.id === currentTag?.sys?.id)),
  );

  const sortedMatchedThumbnails = allMatchedTagThumbnails || [];

  const relatedThumbnails: ThumbnailProps[] = [...sortedMatchedThumbnails, ...thumbnailsWithFirstTag];

  const uniqueRelatedThumbnailsBySysId = relatedThumbnails?.reduce(
    (result: ThumbnailProps[], current: ThumbnailProps) => {
      const isDuplicate = result?.some((article: ThumbnailProps) => article.sys.id === current.sys.id);
      const isCurrentArticle = current.sys.id === currentArticleSysId; // Except current post

      return isDuplicate || isCurrentArticle ? result : [...result, current];
    },
    [],
  );

  return uniqueRelatedThumbnailsBySysId;
};

type GetThumbnailSectionDataBasedOnAndLogic = (args: {
  foundThumbnailSection: IThumbnailSection;
  thumbnailSectionThumbnails: ThumbnailProps[];
}) => ThumbnailProps[];

export const utils_getThumbnailSectionDataBasedOnAndLogic: GetThumbnailSectionDataBasedOnAndLogic = ({
  foundThumbnailSection,
  thumbnailSectionThumbnails,
}) => {
  const { articleType, tags, containsMarketTag } = foundThumbnailSection;

  const filteredOrTags = tags?.items?.filter(Boolean);
  const filteredAndTags = [];

  if (articleType && articleType !== SectionArticleType.notSelected) {
    const findArticleType = filteredOrTags.find((tag: ITag) => tag.type === articleType && tag.slug === null);
    if (!findArticleType) {
      filteredAndTags.push({
        __typename: ContentTypeName.Tag,
        type: articleType,
        slug: null,
      } as any);
    }
  }

  if (containsMarketTag) {
    filteredAndTags.push({
      __typename: ContentTypeName.Market,
    });
  }

  if (!filteredAndTags?.length) return thumbnailSectionThumbnails;

  filteredAndTags.forEach((tag) => {
    thumbnailSectionThumbnails = utils_getThumbnailsByTag({
      articles: thumbnailSectionThumbnails,
      __typename: tag.__typename,
      slug: tag.slug,
      type: tag.type,
    });
  });

  return thumbnailSectionThumbnails;
};

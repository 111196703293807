import styled from 'styled-components';

import type { WidthType } from '@/interfaces';
import Label from '../../Label';
import { styledText } from '@/components/Typography/Typography.styles';
import { theme } from '@/constants';

export const filledInputStyle = `
  top: -11px;
  transform: translateY(0);
  transform-origin: top left;
  left: 8px;
  background: ${theme.colors.highEmphasisWhite};

  ${styledText['Body Text Small']}
`;

export const TextInput = styled.input.attrs(({ type }) => ({ type: type || 'text' }))<{ hasValue?: boolean }>`
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  border: none;
  background: none;
  z-index: 1;
  position: relative;
  color: ${theme.colors.highEmphasisBlack};
  ${styledText['Body Text Large']}

  :focus {
    outline: none;
  }

  ${({ defaultValue }) =>
    defaultValue &&
    `
    ~ ${Label} {
      ${filledInputStyle}
    }
  `}

  :focus ~ ${Label} {
    ${filledInputStyle}
    color: ${theme.colors.blue};
  }
`;

export const TextInputWrapper = styled.div<{ isInvalid: boolean; width?: WidthType }>`
  margin-top: ${({ theme }) => theme.spacing[10]};
  border: 1px solid ${({ theme }) => theme.colors.inactiveBlack};
  height: 45px;
  padding: 9px ${({ theme }) => theme.spacing[14]};
  border-radius: 4px;
  overflow-x: hidden;
  overflow-x: visible;
  position: relative;

  &:focus-within {
    border: solid 2px ${theme.colors.blue};
  }

  ${({ isInvalid, theme }) =>
    isInvalid &&
    `
    border: solid 1px ${theme.colors.error} !important;

    &:focus-within ${Label}, ${Label} {
      color: ${theme.colors.error};
    }
  `}
`;

import styled from 'styled-components';
import { LayoutHierarchy, TextStyles } from '@/constants';
import Typography from '@/components/Typography';
import { FormSubmitButton } from '../Form/Form.styles';

export const PopupContainer = styled.div`
  position: fixed;
  z-index: ${LayoutHierarchy.Second};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PopupOverlay = styled.div<{ on?: string }>`
  background: ${({ theme }) => theme.colors.overlay};
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
`;

export const PopupWrapper = styled.div<{ noTopPosition?: boolean }>`
  position: absolute;
  top: unset;
  bottom: unset;
  left: unset;
  width: 100%;
  max-width: 335px;
  text-align: center;
  border-radius: 8px;
  overflow: hidden;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    max-width: 480px;
  }
`;

export const PopupContentWrapper = styled.div`
  ${({ theme }) => `
    background: ${theme.colors.highEmphasisWhite};
    padding: ${theme.spacing[40]} ${theme.spacing[20]};

    ${theme.mediaBreakpointUp.md} {
      padding: ${theme.spacing[40]};
    }
  `}
`;

export const PopupHeader = styled.div`
  position: relative;
  background: ${({ theme }) => theme.colors.darkBlue};
  height: ${({ theme }) => theme.spacing[40]};
`;

export const Title = styled(Typography).attrs({
  variant: TextStyles['Heading 3'],
  type: 'p',
})`
  margin-bottom: ${({ theme }) => theme.spacing[4]};
`;

export const Subcopy = styled(Typography).attrs({
  variant: TextStyles['Body Text Large'],
})``;

export const SendLinkButton = styled(FormSubmitButton)`
  margin-top: ${({ theme }) => theme.spacing[24]};
`;

export const EmailSent = styled(Typography).attrs({
  variant: TextStyles['Heading 2'],
})`
  width: 100%;
  text-align: center;
  margin-top: ${({ theme }) => theme.spacing[16]};
  color: ${({ theme }) => theme.colors.highEmphasisBlack};
  word-break: break-word;
`;

import { CookieKey, IP_INFO_TOKENS, IpInfoEnv, UNKNOWN_COUNTRY } from '@/constants';
import { useGlobalState } from '@/hooks';
import type { IGeo } from '@/interfaces';
import { fetchGeoLocationFromIp2c, fetchGeoLocationFromIpInfo } from '@/utils';
import Cookies from 'js-cookie';
import getConfig from 'next/config';
import { type FC, createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';

interface IGeoState {
  geoInfo: IGeo;
}

const defaultGeoInfo = {
  /** country of userInfo or detected by ipinfo/ip2c, defaults to 'Unknown' */
  country: UNKNOWN_COUNTRY,
  /** fetch status, is true when country is detected by ipinfo/ip2c
   *  or after checking ipinfo/ip2c country is not found (Unknown).
   *  Is false by default.
   */
  fetched: false,
};

// By default, show cookie banner
const GeoContext = createContext<IGeoState>({ geoInfo: defaultGeoInfo });

const getIpInfoTokens = () => {
  const { publicRuntimeConfig } = getConfig();
  const isProd = publicRuntimeConfig.contentfulEnvironment === 'master';
  const _ipInfoEnv = isProd ? IpInfoEnv.Prod : IpInfoEnv.NonProd;
  return publicRuntimeConfig.ipInfoTokens ?? IP_INFO_TOKENS[_ipInfoEnv];
};

const handleLastValidToken = (isLastValidTokenExists: boolean) => {
  if (!isLastValidTokenExists) {
    Cookies.remove(CookieKey.LastValidIpInfoToken);
  }
};

export const GeoProvider: FC = ({ children }) => {
  const { userInfo } = useGlobalState();
  const retryCountRef = useRef(0);
  const tokenRef = useRef<string | null>(null);
  const [geoInfo, setGeoInfo] = useState<IGeo>({
    country: userInfo?.country ?? UNKNOWN_COUNTRY,
    fetched: !!userInfo?.country,
  });
  const ipInfoTokens = getIpInfoTokens();
  const maxRetry = ipInfoTokens.length - 1;

  //* Fetch GEOLOCATION
  useEffect(() => {
    //* S1: If the user's country is already known, do not fetch
    if (userInfo?.country) return;

    const getInitialTokenFromLocalStorageOrEnv = () => {
      const lastValidToken = Cookies.get(CookieKey.LastValidIpInfoToken);
      const isLastValidTokenExists = !!lastValidToken && ipInfoTokens.includes(lastValidToken);

      tokenRef.current = isLastValidTokenExists ? lastValidToken : ipInfoTokens[0];

      // If lastValidToken is not in the list of tokens, use the first token (even if last token works)
      handleLastValidToken(isLastValidTokenExists);
    };

    const getAndSetIpInfoResponse = async (token: string) => {
      try {
        const response = await fetchGeoLocationFromIpInfo(token);
        setGeoInfo(response);
        Cookies.set(CookieKey.LastValidIpInfoToken, token);
      } catch {
        /**
         * *===How it works===*
         * The 3 lines below will start the process of using the next token in the list.
         * It's done by using the current token's index in the list to get the next token's index.
         * The final token will be the token before the first failed token.
         * Example:
         * *===Current token list===*
         * - ipInfoTokens: ['token1-expired', 'token2-expired-local-storage', 'token3-expired']
         * *===Scenario===*
         * - User has previously used token2-expired-local-storage, and it is stored in user's local storage
         * - But token2-expired-local-storage is now expired
         * *===Logic===*:
         * *Step 1: Use token2-expired-local-storage from Local Storage => Expired so go into catch block
         * - currentTokenIndex = 1
         * - newTokenIndex = (1 + 1) % 3 = 2
         * - newToken = ipInfoTokens[2] = 'token3-expired'
         * - retryCountRef.current = 0 + 1 = 1
         * *Step 2: Use token3-expired from Env Variables => Expired so go into catch block
         * - currentTokenIndex = 2
         * - newTokenIndex = (2 + 1) % 3 = 0
         * - newToken = ipInfoTokens[0] = 'token1-expired'
         * - retryCountRef.current = 1 + 1 = 2
         * *===Stop condition===* => Catch after Step 3, so not repeat the list
         * maxRetry = ipInfoTokens.length - 1 = 2
         * shouldFetchIpInfo = retryCountRef.current <= maxRetry
         * => Stop after Step 2, where retryCountRef.current = 2, and maxRetry = 2
         */
        const currentTokenIndex = ipInfoTokens.indexOf(token);
        const newTokenIndex = (currentTokenIndex + 1) % ipInfoTokens.length;
        const newToken = ipInfoTokens[newTokenIndex];

        retryCountRef.current += 1;

        //* S4: If there is no token left to use, or if the token is out of quota, keep default state
        const shouldFetchIpInfo = retryCountRef.current <= maxRetry;
        if (!shouldFetchIpInfo) {
          setGeoInfo({ country: UNKNOWN_COUNTRY, fetched: true });
          return;
        }

        //* S5: If there is a remaining token to use, handle fetching GEOLOCATION from ipinfo.io using the token
        getAndSetIpInfoResponse(newToken);
      }
    };

    const getAndSetIp2cResponse = async () => {
      try {
        const response = await fetchGeoLocationFromIp2c();
        //* S3a: If fetch from ip2c successful, set the geoInfo
        setGeoInfo(response);
      } catch (error) {
        //* S3b: If fetch from ip2c not successful, start getting/setting the token for ipinfo.io
        getInitialTokenFromLocalStorageOrEnv();
        tokenRef.current && getAndSetIpInfoResponse(tokenRef.current);
        console.error(error);
      }
    };

    //* S2: Handle fetching GEOLOCATION from ip2c.org first
    getAndSetIp2cResponse();
  }, []);

  const value = useMemo(() => ({ geoInfo }), [geoInfo]);

  return <GeoContext.Provider value={value}>{children}</GeoContext.Provider>;
};

const useGeo = (): IGeoState => useContext(GeoContext);

export default useGeo;

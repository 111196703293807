import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import { LanguageCode, SortDirection } from '@/interfaces';

dayjs.extend(isToday);
dayjs.extend(isYesterday);
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

const locales = Object.values(LanguageCode).reduce(
  (acc, languageCode) => {
    return { ...acc, [languageCode]: () => import(`dayjs/locale/${languageCode}.js`) };
  },
  <{ [key in LanguageCode]: () => Promise<any> }>{},
);

export const loadDayjsLocale = (languageCode: LanguageCode) => {
  locales[languageCode]().then(() => dayjs.locale(languageCode));
};

type SortDatesByClosest = <TArrayElement extends Record<string, any>>(
  arrayOfDates: TArrayElement[],
  closest?: Date,
  sortByField?: string,
) => TArrayElement[];

/**
 * @param arrayOfDates array with item format {date: string}
 * @param closest closest date, default is current date
 */
export const sortDatesByClosest: SortDatesByClosest = (
  arrayOfDates,
  closest = new Date(),
  sortByField = 'startDate',
) => {
  const closestDate: number = new Date(closest).getTime();

  return arrayOfDates.sort((a, b) => {
    const aToDate = new Date(a?.[sortByField] as string).getTime();
    const bToDate = new Date(b?.[sortByField] as string).getTime();
    return Math.abs(aToDate - closestDate) - Math.abs(bToDate - closestDate);
  });
};

type SortFromCurrentDate = <TArrayElement extends Record<string, any>>(
  arrayOfDates: TArrayElement[],
  isSortByClosest?: boolean,
  getByField?: string,
) => TArrayElement[];

export const sortFromCurrentDate: SortFromCurrentDate = (
  arrayOfDates,
  isSortByClosest = true,
  getByField = 'startDate',
) => {
  if (!arrayOfDates?.length) return arrayOfDates;

  const sortFromCurrentDateEvents = arrayOfDates?.filter((item: any) => new Date(item?.[getByField]) >= new Date());
  return isSortByClosest ? sortDatesByClosest(sortFromCurrentDateEvents) : sortFromCurrentDateEvents;
};

export const startAndEndDateFormat = (startDate: string, endDate: string | undefined) => {
  if (endDate) {
    const start = new Date(startDate).getTime();
    const end = new Date(endDate).getTime();

    if (end - start > 0) {
      if (dayjs(startDate).format('YYYY') !== dayjs(endDate).format('YYYY'))
        // diff year
        return dayjs(startDate).format('MMM DD, YYYY') + ' - ' + dayjs(endDate).format('MMM DD, YYYY');

      if (dayjs(startDate).format('MMM') !== dayjs(endDate).format('MMM'))
        // diff month
        return dayjs(startDate).format('MMM DD') + ' - ' + dayjs(endDate).format('MMM DD, YYYY');

      if (dayjs(startDate).format('DD') !== dayjs(endDate).format('DD'))
        // diff day
        return dayjs(startDate).format('MMM DD') + ' - ' + dayjs(endDate).format('DD, YYYY');

      return dayjs(startDate).format('MMM DD, YYYY');
    }
  }

  return dayjs(startDate).format('MMM DD, YYYY');
};

export const sortByDate = <T>(
  data: T[],
  direction: SortDirection = SortDirection.Descending,
  sortByField = 'date',
): T[] => {
  if (!data?.length) return data;

  return data.sort((prev: any, next: any) => {
    const _next = new Date(next?.[sortByField]).getTime();
    const _prev = new Date(prev?.[sortByField]).getTime();
    const _condition = direction === SortDirection.Descending ? _prev > _next : _prev <= _next;
    return _condition ? -1 : 1;
  });
};

/**
 *
 * @param date - Date string
 * @returns Date string in format: `Thu, 01 Jan 1970 00:00:00 GMT`
 */
export const convertISOtoUTC = (date: string): string => {
  const utcDate = new Date(date);
  return utcDate.toUTCString();
};

/**
 *
 * @param date - Date string
 * @returns timezone string in format: `+00:00`
 * @example
 * extractTimezoneFromISOString('2021-01-01T00:00:00+00:00') // '+00:00'
 * extractTimezoneFromISOString('2021-01-01T00:00:00') // ''
 * extractTimezoneFromISOString('2021-01-01T00:00:00+03:00') // '+03:00'
 * extractTimezoneFromISOString('2021-01-01T00:00:00-03:00') // '-03:00'
 */
export const extractTimezoneFromISOString = (date: string) => {
  const rgxArr = date.match(/([+-])(\d{2}):(\d{2})$/);
  return rgxArr ? rgxArr[1] + rgxArr[2] + ':' + rgxArr[3] : '';
};

/**
 *
 * @param date - Date string
 * @returns Date string in format: `2021-01-01T00:00:00`
 * @example
 * extractDateFromISOString('2021-01-01T00:00:00+00:00') // '2021-01-01T00:00:00'
 * extractDateFromISOString('2021-01-01T00:00:00') // '2021-01-01T00:00:00'
 * extractDateFromISOString('2021-01-01T00:00:00+03:00') // '2021-01-01T00:00:00'
 * extractDateFromISOString('2021-01-01T00:00:00-03:00') // '2021-01-01T00:00:00'
 */
export const extractDateFromISOString = (date: string) => {
  const rgxArr = date.match(/([+-])(\d{2}):(\d{2})$/);
  return rgxArr ? date.replace(rgxArr[0], '') : date;
};

import dynamic from 'next/dynamic';
import { RichTextEntries } from './contentType.constants';

export const RTEmbeddedEntriesMapping: {
  [key in RichTextEntries]?: React.ComponentType<any>;
} = {
  [RichTextEntries.LeadGenBanner]: dynamic(() => import('../components/RichText/EmbeddedEntries/RTLeadGenBanner')),
  [RichTextEntries.Button]: dynamic(() => import('../components/RichText/EmbeddedEntries/RTButton')),
  [RichTextEntries.RegistrationWall]: dynamic(
    () => import('../components/RichText/EmbeddedEntries/RTRegistrationWall'),
  ),
  [RichTextEntries.People]: dynamic(() => import('../components/RichText/EmbeddedEntries/RTPeople')),
};

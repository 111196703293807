import { ContentTypeName, RECORDINGS_EVENT_SLUG, UNPACKING_SERIES_EVENT_SLUG } from '@/constants';
import { type IEvent, type IMarketAndTag, type ITag, SortDirection, type ThumbnailProps } from '@/interfaces';
import { removeDuplicateCTFObjects, sortByDate, sortDatesByClosest, sortFromCurrentDate } from '@/utils';
import { sortEventsByClosestAndUpcoming } from '../utils';
import { findEventCampaign, findInternalEvent, modifyRelatedCampaignEvents, modifyRelatedEvents } from './common.utils';

const EVENT_START_DATE = 'startDate';

type BaseEventArg = (args: {
  events: ThumbnailProps[];
  slug?: string;
  type?: string;
}) => ThumbnailProps[];

type GetEventByTagListArg = (args: {
  events: ThumbnailProps[];
  tags: ITag[]; // ThumbnailSection's tags
}) => ThumbnailProps[];

export const utils_getEventsByTag: BaseEventArg = ({ events, slug, type }) => {
  let filteredEvents = [...events];

  filteredEvents = filteredEvents.filter(
    (event) =>
      !!event?.tags?.find((tag) => {
        if (tag.__typename === ContentTypeName.Market) {
          if (!tag.slug) return true;
          return tag.slug === slug;
        }
        return tag.type === type && tag.slug === slug;
      }),
  );

  return filteredEvents.sort((prev: any, next: any) => {
    const _next = new Date(next?.[EVENT_START_DATE]).getTime();
    const _prev = new Date(prev?.[EVENT_START_DATE]).getTime();
    return _prev > _next ? -1 : 1;
  });
};

export const utils_getEventsByTagList: GetEventByTagListArg = ({ events, tags }) => {
  const allEventsByTag = tags?.map((tag) =>
    utils_getLatestEventThumbnails({
      events,
      slug: tag.slug,
      type: tag.type,
    }),
  );

  const data = allEventsByTag.flat(1);
  const sortedData = sortDatesByClosest(data);
  return removeDuplicateCTFObjects(sortedData);
};

export const utils_getLatestEventThumbnails: BaseEventArg = ({ events, slug, type }) => {
  const _events = utils_getEventsByTag({
    events,
    slug,
    type,
  });

  if (slug === RECORDINGS_EVENT_SLUG || slug === UNPACKING_SERIES_EVENT_SLUG) {
    return sortEventsByClosestAndUpcoming(_events);
  }

  const upcomingEvents = _events.filter((item) => item.startDate && new Date(item.startDate) >= new Date());
  return sortDatesByClosest(upcomingEvents);
};

export const utils_getEventThumbnailsByDate: BaseEventArg = ({ events, slug, type }) => {
  const _events = utils_getEventsByTag({
    events,
    slug,
    type,
  });
  return sortEventsByClosestAndUpcoming(_events);
};

type FilterEventBySlugArg = (args: {
  events: IEvent[];
  currentEventId?: string;
  limit?: number;
  areUpcomingEvents: boolean;
}) => IEvent[];

export const utils_filterCurrentEventId: FilterEventBySlugArg = ({
  events,
  currentEventId,
  limit,
  areUpcomingEvents = true,
}) => {
  const isNotCurrentEvent = (event: IEvent) => event?.sys?.id !== currentEventId;
  let filteredEvents = [];

  if (areUpcomingEvents) {
    const isUpcomingEvent = (event: IEvent) => {
      if (!event.startDate) return false;
      const eventStartDate = new Date(event.startDate);
      const currentDate = new Date();
      return eventStartDate.getTime() >= currentDate.getTime();
    };

    filteredEvents = events?.filter(
      (event: IEvent) => isUpcomingEvent(event) && isNotCurrentEvent(event) && event.featured,
    );
  } else {
    filteredEvents = events?.filter((event: IEvent) => isNotCurrentEvent(event) && event.featured);
  }

  const sortedList = sortFromCurrentDate(filteredEvents);
  return limit ? sortedList.slice(0, limit) : sortedList;
};

type GetHighlightEventsArg = (args: {
  events: IEvent[];
  currentEventId?: string;
  areUpcomingEvents?: boolean;
}) => IEvent[];

export const utils_getHighlightEvent: GetHighlightEventsArg = ({
  events,
  currentEventId,
  areUpcomingEvents = true,
}) => {
  return utils_filterCurrentEventId({
    events,
    currentEventId,
    limit: 5,
    areUpcomingEvents,
  });
};

type GetRelatedEventsArg = (args: {
  events: ThumbnailProps[];
  tagList: string[];
  currentEventSysId?: string;
}) => IEvent[];

export const utils_getRelatedEvents: GetRelatedEventsArg = ({ events, tagList, currentEventSysId }) => {
  const allEvents = events.filter((event: ThumbnailProps) => event.sys.id !== currentEventSysId);
  return modifyRelatedEvents(allEvents, tagList) as IEvent[];
};

type RelatedCampaignEventsObjArg = (args: {
  events: ThumbnailProps[];
  tagList: IMarketAndTag[];
  currentEventSysId: string;
  showCampaign?: boolean;
}) => {
  campaignTitle: string;
  relatedCampaignEvents: ThumbnailProps[];
} | null;

export const utils_relatedCampaignEventsObj: RelatedCampaignEventsObjArg = ({
  events,
  tagList,
  currentEventSysId,
  showCampaign,
}) => {
  const isInternalEvent = findInternalEvent(tagList);
  const eventCampaign = findEventCampaign(tagList);
  if (!isInternalEvent || !eventCampaign || !showCampaign) return null;
  const allEvents = utils_getEventsByTag({
    events,
    slug: eventCampaign.slug,
    type: 'events',
  });
  const sortedAllEvents = sortByDate(allEvents, undefined, 'startDate');

  const relatedCampaignEvents = modifyRelatedCampaignEvents(sortedAllEvents, currentEventSysId);
  return {
    campaignTitle: eventCampaign.title,
    relatedCampaignEvents,
  };
};

type GetHighlightEvents = (args: {
  events: IEvent[];
  numberOfEvents?: number;
}) => IEvent[] | null;

export const utils_getHighlightEvents: GetHighlightEvents = ({ events, numberOfEvents = 5 }) => {
  const currentDate = new Date();
  const filteredEvents = events.filter((event) => event.featured && new Date(event.startDate as string) >= currentDate);

  return sortByDate(filteredEvents, SortDirection.Ascending, EVENT_START_DATE)?.slice(0, numberOfEvents);
};

type GetFeaturedEventList = (args: {
  events: IEvent[];
  slug: string;
}) => IEvent[] | null;

export const utils_getFeaturedEventList: GetFeaturedEventList = ({ events, slug }) => {
  // Get all events which fall under the respective Full Page and Tag
  const eventsWithCurrentTag = utils_getEventsByTag({
    events,
    slug,
    type: 'events',
  });

  // Sort by closest to current date
  const sortedEventsByClosestDate =
    eventsWithCurrentTag &&
    (slug === RECORDINGS_EVENT_SLUG || slug === UNPACKING_SERIES_EVENT_SLUG
      ? sortDatesByClosest(eventsWithCurrentTag)
      : sortFromCurrentDate(eventsWithCurrentTag));

  // Filter featured events
  const featuredEvents =
    (sortedEventsByClosestDate?.filter((event) => !!event.featured).slice(0, 4) as IEvent[]) || null;

  return featuredEvents;
};

type GetEvent = (args: {
  events: IEvent[];
  slug: string;
}) => IEvent | null;

export const utils_getEvent: GetEvent = ({ events, slug }) => {
  return events.filter((event) => event.slug === slug)[0];
};

import styled from 'styled-components';
import { TextStyles } from '@/constants';
import { Typography } from '@/components';

export const DefaultText = styled(Typography).attrs({
  variant: TextStyles['Body Text Small'], // TODO-24-AFTER: check why body text large not work with tooltip
})`
  word-break: break-word;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
`;

export const TextContainer = styled.div<{
  limit: number;
}>`
  .has-tooltip {
    > * > * {
      -webkit-line-clamp: ${({ limit }) => limit};
    }
  }
`;

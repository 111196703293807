import styled from 'styled-components';

export const ColumnsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${({ theme }) => theme.spacing[24]};
  margin-top: ${({ theme }) => theme.spacing[24]};

  a {
    display: block;
    color: ${({ theme }) => theme.colors.highEmphasisWhite};

    :hover {
      color: ${({ theme }) => theme.colors.mediumEmphasisWhite};
    }
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    flex: 1;
  }

  ${({ theme }) => theme.mediaBreakpointUp.lg} {
    margin: 0;
  }
`;

const desktopColumnWidth = 160;

export const Column = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  margin-bottom: ${({ theme }) => theme.spacing[24]};

  :nth-child(odd) {
    padding-right: ${({ theme }) => theme.spacing[16]};
  }

  :nth-child(even) {
    padding-left: ${({ theme }) => theme.spacing[16]};
  }

  :nth-last-child(1),
  :nth-last-child(2) {
    margin-bottom: 0;
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin-right: ${({ theme }) => theme.spacing[32]};
    flex-basis: ${desktopColumnWidth}px;
    max-width: ${desktopColumnWidth}px;
    margin-bottom: 3rem;

    :nth-child(odd),
    :nth-child(even) {
      padding: 0;
    }

    :nth-child(4n) {
      margin-right: 0;
    }

    :nth-last-child(3),
    :nth-last-child(4) {
      margin-bottom: 0;
    }
  }
`;

export const ColumnTitle = styled.a`
  position: relative;
  margin-bottom: ${({ theme }) => theme.spacing[24]};

  ::before {
    content: '';
    position: absolute;
    left: 0;
    top: calc(100% + 6px);
    width: 24px;
    height: 2px;
    background: ${({ theme }) => theme.colors.darkOrange};
  }
`;

export const ColumnSubItemsWrapper = styled.div``;

export const ColumnSubItem = styled.a`
  display: block;

  :not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing[12]};
  }
`;

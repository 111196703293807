import Image from 'next/image';
import type React from 'react';
import { useState } from 'react';
import {
  LogInButton,
  ProfileDefaultImage,
  ProfileImage,
  ProfileName,
  ProfileWrapper,
  FirstLetter,
} from './Profile.styles';
import { ButtonSize } from '@/interfaces';
import { useGlobalState } from '@/hooks';
import { gtmClickHeaderSignups } from '@/utils';

const DefaultProfileImage = ({
  firstName,
  email,
}: {
  firstName?: string;
  email?: string;
}) => {
  if (!firstName && !email) return null;
  const letters = firstName || email;
  return (
    <ProfileDefaultImage>
      <FirstLetter>{letters?.slice(0, 1)}</FirstLetter>
    </ProfileDefaultImage>
  );
};

const UserAvatar = ({
  imageUrl,
  firstName,
  email,
}: {
  imageUrl?: string | null;
  firstName?: string;
  email?: string;
}) => {
  const [isImageBroken, setIsImageBroken] = useState(false);

  if (imageUrl && !isImageBroken) {
    return (
      <ProfileImage>
        <Image
          fill
          alt='avatar'
          sizes='100vw'
          style={{
            objectFit: 'cover',
          }}
          src={imageUrl}
          onError={() => setIsImageBroken(true)}
        />
      </ProfileImage>
    );
  }

  return <DefaultProfileImage firstName={firstName} email={email} />;
};

const Profile: React.FC = () => {
  const { isLoggedIn, userInfo, setIsFromJoinUsBtn, isErrorPage, isTrackingPage, isMyParcelsPage } = useGlobalState();

  if (isTrackingPage) return null;

  if (isLoggedIn) {
    return (
      <ProfileWrapper>
        <UserAvatar imageUrl={userInfo?.profilePictureUrl} firstName={userInfo?.firstName} email={userInfo?.email} />
        {!isMyParcelsPage && <ProfileName>{userInfo?.firstName}</ProfileName>}
      </ProfileWrapper>
    );
  }

  if (isErrorPage || isMyParcelsPage) return null;

  const handleJoinUsButtonClick = () => {
    setIsFromJoinUsBtn(true);
    gtmClickHeaderSignups();
  };

  return (
    <LogInButton size={ButtonSize.Medium} onClick={handleJoinUsButtonClick}>
      JOIN US
    </LogInButton>
  );
};

export default Profile;

import { useCallback, useEffect, useMemo, useState } from 'react';

import { FALLBACK_PMT_LANGUAGE_CODE } from '@/constants';

import type { LanguageCode } from '@/interfaces';

import { getPmtTranslations } from '@/fetchers';

import { getMasterTranslationLanguageCode } from '@/utils';

const delay = async (ms: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

const RETRY_TIME = 3;
const WAITING_TIME = 1000;

type UseTranslation = (languageCode: LanguageCode) => (key: string) => string;

const INITIAL_DEFAULT_TRANSLATIONS = {};
const INITIAL_TRANSLATIONS = {};

const useTranslations: UseTranslation = (languageCode) => {
  const [defaultTranslations, setDefaultTranslations] = useState<Record<string, string>>(INITIAL_DEFAULT_TRANSLATIONS);
  const [translations, setTranslations] = useState<Record<string, string>>(INITIAL_TRANSLATIONS);

  const isLanguageTheSameAsFallback = useMemo(() => languageCode === FALLBACK_PMT_LANGUAGE_CODE, [languageCode]);

  const t = useCallback(
    (key: string) => {
      return translations[key] || defaultTranslations[key] || key;
    },
    [defaultTranslations, translations],
  );

  const getPmtTranslationsData = useCallback(
    async (lang: LanguageCode, retry = RETRY_TIME): Promise<Record<string, string>> => {
      try {
        const translationsData = await getPmtTranslations(lang);
        return translationsData || {};
      } catch (error) {
        if (retry > 0) {
          await delay(WAITING_TIME);
          return getPmtTranslationsData(lang, retry - 1);
        }

        console.error(`getPmtTranslationsData failed, reached retry limitation: ${RETRY_TIME}`, error);
        return {};
      }
    },
    [],
  );

  useEffect(() => {
    (async () => {
      try {
        const modifiedLanguageCode = getMasterTranslationLanguageCode(languageCode);
        const translationsData = await getPmtTranslationsData(modifiedLanguageCode);
        setTranslations(translationsData);

        if (Object.keys(defaultTranslations).length === 0) {
          if (isLanguageTheSameAsFallback) {
            setDefaultTranslations(translationsData);
          } else {
            setDefaultTranslations(await getPmtTranslationsData(FALLBACK_PMT_LANGUAGE_CODE));
          }
        }
      } catch (error) {
        console.error('getPmtTranslationsData error', error);
      }
    })();
  }, [languageCode, getPmtTranslationsData]);

  return t;
};
export default useTranslations;

import styled from 'styled-components';
import { LeftSection, SectionListWrapper } from '../SectionListRenderer.styles';
import SectionWrapper from '@/components/SectionWrapper/SectionWrapper.styles';

export const CustomSectionListWrapper = styled(SectionListWrapper)<{
  hideSectionBorderTop: boolean;
}>(
  ({ theme, hideSectionBorderTop }) =>
    hideSectionBorderTop && {
      [`${theme.mediaBreakpointDown.sm}`]: {
        [`${LeftSection} ${SectionWrapper}:first-child`]: {
          paddingTop: 0,
          borderTop: 'none',
        },
      },
    },
);

import { useEffect, useMemo, useState } from 'react';

type ICalculateProps = (ids?: string[], dependencyArray?: any[]) => number;

const useCalculateHeight: ICalculateProps = (ids, dependencyArray = []) => {
  const [height, setHeight] = useState<number>(0);

  const selector = useMemo(() => {
    let selector = '';

    if (ids?.length) {
      ids?.forEach((id: string, i: number) => {
        selector = i === 0 ? selector.concat(`#${id}`) : selector.concat(`, #${id}`);
      });
    }
    return selector;
  }, [...dependencyArray]);

  useEffect(() => {
    const handleScroll = () => {
      const elements = document.querySelectorAll(selector);
      let totalHeight = 0;
      if (elements?.length) {
        elements.forEach((el) => {
          const { bottom, top } = el.getBoundingClientRect();
          const elHeight = bottom - top;
          totalHeight += elHeight;
        });
      }
      if (height !== totalHeight) setHeight(totalHeight);
    };

    handleScroll();

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [...dependencyArray]);

  return height;
};

export default useCalculateHeight;

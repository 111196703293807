import { RouteName } from '@/constants';
import { type IPmInsightsRetailer, type IDataSection, type IRetailer, type IChart, PmAnalyticsQueryTable } from '@/interfaces';
import { getMarketSlugFromMarketName, getNameFromHeadquarters, replaceHighlightString, replacePath } from '@/utils';
import { fetchCompetitors } from '../pmInsightsAPI/retailer.fetchers';

type ReplaceOuterPlaceholders = (args: {
  pageData: Record<string, any>;
  retailer: IPmInsightsRetailer;
}) => Partial<IRetailer['seo']>;

export const replaceSeoPlaceholders: ReplaceOuterPlaceholders = ({ pageData, retailer }) => {
  const RETAILER_NAME = 'retailer';
  const RETAILER_SLUG = 'retailer_slug';

  const { slug, name } = retailer;

  return {
    title: replaceHighlightString(pageData.seo.title, RETAILER_NAME, name),
    description: replaceHighlightString(pageData.seo.description, RETAILER_NAME, name),
    ogTitle: replaceHighlightString(pageData.seo.ogTitle, RETAILER_NAME, name),
    ogDescription: replaceHighlightString(pageData.seo.ogDescription, RETAILER_NAME, name),
    ogUrl: replaceHighlightString(pageData.seo.ogUrl, RETAILER_SLUG, slug ?? ''),
  };
};

export const replaceDataSectionPlaceholders = (dataSection: IDataSection, retailer: IPmInsightsRetailer) => {
  const RETAILER_NAME = 'retailer';
  const HEADQUARTERS_NAME = 'headquarters';

  const { name, headquarters } = retailer;
  const headquartersName = getNameFromHeadquarters(headquarters || '');

  const replacedWithRetailerName = replaceHighlightString(dataSection.title || '', RETAILER_NAME, name);
  const replacedWithHeadquartersName = replaceHighlightString(
    replacedWithRetailerName,
    HEADQUARTERS_NAME,
    headquartersName,
  );

  return replacedWithHeadquartersName;
};

type GetCompetitors = (args: {
  slug: string;
  accessToken: string;
  dataSection: IDataSection;
}) => Promise<IPmInsightsRetailer[]>;

export const getCompetitors: GetCompetitors = async ({ slug, accessToken, dataSection }) => {
  const chartList = (dataSection.chartList as any)?.items;
  const hasCompetitorList = dataSection?.retailerCompetitorListTitle;
  const hasCompetitorTable = chartList?.some(
    (chart: IChart) => chart.query.table === PmAnalyticsQueryTable.RetailerCompetitor,
  );

  if (!hasCompetitorList && !hasCompetitorTable) return [];

  const data = await fetchCompetitors({ slug, accessToken });
  if (!data) return [];

  return data;
};

type GetRetailerHeadquartersLink = (args: {
  headquarters?: string;
  sectionLabel?: string;
}) => string | null;

/**
 * Get the link of the retailer's headquarters
 * @param dataSection
 * @param retailer
 * @returns Link of the current retailer's headquarters, e.g. /markets/vietnam
 */
export const getRetailerHeadquartersLink: GetRetailerHeadquartersLink = ({ headquarters, sectionLabel }) => {
  if (!sectionLabel || !headquarters) return null;

  const slug = getMarketSlugFromMarketName(headquarters, true);
  const href = replacePath(RouteName.Market, [slug]);

  return href;
};

import Link from 'next/link';
import { RouteName } from '@/constants';
import type { IIndividualCarrier } from '@/interfaces';
import { getMarketSlugFromMarketName, replacePath } from '@/utils';
import { SectionTitle, SectionWrapper } from '@/components';
import { DetailsItem, DetailsValue, DetailsHeader, DetailsContainer, DetailsLinkValue } from './OverviewSection.styles';

type IIndividualCarrierKeys = keyof Partial<IIndividualCarrier>;

type TitleMappingProps = {
  [key in IIndividualCarrierKeys]?: string;
};

const OverviewSection: React.FC<Partial<IIndividualCarrier>> = (props) => {
  const { overviewTitle, carrierDisplayName } = props;

  if (!overviewTitle) return null;

  const titleMapping: TitleMappingProps = {
    headquarters: 'Headquarters',
    foundedDate: 'Founded',
    industry: 'Industry',
    employees: 'Company Size',
    website: 'Website',
    linkedin: 'LinkedIn',
  };

  const valuesLength = Object.keys(titleMapping).filter((key) => props[key as IIndividualCarrierKeys]).length;
  if (!valuesLength) return null;

  const renderLink = (propKey: IIndividualCarrierKeys) => {
    if (!props[propKey]) return null;

    return (
      <Link passHref href={props[propKey] as string} legacyBehavior>
        <DetailsLinkValue target='_blank'>
          {carrierDisplayName} {titleMapping[propKey]}
        </DetailsLinkValue>
      </Link>
    );
  };

  const renderEmployees = () => {
    if (!props.employees) return null;
    const employees = props.employees.split(' ')[0];

    return <DetailsValue>{employees}</DetailsValue>;
  };

  const renderValue = (key: string) => {
    const value = props[key as IIndividualCarrierKeys];

    switch (key) {
      case 'employees':
        return renderEmployees();
      case 'website':
      case 'linkedin':
        return renderLink(key);
      case 'headquarters': {
        const slug = getMarketSlugFromMarketName(value as string, true);
        const href = replacePath(RouteName.Market, [slug]);

        return (
          <Link passHref href={href} legacyBehavior>
            <DetailsLinkValue>{value}</DetailsLinkValue>
          </Link>
        );
      }
      default:
        return <DetailsValue>{value}</DetailsValue>;
    }
  };

  return (
    <SectionWrapper>
      <SectionTitle>{overviewTitle}</SectionTitle>
      <DetailsContainer length={Math.ceil(valuesLength / 2)}>
        {Object.keys(titleMapping).map((key, idx) => {
          const title = titleMapping[key as IIndividualCarrierKeys];
          const value = props[key as IIndividualCarrierKeys];

          if (!value) return null;

          return (
            <DetailsItem key={idx}>
              <DetailsHeader>{title}</DetailsHeader>
              {renderValue(key)}
            </DetailsItem>
          );
        })}
      </DetailsContainer>
    </SectionWrapper>
  );
};

export default OverviewSection;

import { useEffect, useRef, useState } from 'react';
import { TextStyles } from '@/constants';
import ViewMoreButton from '@/components/Button/ViewMoreButton';
import { SubTitle } from './Description.styles';

interface DescriptionProps {
  description: string;
  lineClamp: {
    desktop: number;
    mobile: number;
  };
  variant?: TextStyles;
}
const Description: React.FC<DescriptionProps> = ({
  description,
  lineClamp,
  variant = TextStyles['Body Text Large'],
}) => {
  const descriptionRef = useRef<HTMLParagraphElement>(null);
  const [hasOverflow, setHasOverflow] = useState(false);
  const [unlimitedLines, setUnlimitedLines] = useState(false);

  useEffect(() => {
    const lineOverflow =
      !!descriptionRef.current && descriptionRef.current.scrollHeight > descriptionRef.current.clientHeight;
    setHasOverflow(lineOverflow);
  }, []);

  return (
    <>
      <SubTitle ref={descriptionRef} $unlimitedLines={unlimitedLines} $lineClamp={lineClamp} variant={variant}>
        {description}
      </SubTitle>

      {hasOverflow && <ViewMoreButton collapsed={!unlimitedLines} onClick={() => setUnlimitedLines((prev) => !prev)} />}
    </>
  );
};

export default Description;

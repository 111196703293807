import type { IOrderTracking } from '../interfaces';

export const mapOrderTracking = (orderTracking: any): IOrderTracking | undefined => {
  if (!orderTracking) return undefined;

  return {
    orderId: orderTracking.order_id,
    orderSourceName: orderTracking.order_source_name,
  };
};

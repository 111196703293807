import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

import { type IOnboardingPopup, type IPanel, OnboardingStepName, ResponsiveCollection } from '@/interfaces';

import LeftPanel from './LeftPanel';
import RightPanel, { AboutYou, FollowUs, JoinUs, AccountVerification, ThankYou } from './RightPanel';
import { OnboardingPopupContainer, OnboardingPopupOverlay, OnboardingPopupWrapper } from './OnboardingPopup.styles';

import { useGlobalState, useResponsive } from '@/hooks';
import useOnboardingPopUp from './useOnboardingPopUp.hook';
import useZapierTrigger from './useZapierTrigger.hooks';

import {
  gtmViewLinkedIn,
  gtmViewJoinUsPmc,
  gtmViewBasicDetails,
  gtmClickJoinUsPmc,
  gtmSignUpSuccessPmc,
  replaceHighlightString,
} from '@/utils';

import { CookieKey, PopupType } from '@/constants';

const OnboardingPopup: React.FC<IOnboardingPopup & { differentBrowser?: boolean }> = (props) => {
  const {
    joinUsHeader,
    joinUsValuePropositionList,
    joinUsTitle,
    joinUsMobileTitle,
    joinUsSubtext,
    joinUsMobileSubtext,
    followUsHeader,
    followUsValuePropositionList,
    followUsTitle,
    followUsMobileTitle,
    followUsSubtext,
    followUsMobileSubtext,
    followUsImage,
    aboutYouHeader,
    aboutYouValuePropositionList,
    aboutYouTitle,
    aboutYouSubtext,
    aboutYouForm,
    thankYouHeader,
    thankYouValuePropositionList,
    thankYouTitle,
    thankYouSubtext,
    thankYouImage,
    variant,
    zapierHookId,
    differentBrowser,
    progressIndicator,
  } = props;

  const {
    userInfo,
    currentStep,
    setCurrentStep,
    isFollowingLinkedIn,
    isNextButtonClicked,
    isLoggedIn,
    isFromJoinUsBtn,
  } = useGlobalState();
  const { showPopUp, closePopUp, isFullScreen, removePopUpTimer, delayTime } = useOnboardingPopUp({ popUp: props });
  const { isDesktop } = useResponsive([ResponsiveCollection.Desktop]);
  const [loginStateBeforePopupIsShown, setLoginStateBeforePopupIsShown] = useState(isLoggedIn);

  useEffect(() => {
    document.body.setAttribute('style', `touch-action: ${showPopUp ? 'none' : 'auto'};`);
  }, [showPopUp]);

  useEffect(() => {
    const isUnverified = !isLoggedIn && Cookies.get(CookieKey.BrowserUuid);
    if (differentBrowser || isUnverified) removePopUpTimer();
    return () => {
      document.body.setAttribute('style', 'overflow: unset;');
    };
  }, []);

  // Push event based on current displaying step
  useEffect(() => {
    if (!showPopUp) return;

    switch (currentStep) {
      case OnboardingStepName.joinUs: {
        gtmViewJoinUsPmc({
          timing: isFromJoinUsBtn ? undefined : delayTime,
          variant,
        });
        break;
      }
      case OnboardingStepName.followUs: {
        gtmViewLinkedIn();
        break;
      }
      case OnboardingStepName.aboutYou: {
        gtmViewBasicDetails();
        break;
      }
      default:
        break;
    }
  }, [showPopUp, currentStep]);

  useEffect(() => {
    if (isDesktop && currentStep === OnboardingStepName.intro) {
      setCurrentStep(OnboardingStepName.joinUs);
    }
  }, [isDesktop]);

  useEffect(() => {
    if (!showPopUp) return;

    setLoginStateBeforePopupIsShown(isLoggedIn);
  }, [showPopUp]);

  useEffect(() => {
    if (currentStep === OnboardingStepName.finish) {
      document.body.setAttribute('style', 'overflow: unset;');
      location.reload();
    }
  }, [currentStep]);

  // get title based on viewport size
  const getResponsiveTitle = (desktopTitle: string | undefined, mobileTitle: string | undefined) => {
    return isDesktop ? desktopTitle : mobileTitle;
  };

  const handleClosePopupAndReload = () => {
    closePopUp();

    const shouldRefreshPage = isLoggedIn && !loginStateBeforePopupIsShown;

    if (shouldRefreshPage) {
      location.reload();
    }
  };

  const goToNextStep = (): void => {
    const isLastStep = currentStep === OnboardingStepName.finish;

    if (isLastStep) {
      return;
    }

    const allStepNames = Object.values(OnboardingStepName);
    const currentStepIndex = allStepNames.indexOf(currentStep);

    setCurrentStep(allStepNames[currentStepIndex + 1]);
  };

  const onStepSuccessMapping: {
    [key in OnboardingStepName]?: (props?: Record<string, any>) => void;
  } = {
    [OnboardingStepName.joinUs]: (props) => {
      const { authenticationMethod } = props || {};

      if (!authenticationMethod) return;

      gtmSignUpSuccessPmc({
        authenticationMethod,
        popupType: isFromJoinUsBtn ? PopupType.actionBased : PopupType.timeBased,
      });
    },
  };

  const onStepClickMapping: {
    [key in OnboardingStepName]?: (props?: Record<string, any>) => void;
  } = {
    [OnboardingStepName.joinUs]: (props) => {
      const { authenticationMethod } = props || {};

      if (!authenticationMethod) return;

      gtmClickJoinUsPmc({
        timing: isFromJoinUsBtn ? undefined : delayTime,
        authenticationMethod,
        popupType: isFromJoinUsBtn ? PopupType.actionBased : PopupType.timeBased,
        variant,
      });
    },
  };

  const onStepSuccess = onStepSuccessMapping[currentStep];
  const onStepClick = onStepClickMapping[currentStep];

  const PanelMapping: {
    [key in OnboardingStepName]?: IPanel;
  } = {
    [OnboardingStepName.intro]: {
      header: joinUsHeader,
      valuePropositionList: joinUsValuePropositionList,
      title: getResponsiveTitle(joinUsTitle, joinUsMobileTitle),
      subtext: getResponsiveTitle(joinUsSubtext, joinUsMobileSubtext),
      Component: JoinUs,
    },
    [OnboardingStepName.joinUs]: {
      header: joinUsHeader,
      valuePropositionList: joinUsValuePropositionList,
      title: getResponsiveTitle(joinUsTitle, joinUsMobileTitle),
      subtext: getResponsiveTitle(joinUsSubtext, joinUsMobileSubtext),
      Component: JoinUs,
      progressIndicator,
    },
    [OnboardingStepName.followUs]: {
      header: followUsHeader,
      valuePropositionList: followUsValuePropositionList,
      title: getResponsiveTitle(followUsTitle, followUsMobileTitle),
      subtext: getResponsiveTitle(followUsSubtext, followUsMobileSubtext),
      image: followUsImage,
      Component: FollowUs,
    },
    [OnboardingStepName.aboutYou]: {
      header: aboutYouHeader,
      valuePropositionList: aboutYouValuePropositionList,
      title: aboutYouTitle,
      subtext: aboutYouSubtext,
      form: aboutYouForm,
      Component: AboutYou,
    },
    [OnboardingStepName.accountVerification]: {
      header: joinUsHeader,
      valuePropositionList: joinUsValuePropositionList,
      title: 'Verify your account',
      subtext: 'Please check your email inbox to complete registration:',
      Component: AccountVerification,
    },
    [OnboardingStepName.thankYou]: {
      header: thankYouHeader,
      valuePropositionList: thankYouValuePropositionList,
      title: replaceHighlightString(thankYouTitle || '', 'username', userInfo?.firstName || ''),
      subtext: thankYouSubtext,
      image: thankYouImage,
      Component: ThankYou,
    },
  };

  const currentStepData = PanelMapping[currentStep];

  useZapierTrigger({
    hookId: zapierHookId,
    userInfo,
    isFollowingLinkedIn,
    showPopUp,
    isNextButtonClicked,
  });

  const handleStepSuccess = (props?: Record<string, any>) => {
    goToNextStep();
    onStepSuccess && onStepSuccess(props);
  };

  const handleStepClick = (props?: Record<string, any>) => {
    onStepClick && onStepClick(props);
  };

  if (!showPopUp || currentStep === OnboardingStepName.finish) return null;

  const renderPanels = () => {
    if (isDesktop) {
      return (
        <>
          <LeftPanel {...currentStepData} />
          <RightPanel
            {...currentStepData}
            closePopUp={closePopUp}
            onStepSuccess={handleStepSuccess}
            onStepClick={handleStepClick}
          />
        </>
      );
    }

    // mobile
    if (currentStep === OnboardingStepName.intro) {
      return <LeftPanel isFullScreen={isFullScreen} {...currentStepData} />;
    } else {
      return (
        <RightPanel
          {...currentStepData}
          isFullScreen={isFullScreen}
          closePopUp={handleClosePopupAndReload}
          onStepSuccess={handleStepSuccess}
          onStepClick={handleStepClick}
        />
      );
    }
  };

  const noTopPosition = currentStep === OnboardingStepName.intro;

  return (
    <OnboardingPopupContainer showPopUp={showPopUp}>
      <OnboardingPopupOverlay onClick={handleClosePopupAndReload} />
      <OnboardingPopupWrapper isFullScreen={isFullScreen} noTopPosition={noTopPosition}>
        {renderPanels()}
      </OnboardingPopupWrapper>
    </OnboardingPopupContainer>
  );
};

export default OnboardingPopup;

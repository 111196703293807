import styled from 'styled-components';
import Language from '../../../../public/images/icons/language.svg';
import ArrowDown from '../../../../public/images/icons/arrow_down.svg';
import { Typography } from '@/components';
import { TextStyles } from '@/constants';
import { styledText } from '@/components/Typography/Typography.styles';

export const LanguageIcon = styled(Language)`
  width: 20px;
  height: 20px;
  padding-top: 2px;
  padding-left: 2px;
  path {
    fill: ${({ theme }) => theme.colors.highEmphasisBlack};
  }
  
  ${({ theme }) => theme.mediaBreakpointUp.lg} {
    path {
      fill: ${({ theme }) => theme.colors.highEmphasisWhite};
    }
  }
`;

export const ArrowIcon = styled(ArrowDown).withConfig({
  shouldForwardProp: (props) => props !== 'show',
})`
  width: 20px;
  height: 20px;
  transition: 0.4s;

  ${({ show }) =>
    show &&
    `
    transform: rotate(180deg);
    transition: .3s;
  `}

  path {
    fill: ${({ theme }) => theme.colors.highEmphasisBlack};
  }

  ${({ theme }) => theme.mediaBreakpointUp.lg} {
    margin-left: ${({ theme }) => theme.spacing[8]};

    path {
      fill: ${({ theme }) => theme.colors.highEmphasisWhite};
    }
  }
`;

export const LanguageItem = styled(Typography).attrs({
  variant: TextStyles['Body Text Small'],
})`
  width: 100%;
  height: 22px;
  color: ${({ theme }) => theme.colors.highEmphasisBlack};
  padding: 0 ${({ theme }) => theme.spacing[28]};
  line-height: ${({ theme }) => theme.lineHeight[22]};

  ${({ theme }) => theme.mediaBreakpointUp.lg} {
    color: ${({ theme }) => theme.colors.highEmphasisWhite};
    padding: 0 ${({ theme }) => theme.spacing[20]};

    :hover {
      color: ${({ theme }) => theme.colors.mediumEmphasisWhite};
    }
  }
`;

export const CurrentLanguageItem = styled(Typography).attrs({
  variant: TextStyles['Nav Item Text'],
})`
  color: ${({ theme }) => theme.colors.highEmphasisBlack};
  margin-left: ${({ theme }) => theme.spacing[8]};
  width: calc(100% - 50px);
  letter-spacing: 0.005em;

  ${({ theme }) => theme.mediaBreakpointUp.lg} {
    width: unset;
    color: ${({ theme }) => theme.colors.highEmphasisWhite};
    ${styledText['Body Text Small']}
  }
`;

export const LanguageList = styled.div<{ show: boolean }>`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  flex-direction: column;
  padding: ${({ theme }) => `${theme.spacing[16]} 0 0 0`};
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 0px 0px 8px 8px;
  width: calc(100vw - 48px);

  & >:not(:first-child) {
    margin-top: ${({ theme }) => theme.spacing[8]};
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.mediumEmphasisWhite};
    border-radius: 8px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 8px;
  }

  ${({ theme }) => theme.mediaBreakpointUp.lg} {
    width: 196px;
    max-height: 368px;
    padding: ${({ theme }) => theme.spacing[20]} 0;
    background: ${({ theme }) => theme.colors.darkBlue};
    position: absolute;
    top: 100%;
    left: 0;

    & >:not(:first-child) {
      margin-top: ${({ theme }) => theme.spacing[12]};
    }
  }
`;

export const LanguageWrapper = styled.div`
  display: block;
  position: relative;
  margin-right: auto;
  padding-bottom: 4px;
  cursor: default;

  ${({ theme }) => theme.mediaBreakpointUp.lg} {
    display: flex;
    align-items: center;
    height: 100%;
    margin-bottom: 0;
    margin-left: ${({ theme }) => theme.spacing[32]};
    padding-left: ${({ theme }) => theme.spacing[12]};
    padding-right: 17px;

    ${CurrentLanguageItem},
    ${LanguageIcon} {
      transition: 0.1s;
    }

    ${ArrowIcon} {
      transition: 0.3s;
    }

    &:hover {
      ${LanguageList} {
        display: flex;
      }

      ${CurrentLanguageItem} {
        color: ${({ theme }) => theme.colors.mediumEmphasisWhite};
      }

      ${LanguageIcon},
      ${ArrowIcon} {
        path {
          fill: ${({ theme }) => theme.colors.mediumEmphasisWhite};
        }
      }
  
      ${ArrowIcon} {
        transform: rotate(180deg);
      }
    }
  }
`;

export const CurrentLanguageWrapper = styled.div`
  display: flex;
`;

import styled, { css } from 'styled-components';
import { theme, TextStyles, ORDER_KEY } from '@/constants';
import { type ITableTheme, UniqueTableSectionField } from '@/interfaces';
import { Typography } from '@/components';
import { styledText } from '@/components/Typography/Typography.styles';
import { CountryFlag } from '@/components/Chart/Chart.styles';
import { IconWrapper } from '@/components/Tooltip/Tooltip.styles';
import { PaginationNumberBox } from './Pagination/Pagination.styles';
import { PaywallBackgroundContainer } from '@/components/RegistrationWall/RegistrationWall.styles';

const TD_MIN_WIDTH = 180;

const customScrollbar = css`
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    border-radius: 4px;
    background: ${({ theme }) => theme.colors.pageBackground};
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.surface05};
    border-radius: 4px;
  }
`;

const commonThAndTdStyles = `
  word-break: break-word;
  display: flex !important;
  align-items: center;
  flex: 1;
`;

export const TableChild = styled.div<{
  hasPagination: boolean;
}>`
  position: absolute;
  height: fit-content;
  z-index: 0;
  display: flex;
  align-items: center;
  width: calc(100% - 2px); // for border 1px
  bottom: 1px; // for border 1px
  left: 1px; // for border 1px
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
  background: ${({ hasPagination }) => (!hasPagination ? theme.colors.backgroundOpacity : theme.colors.focusedBlack)};

  ${({ hasPagination }) =>
    !hasPagination &&
    `
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  `}

  ${({ theme, hasPagination }) =>
    hasPagination &&
    `
    padding-top: ${theme.spacing[64]};

    ${PaywallBackgroundContainer} {
      height: 100%;
    }

    > div {
      align-items: baseline;
    }
  `}
`;

export const Table = styled.div<{ hasPagination?: boolean }>`
  width: 100%;
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  border-radius: 4px;
  z-index: 0;
  border: 1px solid ${({ theme }) => theme.colors.pressedBlack};

  ::-webkit-scrollbar {
    display: none;
  }

  ${({ hasPagination }) =>
    hasPagination &&
    `
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  `}

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    ${customScrollbar}
    ::-webkit-scrollbar {
      display: initial;
    }

    &.scrollable ~ ${TableChild} {
      bottom: 7px; // for border 1px and for scroll bar 6px
    }
  }
`;

export const TH = styled.div<{
  accessor?: string;
  tableTheme: ITableTheme;
  columnWidth?: string | number;
}>`
  ${commonThAndTdStyles}
  ${styledText['Heading 3']}
  border-bottom: 1px solid ${({ theme }) => theme.colors.hoverBlack};
  min-width: ${TD_MIN_WIDTH}px !important;
  background: ${({ tableTheme }) => tableTheme.colors.headerCellBackground};
  padding: ${({ theme }) => `${theme.spacing[12]} ${theme.spacing[16]}`};

  & {
    color: ${({ tableTheme }) => tableTheme.colors.headerCellText};
  }

  & ${IconWrapper} svg path {
    fill: ${({ tableTheme }) => tableTheme.colors.headerCellText};
  }

  ${({ accessor }) => accessor && accessor === ORDER_KEY && 'justify-content: end;'}

  ${({ columnWidth }) =>
    columnWidth &&
    columnWidth !== '100%' &&
    `
    flex: 0 0 ${columnWidth};
    min-width: ${columnWidth} !important;
  `}
`;

export const TD = styled.div<{
  accessor?: string;
  columnWidth?: string | number;
}>`
  ${commonThAndTdStyles}
  ${styledText['Body Text Small']}
  border-bottom: 1px solid ${theme.colors.pressedBlack};
  min-width: ${TD_MIN_WIDTH}px !important;
  overflow: hidden;
  padding: ${({ theme }) => `${theme.spacing[8]} ${theme.spacing[16]}`};
  background: ${({ theme }) => theme.colors.highEmphasisWhite};
  transition: 0.3s;

  ${({ accessor }) => accessor && accessor === ORDER_KEY && 'justify-content: end;'}

  ${({ columnWidth }) =>
    columnWidth &&
    columnWidth !== '100%' &&
    `
    flex: 0 0 ${columnWidth};
    min-width: ${columnWidth} !important;
  `}
`;

export const TR = styled.div<{
  tableTheme?: ITableTheme;
  hasNoActualData?: boolean;
}>`
  ${({ hasNoActualData }) => hasNoActualData && 'display: none !important;'}
  width: auto !important;

  :last-child,
  :last-child > ${TD} {
    border-bottom: none;
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    ${({ tableTheme }) =>
      tableTheme &&
      `
        &:hover > ${TD} {
          background: ${tableTheme.colors.rowHoverBackground};
        }
      `}
  }
`;

export const TableFooter = styled(Typography).attrs({
  variant: TextStyles['Body Text Small'],
})`
  margin-top: ${({ theme }) => theme.spacing[8]};
  color: ${({ theme }) => theme.colors.disabledBlack};
`;

export const TableClonedRowsContainer = styled.div`
  position: relative;
  .table-cell-skeleton-wrapper {
    display: flex;
  }

  ${TD}, ${TD} * {
    color: transparent;
    text-shadow: 0 0 8px ${({ theme }) => theme.colors.highEmphasisBlack};
  }
`;

const SkeletonBoxStyles = `
  background: ${theme.colors.surface04};
  border-radius: 2px;
`;

const FLAG_HEIGHT = 22; // line-height of Body Text Small
const FLAG_WIDTH = 30;
export const CellSkeleton = styled.div<{ containsLogo: boolean }>`
  position: relative;

  ${({ theme, containsLogo }) =>
    containsLogo &&
    `
    ${SkeletonBoxStyles}
    position: absolute;
    left: 0;
    width: 48px;
    height: 48px;
    top: ${theme.spacing[8]};
  `}
`;

export const CellSkeletonContainer = styled.div<{ columnId?: string }>`
  position: relative;
  display: flex;

  ${({ columnId }) =>
    columnId === UniqueTableSectionField.order &&
    `
    margin-left: auto;
  `}

  ${CountryFlag} {
    position: relative;
    font-size: 0 !important;
    margin-right: 38px !important;

    :before {
      content: '---';
      position: absolute;
      overflow: hidden;
      font-size: ${({ theme }) => theme.fontSizes[16]};
      left: 0;
      top: 0;
      width: ${FLAG_WIDTH}px;
      height: ${FLAG_HEIGHT}px;
    }
  }
`;

export const TableAndChildWrapper = styled.div`
  position: relative;
`;

const renderDisabledPaginationNumberStyle = (pageCount: number, arrowIndex: number, pageCountDiff: number) => {
  return new Array(pageCountDiff).fill(0).map((_, idx) => {
    const pageToDisable = pageCount / (arrowIndex + 1) - idx + 1;
    return css`
        &:nth-child(${pageToDisable}) {
          cursor: not-allowed;
          opacity: 0.4;
          background: ${({ theme }) => theme.colors.surface03};
        }
      `;
  });
};

// padding: _ 8px is to make small mobile looks good
export const TablePaginationWrapper = styled.div<{
  pageCount: number;
  arrowIndex: number;
  pageCountDiff: number;
}>`
  padding: ${({ theme }) => `${theme.spacing[12]} ${theme.spacing[8]}`};
  background: ${({ theme }) => theme.colors.highEmphasisWhite};
  border: 1px solid ${({ theme }) => theme.colors.pressedBlack};
  border-top: none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  display: flex;
  justify-content: center;

  ${PaginationNumberBox} {
    ${({ pageCount, arrowIndex, pageCountDiff }) =>
      pageCountDiff > 0 && renderDisabledPaginationNumberStyle(pageCount, arrowIndex, pageCountDiff)}
  }
`;

import type React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import Profile from './Profile';
import { type IHeader, ResponsiveCollection } from '@/interfaces';
import { Container } from '@/components';
import { useGlobalState, useResponsive } from '@/hooks';
import { RouteName, ROUTES } from '@/constants';
import { getPmtLangHref, gtmClickPmc } from '@/utils';
import DropdownNavigation from './DropdownNavigation';
import CollapseNavigation from './CollapseNavigation';
import Languages from './Languages';
import {
  HeaderContent,
  HeaderWrapper,
  Logo,
  ParcelMonitorLogo,
  ExploreCommunityLink,
  ExploreCommunityButton,
} from './Header.styles';

const Header: React.FC<IHeader & { onlyLogo?: boolean }> = (props) => {
  const { itemList, button, onlyLogo } = props;

  const { isDesktopPMT, isTablet } = useResponsive([ResponsiveCollection.DesktopPMT, ResponsiveCollection.Tablet]);

  const { pmtCurrentLanguageCode, isMyParcelsPage } = useGlobalState();

  const DEFAULT_BUTTON_TEXT = 'EXPLORE THE COMMUNITY';

  const TrackingHeader = (
    <>
      <a href={getPmtLangHref(pmtCurrentLanguageCode)}>
        <ParcelMonitorLogo />
      </a>
      {isDesktopPMT ? (
        <>
          <Languages />
          <Profile />
          <ExploreCommunityLink href='/' target='_blank' onClick={() => gtmClickPmc('/')}>
            <ExploreCommunityButton truncate isPMTButton>
              {button || DEFAULT_BUTTON_TEXT}
            </ExploreCommunityButton>
          </ExploreCommunityLink>
        </>
      ) : (
        <>
          <Profile />
          <CollapseNavigation {...props} />
        </>
      )}
    </>
  );

  const CommunityHeader = (
    <>
      <Link href={ROUTES[RouteName.Home].path} passHref>
        <Logo>
          <Image src='/images/icons/monitor_logo.svg' alt='Parcel Monitor Logo' fill sizes='100vw' />
        </Logo>
      </Link>
      {!onlyLogo && (
        <>
          {!isTablet && <DropdownNavigation itemList={itemList} />}
          <Profile />
          {isTablet && <CollapseNavigation itemList={itemList} />}
        </>
      )}
    </>
  );

  return (
    <HeaderWrapper id='header'>
      <Container>
        <HeaderContent>{isMyParcelsPage ? TrackingHeader : CommunityHeader}</HeaderContent>
      </Container>
    </HeaderWrapper>
  );
};

export default Header;

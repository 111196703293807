import { useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { PopupContainer, PopupOverlay } from './Popup.styles';

const getPopupRoot = (mountNodeId: string): HTMLDivElement => {
  const element = document.createElement('div');
  element.setAttribute('id', mountNodeId);
  const __next = document.getElementById('__next');
  __next?.prepend(element);
  return element;
};

const PopupPortal: React.FC = ({ children }) => {
  const [mountNode, setMountNode] = useState<HTMLDivElement>();

  useLayoutEffect(() => {
    const mountNodeId = 'popup-root';
    let popupRoot = document.getElementById(mountNodeId) as HTMLDivElement;
    if (!popupRoot) {
      popupRoot = getPopupRoot(mountNodeId);
    }
    setMountNode(popupRoot);
  }, []);

  if (!mountNode) {
    return null;
  }

  return createPortal(children, mountNode);
};

export interface IPopup {
  show: boolean;
  onHide: () => void;
}

const Popup: React.FC<IPopup> = ({ children, show, onHide }) => {
  if (!show) return null;

  return (
    <PopupPortal>
      <PopupContainer>
        <PopupOverlay onClick={onHide} />
        {children}
      </PopupContainer>
    </PopupPortal>
  );
};

export default Popup;

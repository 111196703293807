import { useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { Container, SearchButton, SearchIcon, SelectGroupWrapper } from './TradeRouteSelector.styles';
import { countries } from '@/constants';
import { CountryOption } from '@/components';
import { type IOption, IconAlignment, SectionThemeKey } from '@/interfaces';
import { splitTradelanePageSlug } from '@/utils';
import { TradeRouteDesktopTitle, TradeRouteTitle } from '../BannerWithTradeRouteWidget.styles';
import CountrySelector from './CountrySelector';
import TradeRouteDivider from './TradeRouteDivider';

const TITLE = 'Explore Trade Routes';

export interface ITradeRoute {
  from: string;
  to: string;
}

interface TradeRouteSelectorProps {
  theme?: SectionThemeKey;
  onSearch: ({ from, to }: ITradeRoute) => void;
}
const TradeRouteSelector: React.FC<TradeRouteSelectorProps> = ({ theme, onSearch }) => {
  const COUNTRIES_OPTIONS: IOption[] = Object.entries(countries).map(([slug, country]) => ({
    key: country.marketName,
    value: { slug, country },
    displayValue: <CountryOption showName code={country.countryCode} flagSize={20} />,
  }));

  const findTradeRouteOptionBySlug = (slug?: string) => {
    return COUNTRIES_OPTIONS.find((option) => option.value.slug === slug);
  };

  const convertSlugToTradeRoutes = (slug: string) => {
    const slugSplit = splitTradelanePageSlug(slug);
    const fromRoute = findTradeRouteOptionBySlug(slugSplit?.originSlug);
    const toRoute = findTradeRouteOptionBySlug(slugSplit?.destinationSlug);

    return { fromRoute, toRoute };
  };

  const { query } = useRouter();
  const { fromRoute, toRoute } = useMemo(() => {
    if (typeof query.slug !== 'string') {
      return { fromRoute: undefined, toRoute: undefined };
    }

    return convertSlugToTradeRoutes(query.slug);
  }, [query.slug]);

  const [fromCountry, setFromCountry] = useState<IOption>(fromRoute ?? COUNTRIES_OPTIONS[0]);

  const [toCountry, setToCountry] = useState<IOption>(toRoute ?? COUNTRIES_OPTIONS[0]);

  const [selecting, setSelecting] = useState(false);

  const handleOnSearchTradeRoute = () => {
    const checkSelectedRoutesIsDefaultRoutes = () => {
      const isDefaultFromRoute = fromCountry.value.slug === fromRoute;
      const isDefaultToRoute = toCountry.value.slug === toRoute;

      return isDefaultFromRoute && isDefaultToRoute;
    };

    const isSelectedDefaultRoutes = checkSelectedRoutesIsDefaultRoutes();
    if (isSelectedDefaultRoutes) {
      return;
    }

    onSearch({
      from: fromCountry.value.slug,
      to: toCountry.value.slug,
    });
  };

  return (
    <Container sectionTheme={theme || SectionThemeKey.Light}>
      <TradeRouteTitle>{TITLE}</TradeRouteTitle>
      <TradeRouteDesktopTitle>{TITLE}</TradeRouteDesktopTitle>

      <SelectGroupWrapper>
        <CountrySelector
          value={fromCountry}
          options={COUNTRIES_OPTIONS}
          onMenuOpened={() => setSelecting(true)}
          onMenuClosed={() => setSelecting(false)}
          onValueChanged={(option) => {
            setSelecting(false);
            setFromCountry(option);
          }}
        />

        <TradeRouteDivider />

        <CountrySelector
          value={toCountry}
          options={COUNTRIES_OPTIONS}
          onMenuOpened={() => setSelecting(true)}
          onMenuClosed={() => setSelecting(false)}
          onValueChanged={(option) => {
            setSelecting(false);
            setToCountry(option);
          }}
        />

        <SearchButton
          primaryButtonText='Search'
          disabled={selecting}
          icon={SearchIcon}
          alignment={IconAlignment.Left}
          onClick={handleOnSearchTradeRoute}
        />
      </SelectGroupWrapper>
    </Container>
  );
};

export default TradeRouteSelector;

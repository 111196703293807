import type { ReactNode } from 'react';
import { type RTButton, ButtonSize, type ButtonProps, ButtonVariant, ButtonFunctionKey, type IButton } from '@/interfaces';
import type { StyledComponent, DefaultTheme } from 'styled-components';
import {
  PrimaryButton,
  SecondaryButton,
  OutlinedButton,
  LinkButton,
  Hyperlink,
} from '@/components/Button/VariantStyles';

export const getStylingOfVariant = (
  variant: ButtonVariant | undefined,
): {
  ButtonComponent: StyledComponent<'a', DefaultTheme, ButtonProps & { as: string }, 'as'>;
  ButtonLink: StyledComponent<'a', DefaultTheme, ButtonProps, never>;
} => {
  // default is "primary"
  switch (variant) {
    case ButtonVariant.Secondary:
      return {
        ButtonComponent: SecondaryButton.Button,
        ButtonLink: SecondaryButton.ButtonLink,
      };
    case ButtonVariant.Outlined:
      return {
        ButtonComponent: OutlinedButton.Button,
        ButtonLink: OutlinedButton.ButtonLink,
      };
    case ButtonVariant.Link:
      return {
        ButtonComponent: LinkButton.Button,
        ButtonLink: LinkButton.ButtonLink,
      };
    case ButtonVariant.Hyperlink:
      return {
        ButtonComponent: Hyperlink.Button,
        ButtonLink: Hyperlink.ButtonLink,
      };
    default:
      return {
        ButtonComponent: PrimaryButton.Button,
        ButtonLink: PrimaryButton.ButtonLink,
      };
  }
};

// For ref:
// smallButtonHeight = 32
// mediumButtonHeight = 36
// largeButtonHeight = 48

export const getButtonSizeMetrics = (size: ButtonSize | undefined, icon?: ReactNode) => {
  switch (size) {
    case ButtonSize.Medium:
      return {
        padding: icon ? '8px 12px' : '10px 12px',
        icon: 8,
      };
    case ButtonSize.Large:
      return {
        padding: icon ? '14px 16px' : '16px',
        icon: 8,
      };
    default:
      return {
        padding: icon ? '6px 10px' : '8px 10px',
        icon: 4,
      };
  }
};

/**
 * Checks if the given button is an actual download button or a pseudo one:
 * - Actual button on click: Guest => Open pop up, User => Download
 * - Pseudo button on click: Guest/User => Open to a page with form
 * @param button - The button to check
 * @returns `true` if the button is a download button with trigger pop-up = Yes, `false` otherwise.
 */
export const checkIsActualDownloadButton = (button: IButton | RTButton) => {
  return button.function === ButtonFunctionKey.Download && !!button.triggerPopUp;
};

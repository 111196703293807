import { AcceptedDataTypes, ArticleTypeName, CompanyType, DataPickerContentType, RouteName } from '@/constants';
import callContentful from '@/fetchers/contentfulAPI/base.fetchers';
import {
  CustomTableField,
  DataPickerFieldType,
  type IDataPicker,
  type IDataPickerField,
  type IMarket,
  RegionType,
  SortDirection,
} from '@/interfaces';
import { replacePath, sortByDate, sortDatesByClosest } from '@/utils';

const ASSET_QUERY = '{ contentType description url(transform: { width: 156, resizeStrategy: FIT }) }';

const PUBLISHED_AT_QUERY = 'sys { publishedAt }';

const CONTENTFUL_QUERY_LIMIT = 1000;

type MapSlug = (data: any) => {
  [key in DataPickerContentType]: string;
};

export const mapSlug: MapSlug = (data) => ({
  [DataPickerContentType.carrier]: replacePath(RouteName.IndividualCarrier, [CompanyType.carriers, data.slug]),
  [DataPickerContentType.article]: replacePath(RouteName.Article, [data.articleType, data.slug]),
  [DataPickerContentType.event]: replacePath(RouteName.Article, [ArticleTypeName.events, data.slug]),
  [DataPickerContentType.market]: replacePath(RouteName.Market, [data.slug]),
  [DataPickerContentType.retailer]: replacePath(RouteName.IndividualCarrier, [CompanyType.retailers, data.slug]),
});

const REQUIRED_FIELDS_FOR_SORTING: {
  [key in DataPickerContentType]?: string[];
} = {
  [DataPickerContentType.carrier]: ['carrierDisplayName', 'carrierName'],
  [DataPickerContentType.article]: ['date'],
  [DataPickerContentType.event]: ['startDate'],
  [DataPickerContentType.market]: ['marketName', PUBLISHED_AT_QUERY],
};

const REQUIRED_FIELDS_FOR_PREMIUM: {
  [key in DataPickerContentType]?: string[];
} = {
  [DataPickerContentType.carrier]: ['slug'],
  [DataPickerContentType.article]: ['articleType', 'slug'],
  [DataPickerContentType.event]: ['slug'],
  [DataPickerContentType.market]: ['slug'],
};

const REQUIRED_FIELDS_FOR_CUSTOM: {
  [key in DataPickerContentType]?: Record<string, string[]>;
} = {
  [DataPickerContentType.carrier]: {
    [CustomTableField.LogoCarrierNameLink]: [`carrierLogo ${ASSET_QUERY}`, 'carrierDisplayName', 'carrierName', 'slug'],
  },
  [DataPickerContentType.market]: {
    [CustomTableField.FlagCountryNameLink]: ['countryCode', 'marketName', 'slug'],
  },
};

export const modifyTextData = (fieldId: IDataPickerField['id'], cellData: any) => {
  if (!cellData) return null;

  switch (fieldId) {
    case 'headquarters':
      return cellData.trim().split(',').pop();
    case 'employees':
    case 'company_size':
      return cellData.split(' ')[0];
    default:
      return cellData;
  }
};

type MapDataTypes = (fieldId: IDataPickerField['id']) => {
  [dataType: string]: string;
};

const mapDataTypes: MapDataTypes = (fieldId) => ({
  [AcceptedDataTypes.Link]: `${fieldId} ${ASSET_QUERY}`,
});

type GetTotal = (contentType: IDataPicker['contentType'], slug: string) => Promise<number>;

const getTotal: GetTotal = async (contentType, slug) => {
  const query = `{${contentType}:${contentType}Collection{total}}`;
  const response = await callContentful(query, false, slug, 'dataPicker.utils.getTotal');
  return response?.data?.[contentType]?.total || 0;
};

type FetchAllEntries = (dataPicker: IDataPicker & { slug: string }) => Promise<any[]>;

export const fetchAllEntries: FetchAllEntries = async ({ contentType, fields, slug }) => {
  if (!fields) return [];

  // NOTE: Ignore fields with type === DataPickerFieldType.backend
  const fieldIds = fields.reduce(
    (acc, { id, type, dataType }) => {
      if ([DataPickerFieldType.Default, DataPickerFieldType.GroupBy].includes(type) && dataType) {
        const fieldQuery = mapDataTypes(id)[dataType] || id;
        acc.push(fieldQuery);
      }
      if (type === DataPickerFieldType.Premium) {
        acc.push(...(REQUIRED_FIELDS_FOR_PREMIUM[contentType] || []));
      }
      if (type === DataPickerFieldType.Custom) {
        acc.push(...(REQUIRED_FIELDS_FOR_CUSTOM[contentType]?.[id] || []));
      }
      return acc;
    },
    <string[]>[],
  );

  if (!fieldIds.length) return [];

  fieldIds.push(...(REQUIRED_FIELDS_FOR_SORTING[contentType] || []));

  const getQuery = (skip: number): string => `
    {
      ${contentType}: ${contentType}Collection(limit: ${CONTENTFUL_QUERY_LIMIT}, skip: ${skip}) {
        items {
          ${Array.from(new Set(fieldIds)).join(' ')} 
        }
      }
    }
  `;

  const total = await getTotal(contentType, slug);
  const timesToFetch = Math.ceil(total / CONTENTFUL_QUERY_LIMIT);

  const promises = [];

  for (let i = 0; i < timesToFetch; i++) {
    promises.push(
      callContentful(getQuery(i * CONTENTFUL_QUERY_LIMIT), false, slug, 'dataPicker.utils.fetchAllEntries'),
    );
  }

  const response = await Promise.all(promises);

  const data = response.flatMap((element) => element?.data?.[contentType]?.items).filter((item) => item);

  return data || [];
};

export const sortTableData = (contentType: DataPickerContentType, tableData: any[]) => {
  switch (contentType) {
    case DataPickerContentType.carrier:
      return tableData.sort((a, b) =>
        (a.carrierDisplayName || a.carrierName)?.localeCompare(b.carrierDisplayName || b.carrierName),
      );
    case DataPickerContentType.article:
      return sortDatesByClosest(tableData, undefined, 'date');
    case DataPickerContentType.event:
      return sortDatesByClosest(tableData, undefined, 'startDate');
    case DataPickerContentType.market:
      return tableData.sort((a, b) => a.marketName?.localeCompare(b.marketName));
    default:
      return tableData;
  }
};

type MappedRegions = {
  region: IMarket['marketName'];
  publishedAt: IMarket['sys']['publishedAt'];
}[];

export const modifyColumnsData = (
  dataPicker: IDataPicker,
  groupedData: Record<string, any[]>,
): [string, any[]][] | undefined => {
  const { contentType, sortDirection } = dataPicker;

  switch (contentType) {
    case DataPickerContentType.market: {
      const regions = groupedData[RegionType.NotSelected] || [];

      const mappedRegions: MappedRegions = regions.map(({ marketName, sys }) => ({
        region: marketName,
        publishedAt: sys.publishedAt,
      }));

      // filter out the real regions. 'market' is a real 'region' when RegionType = NotSelected
      delete groupedData[RegionType.NotSelected];

      const sortedRegions = sortByDate(mappedRegions, sortDirection, 'publishedAt');

      const keyIndices = sortedRegions.reduce(
        (acc, { region }, idx) => {
          return { ...acc, [region]: idx };
        },
        <Record<string, number>>{},
      );

      return Object.entries(groupedData).sort(([a], [b]) => keyIndices[a] - keyIndices[b]);
    }

    default:
      return Object.entries(groupedData).sort(([a], [b]) =>
        sortDirection === SortDirection.Ascending ? a.localeCompare(b) : b.localeCompare(a),
      );
  }
};

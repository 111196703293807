import { useGlobalState } from '@/hooks';
import type { IIndividualCarrier } from '@/interfaces';
import { sortCarrierList } from '@/utils';
import { useEffect, useState } from 'react';

type UseCarrierList = (args: { carrierList?: Partial<IIndividualCarrier>[]; carrierInterval: number }) => {
  allCarrierList: Partial<IIndividualCarrier>[];
  handleLoadMore: () => void;
  truncatedCarrierList: Partial<IIndividualCarrier>[];
  truncatedCarrierListLength: number;
};

const useCarrierList: UseCarrierList = ({ carrierList: initialCarrierList, carrierInterval }) => {
  const { carriers } = useGlobalState();
  const [currentLength, setCurrentLength] = useState(carrierInterval);

  const carrierList =
    sortCarrierList({
      allCarriers: (carriers && Object.values(carriers)) || [],
      manuallyAddedCarriers: initialCarrierList,
    }) || [];
  const clonedCarrierList = [...carrierList];
  const slicedList = clonedCarrierList.slice(0, currentLength);
  const [truncatedCarrierList, setCarrierList] = useState(slicedList);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    setCarrierList(slicedList);
  }, [currentLength]);

  if (!initialCarrierList) {
    return {
      allCarrierList: [],
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      handleLoadMore: () => {},
      truncatedCarrierList: [],
      truncatedCarrierListLength: 0,
    };
  }

  const handleLoadMore = () => {
    setCurrentLength(currentLength + carrierInterval);
  };

  return {
    allCarrierList: carrierList,
    handleLoadMore,
    truncatedCarrierList,
    truncatedCarrierListLength: currentLength,
  };
};

export default useCarrierList;

import { CustomEventName } from '@/constants';
import { resetDataLayerAndPushEvent } from '..';

export const gtmClickNavigationOpen = () => {
  resetDataLayerAndPushEvent(CustomEventName.ClickNavigationOpen);
};

export const gtmClickNavigationItem = (navigationItem: string) => {
  resetDataLayerAndPushEvent(CustomEventName.ClickNavigationItem, {
    navigationItem,
  });
};

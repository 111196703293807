import { useEffect, useMemo, useState } from 'react';
import type { NextPage } from 'next';

import { base64ToUtf8 } from '@/utils';
import { PageWithGraphic } from '@/components';
import { NewsletterOptInSource } from '@/constants';
import { useGeo, useGlobalState } from '@/hooks';
import { triggerHook, updateNewsletterOptIn as updateNewsletterOptInOnUms } from '@/fetchers';
import {
  SubscribeNewsletterButton,
  SubscribeNewsletterTitle,
  SubscribeNewsletterWrapper,
} from './SubscribeNewsletter.style';

interface SubscribeNewsletterProps {
  email: string;
  accessToken?: string;
}

interface PageContent {
  title: string;
  buttonText: string;
  buttonLink?: string;
  buttonClickCallback?: () => void;
}

const SubscribeNewsletter: NextPage<SubscribeNewsletterProps> = ({ email, accessToken }) => {
  const { isLoggedIn, userInfo, isFollowingLinkedIn } = useGlobalState();
  const { geoInfo } = useGeo();
  const [isSubscribed, setIsSubscribed] = useState(false);

  const decodedEmail = base64ToUtf8(email);

  const isNotLoggedIn = !isLoggedIn; // unverified user is considered as not logged in
  const isEmailSameAsLoggedInEmail = userInfo?.email === decodedEmail;

  const triggerZapier = () => {
    triggerHook({
      hookId: 'o0vmbsk',
      data: {
        firstName: userInfo?.firstName,
        lastName: userInfo?.lastName,
        email: userInfo?.email,
        authenticationMethod: userInfo?.authenticationMethod,
        profilePictureUrl: userInfo?.profilePictureUrl,
        linkedInFollowStatus: isFollowingLinkedIn,
        newsletterOptIn: 'Yes',
        newsletterOptInSource: NewsletterOptInSource.PMT_EMAIL_NOTIFICATION,
        userType: userInfo?.userType,
        country: userInfo?.country ?? geoInfo?.country,
      },
    });
  };

  const updateNewsletterOptIn = async (accessToken: string, successCallBack?: () => void) => {
    try {
      const data = await updateNewsletterOptInOnUms({
        accessToken,
        newsletterOptIn: true,
      });

      if (!data?.id) return;

      triggerZapier();

      if (successCallBack) {
        successCallBack();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  useEffect(() => {
    if (isEmailSameAsLoggedInEmail && accessToken) {
      updateNewsletterOptIn(accessToken);
    }
  }, []);

  const handleSubscribe = () => {
    if (!accessToken) return;
    updateNewsletterOptIn(accessToken, () => setIsSubscribed(true));
  };

  const content: PageContent | undefined = useMemo(() => {
    switch (true) {
      case isNotLoggedIn:
        return {
          title: 'Sign up for Parcel Monitor Community to subscribe to our newsletters',
          buttonText: 'Visit Parcel Monitor Community',
          buttonLink: '/',
        };

      case isEmailSameAsLoggedInEmail:
        return {
          title: 'Thank you for subscribing to Parcel Monitor Community’s newsletters!',
          buttonText: 'Visit Parcel Monitor Community',
          buttonLink: '/',
        };

      case isSubscribed:
        return {
          title: 'You are now subscribed to Parcel Monitor Community’s newsletters!',
          buttonText: 'Visit Parcel Monitor Community',
          buttonLink: '/',
        };

      case !isEmailSameAsLoggedInEmail:
        return {
          title: `Subscribe to our Community newsletters as ${userInfo?.email ?? ''}?`,
          buttonText: 'Subscribe',
          buttonClickCallback: handleSubscribe,
        };

      default:
        return;
    }
  }, [isSubscribed, isNotLoggedIn, isEmailSameAsLoggedInEmail, userInfo?.email, handleSubscribe]);

  if (!content) return null;
  return (
    <SubscribeNewsletterWrapper>
      <PageWithGraphic>
        <SubscribeNewsletterTitle>{content.title}</SubscribeNewsletterTitle>
        <SubscribeNewsletterButton href={content.buttonLink} onClick={content.buttonClickCallback}>
          {content.buttonText}
        </SubscribeNewsletterButton>
      </PageWithGraphic>
    </SubscribeNewsletterWrapper>
  );
};

export default SubscribeNewsletter;

import { MasterTranslation } from '@/constants';
import {
  EventPhaseKey,
  type IMilestoneCondition,
  type IShipmentData,
  ParcelEventAnalyticsType,
  ShipmentStatus,
  TrackingMilestone,
} from '@/modules/pmt/interfaces';
import { orderedLastEventKeys, pendingStatuses } from './shipments.constants';

type TrackingMileStoneCondition = {
  /* milestone of shipment, use for shipment milestone chip */
  milestone: TrackingMilestone;
  /* function to check if a shipment belongs to a milestone */
  matcher: (shipment: IShipmentData) => boolean;
};

type CustomMatcherPhases = 'Pending' | 'Ordered' | 'Cancelled';

/* EVENT-BASED MATCHER */
export const milestoneCondition: Record<Exclude<TrackingMilestone, CustomMatcherPhases>, IMilestoneCondition[]> = {
  [TrackingMilestone.InTransit]: [
    { phaseKey: EventPhaseKey.Pickup },
    { phaseKey: EventPhaseKey.Transit },
    { phaseKey: EventPhaseKey.Customs },
  ],
  [TrackingMilestone.OutForDelivery]: [
    {
      phaseKey: EventPhaseKey.OutForDelivery,
      isFixedAddress: false,
    },
    {
      phaseKey: EventPhaseKey.Delivery,
      isFinalEvent: false,
      isFixedAddress: false,
    },
  ],
  [TrackingMilestone.ToCollect]: [
    {
      phaseKey: EventPhaseKey.OutForDelivery,
      isFixedAddress: true,
    },
    {
      phaseKey: EventPhaseKey.Delivery,
      isFinalEvent: false,
      isFixedAddress: true,
    },
  ],
  [TrackingMilestone.Delivered]: [
    {
      phaseKey: EventPhaseKey.Delivery,
      isFinalEvent: true,
      isFixedAddress: false,
    },
  ],
  [TrackingMilestone.Collected]: [
    {
      phaseKey: EventPhaseKey.Delivery,
      isFinalEvent: true,
      isFixedAddress: true,
    },
  ],
  [TrackingMilestone.Returned]: [{ phaseKey: EventPhaseKey.ReturnToSender }],
};

const matcher = (milestoneConditions: IMilestoneCondition[]) => {
  return (shipment: IShipmentData) => {
    const lastValidEvent = shipment.parcel.lastValidEvent;
    if (!lastValidEvent) return false;

    const found = milestoneConditions.find(({ phaseKey, isFinalEvent, isFixedAddress }) => {
      const isPhaseKeyMatched = phaseKey === lastValidEvent.phaseKey;
      const isFinalEventMatched = isFinalEvent === undefined || isFinalEvent === lastValidEvent.isFinalEvent;
      const isFixedAddressMatched = isFixedAddress === undefined || isFixedAddress === lastValidEvent.isFixedAddress;

      return isPhaseKeyMatched && isFinalEventMatched && isFixedAddressMatched;
    });

    return !!found;
  };
};

/* EndOf EVENT-BASED MATCHER*/

/* CUSTOM MATCHER */
const pendingMatcher = (shipment: IShipmentData) => {
  return pendingStatuses.includes(shipment.searchStatus) || shipment.shipmentStatus === ShipmentStatus.Pending;
};

// Ordered & Cancelled shipment milestone matchers must use last_event, because last_valid_event ignores
// event that are in "Others" phase
const orderedMatcher = (shipment: IShipmentData) => {
  if (shipment.isOpenShipment) return true;
  const lastEvent = shipment.parcel.lastEvent;
  const shipmentAnalytics = shipment.parcel.lastEvent?.analytics;
  const isOrderedShipment = lastEvent?.phaseKey ? orderedLastEventKeys.includes(lastEvent.phaseKey) : false;

  const isNoIssue = shipmentAnalytics === ParcelEventAnalyticsType.Regular;

  return isOrderedShipment && isNoIssue;
};

const cancelledMatcher = (shipment: IShipmentData) => {
  return (
    shipment.parcel.lastEvent?.analytics === ParcelEventAnalyticsType.Exception &&
    !!shipment.parcel.lastEvent.isFinalEvent
  );
};
/* EndOf CUSTOM MATCHER */

// Order is important here, check from top->bottom. Please be mindful before adding a milestone matcher
export const MILESTONE_MATCHERS: TrackingMileStoneCondition[] = [
  {
    milestone: TrackingMilestone.Cancelled,
    matcher: cancelledMatcher,
  },
  {
    milestone: TrackingMilestone.Pending,
    matcher: pendingMatcher,
  },
  {
    milestone: TrackingMilestone.Ordered,
    matcher: orderedMatcher,
  },
  {
    milestone: TrackingMilestone.InTransit,
    matcher: matcher(milestoneCondition.InTransit),
  },
  {
    milestone: TrackingMilestone.OutForDelivery,
    matcher: matcher(milestoneCondition.OutForDelivery),
  },
  {
    milestone: TrackingMilestone.ToCollect,
    matcher: matcher(milestoneCondition.ToCollect),
  },
  {
    milestone: TrackingMilestone.Delivered,
    matcher: matcher(milestoneCondition.Delivered),
  },
  {
    milestone: TrackingMilestone.Collected,
    matcher: matcher(milestoneCondition.Collected),
  },
  {
    milestone: TrackingMilestone.Returned,
    matcher: matcher(milestoneCondition.InTransit),
  },
];

export const TRACKING_MILESTONE_TRANSLATION: Record<TrackingMilestone, MasterTranslation> = {
  [TrackingMilestone.Pending]: MasterTranslation.Pending,
  [TrackingMilestone.Ordered]: MasterTranslation.Ordered,
  [TrackingMilestone.Cancelled]: MasterTranslation.Cancelled,
  [TrackingMilestone.InTransit]: MasterTranslation.InTransit,
  [TrackingMilestone.OutForDelivery]: MasterTranslation.OutForDelivery,
  [TrackingMilestone.ToCollect]: MasterTranslation.ToCollect,
  [TrackingMilestone.Delivered]: MasterTranslation.Delivered,
  [TrackingMilestone.Collected]: MasterTranslation.Collected,
  [TrackingMilestone.Returned]: MasterTranslation.Returned,
};

import type { ISEOSimpleFields, IMarketAndTag } from '@/interfaces';

export enum ThumbnailVariant {
  LARGE_DEFAULT = 'Default Large Card',
  LARGE_HIDE_DES = 'Hidden Description Large Card',
  LARGE_HIDE_DATE = 'Hidden Date Large Card',
  SMALL_DEFAULT = 'Default Small Card',
  SMALL_HIDE_IMG = 'Hidden Image Small Card',
  SMALL_IMG = 'Default Small Card With Small Image',
  BLUE_BORDER_LEFT = 'Blue Border Left',
}

export interface ThumbnailProps {
  sys: { id: string };
  tags?: IMarketAndTag[];
  date?: string;
  title?: string;
  featured?: boolean;
  articleType?: string;
  slug?: string;
  startDate?: string;
  endDate?: string;
  address?: string;
  recommended?: boolean | null;
  image?: {
    contentType?: string;
    url: string;
    title?: string;
    description?: string;
  };
  variant?: ThumbnailVariant;
  seo?: ISEOSimpleFields;
}

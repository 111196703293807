import { LeadGenBannerSection, SectionListRendererWrapper } from './SectionListRenderer.styles';

import { ContentTypeMapping, ContentTypeName } from '@/constants';
import { useGlobalState } from '@/hooks';
import { ButtonFunctionKey, type IEvent, PMCAdType, SectionWrapperVariant } from '@/interfaces';
import { capitalize } from '@/utils';
import SectionWrapper from '../SectionWrapper/SectionWrapper.styles';

interface ISectionListRendererProps {
  featuredEventList?: IEvent[] | null;
  sectionList: any[];
  pageTitle?: string;
  hideSpacingBetweenSections?: boolean;
}

const SectionListRenderer: React.FC<ISectionListRendererProps> = ({
  featuredEventList,
  sectionList,
  pageTitle,
  hideSpacingBetweenSections,
}) => {
  const { isLoggedIn } = useGlobalState();
  return (
    <SectionListRendererWrapper hideSpacingBetweenSections={hideSpacingBetweenSections}>
      {sectionList.map((section, index) => {
        const SectionComponent = ContentTypeMapping[section.__typename as ContentTypeName];

        if (!SectionComponent) {
          return null;
        }

        switch (capitalize(section.__typename)) {
          case ContentTypeName['LeadGenBanner']: {
            const isPopUpButton = section?.buttons?.some((button: any) => button.function === ButtonFunctionKey.PopUp);
            const isHideLoggedInUser = isPopUpButton && isLoggedIn;
            return (
              // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
              <LeadGenBannerSection key={index} variant={section.variant} isHideLoggedInUser={isHideLoggedInUser}>
                <SectionComponent {...section} />
              </LeadGenBannerSection>
            );
          }

          case ContentTypeName['EventList']: {
            if (section.featured && !featuredEventList) return null;
            if (!section.featured && featuredEventList) return null;
            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
            return <SectionComponent {...section} featuredEventList={featuredEventList} key={index} />;
          }

          case ContentTypeName['Ads']: {
            return (
              <SectionWrapper
                // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                key={index}
                hasPadding={false}
                hasBorderTop={false}
                variant={SectionWrapperVariant.BlockDisplay}
              >
                <SectionComponent className='ads-left-section' variant={PMCAdType.LEFT_SECTION} {...section} />
              </SectionWrapper>
            );
          }

          case ContentTypeName['LeadsWrapper']: {
            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
            return <SectionComponent {...section} pageTitle={pageTitle} key={index} />;
          }

          default:
            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
            return <SectionComponent key={index} {...section} />;
        }
      })}
    </SectionListRendererWrapper>
  );
};

export default SectionListRenderer;

import { type StatusCodes, UmsErrorMessages } from '@/constants';
import { LanguageCode } from '@/interfaces';
import { getMasterTranslationLanguageCode } from '@/utils';
import { umsFetcher } from './base.fetchers';

type SubscribeShipment = (args: {
  shipmentDocId: string;
  language?: LanguageCode;
  accessToken: string;
}) => Promise<{
  message: string;
  status: StatusCodes;
}>;

export const subscribeSingleShipment: SubscribeShipment = async ({
  shipmentDocId,
  accessToken,
  language = LanguageCode.English,
}) => {
  try {
    const res = await umsFetcher.post(
      'users/shipment/subscribe',
      {
        shipment_id: shipmentDocId,
        language: getMasterTranslationLanguageCode(language),
      },
      { accessToken },
    );

    const data = await res.json();
    const dataWithStatus = { ...data, status: res.status };

    if (dataWithStatus.status === 500) {
      throw new Error(UmsErrorMessages[dataWithStatus.error_code]);
    }

    return dataWithStatus;
  } catch (err: any) {
    console.error('users/shipment/subscribe ', err);
  }
};

type SubscribeAllShipment = (args: {
  language?: LanguageCode;
  accessToken: string;
}) => Promise<{
  message: string;
  status: StatusCodes;
}>;

export const subscribeAllShipments: SubscribeAllShipment = async ({ accessToken, language = LanguageCode.English }) => {
  try {
    const res = await umsFetcher.post(
      'users/shipment/subscribe/all',
      { language: getMasterTranslationLanguageCode(language) },
      { accessToken },
    );

    const data = await res.json();
    const dataWithStatus = { ...data, status: res.status };

    if (dataWithStatus.status === 500) {
      throw new Error(UmsErrorMessages[dataWithStatus.error_code]);
    }

    return dataWithStatus;
  } catch (err: any) {
    console.error('users/shipment/subscribe/all ', err);
  }
};

import Cookies from 'js-cookie';
import { CookieKey, StatusCodes, UmsErrorMessages } from '@/constants';
import type { LanguageCode } from '@/interfaces';

import { umsFetcher } from './base.fetchers';
import { getMasterTranslationLanguageCode } from '@/utils';

type UpdateTrackedShipments = (arg: {
  language: LanguageCode;
  accessToken: string;
}) => void;

const TIO_UUID_KEY = 'uuid';

export const updateTrackedShipments: UpdateTrackedShipments = async ({ language, accessToken }) => {
  try {
    const uuids: string[] = [];

    const pmtGuestUuid = Cookies.get(CookieKey.GuestTrackingUuid);
    const tioGuestUuid = localStorage.getItem(TIO_UUID_KEY);
    if (pmtGuestUuid) uuids.push(pmtGuestUuid);
    if (tioGuestUuid) uuids.push(tioGuestUuid);

    if (!uuids.length) return;

    const response = await umsFetcher.post(
      'users/shipment',
      {
        uuids,
        language: getMasterTranslationLanguageCode(language),
      },
      { accessToken },
    );

    if (response.status === 200) {
      Cookies.remove(CookieKey.GuestTrackingUuid);
      localStorage.removeItem(TIO_UUID_KEY);
    }
  } catch (err: any) {
    console.error('users/shipment ', err);
  }
};

type UnsubscribeShipment = (arg: {
  shipmentUuid: string;
}) => Promise<StatusCodes | undefined>;

export const unsubscribeShipment: UnsubscribeShipment = async ({ shipmentUuid }) => {
  try {
    const response = await umsFetcher.post('users/shipment/unsubscribe', {
      shipment_uuid: shipmentUuid,
    });

    if (response.status === 200) return StatusCodes.SUCCESS_200;
    if (response.status === 404) return StatusCodes.ERROR_404;

    const data = await response.json();

    if (response.status === 500) {
      throw new Error(UmsErrorMessages[data.error_code]);
    }
  } catch (err: any) {
    console.error('users/shipment/unsubscribe ', err);
  }
};

type UnsubscribeAllShipments = (arg: {
  accessToken: string;
}) => Promise<StatusCodes | undefined>;

export const unsubscribeAllShipments: UnsubscribeAllShipments = async ({ accessToken }) => {
  try {
    const response = await umsFetcher.post(
      'users/shipment/unsubscribe/all',
      {},
      {
        accessToken,
      },
    );

    if (response.status === 200) return StatusCodes.SUCCESS_200;
    if (response.status === 404) return StatusCodes.ERROR_404;

    const data = await response.json();

    if (response.status === 500) {
      throw new Error(UmsErrorMessages[data.error_code]);
    }
  } catch (err: any) {
    console.error('users/shipment/unsubscribe/all ', err);
  }
};
